const ProjectSchema = {
  title: "project_string_ata",
  description: "schema for project",
  version: 0,
  type: "object",
  properties: {
    id: {
      type: "string",
      primary: true,
    },
    data: {
      type: "string",
    },
    dbUpdateMark: {
      type: "string",
    },
  },
  required: ["id", "data", "dbUpdateMark"],
};

export default ProjectSchema;
