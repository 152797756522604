import * as RxDB from "rxdb";
import { RxDBMigrationPlugin } from "rxdb/plugins/migration";
import { RxDBQueryBuilderPlugin } from "rxdb/plugins/query-builder";
import { RxDBValidatePlugin } from "rxdb/plugins/validate";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";

RxDB.addRxPlugin(require("pouchdb-adapter-idb"));
RxDB.addRxPlugin(RxDBMigrationPlugin);
RxDB.addRxPlugin(RxDBQueryBuilderPlugin);
RxDB.addRxPlugin(RxDBValidatePlugin);
RxDB.addRxPlugin(RxDBLeaderElectionPlugin);

class AIWriterRxDB {
  constructor(dbName) {
    this.dbName = dbName;
    this.charts = {};
    this.db = null;
  }

  async initDB(chartArray) {
    this.db = await RxDB.createRxDatabase({
      name: this.dbName,
      adapter: "idb",
      multiInstance: true,
      pouchSettings: {
        revs_limit: 5,
      },
    });

    await Promise.all(
      chartArray.map(async (item) => {
        let chart = await this.db.collection({
          name: item.name,
          schema: item.schema,
          migrationStrategies: item.migrationStrategies,
        });
        this.charts[item.name] = chart;
      })
    );
  }

  static async getAIWriterRxDB(dbName, chartArray) {
    const db = new AIWriterRxDB(dbName);
    await db.initDB(chartArray);
    return db;
  }
}

export default AIWriterRxDB;
