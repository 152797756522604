import React, { useEffect, useState } from "react";

const TitleLabel = (props) => {
    const { height, cards, title, src } = props;
    return (
        <div style={{ display: 'flex', marginLeft: '6%', marginBottom: 5 }}>
            <img style={{ height: 20, width: 20, marginRight: 5 }} src={src} /><p>{title}</p>
        </div>
    )
};

export default  TitleLabel;
