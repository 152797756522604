import * as React from "react";
import "../styles.css";

//引入的库组件
import { message, Pagination, Row, Col } from "antd";
import moment from "moment";
import { convertToZH } from "aw-error";
import axios from "axios";
import download from "downloadjs";

//引入的其他页面组件
import headers from "../../../GlobalData/headers";
import MainContainer from "../../Router_MainContainer/index";
import SearchWithDrop from "../../Components/Search/searchWithDrop";
import DeleteProjects from "./DeleteProjects/index";
import ProjectTemplate from "./ProjectTemplate/projectTemplate";
import {
  getPaginationPageVariable,
  TimeStampToDate,
  DateToTimeStamp,
} from "../../../utils/index";
import { renderPagination } from "../../Components/Pagination/index";
import CropperModal from "../../Components/CropperModal";
import EmptyComponent from "../../Components/EmptyComponent/index";
import CreateModal from "../../Components/CreateModal/index";
import ReconfirmModal from "../../Components/ReconfirmModal/index";
import GlobalSearchModal from "../../GlobalSearchModal/index";
import * as WritingModuleAction from "../../WritingModuleAction/index";
import { MainContextObj } from "../../HomePage/MainContext";

//graphql查询
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import {
  useGetProjectListQuery,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  useSetOpenProjectDateMutation,
  ProjectType,
  SortOrder,
} from "../../../generated/graphql";

//引入的资源图片
import DELETE_ICON from "../../../assets/delete_card.png";

const PER_PAGE_COUNT = 12;

const MyProject = (props) => {
  const {
    DBUUID,
    isNetworkOnline,
    dbController,
    isDBLeader,
  } = React.useContext(MainContextObj);

  const { setIsLogin, history } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [currentProjectPage, setCurrentProjectPage] = React.useState(1);
  const [searchString, setSearchString] = React.useState("");
  const [projectList, setProjectList] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState({});
  const [isShowReconfirmModal, setIsShowReconfirmModal] = React.useState(false);
  const [isShowCreateModal, setIsShowCreateModal] = React.useState(false);
  const [deleteProjectTitle, setDeleteProjectTitle] = React.useState("");

  const [globalSearchString, setGlobalSearchString] = React.useState("");
  const [isShowDeleteProjects, setIsShowDeleteProjects] = React.useState(false);
  const [showGlobalSearchModal, setShowGlobalSearchModal] = React.useState({
    isShow: false,
    isSearch: false,
  });

  const [projectListPageInfo, setProjectListPageInfo] = React.useState({
    count: 0,
    pageSize: PER_PAGE_COUNT,
    pageInfo: null,
  });

  const [setOpenProjectDateMutation] = useSetOpenProjectDateMutation();
  const [deleteProjectMutation] = useDeleteProjectMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();

  const { refetch: getProjectListQuery } = useGetProjectListQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const handleOnCoverImg = (src) => {
    let input = {
      id: window.atob(selectedProject.id).split("Project:")[1],
      coverImageUrl: src,
    };

    updateProject(input, (project) => {
      setFile(null);
      let projectListTemp = projectList.map((item) => {
        if (item.id === project.id) {
          item.coverImageUrl = src;
        }
        return item;
      });
      message.success("修改成功");
      setProjectList(projectListTemp);
    });
  };

  const updateProject = (input, callback) => {
    updateProjectMutation({ variables: { input } }).then((res) => {
      if (!res.data.updateProject.successful) {
        message.error(convertToZH(res.data.updateProject.messages[0]));
      } else {
        let project = res.data.updateProject.result;
        if (callback) {
          callback(project);
        }
      }
    });
  };

  const getProjectList = (input, callback) => {
    input = {
      ...input,
      filter: {
        types: [ProjectType.User],
      },
    };
    getProjectListQuery(input).then((res) => {
      if (res.data.projects) {
        let pageInfo = res.data.projects.pageInfo;
        let projects = res.data.projects.edges.map((item, index) => {
          return item.node;
        });

        setProjectListPageInfo({
          ...projectListPageInfo,
          pageInfo,
          count: res.data.projects.count,
        });
        setProjectList(projects);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取项目失败");
      }
    });
  };

  const searchProject = (searchString) => {
    let variables = {
      after: undefined,
      first: PER_PAGE_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString !== "" ? searchString : undefined,
      order: SortOrder.Desc,
    };
    setSearchString(searchString);
    getProjectList(variables, () => {
      setCurrentProjectPage(1);
    });
  };

  const searchProjectRef = React.useRef(searchProject);
  searchProjectRef.current = searchProject;

  React.useState(() => {
    //加载完毕更改标签名
    document.title = `我的项目`;

    getProjectList({
      after: undefined,
      first: PER_PAGE_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString !== "" ? searchString : undefined,
      order: SortOrder.Desc,
    });
  }, []);

  const openGlobalSearch = (string) => {
    setGlobalSearchString(string);
    setShowGlobalSearchModal({ isSearch: true, isShow: true });
  };

  const openGlobalSearchRef = React.useRef(openGlobalSearch);
  openGlobalSearchRef.current = openGlobalSearch;

  const renderProjects = () => {
    return (
      <>
        {projectList.length > 0 ? (
          <>
            <div className="project-list-container-1730">
              <Row gutter={[16, 24]}>
                {projectList.map((item, index) => {
                  return (
                    <Col key={item.id + index} span={4}>
                      <ProjectTemplate
                        key={"project-template-" + index}
                        templateInfo={item}
                        setFile={setFile}
                        setSelectedProject={setSelectedProject}
                        changeNameCallback={() => {
                          setIsShowCreateModal(true);
                        }}
                        deleteCallback={() => {
                          setIsShowReconfirmModal(true);
                          setDeleteProjectTitle(item.title);
                        }}
                        exportProjectCallback={() => {
                          let exportUrl =
                            "http://localhost:3000/export-aiwriter?projectid=" +
                            window.atob(item.id).split("Project:")[1];
                          const req = {
                            method: "GET",
                          };
                          fetch(exportUrl, req).then(async (response) => {
                            if (response.status === 500) return;
                            const pdfBlob = await response.blob();
                            download(pdfBlob, `${item.title}.aiwriter`);
                          });
                        }}
                        templateClick={() => {
                          let id = window.atob(item.id).split("Project:")[1];
                          let code = window
                            .atob(item.code)
                            .split("Project:")[1];
                          setIsLoading(true);
                          let input = {
                            id: id,
                            lastSeenAt: moment()
                              .utc(8)
                              .format(),
                          };
                          setOpenProjectDateMutation({
                            variables: { input },
                          }).then((res) => {
                            history.push("/project/writing/p/" + code);
                          });
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </div>
            <div className="project-delete-button-container">
              <div
                className="project-delete-button-text"
                onClick={() => {
                  setIsShowDeleteProjects(true);
                }}
              >
                项目回收站
              </div>
            </div>
            <Pagination
              className="project-content-page-container"
              pageSize={PER_PAGE_COUNT}
              itemRender={renderPagination}
              current={currentProjectPage}
              total={projectListPageInfo.count}
              hideOnSinglePage
              onChange={(page) => {
                let variables = getPaginationPageVariable(
                  page,
                  currentProjectPage,
                  projectListPageInfo
                );
                variables = {
                  ...variables,
                  matching: searchString !== "" ? searchString : undefined,
                  order: SortOrder.Desc,
                };

                getProjectList(variables, () => {
                  setCurrentProjectPage(page);
                });
              }}
            />
            <CropperModal
              file={file}
              successCallback={handleOnCoverImg}
              aspectRatio={164 / 100}
              titleText={"裁剪封面"}
              okText={"设置封面"}
              isSetMaxSize={true}
              maxSize={{ width: 164 * 3 }}
            />
          </>
        ) : (
          <>
            <div className="project-delete-button-container" style={{ marginTop: "20px" }}>
              <div
                className="project-delete-button-text"
                onClick={() => {
                  setIsShowDeleteProjects(true);
                }}
              >
                项目回收站
              </div>
            </div>
            <div style={{ marginTop: "100px" }}>
              <EmptyComponent tip={"暂时没有项目"} />
            </div>
          </>
        )}
        <DeleteProjects
          isShow={isShowDeleteProjects}
          cancelCallback={() => {
            setIsShowDeleteProjects(false);
          }}
          submitCallback={(selectedProject) => {
            setIsShowDeleteProjects(false);
            if (selectedProject) {
              let input = {
                id: window.atob(selectedProject.id).split("Project:")[1],
                isDeleted: false,
              };

              updateProject(input, (project) => {
                getProjectList(
                  {
                    after: undefined,
                    first: PER_PAGE_COUNT,
                    before: undefined,
                    last: undefined,
                    matching: searchString !== "" ? searchString : undefined,
                    order: SortOrder.Desc,
                  },
                  () => {
                    message.success("恢复项目成功");
                    setCurrentProjectPage(1);
                  }
                );
              });
            } else {
              // message.warning("恢复项目失败");
            }
          }}
        />
        <ReconfirmModal
          isShow={isShowReconfirmModal}
          title="删除项目"
          content={"是否删除项目：" + deleteProjectTitle}
          cancelCallback={() => {
            setIsShowReconfirmModal(false);
            setDeleteProjectTitle("");
          }}
          submitCallback={() => {
            setIsShowReconfirmModal(false);
            setDeleteProjectTitle("");

            let id = window.atob(selectedProject.id).split("Project:")[1];
            deleteProjectMutation({
              variables: { input: { id: id } },
            }).then((res) => {
              if (res.data.securelyDeleteProject.successful) {
                let page = currentProjectPage;
                if (projectList.length === 1 && currentProjectPage > 1) {
                  page--;
                }
                let variables = getPaginationPageVariable(
                  page,
                  currentProjectPage,
                  projectListPageInfo
                );
                variables = {
                  ...variables,
                  matching: searchString !== "" ? searchString : undefined,
                  order: SortOrder.Desc,
                };
                getProjectList(variables, () => {
                  setCurrentProjectPage(page);
                  message.success("删除项目成功");
                });
              } else {
                message.error("删除项目失败");
              }
            });
          }}
        />
        <CreateModal
          originName={selectedProject.title}
          title="修改名称"
          tip="请输入项目名称"
          showModal={isShowCreateModal}
          handleOnOk={async (name) => {
            setIsShowCreateModal(false);
            let projectId = window
              .atob(selectedProject.id)
              .split("Project:")[1];
            let outlineTemp = JSON.parse(
              JSON.stringify(selectedProject.outline)
            );
            let outlineStateTemp = null;
            if (
              selectedProject.outlineState &&
              Object.keys(selectedProject.outlineState).length
            ) {
              outlineStateTemp = JSON.parse(
                JSON.stringify(selectedProject.outlineState)
              );
            } else {
              outlineStateTemp = {
                expandedKeys: selectedProject.outline.expandedKeys,
                lastEditNode: selectedProject.outline.lastEditNode,
                outlineUpdateAt: TimeStampToDate(Date.now()),
              };
            }
            let projectFromDB = await WritingModuleAction.getProjectData(
              projectId,
              dbController
            );

            if (projectFromDB && projectFromDB !== "") {
              let projectDataFromDB = JSON.parse(projectFromDB);
              if (
                DateToTimeStamp(outlineStateTemp.outlineUpdateAt) <
                DateToTimeStamp(projectDataFromDB.outlineState.outlineUpdateAt)
              ) {
                outlineTemp = JSON.parse(
                  JSON.stringify(projectDataFromDB.outline)
                );
                outlineStateTemp = JSON.parse(
                  JSON.stringify(projectDataFromDB.outlineState)
                );
              }
            }

            outlineTemp.title = name;
            outlineTemp.menu[0].title = name;
            outlineStateTemp.outlineUpdateAt = TimeStampToDate(Date.now());
            let input = {
              id: projectId,
              title: name,
              outline: JSON.stringify(outlineTemp),
              outlineState: JSON.stringify(outlineStateTemp),
            };
            updateProject(input, (project) => {
              setFile(null);
              let projectListTemp = projectList.map((item) => {
                if (item.id === project.id) {
                  item.title = name;
                  item.outline = outlineTemp;
                }
                return item;
              });
              message.success("修改成功");
              setProjectList(projectListTemp);
            });
          }}
          handleOnCancel={() => {
            setIsShowCreateModal(false);
          }}
        />
      </>
    );
  };

  const renderBodyContent = () => {
    return renderProjects();
  };

  const renderHeaderContent = () => {
    const categories = [
      {
        name: "项目",
      },
      {
        name: "卡片",
      },
    ];

    return (
      <div className="project-myproject-header-container">
        <div className="project-myproject-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"project-myproject-" + index}
                className={
                  index === 0
                    ? "project-myproject-header-tab-item-selected"
                    : "project-myproject-header-tab-item"
                }
                onClick={() => {
                  if (index !== 0) {
                    history.push("/project/mycard");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        <SearchWithDrop
          position={{ right: "25px" }}
          callback={(index, string) => {
            if (index === "当前页面") {
              searchProjectRef.current(string);
            }
            if (index === "全部搜索") {
              openGlobalSearchRef.current(string);
            }
          }}
          selectValues={[
            {
              value: "当前页面",
            },
            {
              value: "全部搜索",
            },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      <MainContainer
        currentSiderIndex={1}
        setIsLogin={setIsLogin}
        isBodyLoading={isLoading}
        bodyContent={renderBodyContent()}
        headerContent={renderHeaderContent()}
      />
      <GlobalSearchModal
        showModal={showGlobalSearchModal}
        showAddToProject={false}
        addToProjectCallback={null}
        globalSearchString={globalSearchString}
        handleOnCancel={() => {
          setShowGlobalSearchModal({ isShow: false, isSearch: false });
        }}
      />
    </>
  );
};

export default MyProject;
