import * as React from "react";
import "./styles.css";
import Projects from "./Projects";

//引入的库组件
import { message, Row, Col, Spin, Pagination, Popconfirm } from "antd";

//引入的其他页面组件
import InsCards from "./InspireCards";
import { convertToZH } from "aw-error";
import CorpusCardDetailContainer from "../Router_Corpus/corpusCardDetailContainer";
import { getUrlPath, getUrlSearch } from "../../utils/getUrlInfo";

//graphql查询
import {
  NullProjectCardType,
  useGetInspirationProjectDetailQuery,
  useGetInspirationProjectInfoQuery,
  useGetInspirationPackageInfoQuery,
  useGetInspirationCardInfoQuery,
  useProjectCategoriesQuery,
  useInspirationMenuQuery,
  useGetInspirationCardFolderQuery,
  useReferenceCardsMutation,
  useReferenceProjectMutation,
  useSharedCardsQuery,
  useUpdateProjectMutation,
  SharedCardType,
  SharedCardOrder,
  SharedProjectType,
  FileType,
} from "../../generated/graphql";

//引入的资源图片
import WorldWorks from "../../assets/inspiration/world-works.png";
import WorldWriters from "../../assets/inspiration/world-writers.png";
import Ad from "../../assets/inspiration/ad.png";
import Sport from "../../assets/inspiration/sport.png";
import Education from "../../assets/inspiration/education.png";
import Travel from "../../assets/inspiration/travel.png";
import Social from "../../assets/inspiration/social.png";
import Colorful from "../../assets/colorful.png";
import BackIcon from "../../assets/back-black.png";

import Back from "../../assets/inspiration/back.png";
import ADD_PROJECT from "../../assets/corpus/add-dropdown.png";
import CardsInTotal from "./CardsInTotal";
import { useState } from "react";
import ADD_CARD from "../../assets/add-card.png";
import { last, project } from "ramda";

import { useApolloClient } from "react-apollo";

//路由格式示例金句 /idea/golden/p/:code/f/:code/c/:code
export const P_INDEX = 2;
export const P_ID_INDEX = 3;
export const F_INDEX = 4;
export const F_ID_INDEX = 5;
export const C_INDEX = 6;
export const C_ID_INDEX = 7;

const PER_PAGE_COUNT = 24;
let reconfirmModalCallback = null;
const CorpusCardType = {
  Golden: "GOLDEN",
  Wiki: "WIKI",
};

const InspirationContainer = (props) => {
  const client = useApolloClient();
  const {
    titleIndex,
    setSearchCallback,
    isShowCardDetail,
    setIsShowCardDetail,
    history,
  } = props;
  const [categoryList, setCategoryList] = React.useState([]);
  const [categoryShowList, setCategoryShowList] = React.useState([]);
  const [cardList, setCardList] = React.useState([]);
  const [showList, setShowList] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectionTags, setSelectionTags] = React.useState({});
  const [folderCards, setFolderCards] = React.useState([]);
  const [isShowReconfirmModal, setIsShowReconfirmModal] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [currentPage, setCurrentPage] = React.useState(1);
  const [confirmText, setConfirmText] = React.useState("");
  const [chosenCate, setChosenCate] = useState([]);
  const [folderDetail, setFolderDetail] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [isInit, setIsInit] = React.useState(false);

  const [selectedCard, setSelectedCard] = React.useState({});

  const [selectedTagProjectsInfo, setSelectedTagProjectsInfo] = React.useState(
    {}
  );
  const [showListInfo, setShowListInfo] = React.useState({
    autoGenTags: [],
    categories: [],
    count: 0,
  });
  const [currentPageInfo, setCurrentPageInfo] = React.useState({
    count: 0,
    pageSize: PER_PAGE_COUNT,
    pageInfo: null,
  });

  const [referenceCards] = useReferenceCardsMutation();

  const [referenceProject] = useReferenceProjectMutation();

  const { refetch: getInspirationMenu } = useInspirationMenuQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getProjectCategories } = useProjectCategoriesQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const {
    refetch: getInspirationProjectDetail,
  } = useGetInspirationProjectDetailQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const {
    refetch: getInspirationProjectInfo,
  } = useGetInspirationProjectInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getSharedCards } = useSharedCardsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const {
    refetch: getInspirationCardFolder,
  } = useGetInspirationCardFolderQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const {
    refetch: getInspirationPackageInfo,
  } = useGetInspirationPackageInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getInspirationCardInfo } = useGetInspirationCardInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();

    if (titleIndex === 0) {
      if (
        pathArray[P_INDEX] &&
        pathArray[P_INDEX] === "p" &&
        pathArray[P_ID_INDEX] &&
        pathArray[P_ID_INDEX] !== ""
      ) {
        let projectCode = window.btoa("SharedProject:" + pathArray[P_ID_INDEX]);
        let variables = {
          code: projectCode,
        };

        getInspirationProjectInfo(variables).then((res) => {
          if (!res.data.node) {
            message.error("项目不存在");
            setIsInit(true);
            window.history.pushState(null, "", "/idea/golden");
            return;
          }

          let project = res.data.node;
          let cardsVariables = {
            id: project.id,
            after: undefined,
            first: PER_PAGE_COUNT,
            before: undefined,
            last: undefined,
            order: "DESC_INSERTED_AT",
            matching: searchString === "" ? undefined : searchString,
            filter: {
              type:
                titleIndex === 0
                  ? SharedProjectType.Golden
                  : SharedProjectType.Wiki,
              autoGenTags: [project.title],
              categories: project.categories,
            },
          };

          getCardListForProject(cardsVariables, (datas) => {
            setShowList(true);
            setCurrentPage(1);
            setShowListInfo({
              autoGenTags: [project.title],
              categories: project.categories,
              count: project.cardCounts,
              id: project.id,
              title: project.title,
            });

            if (
              pathArray[F_INDEX] &&
              pathArray[F_INDEX] === "f" &&
              pathArray[F_ID_INDEX] &&
              pathArray[F_ID_INDEX] !== ""
            ) {
              let packageCode = window.btoa(
                "SharedCard:" + pathArray[F_ID_INDEX]
              );
              getInspirationPackageInfo({
                code: packageCode,
                projectId: project.id,
              }).then((res) => {
                if (res.data.node) {
                  getFolderCards(
                    {
                      id: res.data.node.id,
                      code: packageCode,
                      title: res.data.node.title,
                      childrenCounts: res.data.node.childrenCounts,
                      type: res.data.node.type,
                    },
                    (list) => {
                      console.log("card==========");
                      console.log(list);
                      console.log(pathArray);
                      console.log("card==========");
                      if (
                        list.length > 0 &&
                        pathArray[C_INDEX] &&
                        pathArray[C_INDEX] === "c" &&
                        pathArray[C_ID_INDEX] &&
                        pathArray[C_ID_INDEX] !== ""
                      ) {
                        let cardCode = window.btoa(
                          "SharedCard:" + pathArray[C_ID_INDEX]
                        );
                        let card = list.filter((item) => {
                          return item.code === cardCode;
                        })[0];
                        if (card) {
                          setSelectedCard(card);
                          setIsShowCardDetail(true);
                        }
                      }
                      setIsInit(true);
                    }
                  );
                } else {
                  setIsInit(true);
                }
              });
            } else if (
              pathArray[F_INDEX] &&
              pathArray[F_INDEX] === "c" &&
              pathArray[F_ID_INDEX] &&
              pathArray[F_ID_INDEX] !== ""
            ) {
              let cardCode = window.btoa("SharedCard:" + pathArray[F_ID_INDEX]);
              getInspirationCardInfo({ code: cardCode }).then((res) => {
                if (res.data.node) {
                  setSelectedCard(res.data.node);
                  setIsShowCardDetail(true);
                }
                setIsInit(true);
              });
            } else {
              setIsInit(true);
            }
          });
        });
      } else {
        setIsInit(true);
        window.history.pushState(null, "", "/idea/golden");
      }
    } else if (titleIndex === 1) {
      setIsInit(true);
    }
  }, []);

  const searchAllCardList = (searchString) => {
    setSearchString(searchString);
    setShowListInfo(null);
    let variables = {
      after: undefined,
      first: PER_PAGE_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString === "" ? undefined : searchString,
      order: SharedCardOrder.DescInsertedAt,
      projectIds: undefined,
      filter: {
        types: [SharedCardType.Golden],
        fileTypes: [FileType.File],
      },
    };
    getCardList(variables, () => {
      setCurrentPage(1);
      setShowList(true);
    });
  };

  const searchProjectCardList = (searchString) => {
    setSearchString(searchString);
    let variables = {
      after: undefined,
      first: PER_PAGE_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString === "" ? undefined : searchString,
      order: SharedCardOrder.DescInsertedAt,
      projectIds: [
        window.btoa(
          "SharedProject:" + window.atob(showListInfo.id).split(":")[1]
        ),
      ],
      filter: {
        types: [SharedCardType.Golden],
        fileTypes: [FileType.File],
      },
    };
    getCardList(variables, () => {
      setCurrentPage(1);
      setShowList(true);
    });
  };

  const searchWikiCardList = (searchString) => {
    setSearchString(searchString);

    let {
      pageInfo,
      selectedTags,
      type,
      order,
      after,
      matching,
    } = selectedTagProjectsInfo;

    let info = {
      pageInfo: {
        first: PER_PAGE_COUNT,
        after: undefined,
        before: undefined,
        last: undefined,
      },
      selectedTags,
      type,
      order,
      filter: { types: [type], fileTypes: [FileType.File] },
      matching: searchString === "" ? undefined : searchString,
    };

    getWIKICards(info, () => setCurrentPage(1));
  };

  React.useEffect(() => {
    setIsShowCardDetail(false);
    if (titleIndex === 0) {
      if (showList) {
        if (showListInfo && showListInfo.id) {
          setSearchCallback(searchProjectCardList);
        } else {
          setSearchCallback(searchAllCardList);
        }
      } else {
        setSearchCallback(searchAllCardList);
      }
    } else {
      setSearchCallback(searchWikiCardList);
    }
  }, [showList, titleIndex, selectedTagProjectsInfo]);

  React.useEffect(() => {
    setCardList([]);
    let variables = {
      type: titleIndex === 0 ? CorpusCardType.Golden : CorpusCardType.Wiki,
    };
    setShowList(false);
    projectCategories(variables);
    inspirationMenu(variables);
  }, [titleIndex]);

  const projectCategories = (variables) => {
    setIsLoading(true);
    getProjectCategories(variables).then((res) => {
      setIsLoading(false);
      if (res.data && res.data.projectCategories) {
        let list = res.data.projectCategories;
        let isShowAll = [];
        for (let i in list) {
          isShowAll.push(false);
        }
        setCategoryShowList(isShowAll);
        setCategoryList(list);
      } else {
        console.log("获取数据失败");
      }
    });
  };

  const inspirationMenu = (variables) => {
    getInspirationMenu({ type: "GOLDEN" }).then((res) => {
      if (res.data && res.data.inspirationMenu) {
        setSelectionTags(res.data.inspirationMenu);
        // let list = res.data.categoryStats;
        // let isShowAll = [];
        // for (let i in list) {
        //   isShowAll.push(false);
        // }
        // setCategoryShowList(isShowAll);
        // setCategoryList(list);
      } else {
        console.log("获取数据失败");
      }
    });
  };

  const getFolderCards = (item, callback = null) => {
    setShowList(true);
    let variables = {
      id: item.id,
      code: item.code,
    };
    setShowListInfo({
      id: item.id,
      code: item.code,
      autoGenTags: [item.title],
      categories: ["全部类别"],
      count: 0,
      title: item.title,
      childrenCounts: item.childrenCounts,
      type: item.type,
    });
    fetchFolderCardList(variables, (list) => {
      if (callback) {
        callback(list);
      }
    });
  };

  const fetchFolderCardList = (variables, callback) => {
    let code = variables.code;
    delete variables.code;
    setIsLoading(true);
    getInspirationCardFolder(variables).then((res) => {
      setIsLoading(false);
      if (res.data && res.data.node) {
        setFolderCards(res.data.node.children);
        setCardList(res.data.node.children);

        if (callback) {
          callback(res.data.node.children);
        }

        if (isInit) {
          let pathArray = getUrlPath();
          let path = "/idea/golden";

          if (
            pathArray[P_INDEX] &&
            pathArray[P_INDEX] === "p" &&
            pathArray[P_ID_INDEX] &&
            pathArray[P_ID_INDEX] !== ""
          ) {
            path += `/p/${pathArray[P_ID_INDEX]}`;
          }

          path += `/f/${window.atob(code).split("SharedCard:")[1]}`;
          path += window.location.search;
          window.history.pushState(null, "", path);

          // let searchArray = getUrlSearch();
          // searchArray.packageid = variables.id;
          // let keys = Object.keys(searchArray);
          // let path = "/idea/golden" + (keys.length > 0 ? "?" : "");
          // keys.forEach((item, index) => {
          //   if (index !== keys.length - 1) {
          //     path += `${item}=${searchArray[item]}&`;
          //   } else {
          //     path += `${item}=${searchArray[item]}`;
          //   }
          // });
          // window.history.pushState(null, "", path);
        }
      }
    });
  };

  const getCardList = (variables, callback) => {
    setIsLoading(true);
    getSharedCards(variables).then((res) => {
      setIsLoading(false);
      if (res.data.sharedCards) {
        let pageInfo = res.data.sharedCards.pageInfo;
        let count = res.data.sharedCards.count;
        let datas = res.data.sharedCards.edges.map((item, index) => {
          return item.node;
        });
        setCurrentPageInfo({
          count: count,
          pageInfo: pageInfo,
          pageSize: PER_PAGE_COUNT,
        });
        setCardList(datas);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取数据失败");
      }
    });
  };

  const getCardListForProject = async (variables, callback) => {
    if (variables.id) {
      setIsLoading(true);
      getInspirationProjectDetail(variables).then((res) => {
        setIsLoading(false);
        if (res.data.node) {
          const result = res.data.node.sharedCards;
          if (result) {
            let pageInfo = result.pageInfo;
            let count = result.count;
            let datas = result.edges.map((item, index) => {
              return item.node;
            });
            const fDetail = {
              id: res.data.node.id,
              isWriterFolder: res.data.node.sharedCards.edges[0]
                ? res.data.node.sharedCards.edges[0].node.fileType === "FOLDER"
                : false,
            };
            setFolderDetail(fDetail);
            setCurrentPageInfo({
              count: count,
              pageInfo: pageInfo,
              pageSize: PER_PAGE_COUNT,
            });
            setCardList(datas);
            if (callback) {
              callback(datas);
            }
          } else {
            message.error("获取数据失败");
          }
        } else {
          message.error(convertToZH(res.errors[0]));
        }
      });
    }
  };

  const getIcon = (i) => {
    const icons = [
      WorldWorks,
      WorldWriters,
      Ad,
      Sport,
      Education,
      Travel,
      Social,
      Colorful,
    ];
    return icons[i];
  };

  const getWIKICards = async (info, callback) => {
    setIsLoading(true);
    const lastTitleIndex = titleIndex;
    setCardList([]);
    let { pageInfo, selectedTags, type, order, after, matching } = info;
    if (!selectedTags) {
      console.error("no selected cards", info);
      return;
    }
    if (chosenCate || selectedTags[0]) {
      const projectIds =
        selectedTags[0] && selectedTags[0].value === "all"
          ? chosenCate.map((t) => t.id)
          : selectedTags.map((t) => t.id);
      if (!projectIds.length) return;

      setSelectedTagProjectsInfo(info);

      if (!pageInfo) {
        pageInfo = { first: PER_PAGE_COUNT };
      }

      const variables = {
        ...pageInfo,
        projectIds,
        order,
        matching,
        filter: {
          types: [type],
          fileTypes: [FileType.File],
        },
      };

      const res = await getSharedCards(variables);
      setIsLoading(false);
      if (!res.data || titleIndex !== lastTitleIndex) {
        console.error("wiki cards error");
        return;
      }
      if (res.data.sharedCards.edges) {
        const wikiCards = res.data.sharedCards.edges.map((sc) => sc.node);
        setCardList(wikiCards);
        let pageInfo = res.data.sharedCards.pageInfo;
        let count = res.data.sharedCards.count;
        setCurrentPageInfo({
          count: count,
          pageInfo: pageInfo,
          pageSize: PER_PAGE_COUNT,
        });

        if (callback) {
          callback();
        } else {
          setCurrentPage(1);
        }
      }
    }
  };

  const collectCard = async (item, callback) => {
    setConfirmText("是否添加卡片到素材库");
    setIsShowReconfirmModal(true);
    reconfirmModalCallback = async (isSubmit) => {
      if (isSubmit) {
        const input = {
          ids: [window.atob(item.id).split(":")[1]],
          type: item.type,
        };
        await referenceCards({ variables: { input } });
        message.success("添加成功");
      } else {
        reconfirmModalCallback = null;
      }
      if (callback) callback();
    };
  };

  const referenceProjectReq = async (val) => {
    if (val.children) {
      const input = {
        ids: val.children.map((vc) => window.atob(vc.id).split(":")[1]),
        type: val.type,
      };
      await referenceCards({ variables: { input } });
      return message.success("添加成功");
    }
    const input = { id: window.atob(val.id).split(":")[1] };
    await referenceProject({ variables: { input } });
    return message.success("添加成功");
  };

  const renderProject = () => {
    return (
      <Spin spinning={isLoading}>
        {isInit && (
          <>
            {titleIndex === 0 ? (
              <Projects
                selectionTags={selectionTags}
                showList={showList}
                categoryList={categoryList}
                getIcon={getIcon}
                categoryShowList={categoryShowList}
                setShowList={setShowList}
                searchString={searchString}
                titleIndex={titleIndex}
                setShowListInfo={setShowListInfo}
                getCardList={getCardList}
                getCardListForProject={getCardListForProject}
                setCurrentPage={setCurrentPage}
                setCategoryShowList={setCategoryShowList}
                setSelectedCard={setSelectedCard}
                setIsShowCardDetail={setIsShowCardDetail}
              />
            ) : (
              <CardsInTotal
                getInspirationCardInfo={getInspirationCardInfo}
                confirmText={confirmText}
                isShowReconfirmModal={isShowReconfirmModal}
                setIsShowReconfirmModal={setIsShowReconfirmModal}
                chosenCate={chosenCate}
                setChosenCate={setChosenCate}
                showList={showList}
                setCardList={setCardList}
                getCardList={getCardList}
                getWIKICards={getWIKICards}
                setCurrentPage={setCurrentPage}
                cardList={cardList}
                collectCard={collectCard}
                searchString={searchString}
                getFolderCards={getFolderCards}
                currentPage={currentPage}
                currentPageInfo={currentPageInfo}
                categoryList={categoryList}
                setSelectedCard={setSelectedCard}
                setIsShowCardDetail={setIsShowCardDetail}
              />
            )}
          </>
        )}
      </Spin>
    );
  };

  const renderShowList = () => {
    let isSearchAll = !showListInfo;
    let title = isSearchAll ? searchString : showListInfo.title;
    let count = isSearchAll ? currentPageInfo.count : showListInfo.count;
    return (
      <Spin spinning={isLoading} style={{ marginTop: "100px" }}>
        <div className="inspiration-main-card-title-container">
          <div
            className="inspiration-main-card-title-category"
            onClick={async () => {
              setSearchString("");
              if (!folderCards.length) {
                setShowList(false);
                setCardList([]);
                setFolderDetail([]);

                let searchArray = getUrlSearch();
                if (searchArray.projectid) {
                  delete searchArray.projectid;
                }
                if (searchArray.search) {
                  delete searchArray.search;
                }
                let path = "/idea/golden" + window.location.search;
                window.history.pushState(null, "", path);
                return;
              }
              const variables = {
                id: folderDetail.id,
                type: "GOLDEN",
                order: SharedCardOrder.DescInsertedAt,
                pageInfo: {
                  before: undefined,
                  after: undefined,
                  first: 24,
                },
              };
              setFolderCards([]);
              await getCardListForProject(variables, () => {
                let searchArray = getUrlSearch();
                let pathArray = getUrlPath();
                let path = `/idea/golden/p/${pathArray[P_ID_INDEX]}`;

                if (searchArray.search) {
                  delete searchArray.search;
                }

                let keys = Object.keys(searchArray);
                path += keys.length > 0 ? "?" : "";
                keys.forEach((item, index) => {
                  if (index !== keys.length - 1) {
                    path += `${item}=${searchArray[item]}&`;
                  } else {
                    path += `${item}=${searchArray[item]}`;
                  }
                });
                window.history.pushState(null, "", path);
              });
            }}
          >
            <img
              src={Back}
              className="inspiration-main-card-title-category-back"
            />
          </div>
          <div className="inspiration-main-card-title-tag-text ">{title}</div>
          {!isSearchAll && (
            <div>
              <span className="inspiration-main-reference-project" />
            </div>
          )}
        </div>
        <InsCards
          folderDetail={folderDetail}
          showListInfo={showListInfo}
          confirmText={confirmText}
          isShowReconfirmModal={isShowReconfirmModal}
          setIsShowReconfirmModal={setIsShowReconfirmModal}
          reconfirmModalCallback={reconfirmModalCallback}
          colSize={6}
          getCardList={getCardList}
          getCardListForProject={getCardListForProject}
          setCurrentPage={setCurrentPage}
          cardList={cardList}
          collectCard={collectCard}
          searchString={searchString}
          getFolderCards={getFolderCards}
          currentPage={currentPage}
          currentPageInfo={currentPageInfo}
          setSelectedCard={setSelectedCard}
          setIsShowCardDetail={setIsShowCardDetail}
        />
      </Spin>
    );
  };

  const renderShowCardList = () => {
    return (
      <div className="inspiration-card-detail-container">
        <div className="inspiration-card-detail-title-bar">
          <div className="inspiration-card-list-left-container">
            <img
              src={BackIcon}
              className="inspiration-card-detail-title-bar-back"
              onClick={() => {
                setIsShowCardDetail(false);
                let searchArray = getUrlSearch();
                let pathArray = getUrlPath();
                pathArray.splice(pathArray.length - 2, 2);
                let path = "/" + pathArray.join("/") + window.location.search;
                window.history.pushState(null, "", path);
              }}
            />

            <div className="inspiration-card-detail-title-bar-title-text">
              {selectedCard.title}
            </div>
          </div>
        </div>
        <div className="inspiration-card-container">
          <CorpusCardDetailContainer
            cardInfo={selectedCard}
            canEdit={false}
            collectCard={async (item) => {
              const input = {
                ids: [window.atob(item.id).split(":")[1]],
                type: item.type,
              };
              await referenceCards({ variables: { input } });
              message.success("成功添加卡片到素材库");
            }}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (!isInit) {
      return null;
    }
    if (isShowCardDetail) {
      return renderShowCardList();
    }
    if (showList) {
      return renderShowList();
    }
    return renderProject();
  };

  return renderContent();
};

export default InspirationContainer;
