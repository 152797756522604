import * as React from "react";
import "./aw-check.css";
import "./styles.css";

//引入的其他页面组件
import configs from "../../configs";
import headers from "../../GlobalData/headers";
import WritingTab from "./WritingTab/index";
import WritingOutline from "./WritingOutline/index";
import WritingRightClickMenu from "./WritingRightClickMenu/index";
import WritingCardMode from "./WritingCardMode/index";
import WritingCardList from "./WritingCardMode/newCardList";
// import TuiEditorComponent from "./TuiEditorComponent/index";
import TuiEditorComponent_Ori from "./TuiEditorComponent/index_2";//markdown或者是卡片拼接模式展示用
// import TuiEditorComponent from "./TuiEditorComponent/index_3";
import TuiEditorComponent_New from "./TuiEditorComponent/index_4";//编辑用
import { getOpenPanelCardId } from "../feedbackComponents/FeedbackPanel";
import RightSideBar from "./RightSideBar/index";
import FeedbackModule from "./FeedbackModule";
import Inspiration from "./Inspiration/index";
import Statistics from "./Statistics/index";
import KeyMap from "../../i18n/keymap";
import ReconfirmModal from "../Components/ReconfirmModal";
import WordMetaDropdown from "./WordMeta/wordMetaDropdown";
import WordReplacementDropdown from "./WordReplace/wordReplacementDropdown";
import { getOutlineIcon } from "./WritingOutline/getOutlineIcon";
import DocumentError from "../Components/DocumentError/index";
import * as WritingModuleAction from "../WritingModuleAction/index";
import {
  TimeStampToDate,
  DateToTimeStamp,
  compareDate,
  compareTimeStamp,
} from "../../utils/index";

import {
  findAncestorsIds,
  isInOutline,
  getDataInOutline,
  clearOutlineData,
  copyObject,
  getDatasIds,
  getChildrenIds,
  getOutlineDatas,
  uniq,
} from "./utils";

import { html2Escape } from "./utils/main";

import {
  P_INDEX,
  P_ID_INDEX,
  C_INDEX,
  C_ID_INDEX,
} from "../Router_Project/MyWriting/index";
import { getUrlSearch, getUrlPath } from "../../utils/getUrlInfo";

//引入的库组件
import { message, Spin } from "antd";
import { convertToZH } from "aw-error";
import { uuid } from "uuidv4";
import axios from "axios";
import copy from "copy-to-clipboard";
import moment from "moment";
import download from "downloadjs";
import Draggable from "react-draggable";

//graphql查询
import { useQuery } from "react-apollo-hooks";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import {
  useUpdateProjectMutation,
  useUpdateCardMutation,
  useCreateCardsMutation,
  useGetProjectUpdateTimeQuery,
  useGetCardsUpdateTimeQuery,
  useCheckQuery,
  useCheckTabooQuery,
  useGetCardsQuery,
  useCopyCardsMutation,
  useDeleteCardsMutation,
  useGetSingleCardQuery,
  CardType,
} from "../../generated/graphql";
import {
  GET_CURRENT_PROJECT_ID,
  GET_CURRENT_PROJECT_DATA,
  GET_CARDS_DATA,
  GET_USER_INFO,
  GET_TOOLS_TAB_DATA,
  GET_DEFAULT_SELECTED_NODE,
  SAVE_BUTTON_CLICK,
  ADD_BUTTON_CLICK,
  INSERT_BUTTON_CLICK,
  DELETE_BUTTON_CLICK,
  EXPORT_BUTTON_CLICK,
  SPLIT_BUTTON_CLICK,
} from "./localQuery.js";

//引入的资源图片
import TRASH from "../../assets/trash.png";
import OUTLINE_NODE from "../../assets/card_icon.png";
import OUTLINE_FOLDER from "../../assets/folder_yellow.png";
import NO_CONTENT from "../../assets/no_content.png";
import emptyIcon from "../../assets/writing/empty-icon.png";
import PreviewIcon from "../../assets/writing/preview.png";
import ExitPreviewIcon from "../../assets/writing/exit-preview.png";
import ExitFullScreenIcon from "../../assets/writing/exit-fullscreen.png";
import CloseIcon from "../../assets/globalmodal/close.png";
import BACK from "../../assets/back.png";
import FORWARD from "../../assets/forward.png";
import BaseDB from "../WritingModuleAction/baseDB";

let selectedKeysAncestors = []; //这些变量需要全局的使用，只能用这种方法处理
export const getSelectedKeysAncestors = () => {
  return selectedKeysAncestors;
};

//计算鼠标鼠标右键菜单的相对位置
const getRightMenuPosition = (mousePositionY) => {
  let positionY = mousePositionY;
  let rightMenu = document.getElementById("right-click-menu");
  let body = document.getElementsByClassName("writing-main-container")[0];
  let rightMenuHeight = 0;
  let bodyHeight = 0;
  if (rightMenu) rightMenuHeight = rightMenu.offsetHeight;
  if (body) bodyHeight = body.offsetHeight;
  if (bodyHeight !== 0 && rightMenuHeight !== 0) {
    if (positionY + rightMenuHeight > bodyHeight) {
      if (positionY - rightMenuHeight > 0) {
        positionY = positionY - rightMenuHeight;
      } else {
        positionY -= positionY + rightMenuHeight - bodyHeight + 10;
      }
    }
  }
  return positionY;
};

//查找替换要用到的相关变量。因为这些数据需要实时更新，使用state的话会有异步的问题
let findAndReplaceTotalCount = 0;
let findAndReplaceTagIds = [];
let findAndReplaceTagIndex = -1;

const AUTO_SEND_DATA_TIME = 500;

const GET_CARD_SORD_TYPE = gql`
  {
    cardSordTypeClient @client
  }
`;

const Writing = (props) => {
  const client = useApolloClient();

  const {
    DBUUID,
    isDBLeader,
    isNetworkOnline,
    dbController,
    setShowGlobalSearchModal,
    setToProjectButtonCallback,
    currentProjectCards,
    setCurrentProjectCards,
    currentProjectInfo,
    setCurrentProjectInfo,
    titleSelectedIndex,
    exportCardCallback,
    setTitleSelectedIndex,
    setImportCallback,
    setExportCallback,
    setSearchCallback,
    isResetEditor,
    setIsResetEditor,
    currentSelectCardId,
    setCurrentSelectCardId,
  } = props;

  const { data: cardSordType } = useQuery(GET_CARD_SORD_TYPE);
  const { cardSordTypeClient } = cardSordType;

  const [changeOutlineName, setChangeOutlineName] = React.useState({
    id: "",
    name: "",
  });
  const [testCurrentProjectCards, setTestCurrentProjectCards] = React.useState(
    []
  );
  const [isInit, setIsInit] = React.useState(false); //初始化标识
  const [isShowOutline, setIsShowOutline] = React.useState(true); //是否展开大纲列表
  // const [currentSelectCardId, setCurrentSelectCardId] = React.useState(""); //当前选中的节点ID
  const [outlineChangeNameNode, setOutlineChangeNameNode] = React.useState(""); //当前大纲改名的卡片ID
  const [outlineChangeName, setOutlineChangeName] = React.useState(""); //当前大纲改名的卡片名
  const [nodeDraggable, setNodeDraggable] = React.useState(true); //当前大纲是否可以拖动
  const [isInTrash, setIsInTrash] = React.useState(false); //是否在废纸篓的判定，为了处理右键功能
  const [isProjectNode, setIsProjectNode] = React.useState(false); //是否为根节点的判定，为了处理右键功能
  const [rightClickNode, setRightClickNode] = React.useState(""); //右键点选的卡片ID，为了处理右键功能
  const [cardDraggingKey, setCardDraggingKey] = React.useState(""); //拖动的卡片ID
  const [mouseNotInTheMenu, setMouseNotInTheMenu] = React.useState(true);
  const [isFullScreen, setIsFullScreen] = React.useState(false); //全屏处理
  const [isShowMarkdown, setIsShowMarkDown] = React.useState(false); //是否渲染markdown
  const [hideProofreadingBar, setHideProofreadingBar] = React.useState(true); //侧边栏
  const [blocksToSave, setBlocksToSave] = React.useState([]); //文本存档缓存
  const [editorReset, setEditorReset] = React.useState(false); //编辑器重置
  const [feedbackTabKey, setFeedbackTabKey] = React.useState(1); //检查、校对的标签
  const [feedbackLoading, setFeedbackLoading] = React.useState(false); //检查、校对加载
  const [noMistake, setNoMistake] = React.useState(false); //检查、校对判定
  const [feedbackSubTabKey, setFeedbackSubTabKey] = React.useState(0);
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [showRefreshConfirm, setShowRefreshConfirm] = React.useState(false);
  const [loadingPDF, setLoadingPDF] = React.useState(false);
  const [replacePosition, setReplacePosition] = React.useState([0, 0]); //查找替换窗口的坐标
  const [searchKw, setSearchKw] = React.useState(""); //查找替换的值
  const [matchCurrent, setMatchCurrent] = React.useState(0); //查找替换的数量
  const [matchTotal, setMatchTotal] = React.useState(0); //查找替换的总数
  const [matchKw, setMatchKw] = React.useState(""); //查找替换的值
  const [isAddingCard, setIsAddingCard] = React.useState(false); //为了防止创建太快导致的请求bug，这里做了限制

  //搜索、高亮、标注相关↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  //因为在高亮模式下，现有的tui编辑器只能够使用Wysiwyg模式，在没有找到更好的解决办法前，只能这样手动控制开关了
  const [isWysiwygMode, setIsWysiwygMode] = React.useState(false);
  //采用了第二种方案，保持markdown编辑模式，在标注的时候强制修改dom树，当用户再次点击编辑的时候，自动取消标注
  const [isHighlightAndMarkMode, setIsHighlightAndMarkMode] = React.useState(
    false
  );
  React.useEffect(() => {
    if (isInit && !isHighlightAndMarkMode) {
      setHighlightWords([{ type: "mark-clear-all" }]);
      setHideProofreadingBar(true);
      setFeedbackData([]);
      setShowReplaceModal(false);
    }
  }, [isHighlightAndMarkMode]);

  React.useEffect(() => {
    if (isInit) {
      setHighlightWords([{ type: "mark-clear-all" }]);
      setHideProofreadingBar(true);
      setFeedbackData([]);
      setShowReplaceModal(false);
    }
  }, [isShowMarkdown]);

  /**
   * 这里的hightlight，广泛用于搜索高亮、标注高亮等
   * 具体的信息由数组中的结构定义
   * 具体事例
   * 1.搜索
   {
     type: "search", //用来表示高亮的类型
     className: "aw-search",
     word: ""
   }
   * 2.查找替换
   {
     type: "find-replace",
     className: "aw-search",
     currentIndex: 0, //如果存在多个的话，这个标记当前选中的index
     word: "",
     callback: func //这个回调方法，用来统计查找替换的词一共有多少
   }
   * 3.标注
   {
     type: "mark",
     cardId: id, //卡片id，多卡片拼接模式下，会批量返回所有卡片的id，这样一来，只能根据对应的卡片id来判别了
     className: `aw-mark-${markType}` //标注的样式颜色，是根据给定的颜色值标注的
     start:0, //这里的start和end是根据后端返回的数据定位的，其实充满了奇怪的感觉，但也只能这样了，对吧
     end:2
   }
   */
  const [highlightWords, setHighlightWords] = React.useState([]); //

  React.useEffect(() => {
    // if (highlightWords.length && highlightWords[0].type === "find-replace") {
    //   findAndReplaceTagIndex = 0;
    //   setTimeout(() => {
    //     selectFindAndReplaceTag(findAndReplaceTagIndex);
    //   });
    // }
    if (
      highlightWords.length &&
      (highlightWords[0].type === "mark" ||
        highlightWords[0].type === "mark-word-cloud" ||
        highlightWords[0].type === "mark-word-meta" ||
        highlightWords[0].type === "mark-word-replacement")
    ) {
      setIsWysiwygMode(true);
      setIsHighlightAndMarkMode(true);
      if (highlightWords[0].type !== "mark-word-replacement") {
        setShowReplaceModal(false);
      }
    }
    if (highlightWords.length && highlightWords[0].type === "mark-clear-all") {
      setWordMetaDropdownPosition({
        left: -99999,
        top: -99999,
      });
      setWordReplacementDropdownPosition({
        left: -99999,
        top: -99999,
      });
      setIsWysiwygMode(false);
      setIsHighlightAndMarkMode(false);
      setHighlightWords([]);
      setShowReplaceModal(false);
    }
  }, [highlightWords]);
  //搜索、高亮、标注相关↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  //检查校对相关数据↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const [feedbackType, setFeedbackType] = React.useState("");
  const [feedbackData, setFeedbackData] = React.useState([]);
  const [modifyDatas, setModifyDatas] = React.useState([]);
  React.useEffect(() => {
    if (isInit) {
      setSearchCallback((e) => {
        if (hideProofreadingBar) {
          omitAllMistake();
          makeSearchInfo(e);
        }
      });
      if (!hideProofreadingBar) {
        setShowReplaceModal(false);
      } else {
        omitAllMistake();
      }
      setIsWysiwygMode(!hideProofreadingBar);
      setIsHighlightAndMarkMode(!hideProofreadingBar);
    }
  }, [hideProofreadingBar]);

  React.useEffect(() => {
    if (!hideProofreadingBar) {
      getFeedbackData();
    }
  }, [feedbackTabKey]);

  React.useEffect(() => {
    if (isResetEditor) {
      setHighlightWords([{ type: "mark-clear-all" }]);
      setHideProofreadingBar(true);
      setIsShowMarkDown(false);
      setIsResetEditor(false);
    }
  }, [isResetEditor]);

  React.useEffect(() => {
    setHighlightWords([{ type: "mark-clear-all" }]);
    setHideProofreadingBar(true);
    setFeedbackData([]);
  }, [titleSelectedIndex]);
  //检查校对关数据↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  //查找和替换相关数据↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const [findAndReplaceDatas, setFindAndReplaceDatas] = React.useState([]);
  const [showReplaceModal, setShowReplaceModal] = React.useState(false);
  React.useEffect(() => {
    if (showReplaceModal) {
      omitAllMistake();
      setHideProofreadingBar(true);
      setHighlightWords([]);
      setIsHighlightAndMarkMode(true);
    } else {
      // setHighlightWords([{ type: "mark-clear-all" }]);
    }
  }, [showReplaceModal]);
  //查找和替换相关数据↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  //元话语相关数据↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const [hoverMarkElement, setHoverMarkElement] = React.useState(null);
  const [
    hoverMarkElementOriginClassName,
    setHoverMarkElementOriginClassName,
  ] = React.useState("");
  const [isShowMetaData, setIsShowMetaData] = React.useState(false);
  const [
    wordMetaDropdownPosition,
    setWordMetaDropdownPosition,
  ] = React.useState({ left: -99999, top: -99999 });
  const [wordMetaDropdownData, setWordMetaDropdownData] = React.useState(null);
  //元话语相关数据↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  //替换相关数据↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const [isShowReplaceData, setIsShowReplaceData] = React.useState(false);
  const [
    wordReplacementDropdownPosition,
    setWordReplacementDropdownPosition,
  ] = React.useState({ left: -99999, top: -99999 });
  const [
    wordReplacementDropdownData,
    setWordReplacementDropdownData,
  ] = React.useState(null);
  //替换相关数据↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  const [
    currentSelectCardAndChildrenIds,
    setCurrentSelectCardAndChildrenIds,
  ] = React.useState(null); //当前选中的节点ID，以及其子节点的ID（卡片列表模式用）, 以及其全部子孙节点ID（卡片拼接模式用）
  const [dropNodeInfo, setDropNodeInfo] = React.useState({
    cardToTop: false,
    cardToBottom: false,
    cardDroppedInside: false,
  }); //拖动的卡片信息
  const [mousePosInOutline, setMousePosInOutline] = React.useState({
    x: 0,
    y: 0,
  }); //右键在大纲中的坐标

  const [currentExpandedKeys, setCurrentExpandedKeys] = React.useState(
    currentProjectInfo.outlineState.expandedKeys
  ); //大纲展开的卡片

  //graphql相关的API
  const [updateCardMutation] = useUpdateCardMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();
  const [deleteCardsMutation] = useDeleteCardsMutation();
  const [createCardsMutation] = useCreateCardsMutation();
  const [copyCardsMutation] = useCopyCardsMutation();

  const { refetch: getCardsUpdateTimeQuery } = useGetCardsUpdateTimeQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getProjectUpdateTimeQuery } = useGetProjectUpdateTimeQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getSingleCardQuery } = useGetSingleCardQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: checkQuery } = useCheckQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: checkTabooQuery } = useCheckTabooQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getCardsInfoQuery } = useGetCardsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  //导入导出==================================================
  const fixOutlineData = (outline) => {
    outline.map((item, index) => {
      if (!item.children) {
        item.children = [];
      } else if (item.children.length) {
        fixOutlineData(item.children);
      }
    });
  };

  const fixCardData = (card) => {};

  const importCallback = (
    selectedNode,
    newOutline,
    newCards,
    source_type = "",
    isLastCard
  ) => {
    let pOutline = newOutline;
    let expandedKeysTemp = currentExpandedKeys.map((item) => {
      return item;
    });
    expandedKeysTemp.push(selectedNode);
    fixOutlineData(pOutline.menu);
    updateProject(
      pOutline.menu,
      null,
      uniq(expandedKeysTemp),
      null,
      null,
      () => {
        client.writeData({
          data: {
            projectOutlineClient: JSON.stringify(pOutline),
          },
        });
      },
      !isLastCard
    );
    let currentProjectCardsTemp = copyObject(currentProjectCards);
    let cards = [];
    newCards.forEach((item, index) => {
      let itemTemp = {
        projectId: currentProjectInfo.originalId,
        categories: null,
        content: item.content,
        coordinate: item.coordinate,
        id: item.id,
        insertedAt: null,
        tags: [],
        title: item.title,
        type: item.type,
        code: item.code,
      };
      itemTemp.updatedAt = TimeStampToDate(Date.now());
      fixCardData(itemTemp);
      if (source_type === "html") {
        itemTemp.content = html2Escape(itemTemp.content);
      }
      cards.push(itemTemp);
      currentProjectCardsTemp[itemTemp.id] = itemTemp;
    });
    setCurrentProjectCards(currentProjectCardsTemp);
    setCanWriteCardsToDB(true);
  };

  const exportCallback = () => {
    let exportUrl = configs.exportUrl + "?id=";
    let ids = [];
    if (titleSelectedIndex === 1) {
      const selectedIndex = currentSelectCardId;
      ids = getChildrenIds(
        selectedIndex,
        currentProjectInfo.outline.menu[0].children
      );
    }
    if (!ids.length) return;
    ids.forEach((lf, i) => {
      if (exportUrl.indexOf(lf) < 0) {
        exportUrl += lf + ",";
      }
    });
    exportUrl = exportUrl.substr(0, exportUrl.length - 1);
    const req = {
      method: "GET",
      headers: headers,
    };
    setLoadingPDF(true);
    fetch(exportUrl, req).then(async (response) => {
      setLoadingPDF(false);
      if (response.status === 500) return;
      const pdfBlob = await response.blob();
      download(pdfBlob);
    });
  };

  const importCallbackRef = React.useRef(importCallback);
  importCallbackRef.current = importCallback;

  const exportCallbackRef = React.useRef(exportCallback);
  exportCallbackRef.current = exportCallback;

  /**
   * 接入了RxDB后
   * 所有的数据操作都放在了Web数据库中
   * 这里使用一个定时器，来定时向服务器发送数据
   * 所有的数据操作在这里统一执行吧，不想拆分出来了，毕竟定制化啊，接口啊什么的都是强耦合的
   */
  React.useEffect(() => {
    if (isNetworkOnline && isInit) {
      setCanWriteProjectToDB(true);
      setCanWriteCardsToDB(true);
    }
  }, [isNetworkOnline]);
  const [isLocalDataChange, setIsLocalDataChange] = React.useState(false);
  const [
    isCurrentProjectWriteToDB,
    setIsCurrentProjectWriteToDB,
  ] = React.useState(true);
  const [isCurrentCardsWriteToDB, setIsCurrentCardsWriteToDB] = React.useState(
    true
  );
  const [dbSubscribes, setDBSubscribes] = React.useState([]);
  const [canWriteProjectToDB, setCanWriteProjectToDB] = React.useState(true);
  const [canWriteCardsToDB, setCanWriteCardsToDB] = React.useState(true);

  const writeProjectDataToDB = (projectData) => {
    let data = {};
    data.dbUpdateMark = DBUUID;
    data.id = currentProjectInfo.originalId;
    data.data = JSON.stringify(projectData);
    WritingModuleAction.updateProjectData(data, dbController);
  };

  const writeCardsDataToDB = (cardsData) => {
    let data = {};
    data.dbUpdateMark = DBUUID;
    data.id = currentProjectInfo.originalId;
    data.data = JSON.stringify(cardsData);
    WritingModuleAction.updateCardData(data, dbController);
  };

  const writeDataToDBCallback = async () => {
    let isOldData = false;
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    let currentProjectCardsTemp = copyObject(currentProjectCards);

    //从服务器拉取数据对比当数据是否落后于服务器数据==========
    // if (canWriteProjectToDB && isNetworkOnline) {
    //   let projectInfoFromDB = await WritingModuleAction.getProjectData(
    //     currentProjectInfo.originalId,
    //     dbController
    //   );
    //   if (projectInfoFromDB && projectInfoFromDB !== "") {
    //     projectInfoFromDB = JSON.parse(projectInfoFromDB);

    //     let projectInfoFromServer = await getProjectUpdateTimeQuery({
    //       id: currentProjectInfo.id,
    //     });
    //     if (!projectInfoFromServer.data) {
    //       setCanWriteProjectToDB(false);
    //       setCanWriteCardsToDB(false);
    //       return;
    //     }
    //     projectInfoFromServer = projectInfoFromServer.data.node;
    //     if (
    //       projectInfoFromDB.outlineState.outlineUpdateAt &&
    //       projectInfoFromServer.outlineState.outlineUpdateAt &&
    //       compareDate(
    //         projectInfoFromServer.outlineState.outlineUpdateAt,
    //         projectInfoFromDB.outlineState.outlineUpdateAt
    //       )
    //     ) {
    //       setCanWriteProjectToDB(false);
    //       setCanWriteCardsToDB(false);
    //       setShowRefreshConfirm(true);
    //       return;
    //     }
    //   }
    // }

    // if (canWriteCardsToDB && isNetworkOnline) {
    //   let cardToWrite = {};
    //   Object.keys(currentProjectCardsTemp).forEach((cardId) => {
    //     let card = currentProjectCardsTemp[cardId];
    //     if (card.isLocalData) {
    //       cardToWrite[card.id] = card;
    //     }
    //   });

    //   let cardToWriteIDs = Object.keys(cardToWrite);
    //   if (cardToWriteIDs.length) {
    //     let cardsFromDB = await WritingModuleAction.getCardsData(
    //       currentProjectInfo.originalId,
    //       dbController
    //     );
    //     if (cardsFromDB && cardsFromDB !== "") {
    //       cardsFromDB = JSON.parse(cardsFromDB);
    //       let cardsFromServer = await getCardsUpdateTimeQuery({
    //         filter: { types: [CardType.User] },
    //         first: cardToWriteIDs.length,
    //         ids: cardToWriteIDs.map((item) => {
    //           return window.btoa("Card:" + item);
    //         }),
    //       });
    //       if (!cardsFromServer.data) {
    //         setCanWriteProjectToDB(false);
    //         setCanWriteCardsToDB(false);
    //         return;
    //       }
    //       cardsFromServer = cardsFromServer.data.cards.edges.map((item) => {
    //         return item.node;
    //       });
    //       for (let i = 0; i < cardsFromServer.length; i++) {
    //         let item = cardsFromServer[i];
    //         let cardFromServerUpdatedAt = item.updatedAt;
    //         let cardFromDBUpdatedAt =
    //           cardsFromDB[window.atob(item.id).split("Card:")[1]].updatedAt;
    //         if (
    //           cardFromServerUpdatedAt &&
    //           cardFromDBUpdatedAt &&
    //           DateToTimeStamp(cardFromServerUpdatedAt) -
    //             DateToTimeStamp(cardFromDBUpdatedAt) >
    //             10000
    //         ) {
    //           setCanWriteProjectToDB(false);
    //           setCanWriteCardsToDB(false);
    //           setShowRefreshConfirm(true);
    //           return;
    //         }
    //       }
    //     }
    //   }
    // }
    //==================================================

    if (canWriteProjectToDB) {
      setCanWriteProjectToDB(false);
      //由于后端代码中，只要打开过项目，项目原始updateAt就会被更新，所以这里使用了一个新的能和数据库同步的outlineUpdateAt
      currentProjectInfoTemp.outlineState.outlineUpdateAt = TimeStampToDate(
        Date.now()
      );
      if (isNetworkOnline) {
        let input = {
          id: currentProjectInfoTemp.originalId,
          outline: JSON.stringify(currentProjectInfoTemp.outline),
          outlineState: JSON.stringify(currentProjectInfoTemp.outlineState),
          title: currentProjectInfoTemp.title,
        };
        updateProjectMutation({ variables: { input } });
      }
      writeProjectDataToDB(currentProjectInfoTemp);
    }
    if (canWriteCardsToDB) {
      setCanWriteCardsToDB(false);

      if (isNetworkOnline) {
        Object.keys(currentProjectCardsTemp).forEach((id) => {
          let card = currentProjectCardsTemp[id];
          if (card.isLocalData) {
            delete card.isLocalData;
            let input = {
              id: card.id,
              title: card.title,
              content: card.content,
            };
            updateCardMutation({
              variables: { input },
            });
          }
        });
      }
      writeCardsDataToDB(currentProjectCardsTemp);
    }
  };

  const writeDataToDBCallbackRef = React.useRef(writeDataToDBCallback);
  writeDataToDBCallbackRef.current = writeDataToDBCallback;

  const dbProjectSubscribeCallback = (data) => {
    if (!data || !isInit) {
      return;
    }
    if (data.dbUpdateMark !== DBUUID) {
      let projectData = JSON.parse(data.data);
      setCurrentProjectInfo(projectData);
      setCurrentExpandedKeys(projectData.outlineState.expandedKeys);
    }
  };
  const dbProjectSubscribeCallbackRef = React.useRef(
    dbProjectSubscribeCallback
  );
  dbProjectSubscribeCallbackRef.current = dbProjectSubscribeCallback;

  const dbCardsSubscribeCallback = (data) => {
    if (!data || !isInit) {
      return;
    }
    if (data.dbUpdateMark !== DBUUID) {
      let cardsData = JSON.parse(data.data);
      setCurrentProjectCards(cardsData);
    }
  };
  const dbCardsSubscribeCallbackRef = React.useRef(dbCardsSubscribeCallback);
  dbCardsSubscribeCallbackRef.current = dbCardsSubscribeCallback;

  const initProjectDBSubscribe = () => {
    const projectSub = WritingModuleAction.projectSubscribe(
      currentProjectInfo.originalId,
      dbController,
      (data) => {
        dbProjectSubscribeCallbackRef.current(data);
      }
    );
    const cardsSub = WritingModuleAction.cardsSubscribe(
      currentProjectInfo.originalId,
      dbController,
      (data) => {
        dbCardsSubscribeCallbackRef.current(data);
      }
    );
    const subs = dbSubscribes.map((item) => item);
    subs.push(projectSub);
    subs.push(cardsSub);
    setDBSubscribes(subs);
  };

  React.useEffect(() => {
    if (!currentProjectInfo) {
      setSearchCallback(null);
    }
    //加载完毕更改标签名
    document.title = currentProjectInfo.outline.menu[0].title;
    //加载完毕后做的操作
    setImportCallback(
      (selectedNode, newOutline, newCards, source_type, isLastCard) => {
        let start = Date.now();
        importCallbackRef.current(
          selectedNode,
          newOutline,
          newCards,
          source_type,
          isLastCard
        );
      }
    );
    setExportCallback(() => {
      exportCallbackRef.current();
    });
    setToProjectButtonCallback((card, callback) => {
      toProjectButtonCallbackRef.current(card, callback);
    });
    // let serverDataInterval = setInterval(() => {
    //   sendDataToServerCallbackRef.current();
    // }, AUTO_SEND_DATA_TIME);
    let writeDataToDBInterval = setInterval(() => {
      writeDataToDBCallbackRef.current();
    }, AUTO_SEND_DATA_TIME);

    initSelectLastEditNode();
    setUpShortcuts();
    setIsInit(true);
    return () => {
      // clearInterval(serverDataInterval);
      dbSubscribes.forEach((sub) => sub.unsubscribe());
    };
  }, []);

  React.useEffect(() => {
    if (isInit) {
      initProjectDBSubscribe();
    }
  }, [isInit]);

  React.useEffect(() => {
    if (isInit) {
      resetSelectedCardInfo(currentSelectCardId);
    }
  }, [currentProjectInfo, currentProjectCards]);

  //一些标题栏上按钮的操作================
  //保存
  const { data: saveButton } = useQuery(SAVE_BUTTON_CLICK);
  const { writingTitleBarToolsSaveClickClient } = saveButton;

  React.useEffect(() => {
    if (writingTitleBarToolsSaveClickClient) {
      updateBlocksRef.current(blocksToSave, false, true);
    }
  }, [writingTitleBarToolsSaveClickClient]);

  //添加卡片
  const { data: addButton } = useQuery(ADD_BUTTON_CLICK);
  const {
    writingTitleBarToolsAddClickClient,
    writingTitleBarToolsAddChildClickClient,
  } = addButton;

  React.useEffect(() => {
    if (writingTitleBarToolsAddClickClient) {
      addCardInMenu(true);
      client.writeData({
        data: {
          writingTitleBarToolsAddClickClient: false,
        },
      });
    }
  }, [writingTitleBarToolsAddClickClient]);

  //添加子卡片
  React.useEffect(() => {
    if (writingTitleBarToolsAddChildClickClient) {
      addCardInMenu(false);
      client.writeData({
        data: {
          writingTitleBarToolsAddChildClickClient: false,
        },
      });
    }
  }, [writingTitleBarToolsAddChildClickClient]);

  //插入卡片
  const { data: insertButton } = useQuery(INSERT_BUTTON_CLICK);
  const { writingTitleBarToolsInsertClickClient } = insertButton;

  React.useEffect(() => {
    if (writingTitleBarToolsInsertClickClient) {
      insertCardInCardMode();
      client.writeData({
        data: {
          writingTitleBarToolsInsertClickClient: false,
        },
      });
    }
  }, [writingTitleBarToolsInsertClickClient]);

  //拆分卡片
  const { data: splitButton } = useQuery(SPLIT_BUTTON_CLICK);
  const {
    writingTitleBarToolsSplitClickClient,
    writingTitleBarToolsSplitTypeClient,
  } = splitButton;

  React.useEffect(() => {
    if (writingTitleBarToolsSplitClickClient) {
      if (writingTitleBarToolsSplitTypeClient !== "") {
        splitCard(writingTitleBarToolsSplitTypeClient);
      }
      client.writeData({
        data: {
          writingTitleBarToolsSplitClickClient: false,
          writingTitleBarToolsSplitTypeClient: "",
        },
      });
    }
  }, [writingTitleBarToolsSplitClickClient]);

  //删除卡片
  const { data: deleteButton } = useQuery(DELETE_BUTTON_CLICK);
  const { writingTitleBarToolsDeleteClickClient } = deleteButton;

  React.useEffect(() => {
    if (writingTitleBarToolsDeleteClickClient) {
      deleteCardInMenu(currentSelectCardId);
      client.writeData({
        data: {
          writingTitleBarToolsDeleteClickClient: false,
        },
      });
    }
  }, [writingTitleBarToolsDeleteClickClient]);

  const cardInTrash = () => {
    return (
      currentSelectCardId === "recycle-node" ||
      isInOutline(
        currentSelectCardId,
        currentProjectInfo.outline.menu[1].children
      )
    );
  };

  const shortCutsCallback = (e) => {
    if (!e) {
      return;
    }
    if (KeyMap.SHORTCUTS_FIND_AND_REPLACE(e)) {
      e.preventDefault();
      if (titleSelectedIndex > 0 && hideProofreadingBar)
        setShowReplaceModal(true);
    }
    if (currentSelectCardId) {
      if (KeyMap.SHORTCUTS_SAVE(e)) {
        e.preventDefault();
        updateBlocksRef.current(blocksToSave, false, true);
      }
      if (KeyMap.SHORTCUTS_INSERT_CARD(e)) {
        e.preventDefault();
        if (isNetworkOnline) {
          const buttonInsert = document.getElementById("button-insert");
          if (buttonInsert) buttonInsert.click();
        }
      }
      if (KeyMap.SHORTCUTS_DELETE_CARD(e)) {
        e.preventDefault();
        if (cardInTrash()) {
          if (isNetworkOnline) {
            setShowDeleteConfirm(true);
          }
        } else {
          deleteCardInMenu(currentSelectCardId);
        }
      }
      if (KeyMap.SHORTCUTS_ADD_CARD_IN_SAME_LEVEL(e)) {
        e.preventDefault();
        window.event.returnValue = false;
        if (isNetworkOnline) {
          const buttonAdd = document.getElementById("button-add");
          if (buttonAdd) buttonAdd.click();
        }
      }
      if (KeyMap.SHORTCUTS_ADD_CARD_IN_CHILD(e)) {
        e.preventDefault();
        window.event.returnValue = false;
        if (isNetworkOnline) {
          const buttonAdd = document.getElementById("button-add-child");
          if (buttonAdd) buttonAdd.click();
        }
      }
    }
  };

  const shortCutsCallbackRef = React.useRef(shortCutsCallback);
  shortCutsCallbackRef.current = shortCutsCallback;

  const setUpShortcuts = () => {
    document.onkeydown = (e) => {
      shortCutsCallbackRef.current(e);
    };
  };

  const makeSearchInfo = (e) => {
    if (e && e !== "") {
      setIsWysiwygMode(true);
      setIsHighlightAndMarkMode(true);
      setHighlightWords([
        {
          type: "search",
          className: "aw-search",
          word: e,
        },
      ]);
    } else {
      setIsWysiwygMode(false);
      setIsHighlightAndMarkMode(false);
      setHighlightWords([]);
    }
  };

  // //初始化时选中上一次编辑的卡片
  // const initSelectLastEditNode = () => {
  //   let lastEditNode = currentProjectInfo.outlineState.lastEditNode;
  //   if (lastEditNode && lastEditNode !== "") {
  //     let currentExpandedKeysTemp = currentExpandedKeys.map((item) => {
  //       return item;
  //     });
  //     currentExpandedKeysTemp = currentExpandedKeysTemp.concat(
  //       findAncestorsIds(currentProjectInfo.outline.menu, lastEditNode)
  //     );

  //     onSelect([lastEditNode]);
  //     onExpand(uniq(currentExpandedKeysTemp));
  //     return;
  //   }

  //   let keys = Object.keys(currentProjectCards);
  //   for (let i = keys.length - 1; i--; i >= 0) {
  //     let id = keys[i];
  //     let card = currentProjectCards[id];
  //     if (card) {
  //       let currentExpandedKeysTemp = currentExpandedKeys.map((item) => {
  //         return item;
  //       });
  //       currentExpandedKeysTemp = currentExpandedKeysTemp.concat(
  //         findAncestorsIds(currentProjectInfo.outline.menu, id)
  //       );
  //       onSelect([id]);
  //       onExpand(uniq(currentExpandedKeysTemp));
  //       return;
  //     }
  //   }

  //   onSelect([currentProjectInfo.originalId]);
  // };

  //初始化时选中上一次编辑的卡片
  const initSelectLastEditNode = () => {
    let lastEditNode = currentProjectInfo.outlineState.lastEditNode;
    let card = currentProjectCards[lastEditNode];
    if (lastEditNode && lastEditNode !== "" && card) {
      let currentExpandedKeysTemp = currentExpandedKeys.map((item) => {
        return item;
      });
      currentExpandedKeysTemp = currentExpandedKeysTemp.concat(
        findAncestorsIds(currentProjectInfo.outline.menu, lastEditNode)
      );

      onSelect([lastEditNode]);
      onExpand(uniq(currentExpandedKeysTemp));
      return;
    }

    const getChildrenIds = (children) => {
      if (children[0].children && children[0].children.length) {
        getChildrenIds(children[0].children);
      } else {
        lastEditNode = children[0].id;
      }
    };

    getChildrenIds(currentProjectInfo.outline.menu);
    card = currentProjectCards[lastEditNode];
    if (lastEditNode && lastEditNode !== "" && card) {
      let currentExpandedKeysTemp = currentExpandedKeys.map((item) => {
        return item;
      });
      currentExpandedKeysTemp = currentExpandedKeysTemp.concat(
        findAncestorsIds(currentProjectInfo.outline.menu, lastEditNode)
      );
      onSelect([lastEditNode]);
      onExpand(uniq(currentExpandedKeysTemp));
      return;
    }

    onSelect([currentProjectInfo.originalId]);
  };

  //更换大纲图片
  const changeOutlineIcon = (key) => {
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    getDataInOutline(
      currentProjectInfoTemp.outline.menu,
      currentSelectCardId,
      async (data, item, index) => {
        item.icon = key;
        updateProject(currentProjectInfoTemp.outline.menu, null, null, item.id);
        return;
      }
    );
  };

  const copyCardUrl = () => {
    // let cardCode = window
    //   .atob(currentProjectCards[currentSelectCardId].code)
    //   .split("Card:")[1];
    // console.log("copyCardUrl=============");
    // console.log(currentProjectCards[currentSelectCardId]);
    // console.log(cardCode);
    // console.log(window.location.href);
    // console.log("copyCardUrl=============");
    // let url = window.location.href.split("/c/")[0] + "/c/" + cardCode;
    copy(window.location.href.split("?")[0]);
    message.success("卡片链接已复制到剪贴板");
  };

  const copyCardUrlRef = React.useRef();
  copyCardUrlRef.current = copyCardUrl;

  const copyCard = async (card, callback = null) => {
    const selectedId = currentSelectCardId;
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    let currentProjectCardsTemp = copyObject(currentProjectCards);
    getDataInOutline(
      currentProjectInfoTemp.outline.menu,
      selectedId,
      async (data, item, i) => {
        let newItem = null;
        let cardsCopyTo = [];
        if (card) {
          const newCardId = uuid();
          const newCard = {
            id: newCardId,
            title: card.title,
            tags: card.tags,
            autoGenTags: card.autoGenTags,
            content: card.content,
            projectId: currentProjectInfoTemp.originalId,
            type: "USER",
            canDelete: true,
          };
          newItem = {
            can_delete: true,
            children: [],
            file_type: "folder",
            icon: "icon-default",
            id: newCardId,
            title: card.title,
          };
          cardsCopyTo.push(newCard);
        } else {
          newItem = copyObject(item);
          let newOutlineDatas = getOutlineDatas(newItem, true);
          newOutlineDatas.forEach((newOutlineDatasItem, index) => {
            const newCardId = uuid();
            const newCard = {
              id: newCardId,
              title: currentProjectCards[newOutlineDatasItem.id].title,
              tags: currentProjectCards[newOutlineDatasItem.id].tags,
              autoGenTags:
                currentProjectCards[newOutlineDatasItem.id].autoGenTags,
              content: currentProjectCards[newOutlineDatasItem.id].content,
              projectId: currentProjectInfoTemp.originalId,
              type: "USER",
              canDelete: true,
            };
            newOutlineDatasItem.id = newCardId;
            cardsCopyTo.push(newCard);
          });
        }
        if (selectedId === currentProjectInfoTemp.originalId) {
          currentProjectInfoTemp.outline.menu[0].push(newItem);
        } else {
          data.splice(i + 1, 0, newItem);
        }

        const input = {
          cardsToCreate: cardsCopyTo,
          projectsToUpdate: {
            id: currentProjectInfoTemp.originalId,
            outline: JSON.stringify({
              menu: currentProjectInfoTemp.outline.menu,
            }),
          },
        };

        const res = await copyCardsMutation({ variables: { input } });
        if (!res.data.copyCards.successful) {
          message.error(convertToZH(res.data.copyCards.messages[0]));
        } else {
          let variables = {
            first: cardsCopyTo.length,
            ids: cardsCopyTo.map((item) => {
              return window.btoa("Card:" + item.id);
            }),
            filter: { types: [CardType.User] },
          };
          const cardsRes = await getCardsInfoQuery(variables);
          if (!cardsRes.data.cards || !cardsRes.data.cards.edges.length) {
            message.error("复制失败");
          } else {
            let newCards = [];
            cardsRes.data.cards.edges.map((item, index) => {
              let data = {
                projectId: currentProjectInfo.originalId,
                categories: item.node.categories,
                content: item.node.content,
                coordinate: item.node.coordinate,
                id: window.atob(item.node.id).split("Card:")[1],
                insertedAt: item.node.insertedAt,
                tags: item.node.tags,
                title: item.node.title,
                type: item.node.type,
                code: window.atob(item.node.code).split("Card:")[1],
                updatedAt: item.node.updatedAt,
              };
              currentProjectCardsTemp[data.id] = data;
              newCards.push(data);
            });

            setCurrentProjectInfo(currentProjectInfoTemp);
            setCurrentProjectCards(currentProjectCardsTemp);
            if (callback) {
              callback(res);
            }

            setCanWriteProjectToDB(true);
            setCanWriteCardsToDB(true);
          }
        }
      }
    );
  };

  const copyCardInMenu = () => {
    const selectedId = currentSelectCardId;
    let card = currentProjectCards[selectedId];
    copyCard(null, () => {});
  };

  const toProjectButtonCallback = (card, callback) => {
    copyCard(card, () => {
      callback();
      setShowGlobalSearchModal({
        isShow: false,
        isSearch: false,
      });
    });
  };

  const toProjectButtonCallbackRef = React.useRef(toProjectButtonCallback);
  toProjectButtonCallbackRef.current = toProjectButtonCallback;

  const insertCardInCardMode = () => {
    setShowGlobalSearchModal({
      isShow: true,
      isSearch: true,
    });
    // setToProjectButtonCallback((card, callback) => {
    //   copyCard(card, () => {
    //     callback();
    //     setShowGlobalSearchModal({
    //       isShow: false,
    //       isSearch: false,
    //     });
    //   });
    // });
  };

  const updateCard = async (card, callback = null) => {
    if (!card || !card.id) return;
    //使用了数据库的保存方式=============================

    let currentTime = TimeStampToDate(Date.now());
    let newData = {
      id: card.id,
      updatedAt: currentTime,
      isLocalData: true,
    };

    if (Object.keys(card).indexOf("title") !== -1) {
      newData.title = card.title;
    }
    if (Object.keys(card).indexOf("content") !== -1) {
      newData.content = card.content;
    }
    if (card.revision) {
      newData.isRevision = true;
    }

    let currentProjectCardsTemp = copyObject(currentProjectCards);
    let targetCard = currentProjectCardsTemp[newData.id];
    if (targetCard) {
      Object.keys(newData).forEach((item) => {
        targetCard[item] = newData[item];
      });
    }
    setCurrentProjectCards(currentProjectCardsTemp);
    if (callback) {
      callback();
    }
    setCanWriteCardsToDB(true);
    //==================================================

    //原始的直接调用API的保存方式=============================
    const res = await updateCardMutation({ variables: { input: card } });
    if (!res.data.updateCard.successful) {
      message.error(convertToZH(res.data.updateCard.messages[0]));
    } else {
      if (callback) {
        callback();
      }
    }
    //====================================================
  };

  const updateProject = async (
    menuOutline = null,
    title = null,
    expandedKeys = null,
    lastEditNode = null,
    projectOutline = null,
    callback = null,
    isDirectWriteToDB = false
  ) => {
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    let outlineForIntput = currentProjectInfoTemp.outline;
    let outlineStateForInput = currentProjectInfoTemp.outlineState;
    if (menuOutline) {
      outlineForIntput.menu = clearOutlineData(menuOutline);
    } else {
      outlineForIntput.menu = currentProjectInfoTemp.outline.menu;
    }
    if (expandedKeys) outlineStateForInput.expandedKeys = expandedKeys;
    if (lastEditNode) outlineStateForInput.lastEditNode = lastEditNode;
    const input = {
      id: currentProjectInfoTemp.originalId,
      outline: JSON.stringify(outlineForIntput),
      outlineState: JSON.stringify(outlineStateForInput),
    };
    if (title) {
      input.title = title;
      currentProjectInfoTemp.outline.menu[0].title = title;
      currentProjectInfoTemp.title = title;
    }

    //使用了数据库的保存方式=============================
    currentProjectInfoTemp.updatedAt = TimeStampToDate(Date.now());
    client.writeData({
      data: {
        projectOutlineClient: JSON.stringify(currentProjectInfoTemp.outline),
      },
    });
    setCurrentProjectInfo(currentProjectInfoTemp);
    if (expandedKeys) {
      setCurrentExpandedKeys(expandedKeys);
    }
    if (callback) {
      callback();
    }
    if (isDirectWriteToDB) {
      writeProjectDataToDB(currentProjectInfoTemp);
    } else {
      setCanWriteProjectToDB(true);
    }

    //==================================================

    //原始的直接调用API的保存方式=============================
    // const res = await updateProjectMutation({ variables: { input } });
    // if (!res.data.updateProject.successful) {
    //   message.error(convertToZH(res.data.updateProject.messages[0]));
    // } else {
    //   let pOutline = res.data.updateProject.result.outline;
    //   let pOutlineState = res.data.updateProject.result.outlineState;
    //   client.writeData({
    //     data: {
    //       projectOutlineClient: JSON.stringify(pOutline),
    //     },
    //   });
    //   currentProjectInfoTemp.outline = pOutline;
    //   currentProjectInfoTemp.outlineState = pOutlineState;
    //   if (expandedKeys) {
    //     setCurrentExpandedKeys(expandedKeys);
    //   }
    //   setCurrentProjectInfo(currentProjectInfoTemp);
    //   if (callback) {
    //     callback();
    //   }
    // }
    //==================================================
  };

  //更新卡片内容和大纲内容
  const updateCardAndOutline = (id, title) => {
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    getDataInOutline(
      currentProjectInfoTemp.outline.menu,
      id,
      async (data, item) => {
        item.title = title;
        const isTopNode = id === currentProjectInfoTemp.originalId;

        if (!isTopNode) {
          await updateCard({ id, title }, () => {
            // let currentProjectCardsTemp = copyObject(currentProjectCards);
            // if (currentProjectCardsTemp[id]) {
            //   currentProjectCardsTemp[id].title = title;
            //   setCurrentProjectCards(currentProjectCardsTemp);
            //   setChangeOutlineName({ id: id, title: title });
            // }
          });
        } else {
          client.writeData({
            data: {
              writingChangeProjectNameClickClient: true,
              writingChangeProjectNameClient: title,
            },
          });
        }
        updateProject(
          currentProjectInfoTemp.outline.menu,
          isTopNode ? title : null
        );
        return;
      }
    );
  };

  const onSelect = (selectedKeys, info, tab = 0) => {
    let selectedKey = selectedKeys[0];
    if (!selectedKey || (selectedKey === currentSelectCardId && tab === 0)) {
      return;
    }
    let card = currentProjectCards[selectedKey];
    if (card) {
      toCardUrl(card.code, tab);
    } else {
      toCardUrl(null, tab);
    }
    selectedKeysAncestors = findAncestorsIds(
      currentProjectInfo.outline.menu,
      selectedKey,
      true
    );
    let element = document.getElementById(selectedKey);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
    resetSelectedCardInfo(selectedKey);
    setHideProofreadingBar(true);
    setHighlightWords([{ type: "mark-clear-all" }]);
    setFeedbackData([]);
  };

  const onSelectRef = React.useRef(onSelect);
  onSelectRef.current = onSelect;

  const resetSelectedCardInfo = (selectedKey) => {
    getDataInOutline(
      currentProjectInfo.outline.menu,
      selectedKey,
      (data, item, i) => {
        let selectedCardAllChildrenIds = getDatasIds(item.children);
        let selectedCardCurrentChildrenIds = item.children.map((item) => {
          return item.id;
        });
        let obj = {
          currentId: item.id,
          selectedCardCurrentChildrenIds,
          selectedCardAllChildrenIds,
        };
        setCurrentSelectCardAndChildrenIds(obj);
        if (currentSelectCardId !== selectedKey) {
          setCurrentSelectCardId(selectedKey);
        }
      },
      false
    );
  };

  const toCardUrl = (cardCode, tab) => {
    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();
    let path = "";
    if (cardCode === null) {
      path = `/project/writing/${pathArray[P_INDEX]}/${pathArray[P_ID_INDEX]}`;
    } else {
      if (!pathArray[C_INDEX]) {
        pathArray[C_INDEX] = "c";
      }
      pathArray[C_ID_INDEX] = cardCode;
      path = `/${pathArray.join("/")}`;
    }

    if (tab > 0) {
      searchArray.tab = tab;
      setTitleSelectedIndex(tab);
    } else if (cardCode === null) {
      if (searchArray.tab && parseInt(searchArray.tab) === 2) {
        searchArray.tab = 1;
        setTitleSelectedIndex(1);
      }
    }
    let keys = Object.keys(searchArray);
    path = path + (keys.length > 0 ? "?" : "");
    keys.forEach((item, index) => {
      if (index !== keys.length - 1) {
        path += `${item}=${searchArray[item]}&`;
      } else {
        path += `${item}=${searchArray[item]}`;
      }
    });
    window.history.pushState(null, "", path);
  };

  const mouseCoords = (e) => {
    if (e.pageX || e.pageY) {
      return { x: e.pageX - 60, y: e.pageY - 50 };
    }
    return {
      x: e.clientX + document.body.scrollLeft - document.body.clientLeft,
      y: e.clientY + document.body.scrollTop - document.body.clientTop,
    };
  };

  const showRightMenu = (e) => {
    e.preventDefault();
    const nodeId = e.target.id || e.target.parentNode.id;
    selectedKeysAncestors = findAncestorsIds(
      currentProjectInfo.outline.menu,
      nodeId,
      true
    );
    let isInTrash = false;
    if (
      isInOutline(nodeId, [currentProjectInfo.outline.menu[1]]) ||
      nodeId === "recycle-node"
    ) {
      setIsInTrash(true);
    } else {
      setIsInTrash(false);
    }
    let el = document.getElementById(nodeId);
    if (el) {
      el.click();
    }
    setIsProjectNode(nodeId === currentProjectInfo.originalId);
    const mousePosition = mouseCoords(e);
    setMousePosInOutline(mousePosition);
    setRightClickNode(nodeId);
  };

  const deleteCards = async (input, callback) => {
    const res = await deleteCardsMutation({ variables: { input } });
    if (!res.data.deleteCards.successful) {
      message.error(convertToZH(res.data.deleteCards.messages[0]));
    } else {
      if (callback) {
        callback();
      }
    }
    return res.data.deleteCards.messages;
  };

  const deleteCardsFromProjectCards = async (ids) => {
    let currentProjectCardsTemp = copyObject(currentProjectCards);
    ids.forEach((item) => {
      delete currentProjectCardsTemp[item];
    });
    setCurrentProjectCards(currentProjectCardsTemp);
    setCanWriteCardsToDB(true);
  };

  const deleteCardInTrash = async () => {
    const indexKey = currentSelectCardId;
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    if (indexKey === "recycle-node") {
      if (!currentProjectInfo.outline.menu[1].children.length) return;
      let ids = getDatasIds(currentProjectInfoTemp.outline.menu[1].children);
      currentProjectInfoTemp.outline.menu[1].children = [];
      deleteCards(
        {
          cardsToDelete: ids.map((item) => {
            return { id: item };
          }),
          projectsToUpdate: [
            {
              id: currentProjectInfoTemp.originalId,
              outline: JSON.stringify({
                menu: currentProjectInfoTemp.outline.menu,
              }),
            },
          ],
        },
        () => {
          deleteCardsFromProjectCards(ids);
          setCurrentProjectInfo(currentProjectInfoTemp);
          setCanWriteProjectToDB(true);
        }
      );
      setMousePosInOutline({ x: 0, y: 0 });
      return onSelect(["recycle-node"]);
    }
    getDataInOutline(
      currentProjectInfoTemp.outline.menu[1].children,
      indexKey,
      async (data, item, i) => {
        let ids = getDatasIds([item]);
        data.splice(i, 1);
        await deleteCards(
          {
            cardsToDelete: ids.map((item) => {
              return { id: item };
            }),
            projectsToUpdate: [
              {
                id: currentProjectInfoTemp.originalId,
                outline: JSON.stringify({
                  menu: currentProjectInfoTemp.outline.menu,
                }),
              },
            ],
          },
          () => {
            const recycleDomNode = document.getElementById("recycle-node");
            if (recycleDomNode) recycleDomNode.click();
            setCurrentProjectInfo(currentProjectInfoTemp);
            deleteCardsFromProjectCards(ids);
          }
        );
        setMousePosInOutline({ x: 0, y: 0 });
      }
    );
  };

  const sendCardToTrash = (id) => {
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    let currentExpandedKeysTemp = currentExpandedKeys.map((item) => {
      return item;
    });
    getDataInOutline(
      currentProjectInfoTemp.outline.menu[0].children,
      id,
      async (data, item, i) => {
        currentProjectInfoTemp.outline.menu[1].children.push(item);
        currentExpandedKeysTemp.push("recycle-node");
        currentExpandedKeysTemp = uniq(currentExpandedKeysTemp);
        data.splice(i, 1);
        setMousePosInOutline({ x: 0, y: 0 });
        updateProject(
          currentProjectInfoTemp.outline.menu,
          null,
          currentExpandedKeysTemp
        );
      }
    );
  };

  const deleteCardInMenu = (id) => {
    deleteOneCard(id);
  };

  const deleteOneCard = (id) => {
    if (
      isInOutline(id, currentProjectInfo.outline.menu[1].children) ||
      id === "recycle-node"
    ) {
      return deleteCardInTrash();
    }
    sendCardToTrash(id);
  };

  const onExpand = (expandedKeys, expanded, callback = null) => {
    updateProject(null, null, expandedKeys, null, null, callback);
  };

  const expandAll = () => {
    let start = Date.now();
    let ids = getChildrenIds(
      currentSelectCardId,
      currentProjectInfo.outline.menu,
      true,
      false
    );
    ids = ids.concat(currentExpandedKeys);
    onExpand(uniq(ids), null);
    setMousePosInOutline({ x: 0, y: 0 });
  };

  const unexpandAll = () => {
    let ids = getChildrenIds(
      currentSelectCardId,
      currentProjectInfo.outline.menu,
      true,
      false
    );
    ids = currentExpandedKeys.filter((item) => {
      return ids.indexOf(item) === -1;
    });
    setMousePosInOutline({ x: 0, y: 0 });
    onExpand(ids, null);
  };

  //添加卡片
  const createCard = (sameLevel, callback) => {
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    const newCardId = uuid();
    const card = {
      id: newCardId,
      projectId: currentProjectInfoTemp.originalId,
      title: moment()
        .utc(8)
        .format("YYYYMMDDhhmmss"),
      type: "USER",
      content: "",
      canDelete: true,
    };
    getDataInOutline(
      currentProjectInfoTemp.outline.menu,
      currentSelectCardId,
      async (data, item, i) => {
        item.children.push({
          title: card.title,
          id: card.id,
          children: [],
          can_delete: true,
          icon: "icon-default",
        });
        if (!sameLevel) {
          let currentExpandedKeysTemp = currentExpandedKeys.map((item) => {
            return item;
          });
          currentExpandedKeysTemp.push(item.id);
          currentExpandedKeysTemp = uniq(currentExpandedKeysTemp);
          currentProjectInfoTemp.outlineState.expandedKeys = currentExpandedKeysTemp;
        }
        currentProjectInfoTemp.outlineState.lastEditNode = newCardId;

        const input = {
          cardsToCreate: [card],
          projectsToUpdate: [
            {
              id: currentProjectInfo.originalId,
              outline: JSON.stringify(currentProjectInfoTemp.outline),
              outlineState: JSON.stringify(currentProjectInfoTemp.outlineState),
            },
          ],
        };

        const res = await createCardsMutation({ variables: { input } });
        if (!res.data.createCards.successful) {
          message.error(convertToZH(res.data.createCards.messages[0]));
        } else {
          const cardRes = await getSingleCardQuery({
            id: window.btoa("Card:" + newCardId),
          });
          if (cardRes.data.node) {
            let card = {
              projectId: currentProjectInfo.originalId,
              categories: cardRes.data.node.categories,
              content: cardRes.data.node.content,
              coordinate: cardRes.data.node.coordinate,
              id: window.atob(cardRes.data.node.id).split("Card:")[1],
              insertedAt: cardRes.data.node.insertedAt,
              tags: cardRes.data.node.tags,
              title: cardRes.data.node.title,
              type: cardRes.data.node.type,
              code: window.atob(cardRes.data.node.code).split("Card:")[1],
              updatedAt: cardRes.data.node.updatedAt,
            };
            let currentProjectCardsTemp = copyObject(currentProjectCards);
            currentProjectCardsTemp[card.id] = card;

            setCurrentExpandedKeys(
              currentProjectInfoTemp.outlineState.expandedKeys
            );
            setCurrentProjectInfo(currentProjectInfoTemp);
            setCurrentProjectCards(currentProjectCardsTemp);
            setTimeout(() => {
              onSelectRef.current([card.id]);
            });

            if (callback) {
              callback();
            }

            setCanWriteProjectToDB(true);
            setCanWriteCardsToDB(true);
          }
        }
      },
      sameLevel
    );
  };

  const addCardInCardMode = (sameLevel) => {
    if (!isAddingCard) {
      setIsAddingCard(true);
      createCard(sameLevel, () => {
        setIsAddingCard(false);
        message.success("添加卡片成功");
      });
    } else {
      message.warning("请等待上一张创建完成");
    }
  };

  const addCardInCardModeRef = React.useRef(addCardInCardMode);
  addCardInCardModeRef.current = addCardInCardMode;

  const addCardInMenu = (sameLevel) => {
    addCardInCardModeRef.current(sameLevel);
    setMousePosInOutline({ x: 0, y: 0 });
  };

  //拆分卡片
  const splitCard = (splitType) => {
    const nodeToSplit = currentSelectCardId;

    const parentNode = findAncestorsIds(
      currentProjectInfo.outline.menu,
      nodeToSplit,
      false
    )[0];
    let url = configs.split;
    let postConfig = {
      headers: headers,
    };
    let formData = {
      project_id: currentProjectInfo.originalId,
      parent_node_id: parentNode,
      node_id: nodeToSplit,
      by: splitType,
    };
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    let currentProjectCardsTemp = copyObject(currentProjectCards);
    axios.post(configs.split, formData, postConfig).then(async (res) => {
      if (res.data.successful) {
        let result = res.data.result;
        let newOutline = result.outline;
        let newCards = result.cards;
        let pOutline = newOutline;

        fixOutlineData(pOutline.menu);
        updateProject(pOutline.menu, null, null, null, null, null, true);
        newCards.forEach((item, index) => {
          fixCardData(item);
          item.updatedAt = TimeStampToDate(Date.now());
          item.projectId = currentProjectInfo.originalId;
          currentProjectCardsTemp[item.id] = item;
        });
        setCurrentProjectCards(currentProjectCardsTemp);
        setCanWriteCardsToDB(true);
      } else {
        message.error("卡片拆分失败");
      }
    });
  };

  const onDragEnter = (enterObj) => {
    setCurrentExpandedKeys(enterObj.expandedKeys);
  };

  const onDrop = async (info) => {
    setMousePosInOutline({ x: 0, y: 0 });
    let {
      dropKey,
      dragKey,
      dropPosition,
      dropNodeHasChildren,
      dropIntoFolder,
      dropNodeExpanded,
    } = info;

    if (
      dragKey === "recycle-node" ||
      dragKey === currentProjectInfo.originalId
    ) {
      return;
    }

    let currentProjectInfoBackup = copyObject(currentProjectInfo);
    let currentProjectInfoTemp = copyObject(currentProjectInfo);
    let expandedKeysTemp = currentExpandedKeys.map((item) => {
      return item;
    });

    let dragItem = null;
    let dragItemArray = [];
    getDataInOutline(
      currentProjectInfoTemp.outline.menu,
      dragKey,
      (data, item, i) => {
        dragItem = item;
        dragItemArray = data;
      }
    );

    let dropItem = null;
    let dropItemArray = [];
    getDataInOutline(
      currentProjectInfoTemp.outline.menu,
      dropKey,
      (data, item, i) => {
        dropItem = item;
        dropItemArray = data;
      }
    );

    if (!dragItem || !dropItem) {
      return;
    }

    if (
      !(
        (dropItem.id === currentProjectInfoTemp.originalId ||
          dropItem.id === "recycle-node") &&
        !dropIntoFolder
      )
    ) {
      let dragItemIndex = dragItemArray.indexOf(dragItem);
      dragItemArray.splice(dragItemIndex, 1);
      if (dropIntoFolder) {
        dropItem.children.unshift(dragItem);
        expandedKeysTemp.push(dropItem.id);
        expandedKeysTemp = uniq(expandedKeysTemp);
      } else {
        let dropItemIndex = dropItemArray.indexOf(dropItem);
        if (dropPosition === -1) {
          //放在上方
          dropItemArray.splice(dropItemIndex, 0, dragItem);
        } else {
          dropItemArray.splice(dropItemIndex + 1, 0, dragItem);
        }
      }

      currentProjectInfoTemp.outlineState.expandedKeys = expandedKeysTemp;
      currentProjectInfoTemp.outlineState.lastEditNode = dragKey;

      const input = {
        id: currentProjectInfoTemp.originalId,
        outline: JSON.stringify(currentProjectInfoTemp.outline),
        outlineState: JSON.stringify(currentProjectInfoTemp.outlineState),
      };

      //使用了数据库的保存方式=============================
      currentProjectInfoTemp.updatedAt = TimeStampToDate(Date.now());
      client.writeData({
        data: {
          projectOutlineClient: JSON.stringify(currentProjectInfoTemp.outline),
        },
      });
      setCurrentExpandedKeys(expandedKeysTemp);
      setCurrentProjectInfo(currentProjectInfoTemp);
      setCanWriteProjectToDB(true);
      //==================================================

      //使用了原始的API的保存方式=============================
      // const res = await updateProjectMutation({ variables: { input } });
      // if (!res.data.updateProject.successful) {
      //   message.error(convertToZH(res.data.updateProject.messages[0]));
      //   setCurrentProjectInfo(currentProjectInfoBackup);
      // } else {
      //   let pOutline = res.data.updateProject.result.outline;
      //   let pOutlineState = res.data.updateProject.result.outlineState;
      //   client.writeData({
      //     data: {
      //       projectOutlineClient: JSON.stringify(pOutline),
      //     },
      //   });
      //   currentProjectInfoTemp.outline = pOutline;
      //   currentProjectInfoTemp.outlineState = pOutlineState;
      //   setCurrentProjectInfo(currentProjectInfoTemp);
      //   setCurrentExpandedKeys(expandedKeysTemp);
      // }
      //==================================================
    }
  };

  // const dropInfo = {
  //   dropKey: '放去的元素（文件夹或者卡片）',
  //   dragKey: '拿起来的卡',
  //   dropPosition: '放在元素上方还是下方',
  //   dropNodeHasChildren: '确定元素是否有子集，（决定把卡片放在元素平级位置，还是放进它子集',
  //   dropNodeExpanded: '文件夹是否展开，（检查expandedKeys里有dropKey',
  //   dropIntoFolder: '是否直接扔进卡片/文件夹'
  // };
  const onDropWithinMenu = (info) => {
    if (!info.node) return;
    const dropPos = info.node.props.pos.split("-");
    const dropInfo = {
      dropKey: info.node.props.eventKey,
      dragKey: info.dragNode.props.eventKey,
      dropPosition: info.dropPosition - Number(dropPos[dropPos.length - 1]),
      dropNodeHasChildren: (info.node.props.children || []).length > 0,
      dropIntoFolder: !info.dropToGap,
      dropNodeExpanded: info.node.props.expanded,
    };
    onDrop(dropInfo);
  };

  const getCurrentCardInfoForWritingTab = () => {
    let info = {
      cardCount: 0,
      contentLength: 0,
      title: "",
      icon: getOutlineIcon("icon-default"),
    };
    if (currentSelectCardAndChildrenIds) {
      let cardCount = 0;
      if (cardSordTypeClient === 0) {
        cardCount =
          currentSelectCardAndChildrenIds.selectedCardCurrentChildrenIds.length;
      } else {
        if (
          currentProjectInfo.originalId ===
            currentSelectCardAndChildrenIds.currentId ||
          currentSelectCardAndChildrenIds.currentId === "recycle-node"
        ) {
          cardCount =
            currentSelectCardAndChildrenIds.selectedCardAllChildrenIds.length;
        } else {
          cardCount =
            currentSelectCardAndChildrenIds.selectedCardAllChildrenIds.length +
            1;
        }
      }

      let cardInfo =
        currentProjectCards[currentSelectCardAndChildrenIds.currentId];
      let outlineData = null;
      getDataInOutline(
        currentProjectInfo.outline.menu,
        currentSelectCardAndChildrenIds.currentId,
        (data, item, index) => {
          outlineData = item;
        }
      );
      if (currentSelectCardId === currentProjectInfo.originalId) {
        info = {
          icon: getOutlineIcon("icon-folder"),
          cardCount,
          contentLength: 0,
          title: currentProjectInfo.title,
        };
      } else if (currentSelectCardId === "recycle-node") {
        info = {
          icon: getOutlineIcon("icon-recycle"),
          cardCount,
          contentLength: 0,
          title: "废纸篓",
        };
      } else {
        let icon = outlineData.icon;
        if (
          outlineData.icon === "icon-default" &&
          outlineData.children &&
          outlineData.children.length
        ) {
          icon = "icon-default-multi";
        }
        info = {
          icon: getOutlineIcon(icon),
          cardCount,
          contentLength: cardInfo.content.length,
          title: cardInfo.title,
        };
      }
    }

    return info;
  };

  //全屏相关代码↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const setFullScreen = (isShow) => {
    if (isShow) {
      let isNoEditorMode = false;
      let card = null;
      if (
        currentSelectCardId === currentProjectInfo.originalId ||
        currentSelectCardId === "recycle-node"
      ) {
        isNoEditorMode = true;
      } else {
        card = currentProjectCards[currentSelectCardId];
        if (!card) {
          isNoEditorMode = true;
        }
      }
      if (isNoEditorMode) {
        message.error("当前状态无法全屏");
        return;
      }

      requestFullScreen();
      setHighlightWords([{ type: "mark-clear-all" }]);
      setHideProofreadingBar(true);
    } else {
      exitFullScreen();
    }
    setIsFullScreen(isShow);
  };

  const fullScreenListener = (e) => {
    if (!document.fullscreenElement) {
      setIsFullScreen(false);
    }
  };

  const requestFullScreen = () => {
    let dom = document.getElementById("fullScreenContainer");
    if (dom) {
      if (dom.requestFullscreen) {
        dom.requestFullscreen();
      } else if (dom.mozRequestFullScreen) {
        dom.mozRequestFullScreen();
      } else if (dom.webkitRequestFullScreen) {
        dom.webkitRequestFullScreen();
      }
      document.addEventListener("fullscreenchange", fullScreenListener);
    }
  };

  const exitFullScreen = () => {
    let dom = document.getElementById("fullScreenContainer");
    if (dom) {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      // setHideProofreadingBar(true);
      // setHideMenuBar(true);
      document.removeEventListener("fullscreenchange", fullScreenListener);
    }
  };
  //全屏相关代码↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  //错误处理、高亮相关代码↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const correctMistake = async (mistake) => {
    let mistakeElement = document.getElementById(mistake.mId);
    const mistakePanel = document.getElementById(mistake.mId + "-panel");
    mistakePanel.style.display = "none";
    mistakeElement.outerHTML = `<span>${mistake.advice}</span>`;
    setModifyDatas([mistake]);
  };

  const correctAllMistake = () => {
    if (!feedbackData.length) return;
    const mistakeCards = {};
    feedbackData.forEach((fbd) => {
      const mistakeElement = document.getElementById(fbd.mId);
      if (mistakeElement) {
        mistakeElement.outerHTML = fbd.advice || fbd.wrong;
        mistakeCards[fbd.cardId] = 1;
      }
    });
    setModifyDatas(JSON.parse(JSON.stringify(feedbackData)));
    setFeedbackData([]);
  };

  const omitMistake = (mistake) => {
    const elementToOmit = document.getElementById(mistake.mId);
    elementToOmit.outerHTML = `<span>${mistake.wrong}</span>`;
    const mistakePanel = document.getElementById(mistake.mId + "-panel");
    mistakePanel.style.display = "none";
  };

  const omitAllMistake = () => {
    if (!feedbackData.length) return;
    feedbackData.forEach((fbd) => {
      const mistakeElement = document.getElementById(fbd.mId);
      if (mistakeElement) {
        mistakeElement.outerHTML = mistakeElement.innerText;
      }
    });
    setFeedbackData([]);
  };

  const setProofReadingBar = (setType) => {
    if (setType === "backward") {
      setFeedbackData([]);
    } else {
      setHighlightWords([]);
    }
    showProofreading();
  };

  const showProofreading = () => {
    setHideProofreadingBar(!hideProofreadingBar);
  };

  const getFeedbackData = async (isReCheck) => {
    omitAllMistake();
    const ids = [];
    let wordCount = "";
    let cardList = [];
    if (
      currentSelectCardAndChildrenIds.currentId !==
      currentProjectInfo.originalId
    ) {
      cardList = [
        currentProjectCards[currentSelectCardAndChildrenIds.currentId],
      ];
    }
    if (titleSelectedIndex === 3) {
      cardList = cardList.concat(
        currentSelectCardAndChildrenIds.selectedCardAllChildrenIds.map(
          (item) => {
            return currentProjectCards[item];
          }
        )
      );
    }

    if (!cardList.length) {
      return;
    }

    cardList.forEach((item) => {
      ids.push(window.btoa(`Card:${item.id}`));
      wordCount += item.content.length;
    });

    if (wordCount.length > 50000) {
      message.error("总数超过50000字了，不能检查了");
      return;
    }

    if (!wordCount) {
      setNoMistake(true);
      setFeedbackData([]);
      return;
    }

    setFeedbackLoading(true);

    const checkRes =
      feedbackTabKey == 1
        ? await checkQuery({ ids })
        : await checkTabooQuery({ ids });
    setFeedbackLoading(false);
    if (!checkRes.data) return;

    const result =
      feedbackTabKey == 1
        ? checkRes.data.check.mistakes
        : checkRes.data.checkTaboo.taboos;
    if (!result.length) {
      setNoMistake(true);
      setFeedbackData([]);
      return;
    }

    result.forEach((m, i) => {
      m.mId = `${m.cardId}&&&${i}-${feedbackTabKey == 1 ? "check" : "style"}`;
    });
    setFeedbackType(feedbackTabKey == 1 ? "check" : "style");
    setFeedbackData(result);
    setNoMistake(false);
  };

  const getCurrentCardIds = (isBase64 = true) => {
    let ids = [];
    if (currentSelectCardAndChildrenIds) {
      ids = [currentSelectCardAndChildrenIds.currentId];
      if (titleSelectedIndex === 3) {
        ids = ids.concat(
          currentSelectCardAndChildrenIds.selectedCardAllChildrenIds
        );
      }
    }
    return isBase64
      ? ids.map((item) => {
          return window.btoa("Card:" + item);
        })
      : ids;
  };
  //错误处理、高亮相关代码↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  //保存相关代码↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const updateBlocks = (blocks, isRevision = false, isClickSave = false) => {
    client.writeData({
      data: {
        writingTitleBarToolsSaveClickClient: false,
      },
    });
    if (blocks.length) {
      let isContentSave = false;
      blocks.forEach((blockItem, blockIndex) => {
        const { id, text, type } = blockItem.data;
        let cardInPool = currentProjectCards[id];
        if (cardInPool.content !== text) {
          isContentSave = true;
          let variable = {
            id,
            content: text,
            revision: isRevision ? true : undefined,
          };
          updateCard(variable, () => {});
          updateProject(null, null, null, id);
        }
      });
      if (isContentSave) {
        return;
      }
    }
    updateProject(
      currentProjectInfo.outline.menu,
      null,
      null,
      null,
      null,
      () => {
        client.writeData({
          data: {
            writingTitleBarToolsSaveTimeClient: moment().format("HH:mm:ss"),
          },
        });
        if (isClickSave) {
          message.success("保存成功");
        }
      }
    );
  };

  const updateBlocksRef = React.useRef(updateBlocks);
  updateBlocksRef.current = updateBlocks;

  const saveCallback = (savedData, isOnFocus = false) => {
    const mistakeTags = document.getElementsByClassName("replace-tags");
    const { blocks } = savedData;
    if (!blocks.length) return;
    setBlocksToSave(blocks);
    if (isInit) {
      updateBlocksRef.current(blocks, isOnFocus);
    }
    // if (isOnFocus) {
    //   let card = {
    //     id: blocks[0].data.id,
    //     revision: true,
    //   };
    //   updateCard(card);
    // }
  };
  //保存相关代码↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  //查找相关代码↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  const handleDrag = (e, ui) => {
    setReplacePosition([ui.x, ui.y]);
  };

  const findAndReplace_find = () => {
    let word = searchKw;
    if (word !== "") {
      let obj = {
        type: "find-replace",
        className: "aw-replace",
        word,
        callback: (totalCount, cardIds) => {
          findAndReplaceTagIds = [];
          findAndReplaceTagIds = findAndReplaceTagIds.concat(cardIds);
          findAndReplaceTotalCount = totalCount;
          findAndReplaceTagIndex = 0;
          selectFindAndReplaceTag(findAndReplaceTagIndex);
        },
      };
      setHighlightWords([obj]);
    } else {
      setHighlightWords([]);
    }
  };

  const findAndReplace_findRef = React.useRef(findAndReplace_find);
  findAndReplace_findRef.current = findAndReplace_find;

  const findAndReplace_forward = () => {
    if (findAndReplaceTagIds.length) {
      let dom = null;
      unselectFindAndReplaceTag(findAndReplaceTagIndex);
      while (dom === null) {
        if (--findAndReplaceTagIndex < 0) {
          findAndReplaceTagIndex = findAndReplaceTotalCount - 1;
        }
        let id = findAndReplaceTagIds[findAndReplaceTagIndex].id;
        dom = document.getElementById(id);
      }
      selectFindAndReplaceTag(findAndReplaceTagIndex);
    }
  };

  const findAndReplace_forwardRef = React.useRef(findAndReplace_forward);
  findAndReplace_forwardRef.current = findAndReplace_forward;

  const findAndReplace_next = () => {
    if (findAndReplaceTagIds.length) {
      let dom = null;
      unselectFindAndReplaceTag(findAndReplaceTagIndex);
      while (!dom) {
        if (++findAndReplaceTagIndex >= findAndReplaceTotalCount) {
          findAndReplaceTagIndex = 0;
        }
        let id = findAndReplaceTagIds[findAndReplaceTagIndex].id;
        dom = document.getElementById(id);
      }
      selectFindAndReplaceTag(findAndReplaceTagIndex);
    }
  };

  const findAndReplace_nextRef = React.useRef(findAndReplace_next);
  findAndReplace_nextRef.current = findAndReplace_next;

  const findAndReplace_replace_next = () => {
    if (findAndReplaceTagIds.length) {
      let currentTagInfo = findAndReplaceTagIds[findAndReplaceTagIndex];
      let replaceStr = matchKw;

      let data = {
        cardId: currentTagInfo.cardId,
        start: currentTagInfo.start,
        end: currentTagInfo.end,
        replace: replaceStr,
      };
      unselectFindAndReplaceTag(findAndReplaceTagIndex);
      let dom = document.getElementById(
        findAndReplaceTagIds[findAndReplaceTagIndex].id
      );
      if (dom) {
        dom.style.background = "unset";
        dom.innerHTML = replaceStr;
      }
      findAndReplaceTagIds.forEach((item, index) => {
        if (index > findAndReplaceTagIndex) {
          let charChangeCount = replaceStr.length - item.targetWord.length;
          item.start += charChangeCount;
          item.end += charChangeCount;
        }
      });
      findAndReplaceTagIds.splice(findAndReplaceTagIndex, 1);
      findAndReplaceTotalCount--;
      if (findAndReplaceTagIndex >= findAndReplaceTotalCount) {
        findAndReplaceTagIndex = findAndReplaceTotalCount - 1;
      }
      selectFindAndReplaceTag(findAndReplaceTagIndex);
      setFindAndReplaceDatas([data]);
    }
  };

  const findAndReplace_replace_nextRef = React.useRef(
    findAndReplace_replace_next
  );
  findAndReplace_replace_nextRef.current = findAndReplace_replace_next;

  const selectFindAndReplaceTag = (index) => {
    if (findAndReplaceTagIds.length) {
      let id = findAndReplaceTagIds[index].id;
      let dom = document.getElementById(id);
      if (dom) {
        dom.className = "aw-replace-selected";
        // dom.scrollIntoView({
        //   behavior: "smooth",
        //   block: "center",
        //   inline: "center",
        // });
      }
    }
  };

  const unselectFindAndReplaceTag = (index) => {
    if (findAndReplaceTagIds.length) {
      let id = findAndReplaceTagIds[index].id;
      let dom = document.getElementById(id);
      if (dom) {
        dom.className = "aw-replace";
      }
    }
  };
  //查找相关代码↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  const getCardList = (isWithChildren = false) => {
    let list = [];
    if (currentSelectCardAndChildrenIds) {
      if (isWithChildren) {
        if (currentSelectCardAndChildrenIds.selectedCardAllChildrenIds.length) {
          list = currentSelectCardAndChildrenIds.selectedCardAllChildrenIds.map(
            (item) => {
              return currentProjectCards[item];
            }
          );
        }
        if (currentProjectCards[currentSelectCardAndChildrenIds.currentId]) {
          list.unshift(
            currentProjectCards[currentSelectCardAndChildrenIds.currentId]
          );
        }
      } else {
        if (
          currentSelectCardAndChildrenIds.selectedCardCurrentChildrenIds.length
        ) {
          list = currentSelectCardAndChildrenIds.selectedCardCurrentChildrenIds.map(
            (item) => {
              return currentProjectCards[item];
            }
          );
        }
      }
    }
    return list;
  };

  const getRenderCard = () => {
    if (!currentSelectCardAndChildrenIds) {
      return [];
    }
    let isNoEditorMode = false;
    let card = null;
    if (
      currentSelectCardId === currentProjectInfo.originalId ||
      currentSelectCardId === "recycle-node"
    ) {
      isNoEditorMode = true;
    } else {
      card = currentProjectCards[currentSelectCardId];
      if (!card) {
        isNoEditorMode = true;
      }
    }

    if (isNoEditorMode && titleSelectedIndex === 2) {
      return [];
    }

    let renderCard = [];
    if (titleSelectedIndex === 3) {
      if (card) {
        renderCard = [card];
      }
      currentSelectCardAndChildrenIds.selectedCardAllChildrenIds.forEach(
        (item) => {
          let card = currentProjectCards[item];
          if (card) {
            renderCard.push(card);
          }
        }
      );
    } else {
      renderCard = [card];
    }

    return renderCard.map((item) => {
      return {
        data: {
          text: item.content,
          title: item.title,
          id: item.id,
          type: "content",
        },
        key: item.id,
        type: "paragraph",
      };
    });
  };

  const renderCardList = () => {
    return (
      <WritingCardMode
        getMatchedCards={() => {
          /*getMatchedCards**/
        }}
        setSelectedTitle={() => {
          /*setSelectedTitle**/
        }}
        currentProjectInfo={currentProjectInfo}
        selectedNode={currentSelectCardId}
        cardState={currentProjectCards}
        updateCardAndOutline={updateCardAndOutline}
        setNodeToChange={() => {
          /*setNodeToChange**/
        }}
        loopData={null /*loopData**/}
        onSelect={(selectedKeys, info, tab = 0) => {
          onSelectRef.current(selectedKeys, info, tab);
        }}
        items={getCardList()}
        itemsWithChildren={getCardList(true)}
        onSortEnd={() => {
          /*onCardSortEnd**/
        }}
        onSortStart={() => {
          /*onCardSortStart**/
        }}
        onCheckCardIsSelected={() => {
          /*onCheckCardIsSelected**/
        }}
        onSelectedCards={() => {
          /*onSelectedCards**/
        }}
        refreshCardData={() => {
          /*refreshCardData**/
        }}
      />
    );
  };

  //退出大纲改名的回调
  const exitTitleInput = (key, title) => {
    setNodeDraggable(true);
    const changeNameValid =
      outlineChangeName &&
      outlineChangeName.trim() &&
      outlineChangeName !== title;
    if (changeNameValid) {
      setOutlineChangeName("");
      setOutlineChangeNameNode("");
      updateCardAndOutline(key, outlineChangeName);
    } else {
      setOutlineChangeName("");
      setOutlineChangeNameNode("");
    }
    // setTimeout(() => {
    //   const selectedKeyNode = document.getElementById(currentSelectCardId);
    //   if (selectedKeyNode) selectedKeyNode.click();
    // }, 100);
  };

  //因为涉及到大纲改名等一系列操作，这里把大纲节点的渲染单独提出来
  const treeNodeInput = (props) => {
    const { title, id, children, icon } = props;
    let src = "";
    if (id === "recycle-node") {
      src = TRASH;
    } else if (id === currentProjectInfo.originalId) {
      src = OUTLINE_FOLDER;
    } else {
      if (icon !== "icon-default") {
        src = getOutlineIcon(icon);
      } else {
        if (children.length) {
          src = getOutlineIcon("icon-default-multi");
        } else {
          src = getOutlineIcon(icon);
        }
      }
    }
    if (outlineChangeNameNode === id) {
      return (
        <div
          key={"tree-node-input-" + id}
          className="writing-outline-tree-node-span"
        >
          <img src={src} style={{ width: 14, height: 14, marginLeft: 5 }} />
          <input
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={id}
            className="writing-outline-tree-node-input"
            id={id}
            value={outlineChangeName}
            autoFocus
            onFocus={(e) => {
              //获取焦点后自动全选
              e.target.select();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                exitTitleInput(id, title);
              }
            }}
            onBlur={() => exitTitleInput(id, title)}
            onChange={(e) => setOutlineChangeName(e.target.value)}
          />
        </div>
      );
    }
    return (
      <div
        id={id}
        key={"tree-node-" + id}
        className="writing-module-tree-node-span"
        // PLAY
        onDoubleClick={() => {
          if (id === "recycle-node" || outlineChangeName) return;
          setOutlineChangeName(title ? title : "无标题卡片");
          setOutlineChangeNameNode(id);
          setNodeDraggable(false);
        }}
        onContextMenu={showRightMenu}
      >
        <img
          src={src}
          style={{
            width: 14,
            height: 14,
            marginLeft: 5,
          }}
        />
        <div className="writing-module-tree-node-text">
          {title || "无标题卡片"}
        </div>
      </div>
    );
  };

  const renameTitle = (id) => {
    client.writeData({
      data: {
        writingChangeNameInOutlineIdClient: id,
      },
    });
    setMousePosInOutline({ x: 0, y: 0 });
  };

  const dragAndDropCallback = (data) => {
    let allOriginDatasTemp = copyObject(currentProjectInfo.outline.menu);
    let allOriginDatasIds = getDatasIds(allOriginDatasTemp);
    let allChangesDatasTemp = copyObject(data);
    let allChangesDatasIds = getDatasIds(allChangesDatasTemp);

    if (allChangesDatasIds.length === allOriginDatasIds.length) {
      let expandedKeys = [];
      const clearOutline = (outline) => {
        for (let i = 0; i < outline.length; i++) {
          let item = outline[i];
          if (item.hasOwnProperty("currentProjectInfo")) {
            delete item.currentProjectInfo;
          }
          if (item.hasOwnProperty("expanded")) {
            if (item.expanded) {
              expandedKeys.push(item.id);
            }
          }
          if (item.hasOwnProperty("updateCardAndOutline")) {
            delete item.updateCardAndOutline;
          }
          if (item.hasOwnProperty("selectedKey")) {
            delete item.selectedKey;
          }
          if (item.hasOwnProperty("showRightMenu")) {
            delete item.showRightMenu;
          }
          if (item.children && item.children.length) {
            clearOutline(item.children);
          }
        }
      };
      clearOutline(allChangesDatasTemp);
      updateProject(allChangesDatasTemp, null, expandedKeys);
    }
  };

  const renderTreeContent = () => {
    return (
      <WritingOutline
        onSelect={(selectedKeys, info, tab = 0) => {
          onSelectRef.current(selectedKeys, info, tab);
        }}
        onExpand={onExpand}
        onDragEnter={onDragEnter}
        onDrop={onDropWithinMenu}
        dragAndDropCallback={dragAndDropCallback}
        currentExpandedKeys={currentExpandedKeys}
        nodeDraggable={nodeDraggable}
        treeNodeInput={treeNodeInput}
        outline={currentProjectInfo.outline.menu}
        currentSelectCardId={currentSelectCardId}
        currentProjectInfo={currentProjectInfo}
        enterMenu={() => setMouseNotInTheMenu(false)}
        leaveMenu={() => setMouseNotInTheMenu(true)}
        currentProjectInfo={currentProjectInfo}
        showRightMenu={showRightMenu}
        updateCardAndOutline={updateCardAndOutline}
      />
    );
  };

  const treeContent = React.useMemo(() => {
    return renderTreeContent();
  }, [
    nodeDraggable,
    currentProjectInfo,
    currentSelectCardId,
    currentExpandedKeys,
    outlineChangeName,
    outlineChangeNameNode,
  ]);

  const renderTuiEditorComponent = (item) => {
    if (!item) return null;
    let props = {
      isCurrentCardsWriteToDB: isCurrentCardsWriteToDB,
      editorReset: editorReset,
      setEditorReset: setEditorReset,
      isFullScreen: isFullScreen,
      highlightWords: highlightWords,
      isWysiwygMode: isWysiwygMode,
      isHighlightAndMarkMode: isHighlightAndMarkMode,
      setIsHighlightAndMarkMode: setIsHighlightAndMarkMode,
      isShowMarkdown: isShowMarkdown || titleSelectedIndex > 2,
      titleSelectedIndex: titleSelectedIndex,
      data: { ...item, projectId: currentProjectInfo.originalId },
      modifyDatas: modifyDatas,
      findAndReplaceDatas: findAndReplaceDatas,
      setFindAndReplaceDatas: setFindAndReplaceDatas,
      setModifyDatas: setModifyDatas,
      key: `tuieditor-key-${titleSelectedIndex}-${item.data.type}-${item.data.id}`,
      saveCallback: saveCallback,
      feedbackData: feedbackData,
      feedbackType: feedbackType,
      changeOutlineName: changeOutlineName,
      onFocusCallback: (e) => {},
      onHoverCallback: (e) => {
        const newElement = e.target;
        if (
          newElement.id &&
          (newElement.id.indexOf("aw-mark-word-meta") !== -1 ||
            newElement.id.indexOf("aw-mark-word-replacement") !== -1)
        ) {
          if (
            hoverMarkElement &&
            hoverMarkElement.id &&
            hoverMarkElement.id === newElement.id
          ) {
            return;
          }
          let originId = "";
          let originClassName = "";
          if (newElement.id.indexOf("aw-mark-word-meta") !== -1) {
            originId = "aw-mark-word-meta";
          }
          if (newElement.id.indexOf("aw-mark-word-replacement") !== -1) {
            originId = "aw-mark-word-replacement";
          }

          let indexInfo = newElement.id.split(originId + "-")[1];
          let cardId = indexInfo.split("&&")[0];
          let data = highlightWords
            .filter((item) => {
              return item.cardId === cardId;
            })[0]
            .wordInfo.filter((item) => {
              return item.id === newElement.id;
            })[0];
          originClassName = newElement.className;
          newElement.className = originId + "-selected";

          let tipElement = document.getElementById(
            originId + "-" + "mark-hover-tip-modal-container"
          );
          if (tipElement) {
            let position = [];
            let parentElement = null;
            if (newElement.offsetHeight > 40) {
              position = [e.pageX, e.pageY];
              parentElement = tipElement.offsetParent;
            } else {
              position = [
                newElement.offsetLeft + newElement.offsetWidth / 2,
                newElement.offsetTop + newElement.offsetHeight / 2,
              ];
              parentElement = newElement.offsetParent;
              while (parentElement) {
                position[0] += parentElement.offsetLeft;
                if (titleSelectedIndex === 2) {
                  position[1] +=
                    parentElement.offsetTop - parentElement.scrollTop;
                } else {
                  position[1] += parentElement.offsetTop;
                }
                parentElement = parentElement.offsetParent;
              }
              if (titleSelectedIndex === 3) {
                position[1] -= document.getElementsByClassName(
                  "full-screen-editor-container-7413"
                )[0].scrollTop;
              }
              parentElement = tipElement.offsetParent;
            }

            while (parentElement) {
              position[0] -= parentElement.offsetLeft;
              position[1] -= parentElement.offsetTop;
              parentElement = parentElement.offsetParent;
            }

            if (originId === "aw-mark-word-meta") {
              setWordMetaDropdownPosition({
                left: position[0] - tipElement.offsetWidth / 2,
                top: position[1],
              });
              setWordMetaDropdownData(data);
            }
            if (originId === "aw-mark-word-replacement") {
              setWordReplacementDropdownPosition({
                left: position[0] - tipElement.offsetWidth / 2,
                top: position[1],
              });
              setWordReplacementDropdownData(data);
            }
          }

          if (hoverMarkElement && hoverMarkElement.id) {
            if (newElement.id !== hoverMarkElement.id) {
              hoverMarkElement.className = hoverMarkElementOriginClassName;
            }
          }
          setHoverMarkElement(newElement);
          setHoverMarkElementOriginClassName(originClassName);
        } else {
          if (hoverMarkElement && hoverMarkElement.id) {
            hoverMarkElement.className = hoverMarkElementOriginClassName;
            setHoverMarkElement(null);
          }
          setWordReplacementDropdownPosition({
            left: -99999,
            top: -99999,
          });
          setWordMetaDropdownPosition({
            left: -99999,
            top: -99999,
          });
        }
      },
      onClickCallback: (e) => {
        const newElement = e.target;
        if (
          newElement.className === "replace-tags" ||
          newElement.id.indexOf("aw-mark-word-meta") !== -1 ||
          newElement.id.indexOf("aw-mark-word-replacement") !== -1
        ) {
          return;
        }
        if (newElement.style.display === "none" || !newElement.id) {
          return;
        }
        if (newElement.tagName === "SPAN") {
          let openPanelCardId = getOpenPanelCardId();
          if (openPanelCardId[0]) {
            const lastElement = document.getElementById(openPanelCardId[0]);
            if (lastElement && lastElement.id) {
              const lastElementPanel = document.getElementById(
                openPanelCardId[0] + "-panel"
              );
              lastElement.style["font-weight"] = "normal";
              lastElement.style.background = "#FFF4A0";
              lastElement.innerHTML = lastElement.innerText;
              if (lastElementPanel)
                lastElementPanel.className = "closing web-panel";
            }
          }
          openPanelCardId[0] = newElement.id;
          newElement.style["font-weight"] = "bold";
          newElement.style.background = "rgba(255,115,141,0.7)";
          const mPanel = document.getElementById(newElement.id + "-panel");
          if (mPanel) {
            mPanel.className = "opening web-panel";
            mPanel.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "nearest",
            });
          }
        }
      },
    };
    return (
      <DocumentError
        errorTip={"编辑模式文档解析错误"}
        key={`${item.data.id}-${item.data.type}-${titleSelectedIndex}`}
      >
        {isShowMarkdown || titleSelectedIndex > 2 ? (
          <TuiEditorComponent_Ori {...props} />
        ) : (
          <TuiEditorComponent_New {...props} />
        )}
        {/* <TuiEditorComponent
          isCurrentCardsWriteToDB={isCurrentCardsWriteToDB}
          editorReset={editorReset}
          setEditorReset={setEditorReset}
          isFullScreen={isFullScreen}
          highlightWords={highlightWords}
          isWysiwygMode={isWysiwygMode}
          isHighlightAndMarkMode={isHighlightAndMarkMode}
          setIsHighlightAndMarkMode={setIsHighlightAndMarkMode}
          isShowMarkdown={isShowMarkdown || titleSelectedIndex > 2}
          titleSelectedIndex={titleSelectedIndex}
          data={{ ...item, projectId: currentProjectInfo.originalId }}
          modifyDatas={modifyDatas}
          findAndReplaceDatas={findAndReplaceDatas}
          setFindAndReplaceDatas={setFindAndReplaceDatas}
          setModifyDatas={setModifyDatas}
          key={`tuieditor-key-${titleSelectedIndex}-${item.data.type}-${item.data.id}`}
          saveCallback={saveCallback}
          feedbackData={feedbackData}
          feedbackType={feedbackType}
          changeOutlineName={changeOutlineName}
          onFocusCallback={(e) => {}}
          onHoverCallback={(e) => {
            const newElement = e.target;
            if (
              newElement.id &&
              (newElement.id.indexOf("aw-mark-word-meta") !== -1 ||
                newElement.id.indexOf("aw-mark-word-replacement") !== -1)
            ) {
              if (
                hoverMarkElement &&
                hoverMarkElement.id &&
                hoverMarkElement.id === newElement.id
              ) {
                return;
              }
              let originId = "";
              let originClassName = "";
              if (newElement.id.indexOf("aw-mark-word-meta") !== -1) {
                originId = "aw-mark-word-meta";
              }
              if (newElement.id.indexOf("aw-mark-word-replacement") !== -1) {
                originId = "aw-mark-word-replacement";
              }

              let indexInfo = newElement.id.split(originId + "-")[1];
              let cardId = indexInfo.split("&&")[0];
              let data = highlightWords
                .filter((item) => {
                  return item.cardId === cardId;
                })[0]
                .wordInfo.filter((item) => {
                  return item.id === newElement.id;
                })[0];
              originClassName = newElement.className;
              newElement.className = originId + "-selected";

              let tipElement = document.getElementById(
                originId + "-" + "mark-hover-tip-modal-container"
              );
              if (tipElement) {
                let position = [];
                let parentElement = null;
                if (newElement.offsetHeight > 40) {
                  position = [e.pageX, e.pageY];
                  parentElement = tipElement.offsetParent;
                } else {
                  position = [
                    newElement.offsetLeft + newElement.offsetWidth / 2,
                    newElement.offsetTop + newElement.offsetHeight / 2,
                  ];
                  parentElement = newElement.offsetParent;
                  while (parentElement) {
                    position[0] += parentElement.offsetLeft;
                    if (titleSelectedIndex === 2) {
                      position[1] +=
                        parentElement.offsetTop - parentElement.scrollTop;
                    } else {
                      position[1] += parentElement.offsetTop;
                    }
                    parentElement = parentElement.offsetParent;
                  }
                  if (titleSelectedIndex === 3) {
                    position[1] -= document.getElementsByClassName(
                      "full-screen-editor-container-7413"
                    )[0].scrollTop;
                  }
                  parentElement = tipElement.offsetParent;
                }

                while (parentElement) {
                  position[0] -= parentElement.offsetLeft;
                  position[1] -= parentElement.offsetTop;
                  parentElement = parentElement.offsetParent;
                }

                if (originId === "aw-mark-word-meta") {
                  setWordMetaDropdownPosition({
                    left: position[0] - tipElement.offsetWidth / 2,
                    top: position[1],
                  });
                  setWordMetaDropdownData(data);
                }
                if (originId === "aw-mark-word-replacement") {
                  setWordReplacementDropdownPosition({
                    left: position[0] - tipElement.offsetWidth / 2,
                    top: position[1],
                  });
                  setWordReplacementDropdownData(data);
                }
              }

              if (hoverMarkElement && hoverMarkElement.id) {
                if (newElement.id !== hoverMarkElement.id) {
                  hoverMarkElement.className = hoverMarkElementOriginClassName;
                }
              }
              setHoverMarkElement(newElement);
              setHoverMarkElementOriginClassName(originClassName);
            } else {
              if (hoverMarkElement && hoverMarkElement.id) {
                hoverMarkElement.className = hoverMarkElementOriginClassName;
                setHoverMarkElement(null);
              }
              setWordReplacementDropdownPosition({
                left: -99999,
                top: -99999,
              });
              setWordMetaDropdownPosition({
                left: -99999,
                top: -99999,
              });
            }
          }}
          onClickCallback={(e) => {
            const newElement = e.target;
            // if (newElement.tagName === "DIV") {
            //   const el = document.getElementById("right-side-button-4");
            //   if(el) {
            //     el.click();
            //   }
            //   return;
            // }
            if (
              newElement.className === "replace-tags" ||
              newElement.id.indexOf("aw-mark-word-meta") !== -1 ||
              newElement.id.indexOf("aw-mark-word-replacement") !== -1
            ) {
              return;
            }
            if (newElement.style.display === "none" || !newElement.id) {
              return;
            }
            if (newElement.tagName === "SPAN") {
              let openPanelCardId = getOpenPanelCardId();
              if (openPanelCardId[0]) {
                const lastElement = document.getElementById(openPanelCardId[0]);
                if (lastElement && lastElement.id) {
                  const lastElementPanel = document.getElementById(
                    openPanelCardId[0] + "-panel"
                  );
                  lastElement.style["font-weight"] = "normal";
                  lastElement.style.background = "#FFF4A0";
                  lastElement.innerHTML = lastElement.innerText;
                  if (lastElementPanel)
                    lastElementPanel.className = "closing web-panel";
                }
              }
              openPanelCardId[0] = newElement.id;
              newElement.style["font-weight"] = "bold";
              newElement.style.background = "rgba(255,115,141,0.7)";
              const mPanel = document.getElementById(newElement.id + "-panel");
              if (mPanel) {
                mPanel.className = "opening web-panel";
                mPanel.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                  inline: "nearest",
                });
              }
            }
          }}
        /> */}
      </DocumentError>
    );
  };

  const renderEditor = () => {
    if (!currentSelectCardAndChildrenIds) {
      return null;
    }

    let renderCard = getRenderCard();
    if (!renderCard.length) {
      return <img src={NO_CONTENT} className="no-content-alert" />;
    }
    let editorContainerClassName = "writing-editor-container-no-full-screen";
    if (isFullScreen) {
      editorContainerClassName = "full-screen-container-7413";
    }

    return (
      <div
        id="fullScreenContainer"
        style={{
          width: isFullScreen ? "55% !important" : "55%",
          display: isFullScreen ? "flex" : "block",
          height: "100%",
          overflow: "auto",
          minWidth: 550,
        }}
        className={editorContainerClassName}
      >
        <div
          className={
            "full-screen-editor-container-7413" +
            (isFullScreen
              ? " full-screen-editor-container-7413-full-screen"
              : "")
          }
        >
          {renderCard.map((item) => {
            return (
              <>
                {titleSelectedIndex === 3 &&
                  renderTuiEditorComponent({
                    ...item,
                    data: {
                      ...item.data,
                      text: item.data.title,
                      type: "title",
                    },
                  })}
                {renderTuiEditorComponent(item)}
              </>
            );
          })}
        </div>

        {isFullScreen && (
          <div className="full-screen-editor-button-container-8756">
            <img
              src={isShowMarkdown ? ExitPreviewIcon : PreviewIcon}
              className="full-screen-editor-button-8904"
              onClick={() => {
                setIsShowMarkDown(!isShowMarkdown);
              }}
            />
            <img
              src={ExitFullScreenIcon}
              className="full-screen-editor-button-8904"
              onClick={() => {
                setFullScreen(false);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="writing-main-container"
      onClick={() => {
        setMousePosInOutline({ x: 0, y: 0 });
      }}
    >
      <Spin
        tip={"正在导出文件..."}
        spinning={loadingPDF}
        style={{ position: "absolute", left: "50%", top: 70, zIndex: 999 }}
      />
      <ReconfirmModal
        isShow={showDeleteConfirm}
        title="确认删除"
        content={"数据一旦清除，将无法找回，确认删除吗？"}
        cancelCallback={() => {
          setShowDeleteConfirm(false);
        }}
        submitCallback={() => {
          setShowDeleteConfirm(false);
          deleteCardInMenu(currentSelectCardId);
        }}
      />
      <ReconfirmModal
        isShow={showRefreshConfirm}
        title="提示"
        content={
          "检测到当前页面数据过旧，无法保存修改内容，是否刷新页面获取最新数据？"
        }
        cancelCallback={() => {
          setShowRefreshConfirm(false);
        }}
        submitCallback={() => {
          setShowRefreshConfirm(false);
          window.location.reload();
        }}
      />
      <WritingTab
        key={"WritingTab"}
        isNetworkOnline={isNetworkOnline}
        renderCardIds={titleSelectedIndex === 1 ? [] : getRenderCard()}
        titleSelectedIndex={titleSelectedIndex}
        isShowOutline={isShowOutline}
        setIsShowOutline={setIsShowOutline}
        currentCardInfo={getCurrentCardInfoForWritingTab()}
        setHighlightWords={(datas) => {
          if (datas.length && datas[0].type === "mark-clear-all") {
            setIsHighlightAndMarkMode(false);
          } else {
            setIsHighlightAndMarkMode(true);
          }
          setHighlightWords(datas);
        }}
        highlightWords={highlightWords}
        hideProofreadingBar={hideProofreadingBar}
        isShowMarkdown={isShowMarkdown}
        setIsShowMarkDown={setIsShowMarkDown}
        setShowReplaceModal={setShowReplaceModal}
        isShowReplaceModal={showReplaceModal}
        setProofReadingBar={setProofReadingBar}
        omitAllMistake={omitAllMistake}
        setFullScreen={setFullScreen}
      />
      <div className="writing-main-content-container">
        <div className="writing-outline-and-editor-container">
          {isShowOutline && treeContent}
          {titleSelectedIndex === 1 && (
            <WritingCardList
              onSelect={(selectedKeys, info, tab = 0) => {
                onSelectRef.current(selectedKeys, info, tab);
              }}
              cards={getCardList()}
              cardsWithAllChilren={getCardList(true)}
              currentProjectInfo={currentProjectInfo}
            />
          )}
          {/* {titleSelectedIndex === 1 && renderCardList()} */}

          {showReplaceModal && titleSelectedIndex > 1 && (
            <Draggable
              bounds="parent"
              handle=".handle"
              onDrag={handleDrag}
              position={{ x: replacePosition[0], y: replacePosition[1] }}
              grid={[10, 10]}
              scale={1}
            >
              <div
                style={{
                  zIndex: 999,
                  position: "absolute",
                  width: 560,
                  bottom: 20,
                  right: 20,
                  boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.11)",
                  borderRadius: 10,
                  background: "white",
                }}
              >
                <div
                  className="handle"
                  style={{
                    height: 38,
                    padding: 5,
                    borderRadius: 10,
                    background: "rgba(249,249,251,1)",
                    textAlign: "center",
                    boxShadow: "0px 0px 50px 0px rgba(0,0,0,0.11)",
                  }}
                >
                  <p>查找</p>
                  <img
                    onClick={() => {
                      setShowReplaceModal(false);
                      setHighlightWords([{ type: "mark-clear-all" }]);
                      // const selectedDom = document.getElementById(
                      //   currentSelectCardId
                      // );
                      // if (selectedDom) selectedDom.click();
                    }}
                    style={{
                      cursor: "pointer",
                      position: "absolute",
                      right: 10,
                      top: 9,
                      width: 14,
                    }}
                    src={CloseIcon}
                  />
                </div>
                <div style={{ background: "white" }}>
                  <div style={{ padding: "30px 2vh 10px 2.5vh" }}>
                    查找：
                    <input
                      ref={(node) => {
                        if (node) node.id = "replace-search-input";
                      }}
                      style={{
                        marginLeft: "0.8vw",
                        border: "none",
                        paddingRight: "2vw",
                        outline: "none",
                        height: 40,
                        width: 450,
                        background: "rgba(249,249,251,1)",
                        borderRadius: 4,
                      }}
                      value={searchKw}
                      onChange={(e) => {
                        setSearchKw(e.target.value);
                      }}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          findAndReplace_findRef.current();
                        }
                      }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 45,
                        top: 76,
                        color: "#BFC1C7",
                      }}
                    >
                      {matchCurrent && matchTotal ? `${matchCurrent}/` : null}
                      {matchTotal ? matchTotal : null}
                    </span>
                  </div>
                  <div style={{ padding: "10px 2vh 10px 2.5vh" }}>
                    替换：
                    <input
                      onChange={(e) => setMatchKw(e.target.value)}
                      style={{
                        marginLeft: "0.8vw",
                        border: "none",
                        outline: "none",
                        paddingRight: "2vw",
                        height: 40,
                        width: 450,
                        background: "rgba(249,249,251,1)",
                        borderRadius: 4,
                      }}
                      value={matchKw}
                    />
                  </div>
                  <div
                    style={{
                      padding: 18,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <section style={{ display: "flex" }}>
                      <div
                        onClick={() => {
                          findAndReplace_forwardRef.current();
                        }}
                        style={{
                          marginLeft: 55,
                          marginTop: 5,
                          padding: 3,
                          width: 30,
                          height: 30,
                          marginRight: 42,
                          background: "rgba(243,244,248,1)",
                          borderRadius: 50,
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={FORWARD}
                          style={{ marginLeft: 6, width: 8, height: 10 }}
                        />
                      </div>
                      <div
                        onClick={() => {
                          findAndReplace_nextRef.current();
                        }}
                        style={{
                          padding: 3,
                          marginTop: 5,
                          width: 30,
                          height: 30,
                          marginRight: 20,
                          background: "rgba(243,244,248,1)",
                          borderRadius: 50,
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={BACK}
                          style={{ marginLeft: 8, width: 8, height: 10 }}
                        />
                      </div>
                    </section>
                    <section style={{ display: "flex", marginRight: 9 }}>
                      <div
                        onClick={() => {
                          findAndReplace_findRef.current();
                        }}
                        style={{
                          width: 106,
                          height: 40,
                          marginRight: 6,
                          paddingTop: 9,
                          textAlign: "center",
                          fontWeight: "bold",
                          background: "rgba(243,244,248,1)",
                          borderRadius: 20,
                          cursor: "pointer",
                        }}
                      >
                        查找
                      </div>
                      <div
                        onClick={() => {
                          findAndReplace_replace_nextRef.current();
                        }}
                        style={{
                          width: 106,
                          height: 40,
                          marginRight: 6,
                          fontWeight: "bold",
                          paddingTop: 9,
                          textAlign: "center",
                          background: "rgba(243,244,248,1)",
                          borderRadius: 20,
                          cursor: "pointer",
                        }}
                      >
                        替换再查找
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </Draggable>
          )}
          {(titleSelectedIndex === 2 || titleSelectedIndex === 3) &&
            renderEditor()}
          <WordMetaDropdown
            position={wordMetaDropdownPosition}
            wordMetaDropdownData={wordMetaDropdownData}
          />
          <WordReplacementDropdown
            position={wordReplacementDropdownPosition}
            wordReplacementDropdownData={wordReplacementDropdownData}
            wordClickCallback={(item) => {
              let data = {
                cardId: item.data.cardId,
                start: item.data.start,
                end: item.data.end,
                advice: item.replaceWord.word,
              };
              const element = document.getElementById(item.data.id);
              if (element) {
                element.outerHTML = item.replaceWord.word;
              }
              let hightlightDeleteIndex = -1;
              highlightWords.forEach((hightlightItem, hightllightIndex) => {
                if (hightlightItem.cardId === item.data.cardId) {
                  let wordInfoDeleteIndex = -1;
                  hightlightItem.wordInfo.forEach(
                    (wordInfoItem, wordInfoIndex) => {
                      if (wordInfoItem.id === item.data.id) {
                        wordInfoDeleteIndex = wordInfoIndex;
                      }
                    }
                  );
                  if (wordInfoDeleteIndex > -1) {
                    hightlightItem.wordInfo.splice(wordInfoDeleteIndex, 1);
                    if (hightlightItem.wordInfo.length === 0) {
                      hightlightDeleteIndex = hightllightIndex;
                    }
                  }
                }
              });
              if (hightlightDeleteIndex > -1) {
                highlightWords.splice(hightlightDeleteIndex, 1);
                if (highlightWords.length === 0) {
                  setHighlightWords([{ type: "mark-clear-all" }]);
                } else {
                  setHighlightWords(highlightWords);
                }
              } else {
                setHighlightWords(highlightWords);
              }
              setModifyDatas([data]);
              setWordReplacementDropdownPosition({ left: -99999, top: -99999 });
            }}
          />
          <WritingRightClickMenu
            isNetworkOnline={isNetworkOnline}
            changeOutlineIcon={changeOutlineIcon}
            exportCardCallback={exportCardCallback}
            copyCardUrl={() => {
              copyCardUrlRef.current();
            }}
            splitCard={splitCard}
            addCardInMenu={addCardInMenu}
            copyCardInMenu={copyCardInMenu}
            renameTitle={renameTitle}
            expandAll={expandAll}
            unexpandAll={unexpandAll}
            projectInfo={currentProjectInfo}
            cardsInfo={currentProjectCards}
            currentSelectCardId={currentSelectCardId}
            isInTrash={isInTrash}
            isProjectNode={isProjectNode}
            deleteCardInMenu={deleteCardInMenu}
            xPos={mousePosInOutline.x}
            yPos={getRightMenuPosition(mousePosInOutline.y)}
          />
        </div>
        {titleSelectedIndex > 1 && isNetworkOnline && (
          <RightSideBar
            isOpen={titleSelectedIndex > 1 && !hideProofreadingBar}
            hideProofreadingBar={hideProofreadingBar}
            omitAllMistake={omitAllMistake}
            setProofReadingBar={setProofReadingBar}
            setFeedbackTabKey={setFeedbackTabKey}
            FeedbackModule={() => {
              return (
                <FeedbackModule
                  feedbackLoading={feedbackLoading}
                  noMistake={noMistake}
                  setFeedbackSubTabKey={setFeedbackSubTabKey}
                  omitAllMistake={omitAllMistake}
                  correctAllMistake={correctAllMistake}
                  omitMistake={omitMistake}
                  correctMistake={correctMistake}
                  feedbackTabKey={feedbackTabKey}
                  setFeedbackTabKey={setFeedbackTabKey}
                  getFeedbackData={getFeedbackData}
                  feedbackData={feedbackData}
                />
              );
            }}
            InspirationModule={() => {
              return <Inspiration writingCards={getCurrentCardIds()} />;
            }}
            Statistics={() => {
              return <Statistics ids={getCurrentCardIds()} />;
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Writing;
