import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件
import MainContainer from "../../Router_MainContainer/index";

//graphql查询

//引入的资源图片

const apps = [
  {
    category: "取名",
    apps: [
      {
        icon: require("../../../assets/services/baby_name.png"),
        title: "宝宝取名",
        content:
          "介绍：小宝宝来到世上，每个父母都希望给自己的孩子起一个响亮的名字。可以说为了孩子的姓名绞尽脑汁、浮想联翩，有的翻阅《词海》寻找那些名言佳句，有的请亲朋好友提供名字方案，从中进行选择；有的干脆到起名公司求一个好名，无论以何种方式为孩子起名，都说明了家长们的一个共同心愿，那就是希望自己的孩子能拥有一个吉祥的名字。",
        isOnline: false,
      },
      {
        icon: require("../../../assets/services/company_name.png"),
        title: "公司取名",
        content:
          "介绍：给公司起名字需要使用一些带有吉祥寓意的汉字进行搭配，这样能为公司带来好的开始，好的期望，好的未来。但是想要为公司取一个吉祥的名字，字体的搭配一定要好，而且还要能体现出公司的企业文化与公司的性质。",
        isOnline: false,
      },
    ],
  },
  {
    category: "翻译",
    apps: [
      {
        icon: require("../../../assets/services/translate.png"),
        title: "翻译",
        content:
          "介绍：翻译是在准确（信）、通顺（达）、优美（雅）的基础上，把一种语言信息转变成另一种语言信息的行为。翻译是将一种相对陌生的表达方式，转换成相对熟悉的表达方式的过程。其内容有语言、文字、图形、符号和视频翻译。",
        isOnline: false,
      },
    ],
  },
];

const Application = (props) => {
  const { setIsLogin, history } = props;

  const renderBodyContent = () => {
    return (
      <div className="service-app-container">
        {apps.map((appCategory, categoryIndex) => {
          return (
            <div
              className="service-app-category-container"
              key={"appCategory_" + categoryIndex}
            >
              <div className="service-app-category-title-container">
                <div className="service-app-category-title-line" />
                <div className="service-app-category-title-text">
                  {appCategory.category}
                </div>
              </div>
              <div className="service-app-category-content-container">
                {appCategory.apps.map((item, index) => {
                  return (
                    <div
                      className="service-app-category-content-item-container"
                      key={"appCategory_item_" + index}
                    >
                      <img
                        src={item.icon}
                        className="service-app-category-content-item-icon"
                      />
                      <div className="service-app-category-content-item-text-container">
                        <div className="service-app-category-content-item-text-title">
                          {item.title}
                        </div>
                        <div className="service-app-category-content-item-text-content">
                          {item.content}
                        </div>
                      </div>
                      <div
                        className={`service-app-category-content-item-button-${
                          item.isOnline ? "online" : "offline"
                        }`}
                      >
                        <div className="service-app-category-content-item-button-text">
                          {item.isOnline ? "使用" : "即将上线"}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderTitleContent = () => {
    const categories = [
      {
        name: "应用",
      },
      {
        name: "课程",
      },
    ];
    return (
      <div className="service-app-category-header-container">
        <div className="service-app-category-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"service-app-category-header-" + index}
                className={
                  index === 0
                    ? "service-app-category-header-tab-item-selected"
                    : "service-app-category-header-tab-item"
                }
                onClick={() => {
                  if (index !== 0) {
                    history.push("/service/course");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <MainContainer
      currentSiderIndex={4}
      setIsLogin={setIsLogin}
      isBodyLoading={false}
      bodyContent={renderBodyContent()}
      headerContent={renderTitleContent()}
    />
  );
};

export default Application;
