import * as React from "react";
import "./styles.css";

//引入的库组件
import { Switch, Route, Redirect } from "react-router";

//引入的其他页面组件
import CorpusProjectListContainer from "./corpusProjectListContainer";
import CorpusProjectDetailContainer from "./corpusProjectDetailContainer";

//graphql查询

//引入的资源图片

const CorpusProjectContainer = (props) => {
  const { setHeaderContent, openGlobalSearchRef } = props;

  React.useEffect(() => {
    //加载完毕更改标签名
    document.title = `素材-项目`;
  }, []);

  const renderContent = () => {
    return (
      <Switch>
        <Route
          path="/corpus/project/list"
          component={(props) => {
            return (
              <CorpusProjectListContainer {...props} setHeaderContent={setHeaderContent} openGlobalSearchRef={openGlobalSearchRef} />
            );
          }}
        />
        <Route
          path="/corpus/project/detail"
          component={(props) => {
            return (
              <CorpusProjectDetailContainer
                {...props}
                setHeaderContent={setHeaderContent}
                openGlobalSearchRef={openGlobalSearchRef}
              />
            );
          }}
        />
        <Route
          path="/"
          render={() => {
            return <Redirect to="/corpus/project/list?category=all" />;
          }}
        />
      </Switch>
    );
  };

  return renderContent();
};

export default CorpusProjectContainer;
