const R = require('ramda')
const RA = require('ramda-adjunct')

const convertToZH = ({code: code, field: field, message: message}) => { 
  if (isAvaliableField(field) && isAvaliableCode(code)) {
    return R.prop(code)(codeMap) + ': ' +  R.prop(field)(fieldMap) 
  } else if (isAvaliableCode(code)) {
    return R.prop(code)(codeMap)
  } else {
    return message
  }
}

const isAvaliableField = (field) =>  { 
  return RA.isNotNil(field)
}

const isAvaliableCode = (code) => { 
  return R.compose(
    RA.isNotNil,
    R.prop(code)
  )(codeMap)
}

const codeMap = {
  invalid_role: "权限错误",
  invalid_invitation_code: "邀请码错误",
  invalid_weixin_code: "微信二维码失效",
  invalid_verification_code: "验证码失效",
  invalid_vip_level: "VIP 等级不够",
  not_found: "无法找到",
  token_not_found: "未登录",
  token_expired: "登录信息已失效",
  unique: "重复",
  vip_expired: "VIP 已过期"
}

const fieldMap = {
  project: '项目',
  shared_project: '共享项目',
  card: '卡片',
  shared_card: '共享卡片',
  "userAuths.0.phoneOrWechat": '手机或微信'
}

export {convertToZH}