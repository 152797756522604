import * as React from "react";
import "./styles.css";

//引入的库组件
import { withRouter } from "react-router";

//引入的其他页面组件

//graphql查询

//引入的资源图片

const Main = (props) => {
  const { children } = props;
  return <div className="ui-main-body">{children}</div>;
};

export default withRouter(Main);
