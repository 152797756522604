import * as React from "react";
import "./styles.css";

//引入的库组件
import { Chart, registerShape } from "@antv/g2";
import { Progress } from "antd";

//引入的其他页面组件
import Help from "./components/help";
import Title from "./components/title";
import { PieChart } from "./components/pieChart";
import { MulitCircleChart } from "./components/mulitCircleChart";
import { WatchChart } from "./components/watchChart";
import { getWatchColor } from "./utils";
import { getModuleTip } from "./moduleTips";

//graphql查询

//引入的资源图片

const glyphColor = ["#82A1FF", "#2BDEB6", "#FFCF00", "#FFA7EE", "#FF8484"];

const Word = (props) => {
  const { data } = props;

  React.useEffect(() => {
    if (data) {
      // makePronunciationChart(data.pronunciation, "pronunciation");
      makeGlyphChart(data.glyph, "glyph", "词数", glyphColor);
      makeWatchChart(
        data.charDifficulty,
        "charDifficulty",
        getWatchColor(data.charDifficulty[0].value * 100)
      );
      makeWatchChart(
        data.wordDifficulty,
        "wordDifficulty",
        getWatchColor(data.wordDifficulty[0].value * 100)
      );
    }
  }, [data]);

  const makePronunciationChart = (chartData, id) => {
    MulitCircleChart(chartData, id);
  };

  const makeGlyphChart = (chartData, id, content, colors) => {
    PieChart(chartData, id, content, colors);
  };

  const makeWatchChart = (chartData, id, color = null) => {
    WatchChart(chartData, id, color);
  };

  const renderPronunciation = () => {
    return (
      <div className="statistics-module-background statistics-word-pronunciation-container">
        <Help
          tip={getModuleTip("word-pronunciation")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"字音"} />
        <div className="statistics-word-meaning-progress-container">
          {data.pronunciation.map((item, index) => {
            return (
              <div key={"word-meaning-" + index} className="statistics-word-meaning-progress-item">
                <div className="statistics-word-meaning-progress-item-name">
                  {item.item}
                </div>
                <Progress
                  className="statistics-word-meaning-progress-item-bar"
                  percent={item.percent}
                  showInfo={false}
                  strokeColor={item.color}
                />
                <div className="statistics-word-meaning-progress-item-count">
                  {item.count}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderGlyph = () => {
    return (
      <div className="statistics-module-background statistics-word-glyph-container">
        <Help
          tip={getModuleTip("word-glyph")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"词数"} />
        <div id="glyph" className="statistics-word-glyph-chart" />
        <div className="statistics-word-glyph-count-container">
          <div className="statistics-word-glyph-count-line-contaier">
            <div className="statistics-word-glyph-count-item">
              <div
                className="statistics-word-glyph-count-item-dot"
                style={{ background: glyphColor[0] }}
              />
              <div className="statistics-word-glyph-count-item-tip">
                {data.glyph[0].item}
              </div>
              <div className="statistics-word-glyph-count-item-count">
                {data.glyph[0].count}
              </div>
            </div>
            <div className="statistics-word-glyph-count-item">
              <div
                className="statistics-word-glyph-count-item-dot"
                style={{ background: glyphColor[1] }}
              />
              <div className="statistics-word-glyph-count-item-tip">
                {data.glyph[1].item}
              </div>
              <div className="statistics-word-glyph-count-item-count">
                {data.glyph[1].count}
              </div>
            </div>
          </div>
          <div className="statistics-word-glyph-count-line-contaier">
            <div className="statistics-word-glyph-count-item">
              <div
                className="statistics-word-glyph-count-item-dot"
                style={{ background: glyphColor[2] }}
              />
              <div className="statistics-word-glyph-count-item-tip">
                {data.glyph[2].item}
              </div>
              <div className="statistics-word-glyph-count-item-count">
                {data.glyph[2].count}
              </div>
            </div>
            <div className="statistics-word-glyph-count-item">
              <div
                className="statistics-word-glyph-count-item-dot"
                style={{ background: glyphColor[3] }}
              />
              <div className="statistics-word-glyph-count-item-tip">
                {data.glyph[3].item}
              </div>
              <div className="statistics-word-glyph-count-item-count">
                {data.glyph[3].count}
              </div>
            </div>
          </div>
          <div className="statistics-word-glyph-count-line-contaier">
            <div className="statistics-word-glyph-count-item">
              <div
                className="statistics-word-glyph-count-item-dot"
                style={{ background: glyphColor[4] }}
              />
              <div className="statistics-word-glyph-count-item-tip">
                {data.glyph[4].item}
              </div>
              <div className="statistics-word-glyph-count-item-count">
                {data.glyph[4].count}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMeaning = () => {
    return (
      <div className="statistics-module-background statistics-word-meaning-container">
        <Help
          tip={getModuleTip("word-meaning")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"词性"} />
        <div className="statistics-word-meaning-progress-container">
          {data.meaning.map((item, index) => {
            return (
              <div key={"word-meaning-" + index} className="statistics-word-meaning-progress-item">
                <div className="statistics-word-meaning-progress-item-name">
                  {item.item}
                </div>
                <Progress
                  className="statistics-word-meaning-progress-item-bar"
                  percent={item.percent}
                  showInfo={false}
                  strokeColor={item.color}
                />
                <div className="statistics-word-meaning-progress-item-count">
                  {item.count}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderDifficulty = () => {
    return (
      <div className="statistics-module-background statistics-word-difficulty-container">
        <Help
          tip={getModuleTip("word-difficulty")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"字词难度"} />
        <div className="statistics-word-difficulty-watch-container">
          <div
            id="charDifficulty"
            className="statistics-entirety-style-watch-item"
          />
          <div
            id="wordDifficulty"
            className="statistics-entirety-style-watch-item"
          />
        </div>

        <div className="statistics-word-difficulty-tip-text">
          根据当前文本
        </div>
        <div className="statistics-entirety-data-sentence-count-text">{`推测本文平均字难度：${Math.floor(
          data.charDifficulty[0].value * 100
        )} ；平均词难度：${Math.floor(
          data.wordDifficulty[0].value * 100
        )}`}</div>
      </div>
    );
  };

  if (!data) {
    return null;
  }

  return (
    <div className="statistics-single-container">
      {renderPronunciation()}
      {renderGlyph()}
      {renderMeaning()}
      {renderDifficulty()}
    </div>
  );
};

export default Word;
