import React, { useEffect, useState } from "react";
import { Icon, Button, Spin } from "antd";
import "../styles.css";
import FeedbackList from "../../feedbackComponents/FeedbackList";
import "../styles.css";

const MISTAKE_TYPE = ["字词错误", "语法错误", "语义错误", "规范错误"];

const FeedbackModule = (props) => {
  let {
    isEditModule,
    feedbackData,
    feedbackLoading,
    wordCount,
    getFeedbackData,
    omitAllMistake,
    correctAllMistake,
    feedbackTabKey,
    omitMistake,
    noMistake,
    correctMistake,
    setFeedbackTabKey,
  } = props;

  const stripHtml = (html) => {
    var regex = /(<((?!br)[^>]+)>)/gi;
    return html.replace(regex, "");
  };

  return (
    <div className="writing-module-feedback-main">
      <div
        className="feedback-module-container"
        style={{
          paddingTop: isEditModule ? 13 : 30,
        }}
      >
        <Spin spinning={feedbackLoading} style={{ marginTop: 50 }}>
          {/* <div className="feedback-toolbar-container">
                            <div className={"feedback-list-button" + (feedbackTabKey == 1 ? ' select' : '')} onClick={() => setFeedbackTabKey('1')}>校对</div>
                            <div className={"feedback-list-button" + (feedbackTabKey == 2 ? ' select' : '')} onClick={() => setFeedbackTabKey('2')}>审核</div>
                        </div> */}
        </Spin>
        <FeedbackList
          isEditModule={isEditModule}
          TabKey={"0"}
          noMistake={noMistake}
          omitAllMistake={omitAllMistake}
          wordCount={wordCount}
          getFeedbackData={getFeedbackData}
          correctAllMistake={correctAllMistake}
          feedbackTabKey={feedbackTabKey}
          omitMistake={omitMistake}
          correctMistake={correctMistake}
          feedbackData={feedbackData}
          ignoreWords={[]}
          filteredData={feedbackData}
          hideIgnoredData={[]}
          setActiveId={() => {}}
          activeId={"0"}
        />
      </div>
    </div>
  );
};

export default FeedbackModule;
