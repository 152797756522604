import * as React from "react";
import configs from '../../configs';

const WxLogin = window.WxLogin;

// weixin_appid=wxdc3ac3584e81359e
// weixin_secret=14ef2f7b508b39cebc8f5a99793288cc

const WechatLogin = props => {
  const { style } = props;
  React.useEffect(() => {
    const redirectUri = encodeURIComponent(configs.redirectUrl);
    console.log("Redirect URI:", redirectUri);

    let obj = new WxLogin({
      id: "login_container",
      appid: configs.appid,
      scope: "snsapi_login",
      redirect_uri: redirectUri,
      state: Math.floor(Math.random() * 999999999) + 1,
      style: "",
      href: ""
    });
  }, []);

  return <div id="login_container" style={{...style, display: "flex", justifyContent: "center"}} />;
};

export default WechatLogin;
