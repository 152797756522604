import * as React from "react";
import "./styles.css";

//引入的库组件
import { Avatar, Row, Col, message, Icon, Upload } from "antd";

//引入的其他页面组件
import PhoneEditorModal from "./phoneEditorModal";
import PenNameEditorModal from "./penNameEditorModal";
import timeLocalStorage from "../../utils/timelocalstorage";
import CropperModal from "../Components/CropperModal/index";
import { convertToZH } from 'aw-error';

//graphql查询
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import {
  useUpdateUserMutation,
  useResetPhoneMutation,
} from "../../generated/graphql";

//引入的资源图片
import EditIcon from "../../assets/sider/edit.png";
import BackIcon from "../../assets/back-white.png";
import CameraIcon from "../../assets/login/change-avatar.png";
import DefaultAvatar from "../../assets/default_avatar.png";

const GET_USER_INFO = gql`
  {
    userInfoClient @client
  }
`;

const type = ["image/jpeg", "image/png"];
const size = 5;

const User = (props) => {
  const { history } = props;

  const client = useApolloClient();
  const [isShowPhoneEditor, setIsShowPhoneEditor] = React.useState(false);
  const [isShowPenNameEditor, setIsShowPenNameEditor] = React.useState(false);
  const [isUpdateUserLoading, setIsUpdateUserLoading] = React.useState(false);
  const [isUpdatePhoneLoading, setIsUpdatePhoneLoading] = React.useState(false);
  const [showAvatarChange, setShowAvatarChange] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const { data: getUserInfoData } = useQuery(GET_USER_INFO);
  const { userInfoClient } = getUserInfoData;
  let userInfo = JSON.parse(userInfoClient);

  const [updateUserMutation] = useUpdateUserMutation();
  const [resetPhoneMutation] = useResetPhoneMutation();

  const handleOnOkPhoneEditor = (values) => {
    setIsShowPhoneEditor(false);
    setIsUpdatePhoneLoading(true);
    let phone = values.phone;
    let verifyCode = values.verify;
    let input = {
      newPhone: phone,
      verificationCode: verifyCode,
    };
    resetPhoneMutation({ variables: { input } }).then((res) => {
      if (!res.data.resetPhone.successful) {
        // message.error(res.data.resetPhone.messages[0].message);
        message.error(convertToZH(res.data.resetPhone.messages[0]));
      } else {
        let userAuth = res.data.resetPhone.result;
        let userInfo = timeLocalStorage.get("USERINFO");
        userInfo.userAuths = [userAuth];
        timeLocalStorage.set("USERINFO", userInfo, 60 * 60 * 24 * 7);
        client.writeData({
          data: { userInfoClient: JSON.stringify(userInfo) },
        });
        message.success("修改成功");
      }
      setIsUpdatePhoneLoading(false);
    });
  };

  const handleOnOkPenNameEditor = (inputStr) => {
    setIsShowPenNameEditor(false);
    setIsUpdateUserLoading(true);
    updateUserMutation({ variables: { input: { nickname: inputStr } } }).then(
      (res) => {
        if (!res.data.updateUser.successful) {
          // message.error(res.data.updateUser.messages[0].message);
          message.error(convertToZH(res.data.updateUser.messages[0]));
        } else {
          let userInfo = res.data.updateUser.result;
          timeLocalStorage.set("USERINFO", userInfo, 60 * 60 * 24 * 7);
          client.writeData({
            data: { userInfoClient: JSON.stringify(userInfo) },
          });
          message.success("修改成功");
        }
        setIsUpdateUserLoading(false);
      }
    );
  };

  const handleOnAvatar = (src) => {
    updateUserMutation({ variables: { input: { avatar: src } } }).then(
      (res) => {
        if (!res.data.updateUser.successful) {
          // message.error(res.data.updateUser.messages[0].message);
          message.error(convertToZH(res.data.updateUser.messages[0]));
        } else {
          let userInfo = res.data.updateUser.result;
          timeLocalStorage.set("USERINFO", userInfo, 60 * 60 * 24 * 7);
          client.writeData({
            data: { userInfoClient: JSON.stringify(userInfo) },
          });
          message.success("修改成功");
        }
      }
    );
  };

  const getUserPhone = (userInfo) => {
    let userAuth = userInfo.userAuths[0];
    return userAuth.identifier;
  };

  const beforeUpload = (file) => {
    let isType = true;
    let isSize = true;
    if (type) {
      isType = type.includes(file.type);
    }
    if (size) {
      isSize = file.size / 1024 / 1024 < size;
    }

    if (!isType) {
      message.error(`不支持 ${file.type} 文件类型`);
    }
    if (!isSize) {
      message.error(`文件限制 ${size} M`);
    }

    if (isType && isSize) {
      setFile(file);
    }
    return false;
  };

  return (
    <div className="user-setting-page">
      <div className="user-setting-page-title-container">
        <div
          className="user-setting-page-title-back"
          onClick={() => {
            history.goBack();
          }}
        >
          <img
            src={BackIcon}
            style={{ width: "6px", height: "10px", marginRight: "14px" }}
          />
          {"账号设置"}
        </div>
        <div className="user-setting-page-title-avatar-container">
          <div className="user-setting-page-title-avatar-background">
            <Avatar
              src={userInfo.avatar !== "" ? userInfo.avatar : DefaultAvatar}
              size={60}
              onMouseEnter={(e) => {
                setShowAvatarChange(true);
              }}
            />
            {
              <Upload
                name="file"
                listType="text"
                showUploadList={false}
                beforeUpload={beforeUpload}
                className={
                  showAvatarChange
                    ? "user-setting-page-title-avatar-modify-container"
                    : "user-setting-page-title-avatar-modify-container-none"
                }
              >
                <div
                  className="user-setting-page-title-avatar-modify"
                  onMouseOut={() => {
                    setShowAvatarChange(false);
                  }}
                >
                  <img
                    src={CameraIcon}
                    style={{
                      color: "#FFFFFF",
                      pointerEvents: "none",
                      width: "26px",
                      height: "22px",
                    }}
                  />
                </div>
              </Upload>
            }
          </div>
        </div>
      </div>
      <div className="user-setting-page-user-info-container">
        <div className="user-setting-page-user-info-block">
          <Row gutter={8} className="user-setting-page-user-info-line">
            <Col span={4}>用户名</Col>
            <Col span={18}>{userInfo.username}</Col>
            <Col span={2}></Col>
          </Row>
          <Row gutter={8} className="user-setting-page-user-info-line">
            <Col span={4}>笔名</Col>
            <Col span={18}>
              {isUpdateUserLoading ? (
                <Icon type="loading" />
              ) : (
                userInfo.nickname
              )}
            </Col>
            <Col span={2}>
              <img
                src={EditIcon}
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                onClick={() => {
                  setIsShowPenNameEditor(true);
                }}
              />
            </Col>
          </Row>
          <Row gutter={8} className="user-setting-page-user-info-line">
            <Col span={4}>手机号</Col>
            <Col span={18}>
              {isUpdatePhoneLoading ? (
                <Icon type="loading" />
              ) : (
                getUserPhone(userInfo)
              )}
            </Col>
            <Col span={2}>
              <img
                src={EditIcon}
                style={{ width: "18px", height: "18px", cursor: "pointer" }}
                onClick={() => {
                  setIsShowPhoneEditor(true);
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <PhoneEditorModal
        showModal={isShowPhoneEditor}
        handleOnOk={handleOnOkPhoneEditor}
        handleOnCancel={() => {
          setIsShowPhoneEditor(false);
        }}
      />
      <PenNameEditorModal
        showModal={isShowPenNameEditor}
        handleOnOk={handleOnOkPenNameEditor}
        handleOnCancel={() => {
          setIsShowPenNameEditor(false);
        }}
      />
      <CropperModal
        file={file}
        successCallback={handleOnAvatar}
        isSetMaxSize={true}
        maxSize={{ width: 1024 }}
      />
    </div>
  );
};

export default User;
