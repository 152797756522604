import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Menu, Dropdown } from "antd";

//引入的其他页面组件

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";

//引入的资源图片
import CardCoountIcon from "../../../assets/project/card-count-icon.png";
import EditIcon from "../../../assets/project/edit.png";

const FolderItem = (props) => {
  const {
    itemClick,
    item,
    children,
    setSelectedFolder,
    setSelectedCard,
    modifyNameCallback,
    deleteCallback,
    isHoverOutLine = false
  } = props;
  const [hover, setHover] = React.useState(false);

  const editOverlay = () => {
    return (
      <Menu
        className="corpus-folder-dropdown-container"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          switch (e.key) {
            case "1":
              if(modifyNameCallback){
                setSelectedCard(item);
                modifyNameCallback();
              }
              break;
            case "2":
              if(deleteCallback){
                setSelectedCard(item);
                deleteCallback();
              }
              break;
          }
        }}
      >
        <Menu.Item key={"1"} className="corpus-folder-dropdown-item-container">
          <div className="corpus-folder-dropdown-item">修改名称</div>
        </Menu.Item>
        <Menu.Item key={"2"} className="corpus-folder-dropdown-item-container">
          <div className="corpus-folder-dropdown-item">删除</div>
        </Menu.Item>
      </Menu>
    );
  };

  let className = "corpus-folder-item-container";
  if (isHoverOutLine && hover) {
    className = className + " corpus-folder-dropdown-container-outline";
  }

  return (
    <div
      className={className}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        itemClick();
        setSelectedFolder(item);
      }}
    >
      <div className="corpus-folder-item-layer3" />
      <div className="corpus-folder-item-layer2" />
      <div className="corpus-folder-item-layer1">
        <div className="corpus-folder-item-text">{item.title}</div>
        <div className="corpus-folder-item-count-container">
          <img src={CardCoountIcon} className="corpus-folder-item-count-icon" />
          <div className="corpus-folder-item-count-text">
            {children.length}张
          </div>
        </div>
      </div>
      <Dropdown
        trigger={["hover"]}
        placement="bottomCenter"
        overlay={editOverlay}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <img
          src={EditIcon}
          className="corpus-folder-item-edit"
          style={!hover ? { display: "none" } : {}}
        />
      </Dropdown>
    </div>
  );
};

export default FolderItem;
