import * as React from "react";
import "./styles.css";

//引入的库组件
import { Progress } from "antd";

//引入的其他页面组件
import Help from "./components/help";
import Title from "./components/title";
import { getModuleTip } from "./moduleTips";
import { PieChart } from "./components/pieChart";

//graphql查询

//引入的资源图片
import NegativeIcon from "../../../assets/writing/statistic/negative.png";
import PostiveIcon from "../../../assets/writing/statistic/postive.png";

const syntaxLengthColor = ["#FFA7EE", "#17D2FF", "#2BDEB6"];

const Sentence = (props) => {
  const { data } = props;

  React.useEffect(() => {
    if (data) {
      makeSyntaxLengthChart(
        data.syntaxLength,
        "syntaxLength",
        "句长",
        syntaxLengthColor
      );
    }
  }, [data]);

  const makeSyntaxLengthChart = (chartData, id, content, colors) => {
    PieChart(chartData, id, content, colors);
  };

  const renderSyntaxLength = () => {
    return (
      <div className="statistics-module-background statistics-syntax-length-container">
        <Help
          tip={getModuleTip("syntax-length")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"句长"} />
        <div id="syntaxLength" className="statistics-syntax-length-chart" />
        <div className="statistics-syntax-length-count-container">
          <div className="statistics-syntax-length-count-line-contaier">
            <div className="statistics-syntax-length-count-item">
              <div
                className="statistics-syntax-length-count-item-dot"
                style={{ background: syntaxLengthColor[0] }}
              />
              <div className="statistics-syntax-length-count-item-tip">
                {data.syntaxLength[0].item}
              </div>
              <div className="statistics-syntax-length-count-item-count">
                {data.syntaxLength[0].count}
              </div>
            </div>
            <div className="statistics-syntax-length-count-item">
              <div
                className="statistics-syntax-length-count-item-dot"
                style={{ background: syntaxLengthColor[1] }}
              />
              <div className="statistics-syntax-length-count-item-tip">
                {data.syntaxLength[1].item}
              </div>
              <div className="statistics-syntax-length-count-item-count">
                {data.syntaxLength[1].count}
              </div>
            </div>
          </div>
          <div className="statistics-syntax-length-count-line-contaier">
            <div className="statistics-syntax-length-count-item">
              <div
                className="statistics-syntax-length-count-item-dot"
                style={{ background: syntaxLengthColor[2] }}
              />
              <div className="statistics-syntax-length-count-item-tip">
                {data.syntaxLength[2].item}
              </div>
              <div className="statistics-syntax-length-count-item-count">
                {data.syntaxLength[2].count}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <div className="statistics-module-background statistics-syntax-form-container">
        <Help
          tip={getModuleTip("syntax-form")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"句型"} />
        <div className="statistics-word-meaning-progress-container">
          {data.form.map((item, index) => {
            return (
              <div
                key={"syntax-form-" + index}
                className="statistics-word-meaning-progress-item"
              >
                <div className="statistics-syntax-form-progress-item-name">
                  {item.item}
                </div>
                <Progress
                  className="statistics-syntax-form-progress-item-bar"
                  percent={item.percent}
                  showInfo={false}
                  strokeColor={item.color}
                />
                <div className="statistics-word-meaning-progress-item-count">
                  {item.count}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderPattern = () => {
    return (
      <div className="statistics-module-background statistics-syntax-pattern-container">
        <Help
          tip={getModuleTip("syntax-pattern")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"句类"} />
        <div className="statistics-word-meaning-progress-container">
          {data.pattern.map((item, index) => {
            return (
              <div
                key={"syntax-pattern-" + index}
                className="statistics-word-meaning-progress-item"
              >
                <div className="statistics-syntax-form-progress-item-name">
                  {item.item}
                </div>
                <Progress
                  className="statistics-syntax-form-progress-item-bar"
                  percent={item.percent}
                  showInfo={false}
                  strokeColor={item.color}
                />
                <div className="statistics-word-meaning-progress-item-count">
                  {item.count}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderMeaning = () => {
    return (
      <div className="statistics-module-background statistics-syntax-pattern-container">
        <Help
          tip={getModuleTip("syntax-meaning")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"句义"} />
        <div className="statistics-syntax-meaning-container">
          <div className="statistics-syntax-meaning-item">
            <div
              className="statistics-syntax-meaning-item-text"
              style={{ color: "#FF859A" }}
            >
              {data.negative}
            </div>
            <div className="statistics-syntax-meaning-item-tip-container">
              <img
                src={NegativeIcon}
                className="statistics-syntax-meaning-item-tip-icon"
              />
              <div className="statistics-syntax-meaning-item-tip-text">
                否定句数
              </div>
            </div>
          </div>
          <div className="statistics-syntax-meaning-item">
            <div
              className="statistics-syntax-meaning-item-text"
              style={{ color: "#7ED321" }}
            >
              {data.postive}
            </div>
            <div className="statistics-syntax-meaning-item-tip-container">
              <img
                src={PostiveIcon}
                className="statistics-syntax-meaning-item-tip-icon"
              />
              <div className="statistics-syntax-meaning-item-tip-text">
                肯定句数
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!data) {
    return null;
  }

  return (
    <div className="statistics-single-container">
      {renderSyntaxLength()}
      {renderForm()}
      {renderPattern()}
      {renderMeaning()}
    </div>
  );
};

export default Sentence;
