import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件

//graphql查询

//引入的资源图片

let buttons = [
  {
    icon: require("../../../assets/writing/rightside/proof.png"),
    name: "校对",
  },
  {
    icon: require("../../../assets/writing/rightside/verify.png"),
    name: "审核",
  },
  {
    icon: require("../../../assets/writing/rightside/statistic.png"),
    name: "统计",
  },
  {
    icon: require("../../../assets/writing/rightside/ins.png"),
    name: "灵感",
  },
  {
    icon: require("../../../assets/writing/rightside/close.png"),
    name: "关闭",
  },
];

const RightSideBar = (props) => {
  const {
    isOpen,
    hideProofreadingBar,
    omitAllMistake,
    setProofReadingBar,
    FeedbackModule,
    InspirationModule,
    Statistics,
    setFeedbackTabKey,
  } = props;

  const [isShowButtonText, setIsShowButtonText] = React.useState(false);
  const [currentButtonIndex, setCurrentButtonIndex] = React.useState(
    buttons.length - 1
  );

  React.useEffect(() => {
    if (!isOpen) {
      setFeedbackTabKey("0");
      setIsShowButtonText(false);
      setCurrentButtonIndex(buttons.length - 1);
    }
  }, [isOpen]);

  const buttonClick = (index) => {
    if (currentButtonIndex === index) {
      return;
    }
    if (index === buttons.length - 1) {
      omitAllMistake();
      setProofReadingBar("backward");
      setIsShowButtonText(false);
    } else {
      if (hideProofreadingBar) {
        setProofReadingBar("forward");
      }
      switch (index) {
        case 0:
          setFeedbackTabKey("1");
          break;
        case 1:
          setFeedbackTabKey("2");
          break;
        case 2:
        case 3:
          omitAllMistake();
          break;
      }
    }
    setCurrentButtonIndex(index);
  };

  const renderContent = () => {
    if (currentButtonIndex === 0 || currentButtonIndex === 1) {
      return FeedbackModule();
    } else if (currentButtonIndex === 2) {
      return Statistics();
    } else if (currentButtonIndex === 3) {
      return InspirationModule();
    }
    return null;
  };

  return (
    <div
      className={
        "writing-right-side-background" +
        (isOpen
          ? " writing-right-side-background-show"
          : " writing-right-side-background-hide")
      }
    >
      <div
        className={
          "writing-right-side-button-container" +
          (!isOpen && isShowButtonText
            ? " writing-right-side-button-container-with-text"
            : " writing-right-side-button-container-no-text") +
          (!isOpen
            ? " writing-right-side-button-container-close"
            : " writing-right-side-button-container-open")
        }
        onMouseOver={() => {
          setIsShowButtonText(true);
        }}
        onMouseLeave={() => {
          setIsShowButtonText(false);
        }}
      >
        {buttons.map((item, index) => {
          if (!isOpen && index === buttons.length - 1) {
            return null;
          }
          return (
            <div
              id={"right-side-button-" + index}
              key={"right-side-button-" + index}
              className={
                "writing-right-side-button-background" +
                (!isOpen && isShowButtonText
                  ? " writing-right-side-button-background-with-text"
                  : " writing-right-side-button-background-no-text") +
                (index === currentButtonIndex
                  ? " writing-right-side-button-background-selected"
                  : "")
              }
              onClick={() => {
                buttonClick(index);
              }}
            >
              <img src={item.icon} className="writing-right-side-button-icon" />
              {!isOpen && isShowButtonText && (
                <div className="writing-right-side-button-text">
                  {item.name}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {renderContent()}
    </div>
  );
};

export default RightSideBar;
