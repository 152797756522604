import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Modal, Spin } from "antd";
import download from "downloadjs";

//引入的其他页面组件
import SelectRadioSingle from "../SelectRadioSingle/index";
import configs from "../../../configs";
import headers from "../../../GlobalData/headers";

//引入的资源图片
import CloseIcon from "../../../assets/globalmodal/close.png";

const ExportFormat = [
  {
    name: "Markdown",
    format: ".md",
    icon: require("../../../assets/import-export/MD.png"),
  },
  {
    name: "Txt",
    format: ".txt",
    icon: require("../../../assets/import-export/TXT.png"),
  },
  {
    name: "Rtf",
    format: ".rtf",
    icon: require("../../../assets/import-export/RTF.png"),
  },
  {
    name: "Docx",
    format: ".docx",
    icon: require("../../../assets/import-export/DOCX.png"),
  },
  {
    name: "Html",
    format: ".html",
    icon: require("../../../assets/import-export/HTML.png"),
  },
  {
    name: "Pdf",
    format: ".pdf",
    icon: require("../../../assets/import-export/PDF.png"),
  },
  {
    name: "Epub",
    format: ".epub",
    icon: require("../../../assets/import-export/EPUB.png"),
  },
  {
    name: "Opml",
    format: ".opml",
    icon: require("../../../assets/import-export/OPML.png"),
  },
];

const ExportModal = (props) => {
  const { exportInfo, setExportInfo, isRootNode } = props;
  const [selectedFormatIndex, setSelectedFormatIndex] = React.useState(0);
  const [exportType, setExportType] = React.useState(0);
  const [exportTarget, setExportTarget] = React.useState(0);
  const [loadingFile, setLoadingFile] = React.useState(false);

  React.useEffect(() => {
    if (exportInfo.isSingleCard) {
      setExportType(1);
      setExportTarget(1);
    } else {
      setExportType(0);
      setExportTarget(0);
    }
  }, [exportInfo]);

  const typeSelect = (e) => {
    setExportType(e.target.value);
  };

  const targetSelect = (e) => {
    setExportTarget(e.target.value);
  };

  const submitExport = () => {
    let searchArray = [];
    searchArray.push(`project_id=${exportInfo.projectId}`);
    searchArray.push(`node_id=${exportInfo.selectedNodeAncestors[0]}`);
    let format = ExportFormat[selectedFormatIndex].format.split(".")[1];
    searchArray.push(`target_type=${format}`);
    let isSingle = exportType === 0;
    searchArray.push(`single=${isSingle}`);
    if (!isSingle) {
      let isWithChildren = exportTarget === 0;
      searchArray.push(`without_children=${!isWithChildren}`);
      searchArray.push(`numbered=${true}`);
    }
    let search = "?" + searchArray.join("&");

    let exportUrl = configs.exportUrl + search;
    const req = {
      method: "GET",
      headers: headers,
    };
    setLoadingFile(true);
    fetch(exportUrl, req)
      .then(async (response) => {
        let fileName = response.headers
          .get("content-disposition")
          .split("filename=")[1];
        fileName = fileName.substring(0, fileName.lastIndexOf("."));
        setLoadingFile(false);
        if (response.status === 500) return;
        const pdfBlob = await response.blob();
        download(pdfBlob, decodeURIComponent(fileName));
      })
      .catch(() => {
        setLoadingFile(false);
        message.error("服务器错误，导出失败");
      });
  };

  return (
    <Modal
      className="export-modal"
      visible={exportInfo.isShowExportModal}
      closable={false}
      footer={null}
      maskClosable={false}
      bodyStyle={{ width: "660px", padding: 0 }}
    >
      <Spin tip={"正在导出文件..."} spinning={loadingFile}>
        <img
          src={CloseIcon}
          className="export-modal-close-btn"
          onClick={() => {
            setExportInfo({
              isShowExportModal: false,
              selectedNodeAncestors: [],
              projectId: "",
            });
          }}
        />
        <div className="export-modal-container">
          <div className="export-modal-content-title">导出</div>
          <div className="export-modal-content-format-container">
            {ExportFormat.map((item, index) => {
              return (
                <div
                  className={
                    "export-modal-content-format-single-container" +
                    (index === selectedFormatIndex
                      ? " export-modal-content-format-single-container-selected"
                      : "")
                  }
                  onClick={() => {
                    if (index !== selectedFormatIndex) {
                      setSelectedFormatIndex(index);
                    }
                  }}
                >
                  <img
                    src={item.icon}
                    className="export-modal-content-format-single-icon"
                  />
                  <div className="export-modal-content-format-single-name">
                    {item.name}
                  </div>
                  <div className="export-modal-content-format-single-format">
                    {item.format}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="export-modal-radio-main-container">
            <div className="export-modal-radio-type-select">
              <SelectRadioSingle
                style={{ width: "65%" }}
                isChecked={exportType === 0}
                onSelected={() => {
                  setExportType(0);
                }}
                text={"导出所有卡片为一个文件"}
              />
              <SelectRadioSingle
                style={{ width: "40%" }}
                isChecked={exportType === 1}
                onSelected={() => {
                  setExportType(1);
                }}
                text={"每张卡片为一个文件"}
              />
            </div>
            {exportType === 1 && (
              <div className="export-modal-radio-type-divider" />
            )}
            {exportType === 1 && (
              <div className="export-modal-radio-target-select">
                <SelectRadioSingle
                  style={{ width: "65%" }}
                  isChecked={exportTarget === 0}
                  onSelected={() => {
                    setExportTarget(0);
                  }}
                  text={"导出当前卡片及其子卡片"}
                />
                <SelectRadioSingle
                  style={{ width: "40%" }}
                  isChecked={exportTarget === 1}
                  onSelected={() => {
                    setExportTarget(1);
                  }}
                  text={"仅导出当前卡片"}
                />
              </div>
            )}
          </div>
          <div
            className="export-modal-submit-button"
            onClick={() => {
              submitExport();
            }}
          >
            <div className="export-modal-submit-button-text">导出</div>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ExportModal;
