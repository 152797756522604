export const getUrlSearch = (url = "") => {
  let checkUrl = url !== "" ? url : window.location.search;
  if (checkUrl && checkUrl.indexOf("?") !== -1) {
    let str = checkUrl.substr(1);
    let strs = str.split("&");
    let params = {};
    for (let i = 0; i < strs.length; i++) {
      let index = strs[i].indexOf("=");
      params[strs[i].substring(0, index)] = strs[i].substring(index + 1);
    }
    return params;
  }
  return {};
};

export const getUrlPath = (url = "") => {
  let checkUrl = url !== "" ? url : window.location.pathname;
  let pathArray = checkUrl.split("/");
  pathArray.splice(0, 1);
  return pathArray;
};
