import * as React from "react";
import "./styles.css";

//引入的库组件
import { Spin } from "antd";
import { Switch, Route, Redirect, Router } from "react-router";
import { createBrowserHistory } from "history";

//引入的其他页面
import Main from "../Router_Main/index";
import Template from "../Router_Template/index";
import Corpus from "../Router_Corpus/index";
import Project from "../Router_Project/index";
import Service from "../Router_Services//index";
import Inspiration from "../Router_Inspiration/index";
import User from "../User/index";

//graphql查询
import {
  useGetProjectListQuery,
  ProjectType,
  SortOrder,
} from "../../generated/graphql";

export const history = createBrowserHistory();

const AiWriterRouter = (props) => {
  const { setIsLogin } = props;

  history.listen(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  });

  const { refetch: getProjectListQuery } = useGetProjectListQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const getList = () => {
    let input = {
      first: 1,
      after: undefined,
      before: undefined,
      last: undefined,
      order: SortOrder.Desc,
      filter: {
        types: [ProjectType.User],
      },
    };
    getProjectListQuery(input).then((res) => {
      if (res.data.projects) {
        let projects = res.data.projects.edges.map((item, index) => {
          return item.node;
        });

        if (projects.length > 0) {
          let id = projects[0].code;
          history.push(
            `/project/writing/p/` + window.atob(id).split("Project:")[1]
          );
        } else {
          history.push(`/template`);
        }
      } else {
        history.push(`/template`);
      }
    });
  };

  const renderLoading = () => {
    return (
      <Spin spinning={true} wrapperClassName="loading">
        <div style={{ width: "100%", height: "100%" }} />
      </Spin>
    );
  };

  const routerContent = React.useMemo(() => {
    return (
      <Router history={history}>
        <Main history={history}>
          <Switch>
            <Route
              key={"/template"}
              path="/template"
              component={(props) => {
                return <Template {...props} setIsLogin={setIsLogin} />;
              }}
            />
            <Route
              key={"/project"}
              path="/project"
              component={(props) => {
                return <Project {...props} setIsLogin={setIsLogin} />;
              }}
            />
            <Route
              key={"/corpus"}
              path="/corpus"
              component={(props) => {
                return <Corpus {...props} setIsLogin={setIsLogin} />;
              }}
            />
            <Route
              key={"/idea"}
              path="/idea"
              component={(props) => {
                return <Inspiration {...props} setIsLogin={setIsLogin} />;
              }}
            />
            <Route
              key={"/service"}
              path="/service"
              component={(props) => {
                return <Service {...props} setIsLogin={setIsLogin} />;
              }}
            />
            <Route
              key={"/user"}
              path="/user"
              component={(props) => {
                return <User {...props} />;
              }}
            />
            <Route
              key={"root-/"}
              path="/"
              render={() => {
                getList();
                return renderLoading();
              }}
            />
          </Switch>
        </Main>
      </Router>
    );
  }, []);

  return <>{routerContent}</>;
};

export default AiWriterRouter;
