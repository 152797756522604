import * as React from "react";
import "./styles.css";
import { CardType } from "../../generated/graphql";

//引入的库组件
import copy from "copy-to-clipboard";
import { message, Input } from "antd";

//引入的其他页面组件

//graphql查询

//引入的资源图片
import GoldenBg from "../../assets/corpus/golden-card-background.png";
import WikiBg from "../../assets/corpus/wiki-card-background.png";
import CopyIcon from "../../assets/corpus/copy.png";
import CollectIcon from "../../assets/corpus/collection.png";
import MarkIcon from "../../assets/corpus/mark.png";

const CorpusCardDetailContainer = (props) => {
  const {
    cardInfo,
    updateCardInfo,
    canEdit = true,
    collectCard = null,
  } = props;

  let author =
    cardInfo.metadata && cardInfo.metadata.author
      ? cardInfo.metadata.author
      : "无";
  let origin =
    cardInfo.metadata && cardInfo.metadata.origin
      ? cardInfo.metadata.origin
      : "无";

  const [titleString, setTitleString] = React.useState(cardInfo.title);
  const [contentString, setContentString] = React.useState(cardInfo.content);
  const [authorString, setAuthorString] = React.useState(author);
  const [originString, setOriginString] = React.useState(origin);
  let hasCollectBtn = collectCard !== null;

  const renderGoldenCard = () => {
    return (
      <>
        <div className="corpus-card-golden-title-back-img-container">
          {canEdit ? (
            <Input
              className="corpus-card-golden-title-text-title"
              defaultValue={titleString}
              onChange={(e) => {
                setTitleString(e.target.value);
              }}
              onBlur={() => {
                if (updateCardInfo && titleString !== cardInfo.title) {
                  updateCardInfo({
                    title: titleString,
                  });
                }
              }}
            />
          ) : (
            <div className="corpus-card-golden-title-text-title">
              {titleString}
            </div>
          )}

          <img src={GoldenBg} className="corpus-card-golden-title-back-img" />
          <img
            src={CopyIcon}
            className={
              hasCollectBtn
                ? "corpus-card-golden-copy-button-with-collect"
                : "corpus-card-golden-copy-button"
            }
            onClick={() => {
              copy(cardInfo.content);
              message.success("内容已复制到剪贴板");
            }}
          />
          {hasCollectBtn && (
            <img
              src={CollectIcon}
              className="corpus-card-golden-collect-button"
              onClick={() => {
                collectCard(cardInfo);
              }}
            />
          )}
        </div>
        <img src={MarkIcon} className="corpus-card-golden-mark" />
        <div className="corpus-card-golden-content">
          {canEdit ? (
            <Input.TextArea
              className="corpus-card-golden-content-text"
              defaultValue={contentString}
              autoSize={{ minRows: 10, maxRows: 10 }}
              onChange={(e) => {
                setContentString(e.target.value);
              }}
              onBlur={() => {
                if (updateCardInfo && contentString !== cardInfo.content) {
                  updateCardInfo({ content: contentString });
                }
              }}
            />
          ) : (
            <div className="corpus-card-golden-content-text">
              {contentString}
            </div>
          )}
        </div>
        <div className="corpus-card-golden-bottom">
          <div className="corpus-card-golden-bottom-divider" />
          <div className="corpus-card-golden-bottom-work-container">
            <div className="corpus-card-golden-bottom-work-text">
              {`引自：`}
            </div>
            {canEdit ? (
              <Input
                className="corpus-card-single-content-text"
                defaultValue={originString}
                onChange={(e) => {
                  setOriginString(e.target.value);
                }}
                onBlur={() => {
                  if (updateCardInfo && originString !== origin) {
                    updateCardInfo({
                      metadata: {
                        ...cardInfo.metadata,
                        origin: originString,
                      },
                    });
                  }
                }}
              />
            ) : (
              <div className="corpus-card-golden-bottom-work-text">
                {originString}
              </div>
            )}
          </div>
          <div className="corpus-card-golden-bottom-writer-container">
            <div className="corpus-card-golden-bottom-writer-text">
              {`作者：`}
            </div>
            {canEdit ? (
              <Input
                className="corpus-card-single-content-text"
                defaultValue={authorString}
                onChange={(e) => {
                  setAuthorString(e.target.value);
                }}
                onBlur={() => {
                  if (updateCardInfo && authorString !== author) {
                    updateCardInfo({
                      metadata: {
                        ...cardInfo.metadata,
                        author: authorString,
                      },
                    });
                  }
                }}
              />
            ) : (
              <div className="corpus-card-golden-bottom-writer-text">
                {authorString}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  const renderWikiCard = () => {
    return (
      <>
        <div className="corpus-card-wiki-title-container">
          {canEdit ? (
            <Input
              className="corpus-card-wiki-title-text-title"
              defaultValue={titleString}
              onChange={(e) => {
                setTitleString(e.target.value);
              }}
              onBlur={() => {
                if (updateCardInfo && titleString !== cardInfo.title) {
                  updateCardInfo({
                    title: titleString,
                  });
                }
              }}
            />
          ) : (
            <div className="corpus-card-wiki-title-text-title">
              {titleString}
            </div>
          )}

          <div className="corpus-card-wiki-title-work-container">
            <div className="corpus-card-wiki-title-work-text">{`引自：`}</div>
            {canEdit ? (
              <Input
                className="corpus-card-single-content-text"
                defaultValue={originString}
                onChange={(e) => {
                  setOriginString(e.target.value);
                }}
                onBlur={() => {
                  if (updateCardInfo && originString !== origin) {
                    updateCardInfo({
                      metadata: {
                        ...cardInfo.metadata,
                        origin: originString,
                      },
                    });
                  }
                }}
              />
            ) : (
              <div className="corpus-card-wiki-title-work-text">
                {originString}
              </div>
            )}
          </div>
          <div className="corpus-card-wiki-title-writer-container">
            <div className="corpus-card-wiki-title-writer-text">{`作者：`}</div>
            {canEdit ? (
              <Input
                className="corpus-card-single-content-text"
                defaultValue={authorString}
                onChange={(e) => {
                  setAuthorString(e.target.value);
                }}
                onBlur={() => {
                  if (updateCardInfo && authorString !== author) {
                    updateCardInfo({
                      metadata: {
                        ...cardInfo.metadata,
                        author: authorString,
                      },
                    });
                  }
                }}
              />
            ) : (
              <div className="corpus-card-wiki-title-writer-text">
                {authorString}
              </div>
            )}
          </div>
          <img src={WikiBg} className="corpus-card-wiki-title-back-img" />
          <img
            src={CopyIcon}
            className={
              hasCollectBtn
                ? "corpus-card-wiki-copy-button-with-collect"
                : "corpus-card-wiki-copy-button"
            }
            onClick={() => {
              copy(cardInfo.content);
              message.success("内容已复制到剪贴板");
            }}
          />
          {hasCollectBtn && (
            <img
              src={CollectIcon}
              className="corpus-card-wiki-collect-button"
              onClick={() => {
                collectCard(cardInfo);
              }}
            />
          )}
        </div>
        <div className="corpus-card-golden-bottom-divider" />
        <div className="corpus-card-wiki-content">
          {canEdit ? (
            <Input.TextArea
              className="corpus-card-golden-content-text"
              defaultValue={contentString}
              autoSize={{ minRows: 14, maxRows: 14 }}
              onChange={(e) => {
                setContentString(e.target.value);
              }}
              onBlur={() => {
                if (updateCardInfo && contentString !== cardInfo.content) {
                  updateCardInfo({ content: contentString });
                }
              }}
            />
          ) : (
            <div className="corpus-card-golden-content-text">
              {contentString}
            </div>
          )}
        </div>
      </>
    );
  };

  const renderCard = () => {
    switch (cardInfo.type) {
      case CardType.Golden:
        return renderGoldenCard();
      case CardType.Wiki:
        return renderWikiCard();
    }
  };

  return (
    <div className="corpus-card-detail-container">
      <div className="corpus-card-detail-layer-3" />
      <div className="corpus-card-detail-layer-2" />
      <div className="corpus-card-detail-layer-1">{renderCard()}</div>
    </div>
  );
};

export default CorpusCardDetailContainer;
