import * as React from "react";
import "./styles.css";

//引入的其他页面组件
import EmptyComponent from "../../Components/EmptyComponent/index";
import MasonryComponent from "./GridVirtualScroll/masonry";
import { getDataInOutline } from "../utils";
import { getOutlineIcon } from "../WritingOutline/getOutlineIcon";

//graphql查询
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

//引入的资源图片

const GET_CARD_SORD_TYPE = gql`
  {
    cardSordTypeClient @client
  }
`;

const SortableItemDetail = (props) => {
  const { id, title, content, onSelect, currentProjectInfo } = props;

  const [showCardTitleInput, setShowCardTitleInput] = React.useState(false);
  const [cardInputTitle, setCardInputTitle] = React.useState("");
  const [showCardDeleteButton, setShowCardDeleteButton] = React.useState(false);
  const [hasChildren, setHasChildren] = React.useState(false);
  const [icon, setIcon] = React.useState("");

  const [listRowCount, setListRowCount] = React.useState(
    window.innerWidth <= 1414 ? 2 : 3
  );

  React.useEffect(() => {
    if (currentProjectInfo) {
      if (id === currentProjectInfo.originalId) {
        setHasChildren(true);
        setIcon(getOutlineIcon("icon-folder"));
      } else {
        getDataInOutline(
          currentProjectInfo.outline.menu,
          id,
          (data, item, index) => {
            setHasChildren(item && item.children && item.children.length);
            if (
              item.icon === "icon-default" &&
              item.children &&
              item.children.length
            ) {
              setIcon(getOutlineIcon("icon-default-multi"));
            } else {
              setIcon(getOutlineIcon(item.icon));
            }
          }
        );
      }
    }
  }, [id, currentProjectInfo]);

  return (
    <div
      className={"card-item-main" + (hasChildren ? " folder" : "")}
      onClick={async (e) => {
        onSelect([id], null, 2);
      }}
    >
      <div className="card-title">
        <section className="card-title-text-container">
          <img
            style={{ width: "14px", height: "14px", marginLeft: "12px" }}
            src={icon}
          />
          <span
            style={{
              marginLeft: "6px",
              width: 241,
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
            className="card-mode-text"
          >
            {title}
          </span>
        </section>
      </div>
      <div className="card-text-area">
        <div
          style={{
            // 必须指定宽度
            paddingLeft: 7,
            width: 320,
            height: 166,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "7",
            WebkitBoxOrient: "vertical",
          }}
          className="card-text"
        >
          {content}
        </div>
      </div>
    </div>
  );
};

const WritingCardList = (props) => {
  const { data: cardSordType } = useQuery(GET_CARD_SORD_TYPE);
  const { cardSordTypeClient } = cardSordType;

  const { cards, cardsWithAllChilren, onSelect, currentProjectInfo } = props;

  const [cardContainerHeight, setCardContainerHeight] = React.useState(
    window.innerHeight - 95
  );

  const handleResize = () => {
    setCardContainerHeight(window.innerHeight - 95);
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  if (
    (cardSordTypeClient === 0 && cards.length === 0) ||
    (cardSordTypeClient !== 0 && cardsWithAllChilren.length === 0)
  ) {
    return (
      <div className="writing-card-list-empty-container">
        <EmptyComponent tip={"没有子卡片"} />
      </div>
    );
  }
  return (
    <div className="writing-card-list-container">
      <MasonryComponent
        list={cardSordTypeClient === 0 ? cards : cardsWithAllChilren}
        height={cardContainerHeight}
        itemSize={{ width: 340, height: 226 }}
        itemRender={SortableItemDetail}
        itemRenderProps={{ currentProjectInfo, onSelect }}
      />
    </div>
  );
};

export default WritingCardList;
