import * as React from "react";

//引入的库组件
import { Modal, message } from "antd";

//引入的其他页面组件
import configs from "../../../configs";
import headers from "../../../GlobalData/headers";
import CropperComponent from "./CropperComponent";
import { uuid } from "uuidv4";

//graphql查询

//引入的资源图片

const TOKENURL = configs.qiniuUri;
const UPLOADURL = "https://up-z1.qiniup.com";

const CropperModal = (props) => {
  const {
    file,
    successCallback,
    isUpload = true,
    aspectRatio,
    titleText = "裁剪头像",
    okText = "设置头像",
    isSetMaxSize = false,
    maxSize = { width: 0, height: 0 },
  } = props;
  const [src, setSrc] = React.useState("");
  const [visible, setVisible] = React.useState(false);

  const cropperRef = React.useRef();

  //加载文件
  React.useEffect(() => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        const dataURL = e.target.result;

        //避免图片过大导致的上传过慢
        if (isSetMaxSize) {
          const canvas = document.createElement("canvas");
          const img = document.createElement("img");
          img.src = e.target.result;
          img.onload = () => {
            const ctx = canvas.getContext("2d");
            //原始图片宽度、高度
            let originImageWidth = img.width;
            let originImageHeight = img.height;
            let targetWidth = originImageWidth;
            let targetHeight = originImageHeight;

            if (
              (maxSize.width && targetWidth > maxSize.width) ||
              (maxSize.height && targetHeight > maxSize.height)
            ) {
              if (maxSize.width && maxSize.height) {
                targetWidth = maxSize.width;
                targetHeight = maxSize.height;
              } else if (maxSize.width) {
                targetWidth = maxSize.width;
                targetHeight =
                  originImageHeight * (maxSize.width / originImageWidth);
              } else if (maxSize.height) {
                targetWidth =
                  originImageWidth * (maxSize.height / originImageHeight);
                targetHeight = maxSize.height;
              }
            }

            canvas.width = targetWidth;
            canvas.height = targetHeight;

            // 清除画布
            ctx.clearRect(0, 0, targetWidth, targetHeight);
            // 绘制图片
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            // quality值越小,图像越模糊,默认图片质量为0.98
            const imageDataURL = canvas.toDataURL(
              file.type || "image/jpeg",
              0.98
            );
            setSrc(imageDataURL);
          };
        } else {
          setSrc(dataURL);
        }
        setVisible(true);
      };
    }
  }, [file]);

  // 提交设置头像
  const handleOK = () => {
    cropperRef.current.getCroppedCanvas().toBlob(async (blob) => {
      if (!isUpload) {
        successCallback(src);
        setVisible(false);
        return;
      }
      try {
        const requestToken = await fetch(TOKENURL, {
          method: "GET",
          headers: headers,
        });
        const resultToken = await requestToken.text();
        const token = resultToken;
        const formData = new FormData();
        formData.append("token", token);
        formData.append("file", blob);
        // formData.append("key", `file/${window.btoa("Image:" + uuid())}`);//可以区分文件夹的方案
        const upload = await fetch(UPLOADURL, {
          method: "POST",
          body: formData,
        });
        const resultUpload = await upload.json();
        const src = `https://static.aiwriter.net/${resultUpload.key}`;
        successCallback(src);
        setVisible(false);
      } catch (error) {
        message.error("上传失败，请检查网络设置");
      }
    });
  };

  return (
    <Modal
      title={titleText}
      okText={okText}
      cancelText="取消"
      visible={visible}
      onOk={handleOK}
      onCancel={() => setVisible(false)}
      maskClosable={false}
    >
      <CropperComponent
        aspectRatio={aspectRatio}
        cropperRef={cropperRef}
        src={src}
        style={{ height: 400, width: "100%" }}
      />
    </Modal>
  );
};

export default CropperModal;
