import * as React from "react";
import "./styles.css";

import { Dropdown, Menu, Input } from "antd";

import SearchIcon from "../../../assets/search.png";
import NodeDownIcon from "../../../assets/node-down.png";

const SearchWithDrop = (props) => {
  const { callback, selectValues, position } = props;
  const [selectValue, setSelectValue] = React.useState(selectValues[0]);
  const [searchString, setSearchString] = React.useState("");

  const searchOverlay = () => {
    return (
      <Menu
        onClick={(e) => {
          let info = selectValues[parseInt(e.key)];
          setSelectValue(info);
        }}
        style={{ width: "80px" }}
      >
        {selectValues.map((item, index) => {
          return (
            <Menu.Item className="ui-main-title-search-item" key={index}>
              {item.value}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className="search-container" style={position ? position : {}}>
      <div className="search-block">
        <Dropdown
          className="search-select"
          trigger={["click"]}
          overlay={searchOverlay}
        >
          <div
            className="ant-dropdown-link"
            style={{ cursor: "pointer", color: "#000000" }}
          >
            <div className="search-select-text">{selectValue.value}</div>
            <img src={NodeDownIcon} className="search-select-icon" />
          </div>
        </Dropdown>
        <Input
          id="search-input-tab"
          placeholder="搜索"
          className="search-input"
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          onPressEnter={() => {
            if (callback) {
              callback(selectValue.value, searchString);
            }
          }}
        />
      </div>
    </div>
  );
};

export default SearchWithDrop;
