import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Input, Form } from "antd";

//引入的其他页面组件

//graphql查询

//引入的资源图片

const CreateCorpusCardModal = (props) => {
  const { isShow, type, cancelCallback, submitCallback, form } = props;

  let typeStr = type === "GOLDEN" ? "金句" : "知识";

  const onSubmit = () => {
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      let cardValues = {
        origin: values.origin,
        author: values.author,
        title: values.title,
        content: values.content ? values.content : "",
      };
      form.resetFields();
      submitCallback(cardValues);
    });
  };

  return (
    <Modal
      footer={null}
      centered
      className="create-corpus-card-modal-container"
      title={`请输入${typeStr}来源：`}
      visible={isShow}
      closable={false}
    >
      <Form>
        <Form.Item hasFeedback>
          {form.getFieldDecorator("origin", {
            rules: [
              {
                required: true,
                message: "请输入作品名",
              },
            ],
          })(
            <Input
              className="create-corpus-card-modal-input"
              maxLength={36}
              placeholder={"请输入作品名"}
              size={"large"}
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {form.getFieldDecorator("author", {
            rules: [
              {
                required: true,
                message: "请输入作者名",
              },
            ],
          })(
            <Input
              className="create-corpus-card-modal-input"
              maxLength={36}
              placeholder={"请输入作者名"}
              size={"large"}
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback>
          {form.getFieldDecorator("title", {
            rules: [
              {
                required: true,
                message: "请输入卡片名",
              },
            ],
          })(
            <Input
              className="create-corpus-card-modal-input"
              maxLength={36}
              placeholder={"请输入卡片名"}
              size={"large"}
            />
          )}
        </Form.Item>
        <Form.Item>
          {form.getFieldDecorator(
            "content",
            {}
          )(
            <div className="create-corpus-card-modal-content-input">
              <Input.TextArea
                className="create-corpus-card-modal-content-input"
                placeholder={"请输入内容"}
                autoSize={{ minRows: 8 }}
              />
            </div>
          )}
        </Form.Item>
      </Form>
      <div className="create-corpus-card-modal-button-container">
        <div
          className="create-corpus-card-modal-button-cancel"
          onClick={() => {
            cancelCallback();
            form.resetFields();
          }}
        >
          <div className="create-corpus-card-modal-button-cancel-text">
            取消
          </div>
        </div>
        <div
          className="create-corpus-card-modal-button-submit"
          onClick={() => {
            onSubmit();
          }}
        >
          <div className="create-corpus-card-modal-button-submit-text">
            确定
          </div>
        </div>
      </div>
    </Modal>
  );
};

const CreateCorpusCardModalWrapper = Form.create()(CreateCorpusCardModal);

export default CreateCorpusCardModalWrapper;
