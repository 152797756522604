import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Upload, Spin, Progress } from "antd";
import axios from "axios";

//引入的其他页面组件
import headers from "../../../GlobalData/headers";
import configs from "../../../configs";

//引入的资源图片
import CloseIcon from "../../../assets/globalmodal/close.png";
import Select from "../../../assets/import-export/select.png";

const ImportFormat = [
  {
    name: "Markdown",
    format: ".md",
    icon: require("../../../assets/import-export/MD.png"),
  },
  {
    name: "Txt",
    format: ".txt",
    icon: require("../../../assets/import-export/TXT.png"),
  },
  {
    name: "Rtf",
    format: ".rtf",
    icon: require("../../../assets/import-export/RTF.png"),
  },
  {
    name: "Docx",
    format: ".docx",
    icon: require("../../../assets/import-export/DOCX.png"),
  },
  {
    name: "Html",
    format: ".html",
    icon: require("../../../assets/import-export/HTML.png"),
  },
];

const uploadOptions = {
  multiple: true,
  showUploadList: false,
};

let inUpLoadCount = 0;

const ImportModal = (props) => {
  const { importInfo, setImportInfo, importSubmit } = props;

  const [selectedFormatIndex, setSelectedFormatIndex] = React.useState(0);
  const [uploadStep, setUploadStep] = React.useState(0);
  const [isUploading, setIsUploading] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [uploadFilesInfo, setUploadFilesInfo] = React.useState([]);
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [currentUploadIndex, setCurrentUploadIndex] = React.useState(-1);

  const getBodyStyle = () => {
    switch (uploadStep) {
      case 0:
        return {
          width: "660px",
          padding: 0,
        };
      case 1:
        return {
          width: "460px",
          padding: 0,
        };
    }
  };

  const closeModal = () => {
    if (loadingFile) {
      return;
    }
    setImportInfo({
      isShowImportModal: false,
      selectedNodeAncestors: [],
      projectId: "",
    });
    setFileList([]);
    setSelectedFormatIndex(0);
    setUploadStep(0);
    inUpLoadCount = 0;
  };

  const uploadEndCallback = async (res, source_type) => {
    if (res && res.data.successful) {
      let start = Date.now();
      if (importSubmit) {
        let idsLength = importInfo.selectedNodeAncestors.length;
        let selectedNode =
          idsLength > 1
            ? importInfo.selectedNodeAncestors[0]
            : importInfo.projectId;
        importSubmit(
          selectedNode,
          res.data.result.outline,
          res.data.result.cards,
          source_type,
          currentUploadIndex >= fileList.length - 1
        );
      }
      let list = uploadFilesInfo.map((item, index) => {
        if (index === currentUploadIndex) {
          item.isSuccess = true;
        }
        return item;
      });
      setUploadFilesInfo(list);
    } else {
      let list = uploadFilesInfo.map((item, index) => {
        if (index === currentUploadIndex) {
          item.isFailed = true;
        }
        return item;
      });
      setUploadFilesInfo(list);
    }
    if (currentUploadIndex < fileList.length - 1) {
      setCurrentUploadIndex(currentUploadIndex + 1);
    } else {
      setLoadingFile(false);
      setCurrentUploadIndex(-1);
    }
  };

  const uploadEndCallbackRef = React.useRef(uploadEndCallback);
  uploadEndCallbackRef.current = uploadEndCallback;

  const makeFileList = (fileList) => {
    let list = fileList.map((item) => {
      return {
        name: item.name,
        progress: 0,
        isFailed: false,
        isSuccess: false,
      };
    });
    setLoadingFile(true);
    setFileList(fileList);
    setUploadFilesInfo(list);
    setUploadStep(1);
    setCurrentUploadIndex(currentUploadIndex + 1);
  };

  const makeFileListRef = React.useRef(makeFileList);
  makeFileListRef.current = makeFileList;

  const updateFileInfo = (event) => {
    let loaded = event.loaded;
    let total = event.total;

    let list = uploadFilesInfo.map((item, index) => {
      if (index === currentUploadIndex) {
        item.progress = (loaded * 100) / total;
      }
      return item;
    });
    setUploadFilesInfo(list);
  };

  const updateFileInfoRef = React.useRef(updateFileInfo);
  updateFileInfoRef.current = updateFileInfo;

  React.useEffect(() => {
    if (currentUploadIndex >= 0) {
      let idsLength = importInfo.selectedNodeAncestors.length;
      let importUrl = configs.importUrl;

      let formData = new FormData();
      let source_type = ImportFormat[selectedFormatIndex].format.split(".")[1];
      formData.append("project_id", importInfo.projectId);
      formData.append(
        "parent_node_id",
        idsLength > 1
          ? importInfo.selectedNodeAncestors[0]
          : importInfo.projectId
      );
      formData.append("source_type", source_type);

      formData.append("files[]", fileList[currentUploadIndex]);
      let postConfig = {
        headers: { ...headers, "Content-Type": "multipart/form-data" },
        onUploadProgress: (event) => {
          updateFileInfoRef.current(event);
        },
      };

      axios
        .post(importUrl, formData, postConfig)
        .then((res) => {
          uploadEndCallbackRef.current(res, source_type);
        })
        .catch(() => {
          uploadEndCallbackRef.current(null);
        });
    }
  }, [currentUploadIndex]);

  const beforeUpload = (file, fileList) => {
    if (++inUpLoadCount === fileList.length) {
      makeFileListRef.current(fileList);
    }
    return false;
  };

  const renderFormatSelect = () => {
    return (
      <div className="import-modal-container">
        <div className="import-modal-content-title">导入</div>
        <div className="import-modal-content-format-container">
          {ImportFormat.map((item, index) => {
            return (
              <div
                className={
                  "import-modal-content-format-single-container" +
                  (index === selectedFormatIndex
                    ? " import-modal-content-format-single-container-selected"
                    : "")
                }
                onClick={() => {
                  if (index !== selectedFormatIndex) {
                    setSelectedFormatIndex(index);
                  }
                }}
              >
                <img
                  src={item.icon}
                  className="import-modal-content-format-single-icon"
                />
                <div className="import-modal-content-format-single-name">
                  {item.name}
                </div>
                <div className="import-modal-content-format-single-format">
                  {item.format}
                </div>
              </div>
            );
          })}
        </div>
        <Upload
          {...uploadOptions}
          beforeUpload={beforeUpload}
          accept={ImportFormat[selectedFormatIndex].format}
        >
          <div className="import-modal-submit-button">
            <div className="import-modal-submit-button-text">导入本地文件</div>
          </div>
        </Upload>
      </div>
    );
  };

  const renderUploadProgress = () => {
    return (
      <div className="import-modal-container">
        <div className="import-modal-content-title">导入中</div>
        <div className="import-modal-upload-list-container">
          {uploadFilesInfo.map((item, index) => {
            return (
              <div
                className="import-modal-upload-list-item-container"
                key={"upload-item-" + item.name + "-" + index}
              >
                <div className="import-modal-upload-list-item-title-container">
                  <img
                    src={ImportFormat[selectedFormatIndex].icon}
                    className="import-modal-upload-list-item-title-icon"
                  />
                  <div className="import-modal-upload-list-item-title-text">
                    {item.name.substring(0, item.name.lastIndexOf("."))}
                  </div>
                </div>
                <div className="import-modal-upload-list-item-progress-container">
                  <Progress
                    percent={item.progress}
                    size="small"
                    status={
                      item.isFailed
                        ? "exception"
                        : item.isSuccess
                        ? "success"
                        : "normal"
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className="import-modal-upload-list-bottom-container">
          <div className="import-modal-upload-list-bottom-text">
            {currentUploadIndex === -1 && (
              <img
                src={Select}
                className="import-modal-upload-list-bottom-icon"
              />
            )}
            <div className="import-modal-upload-list-bottom-text">
              {currentUploadIndex !== -1
                ? `导入中${currentUploadIndex}/${uploadFilesInfo.length}`
                : "导入完成"}
            </div>
          </div>
          <div
            className="import-modal-upload-list-bottom-check-button"
            onClick={() => {
              closeModal();
            }}
          >
            <div className="import-modal-upload-list-bottom-check-button-text">
              查看
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!importInfo.isShowImportModal) {
    return null;
  }

  return (
    <Modal
      className="import-modal"
      visible={importInfo.isShowImportModal}
      closable={false}
      footer={null}
      maskClosable={false}
      bodyStyle={getBodyStyle()}
    >
      <img
        src={CloseIcon}
        className="import-modal-close-btn"
        onClick={() => {
          closeModal();
        }}
      />
      {uploadStep === 0 && renderFormatSelect()}
      {uploadStep === 1 && renderUploadProgress()}
    </Modal>
  );
};

export default ImportModal;
