import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件

//graphql查询

//引入的资源图片

class DocumentError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorTip: props.errorTip && props.errorTip !== '' ? props.errorTip : "文档解析错" };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="document-error">
          <h1>{this.state.errorTip}</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export default DocumentError;
