import * as React from "react";

const CountDownText = props => {
  const { countDownEndCallback, countDownTime, prefixText, suffixText, style } = props;
  const [time, setTime] = React.useState(countDownTime);

  const intervalRef = React.useRef();
  React.useEffect(() => {
    if (countDownTime > 1) {
      const intervalTime = setInterval(() => {
        clock();
      }, 1000);
      intervalRef.current = intervalTime;
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const clock = () => {
    setTime(time => {
      if (time <= 0) {
        if (intervalRef.current) {
          if (countDownEndCallback) {
            countDownEndCallback();
          }
          clearInterval(intervalRef.current);
        }
        return 0;
      }
      return time - 1;
    });
  };

  return <div style={style ? style : {}}>{`${prefixText}${time}${suffixText}`}</div>;
};

export default CountDownText;
