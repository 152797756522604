import * as React from "react";

//引入的库组件
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const CropperComponent = props => {
  const { aspectRatio = 1 / 1 } = props;
  return (
    <Cropper
      ref={props.cropperRef}
      src={props.src}
      style={props.style}
      aspectRatio={aspectRatio}
      guides={false}
    />
  );
};

export default CropperComponent;
