import DataSet from "@antv/data-set";
import { Chart, registerShape, Util } from "@antv/g2";

function getTextAttrs(cfg) {
  return {
    ...cfg.defaultStyle,
    ...cfg.style,
    fontSize: cfg.data.size,
    text: cfg.data.text,
    textAlign: "center",
    fontFamily: cfg.data.font,
    fill: cfg.color || cfg.defaultStyle.stroke,
    textBaseline: "Alphabetic",
  };
}

export const WordCloud = (chartData, id, colors) => {
  // 给point注册一个词云的shape
  registerShape("point", "cloud", {
    draw(cfg, container) {
      const attrs = getTextAttrs(cfg);
      const textShape = container.addShape("text", {
        attrs: {
          ...attrs,
          x: cfg.x,
          y: cfg.y,
        },
      });
      if (cfg.data.rotate) {
        Util.rotate(textShape, (cfg.data.rotate * Math.PI) / 180);
      }

      return textShape;
    },
  });
  const dv = new DataSet.View().source(chartData);
  const range = dv.range("value");
  const min = range[0];
  const max = range[1];
  dv.transform({
    type: "tag-cloud",
    fields: ["x", "value"],
    size: [200, 200],
    font: "Verdana",
    timeInterval: 5000, // max execute time
    rotate() {
      let random = ~~(Math.random() * 4) % 4;
      if (random === 2) {
        random = 0;
      }
      return random * 90; // 0, 90, 270
    },
    fontSize(d) {
      if (d.value) {
        return ((d.value - min) / (max - min)) * 24 + 12;
      }
      return 0;
    },
  });
  const chart = new Chart({
    container: id,
    autoFit: false,
    width: 200,
    height: 200,
    padding: 0,
  });
  chart.data(dv.rows);
  chart.scale({
    x: { nice: false },
    y: { nice: false },
  });
  chart.legend(false);
  chart.axis(false);
  chart.tooltip(false);
  chart.coordinate().reflect();
  chart
    .point()
    .position("x*y")
    .shape("cloud")
    .color("value", colors)
    .state({
      active: {
        style: {
          fillOpacity: 0.4,
        },
      },
    });
  chart.render();
};
