import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal } from "antd";

//引入的其他页面组件

//graphql查询

//引入的资源图片

const ReconfirmModal = props => {
  const { isShow, title, content, cancelCallback, submitCallback } = props;

  return (
    <Modal
      footer={null}
      centered
      className="reconfirm-modal-container"
      title={title}
      visible={isShow}
      closable={false}
    >
      <p>{content}</p>
      <div className="reconfirm-modal-button-container">
        <div
          className="reconfirm-modal-button-cancel"
          onClick={() => {
            cancelCallback();
          }}
        >
          <div className="reconfirm-modal-button-cancel-text">取消</div>
        </div>
        <div
          className="reconfirm-modal-button-submit"
          onClick={() => {
            submitCallback ();
          }}
        >
          <div className="reconfirm-modal-button-submit-text">确定</div>
        </div>
      </div>
    </Modal>
  );
};

export default ReconfirmModal;
