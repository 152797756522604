/**
 * Created by wangna on 2017/12/19.
 */
import React, { useState, useEffect } from "react";
import Panel from "../../common/Panel";
// import '../RevisionPage/style.css';
let openPanelCardId = [];
export const getOpenPanelCardId = () => {
  return openPanelCardId;
};

const FeedbackPanel = (props) => {
  const [openPanel, setOpenPanel] = useState(false);
  const [panelStr, setPanelStr] = useState("");
  const abc = React.useRef();

  useEffect(() => {
    if (props.feedbackData.length) {
      if (!props.mistakeIndex && abc.current) {
        const mistakeElement = document.getElementById(
          props.feedbackData[0].mId
        );
        if (mistakeElement) {
          mistakeElement.style.background = "#FF738D";
          mistakeElement.style["font-weight"] = "bold";
          mistakeElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }
        openPanelCardId[0] = props.feedbackData[0].mId;
        abc.current.className = "opening web-panel";
      }
    }
  }, []);

  useEffect(() => {
    if (abc.current) {
      abc.current.id = props.feedback.mId + "-panel";
      if (abc.current.style.display === "none") {
        abc.current.style.display = "block";
      }
    }
  }, [abc, abc.current && abc.current.attributes]);

  const openThePanel = (feedback) => {
    if (abc.current) {
      abc.current.className =
        abc.current.className === "closing web-panel"
          ? "opening web-panel"
          : "closing web-panel";
    }
    if (openPanelCardId[0] && openPanelCardId[0] !== feedback.mId) {
      const lastPanelElement = document.getElementById(
        openPanelCardId[0] + "-panel"
      );
      const lastElement = document.getElementById(openPanelCardId[0]);
      if (lastElement) {
        lastElement.style["font-weight"] = "normal";
        lastElement.isDeleted = true;
        lastElement.style.background = "#FFF4A0";
      }
      if (lastPanelElement) {
        lastPanelElement.className = "closing web-panel";
      }
    }
    const element = document.getElementById(feedback.mId);
    if (!element) return;
    openPanelCardId[0] = feedback.mId;
    element.style["font-weight"] = "bold";
    element.style.background = "#FF738D";
    element.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  const {
    feedback,
    mistakeIndex,
    omitMistake,
    TabKey,
    openPanelId,
    setOpenPanelId,
    correctMistake,
  } = props;
  const { id, description } = feedback;
  return (
    <Panel
      abc={abc}
      header={
        <FeedbackHeader
          abc={abc}
          open={openPanelId === feedback.mId}
          key={id}
          feedback={feedback}
          mistakeIndex={mistakeIndex}
          TabKey={TabKey}
        />
      }
      key={id}
      wrongText={feedback.wrong}
      onToggle={() => {
        openThePanel(feedback);
      }}
    >
      <span className="fb-desc" style={{ color: "#a09a9a" }}>
        {feedback.category}
      </span>
      <span style={{ position: "absolute", bottom: 10, right: 10 }}>
        <span
          className="fb-ignore"
          style={{
            fontWeight: "bold",
            marginLeft: 130,
            outline: "none",
            cursor: "pointer",
          }}
          onClick={(e) => {
            omitMistake(feedback);
          }}
        >
          忽略
        </span>
        {feedback.advice && (
          <span
            className="fb-edit"
            style={{
              marginLeft: 5,
              color: "red",
              outline: "none",
              cursor: "pointer",
            }}
            onClick={(e) => {
              correctMistake(feedback);
            }}
          >
            修改
          </span>
        )}
      </span>
    </Panel>
  );
};

const FeedbackHeader = (props) => {
  const {
    feedback: { wrong, advice, sentence },
    mistakeIndex,
    TabKey,
    open,
  } = props;
  const strArr = sentence.split(wrong);
  for (let i = 0; i < strArr.length; i++) {
    if (i % 2 === 1) {
      strArr.splice(
        i,
        0,
        <FeedbackErrorShow key={i} wrong={wrong} advice={advice} />
      );
    }
  }
  return (
    <div className={`web-panel-header`}>
      <div
        className={"panel-index"}
        style={{
          fontSize: mistakeIndex > 8 ? 9 : 12,
          paddingTop: mistakeIndex > 8 ? 2 : 0,
          paddingLeft: mistakeIndex > 8 ? 3 : 5,
          marginBottom: 3,
          borderRadius: 5,
          fontWeight: "bold",
          paddingRight: 4,
          width: "fit-content",
          height: 18,
          color: "white",
          background: "#ff6e58",
        }}
      >
        {mistakeIndex + 1}
      </div>
      <section style={{ display: "flex", paddingLeft: 18 }}>
        <span className={"panel-inline-index"}>{mistakeIndex + 1}. </span>
        <div
          style={{
            width: 350,
            wordBreak: "break-all",
            wordWrap: "break-word",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingLeft: open ? 23 : 3,
            paddingRight: 10,
          }}
        >
          {strArr}
        </div>
      </section>
    </div>
  );
};

const FeedbackErrorShow = (props) => {
  const { wrong, advice } = props;
  return (
    <span>
      <span
        style={{
          textDecoration: advice ? "line-through" : "",
          fontWeight: "bold",
          color: "#ff6e58",
        }}
      >
        {wrong}
      </span>
      <span style={{ fontWeight: "bold" }}>{advice}</span>
    </span>
  );
};

export default FeedbackPanel;
