import Daily from "../Daily";
import DAILY from "../../../assets/daily.png";
import InspirationCube from "../InspirationCube";
import TitleLabel from "../TitleLabel";
import HOT from "../../../assets/hot.png";
import HOT_BG from "../../../assets/hot_bg.png";
import NEW from "../../../assets/new.png";
import NEW_BG from "../../../assets/new_bg.png";
import CATE from "../../../assets/cate.png";
import { Col, Row } from "antd";
import {
  SharedProjectType,
  useGetInspirationProjectDetailQuery,
} from "../../../generated/graphql";
import NoMore from "../../../assets/inspiration/no-more.png";
import More from "../../../assets/inspiration/more.png";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import CateTags from "./CateTags";
const PER_PAGE_COUNT = 24;

const Projects = (props) => {
  const {
    showList,
    categoryList,
    getIcon,
    selectionTags,
    categoryShowList,
    setShowList,
    searchString,
    titleIndex,
    setShowListInfo,
    getCardList,
    getCardListForProject,
    setCurrentPage,
    setCategoryShowList,
  } = props;

  const [sharedProjects, setSharedProjects] = useState([]);

  const idFormat = (id) => {
    return window.btoa("SharedProject:" + window.atob(id).split(":")[1]);
  };

  const showCardList = (tag, item) => {
    let variables = {
      id: idFormat(tag.id),
      after: undefined,
      first: PER_PAGE_COUNT,
      before: undefined,
      last: undefined,
      order: "DESC_INSERTED_AT",
      matching: searchString === "" ? undefined : searchString,
      filter: {
        type:
          titleIndex === 0 ? SharedProjectType.Golden : SharedProjectType.Wiki,
        autoGenTags: [tag.title],
        categories: [item.name],
      },
    };

    getCardListForProject(variables, () => {
      setShowList(true);
      setCurrentPage(1);
      setShowListInfo({
        autoGenTags: [tag.title],
        categories: [item.name],
        count: tag.cardCounts,
        id: tag.id,
        title: tag.title,
      });
      let path = `/idea/golden/p/${window.atob(tag.code).split("Project:")[1]}`
      window.history.pushState(null, "", path);
    });

    // setShowList(true);
    // let variables = {
    //   projectIds : [idFormat(tag.id)],
    //   after: undefined,
    //   first: PER_PAGE_COUNT,
    //   before: undefined,
    //   last: undefined,
    //   order: "DESC_INSERTED_AT",
    //   matching: searchString === "" ? undefined : searchString,
    //   filter: {
    //     types:
    //       [titleIndex === 0 ? SharedProjectType.Golden : SharedProjectType.Wiki],
    //     // autoGenTags: [tag.title],
    //     // categories: [item.name],
    //   },
    // };
    // setShowListInfo({
    //   autoGenTags: [tag.title],
    //   categories: [item.name],
    //   count: tag.cardCounts,
    //   id: tag.id,
    //   title: tag.title,
    // });
    // getCardList(variables, () => {
    //   setCurrentPage(1);
    // });
  };

  const getProjects = async (tagName) => {
    const variables = {
      filter: {
        type: "WIKI",
        categories: [tagName],
      },
      first: 100,
      // order,
    };
    // const res = await getSharedProjects(variables);
    // if (res && res.data && res.data.sharedProjects) {
    //     const selectedProjects = res.data.sharedProjects.edges.map(sp => {
    //         const spInfo = sp.node;
    //         return {
    //             title: spInfo.title,
    //             id: spInfo.id
    //         }
    //     });
    //     console.log(selectedProjects);
    // }
  };

  return (
    <div>
      <Daily selectionTags={selectionTags.random} img={DAILY} />
      <InspirationCube
        showCardList={showCardList}
        isRecommended
        selectionTags={selectionTags.recommended}
        height={160}
        noCount
        size={22}
      />
      <TitleLabel src={HOT} title={"热门"} />
      <InspirationCube
        showCardList={showCardList}
        selectionTags={selectionTags.hottest}
        height={75}
        image={HOT_BG}
      />
      <TitleLabel src={NEW} title={"最新"} />
      <InspirationCube
        showCardList={showCardList}
        selectionTags={selectionTags.newest}
        height={75}
        image={NEW_BG}
      />
      <TitleLabel src={CATE} title={"分类"} />
      <div
        className="inspiration-main-container"
        style={showList ? {} : { backgroundColor: "rgba(243,244,248,1)" }}
      >
        {selectionTags.more &&
          selectionTags.more.map((item, index) => {
            let icon = getIcon(index);
            return (
              <CateTags
                  key={"cate" + item.id + index}
                icon={icon}
                index={index}
                item={item}
                categoryList={categoryList}
                categoryShowList={categoryShowList}
                showCardList={showCardList}
                setCategoryShowList={setCategoryShowList}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Projects;
