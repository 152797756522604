import { Chart } from "@antv/g2";

export const LineChart = (chartData, id, color = null) => {
  const getValueTicks = (scale) => {
    const { min, max, values } = scale;
    if (values.length <= 5) {
      return values;
    }
    const avg = Math.ceil((max - min) / 3);
    const ticks = [];
    for (let i = min - avg < 0 ? 0 : min - avg; i <= max + avg; i += avg) {
      ticks.push(i);
    }
    return ticks;
  };

  const getNameTicks = (scale) => {
    const { values } = scale;
    if (values.length <= 4) {
      return values;
    }
    const avg = Math.floor(values.length / 3);
    const ticks = [values[0]];
    for (let i = 1; i < 3; i++) {
      ticks.push(values[i * avg]);
    }
    ticks.push(values[values.length - 1]);
    return ticks;
  };

  const chart = new Chart({
    container: id,
    autoFit: true,
  });

  chart.data(chartData);
  chart.scale({
    name: {
      tickMethod: getNameTicks
    },
    value: {
      tickMethod: getValueTicks,
      nice: true,
    },
  });

  chart.tooltip(false);

  chart
    .line()
    .position("name*value")
    .color(color ? color : "#48A0FC");
  chart.point().position("name*value");
  chart.axis("name", {
    label: {
      style: {
        fontSize: 10,
        fill: "#36363F",
        textAlign: "center",
      },
    },
  });

  chart.render();
};
