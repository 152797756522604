import ADD_CARD from "../../../assets/add-card.png";
import CardCountIcon from "../../../assets/project/card-count-icon.png";
import React, { useState } from "react";
import { message, Popconfirm } from "antd";

const FolderCard = (props) => {
    const { item, getFolderCards, getInspirationCardFolder, referenceCards } = props;
    const [showAddFolder, setShowAddFolder] = useState(false);
    return (
        <div
            onClick={() => getFolderCards(item)}
            onMouseOver={() => setShowAddFolder(true)}
            onMouseLeave={() => setShowAddFolder(false)}
            className="corpus-folder-item-container">
            <div className="corpus-folder-item-layer3" />
            <div className="corpus-folder-item-layer2" />
            <div className="corpus-folder-item-layer1">
                <div className="corpus-folder-item-text">{item.title}</div>
                <div onClick={async (e) => {
                    e.stopPropagation();
                }}>
                    {/*{showAddFolder*/}
                    {/*    ?*/}
                    {/*    <Popconfirm*/}
                    {/*        placement="topRight"*/}
                    {/*        title={'是否添加卡包到素材「默认金句库」'}*/}
                    {/*        onConfirm={async () => {*/}
                    {/*            const folderInfo = await getInspirationCardFolder({ id: item.id });*/}
                    {/*            if (folderInfo && folderInfo.data.node) {*/}
                    {/*                const input = {*/}
                    {/*                    ids: folderInfo.data.node.children.map(ic => window.atob(ic.id).split(':')[1]),*/}
                    {/*                    type: item.type*/}
                    {/*                };*/}
                    {/*                await referenceCards({ variables: { input } });*/}
                    {/*                message.success('添加成功');*/}
                    {/*            }*/}
                    {/*        }}*/}
                    {/*        okText="确定"*/}
                    {/*        cancelText="取消"*/}
                    {/*    >*/}
                    {/*        <img*/}
                    {/*            title={'添加到素材「默认金句库」'}*/}
                    {/*            className="corpus-folder-item-add" src={ADD_CARD} />*/}
                    {/*    </Popconfirm>*/}
                    {/*    : null*/}
                    {/*}*/}
                </div>
                <div className="corpus-folder-item-count-container">

                    <img
                        src={CardCountIcon}
                        className="corpus-folder-item-count-icon" />
                    <div className="corpus-folder-item-count-text">
                        {item.childrenCounts}张
                    </div>
                </div>

            </div>
        </div>
    )
};

export default FolderCard;
