import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件
import MainContainer from "../../Router_MainContainer/index";

//graphql查询

//引入的资源图片

const courses = [
  {
    icon: require("../../../assets/services/class_1.png"),
    url: "",
  },
  {
    icon: require("../../../assets/services/class_2.png"),
    url: "",
  },
];

const Course = (props) => {
  const { setIsLogin, history } = props;

  const renderBodyContent = () => {
    return (
      <div className="service-course-container">
        {courses.map((item, index) => {
          return (
            <img
              src={item.icon}
              className="service-course-img"
              key={"courses_" + index}
            />
          );
        })}
      </div>
    );
  };

  const renderTitleContent = () => {
    const categories = [
      {
        name: "应用",
      },
      {
        name: "课程",
      },
    ];
    return (
      <div className="service-app-category-header-container">
        <div className="service-app-category-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"service-app-category-header-" + index}
                className={
                  index === 1
                    ? "service-app-category-header-tab-item-selected"
                    : "service-app-category-header-tab-item"
                }
                onClick={() => {
                  if (index !== 1) {
                    history.push("/service/app");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <MainContainer
      currentSiderIndex={4}
      setIsLogin={setIsLogin}
      isBodyLoading={false}
      bodyContent={renderBodyContent()}
      headerContent={renderTitleContent()}
    />
  );
};

export default Course;
