import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Row, Col, Spin, Pagination } from "antd";
import { Switch, Route, Redirect } from "react-router";

//引入的其他页面组件
import CardsContainer from "../ProjectContainer/cardsContainer";
import CorpusCardsListContainer from "./corpusCardsListContainer";
import CorpusCardsDateContainer from "./corpusCardsDateContainer";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";

//引入的资源图片

const CorpusCardsContainer = (props) => {
  const { setHeaderContent, openGlobalSearchRef } = props;

  React.useEffect(() => {
    //加载完毕更改标签名
    document.title = `素材-卡片`;
  }, []);

  const renderContent = () => {
    return (
      <Switch>
        <Route
          path="/corpus/cards/date-list"
          component={(props) => {
            return (
              <CorpusCardsDateContainer
                {...props}
                setHeaderContent={setHeaderContent}
                openGlobalSearchRef={openGlobalSearchRef}
              />
            );
          }}
        />
        <Route
          path="/corpus/cards"
          component={(props) => {
            return (
              <CorpusCardsListContainer
                {...props}
                setHeaderContent={setHeaderContent}
                openGlobalSearchRef={openGlobalSearchRef}
              />
            );
          }}
        />
        <Route
          path="/"
          render={() => {
            return <Redirect to="/corpus/cards/date-list?category=all" />;
          }}
        />
      </Switch>
    );
  };

  return renderContent();
};

export default CorpusCardsContainer;
