import React, { useEffect, useState } from "react";
import DAILY_PIC from "../../../assets/daily_pic.png";

const Daily = (props) => {
    const { img, selectionTags } = props;
    const dailyInfo = selectionTags;
    return (
            <div className="daily-container">
                <section className="daily-text">
                    <div style={{ display: 'flex', size: 20, fontWeight: 'bold', marginRight: '2%' }}>
                        <img style={{ width: 19, height: 22, marginRight: 10 }} src={img} />每日金句
                    </div>
                    <p>{(dailyInfo && dailyInfo.content) || ''}</p>
                </section>
                {<img src={DAILY_PIC} className="daily-pic"/>}
            </div>
    )
};

export default  Daily;
