export const getOutlineIcon = (key) => {
  switch (key) {
    case "icon-default-multi":
      return require("../../../assets/writing/outline_icon/icon-default-multi.png");
    case "icon-folder": //文件夹
      return require("../../../assets/writing/outline_icon/icon-folder.png"); //文件夹
    case "icon-menuscript": //手稿
      return require("../../../assets/writing/outline_icon/icon-menuscript.png"); //手稿
    case "icon-data": //资料
      return require("../../../assets/writing/outline_icon/icon-data.png"); //资料
    case "icon-inspiration": //灵感
      return require("../../../assets/writing/outline_icon/icon-inspiration.png"); //灵感
    case "icon-todo": //todo
      return require("../../../assets/writing/outline_icon/icon-todo.png"); //todo
    case "icon-doing": //doing
      return require("../../../assets/writing/outline_icon/icon-doing.png"); //doing
    case "icon-done": //done
      return require("../../../assets/writing/outline_icon/icon-done.png"); //done
    case "icon-mark1": //标记1
      return require("../../../assets/writing/outline_icon/icon-mark1.png"); //标记1
    case "icon-mark2": //标记2
      return require("../../../assets/writing/outline_icon/icon-mark2.png"); //标记2
    case "icon-mark3": //标记3
      return require("../../../assets/writing/outline_icon/icon-mark3.png"); //标记3
    case "icon-person": //人物
      return require("../../../assets/writing/outline_icon/icon-person.png"); //人物
    case "icon-dialog": //对白
      return require("../../../assets/writing/outline_icon/icon-dialog.png"); //对白
    case "icon-timeline": //时间线
      return require("../../../assets/writing/outline_icon/icon-timeline.png"); //时间线
    case "icon-one":
      return require("../../../assets/writing/outline_icon/icon-one.png");
    case "icon-two":
      return require("../../../assets/writing/outline_icon/icon-two.png");
    case "icon-three":
      return require("../../../assets/writing/outline_icon/icon-three.png");
    case "icon-four":
      return require("../../../assets/writing/outline_icon/icon-four.png");
    case "icon-five":
      return require("../../../assets/writing/outline_icon/icon-five.png");
    case "icon-default": //默认
    default:
      return require("../../../assets/writing/outline_icon/icon-default.png"); //默认
  }
};
