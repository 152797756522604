import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Row, Col, Menu, Dropdown, Upload } from "antd";

//引入的其他页面组件

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import { ProjectFrom, ProjectType } from "../../../generated/graphql";

//引入的资源图片
import CardIcon from "../../../assets/project/card-count-icon.png";
import AddCover from "../../../assets/project/default-add-img.png";
import GoldenCover from "../../../assets/project/default-golden-img.png";
import WikiCover from "../../../assets/project/default-wiki-img.png";
import AddIcon from "../../../assets/project/add.png";
import EditIcon from "../../../assets/project/edit.png";
import MyIcon from "../../../assets/project/my.png";

const type = ["image/jpeg", "image/png"];
const size = 5;

const ProjectItem = (props) => {
  const {
    setFile,
    setSelectedProject,
    selectedTabIndex,
    projectInfo,
    projectClick,
    createGoldenCallback,
    createWikiCallback,
    changeNameCallback,
    modifyNameCallback,
    deleteCallback,
  } = props;
  const [hover, setHover] = React.useState(false);

  const beforeUpload = (file) => {
    let isType = true;
    let isSize = true;
    if (type) {
      isType = type.includes(file.type);
    }
    if (size) {
      isSize = file.size / 1024 / 1024 < size;
    }

    if (!isType) {
      message.error(`不支持 ${file.type} 文件类型`);
    }
    if (!isSize) {
      message.error(`文件限制 ${size} M`);
    }

    if (isType && isSize) {
      setSelectedProject(projectInfo);
      setFile(file);
    }
    return false;
  };

  let isEmptyProject =
    projectInfo.id === "" &&
    projectInfo.title === "" &&
    projectInfo.from === "";

  let cover = projectInfo.coverImageUrl
    ? projectInfo.coverImageUrl
    : isEmptyProject
    ? AddCover
    : projectInfo.type && projectInfo.type === ProjectType.Golden
    ? GoldenCover
    : WikiCover;

  const editOverlay = () => {
    return (
      <Menu
        className="corpus-project-dropdown-container"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          switch (e.key) {
            case "1":
              if (modifyNameCallback) {
                setSelectedProject(projectInfo);
                modifyNameCallback();
              }
              break;
            case "2":
              break;
            case "3":
              if (deleteCallback) {
                setSelectedProject(projectInfo);
                deleteCallback();
              }
              break;
          }
        }}
      >
        <Menu.Item key={"1"} className="corpus-project-dropdown-item-container">
          <div className="corpus-project-dropdown-item">修改名称</div>
        </Menu.Item>
        <Menu.Item key={"2"} className="corpus-project-dropdown-item-container">
          <Upload
            name="file"
            listType="text"
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            <div className="corpus-project-dropdown-item">修改封面</div>
          </Upload>
        </Menu.Item>
        <Menu.Item key={"3"} className="corpus-project-dropdown-item-container">
          <div className="corpus-project-dropdown-item">删除</div>
        </Menu.Item>
      </Menu>
    );
  };

  const createOverlay = () => {
    return (
      <Menu
        className="corpus-project-dropdown-container"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          switch (e.key) {
            case "1":
              if (createGoldenCallback) {
                createGoldenCallback();
              }
              break;
            case "2":
              if (createGoldenCallback) {
                createWikiCallback();
              }
              break;
          }
        }}
      >
        <Menu.Item key={"1"} className="corpus-project-dropdown-item-container">
          <div className="corpus-project-dropdown-item">金句项目</div>
        </Menu.Item>
        <Menu.Item key={"2"} className="corpus-project-dropdown-item-container">
          <div className="corpus-project-dropdown-item">知识项目</div>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div
      className="corpus-project-item-container"
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      onClick={() => {
        !isEmptyProject && projectClick();
      }}
    >
      <div className="corpus-project-item-layer3" />
      <div className="corpus-project-item-layer2" />
      <div className="corpus-project-item-layer1">
        <div className="corpus-project-item-img-container">
          <img src={cover} className="corpus-project-item-img" />
          <div className="corpus-project-item-img-mask" />
          {projectInfo.from === ProjectFrom.Self && (
            <img src={MyIcon} className="corpus-project-item-img-my" />
          )}
        </div>
        {isEmptyProject ? (
          <>
            {selectedTabIndex === 0 ? (
              <Dropdown
                trigger={["click"]}
                placement="bottomCenter"
                overlay={createOverlay}
                getPopupContainer={() =>
                  document.getElementById("create-container")
                }
              >
                <div
                  className="corpus-project-item-create-container"
                  id="create-container"
                >
                  <img
                    src={AddIcon}
                    className="corpus-project-item-create-icon"
                  />
                  <div className="corpus-project-item-create-text">新建</div>
                </div>
              </Dropdown>
            ) : (
              <div
                className="corpus-project-item-create-container"
                id="create-container"
                onClick={() => {
                  selectedTabIndex === 1
                    ? createGoldenCallback()
                    : createWikiCallback();
                }}
              >
                <img
                  src={AddIcon}
                  className="corpus-project-item-create-icon"
                />
                <div className="corpus-project-item-create-text">新建</div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="corpus-project-item-title">{projectInfo.title}</div>
            <div className="corpus-project-item-info-container">
              <div className="corpus-project-item-count-container">
                <img
                  src={CardIcon}
                  className="corpus-project-item-count-icon"
                />
                <div className="corpus-project-item-count-text">
                  {projectInfo.cardCounts}张
                </div>
              </div>
              <Dropdown
                trigger={["hover"]}
                placement="bottomCenter"
                overlay={editOverlay}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <img
                  src={EditIcon}
                  className="corpus-project-item-edit"
                  style={!hover ? { display: "none" } : {}}
                />
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectItem;
