import { Chart, registerShape } from "@antv/g2";

export const PieChart = (chartData, id, content, colors) => {
  const chart = new Chart({
    container: id,
    autoFit: true,
  });
  chart.data(chartData);
  chart.coordinate("theta", {
    radius: 1,
    innerRadius: 0.5,
  });
  chart.tooltip(false);
  chart.legend(false);
  // 辅助文本
  chart.annotation().text({
    position: ["50%", "50%"],
    content: content,
    style: {
      fontSize: 12,
      fill: "#36363F",
      textAlign: "center",
    },
  });
  chart
    .interval()
    .adjust("stack")
    .position("percent")
    .color("item", colors);

  chart.render();
};
