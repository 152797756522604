import * as React from "react";

//引入的库组件
import { Switch, Route, Redirect } from "react-router";

//引入的其他页面组件
import Application from "./Application/index";
import Course from "./Course/index";

//graphql查询

//引入的资源图片

const Services = (props) => {
  const { setIsLogin, history } = props;
  return (
    <Switch>
      <Route
        path="/service/app"
        component={(props) => {
          return (
            <Application {...props} history={history} setIsLogin={setIsLogin} />
          );
        }}
      />
      <Route
        path="/service/course"
        component={(props) => {
          return (
            <Course {...props} history={history} setIsLogin={setIsLogin} />
          );
        }}
      />
      <Route
        path="/"
        render={() => {
          return <Redirect to="/service/app" />;
        }}
      />
    </Switch>
  );
};

export default Services;
