import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Row, Col, Spin, Pagination } from "antd";
import moment from "moment";

//引入的其他页面组件
import { history } from "../AiWriterRouter/index";
import CardTemplate from "../Components/CardTemplate";
import { renderPagination } from "../Components/Pagination/index";
import { getPaginationPageVariable } from "../../utils/index";
import { convertToZH } from "../../utils/convertErrors";
import CorpusCardDetailContainer from "./corpusCardDetailContainer";
import { getUrlPath, getUrlSearch } from "../../utils/getUrlInfo";
import SearchWithDrop from "../Components/Search/searchWithDrop";
import EmptyComponent from "../Components/EmptyComponent";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import { useQuery } from "react-apollo-hooks";
import {
  useGetCardListQuery,
  useGetCardInfoQuery,
  useGetSingleCardQuery,
  useUpdateCardMutation,
  CardType,
  SortOrder,
  FileType,
} from "../../generated/graphql";

//引入的资源图片
import BackIcon from "../../assets/back-black.png";
import GoldenIcon from "../../assets/writing/golden-card.png";
import WikiIcon from "../../assets/writing/wiki-card.png";

//路由格式示例/corpus/cards/c/:code?tab=xx
export const C_INDEX = 2;
export const C_ID_INDEX = 3;

const tabs = ["all", "golden", "wiki"];
const PER_PAGE_CARD_COUNT = 18;

const CorpusCardsListContainer = (props) => {
  const client = useApolloClient();
  const { setHeaderContent, openGlobalSearchRef } = props;

  const [isInit, setIsInit] = React.useState(true);
  const [searchString, setSearchString] = React.useState("");
  const [cardStatInfo, setCardStatInfo] = React.useState({ year: 0, month: 0 });
  const [isShowSearchList, setIsShowSearchList] = React.useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [selectedCard, setSelectedCard] = React.useState({});
  const [isShowCardDetail, setIsShowCardDetail] = React.useState(false);
  const [cardList, setCardList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageInfo, setCurrentPageInfo] = React.useState({
    count: 0,
    pageSize: PER_PAGE_CARD_COUNT,
    pageInfo: null,
  });

  const [currentYear, setCurrentYear] = React.useState(-1);

  const [updateCardMutation] = useUpdateCardMutation();
  const { refetch: getCardListQuery } = useGetCardListQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getCardInfoQuery } = useGetCardInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getSingleCardQuery } = useGetSingleCardQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const searchCard = (searchString) => {
    let filter = {
      types: getTypes(),
      fileTypes: [FileType.File],
    };
    if (!isShowSearchList) {
      let startDate = moment(cardStatInfo.year + "-" + cardStatInfo.month)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endDate = moment(cardStatInfo.year + "-" + cardStatInfo.month)
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");
      filter = {
        ...filter,
        year: cardStatInfo.year,
        month: cardStatInfo.month,
      };
    }

    if (currentYear !== 0) {
      let startDate = moment(currentYear + "-" + 1)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endDate = moment(currentYear + "-" + 12)
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");
      filter = {
        ...filter,
        year: currentYear,
      };
    }

    let variables = {
      after: undefined,
      first: PER_PAGE_CARD_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString !== "" ? searchString : undefined,
      order: SortOrder.Desc,
      filter: filter,
    };
    setSearchString(searchString);
    getCardList(variables, () => {
      setCurrentPage(1);
    });
  };

  const searchCardRef = React.useRef(searchCard);
  searchCardRef.current = searchCard;

  const getCardList = (variables, callback) => {
    setIsLoading(true);
    getCardListQuery(variables).then((res) => {
      setIsLoading(false);
      if (res.data.cards) {
        let pageInfo = res.data.cards.pageInfo;
        let count = res.data.cards.count;
        let cards = res.data.cards.edges.map((item, index) => {
          return item.node;
        });
        setCurrentPageInfo({ ...currentPageInfo, pageInfo, count });
        setCardList(cards);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取卡片失败");
      }
    });
  };

  const getTypes = (tabIndex = null) => {
    let index = tabIndex !== null ? tabIndex : selectedTabIndex;
    let types = [];
    if (index === 0) {
      types = [CardType.Golden, CardType.Wiki];
    } else if (index === 1) {
      types = [CardType.Golden];
    } else {
      types = [CardType.Wiki];
    }
    return types;
  };

  const updateCard = (input, callback) => {
    setIsLoading(true);
    updateCardMutation({ variables: { input } }).then((res) => {
      setIsLoading(false);
      if (!res.data.updateCard.successful) {
        // message.error(res.data.updateCard.messages[0].message);
        message.error(convertToZH(res.data.updateCard.messages[0]));
      } else {
        if (callback) {
          callback();
        }
        message.success("修改成功");
      }
    });
  };

  const renderHeaderContent = () => {
    const categories = [
      {
        name: "项目",
      },
      {
        name: "卡片",
      },
    ];

    return (
      <div className="corpus-header-container">
        <div className="corpus-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"corpus-header-" + index}
                className={
                  index === 1
                    ? "corpus-header-tab-item-selected"
                    : "corpus-header-tab-item"
                }
                onClick={() => {
                  if (index !== 1) {
                    history.push("/corpus/project/list?category=all");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {!isShowCardDetail && (
          <SearchWithDrop
            position={{ right: "25px" }}
            callback={(index, string) => {
              if (index === "当前页面") {
                searchCardRef.current(string);
              }
              if (index === "全部搜索") {
                openGlobalSearchRef.current(string);
              }
            }}
            selectValues={[
              {
                value: "当前页面",
              },
              {
                value: "全部搜索",
              },
            ]}
          />
        )}
      </div>
    );
  };

  React.useState(() => {
    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();
    let isSearch = false;

    if (!searchArray || !searchArray.category) {
      history.push("/corpus/cards/date-list");
    } else {
      switch (searchArray.category) {
        case "all":
          setSelectedTabIndex(0);
          break;
        case "golden":
          setSelectedTabIndex(1);
          break;
        case "wiki":
          setSelectedTabIndex(2);
          break;
        default:
          history.push("/corpus/cards/date-list");
          return;
      }
      if (searchArray.searchString) {
        setSearchString(searchArray.searchString.trim());
        isSearch = true;
      }
      let variables = {
        first: PER_PAGE_CARD_COUNT,
        matching: isSearch ? searchArray.searchString.trim() : undefined,
        order: SortOrder.Desc,
        filter: {
          types: getTypes(tabs.indexOf(searchArray.category)),
          fileTypes: [FileType.File],
        },
      };

      if (searchArray && searchArray.month && searchArray.year) {
        setCardStatInfo({
          year: parseInt(searchArray.year),
          month: parseInt(searchArray.month),
        });
        setCurrentYear(parseInt(searchArray.year));
        variables = {
          ...variables,
          filter: {
            ...variables.filter,
            year: parseInt(searchArray.year),
            month: parseInt(searchArray.month),
          },
        };
      } else {
        if (searchArray && searchArray.year) {
          setCardStatInfo({ year: searchArray.year, month: null });
          variables = {
            ...variables,
            filter: {
              ...variables.filter,
              year: parseInt(searchArray.year),
            },
          };
          setCurrentYear(parseInt(searchArray.year));
        }
        setIsShowSearchList(true);
      }

      getCardList(variables, () => {
        setCurrentPage(1);
        if (
          pathArray[C_INDEX] &&
          pathArray[C_INDEX] === "c" &&
          pathArray[C_ID_INDEX] &&
          pathArray[C_ID_INDEX] !== ""
        ) {
          let code = window.btoa("Card:" + pathArray[C_ID_INDEX]);

          getSingleCardQuery({ code }).then((res) => {
            if (res.data.node) {
              let card = res.data.node;
              setSelectedCard(card);
              setIsShowCardDetail(true);
            } else {
              message.error("卡片不存在");
              history.push(`/corpus/cards/cards-list` + window.location.search);
            }
            setIsInit(false);
          });
        } else {
          setIsInit(false);
        }
        // if (searchArray.cardid) {
        //   let variables = {
        //     first: 1,
        //     ids: [searchArray.cardid],
        //     order: SortOrder.Desc,
        //     filter: {
        //       types: getTypes(tabs.indexOf(searchArray.category)),
        //     },
        //   };
        //   getCardInfoQuery(variables).then((res) => {
        //     if (res.data.cards.edges && res.data.cards.edges.length) {
        //       let card = res.data.cards.edges[0].node;
        //       setSelectedCard(card);
        //       setIsShowCardDetail(true);
        //     } else {
        //       message.error("卡片不存在");
        //       let path = [];
        //       let keys = Object.keys(searchArray);
        //       keys.forEach((item, index) => {
        //         if (item !== "cardid") {
        //           path.push(`${item}=${searchArray[item]}`);
        //         }
        //       });
        //       history.push(`/corpus/cards/cards-list?${path.join("&")}`);
        //     }
        //     setIsInit(false);
        //   });
        // }else {
        //   setIsInit(false);
        // }
      });
    }
    setHeaderContent(renderHeaderContent);
  }, []);

  const renderMonthCardList = () => {
    return (
      <>
        <div className="corpus-cards-list-content">
          <Spin spinning={isLoading}>
            <Row gutter={[24, 48]}>
              {cardList.map((item, index) => {
                let icon = null;
                if (item.type === CardType.Golden) {
                  icon = GoldenIcon;
                }
                if (item.type === CardType.Wiki) {
                  icon = WikiIcon;
                }
                return (
                  <Col span={8} key={`corpus-cards-list-card-${item.id}`}>
                    <CardTemplate
                      item={item}
                      icon={icon}
                      isHoverOutLine={true}
                      isHoverShowIcon={true}
                      searchString={searchString}
                      addToProjectCallback={null}
                      setSelectedCard={setSelectedCard}
                      clickCallback={() => {
                        setIsShowCardDetail(true);
                        let path =
                          `/corpus/cards/c/` +
                          window.atob(item.code).split("Card:")[1] +
                          window.location.search;
                        window.history.pushState(null, "", path);
                        // window.history.pushState(null, "", `/corpus/cards/cards-list?year=${cardStatInfo.year}&month=${cardStatInfo.month}&category=${tabs[selectedTabIndex]}&cardid=${item.id}`);
                      }}
                      extraTitleButton={[
                        {
                          buttonType: "copy",
                        },
                      ]}
                    />
                  </Col>
                );
              })}
            </Row>
          </Spin>
          <div className="corpus-cards-list-content-pagination">
            <Pagination
              itemRender={renderPagination}
              pageSize={PER_PAGE_CARD_COUNT}
              current={currentPage}
              total={currentPageInfo.count}
              hideOnSinglePage
              onChange={(page) => {
                let variables = getPaginationPageVariable(
                  page,
                  currentPage,
                  currentPageInfo
                );
                let startDate = moment(
                  cardStatInfo.year + "-" + cardStatInfo.month
                )
                  .startOf("month")
                  .format("YYYY-MM-DD");
                let endDate = moment(
                  cardStatInfo.year + "-" + cardStatInfo.month
                )
                  .endOf("month")
                  .add(1, "days")
                  .format("YYYY-MM-DD");
                variables = {
                  ...variables,
                  matching: searchString !== "" ? searchString : undefined,
                  order: SortOrder.Desc,
                  filter: {
                    types: getTypes(),
                    fileTypes: [FileType.File],
                    year: cardStatInfo.year,
                    month: cardStatInfo.month,
                    // startDate: startDate,
                    // endDate: endDate
                  },
                };
                getCardList(variables, () => {
                  setCurrentPage(page);
                });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderCardList = () => {
    return (
      <div className="corpus-cards-list-content">
        <Spin spinning={isLoading}>
          <Row gutter={[24, 48]}>
            {cardList.map((item, index) => {
              let icon = null;
              if (item.type === CardType.Golden) {
                icon = GoldenIcon;
              }
              if (item.type === CardType.Wiki) {
                icon = WikiIcon;
              }
              return (
                <Col span={8}>
                  <CardTemplate
                    item={item}
                    icon={icon}
                    isHoverOutLine={true}
                    isHoverShowIcon={true}
                    searchString={searchString}
                    addToProjectCallback={null}
                    setSelectedCard={setSelectedCard}
                    clickCallback={() => {
                      setIsShowCardDetail(true);
                      let path =
                        `/corpus/cards/c/` +
                        window.atob(item.code).split("Card:")[1] +
                        window.location.search;
                      window.history.pushState(null, "", path);
                      // window.history.pushState(null, "", `/corpus/cards/cards-list?year=${cardStatInfo.year}&month=${cardStatInfo.month}&category=${tabs[selectedTabIndex]}&cardid=${item.id}`);
                    }}
                    extraTitleButton={[
                      {
                        buttonType: "copy",
                      },
                    ]}
                  />
                </Col>
              );
            })}
          </Row>
        </Spin>
        <div className="corpus-cards-list-content-pagination">
          <Pagination
            itemRender={renderPagination}
            pageSize={PER_PAGE_CARD_COUNT}
            current={currentPage}
            total={currentPageInfo.count}
            hideOnSinglePage
            onChange={(page) => {
              let variables = getPaginationPageVariable(
                page,
                currentPage,
                currentPageInfo
              );
              let filter = { types: getTypes(), fileTypes: [FileType.File] };
              if (currentYear !== 0) {
                let startDate = moment(currentYear + "-" + 1)
                  .startOf("month")
                  .format("YYYY-MM-DD");
                let endDate = moment(currentYear + "-" + 12)
                  .endOf("month")
                  .add(1, "days")
                  .format("YYYY-MM-DD");
                filter = {
                  ...filter,
                  year: currentYear,
                };
              }

              variables = {
                ...variables,
                matching: searchString !== "" ? searchString : undefined,
                order: SortOrder.Desc,
                filter: filter,
              };
              getCardList(variables, () => {
                setCurrentPage(page);
              });
            }}
          />
        </div>
      </div>
    );
  };

  if (isInit) {
    return null;
  }

  if (isShowCardDetail) {
    return (
      <div className="corpus-project-detail-container">
        <div className="corpus-project-detail-title-bar">
          <div className="corpus-project-list-left-container">
            <img
              src={BackIcon}
              className="corpus-project-detail-title-bar-back"
              onClick={() => {
                setIsShowCardDetail(false);
                let searchArray = getUrlSearch();
                let keys = Object.keys(searchArray);
                let path = [];
                keys.forEach((item, index) => {
                  if (item !== "cardid") {
                    path.push(`${item}=${searchArray[item]}`);
                  }
                });
                window.history.pushState(
                  null,
                  "",
                  `/corpus/cards/cards-list?${path.join("&")}`
                );
              }}
            />

            <div className="corpus-project-detail-title-bar-title-text">
              {selectedCard.title}
            </div>
          </div>
        </div>
        <div className="corpus-card-container">
          <CorpusCardDetailContainer
            cardInfo={selectedCard}
            updateCardInfo={(info) => {
              let cardId = window.atob(selectedCard.id).split("Card:")[1];
              let input = {
                revision: false,
                id: cardId,
                ...info,
                metadata:
                  info.metadata && info.metadata !== ""
                    ? JSON.stringify(info.metadata)
                    : null,
              };
              updateCard(input, () => {
                let cardListTemp = cardList.map((listItem) => {
                  if (selectedCard.id === listItem.id) {
                    listItem = { ...listItem, ...info };
                  }
                  return listItem;
                });

                setSelectedCard({ ...selectedCard, ...info });
                setCardList(cardListTemp);
              });
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="corpus-cards-list-container">
      <div className="corpus-cards-list-title-bar">
        <div className="corpus-project-list-left-container">
          <img
            src={BackIcon}
            className="corpus-cards-list-title-bar-back"
            onClick={() => {
              // setShowPage(0);
              // setSearchString("");
              history.push(`/corpus/cards`);
            }}
          />

          <div className="corpus-cards-list-title-bar-title-text">
            {isShowSearchList
              ? currentYear === -1
                ? "全部"
                : currentYear + "年"
              : cardStatInfo.year + " · " + cardStatInfo.month + "月"}
          </div>
        </div>
        <div className="corpus-cards-list-title-bar-title-count">
          {currentPageInfo.count}张
        </div>
      </div>
      {cardList.length ? (
        isShowSearchList ? (
          renderCardList()
        ) : (
          renderMonthCardList()
        )
      ) : (
        <div style={{ marginTop: 100 }}>
          <EmptyComponent tip={"没有卡片数据"} />
        </div>
      )}
    </div>
  );
};

export default CorpusCardsListContainer;
