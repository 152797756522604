import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Spin, message, Avatar } from "antd";
import domtoimage from "dom-to-image";
import download from "downloadjs";
import axios from "axios";
import copy from "copy-to-clipboard";

//graphql查询
import { useGetBillProjectInfoQuery } from "../../../generated/graphql";

//引入的其他页面组件
import headers from "../../../GlobalData/headers";
import configs from "../../../configs";

//引入的资源图片
import CloseIcon from "../../../assets/globalmodal/close.png";
import StopIcon from "../../../assets/import-export/stop.png";
import DeleteIcon from "../../../assets/import-export/delete.png";
import RefreshIcon from "../../../assets/import-export/refresh.png";
import NoSelectIcon from "../../../assets/import-export/no-select.png";
import SelectIcon from "../../../assets/import-export/select-black.png";
import DefaultAvatar from "../../../assets/default_avatar.png";
import ReleaseImg from "../../../assets/writing/release/release-img.png"

const BillInfos = [
  {
    id: "bill-1",
    backgroundImg: require("../../../assets/writing/release/1.png"),
    userHeaderStyle: {
      size: 210,
      left: 150,
      top: 105,
      position: "absolute",
    },
    titleContainerStyle: {
      top: 360,
      left: 135,
      width: 855,
      dsiplay: "flex",
      flexDirection: "column",
      position: "absolute",
    },
    titleStyle: {
      width: 855,
      fontFamily: " PingFangSC-Semibold",
      fontSize: 93,
      color: "#FFFFFF",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      letterSpacing: 3.42,
    },
    nicknameStyle: {
      width: 855,
      fontFamily: "PingFangSC-Regular",
      fontSize: 51,
      color: "#FFFFFF",
      letterSpacing: 1.875,
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
    },
    specialStyle: {
      width: 75,
      height: 75,
      borderLeft: "4.5px solid #FFFFFF",
      transform: "rotate(45deg)",
      marginTop: 37.5,
      marginLeft: 15,
    },
    qrStyle: {
      left: 45,
      bottom: 45,
      width: 300,
      height: 300,
      background: "#F3F4F8",
      position: "absolute",
    },
  },
  {
    id: "bill-2",
    backgroundImg: require("../../../assets/writing/release/2.png"),
    userHeaderStyle: {
      size: 210,
      left: 825,
      top: 120,
    },
    titleContainerStyle: {
      top: 150,
      left: 135,
      width: 600,
      dsiplay: "flex",
      flexDirection: "column",
      position: "absolute",
    },
    titleStyle: {
      width: 600,
      fontFamily: " PingFangSC-Semibold",
      fontSize: 93,
      color: "#7C634E",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      letterSpacing: 3.42,
    },
    nicknameStyle: {
      width: 600,
      fontFamily: "PingFangSC-Regular",
      fontSize: 51,
      color: "#7C634E",
      letterSpacing: 1.875,
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
    },
    qrStyle: {
      left: 45,
      bottom: 45,
      width: 300,
      height: 300,
      background: "#F3F4F8",
      position: "absolute",
    },
  },
  {
    id: "bill-3",
    backgroundImg: require("../../../assets/writing/release/3.png"),
    userHeaderStyle: {
      backgroundSize: 270,
      backgroundColor: "#ffffff",
      size: 250,
      left: 435,
      top: 187.5,
    },
    titleContainerStyle: {
      top: 789,
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      position: "absolute",
      paddingLeft: 20,
      paddingRight: 20,
    },
    titleStyle: {
      fontFamily: "PingFangSC-Semibold",
      fontSize: 93,
      color: "#021B1F",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      letterSpacing: 3.42,
    },
    nicknameStyle: {
      fontFamily: "PingFangSC-Regular",
      fontSize: 51,
      color: "#021B1F",
      letterSpacing: 1.875,
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
    },
    qrStyle: {
      left: 75,
      bottom: 60,
      width: 210,
      height: 210,
      background: "#F3F4F8",
      position: "absolute",
    },
  },
  {
    id: "bill-4",
    backgroundImg: require("../../../assets/writing/release/4.png"),
    userHeaderStyle: {
      backgroundSize: 180,
      backgroundColor: "#BFA997",
      size: 160,
      left: 360,
      top: 705,
    },
    titleContainerStyle: {
      top: 127.5,
      left: 366,
      width: 600,
      dsiplay: "flex",
      flexDirection: "column",
      position: "absolute",
    },
    titleStyle: {
      width: 600,
      fontFamily: " PingFangSC-Semibold",
      fontSize: 93,
      color: "#BFA997",
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      letterSpacing: 3.42,
    },
    nicknameStyle: {
      width: 600,
      fontFamily: "PingFangSC-Regular",
      fontSize: 51,
      color: "#BFA997",
      letterSpacing: 1.875,
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitBoxOrient: "vertical",
      display: "-webkit-box",
      WebkitLineClamp: 2,
    },
    specialStyle: {
      width: 75,
      height: 75,
      borderLeft: "4.5px solid #BFA997",
      transform: "rotate(45deg)",
      marginTop: 37.5,
      marginLeft: 15,
    },
    qrStyle: {
      left: 45,
      bottom: 45,
      width: 300,
      height: 300,
      background: "#F3F4F8",
      position: "absolute",
    },
  },
];

const ReleaseModal = (props) => {
  const { releaseInfo, setReleaseInfo } = props;
  const [renderType, setRenderType] = React.useState(0);
  const [isRenderBill, setIsRenderBill] = React.useState(false);
  const [selectedBillIndex, setSelectedBillIndex] = React.useState(-1);
  const [isLoading, setIsLoading] = React.useState(false);

  const [releaseResultInfo, setReleaseResultInfo] = React.useState(null);
  const [projectInfo, setProjectInfo] = React.useState(null);
  const [billList, setBillList] = React.useState(null);

  const { refetch: getBillProjectInfoQuery } = useGetBillProjectInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  //制作海报的dom转图片的函数
  // domtoimage
  // .toBlob(document.getElementById("test-component"))
  // .then(function(blob) {
  //   download(blob, "bill");
  // });

  React.useEffect(() => {
    if (releaseInfo.isShowReleaseModal) {
      getProjectInfo(() => {
        let projectId = releaseInfo.projectId;
        let url = configs.getPublished + projectId;
        axios
          .get(url, {
            headers: headers,
          })
          .then((res) => {
            let result = res.data.result;
            if (result.state) {
              if (result.state === "published") {
                setRenderType(2);
              }
              setReleaseResultInfo(res.data.result);
            }
          });
      });
    }
  }, [releaseInfo.isShowReleaseModal]);

  React.useEffect(() => {
    if (isRenderBill) {
      let list = {};
      let count = 0;
      BillInfos.forEach((item, index) => {
        domtoimage.toPng(document.getElementById(item.id)).then(function(url) {
          list[item.id] = url;
          if (++count >= BillInfos.length) {
            setIsLoading(false);
            setBillList(list);
          }
        });
      });
    }
  }, [isRenderBill]);

  const getProjectInfo = (callback) => {
    getBillProjectInfoQuery({
      id: window.btoa("Project:" + releaseInfo.projectId),
    }).then((res) => {
      if (res.data.node) {
        setProjectInfo(res.data.node);
      }
      if (callback) {
        callback();
      }
    });
  };

  const renderReleaseModal = () => {
    return (
      <>
        <img src={ReleaseImg} className="release-modal-content-release-img" />
        <div className="release-modal-content-release-text">
          开启后可通过链接对作品进行发布
        </div>
        <div
          className="release-modal-content-release-button"
          onClick={() => {
            let projectId = releaseInfo.projectId;
            let postConfig = {
              headers: headers,
            };

            setIsLoading(true);
            // if (
            //   releaseResultInfo &&
            //   releaseResultInfo.state &&
            //   releaseResultInfo.state === "canceled"
            // ) {
            //   let url = configs.rePublish;
            //   let formData = {
            //     project_id: projectId,
            //   };
            //   axios.post(url, formData, postConfig).then((res) => {
            //     message.success("发布成功");
            //     setIsLoading(false);
            //     setRenderType(1);
            //     setReleaseResultInfo(res.data.result);
            //   });
            // } else {
            let url =
              configs.publishUrl +
              "?" +
              `project_id=${projectId}&target_type=md`;
            axios
              .post(url, {}, postConfig)
              .then((res) => {
                setReleaseResultInfo(res.data.result);
                setIsLoading(false);
                setRenderType(1);
                message.success("发布成功");
              })
              .catch((error) => {
                setIsLoading(false);
                message.error("发布失败");
              });
            // }
          }}
        >
          <div className="release-modal-content-release-button-text">
            开启发布链接
          </div>
        </div>
      </>
    );
  };

  const renderReleasingModal = () => {
    return (
      <div className="release-modal-content-url-main-container">
        <div className="release-modal-content-url-tip-text">
          所有获得链接的人可访问：
        </div>
        <div className="release-modal-content-url-container">
          <div className="release-modal-content-url-box">
            <div className="release-modal-content-url-box-text">
              {releaseResultInfo ? releaseResultInfo.url : ""}
            </div>
          </div>
          <div
            className="release-modal-content-url-button"
            onClick={() => {
              copy(releaseResultInfo.url);
              message.success("链接已复制到剪贴板");
            }}
          >
            <div className="release-modal-content-url-button-text">
              复制链接
            </div>
          </div>
        </div>
        <div className="release-modal-content-releasing-divider" />
        <div className="release-modal-content-releasing-qr-container">
          <div className="release-modal-content-releasing-qr-text">
            使用二维码分享
          </div>
          <img
            src={releaseResultInfo ? releaseResultInfo.qrcode_url : ""}
            className="release-modal-content-releasing-qr-img"
          />
          <div
            className="release-modal-content-releasing-qr-button"
            onClick={() => {
              setIsLoading(true);
              setIsRenderBill(true);
            }}
          >
            <div className="release-modal-content-releasing-qr-button-text">
              生成二维码海报
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReleasedModal = () => {
    return (
      <>
        {renderReleasingModal()}
        <div className="release-modal-content-released-divider" />
        <div className="release-modal-content-released-bottom-container">
          <div
            className="release-modal-content-released-bottom-button"
            onClick={() => {
              let projectId = releaseInfo.projectId;
              let url = configs.cancelPublish;
              let postConfig = {
                headers: headers,
              };
              let formData = {
                project_id: projectId,
              };
              axios.post(url, formData, postConfig).then((res) => {
                message.success("已终止发布");
                setRenderType(0);
                setReleaseResultInfo(res.data.result);
              });
            }}
          >
            <img
              src={StopIcon}
              className="release-modal-content-released-bottom-button-icon"
            />
            <div className="release-modal-content-released-bottom-button-text">
              终止发布
            </div>
          </div>
          <div
            className="release-modal-content-released-bottom-button"
            onClick={() => {
              let projectId = releaseInfo.projectId;
              let url = configs.deletePublished + projectId;
              axios
                .delete(url, {
                  headers: headers,
                })
                .then((res) => {
                  message.success("已删除发布");
                  setRenderType(0);
                });
            }}
          >
            <img
              src={DeleteIcon}
              className="release-modal-content-released-bottom-button-icon"
            />
            <div className="release-modal-content-released-bottom-button-text">
              删除发布链接
            </div>
          </div>
          <div
            className="release-modal-content-released-bottom-button"
            onClick={() => {
              setIsLoading(true);
              let projectId = releaseInfo.projectId;
              let postConfig = {
                headers: headers,
              };
              let url =
                configs.publishUrl +
                "?" +
                `project_id=${projectId}&target_type=md`;
              axios
                .post(url, {}, postConfig)
                .then((res) => {
                  setReleaseResultInfo(res.data.result);
                  setIsLoading(false);
                  message.success("更新发布成功");
                })
                .catch((error) => {
                  setIsLoading(false);
                  message.error("更新失败");
                });
            }}
          >
            <img
              src={RefreshIcon}
              className="release-modal-content-released-bottom-button-icon"
            />
            <div className="release-modal-content-released-bottom-button-text">
              更新发布链接
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderReleaseContent = () => {
    switch (renderType) {
      case 0:
        return renderReleaseModal();
      case 1:
        return renderReleasingModal();
      case 2:
        return renderReleasedModal();
    }
  };

  const renderRelease = () => {
    return (
      <Modal
        className="release-modal"
        visible={releaseInfo.isShowReleaseModal}
        closable={false}
        footer={null}
        maskClosable={false}
        bodyStyle={{
          width: "480px",
          padding: 0,
        }}
      >
        <Spin tip={"正在获取链接..."} spinning={isLoading}>
          <img
            src={CloseIcon}
            className="release-modal-close-btn"
            onClick={() => {
              setReleaseInfo({
                isShowReleaseModal: false,
                selectedNodeAncestors: [],
                projectId: "",
              });
              setRenderType(0);
              setIsRenderBill(false);
              setBillList([]);
            }}
          />
          <div className="release-modal-container">
            <div className="release-modal-content-title">发布</div>
            {renderReleaseContent()}
          </div>
        </Spin>
      </Modal>
    );
  };

  const renderOriginBill = (item, index) => {
    let userHeaderStyle = item.userHeaderStyle;
    let titleStyle = item.titleStyle;
    let specialStyle = item.specialStyle;
    let nicknameStyle = item.nicknameStyle;
    let titleContainerStyle = item.titleContainerStyle;
    let qrStyle = item.qrStyle;
    let userInfo = projectInfo.user;
    return (
      <div
        id={item.id + "-parent"}
        className="release-modal-content-bill-for-make"
      >
        <div
          id={item.id}
          className="release-modal-content-bill-for-make-container "
        >
          <img
            src={item.backgroundImg}
            className="release-modal-content-bill-for-make-background"
          />
          {userHeaderStyle.backgroundSize ? (
            <>
              <div
                style={{
                  position: "absolute",
                  width: userHeaderStyle.backgroundSize,
                  height: userHeaderStyle.backgroundSize,
                  left: userHeaderStyle.left,
                  top: userHeaderStyle.top,
                  backgroundColor: userHeaderStyle.backgroundColor,
                  borderRadius: userHeaderStyle.backgroundSize / 2,
                }}
              />
              <Avatar
                src={
                  userInfo.avatar && userInfo.avatar !== ""
                    ? userInfo.avatar
                    : DefaultAvatar
                }
                size={item.userHeaderStyle.size}
                style={{
                  left: userHeaderStyle.left + 10,
                  top: userHeaderStyle.top + 10,
                }}
              />
            </>
          ) : (
            <Avatar
              src={
                userInfo.avatar && userInfo.avatar !== ""
                  ? userInfo.avatar
                  : DefaultAvatar
              }
              size={item.userHeaderStyle.size}
              style={{ left: userHeaderStyle.left, top: userHeaderStyle.top }}
            />
          )}
          <div style={{ ...titleContainerStyle }}>
            <div
              style={{
                ...titleStyle,
              }}
            >
              {projectInfo.title}
            </div>
            {specialStyle && <div style={{ ...specialStyle }} />}
            <div style={{ ...nicknameStyle }}>{userInfo.nickname}</div>
          </div>
          <img
            src={releaseResultInfo ? releaseResultInfo.qrcode_url : ""}
            style={{ ...qrStyle }}
          />
        </div>
      </div>
    );
  };

  const renderBill = () => {
    return (
      <Modal
        className="release-modal"
        visible={releaseInfo.isShowReleaseModal}
        closable={false}
        footer={null}
        maskClosable={false}
        bodyStyle={{
          width: "800px",
          padding: 0,
        }}
      >
        <Spin tip={"正在合成海报..."} spinning={isLoading}>
          {BillInfos.map((item, index) => {
            return renderOriginBill(item, index);
          })}

          <img
            src={CloseIcon}
            className="release-modal-close-btn"
            onClick={() => {
              setReleaseInfo({
                isShowReleaseModal: false,
                selectedNodeAncestors: [],
                projectId: "",
              });
              setRenderType(0);
              setIsRenderBill(false);
              setBillList([]);
            }}
          />

          <div className="release-modal-container">
            <div className="release-modal-content-title">生成海报</div>
            <div className="release-modal-content-bill-cotainer">
              {BillInfos.map((item, index) => {
                return (
                  <div
                    className="release-modal-content-bill-single-container"
                    onClick={() => {
                      if (billList && billList[item.id]) {
                        if (index === selectedBillIndex) {
                          setSelectedBillIndex(-1);
                        } else {
                          setSelectedBillIndex(index);
                        }
                      }
                    }}
                  >
                    <div className="release-modal-content-bill">
                      {billList && billList[item.id] && (
                        <img
                          src={billList[item.id]}
                          className="release-modal-content-bill-img"
                        />
                      )}
                    </div>
                    <img
                      src={
                        selectedBillIndex === index ? SelectIcon : NoSelectIcon
                      }
                      className="release-modal-content-bill-select-icon"
                    />
                  </div>
                );
              })}
            </div>
            <div
              className="release-modal-content-bill-button"
              style={selectedBillIndex !== -1 ? { cursor: "pointer" } : {}}
              onClick={() => {
                if (selectedBillIndex !== -1) {
                  let id = BillInfos[selectedBillIndex].id;
                  let file = billList[id];
                  download(file, projectInfo.title);
                }
              }}
            >
              <div className="release-modal-content-bill-button-text">下载</div>
            </div>
          </div>
        </Spin>
      </Modal>
    );
  };

  return isRenderBill ? renderBill() : renderRelease();
};

export default ReleaseModal;
