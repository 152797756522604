import * as React from "react";
import "./styles.css";

//引入的其他页面组件
import { copyObject, getChildrenIds } from "../../utils";
import TreeNodeInput from "../treeNodeInput";

//引入的库组件
import SortableTree from "react-sortable-tree";
import FileExplorerTheme from "react-sortable-tree-theme-file-explorer";
import "react-sortable-tree/style.css";

import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";

let currentOutlineData = {};
let draggingExpandedKeys = [];

const WRITING_OUTLINE_CHANGE_NAME_ID = gql`
  {
    writingChangeNameInOutlineIdClient @client
  }
`;

const TreeCopmonent = (props) => {
  const {
    outline,
    expandedKeys,
    selectedKey,
    onSelect,
    onExpand,
    currentProjectInfo,
    showRightMenu,
    updateCardAndOutline,
    dragAndDropCallback,
  } = props;
  const [treeData, setTreeData] = React.useState([]);
  const [isDragging, setIsDragging] = React.useState(false);
  const [draggingNodeId, setDraggingNodeId] = React.useState("");

  const { data: changeNameData } = useQuery(WRITING_OUTLINE_CHANGE_NAME_ID);
  const { writingChangeNameInOutlineIdClient } = changeNameData;

  React.useEffect(() => {
    makeTreeData();
  }, [outline, expandedKeys]);

  const makeTreeData = () => {
    let treeData = [];
    let outlineData = copyObject({ outline: outline }).outline;
    let projectId = outline[0].id;

    const checkOutline = (outline) => {
      for (let i = 0; i < outline.length; i++) {
        let item = outline[i];
        if (
          expandedKeys.indexOf(item.id) !== -1 &&
          item.children &&
          item.children.length
        ) {
          item.expanded = true;
        } else {
          item.expanded = false;
        }
        if (item.children && item.children.length) {
          checkOutline(item.children);
        }
      }
    };
    checkOutline(outlineData);
    setTreeData(outlineData);
  };

  const outlineExpand = (data) => {
    let expanded = data.expanded;
    let id = data.node.id;
    let expandedKeysTemp = [];
    if (expanded) {
      expandedKeysTemp = expandedKeys.concat(id);
    } else {
      expandedKeysTemp = expandedKeys.filter((key) => {
        return key !== id;
      });
    }
    onExpand(expandedKeysTemp);
  };

  const renderNode = (props) => {
    return (
      <span
        onClick={() => {
          onSelect([props.id]);
        }}
      >
        <TreeNodeInput {...props} />
      </span>
    );
  };

  const canDragCallback = (data) => {
    let node = data.node;
    if (
      node.id === currentProjectInfo.originalId ||
      node.id === "recycle-node" ||
      node.id === writingChangeNameInOutlineIdClient
    ) {
      return false;
    }
    // if(node.children && node.children.length){
    //   node.expanded = false;
    // }
    return true;
  };

  const canDropCallback = (data) => {
    if (data.nextParent) {
      let childrenIds = getChildrenIds(data.node.id, outline, false, true);
      if (
        data.node.id === data.nextParent.id ||
        childrenIds.indexOf(data.nextParent.id) !== -1
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const checkCurrentOutlineState = (nodeData) => {
    if (Object.keys(currentOutlineData).indexOf(nodeData.node.id) === -1) {
      currentOutlineData[nodeData.node.id] = nodeData;
    } else {
      let currentData = currentOutlineData[nodeData.node.id];
      if (currentData.node.expanded !== nodeData.node.expanded) {
        if (draggingExpandedKeys.indexOf(nodeData.node.id) === -1) {
          draggingExpandedKeys = draggingExpandedKeys.concat(nodeData.node.id);
        } else {
        }
      }
    }
  };

  return (
    <div className="tree-container">
      <SortableTree
        treeData={treeData}
        rowHeight={35}
        scaffoldBlockPxWidth={20}
        theme={FileExplorerTheme}
        canDrag={canDragCallback}
        canDrop={canDropCallback}
        onDragStateChanged={(data) => {
          setIsDragging(data.isDragging);
          setDraggingNodeId(data.draggedNode ? data.draggedNode.id : "");
        }}
        onChange={(treeData) => {
          // dragAndDropCallback(treeData);
        }}
        onMoveNode={(data) => {
          dragAndDropCallback(data.treeData);
        }}
        onVisibilityToggle={(data) => {
          outlineExpand(data);
        }}
        // isVirtualized={false}
        generateNodeProps={(node) => {
          if (isDragging) {
            // checkCurrentOutlineState(node);
          }
          let props = node.node;
          props.projectId = currentProjectInfo.originalId;
          props.showRightMenu = showRightMenu;
          props.updateCardAndOutline = updateCardAndOutline;
          props.selectedKey = selectedKey;
          return {
            title: renderNode(props),
          };
        }}
        // nodeContentRenderer={TreeNodeInput}
      />
    </div>
  );
};

export default TreeCopmonent;
