import { uuid } from "uuidv4";

export function makeTitle(titleString, level, minLevel) {
  if (titleString.indexOf("#") === 0) {
    return "\n\n" + titleString + "\n\n";
  } else {
    if (level === 1) {
      return "# " + titleString;
    } else if (level === (minLevel === 1 ? 2 : minLevel)) {
      return "## " + titleString;
    } else {
      return "### " + titleString;
    }
  }
}

export function flattenToMDString(arr, minLevel) {
  let MDString = "";
  if (arr && arr[0]) {
    const stringArr = arr.reduce(
      (result, item) => [
        ...result,
        "\n\n" + makeTitle(item.title, item.level, minLevel) + "\n\n",
        item.content + "\n",
        ...flattenToMDString(item.children),
      ],
      []
    );
    MDString = stringArr.join("");
  }
  return MDString;
}

export function insertArr(arr, index, subarray) {
  arr.splice.apply(arr, [index, 0].concat(subarray));
}

export function getDecodedId(id, dataType) {
  if (!id) return;
  let decoded;
  if (dataType === "p") {
    const pjtId = window.atob(id).split(":");
    decoded = pjtId.length > 1 ? pjtId[1] : id;
  }
  return decoded;
}

export function flatten(arr) {
  return arr && arr[0]
    ? arr.reduce((result, item) => {
        return [
          ...result,
          {
            type: "paragraph",
            key: item.id + "-h",
            data: {
              type: "title",
              id: item.id,
              text: item.title,
            },
          },
          {
            type: "paragraph",
            key: item.id + "-t",
            data: {
              type: "content",
              id: item.id,
              text: item.content,
            },
          },
          ...flatten(item.children),
        ];
      }, [])
    : [];
}

export function flattenSearch(arr) {
  return arr && arr[0]
    ? arr.reduce(
        (result, item) => [
          ...result,
          {
            type: "paragraph",
            key: item.id + "-h",
            data: {
              type: "title",
              id: item.id,
              text: item.titleMatched
                ? `<span>${item.titleSplit[0]}<span style="background: #ffd48d">${item.searchWord}</span>${item.titleSplit[1]}</span>`
                : item.title,
            },
          },
          {
            type: "paragraph",
            key: item.id + "-t",
            data: {
              type: "content",
              id: item.id,
              text: item.contentMatched
                ? `<span>${item.contentSplit[0]}<span style="background: #ffd48d">${item.searchWord}</span>${item.contentSplit[1]}</span>`
                : item.content,
            },
          },
          ...flatten(item.children),
        ],
        []
      )
    : [];
}

export function flattenPure(arr, projectId, cardState, setCardState) {
  if (!cardState) {
    console.error("无card state： flatten pure");
  }
  // if (arr && !arr[0]) {
  //     setCardState(cardState);
  // }
  return arr && arr[0]
    ? arr.reduce((result, item) => {
        const oldCard = cardState[item.id];
        if (!oldCard) {
          console.error("无 cardState[item.id]：flatten pure");
          return;
        }
        let newCard;
        if (oldCard) {
          newCard = JSON.parse(JSON.stringify(oldCard));
          delete newCard.insertedAt;
          newCard.id = uuid();
          item.id = newCard.id;
          cardState[newCard.id] = newCard;
          newCard.projectId = projectId;
          newCard.canDelete = true;
        }
        return [
          ...result,
          newCard,
          ...flattenPure(item.children, projectId, cardState, setCardState),
        ];
      }, [])
    : [];
}

export function flattenPureDelete(arr) {
  return arr && arr[0]
    ? arr.reduce((result, item) => {
        return [
          ...result,
          { id: item.id, children: item.children },
          ...flattenPureDelete(item.children),
        ];
      }, [])
    : [];
}

export function setFunCallMaxTimes(fun, times, nextFun) {
  return function() {
    if (times-- > 0) {
      // 执行函数
      return fun.apply(this, arguments);
    } else if (nextFun && typeof nextFun === "function") {
      // 执行下一个函数
      return nextFun.apply(this, arguments);
    }
  };
}

export function html2Escape(str) {
  var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
  return str
    .replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
      return arrEntities[t];
    })
    .replace(/<div>|<br>/g, "\n")
    .replace(/<\/?span[^>]*>/g, "")
    .replace(/<\/?div[^>]*>/g, "");
}

export function html2EscapeForCard(str) {
  var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
  return str
    .replace(/<div>/g, "\n")
    .replace(/<[^>]+>/g, "")
    .replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
      return arrEntities[t];
    });
}
