import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Spin, message } from "antd";
import axios from "axios";
import copy from "copy-to-clipboard";

//引入的其他页面组件
import headers from "../../../GlobalData/headers";
import configs from "../../../configs";

//引入的资源图片
import CloseIcon from "../../../assets/globalmodal/close.png";
import StopIcon from "../../../assets/import-export/stop.png";
import DeleteIcon from "../../../assets/import-export/delete.png";
import RefreshIcon from "../../../assets/import-export/refresh.png";
import NoSelectIcon from "../../../assets/import-export/no-select.png";
import SelectIcon from "../../../assets/import-export/select-black.png";
import ReleaseImg from "../../../assets/writing/release/release-img.png"

const ReportModal = (props) => {
  const { reportInfo, setReportInfo } = props;
  const [renderType, setRenderType] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [reportResultInfo, setReportResultInfo] = React.useState(null);

  React.useEffect(() => {
    if (reportInfo.isShowReportModal) {
      let projectId = reportInfo.projectId;
      let url = configs.getPlayed + projectId;
      axios
        .get(url, {
          headers: headers,
        })
        .then((res) => {
          let result = res.data.result;
          if (result.state) {
            if (result.state === "published") {
              setRenderType(2);
            }else {
              setRenderType(0);
            }
            setReportResultInfo(res.data.result);
          }
        });
    }
  }, [reportInfo.isShowReportModal]);

  const renderReportModal = () => {
    return (
      <>
        <img src={ReleaseImg} className="report-modal-content-release-img" />
        <div className="report-modal-content-release-text">
          开启后可通过链接对作品进行演示
        </div>
        <div
          className="report-modal-content-release-button"
          onClick={() => {
            let projectId = reportInfo.projectId;
            let postConfig = {
              headers: headers,
            };
            setIsLoading(true);
            // if (
            //   reportResultInfo &&
            //   reportResultInfo.state &&
            //   reportResultInfo.state === "canceled"
            // ) {
            //   let url = configs.rePlay;
            //   let formData = {
            //     project_id: projectId,
            //   };
            //   axios.post(url, formData, postConfig).then((res) => {
            //     message.success("开启演示成功成功");
            //     setIsLoading(false);
            //     setRenderType(1);
            //     setReportResultInfo(res.data.result);
            //   });
            // } else {
            let url = configs.playUrl + "?" + `project_id=${projectId}`;

            axios
              .post(url, {}, postConfig)
              .then((res) => {
                setReportResultInfo(res.data.result);
                setIsLoading(false);
                setRenderType(1);
                message.success("开启演示成功");
              })
              .catch((error) => {
                setIsLoading(false);
                message.error("开启演示失败");
              });
            // }
          }}
        >
          <div className="report-modal-content-release-button-text">
            开启演示链接
          </div>
        </div>
      </>
    );
  };

  const renderReportingModal = () => {
    return (
      <div className="report-modal-content-url-main-container">
        <div className="report-modal-content-url-tip-text">
          所有获得链接的人可访问：
        </div>
        <div className="report-modal-content-url-container">
          <div className="report-modal-content-url-box">
            <div className="report-modal-content-url-box-text">
              {reportResultInfo ? reportResultInfo.url : ""}
            </div>
          </div>
          <div
            className="report-modal-content-url-button"
            onClick={() => {
              copy(reportResultInfo.url);
              message.success("链接已复制到剪贴板");
            }}
          >
            <div className="report-modal-content-url-button-text">
              复制链接及密码
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderReportedModal = () => {
    return (
      <>
        {renderReportingModal()}
        <div className="report-modal-content-released-divider" />
        <div className="report-modal-content-released-bottom-container">
          <div
            className="report-modal-content-released-bottom-button"
            onClick={() => {
              let projectId = reportInfo.projectId;
              let url = configs.cancelPlay;
              let postConfig = {
                headers: headers,
              };
              let formData = {
                project_id: projectId,
              };
              axios.post(url, formData, postConfig).then((res) => {
                message.success("已终止演示");
                setRenderType(0);
                setReportResultInfo(res.data.result);
              });
            }}
          >
            <img
              src={StopIcon}
              className="report-modal-content-released-bottom-button-icon"
            />
            <div className="report-modal-content-released-bottom-button-text">
              终止演示
            </div>
          </div>
          <div
            className="report-modal-content-released-bottom-button"
            onClick={() => {
              let projectId = reportInfo.projectId;
              let url = configs.deletePlayed + projectId;
              axios
                .delete(url, {
                  headers: headers,
                })
                .then((res) => {
                  message.success("已删除演示");
                  setRenderType(0);
                });
            }}
          >
            <img
              src={DeleteIcon}
              className="report-modal-content-released-bottom-button-icon"
            />
            <div className="report-modal-content-released-bottom-button-text">
              删除演示链接
            </div>
          </div>
          <div
            className="report-modal-content-released-bottom-button"
            onClick={() => {
              let projectId = reportInfo.projectId;
              let postConfig = {
                headers: headers,
              };
              setIsLoading(true);
              let url = configs.playUrl + "?" + `project_id=${projectId}`;

              axios
                .post(url, {}, postConfig)
                .then((res) => {
                  setReportResultInfo(res.data.result);
                  setIsLoading(false);
                  message.success("更新演示成功");
                })
                .catch((error) => {
                  setIsLoading(false);
                  message.error("更新演示失败");
                });
            }}
          >
            <img
              src={RefreshIcon}
              className="report-modal-content-released-bottom-button-icon"
            />
            <div className="report-modal-content-released-bottom-button-text">
              更新演示链接
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderReportContent = () => {
    switch (renderType) {
      case 0:
        return renderReportModal();
      case 1:
        return renderReportingModal();
      case 2:
        return renderReportedModal();
    }
  };

  return (
    <Modal
      className="report-modal"
      visible={reportInfo.isShowReportModal}
      closable={false}
      footer={null}
      maskClosable={false}
      bodyStyle={{
        width: "480px",
        padding: 0,
      }}
    >
      <Spin tip={"正在获取链接..."} spinning={isLoading}>
        <img
          src={CloseIcon}
          className="report-modal-close-btn"
          onClick={() => {
            setReportInfo({
              isShowReportModal: false,
              selectedNodeAncestors: [],
              projectId: "",
            });
          }}
        />
        <div className="report-modal-container">
          <div className="report-modal-content-title">演示</div>
          {renderReportContent()}
        </div>
      </Spin>
    </Modal>
  );
};

export default ReportModal;
