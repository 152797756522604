import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件
import AiWriterRouter from "../AiWriterRouter/index";
import LoginAndRegisterPage from "../LoginAndRegisterPage/index";
import { checkPhoneNumber } from "../../utils/index";
import timeLocalStorage from "../../utils/timelocalstorage";
import { history } from "../AiWriterRouter/index";
import MainContext from "./MainContext";
import AIWriterRxDB from "../WritingModuleAction/dbController";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import moment from "moment";

//引入的资源图片

const HomePage = (props) => {
  const client = useApolloClient();
  const [isLogin, setIsLogin] = React.useState(false);
  const [isNetworkOnline, setIsNetworkOnline] = React.useState(
    window.navigator.onLine
  );

  React.useEffect(() => {
    // window.addEventListener("resize", handleResize);
    // let mainBody = document.getElementById("MainBody");
    // let xRat = mainBody.offsetWidth  / 1440;
    // mainBody.style.zoom = xRat < 1 ? 1 : xRat;

    let userInfo = timeLocalStorage.get("USERINFO");
    let token = timeLocalStorage.get("TOKENINFO");
    if (userInfo && token) {
      client.writeData({ data: { userInfoClient: JSON.stringify(userInfo) } });
      setIsLogin(true);
    } else {
      history.push("/login");
    }
    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  let isWechatRedirect = false;
  if (!isLogin) {
    let urlSearch = decodeURIComponent(window.location.search);
    if (
      urlSearch !== "" &&
      urlSearch.indexOf("code=") !== -1 &&
      urlSearch.indexOf("state=") !== 1
    ) {
      window.sessionStorage.setItem("UrlSearch", urlSearch);
      window.location.href = window.location.origin;
      return null;
    } else {
      let urlSearch = window.sessionStorage.getItem("UrlSearch");
      if (urlSearch && urlSearch != "") {
        isWechatRedirect = true;
      }
    }
  }

  return (
    <MainContext>
      {isLogin ? (
        <AiWriterRouter setIsLogin={setIsLogin} />
      ) : (
        <LoginAndRegisterPage
          setIsLogin={setIsLogin}
          isWechatRedirect={isWechatRedirect}
        />
      )}
    </MainContext>
  );
};

export default HomePage;
