import * as React from "react";
import "./styles.css";
import "../aw-check.css";

//引入的其他页面组件
import BubbleBox from "../../Components/BubbleBox/index";

//引入的库组件
import { Menu, Dropdown, Spin, message, Tooltip } from "antd";

//graphql查询
import { useGetLabelCharWordSentQuery } from "../../../generated/graphql";

//引入的资源图片
import ArrowRight from "../../../assets/writing/arrow-right.png";
import MarkIcon from "../../../assets/writing/mark-icon.png";
import MarkIconSelectedIcon from "../../../assets/writing/mark-icon-selected.png";

const word_len = [
  {
    typeName: "一字",
    typeKey: "one_char",
    colorClassName: "aw-mark-char-count-color-one_char",
  },
  {
    typeName: "二字",
    typeKey: "two_char",
    colorClassName: "aw-mark-char-count-color-two_char",
  },
  {
    typeName: "三字",
    typeKey: "three_char",
    colorClassName: "aw-mark-char-count-color-three_char",
  },
  {
    typeName: "四字",
    typeKey: "four_char",
    colorClassName: "aw-mark-char-count-color-four_char",
  },
  {
    typeName: "多字",
    typeKey: "multi_char",
    colorClassName: "aw-mark-char-count-color-multi_char",
  },
];

const char_pronounce = [
  {
    typeName: "齿音",
    typeKey: "chi",
    colorClassName: "aw-mark-char-pronounce-color-chi",
  },
  {
    typeName: "舌音",
    typeKey: "she",
    colorClassName: "aw-mark-char-pronounce-color-she",
  },
  {
    typeName: "牙音",
    typeKey: "ya",
    colorClassName: "aw-mark-char-pronounce-color-ya",
  },
  {
    typeName: "唇音",
    typeKey: "chun",
    colorClassName: "aw-mark-char-pronounce-color-chun",
  },
  {
    typeName: "喉音",
    typeKey: "hou",
    colorClassName: "aw-mark-char-pronounce-color-hou",
  },
];

const word_pos = [
  {
    typeName: "介词",
    typeKey: "prep",
    colorClassName: "aw-mark-word-pos-color-prep",
  },
  {
    typeName: "副词",
    typeKey: "adv",
    colorClassName: "aw-mark-word-pos-color-adv",
  },
  {
    typeName: "连词",
    typeKey: "conj",
    colorClassName: "aw-mark-word-pos-color-conj",
  },
  {
    typeName: "divider",
  },
  {
    typeName: "名词",
    typeKey: "noun",
    colorClassName: "aw-mark-word-pos-color-noun",
  },
  {
    typeName: "动词",
    typeKey: "verb",
    colorClassName: "aw-mark-word-pos-color-verb",
  },
  {
    typeName: "形容词",
    typeKey: "adj",
    colorClassName: "aw-mark-word-pos-color-adj",
  },
  {
    typeName: "代词",
    typeKey: "pron",
    colorClassName: "aw-mark-word-pos-color-pron",
  },
  {
    typeName: "divider",
  },
  {
    typeName: "人名",
    typeKey: "per",
    colorClassName: "aw-mark-word-pos-color-per",
  },
  {
    typeName: "地名",
    typeKey: "loc",
    colorClassName: "aw-mark-word-pos-color-loc",
  },
  {
    typeName: "机构名",
    typeKey: "org",
    colorClassName: "aw-mark-word-pos-color-org",
  },
  {
    typeName: "时间",
    typeKey: "time",
    colorClassName: "aw-mark-word-pos-color-time",
  },
];

const sent_len = [
  {
    typeName: "正常",
    typeKey: "normal",
    colorClassName: "aw-mark-sent-len-color-normal",
  },
  {
    typeName: "较长",
    typeKey: "long",
    colorClassName: "aw-mark-sent-len-color-long",
  },
  {
    typeName: "较短",
    typeKey: "short",
    colorClassName: "aw-mark-sent-len-color-short",
  },
];

const sent_form = [
  {
    typeName: "单句",
    typeKey: "single",
    colorClassName: "aw-mark-sent-form-color-single",
  },
  {
    typeName: "复句",
    typeKey: "complex",
    colorClassName: "aw-mark-sent-form-color-complex",
  },
];

const sent_pattern = [
  {
    typeName: "感叹句",
    typeKey: "exclamatory",
    colorClassName: "aw-mark-sent-pattern-color-exclamatory",
  },
  {
    typeName: "疑问句",
    typeKey: "question",
    colorClassName: "aw-mark-sent-pattern-color-question",
  },
  {
    typeName: "祈使句",
    typeKey: "imperative",
    colorClassName: "aw-mark-sent-pattern-color-imperative",
  },
  {
    typeName: "陈述句",
    typeKey: "declarative",
    colorClassName: "aw-mark-sent-pattern-color-declarative",
  },
];

const sent_meaning = [
  {
    typeName: "否定句",
    typeKey: "negative",
    colorClassName: "aw-mark-sent-meaning-color-negative",
  },
  {
    typeName: "肯定句",
    typeKey: "positive",
    colorClassName: "aw-mark-sent-meaning-color-positive",
  },
];

const WordMarkDropDown = (props) => {
  const {
    highlightWords,
    setHighlightWords,
    imgStyle,
    currentCardIds,
    disabled,
  } = props;

  const [isMouseEnter, setIsMouseEnter] = React.useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [markDatas, setMarkDatas] = React.useState([]);
  const { refetch: getMarkInfo } = useGetLabelCharWordSentQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const makeHightlight = (markData, markDataName, key, markType) => {
    let markCount = 0;
    let datas = markDatas.map((item, index) => {
      let cardId = item.cardId;
      let wordInfo = [];
      if (key === "all") {
        markData.forEach((markDataItem, markDataItemIndex) => {
          if (markDataItem.typeName !== "divider") {
            item.labelMap[markDataName][markDataItem.typeKey].forEach(
              (arrayItem, arrayIndex) => {
                wordInfo.push({
                  start: arrayItem.start,
                  end: arrayItem.end,
                  className: `${markType}-${markDataItem.typeKey}`,
                });
              }
            );
          }
        });
      } else {
        item.labelMap[markDataName][key].forEach((arrayItem, arrayIndex) => {
          wordInfo.push({
            start: arrayItem.start,
            end: arrayItem.end,
            className: `${markType}-${key}`,
          });
        });
      }
      markCount += wordInfo.length;
      return {
        type: "mark",
        cardId,
        wordInfo,
      };
    });
    if (markCount > 0) {
      setHighlightWords(datas);
    } else {
      message.warn("没有相关标注");
      setHighlightWords([{ type: "mark-clear-all" }]);
    }
  };

  const onVisibleChange = (visilble) => {
    setIsDropdownVisible(visilble);
    if (visilble) {
      setIsLoading(true);
      getMarkInfo({ ids: currentCardIds }).then((res) => {
        setIsLoading(false);
        if (res.errors) {
          message.error("统计信息获取失败");
        } else {
          let datas = res.data.labelCharWordSent.labels;
          if (datas.length) {
            setMarkDatas(datas);
          } else {
            message.warn("没有统计信息");
          }
        }
      });
    }
  };

  const renderSubList = (array, selectCallback) => {
    return (
      <Menu
        className="word-mark-sub-container"
        onClick={(e) => {
          if (selectCallback) {
            if (e.key === `${array.length}`) {
              selectCallback("all");
            } else {
              selectCallback(e.key);
            }
          }
        }}
      >
        {array.map((item, index) => {
          if (item.typeName === "divider") {
            return (
              <div key={`divider-${index}`} className="word-mark-divider" />
            );
          }
          return (
            <Menu.Item
              key={`${item.typeKey}`}
              className="word-mark-sub-item-container"
            >
              <div
                className={"word-mark-sub-item-color " + item.colorClassName}
              />
              <div className="word-mark-sub-item-text">{item.typeName}</div>
            </Menu.Item>
          );
        })}
        <div className="word-mark-divider" />
        <Menu.Item key={`${array.length}`} className="word-mark-item-container">
          <div className="word-mark-item-text">全部标注</div>
        </Menu.Item>
      </Menu>
    );
  };

  const renderCharCountOverlay = () => {
    return renderSubList(word_len, (key) => {
      makeHightlight(
        word_len,
        "word_len",
        key,
        "aw-mark-editor-underline aw-mark-char-count-color"
      );
    });
  };

  const renderCharPronounce = () => {
    return renderSubList(char_pronounce, (key) => {
      makeHightlight(
        char_pronounce,
        "char_pronounce",
        key,
        "aw-mark-editor-underline aw-mark-char-pronounce-color"
      );
    });
  };

  const renderWordPos = () => {
    return renderSubList(word_pos, (key) => {
      makeHightlight(word_pos, "word_pos", key, "aw-mark-word-pos-color");
    });
  };

  const renderSentLen = () => {
    return renderSubList(sent_len, (key) => {
      makeHightlight(
        sent_len,
        "sent_len",
        key,
        "aw-mark-editor-underline aw-mark-sent-len-color"
      );
    });
  };

  const renderSentForm = () => {
    return renderSubList(sent_form, (key) => {
      makeHightlight(
        sent_form,
        "sent_form",
        key,
        "aw-mark-editor-underline aw-mark-sent-form-color"
      );
    });
  };

  const renderSentPattern = () => {
    return renderSubList(sent_pattern, (key) => {
      makeHightlight(
        sent_pattern,
        "sent_pattern",
        key,
        "aw-mark-editor-underline aw-mark-sent-pattern-color"
      );
    });
  };

  const renderSentMeaning = () => {
    return renderSubList(sent_meaning, (key) => {
      makeHightlight(
        sent_meaning,
        "sent_meaning",
        key,
        "aw-mark-editor-underline aw-mark-sent-meaning-color"
      );
    });
  };

  const wordMardDropDownList = () => {
    if (isLoading) {
      return (
        <Menu className="word-mark-container">
          <Menu.Item className="word-mark-container-loading ">
            <Spin spinning={true} tip="正在统计中" />
          </Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu
        className="word-mark-container"
        onClick={(e) => {
          if (e.key === "8") {
            setHighlightWords([{ type: "mark-clear-all" }]);
          }
        }}
      >
        <Menu.Item key={"1"} className="word-mark-item-container">
          <Dropdown
            trigger={["hover"]}
            placement="bottomCenter"
            overlay={renderCharCountOverlay}
            getPopupContainer={() =>
              document.getElementById("word-mark-char-count")
            }
          >
            <div id="word-mark-char-count" className="word-mark-item-drop">
              <div className="word-mark-item-text">字数</div>
              <img src={ArrowRight} className="word-mark-item-img" />
            </div>
          </Dropdown>
        </Menu.Item>

        <Menu.Item key={"2"} className="word-mark-item-container">
          <Dropdown
            trigger={["hover"]}
            placement="bottomCenter"
            overlay={renderCharPronounce}
            getPopupContainer={() =>
              document.getElementById("word-mark-char-pronounce")
            }
          >
            <div id="word-mark-char-pronounce" className="word-mark-item-drop">
              <div className="word-mark-item-text">字音</div>
              <img src={ArrowRight} className="word-mark-item-img" />
            </div>
          </Dropdown>
        </Menu.Item>

        <div className="word-mark-divider" />

        <Menu.Item key={"3"} className="word-mark-item-container">
          <Dropdown
            trigger={["hover"]}
            placement="bottomCenter"
            overlay={renderWordPos}
            getPopupContainer={() =>
              document.getElementById("word-mark-word-pos")
            }
          >
            <div id="word-mark-word-pos" className="word-mark-item-drop">
              <div className="word-mark-item-text">词性</div>
              <img src={ArrowRight} className="word-mark-item-img" />
            </div>
          </Dropdown>
        </Menu.Item>

        <div className="word-mark-divider" />

        <Menu.Item key={"4"} className="word-mark-item-container">
          <Dropdown
            trigger={["hover"]}
            placement="bottomCenter"
            overlay={renderSentLen}
            getPopupContainer={() =>
              document.getElementById("word-mark-sent-len")
            }
          >
            <div id="word-mark-sent-len" className="word-mark-item-drop">
              <div className="word-mark-item-text">句长</div>
              <img src={ArrowRight} className="word-mark-item-img" />
            </div>
          </Dropdown>
        </Menu.Item>

        <Menu.Item key={"5"} className="word-mark-item-container">
          <Dropdown
            trigger={["hover"]}
            placement="bottomCenter"
            overlay={renderSentForm}
            getPopupContainer={() =>
              document.getElementById("word-mark-sent-form")
            }
          >
            <div id="word-mark-sent-form" className="word-mark-item-drop">
              <div className="word-mark-item-text">句型</div>
              <img src={ArrowRight} className="word-mark-item-img" />
            </div>
          </Dropdown>
        </Menu.Item>

        <Menu.Item key={"6"} className="word-mark-item-container">
          <Dropdown
            trigger={["hover"]}
            placement="bottomCenter"
            overlay={renderSentPattern}
            getPopupContainer={() =>
              document.getElementById("word-mark-sent-pattern")
            }
          >
            <div id="word-mark-sent-pattern" className="word-mark-item-drop">
              <div className="word-mark-item-text">句式</div>
              <img src={ArrowRight} className="word-mark-item-img" />
            </div>
          </Dropdown>
        </Menu.Item>

        <Menu.Item key={"7"} className="word-mark-item-container">
          <Dropdown
            trigger={["hover"]}
            placement="bottomCenter"
            overlay={renderSentMeaning}
            getPopupContainer={() =>
              document.getElementById("word-mark-sent-meaning")
            }
          >
            <div id="word-mark-sent-meaning" className="word-mark-item-drop">
              <div className="word-mark-item-text">句义</div>
              <img src={ArrowRight} className="word-mark-item-img" />
            </div>
          </Dropdown>
        </Menu.Item>

        <div className="word-mark-divider" />

        <Menu.Item key={"8"} className="word-mark-item-container">
          <div className="word-mark-item-text">清空标注</div>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <BubbleBox
      contentNode={wordMardDropDownList}
      onVisibleChange={onVisibleChange}
      disabled={disabled}
    >
      <Tooltip
        placement="bottom"
        title={"智能标注"}
        visible={isMouseEnter && !isDropdownVisible}
      >
        <img
          onMouseEnter={() => {
            setIsMouseEnter(true);
          }}
          onMouseOut={() => {
            setIsMouseEnter(false);
          }}
          style={imgStyle}
          src={
            highlightWords.length && highlightWords[0].type === "mark"
              ? MarkIconSelectedIcon
              : MarkIcon
          }
        />
      </Tooltip>
    </BubbleBox>
  );
};

export default WordMarkDropDown;
