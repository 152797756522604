import moment from "moment";

const springWord = ["春之雨", "春之雾", "春之风", "春之云", "春之空", "春之月"];
const summerWord = [
  "夏之雨",
  "夏之雾",
  "夏之云",
  "夏之空",
  "夏之月",
  "夏之露",
  "夏之日",
  "夏之星"
];
const autumnWord = [
  "秋之雨",
  "秋之嵐",
  "秋之色",
  "秋之霞",
  "秋之云",
  "秋之声",
  "秋之空",
  "秋之虹",
  "秋之日",
  "秋之星",
  "秋之夕"
];
const winterWord = [
  "冬之雨",
  "冬之风",
  "冬之雾",
  "冬之云",
  "冬之空",
  "冬之月",
  "冬之虹",
  "冬之星",
  "冬之靄",
  "冬之雷"
];

export const getProjectTempName = () => {
  let name = "";
  let time = moment().format("MM");
  switch (time) {
    case "03":
    case "04":
    case "05":
      name = springWord[Math.floor(Math.random() * springWord.length)];
      break;
    case "06":
    case "07":
    case "08":
      name = summerWord[Math.floor(Math.random() * summerWord.length)];
      break;
    case "09":
    case "10":
    case "11":
      name = autumnWord[Math.floor(Math.random() * autumnWord.length)];
      break;
    case "12":
    case "01":
    case "02":
      name = winterWord[Math.floor(Math.random() * winterWord.length)];
      break;
  }
  return name;
};
