import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Json: any,
  Date: any,
  Datetime: any,
};

export type Batch = {
   __typename?: 'Batch',
  unsuccessfulPhones?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type BatchCreateCodesInput = {
  codesToCreate?: Maybe<Array<Maybe<CreateCodeInput>>>,
};

export type BatchPayload = {
   __typename?: 'BatchPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<Batch>,
  successful: Scalars['Boolean'],
};

export type CalculateIndexResult = {
   __typename?: 'CalculateIndexResult',
  indexMap?: Maybe<Scalars['Json']>,
};

export type Card = Node & {
   __typename?: 'Card',
  autoGenTags?: Maybe<Array<Maybe<Scalars['String']>>>,
  canDelete?: Maybe<Scalars['Boolean']>,
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  code?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  coordinate?: Maybe<Array<Maybe<Scalars['Int']>>>,
  fileType?: Maybe<FileType>,
  id: Scalars['ID'],
  insertedAt?: Maybe<Scalars['Datetime']>,
  metadata?: Maybe<Scalars['Json']>,
  month?: Maybe<Scalars['Int']>,
  project?: Maybe<Project>,
  revisions?: Maybe<Array<Maybe<CardRevision>>>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<CardType>,
  updatedAt?: Maybe<Scalars['Datetime']>,
  user?: Maybe<User>,
  version?: Maybe<Scalars['Int']>,
  year?: Maybe<Scalars['Int']>,
};

export type CardConnection = {
   __typename?: 'CardConnection',
  count?: Maybe<Scalars['Int']>,
  edges?: Maybe<Array<Maybe<CardEdge>>>,
  pageInfo: PageInfo,
};

export type CardEdge = {
   __typename?: 'CardEdge',
  cursor: Scalars['String'],
  node?: Maybe<Card>,
};

export type CardFilter = {
  autoGenTags?: Maybe<Array<Maybe<Scalars['String']>>>,
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  endDate?: Maybe<Scalars['Date']>,
  fileTypes?: Maybe<Array<Maybe<FileType>>>,
  isDeleted?: Maybe<Scalars['Boolean']>,
  month?: Maybe<Scalars['Int']>,
  notArchived?: Maybe<Scalars['Boolean']>,
  notEmpty?: Maybe<Scalars['Boolean']>,
  startDate?: Maybe<Scalars['Date']>,
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  types: Array<CardType>,
  year?: Maybe<Scalars['Int']>,
};

export type CardPayload = {
   __typename?: 'CardPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<Card>,
  successful: Scalars['Boolean'],
};

export type CardRevision = Node & {
   __typename?: 'CardRevision',
  content?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  revisionedAt?: Maybe<Scalars['Datetime']>,
  version?: Maybe<Scalars['Int']>,
};

export type CardsStat = {
   __typename?: 'CardsStat',
  count?: Maybe<Scalars['Int']>,
  month?: Maybe<Scalars['Int']>,
  year?: Maybe<Scalars['Int']>,
};

export enum CardType {
  Golden = 'GOLDEN',
  User = 'USER',
  Wiki = 'WIKI'
}

export type CheckResult = {
   __typename?: 'CheckResult',
  mistakes?: Maybe<Array<Maybe<Mistake>>>,
  unsuccessfulIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type CheckTabooResult = {
   __typename?: 'CheckTabooResult',
  taboos?: Maybe<Array<Maybe<Taboo>>>,
  unsuccessfulIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type CommonPayload = {
   __typename?: 'CommonPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<Scalars['String']>,
  successful: Scalars['Boolean'],
};

export type CopyCardsInput = {
  cardsToCreate: Array<CreateCardInput>,
  projectsToUpdate: Array<UpdateProjectInput>,
};

export type CreateCardInput = {
  autoGenTags?: Maybe<Array<Maybe<Scalars['String']>>>,
  canDelete: Scalars['Boolean'],
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  content: Scalars['String'],
  coordinate?: Maybe<Array<Maybe<Scalars['Int']>>>,
  fileType: FileType,
  id: Scalars['ID'],
  metadata?: Maybe<Scalars['Json']>,
  projectId: Scalars['ID'],
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  title?: Maybe<Scalars['String']>,
  type: CardType,
};

export type CreateCardsInput = {
  cardsToCreate: Array<CreateCardInput>,
  projectsToUpdate: Array<UpdateProjectInput>,
};

export type CreateCodeInput = {
  monthsDuration?: Maybe<Scalars['Int']>,
  phone?: Maybe<Scalars['String']>,
};

export type CreateProjectByThemeInput = {
  coverImageUrl: Scalars['String'],
  id?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
};

export type CreateProjectInput = {
  coverImageUrl: Scalars['String'],
  outline: Scalars['Json'],
  title: Scalars['String'],
  type: ProjectType,
};

export type CreateThemeInput = {
  categories: Array<Maybe<Scalars['String']>>,
  coverImageUrl: Scalars['String'],
  info: Scalars['Json'],
  isShared: Scalars['Boolean'],
  title: Scalars['String'],
};



export type DeleteCardInput = {
  id: Scalars['ID'],
};

export type DeleteCardsInput = {
  cardsToDelete: Array<DeleteCardInput>,
  projectsToUpdate: Array<UpdateProjectInput>,
};

export type DeleteProjectInput = {
  id: Scalars['ID'],
};

export type DeleteThemeInput = {
  id: Scalars['ID'],
};

export type ExtractKeywordsResult = {
   __typename?: 'ExtractKeywordsResult',
  keywords?: Maybe<Array<Maybe<Keyword>>>,
};

export enum FileType {
  File = 'FILE',
  Folder = 'FOLDER'
}

export enum IdentityType {
  VerificationCode = 'VERIFICATION_CODE',
  Weixin = 'WEIXIN'
}

export type InspirationMenu = {
   __typename?: 'InspirationMenu',
  hottest?: Maybe<Array<Maybe<Project>>>,
  more?: Maybe<Array<Maybe<SharedProjectCategory>>>,
  newest?: Maybe<Array<Maybe<Project>>>,
  random?: Maybe<SharedCard>,
  recommended?: Maybe<Array<Maybe<Project>>>,
};

export type Invitation = Node & {
   __typename?: 'Invitation',
  code?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  info?: Maybe<Scalars['Json']>,
  vipExpiredAt?: Maybe<Scalars['Datetime']>,
  vipLevel?: Maybe<Scalars['String']>,
};

export type InvitationConnection = {
   __typename?: 'InvitationConnection',
  count?: Maybe<Scalars['Int']>,
  edges?: Maybe<Array<Maybe<InvitationEdge>>>,
  pageInfo: PageInfo,
};

export type InvitationEdge = {
   __typename?: 'InvitationEdge',
  cursor: Scalars['String'],
  node?: Maybe<Invitation>,
};

export type InvitationFilter = {
  phones?: Maybe<Array<Maybe<Scalars['String']>>>,
};


export type Keyword = {
   __typename?: 'Keyword',
  weight?: Maybe<Scalars['String']>,
  word?: Maybe<Scalars['String']>,
};

export type Label = {
   __typename?: 'Label',
  cardId?: Maybe<Scalars['ID']>,
  labelMap?: Maybe<Scalars['Json']>,
};

export type LabelReplacementResult = {
   __typename?: 'LabelReplacementResult',
  replacements?: Maybe<Array<Maybe<Token>>>,
  unsuccessfulIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type LabelResult = {
   __typename?: 'LabelResult',
  labels?: Maybe<Array<Maybe<Label>>>,
  unsuccessfulIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type Location = {
   __typename?: 'Location',
  end?: Maybe<Scalars['Int']>,
  start?: Maybe<Scalars['Int']>,
};

export type LoginInput = {
  credential?: Maybe<Scalars['String']>,
  identifier: Scalars['String'],
  identityType: IdentityType,
};

export type Metadiscourse = {
   __typename?: 'Metadiscourse',
  cardId?: Maybe<Scalars['ID']>,
  category?: Maybe<Scalars['String']>,
  examples?: Maybe<Array<Maybe<MetadiscourseExample>>>,
  locations?: Maybe<Array<Maybe<Location>>>,
  word?: Maybe<Scalars['String']>,
};

export type MetadiscourseExample = {
   __typename?: 'MetadiscourseExample',
  author?: Maybe<Scalars['String']>,
  sentence?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type MetadiscourseResult = {
   __typename?: 'MetadiscourseResult',
  metadiscourses?: Maybe<Array<Maybe<Metadiscourse>>>,
  unsuccessfulIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type Mistake = {
   __typename?: 'Mistake',
  advice?: Maybe<Scalars['String']>,
  cardId?: Maybe<Scalars['ID']>,
  category?: Maybe<Scalars['String']>,
  end?: Maybe<Scalars['Int']>,
  sentence?: Maybe<Scalars['String']>,
  start?: Maybe<Scalars['Int']>,
  wrong?: Maybe<Scalars['String']>,
};

export type MoveCardsInput = {
  cardsToCreate?: Maybe<Array<CreateCardInput>>,
  cardsToDelete?: Maybe<Array<DeleteCardInput>>,
  projectsToUpdate: Array<UpdateProjectInput>,
};

export type Node = {
  id: Scalars['ID'],
};

export type PageInfo = {
   __typename?: 'PageInfo',
  endCursor?: Maybe<Scalars['String']>,
  hasNextPage: Scalars['Boolean'],
  hasPreviousPage: Scalars['Boolean'],
  startCursor?: Maybe<Scalars['String']>,
};

export type Project = Node & {
   __typename?: 'Project',
  autoGenTags?: Maybe<Array<Maybe<Scalars['String']>>>,
  cardCounts?: Maybe<Scalars['Int']>,
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  code?: Maybe<Scalars['String']>,
  coverImageUrl?: Maybe<Scalars['String']>,
  from?: Maybe<ProjectFrom>,
  id: Scalars['ID'],
  insertedAt?: Maybe<Scalars['Datetime']>,
  isDeleted?: Maybe<Scalars['Boolean']>,
  isShared?: Maybe<Scalars['Boolean']>,
  lastSeenAt?: Maybe<Scalars['Datetime']>,
  outline?: Maybe<Scalars['Json']>,
  outlineState?: Maybe<Scalars['Json']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<ProjectType>,
  updatedAt?: Maybe<Scalars['Datetime']>,
  user?: Maybe<User>,
};

export type ProjectConnection = {
   __typename?: 'ProjectConnection',
  count?: Maybe<Scalars['Int']>,
  edges?: Maybe<Array<Maybe<ProjectEdge>>>,
  pageInfo: PageInfo,
};

export type ProjectEdge = {
   __typename?: 'ProjectEdge',
  cursor: Scalars['String'],
  node?: Maybe<Project>,
};

export type ProjectFilter = {
  autoGenTags?: Maybe<Array<Maybe<Scalars['String']>>>,
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  from?: Maybe<ProjectFrom>,
  isDeleted?: Maybe<Scalars['Boolean']>,
  isShared?: Maybe<Scalars['Boolean']>,
  types?: Maybe<Array<Maybe<ProjectType>>>,
};

export enum ProjectFrom {
  Collection = 'COLLECTION',
  Self = 'SELF'
}

export type ProjectPayload = {
   __typename?: 'ProjectPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<Project>,
  successful: Scalars['Boolean'],
};

export enum ProjectType {
  Golden = 'GOLDEN',
  User = 'USER',
  Wiki = 'WIKI'
}

export type QuerySimWordsResult = {
   __typename?: 'QuerySimWordsResult',
  simWords?: Maybe<Array<Maybe<SimWord>>>,
};

export type ReferenceCardsInput = {
  ids: Array<Scalars['ID']>,
  type: SharedCardType,
};

export type ReferenceProjectInput = {
  id: Scalars['ID'],
};

export type RegisterInput = {
  avatar?: Maybe<Scalars['String']>,
  invitationCode: Scalars['String'],
  nickname: Scalars['String'],
  openid: Scalars['String'],
  phone: Scalars['String'],
  username: Scalars['String'],
  verificationCode: Scalars['String'],
};

export type ResetPhoneInput = {
  newPhone: Scalars['String'],
  verificationCode: Scalars['String'],
};

export type RootMutationType = {
   __typename?: 'RootMutationType',
  batchCreateCodes?: Maybe<BatchPayload>,
  copyCards?: Maybe<CommonPayload>,
  createCards?: Maybe<CommonPayload>,
  createProject?: Maybe<ProjectPayload>,
  createProjectByTheme?: Maybe<ProjectPayload>,
  createTheme?: Maybe<ThemePayload>,
  deleteCards?: Maybe<CommonPayload>,
  deleteProject?: Maybe<CommonPayload>,
  deleteTheme?: Maybe<CommonPayload>,
  login?: Maybe<SessionPayload>,
  moveCards?: Maybe<CommonPayload>,
  referenceCards?: Maybe<CommonPayload>,
  referenceProject?: Maybe<CommonPayload>,
  register?: Maybe<UserPayload>,
  resetPhone?: Maybe<UserAuthPayload>,
  securelyDeleteProject?: Maybe<ProjectPayload>,
  sendInvitationCodes?: Maybe<BatchPayload>,
  sendMessages?: Maybe<BatchPayload>,
  sendVerificationCode?: Maybe<CommonPayload>,
  updateCard?: Maybe<CardPayload>,
  updateCardsContent?: Maybe<CommonPayload>,
  updateProject?: Maybe<ProjectPayload>,
  updateTheme?: Maybe<ThemePayload>,
  updateUser?: Maybe<UserPayload>,
  verifyInvitationCode?: Maybe<CommonPayload>,
  verifyWeixin?: Maybe<WeixinUserPayload>,
};


export type RootMutationTypeBatchCreateCodesArgs = {
  input?: Maybe<BatchCreateCodesInput>
};


export type RootMutationTypeCopyCardsArgs = {
  input: CopyCardsInput
};


export type RootMutationTypeCreateCardsArgs = {
  input: CreateCardsInput
};


export type RootMutationTypeCreateProjectArgs = {
  input: CreateProjectInput
};


export type RootMutationTypeCreateProjectByThemeArgs = {
  input: CreateProjectByThemeInput
};


export type RootMutationTypeCreateThemeArgs = {
  input: CreateThemeInput
};


export type RootMutationTypeDeleteCardsArgs = {
  input: DeleteCardsInput
};


export type RootMutationTypeDeleteProjectArgs = {
  input: DeleteProjectInput
};


export type RootMutationTypeDeleteThemeArgs = {
  input: DeleteThemeInput
};


export type RootMutationTypeLoginArgs = {
  input?: Maybe<LoginInput>
};


export type RootMutationTypeMoveCardsArgs = {
  input: MoveCardsInput
};


export type RootMutationTypeReferenceCardsArgs = {
  input: ReferenceCardsInput
};


export type RootMutationTypeReferenceProjectArgs = {
  input: ReferenceProjectInput
};


export type RootMutationTypeRegisterArgs = {
  input: RegisterInput
};


export type RootMutationTypeResetPhoneArgs = {
  input?: Maybe<ResetPhoneInput>
};


export type RootMutationTypeSecurelyDeleteProjectArgs = {
  input: DeleteProjectInput
};


export type RootMutationTypeSendInvitationCodesArgs = {
  input: SendInvitationCodesInput
};


export type RootMutationTypeSendMessagesArgs = {
  input: SendMessagesInput
};


export type RootMutationTypeSendVerificationCodeArgs = {
  phone?: Maybe<Scalars['String']>
};


export type RootMutationTypeUpdateCardArgs = {
  input: UpdateCardInput
};


export type RootMutationTypeUpdateCardsContentArgs = {
  input: UpdateCardsContentInput
};


export type RootMutationTypeUpdateProjectArgs = {
  input: UpdateProjectInput
};


export type RootMutationTypeUpdateThemeArgs = {
  input: UpdateThemeInput
};


export type RootMutationTypeUpdateUserArgs = {
  input?: Maybe<UpdateUserInput>
};


export type RootMutationTypeVerifyInvitationCodeArgs = {
  invitationCode?: Maybe<Scalars['String']>
};


export type RootMutationTypeVerifyWeixinArgs = {
  code?: Maybe<Scalars['String']>
};

export type RootQueryType = {
   __typename?: 'RootQueryType',
  calculateIndex?: Maybe<CalculateIndexResult>,
  cards?: Maybe<CardConnection>,
  cardsStats?: Maybe<Array<Maybe<CardsStat>>>,
  check?: Maybe<CheckResult>,
  checkTaboo?: Maybe<CheckTabooResult>,
  extractKeywords?: Maybe<ExtractKeywordsResult>,
  inspirationMenu?: Maybe<InspirationMenu>,
  invitations?: Maybe<InvitationConnection>,
  labelCharWordSent?: Maybe<LabelResult>,
  labelReplacement?: Maybe<LabelReplacementResult>,
  me?: Maybe<User>,
  node?: Maybe<Node>,
  pickMetadiscourses?: Maybe<MetadiscourseResult>,
  projectCategories?: Maybe<Array<Maybe<SharedProjectCategory>>>,
  projects?: Maybe<ProjectConnection>,
  querySimWords?: Maybe<QuerySimWordsResult>,
  recommendations?: Maybe<SharedCardConnection>,
  sharedCards?: Maybe<SharedCardConnection>,
  sharedProjects?: Maybe<SharedProjectConnection>,
  themeCategories?: Maybe<Array<Maybe<Scalars['String']>>>,
  themes?: Maybe<ThemeConnection>,
};


export type RootQueryTypeCalculateIndexArgs = {
  ids: Array<Scalars['ID']>
};


export type RootQueryTypeCardsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  filter: CardFilter,
  first?: Maybe<Scalars['Int']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order?: SortOrder,
  projectCode?: Maybe<Scalars['String']>,
  projectId?: Maybe<Scalars['ID']>
};


export type RootQueryTypeCardsStatsArgs = {
  types: Array<Maybe<CardType>>
};


export type RootQueryTypeCheckArgs = {
  ids: Array<Scalars['ID']>
};


export type RootQueryTypeCheckTabooArgs = {
  ids: Array<Scalars['ID']>
};


export type RootQueryTypeExtractKeywordsArgs = {
  ids: Array<Scalars['ID']>
};


export type RootQueryTypeInspirationMenuArgs = {
  type?: SharedProjectType
};


export type RootQueryTypeInvitationsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  filter?: Maybe<InvitationFilter>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>
};


export type RootQueryTypeLabelCharWordSentArgs = {
  ids: Array<Scalars['ID']>
};


export type RootQueryTypeLabelReplacementArgs = {
  ids: Array<Scalars['ID']>
};


export type RootQueryTypeNodeArgs = {
  code?: Maybe<Scalars['String']>,
  id?: Maybe<Scalars['ID']>
};


export type RootQueryTypePickMetadiscoursesArgs = {
  ids: Array<Scalars['ID']>
};


export type RootQueryTypeProjectCategoriesArgs = {
  type: SharedProjectType
};


export type RootQueryTypeProjectsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  filter?: Maybe<ProjectFilter>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order?: SortOrder
};


export type RootQueryTypeQuerySimWordsArgs = {
  limit?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>
};


export type RootQueryTypeRecommendationsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  extractedKeywords?: Maybe<Array<Maybe<Scalars['String']>>>,
  filter: SharedCardFilter,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order?: SharedCardOrder,
  projectIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type RootQueryTypeSharedCardsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  filter: SharedCardFilter,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order?: SharedCardOrder,
  projectIds?: Maybe<Array<Maybe<Scalars['ID']>>>
};


export type RootQueryTypeSharedProjectsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  filter: SharedProjectFilter,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  order?: SharedProjectOrder
};


export type RootQueryTypeThemesArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  filter: ThemeFilter,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>
};

export type SendInvitationCodesInput = {
  phones: Array<Scalars['String']>,
  templateId: Scalars['String'],
};

export type SendMessagesInput = {
  phones: Array<Scalars['String']>,
  templateId: Scalars['String'],
};

export type Session = {
   __typename?: 'Session',
  token?: Maybe<Scalars['String']>,
  user?: Maybe<User>,
};

export type SessionPayload = {
   __typename?: 'SessionPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<Session>,
  successful: Scalars['Boolean'],
};

export type SharedCard = Node & {
   __typename?: 'SharedCard',
  children?: Maybe<Array<Maybe<SharedCard>>>,
  childrenCounts?: Maybe<Scalars['Int']>,
  code?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  fileType?: Maybe<FileType>,
  id: Scalars['ID'],
  metadata?: Maybe<Scalars['Json']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<CardType>,
};


export type SharedCardChildrenCountsArgs = {
  projectId: Scalars['ID']
};

export type SharedCardConnection = {
   __typename?: 'SharedCardConnection',
  count?: Maybe<Scalars['Int']>,
  edges?: Maybe<Array<Maybe<SharedCardEdge>>>,
  pageInfo: PageInfo,
};

export type SharedCardEdge = {
   __typename?: 'SharedCardEdge',
  cursor: Scalars['String'],
  node?: Maybe<SharedCard>,
};

export type SharedCardFilter = {
  autoGenTags?: Maybe<Array<Maybe<Scalars['String']>>>,
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  fileTypes?: Maybe<Array<Maybe<FileType>>>,
  types: Array<SharedCardType>,
};

export enum SharedCardOrder {
  DescCollectionCounts = 'DESC_COLLECTION_COUNTS',
  DescInsertedAt = 'DESC_INSERTED_AT'
}

export enum SharedCardType {
  Golden = 'GOLDEN',
  Wiki = 'WIKI'
}

export type SharedProject = Node & {
   __typename?: 'SharedProject',
  cardCounts?: Maybe<Scalars['Int']>,
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  code?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  sharedCards?: Maybe<SharedCardConnection>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<SharedProjectType>,
};


export type SharedProjectSharedCardsArgs = {
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>
};

export type SharedProjectCategory = {
   __typename?: 'SharedProjectCategory',
  name?: Maybe<Scalars['String']>,
  sharedProject?: Maybe<Array<Maybe<SharedProject>>>,
};

export type SharedProjectConnection = {
   __typename?: 'SharedProjectConnection',
  count?: Maybe<Scalars['Int']>,
  edges?: Maybe<Array<Maybe<SharedProjectEdge>>>,
  pageInfo: PageInfo,
};

export type SharedProjectEdge = {
   __typename?: 'SharedProjectEdge',
  cursor: Scalars['String'],
  node?: Maybe<SharedProject>,
};

export type SharedProjectFilter = {
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
  isSpecialWiki?: Maybe<Scalars['Boolean']>,
  type: SharedProjectType,
};

export enum SharedProjectOrder {
  DescCollectionCounts = 'DESC_COLLECTION_COUNTS',
  DescInsertedAt = 'DESC_INSERTED_AT'
}

export enum SharedProjectType {
  Golden = 'GOLDEN',
  Wiki = 'WIKI'
}

export type SimWord = {
   __typename?: 'SimWord',
  weight?: Maybe<Scalars['Float']>,
  word?: Maybe<Scalars['String']>,
};

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Taboo = {
   __typename?: 'Taboo',
  advice?: Maybe<Scalars['String']>,
  cardId?: Maybe<Scalars['ID']>,
  category?: Maybe<Scalars['String']>,
  end?: Maybe<Scalars['Int']>,
  sentence?: Maybe<Scalars['String']>,
  start?: Maybe<Scalars['Int']>,
  wrong?: Maybe<Scalars['String']>,
};

export type Theme = Node & {
   __typename?: 'Theme',
  category?: Maybe<Scalars['String']>,
  coverImageUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  info?: Maybe<Scalars['Json']>,
  title?: Maybe<Scalars['String']>,
  type?: Maybe<ThemeType>,
  user?: Maybe<User>,
};

export type ThemeConnection = {
   __typename?: 'ThemeConnection',
  count?: Maybe<Scalars['Int']>,
  edges?: Maybe<Array<Maybe<ThemeEdge>>>,
  pageInfo: PageInfo,
};

export type ThemeEdge = {
   __typename?: 'ThemeEdge',
  cursor: Scalars['String'],
  node?: Maybe<Theme>,
};

export type ThemeFilter = {
  categories?: Maybe<Array<Maybe<Scalars['String']>>>,
};

export type ThemePayload = {
   __typename?: 'ThemePayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<Theme>,
  successful: Scalars['Boolean'],
};

export enum ThemeType {
  BuildIn = 'BUILD_IN',
  User = 'USER'
}

export type Token = {
   __typename?: 'Token',
  cardId?: Maybe<Scalars['ID']>,
  end?: Maybe<Scalars['Int']>,
  start?: Maybe<Scalars['Int']>,
  word?: Maybe<Scalars['String']>,
};

export type UpdateCardContentInput = {
  content: Scalars['String'],
  id: Scalars['ID'],
  title: Scalars['String'],
};

export type UpdateCardInput = {
  content?: Maybe<Scalars['String']>,
  coordinate?: Maybe<Array<Maybe<Scalars['Int']>>>,
  id: Scalars['ID'],
  metadata?: Maybe<Scalars['Json']>,
  revision: Scalars['Boolean'],
  tags?: Maybe<Array<Maybe<Scalars['String']>>>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateCardsContentInput = {
  cardsToUpdate: Array<UpdateCardContentInput>,
};

export type UpdateProjectInput = {
  coverImageUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isDeleted?: Maybe<Scalars['Boolean']>,
  lastSeenAt?: Maybe<Scalars['Datetime']>,
  outline?: Maybe<Scalars['Json']>,
  outlineState?: Maybe<Scalars['Json']>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateThemeInput = {
  category?: Maybe<Scalars['String']>,
  coverImageUrl?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  info?: Maybe<Scalars['Json']>,
  title?: Maybe<Scalars['String']>,
};

export type UpdateUserInput = {
  avatar?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
};

export type User = Node & {
   __typename?: 'User',
  avatar?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  nickname?: Maybe<Scalars['String']>,
  role?: Maybe<UserRole>,
  userAuths?: Maybe<Array<Maybe<UserAuth>>>,
  userProfile?: Maybe<UserProfile>,
  username?: Maybe<Scalars['String']>,
};

export type UserAuth = Node & {
   __typename?: 'UserAuth',
  id: Scalars['ID'],
  identifier?: Maybe<Scalars['String']>,
  identityType?: Maybe<IdentityType>,
};

export type UserAuthPayload = {
   __typename?: 'UserAuthPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<UserAuth>,
  successful: Scalars['Boolean'],
};

export type UserPayload = {
   __typename?: 'UserPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<User>,
  successful: Scalars['Boolean'],
};

export type UserProfile = Node & {
   __typename?: 'UserProfile',
  id: Scalars['ID'],
  vipExpiredAt?: Maybe<Scalars['Datetime']>,
  vipLevel?: Maybe<Scalars['String']>,
};

export enum UserRole {
  Admin = 'ADMIN',
  User = 'USER'
}

export type ValidationMessage = {
   __typename?: 'ValidationMessage',
  code: Scalars['String'],
  field?: Maybe<Scalars['String']>,
  message?: Maybe<Scalars['String']>,
  options?: Maybe<Array<Maybe<ValidationOption>>>,
  template?: Maybe<Scalars['String']>,
};

export type ValidationOption = {
   __typename?: 'ValidationOption',
  key: Scalars['String'],
  value: Scalars['String'],
};

export type WeixinUser = {
   __typename?: 'WeixinUser',
  headimgurl?: Maybe<Scalars['String']>,
  nickname?: Maybe<Scalars['String']>,
  openid?: Maybe<Scalars['String']>,
};

export type WeixinUserPayload = {
   __typename?: 'WeixinUserPayload',
  messages?: Maybe<Array<Maybe<ValidationMessage>>>,
  result?: Maybe<WeixinUser>,
  successful: Scalars['Boolean'],
};

export type GetSharedCardsQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  filter: SharedCardFilter
};


export type GetSharedCardsQuery = (
  { __typename?: 'RootQueryType' }
  & { sharedCards: Maybe<(
    { __typename?: 'SharedCardConnection' }
    & Pick<SharedCardConnection, 'count'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'SharedCardEdge' }
      & Pick<SharedCardEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'SharedCard' }
        & Pick<SharedCard, 'id' | 'title' | 'content'>
      )> }
    )>>> }
  )> }
);

export type InspirationMenuQueryVariables = {
  type: SharedProjectType
};


export type InspirationMenuQuery = (
  { __typename?: 'RootQueryType' }
  & { inspirationMenu: Maybe<(
    { __typename?: 'InspirationMenu' }
    & { hottest: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'code' | 'title' | 'cardCounts'>
    )>>>, newest: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'code' | 'title' | 'cardCounts'>
    )>>>, recommended: Maybe<Array<Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'code' | 'title'>
    )>>>, random: Maybe<(
      { __typename?: 'SharedCard' }
      & Pick<SharedCard, 'code' | 'content'>
    )>, more: Maybe<Array<Maybe<(
      { __typename?: 'SharedProjectCategory' }
      & Pick<SharedProjectCategory, 'name'>
      & { sharedProject: Maybe<Array<Maybe<(
        { __typename?: 'SharedProject' }
        & Pick<SharedProject, 'id' | 'code' | 'title' | 'cardCounts'>
      )>>> }
    )>>> }
  )> }
);

export type ProjectCategoriesQueryVariables = {
  type: SharedProjectType
};


export type ProjectCategoriesQuery = (
  { __typename?: 'RootQueryType' }
  & { projectCategories: Maybe<Array<Maybe<(
    { __typename?: 'SharedProjectCategory' }
    & Pick<SharedProjectCategory, 'name'>
    & { sharedProject: Maybe<Array<Maybe<(
      { __typename?: 'SharedProject' }
      & Pick<SharedProject, 'id' | 'code' | 'title' | 'cardCounts' | 'type'>
    )>>> }
  )>>> }
);

export type SharedCardsQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  projectIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order: SharedCardOrder,
  filter: SharedCardFilter
};


export type SharedCardsQuery = (
  { __typename?: 'RootQueryType' }
  & { sharedCards: Maybe<(
    { __typename?: 'SharedCardConnection' }
    & Pick<SharedCardConnection, 'count'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'SharedCardEdge' }
      & Pick<SharedCardEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'SharedCard' }
        & Pick<SharedCard, 'content' | 'id' | 'code' | 'fileType' | 'title' | 'type' | 'metadata'>
      )> }
    )>>> }
  )> }
);

export type SharedProjectsQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  order: SharedProjectOrder,
  filter: SharedProjectFilter
};


export type SharedProjectsQuery = (
  { __typename?: 'RootQueryType' }
  & { sharedProjects: Maybe<(
    { __typename?: 'SharedProjectConnection' }
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'SharedProjectEdge' }
      & Pick<SharedProjectEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'SharedProject' }
        & Pick<SharedProject, 'id' | 'code' | 'title' | 'type' | 'cardCounts'>
      )> }
    )>>> }
  )> }
);

export type ReferenceCardsMutationVariables = {
  input: ReferenceCardsInput
};


export type ReferenceCardsMutation = (
  { __typename?: 'RootMutationType' }
  & { referenceCards: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type ReferenceProjectMutationVariables = {
  input: ReferenceProjectInput
};


export type ReferenceProjectMutation = (
  { __typename?: 'RootMutationType' }
  & { referenceProject: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type GetInspirationCardFolderQueryVariables = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['String']>
};


export type GetInspirationCardFolderQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | { __typename?: 'Project' } | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | { __typename?: 'SharedProject' } | (
    { __typename?: 'SharedCard' }
    & Pick<SharedCard, 'id'>
    & { children: Maybe<Array<Maybe<(
      { __typename?: 'SharedCard' }
      & Pick<SharedCard, 'id' | 'code' | 'content' | 'title' | 'fileType' | 'type' | 'metadata'>
    )>>> }
  ) | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type GetInspirationCardInfoQueryVariables = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['String']>
};


export type GetInspirationCardInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | { __typename?: 'Project' } | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | { __typename?: 'SharedProject' } | (
    { __typename?: 'SharedCard' }
    & Pick<SharedCard, 'id' | 'code' | 'content' | 'title' | 'fileType' | 'type' | 'metadata'>
  ) | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type GetInspirationProjectDetailQueryVariables = {
  id: Scalars['ID'],
  first?: Maybe<Scalars['Int']>,
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>
};


export type GetInspirationProjectDetailQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | { __typename?: 'Project' } | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | (
    { __typename?: 'SharedProject' }
    & Pick<SharedProject, 'id' | 'code' | 'title' | 'cardCounts' | 'type'>
    & { sharedCards: Maybe<(
      { __typename?: 'SharedCardConnection' }
      & Pick<SharedCardConnection, 'count'>
      & { edges: Maybe<Array<Maybe<(
        { __typename?: 'SharedCardEdge' }
        & { node: Maybe<(
          { __typename?: 'SharedCard' }
          & Pick<SharedCard, 'id' | 'code' | 'title' | 'fileType' | 'type' | 'content' | 'metadata' | 'childrenCounts'>
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  ) | { __typename?: 'SharedCard' } | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type GetInspirationProjectInfoQueryVariables = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['String']>
};


export type GetInspirationProjectInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | { __typename?: 'Project' } | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | (
    { __typename?: 'SharedProject' }
    & Pick<SharedProject, 'id' | 'code' | 'title' | 'categories' | 'cardCounts'>
  ) | { __typename?: 'SharedCard' } | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type GetInspirationPackageInfoQueryVariables = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['String']>,
  projectId: Scalars['ID']
};


export type GetInspirationPackageInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | { __typename?: 'Project' } | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | { __typename?: 'SharedProject' } | (
    { __typename?: 'SharedCard' }
    & Pick<SharedCard, 'id' | 'code' | 'title' | 'childrenCounts' | 'type' | 'fileType'>
  ) | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type VerifyInvitationCodeMutationVariables = {
  invitationCode?: Maybe<Scalars['String']>
};


export type VerifyInvitationCodeMutation = (
  { __typename?: 'RootMutationType' }
  & { verifyInvitationCode: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type SendVerificationCodeMutationVariables = {
  phone?: Maybe<Scalars['String']>
};


export type SendVerificationCodeMutation = (
  { __typename?: 'RootMutationType' }
  & { sendVerificationCode: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type VerfiyWeixinMutationVariables = {
  code?: Maybe<Scalars['String']>
};


export type VerfiyWeixinMutation = (
  { __typename?: 'RootMutationType' }
  & { verifyWeixin: Maybe<(
    { __typename?: 'WeixinUserPayload' }
    & Pick<WeixinUserPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'WeixinUser' }
      & Pick<WeixinUser, 'headimgurl' | 'nickname' | 'openid'>
    )> }
  )> }
);

export type RegisterMutationVariables = {
  input: RegisterInput
};


export type RegisterMutation = (
  { __typename?: 'RootMutationType' }
  & { register: Maybe<(
    { __typename?: 'UserPayload' }
    & Pick<UserPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'avatar' | 'id' | 'nickname' | 'role' | 'username'>
      & { userAuths: Maybe<Array<Maybe<(
        { __typename?: 'UserAuth' }
        & Pick<UserAuth, 'id' | 'identifier' | 'identityType'>
      )>>> }
    )> }
  )> }
);

export type LoginMutationVariables = {
  input?: Maybe<LoginInput>
};


export type LoginMutation = (
  { __typename?: 'RootMutationType' }
  & { login: Maybe<(
    { __typename?: 'SessionPayload' }
    & Pick<SessionPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'token'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'avatar' | 'id' | 'nickname' | 'role' | 'username'>
        & { userAuths: Maybe<Array<Maybe<(
          { __typename?: 'UserAuth' }
          & Pick<UserAuth, 'id' | 'identifier' | 'identityType'>
        )>>> }
      )> }
    )> }
  )> }
);

export type GetCorpusProjectQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order: SortOrder,
  filter?: Maybe<ProjectFilter>
};


export type GetCorpusProjectQuery = (
  { __typename?: 'RootQueryType' }
  & { projects: Maybe<(
    { __typename?: 'ProjectConnection' }
    & Pick<ProjectConnection, 'count'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'ProjectEdge' }
      & Pick<ProjectEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'code' | 'title' | 'cardCounts' | 'type' | 'coverImageUrl' | 'from' | 'outline'>
      )> }
    )>>> }
  )> }
);

export type GetCorpusCardsQueryVariables = {
  first?: Maybe<Scalars['Int']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  filter: CardFilter,
  order: SortOrder,
  matching?: Maybe<Scalars['String']>,
  projectId?: Maybe<Scalars['ID']>
};


export type GetCorpusCardsQuery = (
  { __typename?: 'RootQueryType' }
  & { cards: Maybe<(
    { __typename?: 'CardConnection' }
    & Pick<CardConnection, 'count'>
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'CardEdge' }
      & Pick<CardEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'id' | 'code' | 'categories' | 'content' | 'coordinate' | 'insertedAt' | 'tags' | 'title' | 'type' | 'fileType' | 'metadata'>
      )> }
    )>>> }
  )> }
);

export type GetCardInfoQueryVariables = {
  first?: Maybe<Scalars['Int']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  order: SortOrder,
  filter: CardFilter
};


export type GetCardInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { cards: Maybe<(
    { __typename?: 'CardConnection' }
    & Pick<CardConnection, 'count'>
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'CardEdge' }
      & Pick<CardEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'id' | 'code' | 'categories' | 'content' | 'coordinate' | 'insertedAt' | 'tags' | 'title' | 'type' | 'fileType' | 'metadata'>
      )> }
    )>>> }
  )> }
);

export type GetDeleteProjectListQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order: SortOrder,
  filter?: Maybe<ProjectFilter>
};


export type GetDeleteProjectListQuery = (
  { __typename?: 'RootQueryType' }
  & { projects: Maybe<(
    { __typename?: 'ProjectConnection' }
    & Pick<ProjectConnection, 'count'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'ProjectEdge' }
      & Pick<ProjectEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'code' | 'title' | 'insertedAt' | 'lastSeenAt' | 'coverImageUrl'>
      )> }
    )>>> }
  )> }
);

export type GetProjectListQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order: SortOrder,
  filter?: Maybe<ProjectFilter>
};


export type GetProjectListQuery = (
  { __typename?: 'RootQueryType' }
  & { projects: Maybe<(
    { __typename?: 'ProjectConnection' }
    & Pick<ProjectConnection, 'count'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'ProjectEdge' }
      & Pick<ProjectEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Project' }
        & Pick<Project, 'id' | 'code' | 'title' | 'insertedAt' | 'lastSeenAt' | 'cardCounts' | 'type' | 'coverImageUrl' | 'outline' | 'outlineState'>
      )> }
    )>>> }
  )> }
);

export type GetCardListQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  matching?: Maybe<Scalars['String']>,
  order: SortOrder,
  filter: CardFilter
};


export type GetCardListQuery = (
  { __typename?: 'RootQueryType' }
  & { cards: Maybe<(
    { __typename?: 'CardConnection' }
    & Pick<CardConnection, 'count'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'CardEdge' }
      & Pick<CardEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'code' | 'categories' | 'content' | 'coordinate' | 'id' | 'insertedAt' | 'tags' | 'title' | 'type' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type GetCardsStatsQueryVariables = {
  types: Array<Maybe<CardType>>
};


export type GetCardsStatsQuery = (
  { __typename?: 'RootQueryType' }
  & { cardsStats: Maybe<Array<Maybe<(
    { __typename?: 'CardsStat' }
    & Pick<CardsStat, 'count' | 'month' | 'year'>
  )>>> }
);

export type SetOpenProjectDateMutationVariables = {
  input: UpdateProjectInput
};


export type SetOpenProjectDateMutation = (
  { __typename?: 'RootMutationType' }
  & { updateProject: Maybe<(
    { __typename?: 'ProjectPayload' }
    & Pick<ProjectPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput
};


export type DeleteProjectMutation = (
  { __typename?: 'RootMutationType' }
  & { securelyDeleteProject: Maybe<(
    { __typename?: 'ProjectPayload' }
    & Pick<ProjectPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type GetMyInfoQueryVariables = {};


export type GetMyInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { me: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'role'>
    & { userProfile: Maybe<(
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'id' | 'vipLevel' | 'vipExpiredAt'>
    )> }
  )> }
);

export type CreateProjectMutationVariables = {
  input: CreateProjectInput
};


export type CreateProjectMutation = (
  { __typename?: 'RootMutationType' }
  & { createProject: Maybe<(
    { __typename?: 'ProjectPayload' }
    & Pick<ProjectPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'title' | 'insertedAt' | 'outline' | 'code'>
    )> }
  )> }
);

export type CreateProjectByThemeMutationVariables = {
  input: CreateProjectByThemeInput
};


export type CreateProjectByThemeMutation = (
  { __typename?: 'RootMutationType' }
  & { createProjectByTheme: Maybe<(
    { __typename?: 'ProjectPayload' }
    & Pick<ProjectPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'title' | 'insertedAt' | 'outline' | 'code'>
    )> }
  )> }
);

export type GetThemesQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  before?: Maybe<Scalars['String']>,
  last?: Maybe<Scalars['Int']>,
  filter: ThemeFilter,
  matching?: Maybe<Scalars['String']>
};


export type GetThemesQuery = (
  { __typename?: 'RootQueryType' }
  & { themes: Maybe<(
    { __typename?: 'ThemeConnection' }
    & Pick<ThemeConnection, 'count'>
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'ThemeEdge' }
      & { node: Maybe<(
        { __typename?: 'Theme' }
        & Pick<Theme, 'id' | 'title' | 'coverImageUrl'>
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type GetStatisticDataQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type GetStatisticDataQuery = (
  { __typename?: 'RootQueryType' }
  & { calculateIndex: Maybe<(
    { __typename?: 'CalculateIndexResult' }
    & Pick<CalculateIndexResult, 'indexMap'>
  )> }
);

export type GetExtractKeyWordsQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type GetExtractKeyWordsQuery = (
  { __typename?: 'RootQueryType' }
  & { extractKeywords: Maybe<(
    { __typename?: 'ExtractKeywordsResult' }
    & { keywords: Maybe<Array<Maybe<(
      { __typename?: 'Keyword' }
      & Pick<Keyword, 'weight' | 'word'>
    )>>> }
  )> }
);

export type GetInspirationSearchCardsQueryVariables = {
  after?: Maybe<Scalars['String']>,
  first?: Maybe<Scalars['Int']>,
  extractedKeywords?: Maybe<Array<Maybe<Scalars['String']>>>,
  matching?: Maybe<Scalars['String']>,
  filter: SharedCardFilter
};


export type GetInspirationSearchCardsQuery = (
  { __typename?: 'RootQueryType' }
  & { recommendations: Maybe<(
    { __typename?: 'SharedCardConnection' }
    & Pick<SharedCardConnection, 'count'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ), edges: Maybe<Array<Maybe<(
      { __typename?: 'SharedCardEdge' }
      & Pick<SharedCardEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'SharedCard' }
        & Pick<SharedCard, 'id' | 'code' | 'title' | 'content'>
      )> }
    )>>> }
  )> }
);

export type GetProjectDetailInfoQueryVariables = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['String']>
};


export type GetProjectDetailInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'code' | 'title' | 'outline' | 'outlineState' | 'insertedAt' | 'type' | 'updatedAt'>
  ) | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | { __typename?: 'SharedProject' } | { __typename?: 'SharedCard' } | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type GetSingleCardQueryVariables = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['String']>
};


export type GetSingleCardQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<(
    { __typename?: 'Card' }
    & Pick<Card, 'id' | 'code' | 'categories' | 'content' | 'coordinate' | 'insertedAt' | 'tags' | 'title' | 'type' | 'fileType' | 'updatedAt'>
  ) | { __typename?: 'Project' } | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | { __typename?: 'SharedProject' } | { __typename?: 'SharedCard' } | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput
};


export type UpdateProjectMutation = (
  { __typename?: 'RootMutationType' }
  & { updateProject: Maybe<(
    { __typename?: 'ProjectPayload' }
    & Pick<ProjectPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Project' }
      & Pick<Project, 'id' | 'title' | 'insertedAt' | 'outline' | 'outlineState'>
    )> }
  )> }
);

export type CreateCardsMutationVariables = {
  input: CreateCardsInput
};


export type CreateCardsMutation = (
  { __typename?: 'RootMutationType' }
  & { createCards: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type UpdateCardsContentMutationVariables = {
  input: UpdateCardsContentInput
};


export type UpdateCardsContentMutation = (
  { __typename?: 'RootMutationType' }
  & { updateCardsContent: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type UpdateCardMutationVariables = {
  input: UpdateCardInput
};


export type UpdateCardMutation = (
  { __typename?: 'RootMutationType' }
  & { updateCard: Maybe<(
    { __typename?: 'CardPayload' }
    & Pick<CardPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'Card' }
      & Pick<Card, 'coordinate' | 'id' | 'title' | 'content' | 'tags'>
    )> }
  )> }
);

export type CheckQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type CheckQuery = (
  { __typename?: 'RootQueryType' }
  & { check: Maybe<(
    { __typename?: 'CheckResult' }
    & Pick<CheckResult, 'unsuccessfulIds'>
    & { mistakes: Maybe<Array<Maybe<(
      { __typename?: 'Mistake' }
      & Pick<Mistake, 'advice' | 'cardId' | 'category' | 'wrong' | 'end' | 'start' | 'sentence'>
    )>>> }
  )> }
);

export type CheckTabooQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type CheckTabooQuery = (
  { __typename?: 'RootQueryType' }
  & { checkTaboo: Maybe<(
    { __typename?: 'CheckTabooResult' }
    & Pick<CheckTabooResult, 'unsuccessfulIds'>
    & { taboos: Maybe<Array<Maybe<(
      { __typename?: 'Taboo' }
      & Pick<Taboo, 'advice' | 'cardId' | 'category' | 'wrong' | 'end' | 'start' | 'sentence'>
    )>>> }
  )> }
);

export type GetCardsQueryVariables = {
  first?: Maybe<Scalars['Int']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  filter: CardFilter
};


export type GetCardsQuery = (
  { __typename?: 'RootQueryType' }
  & { cards: Maybe<(
    { __typename?: 'CardConnection' }
    & Pick<CardConnection, 'count'>
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'CardEdge' }
      & Pick<CardEdge, 'cursor'>
      & { node: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'id' | 'code' | 'categories' | 'content' | 'coordinate' | 'insertedAt' | 'tags' | 'title' | 'type' | 'fileType' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type CopyCardsMutationVariables = {
  input: CopyCardsInput
};


export type CopyCardsMutation = (
  { __typename?: 'RootMutationType' }
  & { copyCards: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type DeleteCardsMutationVariables = {
  input: DeleteCardsInput
};


export type DeleteCardsMutation = (
  { __typename?: 'RootMutationType' }
  & { deleteCards: Maybe<(
    { __typename?: 'CommonPayload' }
    & Pick<CommonPayload, 'successful' | 'result'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>> }
  )> }
);

export type GetBillProjectInfoQueryVariables = {
  id?: Maybe<Scalars['ID']>
};


export type GetBillProjectInfoQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | (
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'title' | 'type'>
    & { user: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'avatar' | 'nickname'>
    )> }
  ) | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | { __typename?: 'SharedProject' } | { __typename?: 'SharedCard' } | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type GetLabelCharWordSentQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type GetLabelCharWordSentQuery = (
  { __typename?: 'RootQueryType' }
  & { labelCharWordSent: Maybe<(
    { __typename?: 'LabelResult' }
    & Pick<LabelResult, 'unsuccessfulIds'>
    & { labels: Maybe<Array<Maybe<(
      { __typename?: 'Label' }
      & Pick<Label, 'cardId' | 'labelMap'>
    )>>> }
  )> }
);

export type PickMetaDiscoursesQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type PickMetaDiscoursesQuery = (
  { __typename?: 'RootQueryType' }
  & { pickMetadiscourses: Maybe<(
    { __typename?: 'MetadiscourseResult' }
    & Pick<MetadiscourseResult, 'unsuccessfulIds'>
    & { metadiscourses: Maybe<Array<Maybe<(
      { __typename?: 'Metadiscourse' }
      & Pick<Metadiscourse, 'cardId' | 'category' | 'word'>
      & { examples: Maybe<Array<Maybe<(
        { __typename?: 'MetadiscourseExample' }
        & Pick<MetadiscourseExample, 'author' | 'sentence' | 'title'>
      )>>>, locations: Maybe<Array<Maybe<(
        { __typename?: 'Location' }
        & Pick<Location, 'end' | 'start'>
      )>>> }
    )>>> }
  )> }
);

export type LabelReplacementQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type LabelReplacementQuery = (
  { __typename?: 'RootQueryType' }
  & { labelReplacement: Maybe<(
    { __typename?: 'LabelReplacementResult' }
    & Pick<LabelReplacementResult, 'unsuccessfulIds'>
    & { replacements: Maybe<Array<Maybe<(
      { __typename?: 'Token' }
      & Pick<Token, 'word' | 'start' | 'end' | 'cardId'>
    )>>> }
  )> }
);

export type GetReplacementWordsQueryVariables = {
  matching?: Maybe<Scalars['String']>
};


export type GetReplacementWordsQuery = (
  { __typename?: 'RootQueryType' }
  & { querySimWords: Maybe<(
    { __typename?: 'QuerySimWordsResult' }
    & { simWords: Maybe<Array<Maybe<(
      { __typename?: 'SimWord' }
      & Pick<SimWord, 'weight' | 'word'>
    )>>> }
  )> }
);

export type GetCardCountQueryVariables = {
  first?: Maybe<Scalars['Int']>,
  filter: CardFilter,
  projectId?: Maybe<Scalars['ID']>
};


export type GetCardCountQuery = (
  { __typename?: 'RootQueryType' }
  & { cards: Maybe<(
    { __typename?: 'CardConnection' }
    & Pick<CardConnection, 'count'>
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'CardEdge' }
      & { node: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'id' | 'title'>
      )> }
    )>>> }
  )> }
);

export type GetProjectUpdateTimeQueryVariables = {
  id?: Maybe<Scalars['ID']>,
  code?: Maybe<Scalars['String']>
};


export type GetProjectUpdateTimeQuery = (
  { __typename?: 'RootQueryType' }
  & { node: Maybe<{ __typename?: 'Card' } | (
    { __typename?: 'Project' }
    & Pick<Project, 'outlineState'>
  ) | { __typename?: 'User' } | { __typename?: 'UserAuth' } | { __typename?: 'UserProfile' } | { __typename?: 'CardRevision' } | { __typename?: 'SharedProject' } | { __typename?: 'SharedCard' } | { __typename?: 'Invitation' } | { __typename?: 'Theme' }> }
);

export type GetCardsUpdateTimeQueryVariables = {
  first?: Maybe<Scalars['Int']>,
  ids?: Maybe<Array<Maybe<Scalars['ID']>>>,
  filter: CardFilter
};


export type GetCardsUpdateTimeQuery = (
  { __typename?: 'RootQueryType' }
  & { cards: Maybe<(
    { __typename?: 'CardConnection' }
    & { edges: Maybe<Array<Maybe<(
      { __typename?: 'CardEdge' }
      & { node: Maybe<(
        { __typename?: 'Card' }
        & Pick<Card, 'id' | 'updatedAt'>
      )> }
    )>>> }
  )> }
);

export type UpdateUserMutationVariables = {
  input?: Maybe<UpdateUserInput>
};


export type UpdateUserMutation = (
  { __typename?: 'RootMutationType' }
  & { updateUser: Maybe<(
    { __typename?: 'UserPayload' }
    & Pick<UserPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'avatar' | 'id' | 'nickname' | 'role' | 'username'>
      & { userAuths: Maybe<Array<Maybe<(
        { __typename?: 'UserAuth' }
        & Pick<UserAuth, 'id' | 'identifier' | 'identityType'>
      )>>> }
    )> }
  )> }
);

export type ResetPhoneMutationVariables = {
  input?: Maybe<ResetPhoneInput>
};


export type ResetPhoneMutation = (
  { __typename?: 'RootMutationType' }
  & { resetPhone: Maybe<(
    { __typename?: 'UserAuthPayload' }
    & Pick<UserAuthPayload, 'successful'>
    & { messages: Maybe<Array<Maybe<(
      { __typename?: 'ValidationMessage' }
      & Pick<ValidationMessage, 'code' | 'field' | 'message'>
    )>>>, result: Maybe<(
      { __typename?: 'UserAuth' }
      & Pick<UserAuth, 'identifier'>
    )> }
  )> }
);


export const GetSharedCardsDocument = gql`
    query GetSharedCards($after: String, $first: Int, $before: String, $last: Int, $matching: String, $filter: SharedCardFilter!) {
  sharedCards(after: $after, first: $first, before: $before, last: $last, matching: $matching, filter: $filter) {
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        title
        content
      }
    }
  }
}
    `;
export type GetSharedCardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSharedCardsQuery, GetSharedCardsQueryVariables>, 'query'> & ({ variables: GetSharedCardsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetSharedCardsComponent = (props: GetSharedCardsComponentProps) => (
      <ApolloReactComponents.Query<GetSharedCardsQuery, GetSharedCardsQueryVariables> query={GetSharedCardsDocument} {...props} />
    );
    
export type GetSharedCardsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSharedCardsQuery, GetSharedCardsQueryVariables> & TChildProps;
export function withGetSharedCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSharedCardsQuery,
  GetSharedCardsQueryVariables,
  GetSharedCardsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSharedCardsQuery, GetSharedCardsQueryVariables, GetSharedCardsProps<TChildProps>>(GetSharedCardsDocument, {
      alias: 'getSharedCards',
      ...operationOptions
    });
};

/**
 * __useGetSharedCardsQuery__
 *
 * To run a query within a React component, call `useGetSharedCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSharedCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSharedCardsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      matching: // value for 'matching'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSharedCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSharedCardsQuery, GetSharedCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSharedCardsQuery, GetSharedCardsQueryVariables>(GetSharedCardsDocument, baseOptions);
      }
export function useGetSharedCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSharedCardsQuery, GetSharedCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSharedCardsQuery, GetSharedCardsQueryVariables>(GetSharedCardsDocument, baseOptions);
        }
export type GetSharedCardsQueryHookResult = ReturnType<typeof useGetSharedCardsQuery>;
export type GetSharedCardsLazyQueryHookResult = ReturnType<typeof useGetSharedCardsLazyQuery>;
export type GetSharedCardsQueryResult = ApolloReactCommon.QueryResult<GetSharedCardsQuery, GetSharedCardsQueryVariables>;
export const InspirationMenuDocument = gql`
    query InspirationMenu($type: SharedProjectType!) {
  inspirationMenu(type: $type) {
    hottest {
      id
      code
      title
      cardCounts
    }
    newest {
      id
      code
      title
      cardCounts
    }
    recommended {
      id
      code
      title
    }
    random {
      code
      content
    }
    more {
      name
      sharedProject {
        id
        code
        title
        cardCounts
      }
    }
  }
}
    `;
export type InspirationMenuComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InspirationMenuQuery, InspirationMenuQueryVariables>, 'query'> & ({ variables: InspirationMenuQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InspirationMenuComponent = (props: InspirationMenuComponentProps) => (
      <ApolloReactComponents.Query<InspirationMenuQuery, InspirationMenuQueryVariables> query={InspirationMenuDocument} {...props} />
    );
    
export type InspirationMenuProps<TChildProps = {}> = ApolloReactHoc.DataProps<InspirationMenuQuery, InspirationMenuQueryVariables> & TChildProps;
export function withInspirationMenu<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InspirationMenuQuery,
  InspirationMenuQueryVariables,
  InspirationMenuProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, InspirationMenuQuery, InspirationMenuQueryVariables, InspirationMenuProps<TChildProps>>(InspirationMenuDocument, {
      alias: 'inspirationMenu',
      ...operationOptions
    });
};

/**
 * __useInspirationMenuQuery__
 *
 * To run a query within a React component, call `useInspirationMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useInspirationMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInspirationMenuQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useInspirationMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InspirationMenuQuery, InspirationMenuQueryVariables>) {
        return ApolloReactHooks.useQuery<InspirationMenuQuery, InspirationMenuQueryVariables>(InspirationMenuDocument, baseOptions);
      }
export function useInspirationMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InspirationMenuQuery, InspirationMenuQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InspirationMenuQuery, InspirationMenuQueryVariables>(InspirationMenuDocument, baseOptions);
        }
export type InspirationMenuQueryHookResult = ReturnType<typeof useInspirationMenuQuery>;
export type InspirationMenuLazyQueryHookResult = ReturnType<typeof useInspirationMenuLazyQuery>;
export type InspirationMenuQueryResult = ApolloReactCommon.QueryResult<InspirationMenuQuery, InspirationMenuQueryVariables>;
export const ProjectCategoriesDocument = gql`
    query ProjectCategories($type: SharedProjectType!) {
  projectCategories(type: $type) {
    name
    sharedProject {
      id
      code
      title
      cardCounts
      type
    }
  }
}
    `;
export type ProjectCategoriesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectCategoriesQuery, ProjectCategoriesQueryVariables>, 'query'> & ({ variables: ProjectCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectCategoriesComponent = (props: ProjectCategoriesComponentProps) => (
      <ApolloReactComponents.Query<ProjectCategoriesQuery, ProjectCategoriesQueryVariables> query={ProjectCategoriesDocument} {...props} />
    );
    
export type ProjectCategoriesProps<TChildProps = {}> = ApolloReactHoc.DataProps<ProjectCategoriesQuery, ProjectCategoriesQueryVariables> & TChildProps;
export function withProjectCategories<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectCategoriesQuery,
  ProjectCategoriesQueryVariables,
  ProjectCategoriesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectCategoriesQuery, ProjectCategoriesQueryVariables, ProjectCategoriesProps<TChildProps>>(ProjectCategoriesDocument, {
      alias: 'projectCategories',
      ...operationOptions
    });
};

/**
 * __useProjectCategoriesQuery__
 *
 * To run a query within a React component, call `useProjectCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCategoriesQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useProjectCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectCategoriesQuery, ProjectCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectCategoriesQuery, ProjectCategoriesQueryVariables>(ProjectCategoriesDocument, baseOptions);
      }
export function useProjectCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectCategoriesQuery, ProjectCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectCategoriesQuery, ProjectCategoriesQueryVariables>(ProjectCategoriesDocument, baseOptions);
        }
export type ProjectCategoriesQueryHookResult = ReturnType<typeof useProjectCategoriesQuery>;
export type ProjectCategoriesLazyQueryHookResult = ReturnType<typeof useProjectCategoriesLazyQuery>;
export type ProjectCategoriesQueryResult = ApolloReactCommon.QueryResult<ProjectCategoriesQuery, ProjectCategoriesQueryVariables>;
export const SharedCardsDocument = gql`
    query SharedCards($after: String, $first: Int, $before: String, $projectIds: [ID], $last: Int, $matching: String, $order: SharedCardOrder!, $filter: SharedCardFilter!) {
  sharedCards(after: $after, first: $first, before: $before, projectIds: $projectIds, last: $last, matching: $matching, order: $order, filter: $filter) {
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        content
        id
        code
        fileType
        title
        type
        metadata
      }
    }
  }
}
    `;
export type SharedCardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SharedCardsQuery, SharedCardsQueryVariables>, 'query'> & ({ variables: SharedCardsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SharedCardsComponent = (props: SharedCardsComponentProps) => (
      <ApolloReactComponents.Query<SharedCardsQuery, SharedCardsQueryVariables> query={SharedCardsDocument} {...props} />
    );
    
export type SharedCardsProps<TChildProps = {}> = ApolloReactHoc.DataProps<SharedCardsQuery, SharedCardsQueryVariables> & TChildProps;
export function withSharedCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SharedCardsQuery,
  SharedCardsQueryVariables,
  SharedCardsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SharedCardsQuery, SharedCardsQueryVariables, SharedCardsProps<TChildProps>>(SharedCardsDocument, {
      alias: 'sharedCards',
      ...operationOptions
    });
};

/**
 * __useSharedCardsQuery__
 *
 * To run a query within a React component, call `useSharedCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedCardsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      projectIds: // value for 'projectIds'
 *      last: // value for 'last'
 *      matching: // value for 'matching'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSharedCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedCardsQuery, SharedCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedCardsQuery, SharedCardsQueryVariables>(SharedCardsDocument, baseOptions);
      }
export function useSharedCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedCardsQuery, SharedCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedCardsQuery, SharedCardsQueryVariables>(SharedCardsDocument, baseOptions);
        }
export type SharedCardsQueryHookResult = ReturnType<typeof useSharedCardsQuery>;
export type SharedCardsLazyQueryHookResult = ReturnType<typeof useSharedCardsLazyQuery>;
export type SharedCardsQueryResult = ApolloReactCommon.QueryResult<SharedCardsQuery, SharedCardsQueryVariables>;
export const SharedProjectsDocument = gql`
    query SharedProjects($after: String, $first: Int, $before: String, $last: Int, $order: SharedProjectOrder!, $filter: SharedProjectFilter!) {
  sharedProjects(after: $after, first: $first, before: $before, last: $last, order: $order, filter: $filter) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        code
        title
        type
        cardCounts
      }
    }
  }
}
    `;
export type SharedProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SharedProjectsQuery, SharedProjectsQueryVariables>, 'query'> & ({ variables: SharedProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SharedProjectsComponent = (props: SharedProjectsComponentProps) => (
      <ApolloReactComponents.Query<SharedProjectsQuery, SharedProjectsQueryVariables> query={SharedProjectsDocument} {...props} />
    );
    
export type SharedProjectsProps<TChildProps = {}> = ApolloReactHoc.DataProps<SharedProjectsQuery, SharedProjectsQueryVariables> & TChildProps;
export function withSharedProjects<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SharedProjectsQuery,
  SharedProjectsQueryVariables,
  SharedProjectsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, SharedProjectsQuery, SharedProjectsQueryVariables, SharedProjectsProps<TChildProps>>(SharedProjectsDocument, {
      alias: 'sharedProjects',
      ...operationOptions
    });
};

/**
 * __useSharedProjectsQuery__
 *
 * To run a query within a React component, call `useSharedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedProjectsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSharedProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedProjectsQuery, SharedProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedProjectsQuery, SharedProjectsQueryVariables>(SharedProjectsDocument, baseOptions);
      }
export function useSharedProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedProjectsQuery, SharedProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedProjectsQuery, SharedProjectsQueryVariables>(SharedProjectsDocument, baseOptions);
        }
export type SharedProjectsQueryHookResult = ReturnType<typeof useSharedProjectsQuery>;
export type SharedProjectsLazyQueryHookResult = ReturnType<typeof useSharedProjectsLazyQuery>;
export type SharedProjectsQueryResult = ApolloReactCommon.QueryResult<SharedProjectsQuery, SharedProjectsQueryVariables>;
export const ReferenceCardsDocument = gql`
    mutation ReferenceCards($input: ReferenceCardsInput!) {
  referenceCards(input: $input) {
    successful
    result
    messages {
      code
      field
      message
    }
  }
}
    `;
export type ReferenceCardsMutationFn = ApolloReactCommon.MutationFunction<ReferenceCardsMutation, ReferenceCardsMutationVariables>;
export type ReferenceCardsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReferenceCardsMutation, ReferenceCardsMutationVariables>, 'mutation'>;

    export const ReferenceCardsComponent = (props: ReferenceCardsComponentProps) => (
      <ApolloReactComponents.Mutation<ReferenceCardsMutation, ReferenceCardsMutationVariables> mutation={ReferenceCardsDocument} {...props} />
    );
    
export type ReferenceCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReferenceCardsMutation, ReferenceCardsMutationVariables> & TChildProps;
export function withReferenceCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReferenceCardsMutation,
  ReferenceCardsMutationVariables,
  ReferenceCardsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReferenceCardsMutation, ReferenceCardsMutationVariables, ReferenceCardsProps<TChildProps>>(ReferenceCardsDocument, {
      alias: 'referenceCards',
      ...operationOptions
    });
};

/**
 * __useReferenceCardsMutation__
 *
 * To run a mutation, you first call `useReferenceCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReferenceCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [referenceCardsMutation, { data, loading, error }] = useReferenceCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReferenceCardsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReferenceCardsMutation, ReferenceCardsMutationVariables>) {
        return ApolloReactHooks.useMutation<ReferenceCardsMutation, ReferenceCardsMutationVariables>(ReferenceCardsDocument, baseOptions);
      }
export type ReferenceCardsMutationHookResult = ReturnType<typeof useReferenceCardsMutation>;
export type ReferenceCardsMutationResult = ApolloReactCommon.MutationResult<ReferenceCardsMutation>;
export type ReferenceCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<ReferenceCardsMutation, ReferenceCardsMutationVariables>;
export const ReferenceProjectDocument = gql`
    mutation ReferenceProject($input: ReferenceProjectInput!) {
  referenceProject(input: $input) {
    successful
    result
    messages {
      code
      field
      message
    }
  }
}
    `;
export type ReferenceProjectMutationFn = ApolloReactCommon.MutationFunction<ReferenceProjectMutation, ReferenceProjectMutationVariables>;
export type ReferenceProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReferenceProjectMutation, ReferenceProjectMutationVariables>, 'mutation'>;

    export const ReferenceProjectComponent = (props: ReferenceProjectComponentProps) => (
      <ApolloReactComponents.Mutation<ReferenceProjectMutation, ReferenceProjectMutationVariables> mutation={ReferenceProjectDocument} {...props} />
    );
    
export type ReferenceProjectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ReferenceProjectMutation, ReferenceProjectMutationVariables> & TChildProps;
export function withReferenceProject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReferenceProjectMutation,
  ReferenceProjectMutationVariables,
  ReferenceProjectProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ReferenceProjectMutation, ReferenceProjectMutationVariables, ReferenceProjectProps<TChildProps>>(ReferenceProjectDocument, {
      alias: 'referenceProject',
      ...operationOptions
    });
};

/**
 * __useReferenceProjectMutation__
 *
 * To run a mutation, you first call `useReferenceProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReferenceProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [referenceProjectMutation, { data, loading, error }] = useReferenceProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReferenceProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReferenceProjectMutation, ReferenceProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<ReferenceProjectMutation, ReferenceProjectMutationVariables>(ReferenceProjectDocument, baseOptions);
      }
export type ReferenceProjectMutationHookResult = ReturnType<typeof useReferenceProjectMutation>;
export type ReferenceProjectMutationResult = ApolloReactCommon.MutationResult<ReferenceProjectMutation>;
export type ReferenceProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<ReferenceProjectMutation, ReferenceProjectMutationVariables>;
export const GetInspirationCardFolderDocument = gql`
    query GetInspirationCardFolder($id: ID, $code: String) {
  node(id: $id, code: $code) {
    ... on SharedCard {
      id
      children {
        id
        code
        content
        title
        fileType
        type
        metadata
      }
    }
  }
}
    `;
export type GetInspirationCardFolderComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables>, 'query'>;

    export const GetInspirationCardFolderComponent = (props: GetInspirationCardFolderComponentProps) => (
      <ApolloReactComponents.Query<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables> query={GetInspirationCardFolderDocument} {...props} />
    );
    
export type GetInspirationCardFolderProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables> & TChildProps;
export function withGetInspirationCardFolder<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspirationCardFolderQuery,
  GetInspirationCardFolderQueryVariables,
  GetInspirationCardFolderProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables, GetInspirationCardFolderProps<TChildProps>>(GetInspirationCardFolderDocument, {
      alias: 'getInspirationCardFolder',
      ...operationOptions
    });
};

/**
 * __useGetInspirationCardFolderQuery__
 *
 * To run a query within a React component, call `useGetInspirationCardFolderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationCardFolderQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationCardFolderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetInspirationCardFolderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables>(GetInspirationCardFolderDocument, baseOptions);
      }
export function useGetInspirationCardFolderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables>(GetInspirationCardFolderDocument, baseOptions);
        }
export type GetInspirationCardFolderQueryHookResult = ReturnType<typeof useGetInspirationCardFolderQuery>;
export type GetInspirationCardFolderLazyQueryHookResult = ReturnType<typeof useGetInspirationCardFolderLazyQuery>;
export type GetInspirationCardFolderQueryResult = ApolloReactCommon.QueryResult<GetInspirationCardFolderQuery, GetInspirationCardFolderQueryVariables>;
export const GetInspirationCardInfoDocument = gql`
    query GetInspirationCardInfo($id: ID, $code: String) {
  node(id: $id, code: $code) {
    ... on SharedCard {
      id
      code
      content
      title
      fileType
      type
      metadata
    }
  }
}
    `;
export type GetInspirationCardInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables>, 'query'>;

    export const GetInspirationCardInfoComponent = (props: GetInspirationCardInfoComponentProps) => (
      <ApolloReactComponents.Query<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables> query={GetInspirationCardInfoDocument} {...props} />
    );
    
export type GetInspirationCardInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables> & TChildProps;
export function withGetInspirationCardInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspirationCardInfoQuery,
  GetInspirationCardInfoQueryVariables,
  GetInspirationCardInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables, GetInspirationCardInfoProps<TChildProps>>(GetInspirationCardInfoDocument, {
      alias: 'getInspirationCardInfo',
      ...operationOptions
    });
};

/**
 * __useGetInspirationCardInfoQuery__
 *
 * To run a query within a React component, call `useGetInspirationCardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationCardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationCardInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetInspirationCardInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables>(GetInspirationCardInfoDocument, baseOptions);
      }
export function useGetInspirationCardInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables>(GetInspirationCardInfoDocument, baseOptions);
        }
export type GetInspirationCardInfoQueryHookResult = ReturnType<typeof useGetInspirationCardInfoQuery>;
export type GetInspirationCardInfoLazyQueryHookResult = ReturnType<typeof useGetInspirationCardInfoLazyQuery>;
export type GetInspirationCardInfoQueryResult = ApolloReactCommon.QueryResult<GetInspirationCardInfoQuery, GetInspirationCardInfoQueryVariables>;
export const GetInspirationProjectDetailDocument = gql`
    query GetInspirationProjectDetail($id: ID!, $first: Int, $after: String, $before: String, $last: Int, $matching: String) {
  node(id: $id) {
    ... on SharedProject {
      id
      code
      title
      cardCounts
      type
      sharedCards(first: $first, after: $after, before: $before, last: $last, matching: $matching) {
        count
        edges {
          node {
            id
            code
            title
            fileType
            type
            content
            metadata
            childrenCounts(projectId: $id)
          }
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
}
    `;
export type GetInspirationProjectDetailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables>, 'query'> & ({ variables: GetInspirationProjectDetailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInspirationProjectDetailComponent = (props: GetInspirationProjectDetailComponentProps) => (
      <ApolloReactComponents.Query<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables> query={GetInspirationProjectDetailDocument} {...props} />
    );
    
export type GetInspirationProjectDetailProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables> & TChildProps;
export function withGetInspirationProjectDetail<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspirationProjectDetailQuery,
  GetInspirationProjectDetailQueryVariables,
  GetInspirationProjectDetailProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables, GetInspirationProjectDetailProps<TChildProps>>(GetInspirationProjectDetailDocument, {
      alias: 'getInspirationProjectDetail',
      ...operationOptions
    });
};

/**
 * __useGetInspirationProjectDetailQuery__
 *
 * To run a query within a React component, call `useGetInspirationProjectDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationProjectDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationProjectDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      matching: // value for 'matching'
 *   },
 * });
 */
export function useGetInspirationProjectDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables>(GetInspirationProjectDetailDocument, baseOptions);
      }
export function useGetInspirationProjectDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables>(GetInspirationProjectDetailDocument, baseOptions);
        }
export type GetInspirationProjectDetailQueryHookResult = ReturnType<typeof useGetInspirationProjectDetailQuery>;
export type GetInspirationProjectDetailLazyQueryHookResult = ReturnType<typeof useGetInspirationProjectDetailLazyQuery>;
export type GetInspirationProjectDetailQueryResult = ApolloReactCommon.QueryResult<GetInspirationProjectDetailQuery, GetInspirationProjectDetailQueryVariables>;
export const GetInspirationProjectInfoDocument = gql`
    query GetInspirationProjectInfo($id: ID, $code: String) {
  node(id: $id, code: $code) {
    ... on SharedProject {
      id
      code
      title
      categories
      cardCounts
    }
  }
}
    `;
export type GetInspirationProjectInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables>, 'query'>;

    export const GetInspirationProjectInfoComponent = (props: GetInspirationProjectInfoComponentProps) => (
      <ApolloReactComponents.Query<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables> query={GetInspirationProjectInfoDocument} {...props} />
    );
    
export type GetInspirationProjectInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables> & TChildProps;
export function withGetInspirationProjectInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspirationProjectInfoQuery,
  GetInspirationProjectInfoQueryVariables,
  GetInspirationProjectInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables, GetInspirationProjectInfoProps<TChildProps>>(GetInspirationProjectInfoDocument, {
      alias: 'getInspirationProjectInfo',
      ...operationOptions
    });
};

/**
 * __useGetInspirationProjectInfoQuery__
 *
 * To run a query within a React component, call `useGetInspirationProjectInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationProjectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationProjectInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetInspirationProjectInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables>(GetInspirationProjectInfoDocument, baseOptions);
      }
export function useGetInspirationProjectInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables>(GetInspirationProjectInfoDocument, baseOptions);
        }
export type GetInspirationProjectInfoQueryHookResult = ReturnType<typeof useGetInspirationProjectInfoQuery>;
export type GetInspirationProjectInfoLazyQueryHookResult = ReturnType<typeof useGetInspirationProjectInfoLazyQuery>;
export type GetInspirationProjectInfoQueryResult = ApolloReactCommon.QueryResult<GetInspirationProjectInfoQuery, GetInspirationProjectInfoQueryVariables>;
export const GetInspirationPackageInfoDocument = gql`
    query GetInspirationPackageInfo($id: ID, $code: String, $projectId: ID!) {
  node(id: $id, code: $code) {
    ... on SharedCard {
      id
      code
      title
      childrenCounts(projectId: $projectId)
      type
      fileType
    }
  }
}
    `;
export type GetInspirationPackageInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables>, 'query'> & ({ variables: GetInspirationPackageInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInspirationPackageInfoComponent = (props: GetInspirationPackageInfoComponentProps) => (
      <ApolloReactComponents.Query<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables> query={GetInspirationPackageInfoDocument} {...props} />
    );
    
export type GetInspirationPackageInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables> & TChildProps;
export function withGetInspirationPackageInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspirationPackageInfoQuery,
  GetInspirationPackageInfoQueryVariables,
  GetInspirationPackageInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables, GetInspirationPackageInfoProps<TChildProps>>(GetInspirationPackageInfoDocument, {
      alias: 'getInspirationPackageInfo',
      ...operationOptions
    });
};

/**
 * __useGetInspirationPackageInfoQuery__
 *
 * To run a query within a React component, call `useGetInspirationPackageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationPackageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationPackageInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetInspirationPackageInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables>(GetInspirationPackageInfoDocument, baseOptions);
      }
export function useGetInspirationPackageInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables>(GetInspirationPackageInfoDocument, baseOptions);
        }
export type GetInspirationPackageInfoQueryHookResult = ReturnType<typeof useGetInspirationPackageInfoQuery>;
export type GetInspirationPackageInfoLazyQueryHookResult = ReturnType<typeof useGetInspirationPackageInfoLazyQuery>;
export type GetInspirationPackageInfoQueryResult = ApolloReactCommon.QueryResult<GetInspirationPackageInfoQuery, GetInspirationPackageInfoQueryVariables>;
export const VerifyInvitationCodeDocument = gql`
    mutation VerifyInvitationCode($invitationCode: String) {
  verifyInvitationCode(invitationCode: $invitationCode) {
    messages {
      code
      field
      message
    }
    successful
    result
  }
}
    `;
export type VerifyInvitationCodeMutationFn = ApolloReactCommon.MutationFunction<VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables>;
export type VerifyInvitationCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables>, 'mutation'>;

    export const VerifyInvitationCodeComponent = (props: VerifyInvitationCodeComponentProps) => (
      <ApolloReactComponents.Mutation<VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables> mutation={VerifyInvitationCodeDocument} {...props} />
    );
    
export type VerifyInvitationCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables> & TChildProps;
export function withVerifyInvitationCode<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VerifyInvitationCodeMutation,
  VerifyInvitationCodeMutationVariables,
  VerifyInvitationCodeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables, VerifyInvitationCodeProps<TChildProps>>(VerifyInvitationCodeDocument, {
      alias: 'verifyInvitationCode',
      ...operationOptions
    });
};

/**
 * __useVerifyInvitationCodeMutation__
 *
 * To run a mutation, you first call `useVerifyInvitationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyInvitationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyInvitationCodeMutation, { data, loading, error }] = useVerifyInvitationCodeMutation({
 *   variables: {
 *      invitationCode: // value for 'invitationCode'
 *   },
 * });
 */
export function useVerifyInvitationCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables>(VerifyInvitationCodeDocument, baseOptions);
      }
export type VerifyInvitationCodeMutationHookResult = ReturnType<typeof useVerifyInvitationCodeMutation>;
export type VerifyInvitationCodeMutationResult = ApolloReactCommon.MutationResult<VerifyInvitationCodeMutation>;
export type VerifyInvitationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyInvitationCodeMutation, VerifyInvitationCodeMutationVariables>;
export const SendVerificationCodeDocument = gql`
    mutation SendVerificationCode($phone: String) {
  sendVerificationCode(phone: $phone) {
    messages {
      code
      field
      message
    }
    successful
    result
  }
}
    `;
export type SendVerificationCodeMutationFn = ApolloReactCommon.MutationFunction<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>;
export type SendVerificationCodeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>, 'mutation'>;

    export const SendVerificationCodeComponent = (props: SendVerificationCodeComponentProps) => (
      <ApolloReactComponents.Mutation<SendVerificationCodeMutation, SendVerificationCodeMutationVariables> mutation={SendVerificationCodeDocument} {...props} />
    );
    
export type SendVerificationCodeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SendVerificationCodeMutation, SendVerificationCodeMutationVariables> & TChildProps;
export function withSendVerificationCode<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendVerificationCodeMutation,
  SendVerificationCodeMutationVariables,
  SendVerificationCodeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SendVerificationCodeMutation, SendVerificationCodeMutationVariables, SendVerificationCodeProps<TChildProps>>(SendVerificationCodeDocument, {
      alias: 'sendVerificationCode',
      ...operationOptions
    });
};

/**
 * __useSendVerificationCodeMutation__
 *
 * To run a mutation, you first call `useSendVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationCodeMutation, { data, loading, error }] = useSendVerificationCodeMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useSendVerificationCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>(SendVerificationCodeDocument, baseOptions);
      }
export type SendVerificationCodeMutationHookResult = ReturnType<typeof useSendVerificationCodeMutation>;
export type SendVerificationCodeMutationResult = ApolloReactCommon.MutationResult<SendVerificationCodeMutation>;
export type SendVerificationCodeMutationOptions = ApolloReactCommon.BaseMutationOptions<SendVerificationCodeMutation, SendVerificationCodeMutationVariables>;
export const VerfiyWeixinDocument = gql`
    mutation VerfiyWeixin($code: String) {
  verifyWeixin(code: $code) {
    messages {
      code
      field
      message
    }
    successful
    result {
      headimgurl
      nickname
      openid
    }
  }
}
    `;
export type VerfiyWeixinMutationFn = ApolloReactCommon.MutationFunction<VerfiyWeixinMutation, VerfiyWeixinMutationVariables>;
export type VerfiyWeixinComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<VerfiyWeixinMutation, VerfiyWeixinMutationVariables>, 'mutation'>;

    export const VerfiyWeixinComponent = (props: VerfiyWeixinComponentProps) => (
      <ApolloReactComponents.Mutation<VerfiyWeixinMutation, VerfiyWeixinMutationVariables> mutation={VerfiyWeixinDocument} {...props} />
    );
    
export type VerfiyWeixinProps<TChildProps = {}> = ApolloReactHoc.MutateProps<VerfiyWeixinMutation, VerfiyWeixinMutationVariables> & TChildProps;
export function withVerfiyWeixin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VerfiyWeixinMutation,
  VerfiyWeixinMutationVariables,
  VerfiyWeixinProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, VerfiyWeixinMutation, VerfiyWeixinMutationVariables, VerfiyWeixinProps<TChildProps>>(VerfiyWeixinDocument, {
      alias: 'verfiyWeixin',
      ...operationOptions
    });
};

/**
 * __useVerfiyWeixinMutation__
 *
 * To run a mutation, you first call `useVerfiyWeixinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerfiyWeixinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verfiyWeixinMutation, { data, loading, error }] = useVerfiyWeixinMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useVerfiyWeixinMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerfiyWeixinMutation, VerfiyWeixinMutationVariables>) {
        return ApolloReactHooks.useMutation<VerfiyWeixinMutation, VerfiyWeixinMutationVariables>(VerfiyWeixinDocument, baseOptions);
      }
export type VerfiyWeixinMutationHookResult = ReturnType<typeof useVerfiyWeixinMutation>;
export type VerfiyWeixinMutationResult = ApolloReactCommon.MutationResult<VerfiyWeixinMutation>;
export type VerfiyWeixinMutationOptions = ApolloReactCommon.BaseMutationOptions<VerfiyWeixinMutation, VerfiyWeixinMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($input: RegisterInput!) {
  register(input: $input) {
    messages {
      code
      field
      message
    }
    successful
    result {
      avatar
      id
      nickname
      userAuths {
        id
        identifier
        identityType
      }
      role
      username
    }
  }
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export type RegisterComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RegisterMutation, RegisterMutationVariables>, 'mutation'>;

    export const RegisterComponent = (props: RegisterComponentProps) => (
      <ApolloReactComponents.Mutation<RegisterMutation, RegisterMutationVariables> mutation={RegisterDocument} {...props} />
    );
    
export type RegisterProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RegisterMutation, RegisterMutationVariables> & TChildProps;
export function withRegister<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegisterMutation,
  RegisterMutationVariables,
  RegisterProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RegisterMutation, RegisterMutationVariables, RegisterProps<TChildProps>>(RegisterDocument, {
      alias: 'register',
      ...operationOptions
    });
};

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput) {
  login(input: $input) {
    messages {
      code
      field
      message
    }
    successful
    result {
      token
      user {
        avatar
        id
        nickname
        userAuths {
          id
          identifier
          identityType
        }
        role
        username
      }
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;
export type LoginComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LoginMutation, LoginMutationVariables>, 'mutation'>;

    export const LoginComponent = (props: LoginComponentProps) => (
      <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables> mutation={LoginDocument} {...props} />
    );
    
export type LoginProps<TChildProps = {}> = ApolloReactHoc.MutateProps<LoginMutation, LoginMutationVariables> & TChildProps;
export function withLogin<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LoginMutation,
  LoginMutationVariables,
  LoginProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, LoginMutation, LoginMutationVariables, LoginProps<TChildProps>>(LoginDocument, {
      alias: 'login',
      ...operationOptions
    });
};

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetCorpusProjectDocument = gql`
    query GetCorpusProject($after: String, $first: Int, $before: String, $last: Int, $matching: String, $order: SortOrder!, $filter: ProjectFilter) {
  projects(after: $after, first: $first, before: $before, last: $last, matching: $matching, order: $order, filter: $filter) {
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        code
        title
        cardCounts
        type
        coverImageUrl
        from
        outline
      }
    }
  }
}
    `;
export type GetCorpusProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCorpusProjectQuery, GetCorpusProjectQueryVariables>, 'query'> & ({ variables: GetCorpusProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCorpusProjectComponent = (props: GetCorpusProjectComponentProps) => (
      <ApolloReactComponents.Query<GetCorpusProjectQuery, GetCorpusProjectQueryVariables> query={GetCorpusProjectDocument} {...props} />
    );
    
export type GetCorpusProjectProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCorpusProjectQuery, GetCorpusProjectQueryVariables> & TChildProps;
export function withGetCorpusProject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCorpusProjectQuery,
  GetCorpusProjectQueryVariables,
  GetCorpusProjectProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCorpusProjectQuery, GetCorpusProjectQueryVariables, GetCorpusProjectProps<TChildProps>>(GetCorpusProjectDocument, {
      alias: 'getCorpusProject',
      ...operationOptions
    });
};

/**
 * __useGetCorpusProjectQuery__
 *
 * To run a query within a React component, call `useGetCorpusProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorpusProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorpusProjectQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      matching: // value for 'matching'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCorpusProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCorpusProjectQuery, GetCorpusProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCorpusProjectQuery, GetCorpusProjectQueryVariables>(GetCorpusProjectDocument, baseOptions);
      }
export function useGetCorpusProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCorpusProjectQuery, GetCorpusProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCorpusProjectQuery, GetCorpusProjectQueryVariables>(GetCorpusProjectDocument, baseOptions);
        }
export type GetCorpusProjectQueryHookResult = ReturnType<typeof useGetCorpusProjectQuery>;
export type GetCorpusProjectLazyQueryHookResult = ReturnType<typeof useGetCorpusProjectLazyQuery>;
export type GetCorpusProjectQueryResult = ApolloReactCommon.QueryResult<GetCorpusProjectQuery, GetCorpusProjectQueryVariables>;
export const GetCorpusCardsDocument = gql`
    query GetCorpusCards($first: Int, $ids: [ID], $filter: CardFilter!, $order: SortOrder!, $matching: String, $projectId: ID) {
  cards(first: $first, ids: $ids, filter: $filter, order: $order, matching: $matching, projectId: $projectId) {
    count
    edges {
      cursor
      node {
        id
        code
        categories
        content
        coordinate
        insertedAt
        tags
        title
        type
        fileType
        metadata
      }
    }
  }
}
    `;
export type GetCorpusCardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCorpusCardsQuery, GetCorpusCardsQueryVariables>, 'query'> & ({ variables: GetCorpusCardsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCorpusCardsComponent = (props: GetCorpusCardsComponentProps) => (
      <ApolloReactComponents.Query<GetCorpusCardsQuery, GetCorpusCardsQueryVariables> query={GetCorpusCardsDocument} {...props} />
    );
    
export type GetCorpusCardsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCorpusCardsQuery, GetCorpusCardsQueryVariables> & TChildProps;
export function withGetCorpusCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCorpusCardsQuery,
  GetCorpusCardsQueryVariables,
  GetCorpusCardsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCorpusCardsQuery, GetCorpusCardsQueryVariables, GetCorpusCardsProps<TChildProps>>(GetCorpusCardsDocument, {
      alias: 'getCorpusCards',
      ...operationOptions
    });
};

/**
 * __useGetCorpusCardsQuery__
 *
 * To run a query within a React component, call `useGetCorpusCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCorpusCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCorpusCardsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      ids: // value for 'ids'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      matching: // value for 'matching'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCorpusCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCorpusCardsQuery, GetCorpusCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCorpusCardsQuery, GetCorpusCardsQueryVariables>(GetCorpusCardsDocument, baseOptions);
      }
export function useGetCorpusCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCorpusCardsQuery, GetCorpusCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCorpusCardsQuery, GetCorpusCardsQueryVariables>(GetCorpusCardsDocument, baseOptions);
        }
export type GetCorpusCardsQueryHookResult = ReturnType<typeof useGetCorpusCardsQuery>;
export type GetCorpusCardsLazyQueryHookResult = ReturnType<typeof useGetCorpusCardsLazyQuery>;
export type GetCorpusCardsQueryResult = ApolloReactCommon.QueryResult<GetCorpusCardsQuery, GetCorpusCardsQueryVariables>;
export const GetCardInfoDocument = gql`
    query GetCardInfo($first: Int, $ids: [ID], $order: SortOrder!, $filter: CardFilter!) {
  cards(first: $first, ids: $ids, filter: $filter, order: $order) {
    count
    edges {
      cursor
      node {
        id
        code
        categories
        content
        coordinate
        insertedAt
        tags
        title
        type
        fileType
        metadata
      }
    }
  }
}
    `;
export type GetCardInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCardInfoQuery, GetCardInfoQueryVariables>, 'query'> & ({ variables: GetCardInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCardInfoComponent = (props: GetCardInfoComponentProps) => (
      <ApolloReactComponents.Query<GetCardInfoQuery, GetCardInfoQueryVariables> query={GetCardInfoDocument} {...props} />
    );
    
export type GetCardInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCardInfoQuery, GetCardInfoQueryVariables> & TChildProps;
export function withGetCardInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCardInfoQuery,
  GetCardInfoQueryVariables,
  GetCardInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCardInfoQuery, GetCardInfoQueryVariables, GetCardInfoProps<TChildProps>>(GetCardInfoDocument, {
      alias: 'getCardInfo',
      ...operationOptions
    });
};

/**
 * __useGetCardInfoQuery__
 *
 * To run a query within a React component, call `useGetCardInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardInfoQuery({
 *   variables: {
 *      first: // value for 'first'
 *      ids: // value for 'ids'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCardInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCardInfoQuery, GetCardInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCardInfoQuery, GetCardInfoQueryVariables>(GetCardInfoDocument, baseOptions);
      }
export function useGetCardInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCardInfoQuery, GetCardInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCardInfoQuery, GetCardInfoQueryVariables>(GetCardInfoDocument, baseOptions);
        }
export type GetCardInfoQueryHookResult = ReturnType<typeof useGetCardInfoQuery>;
export type GetCardInfoLazyQueryHookResult = ReturnType<typeof useGetCardInfoLazyQuery>;
export type GetCardInfoQueryResult = ApolloReactCommon.QueryResult<GetCardInfoQuery, GetCardInfoQueryVariables>;
export const GetDeleteProjectListDocument = gql`
    query GetDeleteProjectList($after: String, $first: Int, $matching: String, $order: SortOrder!, $filter: ProjectFilter) {
  projects(after: $after, first: $first, matching: $matching, order: $order, filter: $filter) {
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        code
        title
        insertedAt
        lastSeenAt
        coverImageUrl
      }
    }
  }
}
    `;
export type GetDeleteProjectListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables>, 'query'> & ({ variables: GetDeleteProjectListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetDeleteProjectListComponent = (props: GetDeleteProjectListComponentProps) => (
      <ApolloReactComponents.Query<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables> query={GetDeleteProjectListDocument} {...props} />
    );
    
export type GetDeleteProjectListProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables> & TChildProps;
export function withGetDeleteProjectList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetDeleteProjectListQuery,
  GetDeleteProjectListQueryVariables,
  GetDeleteProjectListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables, GetDeleteProjectListProps<TChildProps>>(GetDeleteProjectListDocument, {
      alias: 'getDeleteProjectList',
      ...operationOptions
    });
};

/**
 * __useGetDeleteProjectListQuery__
 *
 * To run a query within a React component, call `useGetDeleteProjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeleteProjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeleteProjectListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      matching: // value for 'matching'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetDeleteProjectListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables>(GetDeleteProjectListDocument, baseOptions);
      }
export function useGetDeleteProjectListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables>(GetDeleteProjectListDocument, baseOptions);
        }
export type GetDeleteProjectListQueryHookResult = ReturnType<typeof useGetDeleteProjectListQuery>;
export type GetDeleteProjectListLazyQueryHookResult = ReturnType<typeof useGetDeleteProjectListLazyQuery>;
export type GetDeleteProjectListQueryResult = ApolloReactCommon.QueryResult<GetDeleteProjectListQuery, GetDeleteProjectListQueryVariables>;
export const GetProjectListDocument = gql`
    query GetProjectList($after: String, $first: Int, $before: String, $last: Int, $matching: String, $order: SortOrder!, $filter: ProjectFilter) {
  projects(after: $after, first: $first, before: $before, last: $last, matching: $matching, order: $order, filter: $filter) {
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        code
        title
        insertedAt
        lastSeenAt
        cardCounts
        type
        coverImageUrl
        outline
        outlineState
      }
    }
  }
}
    `;
export type GetProjectListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectListQuery, GetProjectListQueryVariables>, 'query'> & ({ variables: GetProjectListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetProjectListComponent = (props: GetProjectListComponentProps) => (
      <ApolloReactComponents.Query<GetProjectListQuery, GetProjectListQueryVariables> query={GetProjectListDocument} {...props} />
    );
    
export type GetProjectListProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetProjectListQuery, GetProjectListQueryVariables> & TChildProps;
export function withGetProjectList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectListQuery,
  GetProjectListQueryVariables,
  GetProjectListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectListQuery, GetProjectListQueryVariables, GetProjectListProps<TChildProps>>(GetProjectListDocument, {
      alias: 'getProjectList',
      ...operationOptions
    });
};

/**
 * __useGetProjectListQuery__
 *
 * To run a query within a React component, call `useGetProjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      matching: // value for 'matching'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetProjectListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectListQuery, GetProjectListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectListQuery, GetProjectListQueryVariables>(GetProjectListDocument, baseOptions);
      }
export function useGetProjectListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectListQuery, GetProjectListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectListQuery, GetProjectListQueryVariables>(GetProjectListDocument, baseOptions);
        }
export type GetProjectListQueryHookResult = ReturnType<typeof useGetProjectListQuery>;
export type GetProjectListLazyQueryHookResult = ReturnType<typeof useGetProjectListLazyQuery>;
export type GetProjectListQueryResult = ApolloReactCommon.QueryResult<GetProjectListQuery, GetProjectListQueryVariables>;
export const GetCardListDocument = gql`
    query GetCardList($after: String, $first: Int, $before: String, $last: Int, $matching: String, $order: SortOrder!, $filter: CardFilter!) {
  cards(after: $after, first: $first, before: $before, last: $last, matching: $matching, order: $order, filter: $filter) {
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        code
        categories
        content
        coordinate
        id
        insertedAt
        tags
        title
        type
        updatedAt
      }
    }
  }
}
    `;
export type GetCardListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCardListQuery, GetCardListQueryVariables>, 'query'> & ({ variables: GetCardListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCardListComponent = (props: GetCardListComponentProps) => (
      <ApolloReactComponents.Query<GetCardListQuery, GetCardListQueryVariables> query={GetCardListDocument} {...props} />
    );
    
export type GetCardListProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCardListQuery, GetCardListQueryVariables> & TChildProps;
export function withGetCardList<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCardListQuery,
  GetCardListQueryVariables,
  GetCardListProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCardListQuery, GetCardListQueryVariables, GetCardListProps<TChildProps>>(GetCardListDocument, {
      alias: 'getCardList',
      ...operationOptions
    });
};

/**
 * __useGetCardListQuery__
 *
 * To run a query within a React component, call `useGetCardListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      matching: // value for 'matching'
 *      order: // value for 'order'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCardListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCardListQuery, GetCardListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCardListQuery, GetCardListQueryVariables>(GetCardListDocument, baseOptions);
      }
export function useGetCardListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCardListQuery, GetCardListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCardListQuery, GetCardListQueryVariables>(GetCardListDocument, baseOptions);
        }
export type GetCardListQueryHookResult = ReturnType<typeof useGetCardListQuery>;
export type GetCardListLazyQueryHookResult = ReturnType<typeof useGetCardListLazyQuery>;
export type GetCardListQueryResult = ApolloReactCommon.QueryResult<GetCardListQuery, GetCardListQueryVariables>;
export const GetCardsStatsDocument = gql`
    query GetCardsStats($types: [CardType]!) {
  cardsStats(types: $types) {
    count
    month
    year
  }
}
    `;
export type GetCardsStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCardsStatsQuery, GetCardsStatsQueryVariables>, 'query'> & ({ variables: GetCardsStatsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCardsStatsComponent = (props: GetCardsStatsComponentProps) => (
      <ApolloReactComponents.Query<GetCardsStatsQuery, GetCardsStatsQueryVariables> query={GetCardsStatsDocument} {...props} />
    );
    
export type GetCardsStatsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCardsStatsQuery, GetCardsStatsQueryVariables> & TChildProps;
export function withGetCardsStats<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCardsStatsQuery,
  GetCardsStatsQueryVariables,
  GetCardsStatsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCardsStatsQuery, GetCardsStatsQueryVariables, GetCardsStatsProps<TChildProps>>(GetCardsStatsDocument, {
      alias: 'getCardsStats',
      ...operationOptions
    });
};

/**
 * __useGetCardsStatsQuery__
 *
 * To run a query within a React component, call `useGetCardsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsStatsQuery({
 *   variables: {
 *      types: // value for 'types'
 *   },
 * });
 */
export function useGetCardsStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCardsStatsQuery, GetCardsStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCardsStatsQuery, GetCardsStatsQueryVariables>(GetCardsStatsDocument, baseOptions);
      }
export function useGetCardsStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCardsStatsQuery, GetCardsStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCardsStatsQuery, GetCardsStatsQueryVariables>(GetCardsStatsDocument, baseOptions);
        }
export type GetCardsStatsQueryHookResult = ReturnType<typeof useGetCardsStatsQuery>;
export type GetCardsStatsLazyQueryHookResult = ReturnType<typeof useGetCardsStatsLazyQuery>;
export type GetCardsStatsQueryResult = ApolloReactCommon.QueryResult<GetCardsStatsQuery, GetCardsStatsQueryVariables>;
export const SetOpenProjectDateDocument = gql`
    mutation SetOpenProjectDate($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    successful
    messages {
      code
      field
      message
    }
  }
}
    `;
export type SetOpenProjectDateMutationFn = ApolloReactCommon.MutationFunction<SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables>;
export type SetOpenProjectDateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables>, 'mutation'>;

    export const SetOpenProjectDateComponent = (props: SetOpenProjectDateComponentProps) => (
      <ApolloReactComponents.Mutation<SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables> mutation={SetOpenProjectDateDocument} {...props} />
    );
    
export type SetOpenProjectDateProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables> & TChildProps;
export function withSetOpenProjectDate<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetOpenProjectDateMutation,
  SetOpenProjectDateMutationVariables,
  SetOpenProjectDateProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables, SetOpenProjectDateProps<TChildProps>>(SetOpenProjectDateDocument, {
      alias: 'setOpenProjectDate',
      ...operationOptions
    });
};

/**
 * __useSetOpenProjectDateMutation__
 *
 * To run a mutation, you first call `useSetOpenProjectDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOpenProjectDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOpenProjectDateMutation, { data, loading, error }] = useSetOpenProjectDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetOpenProjectDateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables>) {
        return ApolloReactHooks.useMutation<SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables>(SetOpenProjectDateDocument, baseOptions);
      }
export type SetOpenProjectDateMutationHookResult = ReturnType<typeof useSetOpenProjectDateMutation>;
export type SetOpenProjectDateMutationResult = ApolloReactCommon.MutationResult<SetOpenProjectDateMutation>;
export type SetOpenProjectDateMutationOptions = ApolloReactCommon.BaseMutationOptions<SetOpenProjectDateMutation, SetOpenProjectDateMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation DeleteProject($input: DeleteProjectInput!) {
  securelyDeleteProject(input: $input) {
    successful
    messages {
      code
      field
      message
    }
  }
}
    `;
export type DeleteProjectMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMutation, DeleteProjectMutationVariables>;
export type DeleteProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectMutation, DeleteProjectMutationVariables>, 'mutation'>;

    export const DeleteProjectComponent = (props: DeleteProjectComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectMutation, DeleteProjectMutationVariables> mutation={DeleteProjectDocument} {...props} />
    );
    
export type DeleteProjectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteProjectMutation, DeleteProjectMutationVariables> & TChildProps;
export function withDeleteProject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteProjectMutation,
  DeleteProjectMutationVariables,
  DeleteProjectProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteProjectMutation, DeleteProjectMutationVariables, DeleteProjectProps<TChildProps>>(DeleteProjectDocument, {
      alias: 'deleteProject',
      ...operationOptions
    });
};

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;
export const GetMyInfoDocument = gql`
    query GetMyInfo {
  me {
    role
    userProfile {
      id
      vipLevel
      vipExpiredAt
    }
  }
}
    `;
export type GetMyInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMyInfoQuery, GetMyInfoQueryVariables>, 'query'>;

    export const GetMyInfoComponent = (props: GetMyInfoComponentProps) => (
      <ApolloReactComponents.Query<GetMyInfoQuery, GetMyInfoQueryVariables> query={GetMyInfoDocument} {...props} />
    );
    
export type GetMyInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetMyInfoQuery, GetMyInfoQueryVariables> & TChildProps;
export function withGetMyInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetMyInfoQuery,
  GetMyInfoQueryVariables,
  GetMyInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetMyInfoQuery, GetMyInfoQueryVariables, GetMyInfoProps<TChildProps>>(GetMyInfoDocument, {
      alias: 'getMyInfo',
      ...operationOptions
    });
};

/**
 * __useGetMyInfoQuery__
 *
 * To run a query within a React component, call `useGetMyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyInfoQuery, GetMyInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyInfoQuery, GetMyInfoQueryVariables>(GetMyInfoDocument, baseOptions);
      }
export function useGetMyInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyInfoQuery, GetMyInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyInfoQuery, GetMyInfoQueryVariables>(GetMyInfoDocument, baseOptions);
        }
export type GetMyInfoQueryHookResult = ReturnType<typeof useGetMyInfoQuery>;
export type GetMyInfoLazyQueryHookResult = ReturnType<typeof useGetMyInfoLazyQuery>;
export type GetMyInfoQueryResult = ApolloReactCommon.QueryResult<GetMyInfoQuery, GetMyInfoQueryVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($input: CreateProjectInput!) {
  createProject(input: $input) {
    messages {
      code
      field
      message
    }
    result {
      id
      title
      insertedAt
      outline
      code
    }
    successful
  }
}
    `;
export type CreateProjectMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;
export type CreateProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProjectMutation, CreateProjectMutationVariables>, 'mutation'>;

    export const CreateProjectComponent = (props: CreateProjectComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProjectMutation, CreateProjectMutationVariables> mutation={CreateProjectDocument} {...props} />
    );
    
export type CreateProjectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateProjectMutation, CreateProjectMutationVariables> & TChildProps;
export function withCreateProject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateProjectMutation,
  CreateProjectMutationVariables,
  CreateProjectProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateProjectMutation, CreateProjectMutationVariables, CreateProjectProps<TChildProps>>(CreateProjectDocument, {
      alias: 'createProject',
      ...operationOptions
    });
};

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const CreateProjectByThemeDocument = gql`
    mutation CreateProjectByTheme($input: CreateProjectByThemeInput!) {
  createProjectByTheme(input: $input) {
    messages {
      code
      field
      message
    }
    result {
      id
      title
      insertedAt
      outline
      code
    }
    successful
  }
}
    `;
export type CreateProjectByThemeMutationFn = ApolloReactCommon.MutationFunction<CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables>;
export type CreateProjectByThemeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables>, 'mutation'>;

    export const CreateProjectByThemeComponent = (props: CreateProjectByThemeComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables> mutation={CreateProjectByThemeDocument} {...props} />
    );
    
export type CreateProjectByThemeProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables> & TChildProps;
export function withCreateProjectByTheme<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateProjectByThemeMutation,
  CreateProjectByThemeMutationVariables,
  CreateProjectByThemeProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables, CreateProjectByThemeProps<TChildProps>>(CreateProjectByThemeDocument, {
      alias: 'createProjectByTheme',
      ...operationOptions
    });
};

/**
 * __useCreateProjectByThemeMutation__
 *
 * To run a mutation, you first call `useCreateProjectByThemeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectByThemeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectByThemeMutation, { data, loading, error }] = useCreateProjectByThemeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectByThemeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables>(CreateProjectByThemeDocument, baseOptions);
      }
export type CreateProjectByThemeMutationHookResult = ReturnType<typeof useCreateProjectByThemeMutation>;
export type CreateProjectByThemeMutationResult = ApolloReactCommon.MutationResult<CreateProjectByThemeMutation>;
export type CreateProjectByThemeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectByThemeMutation, CreateProjectByThemeMutationVariables>;
export const GetThemesDocument = gql`
    query GetThemes($after: String, $first: Int, $before: String, $last: Int, $filter: ThemeFilter!, $matching: String) {
  themes(after: $after, first: $first, before: $before, last: $last, filter: $filter, matching: $matching) {
    count
    edges {
      node {
        id
        title
        coverImageUrl
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type GetThemesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetThemesQuery, GetThemesQueryVariables>, 'query'> & ({ variables: GetThemesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetThemesComponent = (props: GetThemesComponentProps) => (
      <ApolloReactComponents.Query<GetThemesQuery, GetThemesQueryVariables> query={GetThemesDocument} {...props} />
    );
    
export type GetThemesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetThemesQuery, GetThemesQueryVariables> & TChildProps;
export function withGetThemes<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetThemesQuery,
  GetThemesQueryVariables,
  GetThemesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetThemesQuery, GetThemesQueryVariables, GetThemesProps<TChildProps>>(GetThemesDocument, {
      alias: 'getThemes',
      ...operationOptions
    });
};

/**
 * __useGetThemesQuery__
 *
 * To run a query within a React component, call `useGetThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThemesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      matching: // value for 'matching'
 *   },
 * });
 */
export function useGetThemesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, baseOptions);
      }
export function useGetThemesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetThemesQuery, GetThemesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetThemesQuery, GetThemesQueryVariables>(GetThemesDocument, baseOptions);
        }
export type GetThemesQueryHookResult = ReturnType<typeof useGetThemesQuery>;
export type GetThemesLazyQueryHookResult = ReturnType<typeof useGetThemesLazyQuery>;
export type GetThemesQueryResult = ApolloReactCommon.QueryResult<GetThemesQuery, GetThemesQueryVariables>;
export const GetStatisticDataDocument = gql`
    query GetStatisticData($ids: [ID!]!) {
  calculateIndex(ids: $ids) {
    indexMap
  }
}
    `;
export type GetStatisticDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetStatisticDataQuery, GetStatisticDataQueryVariables>, 'query'> & ({ variables: GetStatisticDataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetStatisticDataComponent = (props: GetStatisticDataComponentProps) => (
      <ApolloReactComponents.Query<GetStatisticDataQuery, GetStatisticDataQueryVariables> query={GetStatisticDataDocument} {...props} />
    );
    
export type GetStatisticDataProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetStatisticDataQuery, GetStatisticDataQueryVariables> & TChildProps;
export function withGetStatisticData<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetStatisticDataQuery,
  GetStatisticDataQueryVariables,
  GetStatisticDataProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetStatisticDataQuery, GetStatisticDataQueryVariables, GetStatisticDataProps<TChildProps>>(GetStatisticDataDocument, {
      alias: 'getStatisticData',
      ...operationOptions
    });
};

/**
 * __useGetStatisticDataQuery__
 *
 * To run a query within a React component, call `useGetStatisticDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatisticDataQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatisticDataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetStatisticDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStatisticDataQuery, GetStatisticDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStatisticDataQuery, GetStatisticDataQueryVariables>(GetStatisticDataDocument, baseOptions);
      }
export function useGetStatisticDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStatisticDataQuery, GetStatisticDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStatisticDataQuery, GetStatisticDataQueryVariables>(GetStatisticDataDocument, baseOptions);
        }
export type GetStatisticDataQueryHookResult = ReturnType<typeof useGetStatisticDataQuery>;
export type GetStatisticDataLazyQueryHookResult = ReturnType<typeof useGetStatisticDataLazyQuery>;
export type GetStatisticDataQueryResult = ApolloReactCommon.QueryResult<GetStatisticDataQuery, GetStatisticDataQueryVariables>;
export const GetExtractKeyWordsDocument = gql`
    query GetExtractKeyWords($ids: [ID!]!) {
  extractKeywords(ids: $ids) {
    keywords {
      weight
      word
    }
  }
}
    `;
export type GetExtractKeyWordsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables>, 'query'> & ({ variables: GetExtractKeyWordsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetExtractKeyWordsComponent = (props: GetExtractKeyWordsComponentProps) => (
      <ApolloReactComponents.Query<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables> query={GetExtractKeyWordsDocument} {...props} />
    );
    
export type GetExtractKeyWordsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables> & TChildProps;
export function withGetExtractKeyWords<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetExtractKeyWordsQuery,
  GetExtractKeyWordsQueryVariables,
  GetExtractKeyWordsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables, GetExtractKeyWordsProps<TChildProps>>(GetExtractKeyWordsDocument, {
      alias: 'getExtractKeyWords',
      ...operationOptions
    });
};

/**
 * __useGetExtractKeyWordsQuery__
 *
 * To run a query within a React component, call `useGetExtractKeyWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExtractKeyWordsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExtractKeyWordsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetExtractKeyWordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables>(GetExtractKeyWordsDocument, baseOptions);
      }
export function useGetExtractKeyWordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables>(GetExtractKeyWordsDocument, baseOptions);
        }
export type GetExtractKeyWordsQueryHookResult = ReturnType<typeof useGetExtractKeyWordsQuery>;
export type GetExtractKeyWordsLazyQueryHookResult = ReturnType<typeof useGetExtractKeyWordsLazyQuery>;
export type GetExtractKeyWordsQueryResult = ApolloReactCommon.QueryResult<GetExtractKeyWordsQuery, GetExtractKeyWordsQueryVariables>;
export const GetInspirationSearchCardsDocument = gql`
    query GetInspirationSearchCards($after: String, $first: Int, $extractedKeywords: [String], $matching: String, $filter: SharedCardFilter!) {
  recommendations(after: $after, first: $first, extractedKeywords: $extractedKeywords, matching: $matching, filter: $filter) {
    count
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        id
        code
        title
        content
      }
    }
  }
}
    `;
export type GetInspirationSearchCardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables>, 'query'> & ({ variables: GetInspirationSearchCardsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetInspirationSearchCardsComponent = (props: GetInspirationSearchCardsComponentProps) => (
      <ApolloReactComponents.Query<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables> query={GetInspirationSearchCardsDocument} {...props} />
    );
    
export type GetInspirationSearchCardsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables> & TChildProps;
export function withGetInspirationSearchCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetInspirationSearchCardsQuery,
  GetInspirationSearchCardsQueryVariables,
  GetInspirationSearchCardsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables, GetInspirationSearchCardsProps<TChildProps>>(GetInspirationSearchCardsDocument, {
      alias: 'getInspirationSearchCards',
      ...operationOptions
    });
};

/**
 * __useGetInspirationSearchCardsQuery__
 *
 * To run a query within a React component, call `useGetInspirationSearchCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInspirationSearchCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInspirationSearchCardsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      extractedKeywords: // value for 'extractedKeywords'
 *      matching: // value for 'matching'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInspirationSearchCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables>(GetInspirationSearchCardsDocument, baseOptions);
      }
export function useGetInspirationSearchCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables>(GetInspirationSearchCardsDocument, baseOptions);
        }
export type GetInspirationSearchCardsQueryHookResult = ReturnType<typeof useGetInspirationSearchCardsQuery>;
export type GetInspirationSearchCardsLazyQueryHookResult = ReturnType<typeof useGetInspirationSearchCardsLazyQuery>;
export type GetInspirationSearchCardsQueryResult = ApolloReactCommon.QueryResult<GetInspirationSearchCardsQuery, GetInspirationSearchCardsQueryVariables>;
export const GetProjectDetailInfoDocument = gql`
    query GetProjectDetailInfo($id: ID, $code: String) {
  node(id: $id, code: $code) {
    ... on Project {
      id
      code
      title
      outline
      outlineState
      insertedAt
      type
      updatedAt
    }
  }
}
    `;
export type GetProjectDetailInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables>, 'query'>;

    export const GetProjectDetailInfoComponent = (props: GetProjectDetailInfoComponentProps) => (
      <ApolloReactComponents.Query<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables> query={GetProjectDetailInfoDocument} {...props} />
    );
    
export type GetProjectDetailInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables> & TChildProps;
export function withGetProjectDetailInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectDetailInfoQuery,
  GetProjectDetailInfoQueryVariables,
  GetProjectDetailInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables, GetProjectDetailInfoProps<TChildProps>>(GetProjectDetailInfoDocument, {
      alias: 'getProjectDetailInfo',
      ...operationOptions
    });
};

/**
 * __useGetProjectDetailInfoQuery__
 *
 * To run a query within a React component, call `useGetProjectDetailInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDetailInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDetailInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetProjectDetailInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables>(GetProjectDetailInfoDocument, baseOptions);
      }
export function useGetProjectDetailInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables>(GetProjectDetailInfoDocument, baseOptions);
        }
export type GetProjectDetailInfoQueryHookResult = ReturnType<typeof useGetProjectDetailInfoQuery>;
export type GetProjectDetailInfoLazyQueryHookResult = ReturnType<typeof useGetProjectDetailInfoLazyQuery>;
export type GetProjectDetailInfoQueryResult = ApolloReactCommon.QueryResult<GetProjectDetailInfoQuery, GetProjectDetailInfoQueryVariables>;
export const GetSingleCardDocument = gql`
    query GetSingleCard($id: ID, $code: String) {
  node(id: $id, code: $code) {
    ... on Card {
      id
      code
      categories
      content
      coordinate
      insertedAt
      tags
      title
      type
      fileType
      updatedAt
    }
  }
}
    `;
export type GetSingleCardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSingleCardQuery, GetSingleCardQueryVariables>, 'query'>;

    export const GetSingleCardComponent = (props: GetSingleCardComponentProps) => (
      <ApolloReactComponents.Query<GetSingleCardQuery, GetSingleCardQueryVariables> query={GetSingleCardDocument} {...props} />
    );
    
export type GetSingleCardProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetSingleCardQuery, GetSingleCardQueryVariables> & TChildProps;
export function withGetSingleCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetSingleCardQuery,
  GetSingleCardQueryVariables,
  GetSingleCardProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetSingleCardQuery, GetSingleCardQueryVariables, GetSingleCardProps<TChildProps>>(GetSingleCardDocument, {
      alias: 'getSingleCard',
      ...operationOptions
    });
};

/**
 * __useGetSingleCardQuery__
 *
 * To run a query within a React component, call `useGetSingleCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSingleCardQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSingleCardQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetSingleCardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSingleCardQuery, GetSingleCardQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSingleCardQuery, GetSingleCardQueryVariables>(GetSingleCardDocument, baseOptions);
      }
export function useGetSingleCardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSingleCardQuery, GetSingleCardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSingleCardQuery, GetSingleCardQueryVariables>(GetSingleCardDocument, baseOptions);
        }
export type GetSingleCardQueryHookResult = ReturnType<typeof useGetSingleCardQuery>;
export type GetSingleCardLazyQueryHookResult = ReturnType<typeof useGetSingleCardLazyQuery>;
export type GetSingleCardQueryResult = ApolloReactCommon.QueryResult<GetSingleCardQuery, GetSingleCardQueryVariables>;
export const UpdateProjectDocument = gql`
    mutation UpdateProject($input: UpdateProjectInput!) {
  updateProject(input: $input) {
    messages {
      code
      field
      message
    }
    result {
      id
      title
      insertedAt
      outline
      outlineState
    }
    successful
  }
}
    `;
export type UpdateProjectMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectMutation, UpdateProjectMutationVariables>;
export type UpdateProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectMutation, UpdateProjectMutationVariables>, 'mutation'>;

    export const UpdateProjectComponent = (props: UpdateProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> mutation={UpdateProjectDocument} {...props} />
    );
    
export type UpdateProjectProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateProjectMutation, UpdateProjectMutationVariables> & TChildProps;
export function withUpdateProject<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProjectMutation,
  UpdateProjectMutationVariables,
  UpdateProjectProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProjectMutation, UpdateProjectMutationVariables, UpdateProjectProps<TChildProps>>(UpdateProjectDocument, {
      alias: 'updateProject',
      ...operationOptions
    });
};

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const CreateCardsDocument = gql`
    mutation CreateCards($input: CreateCardsInput!) {
  createCards(input: $input) {
    successful
    result
    messages {
      code
      field
      message
    }
  }
}
    `;
export type CreateCardsMutationFn = ApolloReactCommon.MutationFunction<CreateCardsMutation, CreateCardsMutationVariables>;
export type CreateCardsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCardsMutation, CreateCardsMutationVariables>, 'mutation'>;

    export const CreateCardsComponent = (props: CreateCardsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCardsMutation, CreateCardsMutationVariables> mutation={CreateCardsDocument} {...props} />
    );
    
export type CreateCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CreateCardsMutation, CreateCardsMutationVariables> & TChildProps;
export function withCreateCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCardsMutation,
  CreateCardsMutationVariables,
  CreateCardsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCardsMutation, CreateCardsMutationVariables, CreateCardsProps<TChildProps>>(CreateCardsDocument, {
      alias: 'createCards',
      ...operationOptions
    });
};

/**
 * __useCreateCardsMutation__
 *
 * To run a mutation, you first call `useCreateCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardsMutation, { data, loading, error }] = useCreateCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCardsMutation, CreateCardsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCardsMutation, CreateCardsMutationVariables>(CreateCardsDocument, baseOptions);
      }
export type CreateCardsMutationHookResult = ReturnType<typeof useCreateCardsMutation>;
export type CreateCardsMutationResult = ApolloReactCommon.MutationResult<CreateCardsMutation>;
export type CreateCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCardsMutation, CreateCardsMutationVariables>;
export const UpdateCardsContentDocument = gql`
    mutation UpdateCardsContent($input: UpdateCardsContentInput!) {
  updateCardsContent(input: $input) {
    successful
    result
    messages {
      code
      field
      message
    }
  }
}
    `;
export type UpdateCardsContentMutationFn = ApolloReactCommon.MutationFunction<UpdateCardsContentMutation, UpdateCardsContentMutationVariables>;
export type UpdateCardsContentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCardsContentMutation, UpdateCardsContentMutationVariables>, 'mutation'>;

    export const UpdateCardsContentComponent = (props: UpdateCardsContentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCardsContentMutation, UpdateCardsContentMutationVariables> mutation={UpdateCardsContentDocument} {...props} />
    );
    
export type UpdateCardsContentProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCardsContentMutation, UpdateCardsContentMutationVariables> & TChildProps;
export function withUpdateCardsContent<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCardsContentMutation,
  UpdateCardsContentMutationVariables,
  UpdateCardsContentProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCardsContentMutation, UpdateCardsContentMutationVariables, UpdateCardsContentProps<TChildProps>>(UpdateCardsContentDocument, {
      alias: 'updateCardsContent',
      ...operationOptions
    });
};

/**
 * __useUpdateCardsContentMutation__
 *
 * To run a mutation, you first call `useUpdateCardsContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardsContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardsContentMutation, { data, loading, error }] = useUpdateCardsContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardsContentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCardsContentMutation, UpdateCardsContentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCardsContentMutation, UpdateCardsContentMutationVariables>(UpdateCardsContentDocument, baseOptions);
      }
export type UpdateCardsContentMutationHookResult = ReturnType<typeof useUpdateCardsContentMutation>;
export type UpdateCardsContentMutationResult = ApolloReactCommon.MutationResult<UpdateCardsContentMutation>;
export type UpdateCardsContentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCardsContentMutation, UpdateCardsContentMutationVariables>;
export const UpdateCardDocument = gql`
    mutation UpdateCard($input: UpdateCardInput!) {
  updateCard(input: $input) {
    messages {
      code
      field
      message
    }
    result {
      coordinate
      id
      title
      content
      tags
    }
    successful
  }
}
    `;
export type UpdateCardMutationFn = ApolloReactCommon.MutationFunction<UpdateCardMutation, UpdateCardMutationVariables>;
export type UpdateCardComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCardMutation, UpdateCardMutationVariables>, 'mutation'>;

    export const UpdateCardComponent = (props: UpdateCardComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCardMutation, UpdateCardMutationVariables> mutation={UpdateCardDocument} {...props} />
    );
    
export type UpdateCardProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateCardMutation, UpdateCardMutationVariables> & TChildProps;
export function withUpdateCard<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCardMutation,
  UpdateCardMutationVariables,
  UpdateCardProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCardMutation, UpdateCardMutationVariables, UpdateCardProps<TChildProps>>(UpdateCardDocument, {
      alias: 'updateCard',
      ...operationOptions
    });
};

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCardMutation, UpdateCardMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(UpdateCardDocument, baseOptions);
      }
export type UpdateCardMutationHookResult = ReturnType<typeof useUpdateCardMutation>;
export type UpdateCardMutationResult = ApolloReactCommon.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCardMutation, UpdateCardMutationVariables>;
export const CheckDocument = gql`
    query Check($ids: [ID!]!) {
  check(ids: $ids) {
    mistakes {
      advice
      cardId
      category
      wrong
      end
      start
      sentence
    }
    unsuccessfulIds
  }
}
    `;
export type CheckComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckQuery, CheckQueryVariables>, 'query'> & ({ variables: CheckQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckComponent = (props: CheckComponentProps) => (
      <ApolloReactComponents.Query<CheckQuery, CheckQueryVariables> query={CheckDocument} {...props} />
    );
    
export type CheckProps<TChildProps = {}> = ApolloReactHoc.DataProps<CheckQuery, CheckQueryVariables> & TChildProps;
export function withCheck<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckQuery,
  CheckQueryVariables,
  CheckProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CheckQuery, CheckQueryVariables, CheckProps<TChildProps>>(CheckDocument, {
      alias: 'check',
      ...operationOptions
    });
};

/**
 * __useCheckQuery__
 *
 * To run a query within a React component, call `useCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCheckQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckQuery, CheckQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckQuery, CheckQueryVariables>(CheckDocument, baseOptions);
      }
export function useCheckLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckQuery, CheckQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckQuery, CheckQueryVariables>(CheckDocument, baseOptions);
        }
export type CheckQueryHookResult = ReturnType<typeof useCheckQuery>;
export type CheckLazyQueryHookResult = ReturnType<typeof useCheckLazyQuery>;
export type CheckQueryResult = ApolloReactCommon.QueryResult<CheckQuery, CheckQueryVariables>;
export const CheckTabooDocument = gql`
    query CheckTaboo($ids: [ID!]!) {
  checkTaboo(ids: $ids) {
    taboos {
      advice
      cardId
      category
      wrong
      end
      start
      sentence
    }
    unsuccessfulIds
  }
}
    `;
export type CheckTabooComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckTabooQuery, CheckTabooQueryVariables>, 'query'> & ({ variables: CheckTabooQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CheckTabooComponent = (props: CheckTabooComponentProps) => (
      <ApolloReactComponents.Query<CheckTabooQuery, CheckTabooQueryVariables> query={CheckTabooDocument} {...props} />
    );
    
export type CheckTabooProps<TChildProps = {}> = ApolloReactHoc.DataProps<CheckTabooQuery, CheckTabooQueryVariables> & TChildProps;
export function withCheckTaboo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckTabooQuery,
  CheckTabooQueryVariables,
  CheckTabooProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, CheckTabooQuery, CheckTabooQueryVariables, CheckTabooProps<TChildProps>>(CheckTabooDocument, {
      alias: 'checkTaboo',
      ...operationOptions
    });
};

/**
 * __useCheckTabooQuery__
 *
 * To run a query within a React component, call `useCheckTabooQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckTabooQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckTabooQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCheckTabooQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckTabooQuery, CheckTabooQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckTabooQuery, CheckTabooQueryVariables>(CheckTabooDocument, baseOptions);
      }
export function useCheckTabooLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckTabooQuery, CheckTabooQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckTabooQuery, CheckTabooQueryVariables>(CheckTabooDocument, baseOptions);
        }
export type CheckTabooQueryHookResult = ReturnType<typeof useCheckTabooQuery>;
export type CheckTabooLazyQueryHookResult = ReturnType<typeof useCheckTabooLazyQuery>;
export type CheckTabooQueryResult = ApolloReactCommon.QueryResult<CheckTabooQuery, CheckTabooQueryVariables>;
export const GetCardsDocument = gql`
    query GetCards($first: Int, $ids: [ID], $filter: CardFilter!) {
  cards(first: $first, ids: $ids, filter: $filter) {
    count
    edges {
      cursor
      node {
        id
        code
        categories
        content
        coordinate
        insertedAt
        tags
        title
        type
        fileType
        updatedAt
      }
    }
  }
}
    `;
export type GetCardsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCardsQuery, GetCardsQueryVariables>, 'query'> & ({ variables: GetCardsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCardsComponent = (props: GetCardsComponentProps) => (
      <ApolloReactComponents.Query<GetCardsQuery, GetCardsQueryVariables> query={GetCardsDocument} {...props} />
    );
    
export type GetCardsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCardsQuery, GetCardsQueryVariables> & TChildProps;
export function withGetCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCardsQuery,
  GetCardsQueryVariables,
  GetCardsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCardsQuery, GetCardsQueryVariables, GetCardsProps<TChildProps>>(GetCardsDocument, {
      alias: 'getCards',
      ...operationOptions
    });
};

/**
 * __useGetCardsQuery__
 *
 * To run a query within a React component, call `useGetCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      ids: // value for 'ids'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, baseOptions);
      }
export function useGetCardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCardsQuery, GetCardsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCardsQuery, GetCardsQueryVariables>(GetCardsDocument, baseOptions);
        }
export type GetCardsQueryHookResult = ReturnType<typeof useGetCardsQuery>;
export type GetCardsLazyQueryHookResult = ReturnType<typeof useGetCardsLazyQuery>;
export type GetCardsQueryResult = ApolloReactCommon.QueryResult<GetCardsQuery, GetCardsQueryVariables>;
export const CopyCardsDocument = gql`
    mutation CopyCards($input: CopyCardsInput!) {
  copyCards(input: $input) {
    successful
    result
    messages {
      code
      field
      message
    }
  }
}
    `;
export type CopyCardsMutationFn = ApolloReactCommon.MutationFunction<CopyCardsMutation, CopyCardsMutationVariables>;
export type CopyCardsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CopyCardsMutation, CopyCardsMutationVariables>, 'mutation'>;

    export const CopyCardsComponent = (props: CopyCardsComponentProps) => (
      <ApolloReactComponents.Mutation<CopyCardsMutation, CopyCardsMutationVariables> mutation={CopyCardsDocument} {...props} />
    );
    
export type CopyCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<CopyCardsMutation, CopyCardsMutationVariables> & TChildProps;
export function withCopyCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CopyCardsMutation,
  CopyCardsMutationVariables,
  CopyCardsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, CopyCardsMutation, CopyCardsMutationVariables, CopyCardsProps<TChildProps>>(CopyCardsDocument, {
      alias: 'copyCards',
      ...operationOptions
    });
};

/**
 * __useCopyCardsMutation__
 *
 * To run a mutation, you first call `useCopyCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCardsMutation, { data, loading, error }] = useCopyCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyCardsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyCardsMutation, CopyCardsMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyCardsMutation, CopyCardsMutationVariables>(CopyCardsDocument, baseOptions);
      }
export type CopyCardsMutationHookResult = ReturnType<typeof useCopyCardsMutation>;
export type CopyCardsMutationResult = ApolloReactCommon.MutationResult<CopyCardsMutation>;
export type CopyCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyCardsMutation, CopyCardsMutationVariables>;
export const DeleteCardsDocument = gql`
    mutation DeleteCards($input: DeleteCardsInput!) {
  deleteCards(input: $input) {
    successful
    result
    messages {
      code
      field
      message
    }
  }
}
    `;
export type DeleteCardsMutationFn = ApolloReactCommon.MutationFunction<DeleteCardsMutation, DeleteCardsMutationVariables>;
export type DeleteCardsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCardsMutation, DeleteCardsMutationVariables>, 'mutation'>;

    export const DeleteCardsComponent = (props: DeleteCardsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCardsMutation, DeleteCardsMutationVariables> mutation={DeleteCardsDocument} {...props} />
    );
    
export type DeleteCardsProps<TChildProps = {}> = ApolloReactHoc.MutateProps<DeleteCardsMutation, DeleteCardsMutationVariables> & TChildProps;
export function withDeleteCards<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCardsMutation,
  DeleteCardsMutationVariables,
  DeleteCardsProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCardsMutation, DeleteCardsMutationVariables, DeleteCardsProps<TChildProps>>(DeleteCardsDocument, {
      alias: 'deleteCards',
      ...operationOptions
    });
};

/**
 * __useDeleteCardsMutation__
 *
 * To run a mutation, you first call `useDeleteCardsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardsMutation, { data, loading, error }] = useDeleteCardsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCardsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCardsMutation, DeleteCardsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCardsMutation, DeleteCardsMutationVariables>(DeleteCardsDocument, baseOptions);
      }
export type DeleteCardsMutationHookResult = ReturnType<typeof useDeleteCardsMutation>;
export type DeleteCardsMutationResult = ApolloReactCommon.MutationResult<DeleteCardsMutation>;
export type DeleteCardsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCardsMutation, DeleteCardsMutationVariables>;
export const GetBillProjectInfoDocument = gql`
    query GetBillProjectInfo($id: ID) {
  node(id: $id) {
    ... on Project {
      id
      title
      type
      user {
        avatar
        nickname
      }
    }
  }
}
    `;
export type GetBillProjectInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables>, 'query'>;

    export const GetBillProjectInfoComponent = (props: GetBillProjectInfoComponentProps) => (
      <ApolloReactComponents.Query<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables> query={GetBillProjectInfoDocument} {...props} />
    );
    
export type GetBillProjectInfoProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables> & TChildProps;
export function withGetBillProjectInfo<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetBillProjectInfoQuery,
  GetBillProjectInfoQueryVariables,
  GetBillProjectInfoProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables, GetBillProjectInfoProps<TChildProps>>(GetBillProjectInfoDocument, {
      alias: 'getBillProjectInfo',
      ...operationOptions
    });
};

/**
 * __useGetBillProjectInfoQuery__
 *
 * To run a query within a React component, call `useGetBillProjectInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillProjectInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillProjectInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBillProjectInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables>(GetBillProjectInfoDocument, baseOptions);
      }
export function useGetBillProjectInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables>(GetBillProjectInfoDocument, baseOptions);
        }
export type GetBillProjectInfoQueryHookResult = ReturnType<typeof useGetBillProjectInfoQuery>;
export type GetBillProjectInfoLazyQueryHookResult = ReturnType<typeof useGetBillProjectInfoLazyQuery>;
export type GetBillProjectInfoQueryResult = ApolloReactCommon.QueryResult<GetBillProjectInfoQuery, GetBillProjectInfoQueryVariables>;
export const GetLabelCharWordSentDocument = gql`
    query GetLabelCharWordSent($ids: [ID!]!) {
  labelCharWordSent(ids: $ids) {
    labels {
      cardId
      labelMap
    }
    unsuccessfulIds
  }
}
    `;
export type GetLabelCharWordSentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables>, 'query'> & ({ variables: GetLabelCharWordSentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetLabelCharWordSentComponent = (props: GetLabelCharWordSentComponentProps) => (
      <ApolloReactComponents.Query<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables> query={GetLabelCharWordSentDocument} {...props} />
    );
    
export type GetLabelCharWordSentProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables> & TChildProps;
export function withGetLabelCharWordSent<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetLabelCharWordSentQuery,
  GetLabelCharWordSentQueryVariables,
  GetLabelCharWordSentProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables, GetLabelCharWordSentProps<TChildProps>>(GetLabelCharWordSentDocument, {
      alias: 'getLabelCharWordSent',
      ...operationOptions
    });
};

/**
 * __useGetLabelCharWordSentQuery__
 *
 * To run a query within a React component, call `useGetLabelCharWordSentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelCharWordSentQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelCharWordSentQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetLabelCharWordSentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables>(GetLabelCharWordSentDocument, baseOptions);
      }
export function useGetLabelCharWordSentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables>(GetLabelCharWordSentDocument, baseOptions);
        }
export type GetLabelCharWordSentQueryHookResult = ReturnType<typeof useGetLabelCharWordSentQuery>;
export type GetLabelCharWordSentLazyQueryHookResult = ReturnType<typeof useGetLabelCharWordSentLazyQuery>;
export type GetLabelCharWordSentQueryResult = ApolloReactCommon.QueryResult<GetLabelCharWordSentQuery, GetLabelCharWordSentQueryVariables>;
export const PickMetaDiscoursesDocument = gql`
    query PickMetaDiscourses($ids: [ID!]!) {
  pickMetadiscourses(ids: $ids) {
    metadiscourses {
      cardId
      category
      examples {
        author
        sentence
        title
      }
      locations {
        end
        start
      }
      word
    }
    unsuccessfulIds
  }
}
    `;
export type PickMetaDiscoursesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables>, 'query'> & ({ variables: PickMetaDiscoursesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PickMetaDiscoursesComponent = (props: PickMetaDiscoursesComponentProps) => (
      <ApolloReactComponents.Query<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables> query={PickMetaDiscoursesDocument} {...props} />
    );
    
export type PickMetaDiscoursesProps<TChildProps = {}> = ApolloReactHoc.DataProps<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables> & TChildProps;
export function withPickMetaDiscourses<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PickMetaDiscoursesQuery,
  PickMetaDiscoursesQueryVariables,
  PickMetaDiscoursesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables, PickMetaDiscoursesProps<TChildProps>>(PickMetaDiscoursesDocument, {
      alias: 'pickMetaDiscourses',
      ...operationOptions
    });
};

/**
 * __usePickMetaDiscoursesQuery__
 *
 * To run a query within a React component, call `usePickMetaDiscoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickMetaDiscoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickMetaDiscoursesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePickMetaDiscoursesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables>) {
        return ApolloReactHooks.useQuery<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables>(PickMetaDiscoursesDocument, baseOptions);
      }
export function usePickMetaDiscoursesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables>(PickMetaDiscoursesDocument, baseOptions);
        }
export type PickMetaDiscoursesQueryHookResult = ReturnType<typeof usePickMetaDiscoursesQuery>;
export type PickMetaDiscoursesLazyQueryHookResult = ReturnType<typeof usePickMetaDiscoursesLazyQuery>;
export type PickMetaDiscoursesQueryResult = ApolloReactCommon.QueryResult<PickMetaDiscoursesQuery, PickMetaDiscoursesQueryVariables>;
export const LabelReplacementDocument = gql`
    query LabelReplacement($ids: [ID!]!) {
  labelReplacement(ids: $ids) {
    replacements {
      word
      start
      end
      cardId
    }
    unsuccessfulIds
  }
}
    `;
export type LabelReplacementComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LabelReplacementQuery, LabelReplacementQueryVariables>, 'query'> & ({ variables: LabelReplacementQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LabelReplacementComponent = (props: LabelReplacementComponentProps) => (
      <ApolloReactComponents.Query<LabelReplacementQuery, LabelReplacementQueryVariables> query={LabelReplacementDocument} {...props} />
    );
    
export type LabelReplacementProps<TChildProps = {}> = ApolloReactHoc.DataProps<LabelReplacementQuery, LabelReplacementQueryVariables> & TChildProps;
export function withLabelReplacement<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LabelReplacementQuery,
  LabelReplacementQueryVariables,
  LabelReplacementProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, LabelReplacementQuery, LabelReplacementQueryVariables, LabelReplacementProps<TChildProps>>(LabelReplacementDocument, {
      alias: 'labelReplacement',
      ...operationOptions
    });
};

/**
 * __useLabelReplacementQuery__
 *
 * To run a query within a React component, call `useLabelReplacementQuery` and pass it any options that fit your needs.
 * When your component renders, `useLabelReplacementQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLabelReplacementQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useLabelReplacementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LabelReplacementQuery, LabelReplacementQueryVariables>) {
        return ApolloReactHooks.useQuery<LabelReplacementQuery, LabelReplacementQueryVariables>(LabelReplacementDocument, baseOptions);
      }
export function useLabelReplacementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LabelReplacementQuery, LabelReplacementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LabelReplacementQuery, LabelReplacementQueryVariables>(LabelReplacementDocument, baseOptions);
        }
export type LabelReplacementQueryHookResult = ReturnType<typeof useLabelReplacementQuery>;
export type LabelReplacementLazyQueryHookResult = ReturnType<typeof useLabelReplacementLazyQuery>;
export type LabelReplacementQueryResult = ApolloReactCommon.QueryResult<LabelReplacementQuery, LabelReplacementQueryVariables>;
export const GetReplacementWordsDocument = gql`
    query GetReplacementWords($matching: String) {
  querySimWords(matching: $matching) {
    simWords {
      weight
      word
    }
  }
}
    `;
export type GetReplacementWordsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetReplacementWordsQuery, GetReplacementWordsQueryVariables>, 'query'>;

    export const GetReplacementWordsComponent = (props: GetReplacementWordsComponentProps) => (
      <ApolloReactComponents.Query<GetReplacementWordsQuery, GetReplacementWordsQueryVariables> query={GetReplacementWordsDocument} {...props} />
    );
    
export type GetReplacementWordsProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetReplacementWordsQuery, GetReplacementWordsQueryVariables> & TChildProps;
export function withGetReplacementWords<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetReplacementWordsQuery,
  GetReplacementWordsQueryVariables,
  GetReplacementWordsProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetReplacementWordsQuery, GetReplacementWordsQueryVariables, GetReplacementWordsProps<TChildProps>>(GetReplacementWordsDocument, {
      alias: 'getReplacementWords',
      ...operationOptions
    });
};

/**
 * __useGetReplacementWordsQuery__
 *
 * To run a query within a React component, call `useGetReplacementWordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReplacementWordsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReplacementWordsQuery({
 *   variables: {
 *      matching: // value for 'matching'
 *   },
 * });
 */
export function useGetReplacementWordsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetReplacementWordsQuery, GetReplacementWordsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetReplacementWordsQuery, GetReplacementWordsQueryVariables>(GetReplacementWordsDocument, baseOptions);
      }
export function useGetReplacementWordsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetReplacementWordsQuery, GetReplacementWordsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetReplacementWordsQuery, GetReplacementWordsQueryVariables>(GetReplacementWordsDocument, baseOptions);
        }
export type GetReplacementWordsQueryHookResult = ReturnType<typeof useGetReplacementWordsQuery>;
export type GetReplacementWordsLazyQueryHookResult = ReturnType<typeof useGetReplacementWordsLazyQuery>;
export type GetReplacementWordsQueryResult = ApolloReactCommon.QueryResult<GetReplacementWordsQuery, GetReplacementWordsQueryVariables>;
export const GetCardCountDocument = gql`
    query GetCardCount($first: Int, $filter: CardFilter!, $projectId: ID) {
  cards(first: $first, projectId: $projectId, filter: $filter) {
    count
    edges {
      node {
        id
        title
      }
    }
  }
}
    `;
export type GetCardCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCardCountQuery, GetCardCountQueryVariables>, 'query'> & ({ variables: GetCardCountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCardCountComponent = (props: GetCardCountComponentProps) => (
      <ApolloReactComponents.Query<GetCardCountQuery, GetCardCountQueryVariables> query={GetCardCountDocument} {...props} />
    );
    
export type GetCardCountProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCardCountQuery, GetCardCountQueryVariables> & TChildProps;
export function withGetCardCount<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCardCountQuery,
  GetCardCountQueryVariables,
  GetCardCountProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCardCountQuery, GetCardCountQueryVariables, GetCardCountProps<TChildProps>>(GetCardCountDocument, {
      alias: 'getCardCount',
      ...operationOptions
    });
};

/**
 * __useGetCardCountQuery__
 *
 * To run a query within a React component, call `useGetCardCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardCountQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardCountQuery({
 *   variables: {
 *      first: // value for 'first'
 *      filter: // value for 'filter'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCardCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCardCountQuery, GetCardCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCardCountQuery, GetCardCountQueryVariables>(GetCardCountDocument, baseOptions);
      }
export function useGetCardCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCardCountQuery, GetCardCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCardCountQuery, GetCardCountQueryVariables>(GetCardCountDocument, baseOptions);
        }
export type GetCardCountQueryHookResult = ReturnType<typeof useGetCardCountQuery>;
export type GetCardCountLazyQueryHookResult = ReturnType<typeof useGetCardCountLazyQuery>;
export type GetCardCountQueryResult = ApolloReactCommon.QueryResult<GetCardCountQuery, GetCardCountQueryVariables>;
export const GetProjectUpdateTimeDocument = gql`
    query GetProjectUpdateTime($id: ID, $code: String) {
  node(id: $id, code: $code) {
    ... on Project {
      outlineState
    }
  }
}
    `;
export type GetProjectUpdateTimeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables>, 'query'>;

    export const GetProjectUpdateTimeComponent = (props: GetProjectUpdateTimeComponentProps) => (
      <ApolloReactComponents.Query<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables> query={GetProjectUpdateTimeDocument} {...props} />
    );
    
export type GetProjectUpdateTimeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables> & TChildProps;
export function withGetProjectUpdateTime<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetProjectUpdateTimeQuery,
  GetProjectUpdateTimeQueryVariables,
  GetProjectUpdateTimeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables, GetProjectUpdateTimeProps<TChildProps>>(GetProjectUpdateTimeDocument, {
      alias: 'getProjectUpdateTime',
      ...operationOptions
    });
};

/**
 * __useGetProjectUpdateTimeQuery__
 *
 * To run a query within a React component, call `useGetProjectUpdateTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUpdateTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUpdateTimeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetProjectUpdateTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables>(GetProjectUpdateTimeDocument, baseOptions);
      }
export function useGetProjectUpdateTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables>(GetProjectUpdateTimeDocument, baseOptions);
        }
export type GetProjectUpdateTimeQueryHookResult = ReturnType<typeof useGetProjectUpdateTimeQuery>;
export type GetProjectUpdateTimeLazyQueryHookResult = ReturnType<typeof useGetProjectUpdateTimeLazyQuery>;
export type GetProjectUpdateTimeQueryResult = ApolloReactCommon.QueryResult<GetProjectUpdateTimeQuery, GetProjectUpdateTimeQueryVariables>;
export const GetCardsUpdateTimeDocument = gql`
    query GetCardsUpdateTime($first: Int, $ids: [ID], $filter: CardFilter!) {
  cards(first: $first, ids: $ids, filter: $filter) {
    edges {
      node {
        id
        updatedAt
      }
    }
  }
}
    `;
export type GetCardsUpdateTimeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables>, 'query'> & ({ variables: GetCardsUpdateTimeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetCardsUpdateTimeComponent = (props: GetCardsUpdateTimeComponentProps) => (
      <ApolloReactComponents.Query<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables> query={GetCardsUpdateTimeDocument} {...props} />
    );
    
export type GetCardsUpdateTimeProps<TChildProps = {}> = ApolloReactHoc.DataProps<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables> & TChildProps;
export function withGetCardsUpdateTime<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetCardsUpdateTimeQuery,
  GetCardsUpdateTimeQueryVariables,
  GetCardsUpdateTimeProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables, GetCardsUpdateTimeProps<TChildProps>>(GetCardsUpdateTimeDocument, {
      alias: 'getCardsUpdateTime',
      ...operationOptions
    });
};

/**
 * __useGetCardsUpdateTimeQuery__
 *
 * To run a query within a React component, call `useGetCardsUpdateTimeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsUpdateTimeQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsUpdateTimeQuery({
 *   variables: {
 *      first: // value for 'first'
 *      ids: // value for 'ids'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCardsUpdateTimeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables>(GetCardsUpdateTimeDocument, baseOptions);
      }
export function useGetCardsUpdateTimeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables>(GetCardsUpdateTimeDocument, baseOptions);
        }
export type GetCardsUpdateTimeQueryHookResult = ReturnType<typeof useGetCardsUpdateTimeQuery>;
export type GetCardsUpdateTimeLazyQueryHookResult = ReturnType<typeof useGetCardsUpdateTimeLazyQuery>;
export type GetCardsUpdateTimeQueryResult = ApolloReactCommon.QueryResult<GetCardsUpdateTimeQuery, GetCardsUpdateTimeQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput) {
  updateUser(input: $input) {
    messages {
      code
      field
      message
    }
    result {
      avatar
      id
      nickname
      userAuths {
        id
        identifier
        identityType
      }
      role
      username
    }
    successful
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    
export type UpdateUserProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateUserMutation, UpdateUserMutationVariables> & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserMutation, UpdateUserMutationVariables, UpdateUserProps<TChildProps>>(UpdateUserDocument, {
      alias: 'updateUser',
      ...operationOptions
    });
};

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const ResetPhoneDocument = gql`
    mutation ResetPhone($input: ResetPhoneInput) {
  resetPhone(input: $input) {
    messages {
      code
      field
      message
    }
    result {
      identifier
    }
    successful
  }
}
    `;
export type ResetPhoneMutationFn = ApolloReactCommon.MutationFunction<ResetPhoneMutation, ResetPhoneMutationVariables>;
export type ResetPhoneComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPhoneMutation, ResetPhoneMutationVariables>, 'mutation'>;

    export const ResetPhoneComponent = (props: ResetPhoneComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPhoneMutation, ResetPhoneMutationVariables> mutation={ResetPhoneDocument} {...props} />
    );
    
export type ResetPhoneProps<TChildProps = {}> = ApolloReactHoc.MutateProps<ResetPhoneMutation, ResetPhoneMutationVariables> & TChildProps;
export function withResetPhone<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetPhoneMutation,
  ResetPhoneMutationVariables,
  ResetPhoneProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, ResetPhoneMutation, ResetPhoneMutationVariables, ResetPhoneProps<TChildProps>>(ResetPhoneDocument, {
      alias: 'resetPhone',
      ...operationOptions
    });
};

/**
 * __useResetPhoneMutation__
 *
 * To run a mutation, you first call `useResetPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPhoneMutation, { data, loading, error }] = useResetPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPhoneMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPhoneMutation, ResetPhoneMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPhoneMutation, ResetPhoneMutationVariables>(ResetPhoneDocument, baseOptions);
      }
export type ResetPhoneMutationHookResult = ReturnType<typeof useResetPhoneMutation>;
export type ResetPhoneMutationResult = ApolloReactCommon.MutationResult<ResetPhoneMutation>;
export type ResetPhoneMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPhoneMutation, ResetPhoneMutationVariables>;