import { Col, message, Pagination, Row, Spin } from "antd";
import {
  CardType,
  FileType,
  SortOrder,
  useGetInspirationCardFolderQuery,
  useReferenceCardsMutation,
} from "../../../generated/graphql";
import FolderCard from "../FolderCard";
import GoldenIcon from "../../../assets/writing/golden-card.png";
import WikiIcon from "../../../assets/writing/wiki-card.png";
import CardTemplate from "../../Components/CardTemplate";
import EmptyComponent from "../../Components/EmptyComponent";
import ReconfirmModal from "../../Components/ReconfirmModal";
import CreateModal from "../../Components/CreateModal";
import * as React from "react";
import Back from "../../../assets/inspiration/back.png";
import ADD_PROJECT from "../../../assets/corpus/add-dropdown.png";
import ADD_CARD from "../../../assets/add-card.png";
import CardCountIcon from "../../../assets/project/card-count-icon.png";
import { renderPagination } from "../../Components/Pagination";
import { getPaginationPageVariable } from "../../../utils";
import { useState } from "react";
import { useEffect } from "react";
import { getUrlPath, getUrlSearch } from "../../../utils/getUrlInfo";
import {
  P_INDEX,
  P_ID_INDEX,
  F_INDEX,
  F_ID_INDEX,
  C_INDEX,
  C_ID_INDEX,
} from "../index";

const PER_PAGE_COUNT = 24;

const InsCards = (props) => {
  const {
    cardList,
    order,
    folderDetail,
    getCardListForProject,
    getWIKICards,
    showListInfo,
    searchString,
    getCardList,
    setCurrentPage,
    getFolderCards,
    currentPage,
    selectedTags,
    currentPageInfo,
    confirmText,
    colSize,
    isShowReconfirmModal,
    setIsShowReconfirmModal,
    setSelectedCard,
    setIsShowCardDetail,
  } = props;

  const [chosenItem, setChosenItem] = useState(null);
  const [referenceCards] = useReferenceCardsMutation();
  const {
    refetch: getInspirationCardFolder,
  } = useGetInspirationCardFolderQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  return (
    <>
      <div className="inspiration-main-card-container">
        <div className="inspiration-main-card-list-container">
          {cardList.length ? (
            <Row gutter={[8, 16]}>
              {cardList.map((item, index) => {
                let showAddFolder = false;
                if (item.fileType === "FILE")
                  return (
                    <Col key={"col-ins-" + item.id + index} span={colSize}>
                      <CardTemplate
                        item={item}
                        isHoverOutLine={true}
                        isHoverShowIcon={true}
                        searchString={searchString}
                        setSelectedCard={setSelectedCard}
                        clickCallback={() => {
                          setIsShowCardDetail(true);

                          let pathArray = getUrlPath();
                          let path = `/${pathArray[0]}/${pathArray[1]}`;

                          if (
                            pathArray[P_INDEX] &&
                            pathArray[P_INDEX] === "p" &&
                            pathArray[P_ID_INDEX] &&
                            pathArray[P_ID_INDEX] !== ""
                          ) {
                            path += `/p/${pathArray[P_ID_INDEX]}`;
                          }
                          if (
                            pathArray[F_INDEX] &&
                            pathArray[F_INDEX] === "f" &&
                            pathArray[F_ID_INDEX] &&
                            pathArray[F_ID_INDEX] !== ""
                          ) {
                            path += `/f/${pathArray[F_ID_INDEX]}`;
                          }

                          path += `/c/${
                            window.atob(item.code).split("Card:")[1]
                          }`;
                          path += window.location.search;
                          window.history.pushState(null, "", path);
                        }}
                        style={{ width: "300px", height: "220px" }}
                        icon={GoldenIcon}
                        extraTitleButton={[
                          {
                            buttonType: "copy",
                            callback: null,
                          },
                          {
                            buttonType: "collect",
                            callback: async (val, cb) => {
                              setIsShowReconfirmModal(false);
                              const input = {
                                ids: [window.atob(item.id).split(":")[1]],
                                type: item.type,
                              };
                              await referenceCards({
                                variables: { input },
                              });
                              message.success("成功添加卡片到素材库");
                              cb();
                            },
                          },
                        ]}
                      />
                    </Col>
                  );
                return (
                  <Col key={"col-ins-" + item.id + index} span={colSize}>
                    <FolderCard
                      getInspirationCardFolder={getInspirationCardFolder}
                      referenceCards={referenceCards}
                      getFolderCards={getFolderCards}
                      item={item}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : searchString ? (
            <div style={{ marginTop: "100px" }}>
              <EmptyComponent tip={"没有搜索到卡片"} />
            </div>
          ) : null}
        </div>
        {cardList.length ? (
          <div className="inspiration-main-card-page-container">
            <Pagination
              pageSize={PER_PAGE_COUNT}
              itemRender={
                colSize === 6 &&
                folderDetail.isWriterFolder &&
                !cardList[0].childrenCounts
                  ? () => {
                      const pageContainer = document.getElementsByClassName(
                        "ant-pagination"
                      );
                      if (pageContainer[0]) {
                        pageContainer[0].style.display = "none";
                      }
                      return <br />;
                    }
                  : renderPagination
              }
              current={currentPage}
              hideOnSinglePage
              total={currentPageInfo.count}
              onChange={(page) => {
                const pageInfo = getPaginationPageVariable(
                  page,
                  currentPage,
                  currentPageInfo
                );
                if (colSize === 6) {
                  const variables = {
                    ...pageInfo,
                    projectIds: showListInfo
                      ? [
                          window.btoa(
                            "SharedProject:" +
                              window.atob(showListInfo.id).split(":")[1]
                          ),
                        ]
                      : undefined,
                    matching: searchString === "" ? undefined : searchString,
                  };
                  const isCardFolder = cardList[0].fileType === "FOLDER";
                  if (isCardFolder) {
                    getCardListForProject(
                      {
                        ...pageInfo,
                        id: window.btoa(
                          "SharedProject:" +
                            window.atob(folderDetail.id).split(":")[1]
                        ),
                      },
                      () => {
                        setCurrentPage(page);
                      }
                    );
                  } else {
                    variables.order = "DESC_INSERTED_AT";
                    variables.filter = {
                      types: ["GOLDEN"],
                    };
                    getCardList(variables, () => setCurrentPage(page));
                  }
                  return;
                }
                const wikiVariables = {
                  pageInfo,
                  selectedTags,
                  type: "WIKI",
                  order,
                  matching: searchString === "" ? undefined : searchString,
                };
                getWIKICards(wikiVariables, () => setCurrentPage(page));
              }}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default InsCards;
