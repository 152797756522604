import moment from "moment";
import {
  resetProjectInfo,
  resetCardInfo,
} from "../Router_Project/MyWriting/utils";
import { async } from "ramda-adjunct";

export const updateProjectData = async (
  projectData,
  dbController,
  successCallback,
  failCallback
) => {
  try {
    const projectsChart = dbController.db.project_string_data;
    const projectQuery = projectsChart
      .findOne()
      .where("id")
      .eq(projectData.id);
    const projectFromDB = await projectQuery.exec();
    if (projectFromDB && projectFromDB.data !== "") {
      const changeFunction = (oldData) => {
        oldData.data = projectData.data;
        oldData.dbUpdateMark = projectData.dbUpdateMark;
        return oldData;
      };
      await projectFromDB.atomicUpdate(changeFunction);
    } else {
      projectsChart.insert({
        id: projectData.id,
        data: projectData.data,
        dbUpdateMark: projectData.dbUpdateMark,
      });
    }
    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    console.log("updateProjectData error!");
    console.log(e);
    if (failCallback) {
      failCallback();
    }
  }
};
// export const updateProjectData = async (
//   projectOriginalId,
//   projectData,
//   dbController,
//   successCallback,
//   failCallback
// ) => {
//   try {
//     const projectsChart = dbController.db.projects;
//     const projectQuery = projectsChart
//       .findOne()
//       .where("originalId")
//       .eq(projectOriginalId);
//     const projectFromDB = await projectQuery.exec();
//     if (projectFromDB) {
//       const changeFunction = (oldData) => {
//         oldData.code = projectData.code;
//         oldData.id = projectData.id;
//         oldData.insertedAt = projectData.insertedAt;
//         oldData.originalCode = projectData.originalCode;
//         oldData.originalId = projectData.originalId;
//         oldData.outline = projectData.outline;
//         oldData.outlineState = projectData.outlineState;
//         oldData.title = projectData.title;
//         oldData.type = projectData.type;
//         oldData.updatedAt = projectData.updatedAt;
//         return oldData;
//       };
//       await projectFromDB.atomicUpdate(changeFunction);
//       // await projectQuery.update({
//       //   $set: {
//       //     ...resetProjectInfo(projectData),
//       //   },
//       // });
//     }
//     // else {
//     //   projectsChart.insert(resetProjectInfo(projectData));
//     // }
//     if (successCallback) {
//       successCallback();
//     }
//   } catch (e) {
//     console.log("updateProjectData error!");
//     console.log(e);
//     if (failCallback) {
//       failCallback();
//     }
//   }
// };

export const getProjectData = async (projectId, dbController) => {
  try {
    const projectsChart = dbController.db.project_string_data;
    const projectQuery = projectsChart
      .findOne()
      .where("id")
      .eq(projectId);
    const projectFromDB = await projectQuery.exec();
    if (projectFromDB) {
      return projectFromDB.data;
    }
  } catch (e) {}
};

export const projectSubscribe = (projectId, dbController, callback) => {
  return dbController.db.project_string_data
    .findOne()
    .where("id")
    .eq(projectId)
    .$.subscribe((data) => {
      if (callback) {
        callback(data);
      }
    });
};

export const updateCardData = async (
  cardData,
  dbController,
  successCallback,
  failCallback
) => {
  try {
    const cardsChart = dbController.db.cards_string_data;
    const cardQuery = cardsChart
      .findOne()
      .where("id")
      .eq(cardData.id);
    const cardFromDB = await cardQuery.exec();
    if (cardFromDB && cardFromDB.data !== "") {
      const changeFunction = (oldData) => {
        oldData.data = cardData.data;
        oldData.dbUpdateMark = cardData.dbUpdateMark;
        return oldData;
      };
      await cardFromDB.atomicUpdate(changeFunction);
      if (successCallback) {
        successCallback();
      }
    } else {
      cardsChart.insert({
        id: cardData.id,
        data: cardData.data,
        dbUpdateMark: cardData.dbUpdateMark,
      });
    }
  } catch (e) {
    console.log("updateCardData error!");
    console.log(e);
    if (failCallback) {
      failCallback();
    }
  }
};

export const getCardsData = async (projectId, dbController) => {
  try {
    const cardsChart = dbController.db.cards_string_data;
    const cardsQuery = cardsChart
      .findOne()
      .where("id")
      .eq(projectId);
    const cardsFromDB = await cardsQuery.exec();
    if (cardsFromDB) {
      return cardsFromDB.data;
    }
  } catch (e) {
  }
};

export const cardsSubscribe = (projectId, dbController, callback) => {
  return dbController.db.cards_string_data
    .findOne()
    .where("id")
    .eq(projectId)
    .$.subscribe((data) => {
      if (callback) {
        callback(data);
      }
    });
};

// export const updateCardData = async (
//   cardData,
//   dbController,
//   successCallback,
//   failCallback
// ) => {
//   try {
//     const cardsChart = dbController.db.cards;
//     const cardQuery = cardsChart
//       .findOne()
//       .where("id")
//       .eq(cardData.id);
//     const cardFromDB = await cardQuery.exec();
//     if (cardFromDB) {
//       const changeFunction = (oldData) => {
//         Object.keys(cardData).forEach((item) => {
//           oldData[item] = cardData[item];
//         });
//         return oldData;
//       };
//       await cardFromDB.atomicUpdate(changeFunction);
//       if (successCallback) {
//         successCallback();
//       }
//     } else {
//       if (failCallback) {
//         failCallback();
//       }
//     }
//   } catch (e) {
//     console.log("updateCardData error!");
//     console.log(e);
//     if (failCallback) {
//       failCallback();
//     }
//   }
// };

// export const getCardsData = async (projectId, dbController) => {
//   const cardsData = [];
//   try {
//     const cardsChart = dbController.db.cards;
//     const cardQuery = cardsChart
//       .find()
//       .where("projectId")
//       .eq(projectId)
//       .where("isLocalData")
//       .eq(true);
//     const cardsFromDB = await cardQuery.exec();
//     if (cardsFromDB && cardsFromDB.length) {
//       const changeFunction = (oldData) => {
//         oldData.isLocalData = false;
//         oldData.isRevision = false;
//         return oldData;
//       };
//       await Promise.all(
//         cardsFromDB.map(async (item) => {
//           cardsData.push({
//             id: item.id,
//             title: item.title,
//             content: item.content,
//             revision: item.isRevision,
//           });
//           await item.atomicUpdate(changeFunction);
//         })
//       );
//     }
//   } catch (e) {}
//   return cardsData;
// };

export const insertCardsData = async (cards, dbController, successCallback) => {
  try {
    const cardsChart = dbController.db.cards;
    await Promise.all(
      cards.map(async (item) => {
        await cardsChart.insert({
          projectId: item.projectId,
          code: item.code,
          content: item.content,
          id: item.id,
          title: item.title,
          updatedAt: item.updatedAt,
          isLocalData: true,
          isRevision: false,
        });
      })
    );
    if (successCallback) {
      successCallback();
    }
  } catch (e) {}
};
