import gql from "graphql-tag";

export const GET_USER_INFO = gql`
  {
    userInfoClient @client
  }
`;

export const GET_CARDS_DATA = gql`
  {
    projectCardsDataClient @client
  }
`;

export const GET_DEFAULT_SELECTED_NODE = gql`
  {
    defaultSelectedCardIdClient @client
  }
`;

export const GET_CURRENT_PROJECT_DATA = gql`
  {
    currentProjectIdClient @client
    projectOutlineClient @client
    projectCardsDataClient @client
    projectShowInitTipClient @client
  }
`;

export const GET_CURRENT_PROJECT_ID = gql`
  {
    currentProjectIdClient @client
  }
`;

export const GET_TOOLS_TAB_DATA = gql`
  {
    writingToolsTabIndexClient @client
  }
`;

export const SAVE_BUTTON_CLICK = gql`
  {
    writingTitleBarToolsSaveClickClient @client
  }
`;

export const ADD_BUTTON_CLICK = gql`
  {
    writingTitleBarToolsAddClickClient @client
    writingTitleBarToolsAddChildClickClient @client
  }
`;

export const INSERT_BUTTON_CLICK = gql`
  {
    writingTitleBarToolsInsertClickClient @client
  }
`;

export const DELETE_BUTTON_CLICK = gql`
  {
    writingTitleBarToolsDeleteClickClient @client
  }
`;


export const EXPORT_BUTTON_CLICK = gql`
  {
    writingTitleBarToolsExportClickClient @client
  }
`;

export const SPLIT_BUTTON_CLICK = gql`
  {
    writingTitleBarToolsSplitClickClient @client
    writingTitleBarToolsSplitTypeClient @client
  }
`;