import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

//apollo库函数
import { InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import { split, ApolloLink } from "apollo-link";
import { hasSubscription } from "@jumpn/utils-graphql";
import { ApolloProvider } from "react-apollo";
import { createLink } from "apollo-absinthe-upload-link";
import { ApolloClient } from "apollo-client";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import { WebSocketLink } from "apollo-link-ws";

import { converToZH } from "./utils/convertErrors";

//第三方组件
import { message } from "antd";

//其他页面组件
import datas from "./GlobalData/datas";
import headers from "./GlobalData/headers";
import config from "./configs";

//全局变量，使用了Graphql的缓存方案
const cache = new InMemoryCache();
cache.writeData({
  data: {
    ...datas,
  },
});

//websocket订阅
// const wsLink = new WebSocketLink({
//   uri: config.ws,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       authToken: headers.Authorization
//     }
//   }
// });

//全局错误处理
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let i in graphQLErrors) {
      const { code, message, locations, path } = graphQLErrors[i];
      if (code === "token_not_found") {
        window.localStorage.removeItem("TOKENINFO");
        window.localStorage.removeItem("USERINFO");
        window.location.reload();
      }
    }
  }
  // if (networkError) {
  //   message.error("网络连接错误");
  // }
});

//数据传输中间件
const middlewareLink = new ApolloLink((operation, forward) => {
  return forward(operation);
});

// const link = split(
//   operation => hasSubscription(operation.query),
//   wsLink,
//   ApolloLink.from([
//     errorLink,
//     middlewareLink,
//     createLink({ uri: config.url, headers })
//   ])
// );

// const link = split(
//   operation => hasSubscription(operation.query),
//   errorLink,
//   ApolloLink.from([
//     middlewareLink,
//     createLink({ uri: config.url, headers })
//   ])
// );

const link = ApolloLink.from([
  errorLink,
  middlewareLink,
  createLink({ uri: config.url, headers }),
]);

const client = new ApolloClient({
  link,
  cache: cache,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <App />
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
