import * as React from "react";
import "./styles.css";

import { Dropdown, Menu, Input } from "antd";

import SearchIcon from "../../../assets/search.png";
import NodeDownIcon from "../../../assets/node-down.png";

const SearchWithoutDrop = (props) => {
  const { callback, position = null } = props;
  const [searchString, setSearchString] = React.useState("");

  return (
    <div className="search-container" style={position ? position : {}}>
      <div className="search-block">
        <Input
          placeholder="搜索"
          className="search-input-single"
          prefix={
            <img src={SearchIcon} style={{ width: "16px", height: "16px" }} />
          }
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          onPressEnter={() => {
            if (callback) {
              callback(searchString);
            }
          }}
        />
      </div>
    </div>
  );
};

export default SearchWithoutDrop;
