import { Icon, message } from "antd";
import React, { useEffect, useState } from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Draggable from "react-draggable";
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import { SET_MAIN_DATA } from "../../../utils/qlQueries";
import { GET_CARDS_DATA } from "../localQuery";
import { useUpdateCardMutation } from "../../../generated/graphql";
import "../styles.css";
import OUTLINE_NODE from "../../../assets/card_icon.png";
import OUTLINE_FOLDER from "../../../assets/folder_yellow.png";
import DELETE_CARD from "../../../assets/delete_card.png";
import { convertToZH } from "../../../utils/convertErrors";
import { html2EscapeForCard } from "../utils/main";
import { getUrlSearch } from "../../../utils/getUrlInfo";
import EmptyComponent from "../../Components/EmptyComponent/index";
import { getDataInOutline } from "../utils";
import { getOutlineIcon } from "../WritingOutline/getOutlineIcon";

const SortableItemDetail = (props) => {
  const {
    value,
    chooseCard,
    getMatchedCards,
    onSelect,
    setSelectedTitle,
    cardState,
    currentProjectInfo,
    setNodeToChange,
    updateCardAndOutline,
    onShuffleModule,
    onCheckCardIsSelected,
    onSelectedCards,
  } = props;

  const [showCardTitleInput, setShowCardTitleInput] = useState(false);
  const [cardInputTitle, setCardInputTitle] = useState("");
  const [showCardDeleteButton, setShowCardDeleteButton] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const [icon, setIcon] = useState("");

  const [listRowCount, setListRowCount] = React.useState(
    window.innerWidth <= 1414 ? 2 : 3
  );

  const client = useApolloClient();

  React.useEffect(() => {
    if (currentProjectInfo) {
      if (value.id === currentProjectInfo.originalId) {
        setHasChildren(true);
        setIcon(getOutlineIcon("icon-folder"));
      } else {
        getDataInOutline(
          currentProjectInfo.outline.menu,
          value.id,
          (data, item, index) => {
            setHasChildren(item && item.children && item.children.length);
            if (
              item.icon === "icon-default" &&
              item.children &&
              item.children.length
            ) {
              setIcon(getOutlineIcon("icon-default-multi"));
            } else {
              setIcon(getOutlineIcon(item.icon));
            }
          }
        );
      }
    }
  }, []);

  const changeCardContent = (e, value) => {
    if (value.title !== e.target.value) {
      setCardInputTitle(e.target.value);
    }
  };

  const getCardClassName = () => {
    const cardSelectType = isSelected
      ? "card-item-main-selected"
      : "card-item-main";
    const hasChildrenClass = hasChildren ? " folder" : "";
    return cardSelectType + hasChildrenClass;
  };

  const isSelected = onCheckCardIsSelected(value.id);

  const cardCache = cardState ? cardState[value.id] : {};

  const cardContent = (cardCache && cardCache.content) || value.content;
  const upperContent = value.contentSplit ? value.contentSplit[0] : "";
  const lowerContent = value.contentSplit ? value.contentSplit[1] : "";
  const rmSymbolUContent = upperContent
    ? html2EscapeForCard(upperContent)
    : upperContent;
  const rmSymbolLContent = lowerContent
    ? html2EscapeForCard(lowerContent)
    : lowerContent;
  const rmSymbolContent = cardContent
    ? html2EscapeForCard(cardContent)
    : cardContent;

  return (
    <div
      onDoubleClick={async () => {
        if (!onShuffleModule) {
          onSelect([value.id], null, 2);
        }
      }}
      id={value.id}
      className={getCardClassName()}
      style={{
        background: "white",
      }}
      onClick={async (e, clickValue) => {
        e.stopPropagation();
        if (onShuffleModule) {
          let isCtrlPress = false;
          if (e.ctrlKey || e.metaKey) {
            isCtrlPress = true;
          }
          onSelectedCards([value.id], isCtrlPress, false);
        } else {
          onSelect([value.id], null, 2);
          setSelectedTitle({
            title: value.title,
          });
        }
      }}
      // onMouseOver={() => {
      //   setShowCardDeleteButton(true);
      // }}
      // onMouseLeave={e => {
      //   setShowCardDeleteButton(false);
      // }}
    >
      <div
        className="card-title"
        // onDoubleClick={() => {
        //   // if (showCardTitleInput) {
        //   //   updateCardAndOutline(value.id, cardInputTitle);
        //   // }
        //   // setShowCardTitleInput(!showCardTitleInput);
        //   // setShowCardTitleInput(true);
        // }}
        // onBlur={() => {
        //   if (showCardTitleInput) {
        //     updateCardAndOutline(value.id, cardInputTitle);
        //   }
        //   setShowCardTitleInput(false);
        // }}
        // onKeyDown={e => {
        //   if (e.keyCode === 13) {
        //     if (showCardTitleInput) {
        //       updateCardAndOutline(value.id, cardInputTitle);
        //     }
        //     setShowCardTitleInput(false);
        //   }
        // }}
      >
        <section className="card-title-text-container">
          <img
            style={{ width: "14px", height: "14px", marginLeft: "12px" }}
            src={icon}
          />
          {showCardTitleInput ? (
            <input
              id={`card-title-${value.id}`}
              onFocus={() => {
                document.getElementById(`card-title-${value.id}`).select();
              }}
              max={20}
              style={{ width: 240, marginLeft: "6px" }}
              value={cardInputTitle}
              autoFocus
              onChange={(e) => {
                changeCardContent(e, value);
              }}
            />
          ) : (
            <span
              style={{
                marginLeft: "6px",
                width: 241,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
              className="card-mode-text"
            >
              {value.isReplace || !value.titleMatched ? (
                value.title
              ) : (
                <span>
                  {value.titleSplit[0]}
                  <span style={{ background: "#ffd48d" }}>
                    {value.searchWord}
                  </span>
                  {value.titleSplit[1]}
                </span>
              )}
            </span>
          )}
        </section>
        <span
          onClick={() => {
            setNodeToChange(value.id, "delete");
          }}
          className="card-delete-btn"
        >
          {showCardDeleteButton && !showCardTitleInput ? (
            <img
              style={{ margin: "2px 1px 2px 13px", width: 15 }}
              src={DELETE_CARD}
            />
          ) : (
            ""
          )}
        </span>
      </div>
      <div className="card-text-area">
        <div
          style={{
            // 必须指定宽度
            paddingLeft: 7,
            width: 320,
            height: 166,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "7",
            WebkitBoxOrient: "vertical",
          }}
          className="card-text"
        >
          {value.isReplace || !value.contentMatched ? (
            rmSymbolContent
          ) : (
            <div>
              {rmSymbolUContent.length > 100
                ? rmSymbolUContent.substring(
                    rmSymbolUContent.length - 100,
                    rmSymbolUContent.length - 1
                  )
                : rmSymbolUContent}
              <span style={{ background: "#ffd48d" }}>{value.searchWord}</span>
              {rmSymbolLContent}...
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SortableItem = (props) => {
  const {
    value,
    chooseCard,
    getMatchedCards,
    setSelectedTitle,
    cardState,
    onSelect,
    currentProjectInfo,
    updateCardAndOutline,
    setNodeToChange,
    onCheckCardIsSelected,
    onSelectedCards,
  } = props;
  return (
    <SortableItemDetail
      getMatchedCards={getMatchedCards}
      setSelectedTitle={setSelectedTitle}
      currentProjectInfo={currentProjectInfo}
      updateCardAndOutline={updateCardAndOutline}
      chooseCard={chooseCard}
      setNodeToChange={setNodeToChange}
      onSelect={onSelect}
      value={value}
      cardState={cardState}
      onCheckCardIsSelected={onCheckCardIsSelected}
      onSelectedCards={onSelectedCards}
    />
  );
};

const SortableList = (props) => {
  const {
    items,
    chooseCard,
    getMatchedCards,
    updateCardAndOutline,
    cardState,
    setNodeToChange,
    onSelect,
    onCheckCardIsSelected,
    onSelectedCards,
    setSelectedTitle,
    currentProjectInfo,
  } = props;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        paddingBottom: 33,
      }}
    >
      {items &&
        items.map((value, index) => {
          return value ? (
            <SortableItem
              getMatchedCards={getMatchedCards}
              setSelectedTitle={setSelectedTitle}
              updateCardAndOutline={updateCardAndOutline}
              chooseCard={chooseCard}
              setNodeToChange={setNodeToChange}
              onSelect={onSelect}
              cardState={cardState}
              key={`item-${value.id}`}
              index={index}
              value={value}
              onCheckCardIsSelected={onCheckCardIsSelected}
              onSelectedCards={onSelectedCards}
              currentProjectInfo={currentProjectInfo}
            />
          ) : null;
        })}
    </div>
  );
};

const GET_FREEDOM_CARD_POSITION_INFO = gql`
  {
    cardFreedomPositionInfosClient @client
  }
`;

const FreedomList = (props) => {
  const {
    items,
    chooseCard,
    loopData,
    setNodeToChange,
    onSelect,
    onCheckCardIsSelected,
    onSelectedCards,
    refreshCardData,
    currentProjectInfo,
  } = props;

  const client = useApolloClient();
  const { data: positionInfos } = useQuery(GET_FREEDOM_CARD_POSITION_INFO);
  const { cardFreedomPositionInfosClient: positionDatas } = positionInfos;
  const { data: cardsDataInfos } = useQuery(GET_CARDS_DATA);
  const { projectCardsDataClient } = cardsDataInfos;
  const [updateCardMutation] = useUpdateCardMutation();

  const refreshZIndex = (elementId) => {
    let el = document.getElementById(elementId);
    let oriZIndex = el.style.zIndex;
    for (let i in items) {
      let id = `item-${items[i].id}`;
      let zIndex = 0;
      if (id === elementId) {
        zIndex = el.style.zIndex = items.length - 1;
      } else {
        let itemEl = document.getElementById(id);
        let itemZIndex = itemEl.style.zIndex;
        if (itemZIndex > oriZIndex) {
          itemEl.style.zIndex--;
        }
        zIndex = itemEl.style.zIndex;
      }
    }
  };

  const getPositionDataFromCache = (key) => {
    for (let i in positionDatas) {
      let positionData = positionDatas[i];
      let cardKey = positionData.id;
      if (cardKey === key) {
        return {
          x: positionData.x,
          y: positionData.y,
        };
      }
    }
    return {
      x: 0,
      y: 0,
    };
  };

  const refreshPosition = (e, ui) => {
    let currentKey = ui.node.id;
    let cards = JSON.parse(
      projectCardsDataClient !== "" ? projectCardsDataClient : "{}"
    );
    for (let i in items) {
      let item = items[i];
      let key = `item-${item.id}`;
      let el = document.getElementById(key);
      let coordinate = item.coordinate ? item.coordinate : [0, 0, 0];
      if (currentKey === key) {
        coordinate[0] = ui.x;
        coordinate[1] = ui.y;
      }
      coordinate[2] = parseInt(el.style.zIndex);
      item.coordinate = coordinate;
      if (cards[item.id]) {
        cards[item.id].coordinate = coordinate;
      }
      let input = {
        id: item.id,
        coordinate: coordinate,
      };
      updateCardMutation({ variables: { input: input } }).then((res) => {
        if (!res.data.updateCard.successful) {
          // message.error(res.data.updateCard.messages[0].message);
          message.error(convertToZH(res.data.updateCard.messages[0]));
        } else {
          refreshCardData(item);
        }
      });
    }
  };

  const getDefaultPosition = (key, item, index) => {
    let coordinate = item.coordinate;
    if (coordinate) {
      return {
        x: coordinate[0],
        y: coordinate[1],
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  };

  React.useEffect(() => {
    for (let i in items) {
      let item = items[i];
      let key = `item-${item.id}`;
      let el = document.getElementById(key);
      let coordinate = item.coordinate;
      if (coordinate) {
        el.style.zIndex = coordinate[2];
      } else {
        el.style.zIndex = i;
      }
    }
  }, []);

  return (
    <div className="box card-freedom-container">
      {items.map((value, index) =>
        value ? (
          <Draggable
            key={`item-drag-${value.id}`}
            bounds="parent"
            defaultPosition={getDefaultPosition(
              `item-${value.id}`,
              value,
              index
            )}
            onStart={() => {
              refreshZIndex(`item-${value.id}`);
            }}
            onStop={(e, ui) => {
              refreshPosition(e, ui);
            }}
          >
            <div
              className="card-drag-container"
              key={`item-${value.id}`}
              id={`item-${value.id}`}
              style={{ zIndex: `${index}` }}
            >
              <SortableItemDetail
                chooseCard={chooseCard}
                setNodeToChange={setNodeToChange}
                onSelect={onSelect}
                loopData={loopData}
                index={index}
                value={value}
                onShuffleModule
                onCheckCardIsSelected={onCheckCardIsSelected}
                onSelectedCards={onSelectedCards}
                currentProjectInfo={currentProjectInfo}
              />
            </div>
          </Draggable>
        ) : null
      )}
    </div>
  );
};

const GET_CARD_SORD_TYPE = gql`
  {
    cardSordTypeClient @client
  }
`;

const CardMode = (props) => {
  const {
    setSelectedTitle,
    onSelect,
    getMatchedCards,
    items,
    itemsWithChildren,
    currentProjectInfo,
    cardState,
    onSortEnd,
    selectedNode,
    onSortStart,
    setNodeToChange,
    chooseCard,
    onCheckCardIsSelected,
    onSelectedCards,
    updateCardAndOutline,
    refreshCardData,
  } = props;
  const client = useApolloClient();
  const { data: cardSordType } = useQuery(GET_CARD_SORD_TYPE);
  const { cardSordTypeClient } = cardSordType;

  if (
    (cardSordTypeClient === 0 && items.length === 0) ||
    (cardSordTypeClient !== 0 && itemsWithChildren.length === 0)
  ) {
    return (
      <div
        style={{
          backgroundColor: "rgba(243,244,248,1)",
          flex: 1,
          width: "100%",
          height: "100%",
          overflow: "auto",
          paddingTop: 13,
          paddingLeft: 15,
          paddingBottom: 120,
        }}
      >
        <EmptyComponent tip={"没有子卡片"} />
      </div>
    );
  }

  if (true || cardSordTypeClient === 0) {
    return (
      <div
        style={{
          backgroundColor: "rgba(243,244,248,1)",
          flex: 1,
          width: "100%",
          height: "100%",
          overflow: "auto",
          paddingTop: 13,
          paddingLeft: 15,
          paddingBottom: 120,
        }}
      >
        <SortableList
          getMatchedCards={getMatchedCards}
          setSelectedTitle={setSelectedTitle}
          updateCardAndOutline={updateCardAndOutline}
          setNodeToChange={setNodeToChange}
          pressDelay={100}
          cardState={cardState}
          onSelect={onSelect}
          axis="xy"
          chooseCard={chooseCard}
          items={cardSordTypeClient === 0 ? items : itemsWithChildren}
          onSortEnd={(e) => {
            onSortEnd(e);
          }}
          onSortStart={(e) => {
            onSortStart(e);
          }}
          onCheckCardIsSelected={onCheckCardIsSelected}
          onSelectedCards={onSelectedCards}
          currentProjectInfo={currentProjectInfo}
        />
      </div>
    );
  } else {
    return (
      <FreedomList
        items={items}
        chooseCard={chooseCard}
        onSelect={onSelect}
        setNodeToChange={setNodeToChange}
        onCheckCardIsSelected={onCheckCardIsSelected}
        onSelectedCards={onSelectedCards}
        refreshCardData={refreshCardData}
        currentProjectInfo={currentProjectInfo}
      />
    );
  }
};

export default CardMode;
