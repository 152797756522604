import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Row, Col, Spin, Pagination } from "antd";

//引入的其他页面组件
import { getUrlInfo } from "../../utils/urlInfo";
import ProjectItem from "./ProjectItem/index";
import { getProjectTempName } from "../../utils/projectTempName";
import CreateModal from "../Components/CreateModal/index";
import CropperModal from "../Components/CropperModal";
import ReconfirmModal from "../Components/ReconfirmModal/index";
import EmptyComponent from "../Components/EmptyComponent/index";
import { convertToZH } from "aw-error";
import { renderPagination } from "../Components/Pagination/index";
import { getPaginationPageVariable } from "../../utils/index";
import { history } from "../AiWriterRouter/index";
import { getUrlPath, getUrlSearch } from "../../utils/getUrlInfo";
import SearchWithDrop from "../Components/Search/searchWithDrop";
import SearchWithoutDrop from "../Components/Search/searchWithoutDrop";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import {
  useGetCorpusProjectQuery,
  useCreateProjectMutation,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  ProjectType,
  SortOrder,
} from "../../generated/graphql";

//引入的资源图片

const projectTab = ["全部", "金句", "知识"];
const FIRST_PAGE_COUNT = 8;
const OTHER_PAGE_COUNT = 9;

const tabs = ["all", "golden", "wiki"];

const CorpusProjectListContainer = (props) => {
  const { setHeaderContent, openGlobalSearchRef } = props;

  const [isInit, setIsInit] = React.useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [projectList, setProjectList] = React.useState([]);
  const [projectListPageInfo, setProjectListPageInfo] = React.useState({
    count: 0,
    pageSize: FIRST_PAGE_COUNT,
    pageInfo: null,
  });
  const [searchString, setSearchString] = React.useState("");
  const [isGetProject, setIsGetProject] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [isShowCreateModal, setIsShowCreateModal] = React.useState(false);
  const [isShowReconfirmModal, setIsShowReconfirmModal] = React.useState(false);
  const [selectedProject, setSelectedProject] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);

  const { refetch: getCorpusProjectQuery } = useGetCorpusProjectQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const [createProjectMutation] = useCreateProjectMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();
  const [deleteProjectMutation] = useDeleteProjectMutation();

  const deleteProject = (input, callback) => {
    deleteProjectMutation({
      variables: { input },
    }).then((res) => {
      if (res.data.securelyDeleteProject.successful) {
        if (callback) {
          callback(window.btoa("Project:" + input.id));
        }
        message.success("删除成功");

        let page = currentPage;
        if (projectList.length === 1 && currentPage > 1) {
          page--;
        }
        let pageInfo = {
          ...projectListPageInfo,
          pageSize:
            page === 1 && searchString !== ""
              ? FIRST_PAGE_COUNT
              : OTHER_PAGE_COUNT,
        };
        let variables = getPaginationPageVariable(page, currentPage, pageInfo);
        variables = {
          ...variables,
          matching: searchString !== "" ? searchString : undefined,
          order: SortOrder.Desc,
          filter: {
            types: getTypes(),
          },
        };
        getCorpusProject(variables, page, false, () => {
          setCurrentPage(page);
        });
      } else {
        message.error("删除项目失败");
      }
    });
  };

  const updateProject = (input, callback) => {
    updateProjectMutation({ variables: { input } }).then((res) => {
      if (!res.data.updateProject.successful) {
        // message.error(res.data.updateProject.messages[0].message);
        message.error(convertToZH(res.data.updateProject.messages[0]));
      } else {
        let project = res.data.updateProject.result;
        if (callback) {
          callback(project);
        }
        message.success("修改成功");
      }
    });
  };

  const handleOnCoverImg = (src) => {
    let input = {
      id: window.atob(selectedProject.id).split("Project:")[1],
      coverImageUrl: src,
    };
    updateProject(input, (project) => {
      setFile(null);
      let projectListTemp = projectList.map((item) => {
        if (item.id === project.id) {
          item.coverImageUrl = src;
        }
        return item;
      });
      setProjectList(projectListTemp);
    });
  };

  const createProject = (projectName, type) => {
    let input = {
      title: projectName,
      outline: JSON.stringify({
        menu: { title: projectName, children: [] },
      }),
      coverImageUrl: "",
      type: type,
    };
    createProjectMutation({ variables: { input: input } }).then((res) => {
      if (!res.data.createProject.successful) {
        // message.error(res.data.createProject.messages[0].message);
        message.error(convertToZH(res.data.createProject.messages[0]));
      } else {
        let project = res.data.createProject.result;
        message.success(project.title + "创建成功");
        let variables = {
          after: undefined,
          first: FIRST_PAGE_COUNT,
          before: undefined,
          last: undefined,
          matching: undefined,
          order: SortOrder.Desc,
          filter: {
            types: getTypes(),
          },
        };
        getCorpusProject(variables, 1, false, () => {
          setCurrentPage(1);
        });
      }
    });
  };

  const getCorpusProject = (
    variables,
    page,
    isFetchMore = false,
    callback = null
  ) => {
    setIsGetProject(true);
    getCorpusProjectQuery(variables).then((res) => {
      setIsGetProject(false);
      if (res.data.projects) {
        let pageInfo = res.data.projects.pageInfo;
        let projects = res.data.projects.edges.map((item, index) => {
          return item.node;
        });

        setProjectListPageInfo({
          ...projectListPageInfo,
          pageInfo,
          count: res.data.projects.count,
        });
        if (page === 1) {
          let item = {
            id: "",
            title: "",
            from: "",
          };
          projects.unshift(item);
        }
        // if (isFetchMore) {
        //   let projectTemp = projectList.map((item) => {
        //     return item;
        //   });
        //   projects = projectTemp.concat(projects);
        // } else {
        //   let item = {
        //     id: "",
        //     title: "",
        //     from: "",
        //   };
        //   projects.unshift(item);
        // }
        setProjectList(projects);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取项目失败");
      }
    });
  };

  const getTypes = () => {
    let types = [];
    if (selectedTabIndex === 0) {
      types = [ProjectType.Golden, ProjectType.Wiki];
    } else if (selectedTabIndex === 1) {
      types = [ProjectType.Golden];
    } else {
      types = [ProjectType.Wiki];
    }
    return types;
  };

  const searchProject = (searchString) => {
    let variables = {
      after: undefined,
      first: FIRST_PAGE_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString !== "" ? searchString : undefined,
      order: SortOrder.Desc,
      filter: {
        types: getTypes(),
      },
    };

    setSearchString(searchString);
    getCorpusProject(variables, 1, false, () => {
      setCurrentPage(1);
    });
  };

  const searchProjectRef = React.useRef(searchProject);
  searchProjectRef.current = searchProject;

  React.useEffect(() => {
    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();
    if (searchArray && searchArray.category) {
      switch (searchArray.category) {
        case "all":
          setSelectedTabIndex(0);
          break;
        case "wiki":
          setSelectedTabIndex(2);
          break;
        case "golden":
          setSelectedTabIndex(1);
          break;
        default:
          setSelectedTabIndex(0);
          window.history.pushState(
            null,
            "",
            "/corpus/project/list?category=all"
          );
          break;
      }
    } else {
      setSelectedTabIndex(0);
      window.history.pushState(null, "", "/corpus/project/list?category=all");
    }
    setIsInit(true);
    setHeaderContent(renderHeaderContent);
  }, []);

  React.useEffect(() => {
    if (isInit) {
      // setSearchCallback(searchProject);
      let variables = {
        after: undefined,
        first: FIRST_PAGE_COUNT,
        before: undefined,
        last: undefined,
        matching: searchString !== "" ? searchString : undefined,
        order: SortOrder.Desc,
        filter: {
          types: getTypes(),
        },
      };
      getCorpusProject(variables, 1, false, () => {
        setCurrentPage(1);
      });
    }
  }, [isInit, selectedTabIndex]);

  const renderHeaderContent = () => {
    const categories = [
      {
        name: "项目",
      },
      {
        name: "卡片",
      },
    ];

    return (
      <div className="corpus-header-container">
        <div className="corpus-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"corpus-header-" + index}
                className={
                  index === 0
                    ? "corpus-header-tab-item-selected"
                    : "corpus-header-tab-item"
                }
                onClick={() => {
                  if (index !== 0) {
                    history.push("/corpus/cards/data-list");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        <SearchWithDrop
          position={{ right: "25px" }}
          callback={(index, string) => {
            if (index === "当前页面") {
              searchProjectRef.current(string);
            }
            if (index === "全部搜索") {
              openGlobalSearchRef.current(string);
            }
          }}
          selectValues={[
            {
              value: "当前页面",
            },
            {
              value: "全部搜索",
            },
          ]}
        />
      </div>
    );
  };

  return (
    <div className="corpus-project-container">
      <div className="corpus-project-tab-container">
        {projectTab.map((item, index) => {
          let isSelected = "";
          if (index === selectedTabIndex) {
            isSelected = "-selected";
          }
          return (
            <div
              key={"container-" + index}
              className={"corpus-project-tab-item-container" + isSelected}
              onClick={() => {
                if (index !== selectedTabIndex) {
                  setSelectedTabIndex(index);
                  window.history.pushState(
                    null,
                    "",
                    `/corpus/project/list?category=${tabs[index]}`
                  );
                }
              }}
            >
              <div className={"corpus-project-tab-item-text" + isSelected}>
                {item}
              </div>
            </div>
          );
        })}
      </div>

      <div
        className="corpus-project-list-container"
        // onScroll={(e) => {
        //   const { clientHeight, scrollHeight, scrollTop } = e.target;
        //   const isBottom = scrollTop + clientHeight + 20 > scrollHeight;
        //   if (
        //     !isGetProject &&
        //     projectListPageInfo &&
        //     projectListPageInfo.hasNextPage &&
        //     isBottom
        //   ) {
        //     let variables = {
        //       after: projectListPageInfo.endCursor,
        //       first: FIRST_PAGE_COUNT + 1,
        //       before: undefined,
        //       last: undefined,
        //       matching: searchString !== "" ? searchString : undefined,
        //       order: SortOrder.Desc,
        //       filter: {
        //         types: getTypes(),
        //       },
        //     };
        //     getCorpusProject(variables, true);
        //   }
        // }}
      >
        <Spin spinning={isGetProject}>
          {projectList.length > 0 ? (
            <Row gutter={[24, 32]}>
              {projectList.map((item, index) => {
                return (
                  <Col span={8} key={item.id}>
                    <ProjectItem
                      projectInfo={item}
                      setFile={setFile}
                      setSelectedProject={setSelectedProject}
                      selectedTabIndex={selectedTabIndex}
                      projectClick={() => {
                        // projectClickCallback(item);
                        history.push(
                          "/corpus/project/detail/p/" +
                            window.atob(item.code).split("Project:")[1]
                        );
                      }}
                      createGoldenCallback={() => {
                        createProject(getProjectTempName(), ProjectType.Golden);
                      }}
                      createWikiCallback={() => {
                        createProject(getProjectTempName(), ProjectType.Wiki);
                      }}
                      modifyNameCallback={() => {
                        setIsShowCreateModal(true);
                      }}
                      deleteCallback={() => {
                        setIsShowReconfirmModal(true);
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <EmptyComponent tip={"暂时没有项目"} />
          )}
        </Spin>
        <div className="corpus-cards-list-content-pagination">
          <Pagination
            pageSize={OTHER_PAGE_COUNT}
            itemRender={renderPagination}
            current={currentPage}
            total={
              searchString === ""
                ? projectListPageInfo.count + 1
                : projectListPageInfo.count
            }
            hideOnSinglePage
            onChange={(page) => {
              let pageInfo = {
                ...projectListPageInfo,
                pageSize: page === 1 ? FIRST_PAGE_COUNT : OTHER_PAGE_COUNT,
              };
              let variables = getPaginationPageVariable(
                page,
                currentPage,
                pageInfo
              );
              variables = {
                ...variables,
                matching: searchString !== "" ? searchString : undefined,
                types: getTypes(),
              };
              getCorpusProject(variables, page, false, () => {
                setCurrentPage(page);
              });
            }}
          />
        </div>
      </div>
      <ReconfirmModal
        isShow={isShowReconfirmModal}
        title="删除项目"
        content={"是否删除项目：" + selectedProject.title}
        cancelCallback={() => {
          setIsShowReconfirmModal(false);
        }}
        submitCallback={() => {
          setIsShowReconfirmModal(false);
          let input = {
            id: window.atob(selectedProject.id).split("Project:")[1],
          };
          deleteProject(input, (id) => {
            let projectListTemp = projectList.filter((item) => {
              if (item.id === id) {
                return false;
              }
              return true;
            });
            setProjectList(projectListTemp);
          });
        }}
      />
      <CreateModal
        originName={selectedProject.title}
        title="修改名称"
        tip="请输入项目名称"
        showModal={isShowCreateModal}
        handleOnOk={(name) => {
          setIsShowCreateModal(false);
          let input = {
            id: window.atob(selectedProject.id).split("Project:")[1],
            title: name,
          };
          updateProject(input, (project) => {
            setFile(null);
            let projectListTemp = projectList.map((item) => {
              if (item.id === project.id) {
                item.title = name;
              }
              return item;
            });
            setProjectList(projectListTemp);
          });
        }}
        handleOnCancel={() => {
          setIsShowCreateModal(false);
        }}
      />
      <CropperModal
        file={file}
        successCallback={handleOnCoverImg}
        aspectRatio={345 / 120}
        titleText={"裁剪封面"}
        okText={"设置封面"}
        isSetMaxSize={true}
        maxSize={{ width: 345 * 3 }}
      />
    </div>
  );
};

export default CorpusProjectListContainer;
