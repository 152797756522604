import * as React from "react";
import "../aw-check.css";

//引入的其他页面组件
import BubbleBox from "../../Components/BubbleBox/index";

//引入的库组件
import { Menu, Dropdown, Spin, message, Tooltip } from "antd";

//graphql查询
import { usePickMetaDiscoursesQuery } from "../../../generated/graphql";

//引入的资源图片
import MetadiscourseIcon from "../../../assets/writing/metadiscourse-icon.png";
import MetadiscourseIconSelected from "../../../assets/writing/metadiscourse-icon-selected.png";

const WordMeta = (props) => {
  const {
    highlightWords,
    setHighlightWords,
    imgStyle,
    currentCardIds,
    disabled,
  } = props;

  const [isMarked, setIsMarked] = React.useState(false);

  const { refetch: getWordMetaQuery } = usePickMetaDiscoursesQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    if (
      isMarked &&
      (!highlightWords.length || highlightWords[0].type !== "mark-word-meta")
    ) {
      setIsMarked(false);
    }
  }, [highlightWords]);

  const getWordMetaData = () => {
    if (isMarked) {
      setHighlightWords([{ type: "mark-clear-all" }]);
    } else {
      setIsMarked(true);
      getWordMetaQuery({ ids: currentCardIds }).then((res) => {
        if (res.errors) {
          message.error("元话语信息获取失败");
        } else {
          let metadiscourses = res.data.pickMetadiscourses.metadiscourses;
          if (metadiscourses.length) {
            let dataObjs = {};
            metadiscourses.map((item, index) => {
              let metaWord = item.word;
              let examples = item.examples;
              let category = item.category;
              item.locations.map((locationItem, locationItemIndex) => {
                let wordInfo = {
                  metaWord,
                  examples,
                  category,
                  start: locationItem.start,
                  end: locationItem.end,
                };
                if (dataObjs[item.cardId]) {
                  wordInfo.className = `aw-mark-word-meta ${item.cardId}&&${
                    dataObjs[item.cardId].wordInfo.length
                  }`;
                  wordInfo.id = `aw-mark-word-meta-${item.cardId}&&${
                    dataObjs[item.cardId].wordInfo.length
                  }`;
                  dataObjs[item.cardId].wordInfo.push(wordInfo);
                } else {
                  wordInfo.className = `aw-mark-word-meta ${item.cardId}&&${0}`;
                  wordInfo.id = `aw-mark-word-meta-${item.cardId}&&${0}`;
                  dataObjs[item.cardId] = {
                    cardId: item.cardId,
                    type: "mark-word-meta",
                    wordInfo: [wordInfo],
                  };
                }
              });
            });

            let datas = Object.keys(dataObjs).map((item) => {
              return dataObjs[item];
            });
            setHighlightWords(datas);
          } else {
            message.warn("没有元话语信息");
            setHighlightWords([{ type: "mark-clear-all" }]);
          }
        }
      });
    }
  };

  return (
    <Tooltip placement="bottom" title={"元话语标注"}>
      <img
        onClick={() => {
          if (!disabled) {
            getWordMetaData();
          }
        }}
        style={imgStyle}
        src={
          highlightWords.length && highlightWords[0].type === "mark-word-meta"
            ? MetadiscourseIconSelected
            : MetadiscourseIcon
        }
      />
    </Tooltip>
  );
};

export default WordMeta;
