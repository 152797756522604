import React, { useEffect, useState } from "react";
import WANGXIAOBO from '../../../assets/xiaobo.png';
import YUGUANGZHONG from '../../../assets/yuguangzhong.png';
import SADNESS from '../../../assets/sadness.png';
import KAFKA from '../../../assets/kafka.png';

const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
};

const getImageUrl = (item) => {
    if (item.title === '卡夫卡的作品') {
        return KAFKA;
    }
    if (item.title === '余光中的作品') {
        return YUGUANGZHONG;
    }
    if (item.title === '王小波的作品') {
        return WANGXIAOBO;
    }
    if (item.title === '忧伤') {
        return SADNESS;
    }
};

const InspirationCube = (props) => {
    const { height, size, cards, image, isRecommended, noCount, showCardList, selectionTags } = props;
    const COLORS = shuffle(['rgb(206,164,164)', '#A5C0DB', 'rgba(195,167,146,0.65)', '#99bdb3']);
    return (
        <div style={{ fontWeight: 'bold', color: 'white', textAlign: 'center', display: 'flex', paddingLeft: '6%', marginBottom: 30 }}>
            {
                selectionTags && selectionTags.length && selectionTags.map((c, i) =>{
                    return <div
                        key={c.id}
                        onClick={() => {
                            showCardList(c, {});
                        }}
                        style={{
                            width: '22%',
                            cursor: 'pointer',
                            justifyContent: 'space-around',
                            paddingTop: size ? height / 2.5 : 15,
                            height,
                            display: 'flex',
                            paddingLeft: size ? 0 : '3%',
                            background: isRecommended ? `url(${getImageUrl(c)})` : COLORS[i],
                            marginRight: '2%',
                            borderRadius:10 }}>
                        <section style={{ width: '84%' }}>
                            <p style={{
                                width: '100%',
                                textOverflow : 'ellipsis',
                                whiteSpace : 'nowrap',
                                overflow : 'hidden',
                                fontSize: size,
                                marginBottom: 0 }}># {c.title} #</p>
                            {!noCount && <p style={{  marginBottom: 0 }}><img />{c.cardCounts || 0 } 张</p>}
                        </section>
                        {image && <img style={{ marginRight: -3, height: 50, marginTop: 10 }} src={image} />}
                    </div>
                })
            }
        </div>
    )
};

export default  InspirationCube;
