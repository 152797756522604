import * as React from "react";
import "./styles.css";

//引入的库组件
import Resizable from "react-component-resizable";

//引入的其他页面组件

//引入的资源图片

const MarkHoverTipModal = (props) => {
  const { children, position, componentId } = props;

  const [currentComponentId, setCurrentComponentId] = React.useState(
    (componentId ? componentId + "-" : "") + "mark-hover-tip-modal-container"
  );
  const [currentPosition, setCurrentPosition] = React.useState({
    left: position.left,
    top: position.top,
  });
  const [isReserve, setIsReserve] = React.useState(false);
  const [lastHeight, setLastHeight] = React.useState(0);

  React.useEffect(() => {
    const currentElement = document.getElementById(currentComponentId);
    let positionTemp = { left: position.left, top: position.top };
    setTimeout(() => {
      const currentElement = document.getElementById(currentComponentId);
      if (currentElement) {
        let currentElementHeight = 0;
        let bodyElementHeight = 0;
        const bodyElement = currentElement.offsetParent;
        if (currentElement) currentElementHeight = currentElement.offsetHeight;
        if (bodyElement) bodyElementHeight = bodyElement.offsetHeight;
        if (bodyElementHeight !== 0 && currentElementHeight !== 0) {
          if (
            positionTemp.top + currentElementHeight + 20 > bodyElementHeight &&
            positionTemp.top - currentElementHeight > 0
          ) {
            positionTemp.top -= currentElementHeight + 20;
            setIsReserve(true);
          } else {
            positionTemp.top += 10;
            setIsReserve(false);
          }
        }
        setLastHeight(currentElementHeight);
        setCurrentPosition(positionTemp);
      }
    }, 10);
  }, [position]);

  const onResize = (e) => {
    const currentElement = document.getElementById(currentComponentId);
    if (currentElement) {
      let positionTemp = { left: position.left, top: position.top };
      if (currentElement) {
        let currentElementHeight = 0;
        let bodyElementHeight = 0;
        const bodyElement = currentElement.offsetParent;
        if (currentElement) currentElementHeight = currentElement.offsetHeight;
        if (bodyElement) bodyElementHeight = bodyElement.offsetHeight;
        if (bodyElementHeight !== 0 && currentElementHeight !== 0) {
          if (
            positionTemp.top + currentElementHeight + 20 > bodyElementHeight &&
            positionTemp.top - currentElementHeight > 0
          ) {
            positionTemp.top -= currentElementHeight + 20;
            setIsReserve(true);
          } else {
            positionTemp.top += 10;
            setIsReserve(false);
          }
        }
        setLastHeight(currentElementHeight);
        setCurrentPosition(positionTemp);
      }
    }
  };

  return (
    // <div
    //   style={{ left: currentPosition.left, top: currentPosition.top + 7 }}
    //   id={currentComponentId}
    //   className={
    //     isReserve
    //       ? "mark-hover-tip-modal-container-reserve"
    //       : "mark-hover-tip-modal-container"
    //   }
    // >
    //   {children}
    // </div>

    <Resizable
      id={currentComponentId}
      style={{ left: currentPosition.left, top: currentPosition.top + 7 }}
      className={
        isReserve
          ? "mark-hover-tip-modal-container-reserve"
          : "mark-hover-tip-modal-container"
      }
      onResize={onResize}
    >
      {children}
    </Resizable>
  );
};

export default MarkHoverTipModal;
