import gql from "graphql-tag";

export const GET_MAIN_DATA = gql`
  {
    titleIndexClient @client(always: true)
    siderSelectedIndexClient @client(always: true)
    pageSelectedIndexClient @client(always: true)
  }
`;

export const SET_MAIN_DATA = gql`
  {
    siderSelectedIndexClient @client
    titleIndexClient @client
    pageSelectedIndexClient @client
  }
`;

export const SET_DRAFT_DATA = gql`
  {
    draftInfoClient @client
  }
`;

export const GET_DRAFT_DATA = gql`
  {
     draftInfoClient @client
  }
`;

export const GET_USER_INFO = gql`
  {
    userInfoClient @client
  }
`;
