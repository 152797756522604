import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import "./styles.css";
import { html2Escape } from "../../Router_Writing/utils/main";

class MyReactMarkdown extends Component {
  renderBlockquote(props) {
    return (
      <blockquote
        {...props}
        style={{
          borderLeft: ".25em solid #dfe2e5",
          padding: "0 1em",
          color: "#6a737d",
        }}
      />
    );
  }
  renderPraphgraph(props) {
    return (
      <pre>
        <p
          {...props}
          style={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}
        ></p>
      </pre>
    );
  }
  renderLink(props) {
    return <a {...props} target="_blank" alt="tu"></a>;
  }
  renderImage(props) {
    return <img {...props} style={{ maxWidth: "100%" }} />;
  }

  renderCode(props) {
    return (
      <pre style={{ whiteSpace: "pre-wrap" }}>
        <code>{props.value}</code>
      </pre>
    );
  }

  renderTable(props) {
    return (
      <table
        {...props}
        style={{ borderCollapse: "collapse", borderSpacing: 1, width: "100%" }}
      />
    );
  }

  renderUl(props) {
    return (
      <ul
        {...props}
        style={{ wordWrap: "break-word", wordBreak: "break-all" }}
      />
    );
  }
  renderP(props) {
    return (
      <ul
        {...props}
        style={{ wordWrap: "break-word", wordBreak: "break-all" }}
      />
    );
  }

  render() {
    const { source, className } = this.props;
    return (
      <ReactMarkdown
        className={className}
        source={html2Escape(source)}
        skipHtml={false}
        escapeHtml={false}
        parserOptions={{ commonmark: true }}
        renderers={{
          table: this.renderTable,
          image: this.renderImage,
          code: this.renderCode,
          ul: this.renderUl,
          paragraph: this.renderPraphgraph,
          link: this.renderLink,
          blockquote: this.renderBlockquote,
        }}
      />
    );
  }
}

export default MyReactMarkdown;
