import * as React from "react";
import "./styles.css";

//引入的其他页面组件
import ReconfirmModal from "../../Components/ReconfirmModal";
import { getOutlineIcon } from "../WritingOutline/getOutlineIcon";
import { copyObject } from "../utils";
import { TimeStampToDate } from "../../../utils/index";

//引入的库组件
import { Dropdown, Menu, message } from "antd";

//graphql查询
import {
  useGetCardCountQuery,
  useGetCardsQuery,
  useUpdateProjectMutation,
  CardType,
} from "../../../generated/graphql";

//引入的资源图片
import RightArrow from "../../../assets/writing/arrow-right.png";
import IconDefault from "../../../assets/writing/outline_icon/icon-default.png";
import IconDefaultMulti from "../../../assets/writing/outline_icon/icon-default-multi.png";

const ICON_INFO = [
  {
    title: "默认",
    key: "icon-default",
    src: getOutlineIcon("icon-default"),
  },
  {
    title: "分割线",
    key: "divider",
    src: "",
  },
  {
    title: "文件夹",
    key: "icon-folder",
    src: getOutlineIcon("icon-folder"),
  },
  {
    title: "手稿",
    key: "icon-menuscript",
    src: getOutlineIcon("icon-menuscript"),
  },
  {
    title: "资料",
    key: "icon-data",
    src: getOutlineIcon("icon-data"),
  },
  {
    title: "灵感",
    key: "icon-inspiration",
    src: getOutlineIcon("icon-inspiration"),
  },
  {
    title: "分割线",
    key: "divider",
    src: "",
  },
  {
    title: "todo",
    key: "icon-todo",
    src: getOutlineIcon("icon-todo"),
  },
  {
    title: "doing",
    key: "icon-doing",
    src: getOutlineIcon("icon-doing"),
  },
  {
    title: "done",
    key: "icon-done",
    src: getOutlineIcon("icon-done"),
  },
  {
    title: "分割线",
    key: "divider",
    src: "",
  },
  {
    title: "标记1",
    key: "icon-mark1",
    src: getOutlineIcon("icon-mark1"),
  },
  {
    title: "标记2",
    key: "icon-mark2",
    src: getOutlineIcon("icon-mark2"),
  },
  {
    title: "标记3",
    key: "icon-mark3",
    src: getOutlineIcon("icon-mark3"),
  },
  {
    title: "分割线",
    key: "divider",
    src: "",
  },
  {
    title: "人物",
    key: "icon-person",
    src: getOutlineIcon("icon-person"),
  },
  {
    title: "对白",
    key: "icon-dialog",
    src: getOutlineIcon("icon-dialog"),
  },
  {
    title: "时间线",
    key: "icon-timeline",
    src: getOutlineIcon("icon-timeline"),
  },
  {
    title: "分割线",
    key: "divider",
    src: "",
  },
  {
    title: "1",
    key: "icon-one",
    src: getOutlineIcon("icon-one"),
  },
  {
    title: "2",
    key: "icon-two",
    src: getOutlineIcon("icon-two"),
  },
  {
    title: "3",
    key: "icon-three",
    src: getOutlineIcon("icon-three"),
  },
  {
    title: "4",
    key: "icon-four",
    src: getOutlineIcon("icon-four"),
  },
  {
    title: "5",
    key: "icon-five",
    src: getOutlineIcon("icon-five"),
  },
];

const hideRightClickMenu = () => {
  const rightClickMenu = document.getElementsByClassName("right-click-menu")[0];
  if (rightClickMenu) {
    rightClickMenu.style.left = "-10000px";
    rightClickMenu.style.top = "-10000px";
  }
};

const WritingRightClickMenu = (props) => {
  const {
    isNetworkOnline,
    projectInfo,
    cardsInfo,
    isInTrash,
    isProjectNode,
    xPos,
    yPos,
    currentSelectCardId,
    deleteCardInMenu,
    renameTitle,
    expandAll,
    unexpandAll,
    addCardInMenu,
    splitCard,
    copyCardInMenu,
    copyCardUrl,
    exportCardCallback,
    changeOutlineIcon,
  } = props;

  const { refetch: getCardCountQuery } = useGetCardCountQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getCardsInfoQuery } = useGetCardsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const [updateProjectMutation] = useUpdateProjectMutation();

  const [showConfirm, setShowConfirm] = React.useState(false);

  if (isInTrash) {
    if (!isNetworkOnline) {
      return null;
    }
    return (
      <div
        className="right-click-menu"
        onContextMenu={(e) => e.preventDefault()}
        style={{
          left: xPos,
          top: yPos,
          display: xPos ? "block" : "none",
        }}
      >
        {!isProjectNode && (
          <div
            className="right-click-menu-li"
            onClick={() => {
              setShowConfirm(true);
              hideRightClickMenu();
            }}
          >
            {currentSelectCardId === "recycle-node" ? "清空废纸篓" : "彻底删除"}
          </div>
        )}
        <ReconfirmModal
          isShow={showConfirm}
          title="确认删除"
          content={"数据一旦清除，将无法找回，确认删除吗？"}
          cancelCallback={() => {
            setShowConfirm(false);
          }}
          submitCallback={() => {
            setShowConfirm(false);
            deleteCardInMenu(currentSelectCardId);
          }}
        />
      </div>
    );
  }

  const importCard = () => {
    const element = document.getElementById("button-import");
    if (element) {
      element.click();
    }
  };
  const exportCard = () => {
    if (exportCardCallback) {
      exportCardCallback(true);
    }
  };

  const getCardCount = async () => {
    let count = 0;
    let res = await getCardCountQuery({
      first: 1,
      filter: { types: [CardType.User] },
      projectId: projectInfo.id,
    });
    if (!res.data.cards) {
      message.error("修复失败，请重新尝试");
    } else {
      count = res.data.cards.count;
    }
    return count;
  };

  const getAllCardId = async (cardCount) => {
    let cards = [];
    let res = await getCardCountQuery({
      first: cardCount,
      filter: { types: [CardType.User] },
      projectId: projectInfo.id,
    });
    if (!res.data.cards) {
      message.error("修复失败，请重新尝试");
    } else {
      cards = res.data.cards.edges.map((item) => {
        return {
          id: window.atob(item.node.id).split("Card:")[1],
          title: item.node.title,
        };
      });
    }
    return cards;
  };

  const repairOutline = async () => {
    let cardCount = 0;
    let cards = [];
    let lostCards = [];
    let surplusCards = [];
    let isNeedRepaired = false;
    let projectInfoTemp = copyObject(projectInfo);

    cardCount = await getCardCount();
    if (cardCount) {
      cards = await getAllCardId(cardCount);
    }
    if (cards.length) {
      let cardsIdFromOutline = [];
      let cardsIdsFromSever = cards.map((item) => {
        return item.id;
      });
      //获取大纲中全部的节点id
      const getCardIdFromOutline = (children) => {
        for (let i = 0; i < children.length; i++) {
          let child = children[i];
          cardsIdFromOutline.push(child.id);
          if (child.children && child.children.length) {
            getCardIdFromOutline(child.children);
          }
        }
      };
      getCardIdFromOutline(projectInfoTemp.outline.menu);

      //获取到大纲数据后，检查大纲数据是否有重复的id， 有的话删除其中一个看起来是错误的（主观臆断，以节点下子节点数量来看）
      let repeatIds = [];
      const checkRepeatOutlineNode = (ids) => {
        for (let i = 0; i < ids.length; i++) {
          for (let j = i + 1; j < ids.length; j++) {
            if (ids[i] === ids[j] && repeatIds.indexOf(ids[i]) === -1) {
              repeatIds.push(ids[i]);
              break;
            }
          }
        }
      };
      checkRepeatOutlineNode(cardsIdFromOutline);

      if (repeatIds.length) {
        const removeRepeatOutline = (repeatId) => {
          let repeatItems = [];
          const checkRepeatDatas = (outline) => {
            for (let i = 0; i < outline.length; i++) {
              let item = outline[i];
              if (repeatId !== item.id) {
                if (item.children && item.children.length) {
                  checkRepeatDatas(item.children);
                }
              } else {
                repeatItems.push({ data: outline, item: item, index: i });
              }
            }
          };
          checkRepeatDatas(projectInfoTemp.outline.menu);
          let itemTemp = null;
          for (let i = repeatItems.length - 1; i >= 0; i--) {
            if (!itemTemp) {
              itemTemp = repeatItems[i];
            } else {
              if (
                itemTemp.item.children.length >
                repeatItems[i].item.children.length
              ) {
                repeatItems[i].data.splice(repeatItems[i].index, 1);
              } else {
                itemTemp.data.splice(itemTemp.index, 1);
                itemTemp = repeatItems[i];
              }
            }
          }
        };
        for (let i = 0; i < repeatIds.length; i++) {
          let repeatId = repeatIds[i];
          removeRepeatOutline(repeatId);
        }
        isNeedRepaired = true;
      }

      //删除大纲中存在，卡片库中不存在的节点
      surplusCards = cardsIdFromOutline.filter((item) => {
        if (
          item === projectInfoTemp.originalId ||
          item === "recycle-node" ||
          !item
        ) {
          return false;
        } else {
          return cardsIdsFromSever.indexOf(item) === -1;
        }
      });
      if (surplusCards.length) {
        const removeSurplusCardsOutline = (surplusCard) => {
          let surplusItems = [];
          const checkSurplusDatas = (outline) => {
            for (let i = 0; i < outline.length; i++) {
              let item = outline[i];
              if (surplusCard !== item.id) {
                if (item.children && item.children.length) {
                  checkSurplusDatas(item.children);
                }
              } else {
                surplusItems.push({ data: outline, item: item, index: i });
              }
            }
          };
          checkSurplusDatas(projectInfoTemp.outline.menu);
          for (let i = surplusItems.length - 1; i >= 0; i--) {
            let itemTemp = surplusItems[i];
            itemTemp.data.splice(itemTemp.index, 1);
          }
        };
        for (let i = 0; i < surplusCards.length; i++) {
          let surplusCard = surplusCards[i];
          removeSurplusCardsOutline(surplusCard);
        }
        isNeedRepaired = true;
      }

      //补充卡片库中存在，大纲中不存在的节点
      lostCards = cards.filter((item) => {
        return cardsIdFromOutline.indexOf(item.id) === -1;
      });
      if (lostCards.length) {
        if (
          projectInfoTemp.outline.menu.length === 0 ||
          projectInfoTemp.outline.menu[0].id !== projectInfoTemp.originalId
        ) {
          projectInfoTemp.outline.menu[0] = {
            title: projectInfoTemp.title,
            icon: "icon-default",
            parent: null,
            children: [],
          };
        }
        if (!projectInfoTemp.outline.menu[0].children) {
          projectInfoTemp.outline.menu[0].children = [];
        }
        lostCards.forEach((item, index) => {
          projectInfoTemp.outline.menu[0].children.push({
            title: item.title,
            id: item.id,
            expanded: false,
            icon: "icon-default",
            children: [],
          });
        });
        isNeedRepaired = true;
      }
    }
    if (!isNeedRepaired) {
      message.success("大纲数据完整，无需修复");
    } else {
      projectInfoTemp.outlineState.outlineUpdateAt = TimeStampToDate(
        Date.now()
      );
      const input = {
        id: projectInfoTemp.originalId,
        outline: JSON.stringify(projectInfoTemp.outline),
        outlineState: JSON.stringify(projectInfoTemp.outlineState),
      };
      const res = await updateProjectMutation({ variables: { input } });
      message.success("大纲数据修复完成，重新加载页面");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const renderProjectNodeContent = () => {
    return (
      <>
        {isNetworkOnline && (
          <div
            className="right-click-menu-li"
            onClick={() => addCardInMenu(false)}
          >
            新建卡片
          </div>
        )}

        <div
          className="right-click-menu-li"
          onClick={() => {
            renameTitle(currentSelectCardId);
          }}
        >
          重命名项目
        </div>
        <div className="right-click-menu-li" onClick={expandAll}>
          展开全部
        </div>
        <div className="right-click-menu-li" onClick={unexpandAll}>
          折叠全部
        </div>
        {isNetworkOnline && (
          <div className="right-click-menu-li" onClick={repairOutline}>
            修复大纲
          </div>
        )}
      </>
    );
  };

  const splitOverlay = () => {
    return (
      <Menu
        id="splitOverlay"
        className="right-click-split-container"
        onClick={(e) => {
          switch (e.key) {
            case "0":
              splitCard("sentence");
              break;
            case "1":
              splitCard("paragraph");
              break;
            case "2":
              splitCard("heading");
              break;
          }
        }}
      >
        <Menu.Item key={"0"} className="right-click-split-item-container">
          按照句子拆分
        </Menu.Item>
        <Menu.Item key={"1"} className="right-click-split-item-container">
          按照段落拆分
        </Menu.Item>
        <Menu.Item key={"2"} className="right-click-split-item-container">
          按照标题拆分
        </Menu.Item>
      </Menu>
    );
  };

  //修正侧拉窗口位置
  const splitOverlayVisibleChange = (visible) => {
    if (visible) {
      let element = document.getElementById("splitOverlay");
      if (element && element.parentNode) {
        if (parseInt(element.parentNode.style.top) > 0) {
          element.parentNode.style.top = "-5px";
        } else {
          element.parentNode.style.top = `${35 -
            element.parentNode.offsetHeight}px`;
        }
      }
    }
  };

  const moreOverlay = () => {
    return (
      <Menu
        id="moreOverlay"
        className="right-click-split-container"
        onClick={(e) => {
          switch (e.key) {
            case "0":
              importCard();
              break;
            case "1":
              exportCard();
              break;
            case "2":
              copyCardUrl();
              break;
          }
        }}
      >
        {isNetworkOnline && (
          <Menu.Item key={"0"} className="right-click-split-item-container">
            导入
          </Menu.Item>
        )}
        {isNetworkOnline && (
          <Menu.Item key={"1"} className="right-click-split-item-container">
            导出
          </Menu.Item>
        )}
        {isNetworkOnline && <Menu.Divider />}

        <Menu.Item key={"2"} className="right-click-split-item-container">
          复制卡片链接
        </Menu.Item>
      </Menu>
    );
  };

  //修正侧拉窗口位置
  const moreOverlayVisibleChange = (visible) => {
    if (visible) {
      let element = document.getElementById("moreOverlay");
      if (element && element.parentNode) {
        if (parseInt(element.parentNode.style.top) > 0) {
          element.parentNode.style.top = "-5px";
        } else {
          element.parentNode.style.top = `${35 -
            element.parentNode.offsetHeight}px`;
        }
      }
    }
  };

  const changeOutlineIconOverlay = () => {
    return (
      <Menu
        id="ChangeOutlineIconOverlay"
        className="right-click-menu-change-outline-icon-container"
        onClick={(e) => {
          if (changeOutlineIcon) {
            changeOutlineIcon(e.key);
          }
        }}
      >
        {ICON_INFO.map((item, index) => {
          if (item.key === "divider") {
            return (
              <div
                key={`${item.key}-${index}`}
                className="right-click-menu-divider"
              />
            );
          } else {
            return (
              <Menu.Item
                key={item.key}
                className="right-click-menu-change-outline-icon-item-container"
              >
                <img
                  src={item.src}
                  className="right-click-menu-change-outline-icon-img"
                />
                <div className="right-click-menu-change-outline-icon-title">
                  {item.title}
                </div>
              </Menu.Item>
            );
          }
        })}
      </Menu>
    );
  };

  //修正侧拉窗口位置
  const changeOutlineIconOverlayVisibleChange = (visible) => {
    if (visible) {
      let element = document.getElementById("ChangeOutlineIconOverlay");
      if (element && element.parentNode) {
        if (parseInt(element.parentNode.style.top) < -590) {
          element.parentNode.style.top = `${-590}px`;
        }
      }
    }
  };

  const renderNoProjectNodeContent = () => {
    return (
      <>
        {isNetworkOnline && (
          <>
            <div
              className="right-click-menu-li"
              onClick={() => addCardInMenu(true)}
            >
              新建同级卡片
            </div>
            <div
              className="right-click-menu-li"
              onClick={() => addCardInMenu(false)}
            >
              新建子卡片
            </div>
            <div
              className="right-click-menu-li"
              onClick={() => {
                const buttonInsert = document.getElementById("button-insert");
                if (buttonInsert) buttonInsert.click();
              }}
            >
              插入卡片
            </div>
            <div className="right-click-menu-divider" />
          </>
        )}

        <div
          className="right-click-menu-li"
          onClick={() => {
            renameTitle(currentSelectCardId);
          }}
        >
          重命名
        </div>
        {isNetworkOnline && (
          <>
            <div
              className="right-click-menu-li"
              onClick={() => {
                copyCardInMenu();
              }}
            >
              复制
            </div>
            <div
              className="right-click-menu-li"
              onClick={() => {
                deleteCardInMenu(currentSelectCardId);
              }}
            >
              删除
            </div>
          </>
        )}

        <div className="right-click-menu-divider" />
        {isNetworkOnline && (
          <>
            <Dropdown
              trigger={["hover"]}
              transitionName=""
              placement="bottomCenter"
              overlay={splitOverlay}
              onVisibleChange={splitOverlayVisibleChange}
              getPopupContainer={() =>
                document.getElementById("right-click-split")
              }
            >
              <div id="right-click-split" className="right-click-menu-li">
                拆分卡片
                <img
                  src={RightArrow}
                  style={{ width: "7px", height: "10px" }}
                />
              </div>
            </Dropdown>
            <div className="right-click-menu-divider" />
          </>
        )}

        <div className="right-click-menu-li" onClick={expandAll}>
          展开全部
        </div>
        <div className="right-click-menu-li" onClick={unexpandAll}>
          折叠全部
        </div>
        <div className="right-click-menu-divider" />

        <Dropdown
          trigger={["hover"]}
          transitionName=""
          placement="bottomCenter"
          overlay={changeOutlineIconOverlay}
          onVisibleChange={changeOutlineIconOverlayVisibleChange}
          getPopupContainer={() =>
            document.getElementById("right-click-change")
          }
        >
          <div id="right-click-change" className="right-click-menu-li">
            更改图标
            <img src={RightArrow} style={{ width: "7px", height: "10px" }} />
          </div>
        </Dropdown>
        <div className="right-click-menu-divider" />
        <Dropdown
          trigger={["hover"]}
          transitionName=""
          placement="bottomCenter"
          overlay={moreOverlay}
          onVisibleChange={moreOverlayVisibleChange}
          getPopupContainer={() => document.getElementById("right-click-more")}
        >
          <div id="right-click-more" className="right-click-menu-li">
            更多操作
            <img src={RightArrow} style={{ width: "7px", height: "10px" }} />
          </div>
        </Dropdown>
        <div className="right-click-menu-divider" />
        {isNetworkOnline && (
          <div className="right-click-menu-li" onClick={repairOutline}>
            修复大纲
          </div>
        )}
      </>
    );
  };

  return (
    <div
      id="right-click-menu"
      className="right-click-menu"
      onContextMenu={(e) => e.preventDefault()}
      style={{
        left: xPos,
        top: yPos,
        display: xPos ? "block" : "none",
      }}
    >
      {isProjectNode && renderProjectNodeContent()}
      {!isProjectNode && renderNoProjectNodeContent()}
    </div>
  );
};

export default WritingRightClickMenu;
