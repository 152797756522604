import { SYSTEM } from "./index";

const CTRL = SYSTEM === "MAC" ? "Command" : "Ctrl";
const ALT = "Alt";
const SHIFT = "Shift";

const A = "A";
const B = "B";
const C = "C";
const D = "D";
const E = "E";
const F = "F";
const G = "G";
const H = "H";
const I = "I";
const J = "J";
const K = "K";
const L = "L";
const M = "M";
const N = "N";
const O = "O";
const P = "P";
const Q = "Q";
const R = "R";
const S = "S";
const T = "T";
const U = "U";
const V = "V";
const W = "W";
const X = "X";
const Y = "Y";
const Z = "Z";
const MINUS = "-";

const makeShortCuts = (...keys) => {
  let shortCuts = "";
  keys.forEach((item, index) => {
    if (index !== keys.length - 1) {
      shortCuts += item + " + ";
    } else {
      shortCuts += item;
    }
  });
  return shortCuts;
};

const SHORTCUTS_SAVE_KEY = makeShortCuts(CTRL, S);
const SHORTCUTS_SAVE = (e) => {
  return (e.ctrlKey || e.metaKey) && e.key === "s";
};
const SHORTCUTS_ADD_CARD_IN_SAME_LEVEL_KEY = makeShortCuts(CTRL, SHIFT, J);
const SHORTCUTS_ADD_CARD_IN_SAME_LEVEL = (e) => {
  return (e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "j";
};

const SHORTCUTS_ADD_CARD_IN_CHILD_KEY = makeShortCuts(CTRL, SHIFT, K);
const SHORTCUTS_ADD_CARD_IN_CHILD = (e) => {
  return (e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "k";
};

const SHORTCUTS_INSERT_CARD_KEY = makeShortCuts(CTRL, SHIFT, I);
const SHORTCUTS_INSERT_CARD = (e) => {
  return (e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "i";
};

const SHORTCUTS_DELETE_CARD_KEY = makeShortCuts(CTRL, SHIFT, D);
const SHORTCUTS_DELETE_CARD = (e) => {
  return (e.ctrlKey || e.metaKey) && e.shiftKey && e.key === "d";
};
const EDITOR_BOLD_KEY = makeShortCuts(CTRL, B);
const EDITOR_BOLD = (e) => {
  return (e.ctrlKey || e.metaKey) && e.code === "KeyB";
};

const EDITOR_ITALIC_KEY = makeShortCuts(CTRL, I);
const EDITOR_ITALIC = (e) => {
  return (e.ctrlKey || e.metaKey) && e.code === "KeyI";
};

const EDITOR_STRIKE_KEY = makeShortCuts(CTRL, MINUS);
const EDITOR_STRIKE = (e) => {
  return (e.ctrlKey || e.metaKey) && e.code === "Minus";
};

const EDITOR_LINE_KEY = makeShortCuts(CTRL, L);
const EDITOR_LINE = (e) => {
  return (e.ctrlKey || e.metaKey) && e.code === "KeyL";
};

const EDITOR_BLOCKQUOTE_KEY = makeShortCuts(ALT, Q);
const EDITOR_BLOCKQUOTE = (e) => {
  return e.altKey && e.code === "KeyQ";
};

const EDITOR_UNORDERED_LIST_KEY = makeShortCuts(ALT, U);
const EDITOR_UNORDERED_LIST = (e) => {
  return e.altKey && e.code === "KeyU";
};

const EDITOR_ORDERED_LIST_KEY = makeShortCuts(ALT, O);
const EDITOR_ORDERED_LIST = (e) => {
  return e.altKey && e.code === "KeyO";
};

const EDITOR_TAST_KEY = makeShortCuts(ALT, T);
const EDITOR_TAST = (e) => {
  return e.altKey && e.code === "KeyT";
};

const SHORTCUTS_FIND_AND_REPLACE = (e) => {
  return (e.ctrlKey || e.metaKey) && e.key === "f";
};

export default {
  SHORTCUTS_SAVE_KEY,
  SHORTCUTS_SAVE,

  SHORTCUTS_ADD_CARD_IN_SAME_LEVEL_KEY,
  SHORTCUTS_ADD_CARD_IN_SAME_LEVEL,

  SHORTCUTS_ADD_CARD_IN_CHILD_KEY,
  SHORTCUTS_ADD_CARD_IN_CHILD,

  SHORTCUTS_INSERT_CARD_KEY,
  SHORTCUTS_INSERT_CARD,

  SHORTCUTS_DELETE_CARD_KEY,
  SHORTCUTS_DELETE_CARD,

  EDITOR_BOLD_KEY,
  EDITOR_BOLD,

  EDITOR_ITALIC_KEY,
  EDITOR_ITALIC,

  EDITOR_STRIKE_KEY,
  EDITOR_STRIKE,

  EDITOR_LINE_KEY,
  EDITOR_LINE,

  EDITOR_BLOCKQUOTE_KEY,
  EDITOR_BLOCKQUOTE,

  EDITOR_UNORDERED_LIST_KEY,
  EDITOR_UNORDERED_LIST,

  EDITOR_ORDERED_LIST_KEY,
  EDITOR_ORDERED_LIST,

  EDITOR_TAST_KEY,
  EDITOR_TAST,

  SHORTCUTS_FIND_AND_REPLACE,
};
