import * as React from "react";
import "../WordMark/styles.css";
import "../aw-check.css";

//引入的其他页面组件
import BubbleBox from "../../Components/BubbleBox/index";

//引入的库组件
import { Menu, Dropdown, Spin, message, Tooltip } from "antd";

//graphql查询
import { useGetLabelCharWordSentQuery } from "../../../generated/graphql";

//引入的资源图片
import WordCloudIcon from "../../../assets/writing/word-cloud-icon.png";
import WordCloudSelectedIcon from "../../../assets/writing/word-cloud-icon-selected.png";

const WORD_CLOUD_BACK_PREFIX = "aw-mark-word-cloud-";

const WordCloud = (props) => {
  const {
    highlightWords,
    setHighlightWords,
    imgStyle,
    currentCardIds,
    disabled
  } = props;

  const [isMouseEnter, setIsMouseEnter] = React.useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [markDatas, setMarkDatas] = React.useState([]);
  const { refetch: getMarkInfo } = useGetLabelCharWordSentQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const onVisibleChange = (visilble) => {
    setIsDropdownVisible(visilble);
    if (visilble) {
      setIsLoading(true);
      getMarkInfo({ ids: currentCardIds }).then((res) => {
        setIsLoading(false);
        if (res.errors) {
          message.error("统计信息获取失败");
        } else {
          let datas = res.data.labelCharWordSent.labels;
          if (datas.length) {
            let wordCloudArray = {};
            datas.forEach((item, index) => {
              let dataWordCloud = item.labelMap.word_cloud;
              let objKeys = Object.keys(dataWordCloud);
              objKeys.forEach((key) => {
                let wordInfo = dataWordCloud[key];
                if (wordCloudArray[key]) {
                  wordCloudArray[key].count += wordInfo.length;
                  wordCloudArray[key].info.push({
                    type: "mark-word-cloud",
                    cardId: item.cardId,
                    wordInfo: wordInfo,
                  });
                } else {
                  wordCloudArray[key] = {
                    count: wordInfo.length,
                    info: [
                      {
                        type: "mark-word-cloud",
                        cardId: item.cardId,
                        wordInfo: wordInfo,
                      },
                    ],
                  };
                }
              });
            });

            let keySortArray = Object.keys(wordCloudArray).sort((a, b) => {
              return wordCloudArray[b].count - wordCloudArray[a].count;
            });

            let markDatasTemp = [];
            keySortArray.forEach((item, index) => {
              if (index < 10) {
                let info = wordCloudArray[item].info;
                info.forEach((infoItem) => {
                  infoItem.wordInfo = infoItem.wordInfo.map((posItem) => {
                    return {
                      ...posItem,
                      className: WORD_CLOUD_BACK_PREFIX + index,
                    };
                  });
                });
                markDatasTemp.push({
                  typeName: item,
                  colorClassName: WORD_CLOUD_BACK_PREFIX + index,
                  ...wordCloudArray[item],
                });
              }
            });
            setMarkDatas(markDatasTemp);
          } else {
            message.warn("没有统计信息");
          }
        }
      });
    }
  };

  const makeHightlight = (key) => {
    if (key === "mark-clear") {
      setHighlightWords([{ type: "mark-clear-all" }]);
    } else if (key === "mark-all") {
      let data = [];
      markDatas.forEach((item) => {
        data = data.concat(item.info);
      });
      //因为这里在卡片拼接模式的时候，词云前十统计的是所有卡片的信息，所以是获取了全部的卡片平铺成数组，统计的同一个词在全部卡片中出现的数量
      //所以在这里，需要将这个平铺的数据，根据卡片ID组装成按一个卡片一套数组，方便渲染
      let dataObj = {};
      data.forEach((item) => {
        if (dataObj[item.cardId]) {
          dataObj[item.cardId] = {
            ...dataObj[item.cardId],
            wordInfo: dataObj[item.cardId].wordInfo.concat(item.wordInfo),
          };
        } else {
          dataObj[item.cardId] = {
            type: item.type,
            cardId: item.cardId,
            wordInfo: item.wordInfo,
          };
        }
      });

      let dataTemp = [];
      Object.keys(dataObj).forEach((key) => {
        dataTemp.push(dataObj[key]);
      });
      setHighlightWords(dataTemp);
    } else {
      let data = markDatas.filter((item) => {
        return item.typeName === key;
      })[0].info;
      setHighlightWords(data);
    }
  };

  const wordCloudDropDownList = () => {
    if (isLoading) {
      return (
        <Menu className="word-mark-container">
          <Menu.Item className="word-mark-container-loading ">
            <Spin spinning={true} tip="正在统计中" />
          </Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu
        className="word-mark-container"
        onClick={(e) => {
          makeHightlight(e.key);
        }}
      >
        {markDatas.map((item, index) => {
          return (
            <Menu.Item
              key={`${item.typeName}`}
              className="word-mark-sub-item-container"
            >
              <div
                className={"word-mark-sub-item-color " + item.colorClassName}
              />
              <div className="word-mark-sub-item-text">{item.typeName}</div>
            </Menu.Item>
          );
        })}
        <div className="word-mark-divider" />
        <Menu.Item key={"mark-all"} className="word-mark-item-container">
          <div className="word-mark-item-text">全部标注</div>
        </Menu.Item>
        <Menu.Item key={"mark-clear"} className="word-mark-item-container">
          <div className="word-mark-item-text">清空标注</div>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Tooltip
      placement="bottom"
      title={"词云标注"}
      visible={isMouseEnter && !isDropdownVisible}
    >
      <BubbleBox
        contentNode={wordCloudDropDownList}
        onVisibleChange={onVisibleChange}
        disabled={disabled}
      >
        <img
          onMouseEnter={() => {
            setIsMouseEnter(true);
          }}
          onMouseOut={() => {
            setIsMouseEnter(false);
          }}
          style={imgStyle}
          src={
            highlightWords.length &&
            highlightWords[0].type === "mark-word-cloud"
              ? WordCloudSelectedIcon
              : WordCloudIcon
          }
        />
      </BubbleBox>
    </Tooltip>
  );
};

export default WordCloud;
