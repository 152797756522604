import moment from "moment";

export const getPaginationPageVariable = (toPage, currentPage, pageInfo) => {
  let changePages = toPage - currentPage;
  let variable = {};
  if (changePages > 0) {
    let currentLastDataCursor = pageInfo.pageInfo.endCursor;
    let cursorName = window.atob(currentLastDataCursor).split(":")[0];
    let index =
      parseInt(window.atob(currentLastDataCursor).split(":")[1]) +
      (changePages - 1) * pageInfo.pageSize;
    variable = {
      first: pageInfo.pageSize,
      after: window.btoa(cursorName + ":" + index),
      before: undefined,
      last: undefined,
    };
  } else {
    let currentLastDataCursor = pageInfo.pageInfo.startCursor;
    let cursorName = window.atob(currentLastDataCursor).split(":")[0];
    let index =
      parseInt(window.atob(currentLastDataCursor).split(":")[1]) +
      (changePages + 1) * pageInfo.pageSize;
    variable = {
      last: pageInfo.pageSize,
      before: window.btoa(cursorName + ":" + index),
      first: undefined,
      after: undefined,
    };
  }
  return variable;
};

export const checkPhoneNumber = (phone) => {
  /*
   * 判断字符串是否符合手机号码格式
   * 移动号段:   134 135 136 137 138 139 147 148 150 151 152 157 158 159  165 172 178 182 183 184 187 188 198
   * 联通号段:   130 131 132 145 146 155 156 166 170 171 175 176 185 186
   * 电信号段:   133 149 153 170 173 174 177 180 181 189  191  199
   * 虚拟运营商: 170
   * */

  let reg = /^((13[0-9])|(14[5,6,7,9])|(15[^4])|(16[5,6])|(17[0-9])|(18[0-9])|(19[1,8,9]))\d{8}$/;

  return reg.test(phone);
};

export const DateToTimeStamp = (date) => {
  return moment(date, "YYYY-MM-DD HH:mm:ss").valueOf();
};
export const TimeStampToDate = (timeStamp) => {
  return moment(timeStamp).format("YYYY-MM-DD HH:mm:ss");
};
export const compareTimeStamp = (timeStampA, timeStampB) => {
  return timeStampA > timeStampB;
};
export const compareDate = (DateA, DateB) => {
  return DateToTimeStamp(DateA) > DateToTimeStamp(DateB);
};