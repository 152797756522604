import * as React from "react";
import "./styles.css";
import CardCountIcon from "../../../assets/project/card-count-icon.png";

//引入的库组件
import { message, Input, Row, Col, Spin, Pagination } from "antd";

//引入的其他页面组件
import CardTemplate from "../../Components/CardTemplate/index";
import EmptyComponent from "../../Components/EmptyComponent";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import {
  useGetInspirationSearchCardsQuery,
  useGetExtractKeyWordsQuery,
  SharedProjectType,
} from "../../../generated/graphql";
//引入的资源图片
import GoldenCardIcon from "../../../assets/writing/golden-card.png";
import WikiCardIcon from "../../../assets/writing/wiki-card.png";
import CardCoountIcon from "../../../assets/project/card-count-icon.png";
import SearchIcon from "../../../assets/writing/search.png";

const PER_PAGE_COUNT = 10;

const WritingInspiration = (props) => {
  const { writingCards } = props;
  const [tabIndex, setTabIndex] = React.useState(0);
  const [searchString, setSearchString] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isKeywordsLoading, setIsKeywordsLoading] = React.useState(false);
  const [cardList, setCardList] = React.useState([]);
  const [keywords, setKeywords] = React.useState([]);
  const [keywordsSelected, setKeywordsSelected] = React.useState([]);
  const [isInit, setIsInit] = React.useState(true);

  const [currentPageInfo, setCurrentPageInfo] = React.useState({
    count: 0,
    pageSize: PER_PAGE_COUNT,
    pageInfo: null,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const {
    refetch: getInspirationSearchCardsQuery,
  } = useGetInspirationSearchCardsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getExtractKeyWordsQuery } = useGetExtractKeyWordsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    let variables = {
      ids: writingCards,
    };
    getExtractKeyWords(variables, (keywords) => {
      if (keywords.length > 0) {
        setKeywordsSelected([keywords[0]]);
      }
    });
    setIsInit(false);
  }, []);

  React.useEffect(() => {
    if (!isInit) {
      let variables = {
        after: undefined,
        first: PER_PAGE_COUNT,
        matching: searchString === "" ? undefined : searchString,
        extractedKeywords:
          keywordsSelected.length === 0 ? undefined : keywordsSelected,
        filter: {
          types: [
            tabIndex === 0 ? SharedProjectType.Golden : SharedProjectType.Wiki,
          ],
        },
      };
      getInspirationCard(variables, () => {
        setCurrentPage(1);
      });
    }
  }, [tabIndex, keywordsSelected]);

  const getInspirationCard = (variables, callback, isGetMore = false) => {
    setIsLoading(true);
    if (!isGetMore) {
      setCardList([]);
    }
    getInspirationSearchCardsQuery(variables).then((res) => {
      setIsLoading(false);
      if (res.data && res.data.recommendations) {
        let pageInfo = res.data.recommendations.pageInfo;
        let count = res.data.recommendations.count;
        // let count = 0;
        let datas = res.data.recommendations.edges.map((item, index) => {
          return item.node;
        });
        setCurrentPageInfo({
          count: count,
          pageInfo: pageInfo,
          pageSize: PER_PAGE_COUNT,
        });
        if (isGetMore) {
          let cardsTemp = cardList.map((item) => {
            return item;
          });
          datas = cardsTemp.concat(datas);
        }
        setCardList(datas);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取数据失败");
      }
    });
  };

  const getExtractKeyWords = (variables, callback) => {
    setIsKeywordsLoading(true);
    getExtractKeyWordsQuery(variables).then((res) => {
      setIsKeywordsLoading(false);
      if (res.data.extractKeywords) {
        let keywords = res.data.extractKeywords.keywords.map((item) => {
          return item.word;
        });
        setKeywords(keywords);
        if (callback) {
          callback(keywords);
        }
      } else {
        message.error("获取数据失败");
      }
    });
  };

  const getPlaceholder = () => {
    switch (tabIndex) {
      case 0:
        return "搜索金句";
      case 1:
        return "搜索知识";
    }
  };

  const checkIsTagSelected = (tag) => {
    for (let i in keywordsSelected) {
      if (tag === keywordsSelected[i]) {
        return true;
      }
    }
    return false;
  };

  return (
    <div className="writing-module-inspiration-main">
      <div className="writing-module-inspiration-container">
        <div className="writing-module-inspiration-tab-and-tag-container">
          <div className="writing-module-inspiration-search-bar-container">
            <div className="writing-module-inspiration-search-bar-tab-contaner">
              <div
                className={
                  tabIndex === 0
                    ? "writing-module-inspiration-search-bar-text-selected"
                    : "writing-module-inspiration-search-bar-text"
                }
                onClick={() => {
                  if (tabIndex !== 0) {
                    setTabIndex(0);
                  }
                }}
              >
                金句
              </div>
              <div
                className={
                  tabIndex === 1
                    ? "writing-module-inspiration-search-bar-text-selected"
                    : "writing-module-inspiration-search-bar-text"
                }
                onClick={() => {
                  if (tabIndex !== 1) {
                    setTabIndex(1);
                  }
                }}
              >
                知识
              </div>
              {/* <div
                className={
                  tabIndex === 2
                    ? "writing-module-inspiration-search-bar-text-selected"
                    : "writing-module-inspiration-search-bar-text"
                }
                onClick={() => {
                  if (tabIndex !== 2) {
                    // setTabIndex(1);
                  }
                }}
              >
                诗歌
              </div> */}
            </div>
            <div className="writing-module-inspiration-search-bar-input-container">
              <Input
                prefix={<img src={SearchIcon} style={{width: "16px", height: "16px"}} />}
                placeholder={getPlaceholder()}
                style={{ backgroundColor: "rgba(249, 249, 251, 1)" }}
                onChange={(e) => {
                  setSearchString(e.target.value);
                }}
                onPressEnter={() => {
                  let variables = {
                    after: undefined,
                    first: PER_PAGE_COUNT,
                    matching: searchString === "" ? undefined : searchString,
                    extractedKeywords:
                      keywordsSelected.length === 0
                        ? undefined
                        : keywordsSelected,
                    filter: {
                      types: [
                        tabIndex === 0
                          ? SharedProjectType.Golden
                          : SharedProjectType.Wiki,
                      ],
                    },
                  };
                  getInspirationCard(variables, () => {
                    setCurrentPage(1);
                  });
                }}
              />
            </div>
          </div>
          <div className="writing-module-inspiration-search-tag-container">
            {keywords.map((item) => {
              let isSelected = checkIsTagSelected(item);
              return (
                <div
                  className={
                    isSelected
                      ? "writing-module-inspiration-search-tag-background-selected"
                      : "writing-module-inspiration-search-tag-background"
                  }
                  onClick={() => {
                    if (isSelected) {
                      setKeywordsSelected([]);
                    } else {
                      setKeywordsSelected([item]);
                    }
                  }}
                >
                  <div
                    className={
                      isSelected
                        ? "writing-module-inspiration-search-tag-text-selected"
                        : "writing-module-inspiration-search-tag-text"
                    }
                  >
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="writing-module-inspiration-search-list-container"
          // onScroll={e => {
          //   const { clientHeight, scrollHeight, scrollTop } = e.target;
          //   const isBottom = scrollTop + clientHeight + 20 > scrollHeight;
          //   if (
          //     !isLoading &&
          //     currentPageInfo.pageInfo &&
          //     currentPageInfo.pageInfo.hasNextPage &&
          //     isBottom
          //   ) {
          //     let variables = {
          //       after: currentPageInfo.pageInfo.endCursor,
          //       first: PER_PAGE_COUNT,
          //       matching: searchString === "" ? undefined : searchString,
          //       extractedKeywords:
          //         keywordsSelected.length === 0 ? undefined : keywordsSelected,
          //       filter: {
          //         types:
          //           [tabIndex === 0
          //             ? SharedProjectType.Golden
          //             : SharedProjectType.Wiki]
          //       }
          //     };
          //     getInspirationCard(
          //       variables,
          //       () => {
          //         // setCurrentPage(1);
          //       },
          //       true
          //     );
          //   }
          // }}
        >
          {/* <Spin spinning={isLoading}> */}
          {cardList.length ? (
            <Row gutter={[8, 16]}>
              {cardList.map((item, index) => {
                return (
                  <Col span={24}>
                    <CardTemplate
                      item={item}
                      isHoverOutLine={true}
                      isHoverShowIcon={true}
                      icon={tabIndex === 0 ? GoldenCardIcon : WikiCardIcon}
                      searchString={searchString}
                      style={{ width: "320px", height: "226px" }}
                      extraTitleButton={[
                        {
                          buttonType: "copy",
                          callback: null,
                        },
                      ]}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : (
            <EmptyComponent tip={"没有相关结果"} />
          )}

          {/* </Spin> */}
          {/* <div className="writing-module-inspiration-search-get-more-container">
            <Spin spinning={isLoading} tip="加载中">
              {isLoading
                ? ""
                : currentPageInfo.pageInfo &&
                  currentPageInfo.pageInfo.hasNextPage
                ? "下拉加载更多"
                : "没有了"}
            </Spin>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default WritingInspiration;
