import {Col, Row} from "antd";
import NoMore from "../../../../assets/inspiration/no-more.png";
import More from "../../../../assets/inspiration/more.png";
import * as React from "react";
import {useGetInspirationProjectDetailQuery, useSharedProjectsQuery} from "../../../../generated/graphql";

const CateTags = (props) => {
    const { icon, index, item, categoryList, categoryShowList, showCardList, setCategoryShowList } = props;
    const { refetch: getSharedProjects } = useSharedProjectsQuery({
        skip: true,
        fetchPolicy: "network-only",
        errorPolicy: "all"
    });
    return (
        <div>
            {
                item ?
                    <div key={'col-m' + index} className="inspiration-category-container">
                        <section style={{ display: 'flex' }}>
                            <div className="inspiration-category-title-container">
                                <img src={icon} className="inspiration-category-title-icon" />
                                <div className="inspiration-category-title-text">
                                    {item.name}
                                </div>
                            </div>
                            <div className="inspiration-category-item-container">
                                <Row gutter={[16, 8]}>
                                    {item.sharedProject.map((tag, tagIndex) => {
                                        if (tag.type === 'WIKI') return null;
                                        if (tagIndex >= 20 && !categoryShowList[index]) {
                                            return null;
                                        }
                                        return (
                                            <Col key={'col' + tag.id} span={5} style={{ display: "flex" }}>
                                                <div
                                                    className="inspiration-category-item-text"
                                                    onClick={() => {
                                                        showCardList(tag, item);
                                                    }}
                                                >{tag.title}</div>
                                                <span className="inspiration-category-item-text-count">
                                            （{tag.cardCounts || 0}）
                                        </span>
                                            </Col>
                                        );
                                    })}
                                </Row>
                                {item.sharedProject.length > 19 && (
                                    <div
                                        className="inspiration-category-show-all-container"
                                        onClick={async (e) => {
                                            let showList = categoryShowList.map((show) => {
                                                return show;
                                            });
                                            showList[index] = !showList[index];
                                            if (!categoryShowList[index]) {
                                                const variables = {
                                                    filter: {
                                                        type: 'GOLDEN',
                                                        categories: [item.name]
                                                    },
                                                    first: 100,
                                                    order: 'DESC_COLLECTION_COUNTS',
                                                };
                                                const newTagNames = item.sharedProject.map(isp => isp.title);
                                                const newTags = [...item.sharedProject];
                                                const moreTags = await getSharedProjects(variables);
                                                moreTags.data.sharedProjects.edges.forEach(sp => {
                                                   if (newTagNames.indexOf(sp.node.title) < 0 && newTags.length < 40) {
                                                       newTags.push(sp.node);
                                                   }
                                                });
                                                item.sharedProject = newTags;
                                            }
                                            setCategoryShowList(showList);
                                        }}
                                    >
                                        <div className="inspiration-category-show-all-text">
                                            {categoryShowList[index] ? "收起" : "更多"}
                                        </div>
                                        <img
                                            src={categoryShowList[index] ? NoMore : More}
                                            style={{ width: "10px", height: "6px" }}
                                        />
                                    </div>
                                )}
                            </div>
                        </section>
                        {index < categoryList.length - 1 && (
                            <div className="inspiration-category-divider" />
                        )}
                    </div> : null
            }
        </div>
    )
};

export default CateTags;
