import * as React from "react";
import "./styles.css";

//引入的资源图片
import EmptySelect from "../../../assets/import-export/empty-select.png";
import Select from "../../../assets/import-export/select.png";

const SelectRadioSingle = (props) => {
  const { isChecked, onSelected, text, style } = props;

  return (
    <div
      style={style ? style : {}}
      className="select-radio-single-container"
      onClick={() => {
        onSelected();
      }}
    >
      <img
        src={isChecked ? Select : EmptySelect}
        className="select-radio-single-selected-icon"
      />
      <div className="select-radio-single-selected-text">{text}</div>
    </div>
  );
};

export default SelectRadioSingle;
