import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Row, Col, Spin, Dropdown, Menu } from "antd";

//引入的其他页面组件
import EmptyComponent from "../Components/EmptyComponent";
import FolderItem from "./FolderItem/index";
import CardTemplate from "../Components/CardTemplate/index";
import CreateModal from "../Components/CreateModal/index";
import CreateCorpusCardModal from "../Components/CreateCorpusCardModal/index";
import ReconfirmModal from "../Components/ReconfirmModal/index";
import { convertToZH } from "aw-error";
import CorpusCardDetailContainer from "./corpusCardDetailContainer";
import SearchWithDrop from "../Components/Search/searchWithDrop";
import { getUrlPath, getUrlSearch } from "../../utils/getUrlInfo";
import { history } from "../AiWriterRouter/index";

//graphql查询
import { uuid } from "uuidv4";
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import {
  useGetProjectDetailInfoQuery,
  useGetCorpusCardsQuery,
  useCreateCardsMutation,
  useUpdateCardMutation,
  useUpdateProjectMutation,
  useDeleteCardsMutation,
  useGetSingleCardQuery,
  FileType,
  CardType,
  SortOrder,
  ProjectType,
} from "../../generated/graphql";

//引入的资源图片
import BackIcon from "../../assets/back-black.png";
import AddIcon from "../../assets/corpus/add-dropdown.png";
import GoldenIcon from "../../assets/writing/golden-card.png";
import WikiIcon from "../../assets/writing/wiki-card.png";
import GoldenAddTip from "../../assets/project/golden-empty-tip.png";
import WikiAddTip from "../../assets/project/wiki-empty-tip.png";

//路由格式示例/corpus/project/detail/p/:code/f/:code/c/:code?tab=xx
export const P_INDEX = 3;
export const P_ID_INDEX = 4;
export const F_INDEX = 5;
export const F_ID_INDEX = 6;
export const C_INDEX = 7;
export const C_ID_INDEX = 8;

const CorpusProjectDetailContainer = (props) => {
  const client = useApolloClient();

  const { setHeaderContent, openGlobalSearchRef } = props;

  const [isInit, setIsInit] = React.useState(true);
  const [searchString, setSearchString] = React.useState("");
  const [currentProjectInfo, setCurrentProjectInfo] = React.useState(null);

  const [outline, setOutline] = React.useState(null);
  const [showAllCardsList, setShowAllCardsList] = React.useState([]);
  const [showFolderCardsList, setShowFolderCardsList] = React.useState([]);
  const [isGetCards, setIsGetCards] = React.useState(false);
  const [isShowFolderCards, setIsShowFolderCards] = React.useState(false);
  const [isShowCreateModal, setIsShowCreateModal] = React.useState(false);
  const [isShowReconfirmModal, setIsShowReconfirmModal] = React.useState(false);
  const [
    isShowCreateCorpusCardModal,
    setIsShowCreateCorpusCardModal,
  ] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState({});
  const [selectedFolder, setSelectedFolder] = React.useState("");

  const [isShowCardDetail, setIsShowCardDetail] = React.useState(false);

  const [createCardsMutation] = useCreateCardsMutation();
  const [updateCardMutation] = useUpdateCardMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();
  const [deleteCardsMutation] = useDeleteCardsMutation();
  const { refetch: getProjectDetailInfoQuery } = useGetProjectDetailInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getCardsInfoQuery } = useGetCorpusCardsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getSingleCardQuery } = useGetSingleCardQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const searchCards = (searchString) => {
    setSearchString(searchString);
    setIsShowFolderCards(false);
    let variables = {
      ids: undefined,
      projectId: currentProjectInfo.id,
      first: currentProjectInfo.cardsCount,
      filter: { types: [currentProjectInfo.type] },
      matching: searchString === "" ? undefined : searchString,
      order: SortOrder.Desc,
    };
    getCards(variables, false);
  };

  const searchCardsRef = React.useRef(searchCards);
  searchCardsRef.current = searchCards;

  React.useEffect(() => {
    if (isShowCardDetail) {
      client.writeData({ data: { isShowSearchComponentClient: false } });
    } else {
      client.writeData({ data: { isShowSearchComponentClient: true } });
    }
  }, [isShowCardDetail]);

  React.useEffect(() => {
    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();

    if (
      pathArray[P_INDEX] &&
      pathArray[P_INDEX] === "p" &&
      pathArray[P_ID_INDEX] &&
      pathArray[P_ID_INDEX] !== ""
    ) {
      let projectCode = window.btoa("Project:" + pathArray[P_ID_INDEX]);
      let variables = { code: projectCode };
      getProjectDetailInfoQuery(variables).then((res) => {
        if (!res.data.node) {
          message.error("项目不存在");
          history.push("/corpus/project/list?category=all");
          return;
        } else {
          let projectInfo = res.data.node;
          setOutline(projectInfo.outline);
          let ids = [];
          if (
            projectInfo.outline.menu.children &&
            projectInfo.outline.menu.children.length
          ) {
            ids = projectInfo.outline.menu.children.map((item, index) => {
              let id = window.btoa("Card:" + item.id);
              return id;
            });
          }
          projectInfo.cardsCount = ids.length;
          setCurrentProjectInfo(projectInfo);

          if (ids.length) {
            let variables = {
              first: ids.length,
              ids: ids,
              filter: { types: [projectInfo.type] },
              matching: undefined,
              order: SortOrder.Desc,
            };
            getCards(variables, false, (showListTemp) => {
              if (
                pathArray[F_INDEX] &&
                pathArray[F_INDEX] === "f" &&
                pathArray[F_ID_INDEX] &&
                pathArray[F_ID_INDEX] !== ""
              ) {
                let packageCode = window.btoa("Card:" + pathArray[F_ID_INDEX]);
                let selectedFolder = showListTemp.filter((item) => {
                  return item.code === packageCode;
                })[0];
                let id = selectedFolder.id;
                let info = getOutlineCardInfo(id, projectInfo.outline);
                setSelectedFolder(selectedFolder);

                if (!info) {
                  message.error("卡包不存在");
                  history.push(
                    "/corpus/project/detail?projectid=" + searchArray.projectid
                  );
                  return;
                }
                let children = info.children;
                let ids = children.map((item, index) => {
                  let id = window.btoa("Card:" + item.id);
                  return id;
                });
                if (ids.length) {
                  let variables = {
                    first: ids.length,
                    ids: ids,
                    filter: { types: [projectInfo.type] },
                    matching: searchString === "" ? undefined : searchString,
                    order: SortOrder.Desc,
                  };
                  getCards(variables, true, (showListTemp) => {
                    setIsShowFolderCards(true);
                    if (
                      pathArray[C_INDEX] &&
                      pathArray[C_INDEX] === "c" &&
                      pathArray[C_ID_INDEX] &&
                      pathArray[C_ID_INDEX] !== ""
                    ) {
                      let cardCode = window.btoa(
                        "Card:" + pathArray[C_ID_INDEX]
                      );
                      let selectedCard = showListTemp.filter((item) => {
                        return item.code === cardCode;
                      })[0];
                      if (selectedCard) {
                        setSelectedCard(selectedCard);
                        setIsShowCardDetail(true);
                      } else {
                        message.error("卡片不存在");
                        history.push(
                          `/corpus/project/detail/p/${pathArray[P_ID_INDEX]}/f/${pathArray[F_ID_INDEX]}`
                        );
                      }
                    }
                    setIsInit(false);
                  });
                } else {
                  setIsInit(false);
                  setShowFolderCardsList([]);
                  setIsShowFolderCards(true);
                }
              } else if (
                pathArray[F_INDEX] &&
                pathArray[F_INDEX] === "c" &&
                pathArray[F_ID_INDEX] &&
                pathArray[F_ID_INDEX] !== ""
              ) {
                let cardCode = window.btoa("Card:" + pathArray[F_ID_INDEX]);
                let selectedCard = showListTemp.filter((item) => {
                  return item.code === cardCode;
                })[0];
                if (selectedCard) {
                  setSelectedCard(selectedCard);
                  setIsShowCardDetail(true);
                } else {
                  message.error("卡片不存在");
                  history.push(
                    `/corpus/project/detail/p/${pathArray[P_ID_INDEX]}`
                  );
                }
                setIsInit(false);
              } else {
                setIsInit(false);
              }
            });
          } else {
            setIsInit(false);
          }
        }
      });
    } else {
      history.push("/corpus/project/list?category=all");
    }
  }, []);

  React.useEffect(() => {
    setHeaderContent(renderHeaderContent);
  }, [isShowCardDetail]);

  const renderHeaderContent = () => {
    const categories = [
      {
        name: "项目",
      },
      {
        name: "卡片",
      },
    ];

    return (
      <div className="corpus-header-container">
        <div className="corpus-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"corpus-header-" + index}
                className={
                  index === 0
                    ? "corpus-header-tab-item-selected"
                    : "corpus-header-tab-item"
                }
                onClick={() => {
                  if (index !== 0) {
                    history.push("/corpus/cards/data-list");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {!isShowCardDetail && (
          <SearchWithDrop
            position={{ right: "25px" }}
            callback={(index, string) => {
              if (index === "当前页面") {
                searchCardsRef.current(string);
              }
              if (index === "全部搜索") {
                openGlobalSearchRef.current(string);
              }
            }}
            selectValues={[
              {
                value: "当前页面",
              },
              {
                value: "全部搜索",
              },
            ]}
          />
        )}
      </div>
    );
  };

  const getCards = (variables, isGetFolderCards, callback) => {
    setIsGetCards(true);
    getCardsInfoQuery(variables).then((res) => {
      setIsGetCards(false);
      let showListTemp = [];
      if (!res.data.cards) {
        message.error("获取卡片失败");
      } else {
        let folders = [];
        let cards = [];
        res.data.cards.edges.map((item, index) => {
          let data = {
            categories: item.node.categories,
            content: item.node.content,
            coordinate: item.node.coordinate,
            id: window.atob(item.node.id).split("Card:")[1],
            insertedAt: item.node.insertedAt,
            tags: item.node.tags,
            title: item.node.title,
            type: item.node.type,
            fileType: item.node.fileType,
            code: item.node.code,
          };
          if (item.node.fileType === FileType.File) {
            cards.push(data);
          }
          if (item.node.fileType === FileType.Folder) {
            folders.push(data);
          }
        });
        showListTemp = folders.concat(cards);
        if (isGetFolderCards) {
          setShowFolderCardsList(showListTemp);
        } else {
          setShowAllCardsList(showListTemp);
        }
      }
      if (callback) {
        callback(showListTemp);
      }
    });
  };

  const createCard = (input, callback) => {
    setIsGetCards(true);
    createCardsMutation({ variables: { input } }).then((res) => {
      setIsGetCards(false);
      if (!res.data.createCards.successful) {
        // message.error(res.data.createCards.messages[0].message);
        message.error(convertToZH(res.data.createCards.messages[0]));
      } else {
        message.success("创建成功");
        if (callback) {
          callback();
        }
      }
    });
  };

  const updateCard = (input, callback) => {
    setIsGetCards(true);
    updateCardMutation({ variables: { input } }).then((res) => {
      setIsGetCards(false);
      if (!res.data.updateCard.successful) {
        // message.error(res.data.updateCard.messages[0].message);
        message.error(convertToZH(res.data.updateCard.messages[0]));
      } else {
        if (callback) {
          callback();
        }
      }
    });
  };

  const updateProject = (input, callback) => {
    setIsGetCards(true);
    updateProjectMutation({ variables: { input } }).then((res) => {
      setIsGetCards(false);
      if (!res.data.updateProject.successful) {
        // message.error(res.data.updateProject.messages[0].message);
        message.error(convertToZH(res.data.updateProject.messages[0]));
      } else {
        message.success("修改成功");
        if (callback) {
          callback();
        }
      }
    });
  };

  const addCardToProject = (fileType, values = null) => {
    let projectID = window.atob(currentProjectInfo.id).split("Project:")[1];
    let outlineTemp = JSON.parse(JSON.stringify(outline));
    let children = outlineTemp.menu.children;
    let cardList = isShowFolderCards
      ? showFolderCardsList.map((item) => {
          return item;
        })
      : showAllCardsList.map((item) => {
          return item;
        });

    let cardToAdd = {
      id: uuid(),
      projectId: projectID,
      type: currentProjectInfo.type,
      canDelete: true,
      fileType: fileType,
    };

    if (fileType === FileType.File) {
      if (values) {
        cardToAdd = {
          ...cardToAdd,
          title: values.title,
          content: values.content,
          metadata: JSON.stringify({
            origin: values.origin,
            author: values.author,
          }),
        };
      }
    } else {
      cardToAdd = {
        ...cardToAdd,
        title: "新建卡包",
        content: "",
      };
    }

    let folders = cardList.filter((item) => {
      if (item.fileType === FileType.Folder) {
        return true;
      }
      return false;
    });

    let cards = cardList.filter((item) => {
      if (item.fileType === FileType.File) {
        return true;
      }
      return false;
    });

    if (fileType === FileType.Folder) {
      folders.unshift(cardToAdd);
    } else {
      let card = {
        ...cardToAdd,
        metadata: { origin: values.origin, author: values.author },
      };
      cards.unshift(card);
    }

    let showListTemp = folders.concat(cards);
    let outlineData = showListTemp.map((item) => {
      const getOutlineChildrenData = (id) => {
        for (let i in outlineTemp.menu.children) {
          if (outlineTemp.menu.children[i].id === id) {
            return outlineTemp.menu.children[i].children;
          }
        }
        return [];
      };

      return {
        id: item.id,
        title: item.title,
        children: getOutlineChildrenData(item.id),
      };
    });

    if (isShowFolderCards) {
      for (let i = 0; i < outlineTemp.menu.children.length; i++) {
        let child = outlineTemp.menu.children[i];
        if (child.id === selectedFolder.id) {
          child.children = outlineData;
          break;
        }
      }
    } else {
      outlineTemp.menu.children = outlineData;
    }

    const input = {
      cardsToCreate: [cardToAdd],
      projectsToUpdate: [
        {
          id: projectID,
          outline: JSON.stringify(outlineTemp),
        },
      ],
    };
    createCard(input, () => {
      let projectInfoTemp = JSON.parse(JSON.stringify(currentProjectInfo));
      projectInfoTemp = {
        ...projectInfoTemp,
        cardCounts: projectInfoTemp.cardCounts + 1,
        outline: outlineTemp,
      };
      setOutline(outlineTemp);
      setCurrentProjectInfo(projectInfoTemp);

      getSingleCardQuery({
        id: window.btoa("Card:" + cardToAdd.id),
      }).then((res) => {
        if (res.data.node) {
          let newCardId = res.data.node.id;
          let newCardCode = res.data.node.code;

          showListTemp.forEach((item) => {
            if (item.id === window.atob(newCardId).split("Card:")[1]) {
              item.code = newCardCode;
            }
          });
        }
        if (isShowFolderCards) {
          setShowFolderCardsList(showListTemp);
        } else {
          setShowAllCardsList(showListTemp);
        }
      });
    });
  };

  const addOverlay = () => {
    return (
      <Menu
        className="corpus-project-detail-title-bar-title-add-dropdown-container"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          switch (e.key) {
            case "1":
              addCardToProject(FileType.Folder);
              break;
            case "2":
              setIsShowCreateCorpusCardModal(true);
              // addCardToProject(FileType.File);
              break;
          }
        }}
      >
        <Menu.Item
          key={"1"}
          className="corpus-project-detail-title-bar-title-add-dropdown-item-container"
        >
          <div className="corpus-project-detail-title-bar-title-add-dropdown-item">
            卡包
          </div>
        </Menu.Item>

        <Menu.Item
          key={"2"}
          className="corpus-project-detail-title-bar-title-add-dropdown-item-container"
        >
          <div className="corpus-project-detail-title-bar-title-add-dropdown-item">
            卡片
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  let showList = isShowFolderCards ? showFolderCardsList : showAllCardsList;

  const changeCardInfo = (card, info) => {
    if (card.id === info.id) {
      let keys = Object.keys(info);
      for (let i = 0; i < keys.length; i++) {
        card[keys[i]] = info[keys[i]];
      }
    } else {
      if (card.children && card.children.length) {
        for (let i = 0; i < card.children.length; i++) {
          changeCardInfo(card.children[i], info);
        }
      }
    }
  };

  const deleteChildrenCard = (card, id) => {
    let isFind = false;
    if (card.children && card.children.length) {
      card.children = card.children.filter((item) => {
        if (item.id === id) {
          isFind = true;
          return false;
        }
        return true;
      });
      if (!isFind) {
        for (let i = 0; i < card.children.length; i++) {
          deleteChildrenCard(card.children[i], id);
        }
      }
    }
  };

  const changeCardTitleAndContent = (info) => {
    let projectID = window.atob(currentProjectInfo.id).split("Project:")[1];
    let input = {
      revision: false,
      id: selectedCard.id,
      ...info,
      metadata:
        info.metadata && info.metadata !== ""
          ? JSON.stringify(info.metadata)
          : null,
    };
    updateCard(input, () => {
      let showListTemp = showList.map((item) => {
        if (item.id === selectedCard.id) {
          item = {
            ...item,
            ...info,
          };
          setSelectedCard(item);
        }
        return item;
      });
      let outlineTemp = JSON.parse(JSON.stringify(outline));
      changeCardInfo(outlineTemp.menu, { id: selectedCard.id, ...info });
      let input = {
        id: projectID,
        outline: JSON.stringify(outlineTemp),
      };
      updateProject(input, () => {
        let projectInfoTemp = JSON.parse(JSON.stringify(currentProjectInfo));
        projectInfoTemp = {
          ...projectInfoTemp,
          outline: outlineTemp,
        };
        setOutline(outlineTemp);
        setCurrentProjectInfo(projectInfoTemp);
        if (isShowFolderCards) {
          setShowFolderCardsList(showListTemp);
        } else {
          setShowAllCardsList(showListTemp);
        }
      });
    });
  };

  const getCardInfo = (cards, id) => {
    let info = null;
    for (let i = 0; i < cards.length; i++) {
      let item = cards[i];
      if (item.id === id) {
        return item;
      } else if (item.children && item.children.length) {
        info = getCardInfo(item.children, id);
        if (info) {
          return info;
        }
      }
    }
    return info;
  };

  const getOutlineCardInfo = (id, inputOutline = null) => {
    let cards = inputOutline
      ? inputOutline.menu.children
      : outline.menu.children;
    let info = getCardInfo(cards, id);
    return info;
  };

  const deleteCard = () => {
    let projectID = window.atob(currentProjectInfo.id).split("Project:")[1];
    let cardsToDelete = [{ id: selectedCard.id }];

    let showListTemp = showList.filter((item) => {
      if (item.id === selectedCard.id) {
        return false;
      }
      return true;
    });
    let outlineTemp = JSON.parse(JSON.stringify(outline));
    let cardInfo = getOutlineCardInfo(selectedCard.id);

    if (cardInfo.children && cardInfo.children.length) {
      for (let i = 0; i < cardInfo.children.length; i++) {
        cardsToDelete.push({ id: cardInfo.children[i].id });
      }
    }

    deleteChildrenCard(outlineTemp.menu, selectedCard.id);
    let projectsToUpdate = {
      id: projectID,
      outline: JSON.stringify(outlineTemp),
    };

    const input = {
      cardsToDelete,
      projectsToUpdate,
    };

    setIsGetCards(true);
    deleteCardsMutation({ variables: { input } }).then((res) => {
      setIsGetCards(false);
      if (!res.data.deleteCards.successful) {
        // message.error(res.data.deleteCards.messages[0].message);
        message.error(convertToZH(res.data.deleteCards.messages[0]));
      } else {
        let projectInfoTemp = JSON.parse(JSON.stringify(currentProjectInfo));
        projectInfoTemp = {
          ...projectInfoTemp,
          cardCounts: projectInfoTemp.cardCounts - 1,
          outline: outlineTemp,
        };
        setOutline(outlineTemp);
        setCurrentProjectInfo(projectInfoTemp);
        if (isShowFolderCards) {
          setShowFolderCardsList(showListTemp);
        } else {
          setShowAllCardsList(showListTemp);
        }
        message.success("删除成功");
      }
    });
  };

  const renderCardDetail = () => {
    return (
      <div className="corpus-project-detail-container">
        <div className="corpus-project-detail-title-bar">
          <div className="corpus-project-list-left-container">
            <img
              src={BackIcon}
              className="corpus-project-detail-title-bar-back"
              onClick={() => {
                setIsShowCardDetail(false);
                let projectCode = window
                  .atob(currentProjectInfo.code)
                  .split("Project:")[1];
                if (isShowFolderCards) {
                  let packageCode = window
                    .atob(selectedFolder.code)
                    .split("Card:")[1];
                  window.history.pushState(
                    null,
                    "",
                    `/corpus/project/detail/p/${projectCode}/f/${packageCode}`
                  );
                } else {
                  window.history.pushState(
                    null,
                    "",
                    `/corpus/project/detail/p/${projectCode}`
                  );
                }
              }}
            />

            <div className="corpus-project-detail-title-bar-title-text">
              {selectedCard.title}
            </div>
          </div>
        </div>
        <div className="corpus-card-container">
          <CorpusCardDetailContainer
            cardInfo={selectedCard}
            updateCardInfo={changeCardTitleAndContent}
          />
        </div>
      </div>
    );
  };

  const renderCardList = () => {
    return (
      <div className="corpus-project-detail-container">
        <div className="corpus-project-detail-title-bar">
          <div className="corpus-project-list-left-container">
            <img
              src={BackIcon}
              className="corpus-project-detail-title-bar-back"
              onClick={() => {
                if (isShowFolderCards) {
                  let projectCode = window
                    .atob(currentProjectInfo.code)
                    .split("Project:")[1];
                  setIsShowFolderCards(false);
                  window.history.pushState(
                    null,
                    "",
                    `/corpus/project/detail/p/${projectCode}`
                  );
                } else {
                  history.push(`/corpus/project/list`);
                }
              }}
            />

            <div className="corpus-project-detail-title-bar-title-text">
              {currentProjectInfo.title +
                (isShowFolderCards ? "-" + selectedFolder.title : "")}
            </div>

            {!isShowFolderCards ? (
              <Dropdown
                trigger={["click"]}
                placement="bottomCenter"
                overlay={addOverlay}
                getPopupContainer={() =>
                  document.getElementById("create-container")
                }
              >
                <div
                  id={"create-container"}
                  className="corpus-project-detail-title-bar-title-add-container"
                >
                  {showList.length === 0 && (
                    <img
                      src={
                        currentProjectInfo.type === ProjectType.Golden
                          ? GoldenAddTip
                          : WikiAddTip
                      }
                      className="corpus-project-detail-title-bar-title-add-empty-tip"
                    />
                  )}

                  <img
                    src={AddIcon}
                    className="corpus-project-detail-title-bar-title-add-icon"
                  />
                  <div className="corpus-project-detail-title-bar-title-add-text">
                    添加
                  </div>
                </div>
              </Dropdown>
            ) : (
              <div
                id={"create-container"}
                className="corpus-project-detail-title-bar-title-add-container"
                onClick={() => {
                  setIsShowCreateCorpusCardModal(true);
                  // addCardToProject(FileType.File);
                }}
              >
                {showList.length === 0 && (
                  <img
                    src={
                      currentProjectInfo.type === ProjectType.Golden
                        ? GoldenAddTip
                        : WikiAddTip
                    }
                    className="corpus-project-detail-title-bar-title-add-empty-tip"
                  />
                )}
                <img
                  src={AddIcon}
                  className="corpus-project-detail-title-bar-title-add-icon"
                />
                <div className="corpus-project-detail-title-bar-title-add-text">
                  添加
                </div>
              </div>
            )}
          </div>
          <div className="corpus-project-detail-title-bar-title-count">
            {showList.length}张
          </div>
        </div>
        <div
          className="corpus-project-detail-list-container"
          style={
            showList.length > 0
              ? {}
              : { alignItems: "center", justifyContent: "center" }
          }
        >
          <Spin spinning={isGetCards}>
            {showList.length > 0 ? (
              <Row gutter={[24, 32]}>
                {showList.map((item, index) => {
                  if (item.fileType === FileType.Folder) {
                    const getChildren = () => {
                      let cards = outline.menu.children;
                      for (let i = 0; i < cards.length; i++) {
                        if (cards[i].id === item.id) {
                          return cards[i].children;
                        }
                      }
                      return [];
                    };
                    let children = getChildren();

                    return (
                      <Col span={6} key={"corpus folder card:" + item.id}>
                        <FolderItem
                          key={item.id}
                          isHoverOutLine={true}
                          itemClick={() => {
                            let info = getOutlineCardInfo(item.id);
                            let children = info.children;
                            let ids = children.map((item, index) => {
                              let id = window.btoa("Card:" + item.id);
                              return id;
                            });
                            if (ids.length) {
                              let variables = {
                                first: ids.length,
                                ids: ids,
                                filter: { types: [currentProjectInfo.type] },
                                matching:
                                  searchString === ""
                                    ? undefined
                                    : searchString,
                                order: SortOrder.Desc,
                              };
                              getCards(variables, true, () => {
                                setIsShowFolderCards(true);
                              });
                            } else {
                              setShowFolderCardsList([]);
                              setIsShowFolderCards(true);
                            }
                            let projectCode = window
                              .atob(currentProjectInfo.code)
                              .split("Project:")[1];
                            let packageCode = window
                              .atob(item.code)
                              .split("Card:")[1];
                            window.history.pushState(
                              null,
                              "",
                              `/corpus/project/detail/p/${projectCode}/f/${packageCode}`
                            );
                          }}
                          item={item}
                          children={children}
                          setSelectedFolder={setSelectedFolder}
                          setSelectedCard={setSelectedCard}
                          modifyNameCallback={() => {
                            setIsShowCreateModal(true);
                          }}
                          deleteCallback={() => {
                            setIsShowReconfirmModal(true);
                          }}
                        />
                      </Col>
                    );
                  }
                  if (item.fileType === FileType.File) {
                    let icon = null;
                    if (item.type === CardType.Golden) {
                      icon = GoldenIcon;
                    }
                    if (item.type === CardType.Wiki) {
                      icon = WikiIcon;
                    }
                    return (
                      <Col
                        span={6}
                        key={item.id}
                        key={"corpus file card:" + item.id}
                      >
                        <CardTemplate
                          item={item}
                          isHoverOutLine={true}
                          isHoverShowIcon={true}
                          clickCallback={(item) => {
                            setIsShowCardDetail(true);
                            let path = "";
                            let projectCode = window
                              .atob(currentProjectInfo.code)
                              .split("Project:")[1];
                            let cardCode = window
                              .atob(item.code)
                              .split("Card:")[1];
                            if (isShowFolderCards) {
                              let packageCode = window
                                .atob(selectedFolder.code)
                                .split("Card:")[1];
                              path = `/corpus/project/detail/p/${projectCode}/f/${packageCode}/c/${cardCode}`;
                            } else {
                              path = `/corpus/project/detail/p/${projectCode}/c/${cardCode}`;
                            }
                            window.history.pushState(null, "", path);
                          }}
                          extraTitleButton={[
                            {
                              buttonType: "copy",
                            },
                            {
                              buttonType: "delete",
                              callback: (item) => {
                                setSelectedCard(item);
                                setIsShowReconfirmModal(true);
                              },
                            },
                          ]}
                          searchString={searchString}
                          style={{ width: "300px", height: "220px" }}
                          icon={icon}
                          // isTitleEditble={true}
                          // titleEditCallback={(title) => {
                          //   changeCardTitleAndContent({ title: title });
                          // }}
                          // isContentEditble={true}
                          // contentEditCallback={(content) => {
                          //   changeCardTitleAndContent({ content: content });
                          // }}
                          setSelectedCard={setSelectedCard}
                        />
                      </Col>
                    );
                  }
                  return null;
                })}
              </Row>
            ) : (
              <EmptyComponent tip={"没有卡片数据"} />
            )}
          </Spin>
        </div>
        <CreateCorpusCardModal
          isShow={isShowCreateCorpusCardModal}
          type={currentProjectInfo.type}
          cancelCallback={() => {
            setIsShowCreateCorpusCardModal(false);
          }}
          submitCallback={(values) => {
            setIsShowCreateCorpusCardModal(false);
            addCardToProject(FileType.File, values);
          }}
        />
        <ReconfirmModal
          isShow={isShowReconfirmModal}
          title="删除卡片"
          content={"是否删除:" + selectedCard.title}
          cancelCallback={() => {
            setIsShowReconfirmModal(false);
          }}
          submitCallback={() => {
            setIsShowReconfirmModal(false);
            deleteCard();
          }}
        />
        <CreateModal
          originName={selectedCard.title}
          title="修改名称"
          tip="请输入名称"
          showModal={isShowCreateModal}
          handleOnOk={(name) => {
            setIsShowCreateModal(false);
            changeCardTitleAndContent({ title: name });
          }}
          handleOnCancel={() => {
            setIsShowCreateModal(false);
          }}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (!currentProjectInfo || !outline || isInit) {
      return null;
    }
    if (isShowCardDetail) {
      return renderCardDetail();
    } else {
      return renderCardList();
    }
  };

  return renderContent();
};

export default CorpusProjectDetailContainer;
