import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件

//graphql查询

//引入的资源图片
import DefaultCover from "../../../assets/project/default-cover.png";

const AddNewTemplate = (props) => {
  const { templateInfo, templateClick } = props;
  let coverImg =
    templateInfo.coverImageUrl && templateInfo.coverImageUrl !== ""
      ? templateInfo.coverImageUrl
      : DefaultCover;
  return (
    <div
      className="new-content-template-container-1133"
      onClick={() => {
        templateClick();
      }}
    >
      <div className="new-content-template-container-layer3" />
      <div className="new-content-template-container-layer2" />
      <div className="new-content-template-container-layer1">
        <div className="new-content-template-content-container">
          {templateInfo.title === "" || templateInfo.title === "默认" ? (
            <div className="new-content-empty-icon-button">
              <div className="add" />
            </div>
          ) : (
            <>
              <div className="project-template-content-text">
                {templateInfo.title}
              </div>
              <img src={coverImg} className="project-template-content-cover" />
            </>
          )}
        </div>
        {/* <div className="new-content-template-title-2847">
          {templateInfo.title}
        </div> */}
      </div>
    </div>
  );
};

export default AddNewTemplate;
