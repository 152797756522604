import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Form, Input, message } from "antd";

//引入的其他页面组件
import CountDownText from "../Components/CountDownText/index";
import { checkPhoneNumber } from "../../utils/index";
import { convertToZH } from '../../utils/convertErrors';

//graphql查询
import { useSendVerificationCodeMutation } from "../../generated/graphql";

//引入的资源图片

const PhoneEditorModal = props => {
  const { showModal, handleOnOk, handleOnCancel, form } = props;

  const [isVerifySend, setIsVerifySend] = React.useState(false);

  const [sendVerificationCodeMutation] = useSendVerificationCodeMutation();
  const submit = () => {
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      handleOnOk && handleOnOk(values);
      form.resetFields();
    });
  };

  const sendVerify = () => {
    form.validateFieldsAndScroll(["phone"], (errors, values) => {
      if (errors) {
        return;
      }
      setIsVerifySend(true);
      let phone = values.phone;
      sendVerificationCodeMutation({
        variables: { phone: phone }
      }).then(res => {
        if (!res.data.sendVerificationCode.successful) {
          // message.error(res.data.verifyWeixin.messages[0].message);
          message.error(convertToZH(res.data.verifyWeixin.messages[0]));
        }
      });
    });
  };

  const checkPhone = (rule, value, callback) => {
    if (!value || value.trim() === "") {
      callback("请输入手机号");
    } else if (!checkPhoneNumber(value)) {
      callback("手机号格式不正确");
    } else {
      callback();
    }
  };

  return (
    <Modal
      afterClose={() => {
        form.resetFields();
      }}
      footer={null}
      centered
      className="PhoneModal-1546"
      title="手机号"
      visible={showModal}
      closable={false}
      onCancel={() => {
        form.resetFields();
        handleOnCancel && handleOnCancel();
      }}
    >
      <Form>
        <Form.Item hasFeedback>
          {form.getFieldDecorator("phone", {
            rules: [
              {
                validator: checkPhone
              }
            ]
          })(
            <Input
              className="PhoneModal-2745"
              maxLength={36}
              placeholder={"请输入手机号"}
              size={"large"}
            />
          )}
        </Form.Item>
        <Form.Item hasFeedback style={{ position: "relative" }}>
          {form.getFieldDecorator("verify", {
            rules: [
              {
                required: true,
                message: "请输入验证码"
              }
            ]
          })(
            <Input
              className="PhoneModal-2745"
              maxLength={6}
              placeholder={"请输入验证码"}
              size={"large"}
            />
          )}
          {isVerifySend ? (
            <CountDownText
              style={{
                position: "absolute",
                top: "-11px",
                right: "30px",
                cursor: "pointer"
              }}
              countDownEndCallback={() => {
                setIsVerifySend(false);
              }}
              countDownTime={60}
              prefixText={""}
              suffixText={"后重发"}
            />
          ) : (
            <div
              style={{
                position: "absolute",
                top: "-11px",
                right: "0px",
                cursor: "pointer"
              }}
              onClick={() => {
                sendVerify();
              }}
            >
              获取验证码
            </div>
          )}
        </Form.Item>
      </Form>
      <div className="PhoneModal-3709">
        <div
          className="PhoneModal-4214"
          onClick={() => {
            form.resetFields();
            handleOnCancel && handleOnCancel();
          }}
        >
          <div className="PhoneModal-4224">取消</div>
        </div>
        <div
          className="PhoneModal-4487"
          onClick={() => {
            submit();
          }}
        >
          <div className="PhoneModal-4497">确定</div>
        </div>
      </div>
    </Modal>
  );
};

const PhoneEditorModalModalWrapper = Form.create()(PhoneEditorModal);

export default PhoneEditorModalModalWrapper;
