import React, { useEffect, useState } from "react";
import FILTER from "../../../../assets/filter.png";
import "../../styles.css";
import ADD_PROJECT from "../../../../assets/corpus/add-dropdown.png";
import { message, Popconfirm } from "antd";
import { useReferenceProjectMutation } from "../../../../generated/graphql";
import { getUrlPath, getUrlSearch } from "../../../../utils/getUrlInfo";

const ORDER_COL = "DESC_COLLECTION_COUNTS";
const ORDER_I = "DESC_INSERTED_AT";

const TabBar = (props) => {
  const {
    cStr,
    isMultiple,
    setCStr,
    setOrder,
    order,
    selectedTags,
    setSelectedTags,
    chosenTag,
  } = props;
  const [referenceProject] = useReferenceProjectMutation();

  const onClickOrder = (e) => {
    setOrder(e.target.id);
    let searchArray = getUrlSearch();
    let order = "";
    if (e.target.id === ORDER_COL) {
      order = "hot";
    } else {
      order = "time";
    }

    let path = "/idea/wiki?order=" + order;
    window.history.pushState(null, "", path);
  };

  const referenceProjectReq = async (val) => {
    const input = { id: window.atob(val.id).split(":")[1] };
    await referenceProject({ variables: { input } });
    return message.success("添加成功");
  };

  const getStyling = (orderType) =>
    order === orderType ? "order-checked" : "order-type";

  return (
    <div
      style={{
        display: "flex",
        background: "white",
        height: 30,
        paddingTop: 3,
      }}
    >
      <div
        style={{
          paddingTop: 3,
          width: 286,
          display: "flex",
          justifyContent: "space-between",
          borderRight: "1px solid rgba(239,240,244,1)",
        }}
      >
        <img style={{ marginLeft: 10, width: 14, height: 14 }} src={FILTER} />
        <section style={{ display: "flex", marginRight: 0 }}>
          <div
            className={getStyling(ORDER_COL)}
            id={ORDER_COL}
            onClick={onClickOrder}
          >
            按热度
          </div>
          <div
            className={getStyling(ORDER_I)}
            id={ORDER_I}
            onClick={onClickOrder}
          >
            按时间
          </div>
        </section>
      </div>
      {cStr ? (
        <div className={"selected-tags"}>
          <div>{cStr}</div>
          <div
            onClick={() => {
              setCStr("");
              setSelectedTags([]);
            }}
            style={{
              cursor: "pointer",
              marginRight: 10,
              marginLeft: 10,
              marginTop: -2,
              fontSize: 13,
              fontWeight: "bold",
            }}
          >
            x
          </div>
        </div>
      ) : null}
      {/*{!isMultiple && selectedTags[0] && !selectedTags[0].value && chosenTag.id*/}
      {/*    ? <Popconfirm*/}
      {/*            placement="bottom"*/}
      {/*            title={`是否添加「${chosenTag.title}」所有卡片到素材「默认金句库」`}*/}
      {/*            onConfirm={async () => {*/}
      {/*                if (chosenTag.id) {*/}
      {/*                    await referenceProjectReq({ id: chosenTag.id});*/}
      {/*                }*/}
      {/*            }}*/}
      {/*            okText="确定"*/}
      {/*            cancelText="取消"*/}
      {/*        >*/}
      {/*            <span className="ins-add-project-container-wiki">*/}
      {/*                <img src={ADD_PROJECT} className="ins-add-project" />*/}
      {/*                <span className="ins-add-project-text">{'全部添加到素材'}</span>*/}
      {/*            </span>*/}
      {/*        </Popconfirm>*/}
      {/*    : null*/}
      {/*}*/}
    </div>
  );
};

export default TabBar;
