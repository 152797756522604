import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件
import MarkHoverTipModal from "../../Components/MarkHoverTipModal/index";

//引入的资源图片

const WordMetaDropdown = (props) => {
  const { position, wordMetaDropdownData } = props;

  const [titleCategory, setTitleCategory] = React.useState("");
  const [explainCategory, setExplainCategory] = React.useState("");
  const [subclassCategory, setSubclassCategory] = React.useState("");
  const [word, setWord] = React.useState("");
  const [examples, setExamples] = React.useState([]);

  React.useEffect(() => {
    if (wordMetaDropdownData) {
      setWord(wordMetaDropdownData.metaWord);
      let cagetory = wordMetaDropdownData.category.split("->");
      setTitleCategory(cagetory[0]);
      setExplainCategory(cagetory[1]);
      setSubclassCategory(cagetory[2]);
      if (wordMetaDropdownData.examples.length) {
        setExamples([wordMetaDropdownData.examples[0]]);
      }else {
        setExamples([]);
      }
    }
  }, [wordMetaDropdownData]);

  const renderCategory = () => {
    return (
      <div className="word-meta-category-container">
        <div className="word-meta-category-word-container">
          <div className="word-meta-category-word-text">{word}</div>
          {titleCategory !== "" && (
            <>
              <div className="word-meta-category-word-category word-meta-category-word-category-dot">
                ·
              </div>
              <div className="word-meta-category-word-category">
                {titleCategory}
              </div>
            </>
          )}
        </div>
        {explainCategory !== "" && (
          <div className="word-meta-category-explain-container">
            <div className="word-meta-category-explain-divider" />
            <div className="word-meta-category-explain-text">{`说明：${explainCategory}`}</div>
          </div>
        )}
        {subclassCategory !== "" && (
          <div className="word-meta-category-explain-container">
            <div className="word-meta-category-explain-divider" />
            <div className="word-meta-category-explain-text">{`子类：${subclassCategory}`}</div>
          </div>
        )}
      </div>
    );
  };

  const renderExampleItem = (item, index) => {
    return (
      <div key={index} className="word-meta-example-item-container">
        <div className="word-meta-example-item-content-container">
          <div className="word-meta-example-item-text">{item.sentence}</div>
        </div>
        <div className="word-meta-example-item-author-container">
          <div className="word-meta-example-item-text">{`--${item.author +
            item.title}`}</div>
        </div>
      </div>
    );
  };

  const renderExample = () => {
    if (!!!examples.length) {
      return null;
    }
    return (
      <div className="word-meta-example-container">
        <div className="word-meta-category-word-container">
          <div className="word-meta-category-word-text">例句</div>
        </div>
        {examples.map((item, index) => {
          return renderExampleItem(item, index);
        })}
      </div>
    );
  };

  const renderBody = () => {
    if (!!!wordMetaDropdownData) {
      return null;
    }
    return (
      <>
        {renderCategory()}
        {renderExample()}
      </>
    );
  };

  return (
    <MarkHoverTipModal position={position} componentId="aw-mark-word-meta">
      <div className="word-meta-dropdown-container">{renderBody()}</div>
    </MarkHoverTipModal>
  );
};

export default WordMetaDropdown;
