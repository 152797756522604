const pageDatas = {
  siderSelectedIndexClient: -1,
  titleIndexClient: 0,
  pageSelectedIndexClient: 0,
  currentRouterClient: "",
};

const functionDatas = {
  projectShowInitTipClient: false, //进入项目后是否显示项目初始化成功（在新建项目时才提醒）
  isShowSearchComponentClient: true, //用来临时隐藏搜索框的
};

const cardPageDatas = {
  currentProjectIdClient: "", //当前选中项目的ID
  projectCardStatsClient: "", //作品中卡片集卡片月度信息
  currentYearStatClient: 0, //当全选中的年份信息
  projectDataClient: {
    cards: null,
    id: "",
    insertedAt: "",
    title: "",
    __typename: "ProjectData",
  }, //项目信息
  projectOutlineClient: "", //项目大纲目录
  projectCardsDataClient: "", //项目的卡片库，项目结构问题，以json储存
  cardSordTypeClient: 0, //0：顺序模式 1：自由模式
  cardFreedomPositionInfosClient: [], //自由模式下卡片位置信息
  defaultSelectedCardIdClient: "", //初始化选中的节点ID
};

const titleBarButton = {
  addNewCardButtonPressClient: false,
  insertCardButtonPressClient: false,
  deleteCardButtonPressClient: false,
};

const writingPageDatas = {
  writingToolsTabIndexClient: 0, //写作业面右边栏工具标签 0: 修改 1: 灵感
  writingTitleBarToolsSaveClickClient: false, //用来触发保存的点击按钮判断
  writingTitleBarToolsSaveTimeClient: "", //存档触发时间
  writingTitleBarToolsAddClickClient: false, //用来触发添加卡片的按钮判断
  writingTitleBarToolsAddChildClickClient: false, //用来触发添加子卡片的按钮判断
  writingTitleBarToolsInsertClickClient: false, //用来触发插入卡片的按钮判断
  writingTitleBarToolsSplitClickClient: false, //用来触发拆分卡片的按钮判断
  writingTitleBarToolsSplitTypeClient: "", //用来触发拆分卡片的类型判断
  writingTitleBarToolsDeleteClickClient: false, //用来触发删除卡片的按钮判断
  writingTitleBarToolsExportClickClient: false, //用来触发导出卡片的按钮判断
  writingChangeProjectNameClickClient: false, //用来触发修改项目名称的判断
  writingChangeProjectNameClient: "", //用来修改项目名称
  writingChangeNameInOutlineIdClient: "", //用来触发大纲中改名操作的ID
};

const fullScreenPageDatas = {
  isShowFullScreenClient: false,
};

const userInfoDatas = {
  userInfoClient: "",
};

const mainDatas = {
  isOnlineClient: window.navigator.onLine, //用来修改项目名称
};

let datas = {
  ...pageDatas,
  ...functionDatas,
  ...cardPageDatas,
  ...titleBarButton,
  ...writingPageDatas,
  ...fullScreenPageDatas,
  ...userInfoDatas,
  ...mainDatas,
};

export default datas;
