import * as React from "react";
import "./styles.css";

import timeLocalStorage from "../../utils/timelocalstorage";
import { history } from "../AiWriterRouter/index";

//引入的库组件
import {
  Avatar,
  Input,
  Icon,
  message,
  Spin,
  Form,
  Row,
  Col,
  Button,
  Upload,
} from "antd";
import axios from "axios";

//引入的其他页面组件
import headers from "../../GlobalData/headers";
import WechatLogin from "./wechatLogin";
import configs from "../../configs";
import CountDownText from "../Components/CountDownText/index";
import { checkPhoneNumber } from "../../utils/index";
import CropperModal from "../Components/CropperModal/index";
import { convertToZH } from "../../utils/convertErrors";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import {
  useVerifyInvitationCodeMutation,
  useSendVerificationCodeMutation,
  useVerfiyWeixinMutation,
  useRegisterMutation,
  useLoginMutation,
  IdentityType,
  useUpdateUserMutation,
} from "../../generated/graphql";

//引入的资源图片
import WeChatIcon from "../../assets/login/wechat-icon.png";
import CloseIcon from "../../assets/globalmodal/close.png";
import BackgroundIcon from "../../assets/login/background.png";
import BackIcon from "../../assets/back-white.png";
import CameraIcon from "../../assets/login/change-avatar.png";
import LogoIcon from "../../assets/logo.png";

const TOKENURL = configs.qiniuUri;
const UPLOADURL = "https://up-z1.qiniup.com";
const type = ["image/jpeg", "image/png"];
const size = 5;

const checkIsMobile = () => {
  let userAgent = navigator.userAgent.toLowerCase();
  if (
    /ipad|iphone|midp|rv:1.2.3.4|micromessenger|ucweb|android|windows ce|windows mobile/.test(
      userAgent
    )
  ) {
    return true;
  }
  return false;
};

const LoginAndRegisterPage = (props) => {
  const client = useApolloClient();
  const { setIsLogin, isWechatRedirect } = props;
  const [loginStep, setLoginStep] = React.useState("main");
  const [registerStep, setRegisterStep] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [inputString, setInputString] = React.useState("");
  const [wechatCode, setWechatCode] = React.useState("");
  const [wechatUserInfo, setWechatUserInfo] = React.useState({});
  const [userNameInput, setUserNameInput] = React.useState("");
  const [isUserNameFocusOn, setIsUserNameFocusOn] = React.useState(false);
  const [penInput, setPenInput] = React.useState("");
  const [isPenFocusOn, setIsPenFocusOn] = React.useState(false);
  const [phoneInput, setPhoneInput] = React.useState("");
  const [isPhoneFocusOn, setIsPhoneFocusOn] = React.useState(false);
  const [verifyCodeInput, setVerifyCodeInput] = React.useState("");
  const [isVerifyFocusOn, setIsVerifyFocusOn] = React.useState(false);
  const [isVerifySend, setIsVerifySend] = React.useState(false);
  const [invitationCode, setInvitationCode] = React.useState("");
  const [showAvatarChange, setShowAvatarChange] = React.useState("");
  const [file, setFile] = React.useState(null);

  const [verifyInvitationCodeMutation] = useVerifyInvitationCodeMutation();
  const [sendVerificationCodeMutation] = useSendVerificationCodeMutation();
  const [verfiyweixinMutation] = useVerfiyWeixinMutation();
  const [registerMutation] = useRegisterMutation();
  const [loginMutation] = useLoginMutation();

  React.useEffect(() => {
    if (isWechatRedirect) {
      let wechatFunc = window.sessionStorage.getItem("WechatFunc");
      let urlSearch = window.sessionStorage.getItem("UrlSearch");
      let paramsStr = urlSearch.substring(1).split("&");
      let params = {};
      for (let i in paramsStr) {
        let str = paramsStr[i];
        params[str.split("=")[0]] = str.split("=")[1];
      }

      // let url = `/wechat?appid=${configs.appid}&secret=${configs.secret}&code=${params["code"]}&grant_type=authorization_code`;
      // axios.get(url).then(res => {
      //   setWechatUserInfo(res.data);
      //   setIsLoading(false);
      //   setRegisterStep(4);
      // });

      if (wechatFunc === "register") {
        verfiyweixinMutation({ variables: { code: params["code"] } }).then(
          (res) => {
            if (!res.data.verifyWeixin.successful) {
              // message.error(res.data.verifyWeixin.messages[0].message);
              message.error(convertToZH(res.data.verifyWeixin.messages[0]));
              setRegisterStep(1);
            } else {
              setInvitationCode(
                window.sessionStorage.getItem("InvitationCode")
              );
              setWechatUserInfo(res.data.verifyWeixin.result);
              setPenInput(res.data.verifyWeixin.result.nickname);
              setRegisterStep(4);
            }
            window.sessionStorage.removeItem("InvitationCode");
            window.sessionStorage.removeItem("WechatFunc");
            window.sessionStorage.removeItem("UrlSearch");
            setIsLoading(false);
          }
        );
        setIsLoading(true);
        setLoginStep("register");
        setRegisterStep(3);
      } else if (wechatFunc === "login") {
        let input = {
          identifier: params["code"],
          identityType: IdentityType.Weixin,
        };
        loginMutation({ variables: { input } }).then((res) => {
          if (!res.data.login.successful) {
            // message.error(res.data.login.messages[0].message);
            message.error(convertToZH(res.data.login.messages[0]));
            setLoginStep("main");
            resetState();
          } else {
            let result = res.data.login.result;
            timeLocalStorage.set("TOKENINFO", result.token, 60 * 60 * 24 * 7);
            timeLocalStorage.set("USERINFO", result.user, 60 * 60 * 24 * 7);
            headers.Authorization = "Bearer " + result.token;
            client.writeData({
              data: { userInfoClient: JSON.stringify(result.user) },
            });
            resetState();
            setIsLogin(true);
            history.push("/template");
          }
          window.sessionStorage.removeItem("InvitationCode");
          window.sessionStorage.removeItem("WechatFunc");
          window.sessionStorage.removeItem("UrlSearch");
          setIsLoading(false);
        });
        setIsLoading(true);
        setLoginStep("wechatLogin");
      }
    }
  }, [isWechatRedirect]);

  const resetState = () => {
    setRegisterStep(1);
    setIsLoading(false);
    setInputString("");
    setWechatCode("");
    setWechatUserInfo({});
    setUserNameInput("");
    setIsUserNameFocusOn(false);
    setPenInput("");
    setIsPenFocusOn(false);
    setPhoneInput("");
    setIsPhoneFocusOn(false);
    setVerifyCodeInput("");
    setIsVerifyFocusOn(false);
    setIsVerifySend(false);
    setInvitationCode("");
  };

  const beforeUpload = (file) => {
    let isType = true;
    let isSize = true;
    if (type) {
      isType = type.includes(file.type);
    }
    if (size) {
      isSize = file.size / 1024 / 1024 < size;
    }

    if (!isType) {
      message.error(`不支持 ${file.type} 文件类型`);
    }
    if (!isSize) {
      message.error(`文件限制 ${size} M`);
    }

    if (isType && isSize) {
      setFile(file);
    }
    return false;
  };

  const handleOnAvatar = (src) => {
    setWechatUserInfo({ ...wechatUserInfo, headimgurl: src });
    message.success("修改成功");
  };

  const loginSumit = () => {
    if (phoneInput.trim() === "") {
      message.warn("手机号不能为空");
      return;
    }
    if (!checkPhoneNumber(phoneInput.trim())) {
      message.warn("手机号格式不正确");
      return;
    }
    if (verifyCodeInput.trim() === "") {
      message.warn("验证码不能为空");
      return;
    }
    setIsLoading(true);
    let input = {
      credential: verifyCodeInput.trim(),
      identifier: phoneInput.trim(),
      identityType: IdentityType.VerificationCode,
    };
    loginMutation({ variables: { input } }).then((res) => {
      if (!res.data.login.successful) {
        // message.error(res.data.login.messages[0].message);
        message.error(convertToZH(res.data.login.messages[0]));
        // setLoginStep("main");
        // resetState();
      } else {
        let result = res.data.login.result;
        timeLocalStorage.set("TOKENINFO", result.token, 60 * 60 * 24 * 7);
        timeLocalStorage.set("USERINFO", result.user, 60 * 60 * 24 * 7);
        headers.Authorization = "Bearer " + result.token;
        client.writeData({
          data: { userInfoClient: JSON.stringify(result.user) },
        });
        resetState();
        setIsLogin(true);
        history.push("/template");
      }
      setIsLoading(false);
    });
  };

  const registerSubmit = async () => {
    if (userNameInput.trim() === "") {
      message.warn("用户名不能为空");
      return;
    }
    let regex = /^[0-9A-Za-z]+$/;
    if (!regex.test(userNameInput)) {
      message.warn("用户名只能使用英文字母和数字");
      return;
    }
    if (penInput.trim() === "") {
      message.warn("笔名不能为空");
      return;
    }
    if (phoneInput.trim() === "") {
      message.warn("手机号不能为空");
      return;
    }
    if (!checkPhoneNumber(phoneInput.trim())) {
      message.warn("手机号格式不正确");
      return;
    }
    if (verifyCodeInput.trim() === "") {
      message.warn("验证码不能为空");
      return;
    }
    setIsLoading(true);

    try {
      let imgString = "";
      if (wechatUserInfo.headimgurl.indexOf("data:image") !== -1) {
        imgString = wechatUserInfo.headimgurl;
      } else {
        let headImgUrl =
          wechatUserInfo.headimgurl.indexOf("http://") !== -1
            ? wechatUserInfo.headimgurl.replace("http://", "https://")
            : wechatUserInfo.headimgurl;
        const res = await fetch(headImgUrl, { method: "GET" });
        const blob = await res.blob();

        imgString = blob;
      }

      const requestToken = await fetch(TOKENURL, {
        method: "GET",
        headers: headers,
      });
      const resultToken = await requestToken.text();
      const token = resultToken;
      const formData = new FormData();
      formData.append("token", token);
      formData.append("file", imgString);
      const upload = await fetch(UPLOADURL, {
        method: "POST",
        body: formData,
      });
      const resultUpload = await upload.json();
      const src = `https://static.aiwriter.net/${resultUpload.key}`;

      let input = {
        avatar: src,
        invitationCode: invitationCode,
        nickname: penInput.trim(),
        openid: wechatUserInfo.openid,
        phone: phoneInput.trim(),
        username: userNameInput.trim(),
        verificationCode: verifyCodeInput.trim(),
      };

      registerMutation({ variables: { input } }).then((res) => {
        setIsLoading(false);
        if (!res.data.register.successful) {
          // message.error(res.data.register.messages[0].message);
          message.error(convertToZH(res.data.register.messages[0]));
        } else {
          setLoginStep("main");
          resetState();
          message.success("注册成功，请登录");
        }
      });
    } catch (error) {
      message.error("上传失败，请检查网络设置");
      setIsLoading(false);
    }
  };

  const renderLoginMain = () => {
    let avatar = window.localStorage.getItem("UserInfo")
      ? window.localStorage.getItem("UserInfo").avatar
      : LogoIcon;
    return (
      <div className="home-page-main-login-main">
        <div
          className="home-page-main-login-main-register"
          onClick={() => {
            if (checkIsMobile()) {
              message.warning("请在PC端web浏览器注册");
              return;
            }
            setLoginStep("register");
            setRegisterStep(1);
          }}
        >
          注册
        </div>
        <div className="home-page-main-login-main-tip">
          <Avatar src={avatar} size={70} />
          <div className="home-page-main-login-main-tip-text-1">你好 !</div>
          <div className="home-page-main-login-main-tip-text-2">
            欢迎使用写匠
          </div>
        </div>
        <div className="home-page-main-login-main-button-container">
          <div
            className="home-page-main-login-main-button-login-background"
            onClick={() => {
              if (checkIsMobile()) {
                message.warning("请在PC端web浏览器扫码登录");
                return;
              }
              setLoginStep("wechatLogin");
              window.sessionStorage.setItem("WechatFunc", "login");
            }}
          >
            <div className="home-page-main-login-main-button-login-container">
              <img
                src={WeChatIcon}
                className="home-page-main-login-main-button-login-icon "
              />
              <div className="home-page-main-login-main-button-login-text">
                微信扫码登录
              </div>
            </div>
          </div>
          <div
            className="home-page-main-login-main-button-mobile-text"
            onClick={() => {
              setLoginStep("phoneLogin");
            }}
          >
            手机号登录
          </div>
        </div>
      </div>
    );
  };

  const renderRegisterInvitation = () => {
    return (
      <div className="home-page-main-register-invitation-code-container">
        <div className="home-page-main-register-invitation-code-input-container">
          <div className="home-page-main-register-invitation-code-input-text">
            邀请码
          </div>
          <Input
            className="home-page-main-register-invitation-code-input-input"
            maxLength={36}
            placeholder={"请填写邀请码"}
            size={"large"}
            onChange={(e) => {
              setInputString(e.target.value);
            }}
          />
        </div>
        <div
          className="home-page-main-register-invitation-code-buton-container"
          onClick={() => {
            if (isLoading) {
              return;
            }
            setIsLoading(true);
            verifyInvitationCodeMutation({
              variables: { invitationCode: inputString },
            }).then((res) => {
              if (!res.data.verifyInvitationCode.successful) {
                message.error(
                  convertToZH(res.data.verifyInvitationCode.messages[0])
                );
              } else {
                window.sessionStorage.setItem("InvitationCode", inputString);
                window.sessionStorage.setItem("WechatFunc", "register");
                setInputString("");
                setRegisterStep(2);
              }
              setIsLoading(false);
            });
          }}
        >
          {!isLoading ? (
            <div className="home-page-main-register-invitation-code-buton-text">
              下一步
            </div>
          ) : (
            <Icon type="loading" style={{ color: "#ffffff" }} />
          )}
        </div>
      </div>
    );
  };

  const renderRegisterWechat = () => {
    return (
      <div className="home-page-main-register-invitation-wechat-container">
        <WechatLogin
          style={{ marginTop: "90px", width: "400px", height: "300px" }}
        />
      </div>
    );
  };

  const renderRegisterLoading = () => {
    return (
      <div className="home-page-main-register-invitation-loading-container">
        <Spin spinning={isLoading} size="large" tip="正在获取数据" />
      </div>
    );
  };

  const renderRegisterUserInfo = () => {
    const { form } = props;
    return (
      <div className="home-page-main-register-user-info-container">
        <div className="home-page-main-register-user-info-avatar-background">
          <Avatar
            src={wechatUserInfo.headimgurl}
            size={60}
            onMouseEnter={(e) => {
              setShowAvatarChange(true);
            }}
          />
          <Upload
            name="file"
            listType="text"
            showUploadList={false}
            beforeUpload={beforeUpload}
            className={
              showAvatarChange
                ? "home-page-main-register-user-info-avatar-container"
                : "home-page-main-register-user-info-avatar-container-none"
            }
          >
            <div
              className="home-page-main-register-user-info-avatar-modify"
              onMouseOut={() => {
                setShowAvatarChange(false);
              }}
            >
              <img
                src={CameraIcon}
                style={{
                  color: "#FFFFFF",
                  pointerEvents: "none",
                  width: "26px",
                  height: "22px",
                }}
              />
            </div>
          </Upload>
        </div>

        <div className="home-page-main-register-user-info-name-container">
          <div className="home-page-main-register-user-info-name-text">
            用户名
          </div>
          <div
            className={
              isUserNameFocusOn
                ? "home-page-main-register-user-info-name-input-container-focus"
                : "home-page-main-register-user-info-name-input-container"
            }
          >
            <Input
              className="home-page-main-register-user-info-name-input"
              maxLength={15}
              placeholder={"请填写用户名"}
              size={"large"}
              onFocus={() => {
                setIsUserNameFocusOn(true);
              }}
              onBlur={() => {
                setIsUserNameFocusOn(false);
              }}
              onChange={(e) => {
                setUserNameInput(e.target.value);
              }}
            />
            <div
              className="home-page-main-register-user-info-name-input-tip"
              style={isUserNameFocusOn ? {} : { display: "none" }}
            >
              设置成功后不能修改
            </div>
          </div>
        </div>

        <div className="home-page-main-register-user-info-pen-container">
          <div className="home-page-main-register-user-info-pen-text">笔名</div>
          <div
            className={
              isPenFocusOn
                ? "home-page-main-register-user-info-pen-input-container-focus"
                : "home-page-main-register-user-info-pen-input-container"
            }
          >
            <Input
              className="home-page-main-register-user-info-pen-input"
              maxLength={36}
              placeholder={"请填写笔名"}
              size={"large"}
              defaultValue={wechatUserInfo.nickname}
              onFocus={() => {
                setIsPenFocusOn(true);
              }}
              onBlur={() => {
                setIsPenFocusOn(false);
              }}
              onChange={(e) => {
                setPenInput(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="home-page-main-register-user-info-pen-container">
          <div className="home-page-main-register-user-info-pen-text">
            手机号
          </div>
          <div
            className={
              isPhoneFocusOn
                ? "home-page-main-register-user-info-pen-input-container-focus"
                : "home-page-main-register-user-info-pen-input-container"
            }
          >
            <Input
              className="home-page-main-register-user-info-pen-input"
              maxLength={36}
              placeholder={"请填写手机号"}
              size={"large"}
              onFocus={() => {
                setIsPhoneFocusOn(true);
              }}
              onBlur={() => {
                setIsPhoneFocusOn(false);
              }}
              onChange={(e) => {
                setPhoneInput(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="home-page-main-register-user-info-verify-container">
          <div className="home-page-main-register-user-info-verify-text">
            验证码
          </div>
          <div
            className={
              isVerifyFocusOn
                ? "home-page-main-register-user-info-verify-input-container-focus"
                : "home-page-main-register-user-info-verify-input-container"
            }
            // onBlur={() => {
            //   setIsVerifyFocusOn(false);
            // }}
          >
            <Input
              className="home-page-main-register-user-info-verify-input"
              maxLength={36}
              placeholder={"请填写验证码"}
              size={"large"}
              onFocus={() => {
                setIsVerifyFocusOn(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setIsVerifyFocusOn(false);
                }, 100);
              }}
              onChange={(e) => {
                setVerifyCodeInput(e.target.value);
              }}
            />
            <Button
              style={
                true || isVerifyFocusOn || isVerifySend
                  ? {}
                  : { display: "none" }
              }
              disabled={isVerifySend}
              onClick={(e) => {
                if (!checkPhoneNumber(phoneInput.trim())) {
                  message.warn("手机号格式不正确");
                } else {
                  setIsVerifySend(true);
                  sendVerificationCodeMutation({
                    variables: { phone: phoneInput.trim() },
                  }).then((res) => {
                    if (!res.data.sendVerificationCode.successful) {
                      // message.error(res.data.verifyWeixin.messages[0].message);
                      message.error(
                        convertToZH(res.data.verifyWeixin.messages[0])
                      );
                    }
                  });
                }
              }}
            >
              {isVerifySend ? (
                <CountDownText
                  countDownEndCallback={() => {
                    setIsVerifySend(false);
                  }}
                  countDownTime={60}
                  prefixText={""}
                  suffixText={"后重发"}
                />
              ) : (
                <div>点击获取验证码</div>
              )}
            </Button>
          </div>
        </div>

        <div
          className="home-page-main-register-user-info-buton-container"
          onClick={() => {
            if (isLoading) {
              return;
            }
            registerSubmit();
          }}
        >
          {!isLoading ? (
            <div className="home-page-main-register-user-info-buton-text">
              完成
            </div>
          ) : (
            <Icon type="loading" style={{ color: "#ffffff" }} />
          )}
        </div>
        <CropperModal
          file={file}
          successCallback={handleOnAvatar}
          isUpload={false}
        />
      </div>
    );
  };

  const renderRegister = () => {
    return (
      <div className="home-page-main-register">
        <div className="home-page-main-register-title-container">
          <div
            className="home-page-main-register-title-back"
            onClick={() => {
              setLoginStep("main");
              resetState();
            }}
          >
            <img
              src={BackIcon}
              style={{ width: "6px", height: "10px", marginRight: "14px" }}
            />
            {"注册"}
          </div>
          <div className="home-page-main-register-title-step-container">
            <div className="home-page-main-register-title-step">
              <div className="home-page-main-register-title-step-item-container">
                <div
                  className={
                    registerStep === 1
                      ? "home-page-main-register-title-step-item-circle-item-text-selected"
                      : "home-page-main-register-title-step-item-circle-item-text"
                  }
                >
                  填写邀请码
                </div>
                <div className="home-page-main-register-title-step-item-circle-1">
                  <div className="home-page-main-register-title-step-item-circle-2">
                    <div className="home-page-main-register-title-step-item-circle-item">
                      1
                    </div>
                  </div>
                </div>
              </div>

              <div className="home-page-main-register-title-step-item-container">
                <div
                  className={
                    registerStep === 2
                      ? "home-page-main-register-title-step-item-circle-item-text-selected"
                      : "home-page-main-register-title-step-item-circle-item-text"
                  }
                >
                  绑定微信
                </div>
                <div className="home-page-main-register-title-step-item-circle-1">
                  <div className="home-page-main-register-title-step-item-circle-2">
                    <div className="home-page-main-register-title-step-item-circle-item">
                      2
                    </div>
                  </div>
                </div>
              </div>

              <div className="home-page-main-register-title-step-item-container">
                <div
                  className={
                    registerStep === 4
                      ? "home-page-main-register-title-step-item-circle-item-text-selected"
                      : "home-page-main-register-title-step-item-circle-item-text"
                  }
                >
                  填写信息
                </div>
                <div className="home-page-main-register-title-step-item-circle-1">
                  <div className="home-page-main-register-title-step-item-circle-2">
                    <div className="home-page-main-register-title-step-item-circle-item">
                      3
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home-page-main-register-body-container">
          {registerStep === 1 && renderRegisterInvitation()}
          {registerStep === 2 && renderRegisterWechat()}
          {registerStep === 3 && renderRegisterLoading()}
          {registerStep === 4 && renderRegisterUserInfo()}
        </div>
      </div>
    );
  };

  const renderWechatLogin = () => {
    return (
      <Spin spinning={isLoading}>
        <div className="home-page-main-login-wechat">
          {isLoading ? (
            <div />
          ) : (
            <>
              <img
                src={CloseIcon}
                className="home-page-main-login-main-close"
                onClick={() => {
                  window.sessionStorage.removeItem("WechatFunc");
                  setLoginStep("main");
                  resetState();
                }}
              />
              <WechatLogin
                style={{ width: "100%", height: "100%", marginTop: "30px" }}
              />
            </>
          )}
        </div>
      </Spin>
    );
  };

  const renderPhoneLogin = () => {
    return (
      <Spin spinning={isLoading}>
        <div className="home-page-main-login-phone">
          <img
            src={CloseIcon}
            className="home-page-main-login-main-close"
            onClick={() => {
              setLoginStep("main");
              resetState();
            }}
          />
          <div className="home-page-main-login-phone-phone-tip">手机号登录</div>
          <div className="home-page-main-login-phone-phone-container">
            <div className="home-page-main-login-phone-phone-text">手机号</div>
            <Input
              className="home-page-main-login-phone-phone-input"
              maxLength={36}
              placeholder={"请填写手机号"}
              size={"large"}
              onChange={(e) => {
                setPhoneInput(e.target.value);
              }}
            />
          </div>
          <div className="home-page-main-login-phone-verify-container">
            <div className="home-page-main-login-phone-verify-text">验证码</div>
            <Input
              className="home-page-main-login-phone-verify-input"
              maxLength={36}
              placeholder={"请填写验证码"}
              size={"large"}
              onChange={(e) => {
                setVerifyCodeInput(e.target.value);
              }}
            />
            <div className="home-page-main-login-phone-verify-button-container">
              {isVerifySend ? (
                <CountDownText
                  countDownEndCallback={() => {
                    setIsVerifySend(false);
                  }}
                  countDownTime={60}
                  prefixText={""}
                  suffixText={"后重发"}
                  style={{
                    fontSize: "12px",
                    fontFamily: "PingFangSC-Regular",
                    fontWeight: "400",
                    color: "rgba(54,54,63,1)",
                    lineHeight: "18px",
                  }}
                />
              ) : (
                <div
                  style={{
                    fontSize: "12px",
                    fontFamily: "PingFangSC-Regular",
                    fontWeight: "400",
                    color: "rgba(54,54,63,1)",
                    lineHeight: "18px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!checkPhoneNumber(phoneInput.trim())) {
                      message.warn("手机号格式不正确");
                    } else {
                      setIsVerifySend(true);
                      sendVerificationCodeMutation({
                        variables: { phone: phoneInput.trim() },
                      }).then((res) => {
                        if (!res.data.sendVerificationCode.successful) {
                          // message.error(
                          //   res.data.sendVerificationCode.messages[0].message
                          // );
                          message.error(
                            convertToZH(
                              res.data.sendVerificationCode.messages[0]
                            )
                          );
                        }
                      });
                    }
                  }}
                >
                  获取验证码
                </div>
              )}
            </div>
          </div>
          <div
            className="home-page-main-login-phone-login-button-container"
            onClick={() => {
              if (isLoading) {
                return;
              }
              loginSumit();
            }}
          >
            {isLoading ? (
              <Icon type="loading" style={{ color: "#ffffff" }} />
            ) : (
              <div className="home-page-main-login-phone-login-button-text">
                登录
              </div>
            )}
          </div>
          {/* <Button
            className="home-page-main-login-phone-login-button-container"
            onClick={() => {
              loginSumit();
            }}
            loading={isLoading}
          >
            登录
          </Button> */}
        </div>
      </Spin>
    );
  };

  const renderContent = () => {
    switch (loginStep) {
      case "main":
        return renderLoginMain();
      case "register":
        return renderRegister();
      case "wechatLogin":
        return renderWechatLogin();
      case "phoneLogin":
        return renderPhoneLogin();
    }
  };

  const renderGreatLine = () => {
    return (
      <div className="home-page-main-login-great-line-container">
        <div className="home-page-main-login-great-line-text"></div>
      </div>
    );
  };

  return (
    <>
      {loginStep === "register" ? (
        <div className="home-page-main-container">{renderContent()}</div>
      ) : (
        <div
          className="home-page-main-container"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundImage: `url(${BackgroundIcon})`,
            justifyContent: "space-around",
          }}
        >
          {renderGreatLine()}
          {renderContent()}
          <div className="home-page-record-number-container">
            <a
              href="https://beian.miit.gov.cn"
              className="home-page-record-number"
            >
              京ICP备2024044642号-7
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const LoginAndRegisterPageWrapper = Form.create()(LoginAndRegisterPage);

export default LoginAndRegisterPageWrapper;
