import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Pagination, Row, Col, Spin } from "antd";
import { uuid } from "uuidv4";
import moment from "moment";
import { convertToZH } from "aw-error";

//引入的其他页面组件
import AddNewTemplate from "./AddNewTemplate/addNewTemplate";
import CreateModal from "../Components/CreateModal";
import { renderPagination } from "../Components/Pagination/index";
import { getPaginationPageVariable } from "../../utils/index";
import timelocalstorage from "../../utils/timelocalstorage";
import headers from "../../GlobalData/headers";
import { getProjectTempName } from "../../utils/projectTempName";

import MainContainer from "../Router_MainContainer/index";
import { getUrlSearch } from "../../utils/getUrlInfo";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import {
  useCreateProjectMutation,
  useGetThemesQuery,
  useCreateProjectByThemeMutation,
  useGetCardsQuery,
} from "../../generated/graphql";

const PAGE_COUNT = 18;
const themesCategories = [
  {
    name: "通用",
  },
  {
    name: "生活",
  },
  {
    name: "创意",
  },
  {
    name: "新媒体",
  },
  {
    name: "教育",
  },
  {
    name: "产品",
  },
  {
    name: "学术",
  },
  {
    name: "图书",
  },
];

const Template = (props) => {
  const { setIsLogin, history } = props;

  const [createProjectByThemeMutation] = useCreateProjectByThemeMutation();

  const [isLoading, setIsLoading] = React.useState(true);
  const [isInit, setIsInit] = React.useState(false);
  const [headerIndex, setHeaderIndex] = React.useState(0);
  const [themesList, setThemesList] = React.useState([]);
  const [currentThemesPage, setCurrentThemesPage] = React.useState(1);
  const [themesPageInfo, setThemesPageInfo] = React.useState({
    count: 0,
    pageSize: PAGE_COUNT,
    pageInfo: null,
  });

  React.useEffect(() => {
    let searchParam = getUrlSearch();
    let category = 0;
    if (
      !getUrlSearch() ||
      !searchParam.category ||
      !(
        searchParam.category >= 0 &&
        searchParam.category < themesCategories.length
      )
    ) {
      history.push("/template?category=0");
    } else {
      category = parseInt(searchParam.category);
    }
    setIsInit(true);
    setHeaderIndex(category);
  }, []);

  React.useEffect(() => {
    if (isInit) {
      let variables = {
        after: undefined,
        first: PAGE_COUNT,
        before: undefined,
        last: undefined,
        filter: { categories: [themesCategories[headerIndex].name] },
      };

      getThemes(variables, () => {
        setCurrentThemesPage(1);
        //加载完毕更改标签名
        document.title = `新建-${themesCategories[headerIndex].name}模板`;
      });
    }
  }, [isInit, headerIndex]);

  const { refetch: getThemesQuery } = useGetThemesQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const getThemes = (variables, callback) => {
    setIsLoading(true);
    getThemesQuery(variables).then((res) => {
      setIsLoading(false);
      if (res.data.themes) {
        let pageInfo = res.data.themes.pageInfo;
        let count = res.data.themes.count;
        let themes = res.data.themes.edges.map((item, index) => {
          return item.node;
        });
        setThemesPageInfo({
          ...themesPageInfo,
          pageInfo,
          count,
        });
        setThemesList(themes);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取模板失败");
      }
    });
  };

  const createProjectByTheme = async (
    projectName,
    id = undefined,
    coverImageUrl = ""
  ) => {
    let input = {
      title: projectName,
      id: id ? window.atob(id).split("Theme:")[1] : undefined,
      coverImageUrl: coverImageUrl,
    };
    await createProjectByThemeMutation({ variables: { input: input } }).then(
      async (res) => {
        if (!res.data.createProjectByTheme.successful) {
          message.error(convertToZH(res.data.createProjectByTheme.messages[0]));
        } else {
          let project = res.data.createProjectByTheme.result;
          // history.push("/project/writing?projectid=" + project.id);
          let code = window.atob(project.code).split("Project:")[1];
          history.push(`/project/writing/p/${code}?tab=2`);
        }
      }
    );
  };

  const renderTitleContent = () => {
    return (
      <div className="template-header-container">
        <div className="template-header-tab-container">
          {themesCategories.map((item, index) => {
            return (
              <div
                key={"theme-header-" + index}
                className={
                  index === headerIndex
                    ? "template-header-tab-item-selected"
                    : "template-header-tab-item"
                }
                onClick={() => {
                  if (headerIndex !== index) {
                    let pathname = window.location.pathname;
                    history.push(pathname + "?category=" + index);
                    setHeaderIndex(index);
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderBodyContent = () => {
    let themesListTemp = [];

    themesList.forEach((item) => {
      if (item.id !== "" && item.title === "默认") {
        themesListTemp.unshift(item);
      } else {
        themesListTemp.push(item);
      }
    });
    return (
      <div className="template-list-container">
        <Row gutter={[16, 24]}>
          {themesListTemp.map((item, index) => {
            return (
              <Col span={4} key={`template-${headerIndex}-${item.id}`}>
                <AddNewTemplate
                  key={"template" + index}
                  templateInfo={item}
                  templateClick={() => {
                    if (item.id !== "" && item.title !== "默认") {
                      createProjectByTheme(
                        item.title,
                        item.id,
                        item.coverImageUrl
                      );
                    } else {
                      createProjectByTheme(getProjectTempName());
                    }
                  }}
                />
              </Col>
            );
          })}
        </Row>
        <Pagination
          className="template-page-container"
          pageSize={PAGE_COUNT}
          itemRender={renderPagination}
          current={currentThemesPage}
          total={themesPageInfo.count}
          hideOnSinglePage
          onChange={(page) => {
            let pageInfo = {
              ...themesPageInfo,
              pageSize: PAGE_COUNT,
            };
            let variables = getPaginationPageVariable(
              page,
              currentThemesPage,
              pageInfo
            );
            variables = {
              ...variables,
              filter: { categories: [themesCategories[headerIndex].name] },
            };
            getThemes(variables, () => {
              setCurrentThemesPage(page);
            });
          }}
        />
      </div>
    );
  };

  return (
    <MainContainer
      currentSiderIndex={0}
      setIsLogin={setIsLogin}
      isBodyLoading={isLoading}
      bodyContent={renderBodyContent()}
      headerContent={renderTitleContent()}
    />
  );
};

export default Template;
