import * as React from "react";
import "./styles.css";

import { Dropdown, Menu, Icon } from "antd";

const YearDrop = (props) => {
  const { years = [], selectedCallback, position = null, currentYear = "全部" } = props;

  const cardStatsOverlay = () => {
    return (
      <Menu
        onClick={(item) => {
          if (selectedCallback) {
            selectedCallback(parseInt(item.key));
          }
        }}
        className="year-dropdown-list"
      >
        <Menu.Item className="year-dropdown-item" key={-1}>
          全部
        </Menu.Item>
        {years.map((item, index) => {
          return (
            <Menu.Item className="year-dropdown-item" key={index}>
              {item.year + "年"}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className="year-dropdown-container" style={position ? position : {}}>
      <Dropdown
        className="year-dropdown-background"
        trigger={["click"]}
        overlay={cardStatsOverlay}
      >
        <div
          className="ant-dropdown-link"
          style={{ cursor: "pointer", color: "#000000" }}
        >
          <div className="year-dropdown-text">{currentYear}</div>
          <Icon type="down" className="year-dropdown-icon" />
        </div>
      </Dropdown>
    </div>
  );
};

export default YearDrop;
