import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件
import Help from "./components/help";
import Title from "./components/title";
import { getModuleTip } from "./moduleTips";
import { WordCloud } from "./components/wordCloud";
import { LineChart } from "./components/lineChart";
import { WatchChart } from "./components/watchChart";
import { getWatchColor } from "./utils";

//graphql查询

//引入的资源图片
import charCountIcon from "../../../assets/writing/statistic/char_count.png";
import wordCountIcon from "../../../assets/writing/statistic/word_count.png";
import SentCountIcon from "../../../assets/writing/statistic/sent_count.png";

const WordCloudColors = ["#CED7E1", "#9BCAF5", "#7F97B0"];

const Article = (props) => {
  const { data } = props;

  React.useEffect(() => {
    if (data) {
      makeWordCloud(data.wordCloudData, "word_cloud", WordCloudColors);
      makeLineChart(data.conciseness, "conciseness");
      makeLineChart(data.chars, "char_count", "#FFB3CB");
      makeLineChart(data.words, "word_count", "#FFCF00");
      makeLineChart(data.sents, "sent_count", "#2BDEB6");
      makeWatchChart(
        data.aveConciseness,
        "ave-conciseness",
        getWatchColor(data.aveConciseness[0].value * 100),
        {
          style: {
            fontSize: 12,
            fill: "#36363F",
            fontWeight: 400,
            letterSpacing: 0,
            textAlign: "center",
          },
        },
        {
          style: {
            fontSize: 26,
            fill: "#36363F",
            fontWeight: 800,
            letterSpacing: 0,
            textAlign: "center",
          },
        }
      );
    }
  }, [data]);

  const makeWordCloud = (chartData, id, colors) => {
    WordCloud(chartData, id, colors);
  };

  const makeLineChart = (chartData, id, color = null) => {
    LineChart(chartData, id, color);
  };

  const makeWatchChart = (
    chartData,
    id,
    color = null,
    textStyle = null,
    countStyle = null
  ) => {
    WatchChart(chartData, id, color, textStyle, countStyle);
  };

  const renderWordCloud = () => {
    return (
      <div className="statistics-module-background statistics-article-cloud-container">
        <Help
          tip={getModuleTip("passage-word_cloud")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"词云"} />
        <div id="word_cloud" className="statistics-article-cloud-item" />
      </div>
    );
  };

  const renderConciseness = () => {
    return (
      <div className="statistics-module-background statistics-article-conciseness-container">
        <Help
          tip={getModuleTip("passage-difficulty")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"篇章难易度"} />
        <div
          id="conciseness"
          className="statistics-article-conciseness-chart"
        />
        <div className="statistics-article-conciseness-watch-item-container">
          <div
            id="ave-conciseness"
            className="statistics-article-conciseness-watch-item"
          />
        </div>
      </div>
    );
  };

  const renderEachChart = (title, icon, id) => {
    return (
      <div className="statistics-article-count-container">
        <div className="statistics-article-count-title-container">
          <img src={icon} className="statistics-article-count-title-icon" />
          <div className="statistics-article-count-title-text">{title}</div>
        </div>
        <div id={id} className="statistics-article-count-chart" />
      </div>
    );
  };

  const renderCharSent = () => {
    return (
      <div className="statistics-module-background statistics-article-count-container">
        <Help
          tip={getModuleTip("passage-char_word_sent")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"字词句"} />
        {renderEachChart("字数", charCountIcon, "char_count")}
        {renderEachChart("词数", wordCountIcon, "word_count")}
        {renderEachChart("句数", SentCountIcon, "sent_count")}
        <div className="statistics-article-ave-container">
          <div className="statistics-article-ave-item">
            <div
              className="statistics-article-ave-item-count"
              style={{ color: "#2bdeb6" }}
            >
              {data.aveWordLength.value}
            </div>
            <div className="statistics-article-ave-item-text">
              {data.aveWordLength.name}
            </div>
          </div>
          <div className="statistics-article-ave-item">
            <div
              className="statistics-article-ave-item-count"
              style={{ color: "#FFB048" }}
            >
              {data.aveSentLength.value}
            </div>
            <div className="statistics-article-ave-item-text ">
              {data.aveSentLength.name}
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!data) {
    return null;
  }

  return (
    <div className="statistics-single-container">
      {renderWordCloud()}
      {renderConciseness()}
      {renderCharSent()}
    </div>
  );
};

export default Article;
