import * as React from "react";
import "./styles.css";

import EmptyIcon from "../../../assets/no-card.png";

const EmptyComponent = props => {
  const { tip } = props;
  return (
    <div className="empty-component-container">
      <img src={EmptyIcon} className="empty-component-icon" />
      {tip && tip !== "" && <div className="empty-component-text">{tip}</div>}
    </div>
  );
};

export default EmptyComponent;
