/**
 * Created by wangna on 2017/6/18.
 */
import React, { PureComponent } from 'react';
// import PropTypes from 'prop-types';
import './panel.css';
import classnames from '../classnames';

export default class Panel extends PureComponent {

    onToggle = (e) => {
        const { onToggle } = this.props;
        return onToggle && onToggle(e);
    };

    render() {
        const { header, children, abc, openPanel, className } = this.props;
        const cls = classnames('web-panel', {
            [className]: !!className,
            closing: !openPanel,
            opening: openPanel,
        });

        return (
            <div
                style={{ marginLeft: 22, marginRight: 10 }}
                ref={abc}
                className={cls}
                onClick={this.onToggle}>
                {header}
                <div className="web-panel-content">
                    {children}
                </div>
            </div>
        );
    }
}

Panel.defaultProps = {
    header: 'panel-header',
};
