import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Spin, Row, Col } from "antd";

//引入的其他页面组件
import SearchWithoutDrop from "../../../Components/Search/searchWithoutDrop";
import EmptyComponent from "../../../Components/EmptyComponent/index";

//graphql查询
import {
  useGetDeleteProjectListQuery,
  SortOrder,
  ProjectType,
} from "../../../../generated/graphql";

//引入的资源图片
import DefaultCover from "../../../../assets/project/default-cover.png";

const PER_PAGE_COUNT = 12;

const MyDeleteProjectsContainer = (props) => {
  const { isShow, cancelCallback, submitCallback } = props;

  const [isLoadingList, setIsLoadingList] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [deleteProjectList, setDeleteProjectList] = React.useState([]);
  const [
    deleteProjectListPageInfo,
    setDeleteProjectListPageInfo,
  ] = React.useState({
    count: 0,
    pageSize: PER_PAGE_COUNT,
    pageInfo: null,
  });

  const { refetch: getDeleteProjectListQuery } = useGetDeleteProjectListQuery({
    fetchPolicy: "network-only",
    skip: true,
    errorPolicy: "all",
  });

  React.useEffect(() => {
    if (isShow) {
      let input = {
        after: undefined,
        first: PER_PAGE_COUNT,
        matching: searchString !== "" ? searchString : undefined,
        order: SortOrder.Desc,
      };
      getDeleteProjectList(input);
    } else {
      setSelectedProject(null);
      setSearchString("");
      setDeleteProjectList([]);
    }
  }, [isShow]);

  const getDeleteProjectList = (input, callback, isFetchMore = false) => {
    input = {
      ...input,
      filter: {
        types: [ProjectType.User],
        isDeleted: true,
      },
    };
    setIsLoadingList(!isFetchMore);
    setIsFetching(isFetchMore);
    getDeleteProjectListQuery(input).then((res) => {
      setIsLoadingList(false);
      setIsFetching(false);
      if (res.data.projects) {
        let pageInfo = res.data.projects.pageInfo;
        let projects = res.data.projects.edges.map((item, index) => {
          return item.node;
        });
        setDeleteProjectListPageInfo({
          ...deleteProjectListPageInfo,
          pageInfo,
          count: res.data.projects.count,
        });
        if (isFetchMore) {
          projects = deleteProjectList.concat(projects);
        }
        setDeleteProjectList(projects);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取项目失败");
      }
    });
  };

  const renderDeleteProject = (projectInfo) => {
    let coverImg =
      projectInfo.coverImageUrl && projectInfo.coverImageUrl !== ""
        ? projectInfo.coverImageUrl
        : DefaultCover;
    return (
      <Col key={`delete-project-${projectInfo.id}`} span={8}>
        <div className="my-delete-projects-modal-list-item-container">
          <div
            className={`my-delete-projects-modal-list-item-background${
              selectedProject && selectedProject.id === projectInfo.id
                ? " my-delete-projects-modal-list-item-background-selected"
                : ""
            }`}
            onClick={() => {
              if (selectedProject && selectedProject.id === projectInfo.id) {
                setSelectedProject(null);
              } else {
                setSelectedProject(projectInfo);
              }
            }}
          >
            <div className="my-delete-projects-modal-list-item-container">
              <div className="my-delete-projects-modal-list-item-text">
                {projectInfo.title}
              </div>
              <img
                src={coverImg}
                className="my-delete-projects-modal-list-item-cover"
              />
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const renderDeleteProjectList = () => {
    if (deleteProjectList.length) {
      return (
        <Row gutter={[16, 24]}>
          {deleteProjectList.map((item) => {
            return renderDeleteProject(item);
          })}
        </Row>
      );
    } else {
      return <EmptyComponent tip={"没有被删除的项目"} />;
    }
  };

  return (
    <Modal
      footer={null}
      centered
      destroyOnClose
      className="my-delete-projects-modal-container"
      title={"已删除项目"}
      visible={isShow}
      closable={false}
      bodyStyle={{ backgroundColor: "rgba(249, 249, 251, 1)" }}
    >
      <SearchWithoutDrop
        position={{ right: "25px", top: "65px" }}
        callback={(string) => {
          let input = {
            after: undefined,
            first: PER_PAGE_COUNT,
            matching: string !== "" ? string : undefined,
            order: SortOrder.Desc,
          };
          getDeleteProjectList(input);
          setSearchString(string);
        }}
      />
      <p>请选择想要恢复的项目</p>
      <div
        className={`my-delete-projects-modal-list-container${
          isLoadingList
            ? " my-delete-projects-modal-list-container-loading-center"
            : ""
        }`}
        onScroll={(e) => {
          const { clientHeight, scrollHeight, scrollTop } = e.target;
          const isBottom = scrollTop + clientHeight + 20 > scrollHeight;
          if (
            !isFetching &&
            deleteProjectList.length &&
            deleteProjectListPageInfo.pageInfo &&
            deleteProjectListPageInfo.pageInfo.hasNextPage &&
            isBottom
          ) {
            let input = {
              after: deleteProjectListPageInfo.pageInfo.endCursor,
              first: PER_PAGE_COUNT,
              matching: searchString !== "" ? searchString : undefined,
              order: SortOrder.Desc,
            };
            getDeleteProjectList(input, () => {}, true);
          }
        }}
      >
        {isLoadingList ? (
          <Spin tip={"正在获取数据"} />
        ) : (
          renderDeleteProjectList()
        )}
      </div>
      <div className="reconfirm-modal-button-container">
        <div
          className="reconfirm-modal-button-cancel"
          onClick={() => {
            cancelCallback();
          }}
        >
          <div className="reconfirm-modal-button-cancel-text">取消</div>
        </div>
        <div
          className="reconfirm-modal-button-submit"
          onClick={() => {
            submitCallback(selectedProject);
          }}
        >
          <div className="reconfirm-modal-button-submit-text">确定</div>
        </div>
      </div>
    </Modal>
  );
};

export default MyDeleteProjectsContainer;
