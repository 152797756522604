import * as React from "react";
import "./styles.css";
import { CardType } from "../../../generated/graphql";

//引入的库组件
import copy from "copy-to-clipboard";
import { message, Input } from "antd";

//引入的其他页面组件

//graphql查询

//引入的资源图片
import GoldenBg from "../../../assets/corpus/golden-card-background.png";
import WikiBg from "../../../assets/corpus/wiki-card-background.png";
import CopyIcon from "../../../assets/corpus/copy.png";
import CollectIcon from "../../../assets/corpus/collection.png";
import MarkIcon from "../../../assets/corpus/mark.png";

const CorpusCardDetailContainer = (props) => {
  const { cardInfo, updateCardInfo } = props;

  let author =
    cardInfo.metadata && cardInfo.metadata.author
      ? cardInfo.metadata.author
      : "无";
  let origin =
    cardInfo.metadata && cardInfo.metadata.origin
      ? cardInfo.metadata.origin
      : "无";

  const [titleString, setTitleString] = React.useState(cardInfo.title);
  const [contentString, setContentString] = React.useState(cardInfo.content);
  const [authorString, setAuthorString] = React.useState(author);
  const [originString, setOriginString] = React.useState(origin);

  const renderCard = () => {
    return (
      <>
        <div className="card-title-back-img-container">
          <Input
            className="card-title-text-title"
            defaultValue={titleString}
            onChange={(e) => {
              setTitleString(e.target.value);
            }}
            onBlur={() => {
              if (updateCardInfo && titleString !== cardInfo.title) {
                updateCardInfo({
                  title: titleString,
                });
              }
            }}
          />

          <img src={GoldenBg} className="card-title-back-img" />
          <img
            src={CopyIcon}
            className="card-copy-button"
            onClick={() => {
              copy(cardInfo.content);
              message.success("内容已复制到剪贴板");
            }}
          />
        </div>
        <img src={MarkIcon} className="card-mark" />
        <div className="card-content">
          <Input.TextArea
            className="card-content-text"
            defaultValue={contentString}
            autoSize={{ minRows: 10, maxRows: 10 }}
            onChange={(e) => {
              setContentString(e.target.value);
            }}
            onBlur={() => {
              if (updateCardInfo && contentString !== cardInfo.content) {
                updateCardInfo({ content: contentString });
              }
            }}
          />
        </div>
      </>
    );
  };

  return (
    <div className="card-detail-container">
      <div className="card-detail-layer-3" />
      <div className="card-detail-layer-2" />
      <div className="card-detail-layer-1">{renderCard()}</div>
    </div>
  );
};

export default CorpusCardDetailContainer;
