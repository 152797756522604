/* eslint-disable */

const dev = {
  host: "http://140.143.179.83",
  wsHost: "http://140.143.118.95",
  wsPort: "3000",
  port: "8888",
  env: "dev",
};

const pro = {
  host: "http://140.143.117.33",
  wsHost: "http://140.143.180.63",
  wsPort: "3000",
  port: "80",
  env: "pro",
};

const graphqlLocal = {
  url: "/api",
  appid: process.env.REACT_APP_ID,
  secret: process.env.REACT_APP_SECRET,
  qiniuUri: "/qiniu",
  redirectUrl: window.location.origin,
  exportUrl: "/export",
  importUrl: "/import",
  publishUrl: "/publish",
  playUrl: "/play",
  cancelPublish: "/cancel_published",
  cancelPlay: "/cancel_played",
  rePublish: "/republish",
  rePlay: "/replay",
  getPublished: "/published/",
  getPlayed: "/played/",
  deletePublished: "/published/",
  deletePlayed: "/played/",
  getWordTag: "/analyse-tagging/",
  split: "/split",
};

const graphqlDev = {
  url: "https://devu.aiwriter.net/api",
  appid: process.env.REACT_APP_ID,
  secret: process.env.REACT_APP_SECRET,
  qiniuUri: "https://devu.aiwriter.net/rest_api/v1/qiniu_token", //七牛云token获取
  redirectUrl: "https://devu.aiwriter.net", //微信登录重定向网页
  exportUrl: "https://devu.aiwriter.net/rest_api/v1/export", //导出
  importUrl: "https://devu.aiwriter.net/rest_api/v1/import", //导入
  publishUrl: "https://devu.aiwriter.net/rest_api/v1/publish", //发布
  playUrl: "https://devu.aiwriter.net/rest_api/v1/play", //演示
  cancelPublish: "https://devu.aiwriter.net/rest_api/v1/cancel_published", //取消发布
  cancelPlay: "https://devu.aiwriter.net/rest_api/v1/cancel_played", //取消演示
  rePublish: "https://devu.aiwriter.net/rest_api/v1/republish", //发布再开再开
  rePlay: "https://devu.aiwriter.net/rest_api/v1/replay", //演示再开
  getPublished: "https://devu.aiwriter.net/rest_api/v1/published/", //是否已发布
  getPlayed: "https://devu.aiwriter.net/rest_api/v1/played/", //是否已开启演示
  deletePublished: "https://devu.aiwriter.net/rest_api/v1/published/", //删除发布
  deletePlayed: "https://devu.aiwriter.net/rest_api/v1/played/", //删除演示
  getWordTag: "https://demo.aiwriter.cn/api/ai-algorithm/analyse-tagging/",
  split: "https://devu.aiwriter.net/rest_api/v1/split", //智能拆分
};

const graphqlPro = {
  url: "https://aiwriter.net/api",
  appid: process.env.REACT_APP_ID,
  secret: process.env.REACT_APP_SECRET,
  qiniuUri: "https://aiwriter.net/rest_api/v1/qiniu_token",
  redirectUrl: "https://aiwriter.net",
  exportUrl: "https://aiwriter.net/rest_api/v1/export",
  importUrl: "https://aiwriter.net/rest_api/v1/import",
  publishUrl: "https://aiwriter.net/rest_api/v1/publish",
  playUrl: "https://aiwriter.net/rest_api/v1/play",
  cancelPublish: "https://aiwriter.net/rest_api/v1/cancel_published",
  cancelPlay: "https://aiwriter.net/rest_api/v1/cancel_played",
  rePublish: "https://aiwriter.net/rest_api/v1/republish",
  rePlay: "https://aiwriter.net/rest_api/v1/replay",
  getPublished: "https://aiwriter.net/rest_api/v1/published/",
  getPlayed: "https://aiwriter.net/rest_api/v1/played/",
  deletePublished: "https://aiwriter.net/rest_api/v1/published/",
  deletePlayed: "https://aiwriter.net/rest_api/v1/played/",
  getWordTag: "https://demo.aiwriter.cn/api/ai-algorithm/analyse-tagging/",
  split: "https://aiwriter.net/rest_api/v1/split", //智能拆分
};

const getConfig = () => {
  // 读取环境变量
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    case "local":
      return graphqlLocal;
    case "development":
      return graphqlDev;
    case "product":
      return graphqlPro;
    case "development_electron":
      break;
    case "product_electron":
      break;
  }
};

// 环境配置
const config = getConfig();
config.wsDomain = "152.136.8.231:5000";

/**
 // let config = process.env.REACT_APP_OMC_ENV === 'production' ? pro : dev;
let config = pro;
config.domain = "http://140.143.117.33"; // config.host + ':' + config.port;
// config.wsDomain = "https://socketio.aiwriter.cn" // config.wsHost + ':' + config.wsPort;
config.wsDomain = "http://152.136.8.231:5000";
 */

export default config;
