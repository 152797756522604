import React, { useState, useEffect } from "react";
import HotTags from "./HotTags";
import TabBar from "./TabBar";
import { Spin, message } from "antd";
import InsCards from "../InspireCards";
import { getUrlPath, getUrlSearch } from "../../../utils/getUrlInfo";

//路由格式示例金句 /idea/wiki/c/:code
export const C_INDEX = 2;
export const C_ID_INDEX = 3;

const CardsInTotal = (props) => {
  const {
    isShowReconfirmModal,
    setIsShowReconfirmModal,
    reconfirmModalCallback,
    chosenCate,
    setChosenCate,
    categoryList,
    cardList,
    confirmText,
    searchString,
    getWIKICards,
    getCardList,
    setCurrentPage,
    getFolderCards,
    currentPage,
    setCardList,
    titleIndex,
    currentPageInfo,
    setSelectedCard,
    setIsShowCardDetail,
    getInspirationCardInfo,
  } = props;
  const [isMultiple, setIsMultiple] = useState(false);
  const [selectedProjects, filterSelectedProjects] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chosenTag, setChosenTag] = useState({});
  const [cStr, setCStr] = useState("");
  const [order, setOrder] = useState("DESC_COLLECTION_COUNTS");

  useEffect(() => {
    if (!cardList.length && !searchString) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [cardList]);

  React.useState(() => {
    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();
    if (searchArray.order && searchArray.order === "time") {
      setOrder("DESC_INSERTED_AT");
    } else {
      searchArray.order = "hot";
    }

    if(pathArray[C_INDEX] && pathArray[C_INDEX] === "c" && pathArray[C_ID_INDEX] && pathArray[C_ID_INDEX] !== "") {
      let cardCode = window.btoa("SharedCard:" + pathArray[C_ID_INDEX]);
      getInspirationCardInfo({ code: cardCode }).then((res) => {
        if (res.data.node) {
          setSelectedCard(res.data.node);
          setIsShowCardDetail(true);
        }else {
          message.error(("卡片不存在"));
          window.history.pushState(null, "", '/idea/wiki' + window.location.search);
        }
      });
    }else {
      window.history.pushState(null, "", '/idea/wiki' + window.location.search)
    }
  }, []);

  return (
    <div>
      {/*<Spin*/}
      {/*    tip={'正在获取卡片...'}*/}
      {/*    spinning={isLoading}*/}
      {/*    style={{ position: 'absolute',left: '50%', top: 70, zIndex: 999 }} />*/}
      <TabBar
        isMultiple={isMultiple}
        selectedTags={selectedTags}
        setChosenTag={setChosenTag}
        chosenTag={chosenTag}
        order={order}
        setSelectedTags={setSelectedTags}
        setOrder={setOrder}
        cStr={cStr}
        setCStr={setCStr}
        filterSelectedProjects={filterSelectedProjects}
      />
      <section style={{ display: "flex" }}>
        <div
          style={{
            width: 286,
            minHeight: "100vh",
            height: "max-content",
            background: "white",
          }}
        >
          <div style={{ width: "100%", height: "100vh", overflow: "auto" }}>
            <HotTags
              isMultiple={isMultiple}
              setIsMultiple={setIsMultiple}
              chosenTag={chosenTag}
              setChosenTag={setChosenTag}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setChosenCate={setChosenCate}
              setCStr={setCStr}
              order={order}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              title={"分类"}
              getWIKICards={getWIKICards}
              hasMultiChoice={false}
              filterSelectedProjects={filterSelectedProjects}
              categoryList={categoryList}
            />
            <HotTags
              isMultiple={isMultiple}
              setIsMultiple={setIsMultiple}
              order={order}
              setChosenTag={setChosenTag}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
              setCardList={setCardList}
              chosenCate={chosenCate}
              setCStr={setCStr}
              title={"筛选"}
              hasMultiChoice
              getWIKICards={getWIKICards}
              categoryList={selectedProjects}
            />
          </div>
        </div>
        <>
          <div style={{ width: "100%", height: "100vh" }}>
            <InsCards
              confirmText={confirmText}
              isShowReconfirmModal={isShowReconfirmModal}
              setIsShowReconfirmModal={setIsShowReconfirmModal}
              reconfirmModalCallback={reconfirmModalCallback}
              order={order}
              selectedTags={selectedTags}
              getWIKICards={getWIKICards}
              colSize={8}
              titleIndex={titleIndex}
              getCardList={getCardList}
              setCurrentPage={setCurrentPage}
              cardList={cardList}
              searchString={searchString}
              getFolderCards={getFolderCards}
              currentPage={currentPage}
              currentPageInfo={currentPageInfo}
              setSelectedCard={setSelectedCard}
              setIsShowCardDetail={setIsShowCardDetail}
            />
          </div>
        </>
      </section>
    </div>
  );
};

export default CardsInTotal;
