import * as React from "react";
import "../styles.css";

const Title = (props) => {
  const { title } = props;
  return (
    <div className="statistics-module-title-container">
      <div className="statistics-module-title-line" />
      <div className="statistics-module-title-text">{title}</div>
    </div>
  );
};

export default Title;
