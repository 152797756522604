import * as React from "react";
import "../styles.css";

//引入的其他页面组件
import { getOutlineIcon } from "./getOutlineIcon";

//引入的库组件
import { useQuery } from "react-apollo-hooks";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";

//引入的资源图片
import TRASH from "../../../assets/trash.png";
import OUTLINE_FOLDER from "../../../assets/folder_yellow.png";

const WRITING_OUTLINE_CHANGE_NAME_ID = gql`
  {
    writingChangeNameInOutlineIdClient @client
  }
`;

const TreeNodeInput = (props) => {
  const {
    title,
    id,
    children,
    icon,
    projectId,
    showRightMenu,
    updateCardAndOutline,
    selectedKey,
  } = props;

  const client = useApolloClient();
  const { data: changeNameData } = useQuery(WRITING_OUTLINE_CHANGE_NAME_ID);
  const { writingChangeNameInOutlineIdClient } = changeNameData;

  const [isOutlineChangeName, setIsOutlineChangeName] = React.useState(false);
  const [outlineChangeName, setOutlineChangeName] = React.useState("");

  React.useEffect(() => {
    if (
      writingChangeNameInOutlineIdClient &&
      writingChangeNameInOutlineIdClient === id
    ) {
      startNameEditor();
    }
  }, [writingChangeNameInOutlineIdClient]);

  //退出大纲改名的回调
  const exitTitleInput = (key, title) => {
    const changeNameValid =
      outlineChangeName &&
      outlineChangeName.trim() &&
      outlineChangeName !== title;
    if (changeNameValid) {
      updateCardAndOutline(key, outlineChangeName);
    }
    setOutlineChangeName("");
    setIsOutlineChangeName(false);
    client.writeData({
      data: {
        writingChangeNameInOutlineIdClient: "",
      },
    });
  };

  const startNameEditor = () => {
    if (id === "recycle-node" || outlineChangeName) return;
    setOutlineChangeName(title ? title : "无标题卡片");
    setIsOutlineChangeName(true);
  };

  const renderNode = () => {
    let src = "";
    if (id === "recycle-node") {
      src = TRASH;
    } else if (id === projectId) {
      src = OUTLINE_FOLDER;
    } else {
      if (icon !== "icon-default") {
        src = getOutlineIcon(icon);
      } else {
        if (children.length) {
          src = getOutlineIcon("icon-default-multi");
        } else {
          src = getOutlineIcon(icon);
        }
      }
    }

    if (isOutlineChangeName) {
      return (
        <div
          key={"tree-node-input-" + id}
          className={
            selectedKey === id
              ? " writing-outline-tree-node-span-select"
              : "writing-outline-tree-node-span"
          }
        >
          <img src={src} style={{ width: 14, height: 14, marginLeft: 5 }} />
          <input
            onClick={(e) => {
              e.stopPropagation();
            }}
            key={id}
            className="writing-outline-tree-node-input"
            id={id}
            value={outlineChangeName}
            autoFocus
            onFocus={(e) => {
              //获取焦点后自动全选
              e.target.select();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                exitTitleInput(id, title);
              }
            }}
            onBlur={() => exitTitleInput(id, title)}
            onChange={(e) => setOutlineChangeName(e.target.value)}
          />
        </div>
      );
    }

    return (
      <div
        id={id}
        key={"tree-node-" + id}
        className={
          selectedKey === id
            ? " writing-outline-tree-node-span-select"
            : "writing-outline-tree-node-span"
        }
        // PLAY
        onDoubleClick={() => {
          client.writeData({
            data: {
              writingChangeNameInOutlineIdClient: id,
            },
          });
        }}
        onContextMenu={showRightMenu}
      >
        <img
          src={src}
          style={{
            width: 14,
            height: 14,
            marginLeft: 5,
          }}
        />
        <div className="writing-module-tree-node-text">
          {title || "无标题卡片"}
        </div>
      </div>
    );
  };

  return renderNode();
};

export default TreeNodeInput;
