import * as React from "react";
import "../styles.css";

//引入的库组件
import { message, Pagination, Row, Col, Spin } from "antd";
import moment from "moment";
import { convertToZH } from "aw-error";

//引入的其他页面组件
import MainContainer from "../../Router_MainContainer/index";
import SearchWithDrop from "../../Components/Search/searchWithDrop";
import YearDrop from "../../Components/YearDrop/index";
import { getUrlPath, getUrlSearch } from "../../../utils/getUrlInfo";
import CardTemplate from "../../Components/CardTemplate/index";
import { renderPagination } from "../../Components/Pagination/index";
import { getPaginationPageVariable } from "../../../utils/index";
import MonthCardTemplate from "../../Components/MonthCardTemplate/monthCardTemplate";
import GlobalSearchModal from "../../GlobalSearchModal/index";
import CardDetail from "./cardDetail";

//graphql查询
import {
  useGetCardsStatsQuery,
  useGetCardListQuery,
  useUpdateCardMutation,
  useGetSingleCardQuery,
  CardType,
  FileType,
  SortOrder,
} from "../../../generated/graphql";

//引入的资源图片
import BackIcon from "../../../assets/project/back-icon.png";
import NormalCardIcon from "../../../assets/writing/normal-card.png";
import EmptyComponent from "../../Components/EmptyComponent";

const PER_PAGE_CARD_COUNT = 18;

//路由格式示例/project/mycard/c/:code
export const C_INDEX = 2;
export const C_ID_INDEX = 3;

const MyCard = (props) => {
  const { setIsLogin, history } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isShowCardList, setIsShowCardList] = React.useState(false);
  const [isShowMonthCardList, setIsShowMonthCardList] = React.useState(false);
  const [monthCardInfo, setMonthCardInfo] = React.useState({
    year: 0,
    month: 0,
  });
  const [globalSearchString, setGlobalSearchString] = React.useState("");
  const [showGlobalSearchModal, setShowGlobalSearchModal] = React.useState({
    isShow: false,
    isSearch: false,
  });
  const [isShowCardDetail, setIsShowCardDetail] = React.useState(false);
  const [selectedCard, setSelectedCard] = React.useState({});
  const [cardList, setCardList] = React.useState([]);
  const [searchString, setSearchString] = React.useState("");
  const [isInit, setIsInit] = React.useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [years, setYears] = React.useState([]);
  const [yearSelectedIndex, setYearSelectedIndex] = React.useState(-1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageInfo, setCurrentPageInfo] = React.useState({
    count: 0,
    pageSize: PER_PAGE_CARD_COUNT,
    pageInfo: null,
  });
  const [updateCardMutation] = useUpdateCardMutation();
  const { refetch: getCardListQuery } = useGetCardListQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getSingleCardQuery } = useGetSingleCardQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const { refetch: getCardsStatsQuery } = useGetCardsStatsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    //加载完毕更改标签名
    document.title = `我的卡片`;

    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();

    getYear((years) => {
      if (searchArray && searchArray.year) {
        let index = -1;
        years.forEach((item, index) => {
          if (item.year === parseInt(searchArray.year)) {
            setYearSelectedIndex(index);
          }
        });
      }
      if (
        pathArray[C_INDEX] &&
        pathArray[C_INDEX] === "c" &&
        pathArray[C_ID_INDEX] &&
        pathArray[C_ID_INDEX] !== ""
      ) {
        let cardCode = window.btoa("Card:" + pathArray[C_ID_INDEX]);
        getSingleCardQuery({ code: cardCode }).then((res) => {
          if (res.data.node) {
            setSelectedCard(res.data.node);
            setIsShowCardDetail(true);
            window.history.pushState(null, "", window.location.pathname);
          } else {
            message.error("卡片不存在");
            history.push("/project/mycard");
          }
          setIsInit(true);
        });
      } else {
        if (searchArray && searchArray.year) {
          if (searchArray.month) {
            toCardList({
              year: parseInt(searchArray.year),
              month: parseInt(searchArray.month),
            });
          }
        } else {
          setIsInit(true);
        }
      }
    });
  }, []);

  React.useEffect(() => {
    if (isShowCardList && isInit) {
      searchCard(searchString);
    }
  }, [isInit, isShowMonthCardList, isShowCardList, yearSelectedIndex]);

  const updateCard = (input, callback) => {
    setIsLoading(true);
    updateCardMutation({ variables: { input } }).then((res) => {
      setIsLoading(false);
      if (!res.data.updateCard.successful) {
        // message.error(res.data.updateCard.messages[0].message);
        message.error(convertToZH(res.data.updateCard.messages[0]));
      } else {
        if (callback) {
          callback();
        }
        message.success("修改成功");
      }
    });
  };

  const searchCard = (searchString) => {
    let currentYear = years[yearSelectedIndex]
      ? years[yearSelectedIndex].year
      : 0;
    let filter = {
      types: [CardType.User],
      fileTypes: [FileType.File],
    };
    if (isShowMonthCardList) {
      let startDate = moment(monthCardInfo.year + "-" + monthCardInfo.month)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endDate = moment(monthCardInfo.year + "-" + monthCardInfo.month)
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");
      filter = {
        ...filter,
        year: monthCardInfo.year,
        month: monthCardInfo.month,
        // startDate: startDate,
        // endDate: endDate
      };
    }

    if (currentYear !== 0) {
      let startDate = moment(currentYear + "-" + 1)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endDate = moment(currentYear + "-" + 12)
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");
      filter = {
        ...filter,
        year: currentYear,
      };
    }

    let variables = {
      after: undefined,
      first: PER_PAGE_CARD_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString !== "" ? searchString : undefined,
      order: SortOrder.Desc,
      filter: filter,
    };
    setSearchString(searchString);
    getCardList(variables, () => {
      setCurrentPage(1);
      if (!isShowMonthCardList) {
        setIsShowCardList(true);
      }
    });
  };

  const searchCardRef = React.useRef(searchCard);
  searchCardRef.current = searchCard;

  const changeYear = (index) => {
    setYearSelectedIndex(index);
    let searchArray = getUrlSearch();
    if (index === -1) {
      delete searchArray.year;
    } else {
      searchArray.year = years[index].year;
    }
    let keys = Object.keys(searchArray);
    let path = "/project/mycard" + (keys.length > 0 ? "?" : "");
    keys.forEach((item, index) => {
      if (index !== keys.length - 1) {
        path += `${item}=${searchArray[item]}&`;
      } else {
        path += `${item}=${searchArray[item]}`;
      }
    });
    window.history.pushState(null, "", path);
  };

  const changeYearRef = React.useRef(changeYear);
  changeYearRef.current = changeYear;

  const toCardList = (cardInfo) => {
    setIsShowMonthCardList(true);
    setMonthCardInfo(cardInfo);
    setIsLoading(true);
    let startDate = moment(cardInfo.year + "-" + cardInfo.month)
      .startOf("month")
      .format("YYYY-MM-DD");
    let endDate = moment(cardInfo.year + "-" + cardInfo.month)
      .endOf("month")
      .add(1, "days")
      .format("YYYY-MM-DD");
    let variables = {
      after: undefined,
      first: PER_PAGE_CARD_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString !== "" ? searchString : undefined,
      order: SortOrder.Desc,
      filter: {
        types: [CardType.User],
        fileTypes: [FileType.File],
        year: cardInfo.year,
        month: cardInfo.month,
      },
    };
    getCardList(variables, () => {
      setCurrentPage(1);
      setIsInit(true);
      window.history.pushState(
        null,
        "",
        `/project/mycard?year=${cardInfo.year}&month=${cardInfo.month}`
      );
    });
  };

  const toCardListRef = React.useRef(toCardList);
  toCardListRef.current = toCardList;

  const openGlobalSearch = (string) => {
    setGlobalSearchString(string);
    setShowGlobalSearchModal({ isSearch: true, isShow: true });
  };

  const openGlobalSearchRef = React.useRef(openGlobalSearch);
  openGlobalSearchRef.current = openGlobalSearch;

  const getCardList = (variables, callback) => {
    getCardListQuery(variables).then((res) => {
      if (res.data.cards) {
        let pageInfo = res.data.cards.pageInfo;
        let count = res.data.cards.count;
        let cards = res.data.cards.edges.map((item, index) => {
          return item.node;
        });
        setCurrentPageInfo({ ...currentPageInfo, pageInfo, count });
        setCardList(cards);
        setIsLoading(false);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取卡片失败");
      }
    });
  };

  const getYear = (callback) => {
    getCardsStatsQuery({ types: [CardType.User] }).then((res) => {
      if (res.data.cardsStats) {
        let datas = res.data.cardsStats;
        let years = Array.from(
          new Set(
            datas.map((item) => {
              return item.year;
            })
          )
        );
        years.sort((v1, v2) => {
          return v2 - v1;
        });
        let stats = [];
        for (let i in years) {
          let year = years[i];
          let yearStats = {
            year: year,
            month: datas
              .filter((item) => {
                return year === item.year;
              })
              .sort((v1, v2) => {
                return v2.month - v1.month;
              }),
          };
          stats.push(yearStats);
        }
        setYears(stats);
        if (callback) {
          callback(stats);
        }
      } else {
        message.error("获取数据失败");
      }
    });
  };

  const renderSingleYearData = () => {
    let year = years[yearSelectedIndex];
    return (
      <div className="project-card-page-year-card-container">
        <Row gutter={[24, 32]}>
          {year.month.map((monthItem, index) => {
            return (
              <Col span={8} key={monthItem.month}>
                <MonthCardTemplate
                  cardInfo={monthItem}
                  cardClick={(cardInfo) => {
                    toCardListRef.current(cardInfo);
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  const renderYearData = (item) => {
    return (
      <>
        <div className="project-card-page-year-title-container">
          <div className="project-card-page-year-title-dot" />
          <div className="project-card-page-year-title-text">{item.year}年</div>
        </div>
        <div className="project-card-page-year-card-container">
          <Row gutter={[24, 32]}>
            {item.month.map((monthItem, index) => {
              return (
                <Col span={8} key={"project-card-" + monthItem.month}>
                  <MonthCardTemplate
                    key={monthItem.month}
                    cardInfo={monthItem}
                    cardClick={(cardInfo) => {
                      toCardListRef.current(cardInfo);
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  };

  const renderAllYearsData = () => {
    if (years.length <= 0) {
      return <EmptyComponent tip={"没有卡片数据"} />;
    }
    return years.map((item, index) => {
      return (
        <div
          className="project-card-page-year-container"
          key={"year-" + item.year}
        >
          {renderYearData(item)}
        </div>
      );
    });
  };

  const renderMonthCardList = () => {
    return (
      <>
        <div className="project-card-page-month-title-container">
          <img
            src={BackIcon}
            className="project-card-page-month-title-icon"
            onClick={() => {
              setIsShowMonthCardList(false);
              window.history.pushState(
                null,
                "",
                `/project/mycard?year=${years[yearSelectedIndex].year}`
              );
            }}
          />
          <div className="project-card-page-month-title-text">
            {monthCardInfo.year}年 · {monthCardInfo.month}月
          </div>
          <div className="project-card-page-month-title-text">
            {currentPageInfo.count}张
          </div>
        </div>
        <div className="project-card-cards-list-content">
          <Spin
            spinning={isLoading}
            wrapperClassName="project-card-page-month-list-container"
          >
            <Row gutter={[24, 48]}>
              {cardList.map((item, index) => {
                return (
                  <Col span={8} key={"renderMonthCardList-" + item.id}>
                    <CardTemplate
                      item={item}
                      icon={NormalCardIcon}
                      isHoverOutLine={true}
                      isHoverShowIcon={true}
                      searchString={searchString}
                      addToProjectCallback={null}
                      setSelectedCard={setSelectedCard}
                      clickCallback={() => {
                        setIsShowCardDetail(true);
                        let path =
                          `/project/mycard/c/` +
                          window.atob(item.code).split("Card:")[1] +
                          window.location.search;
                        window.history.pushState(null, "", path);
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Spin>
          <div className="project-card-list-content-pagination">
            <Pagination
              itemRender={renderPagination}
              pageSize={PER_PAGE_CARD_COUNT}
              current={currentPage}
              total={currentPageInfo.count}
              hideOnSinglePage
              onChange={(page) => {
                let variables = getPaginationPageVariable(
                  page,
                  currentPage,
                  currentPageInfo
                );
                let startDate = moment(
                  monthCardInfo.year + "-" + monthCardInfo.month
                )
                  .startOf("month")
                  .format("YYYY-MM-DD");
                let endDate = moment(
                  monthCardInfo.year + "-" + monthCardInfo.month
                )
                  .endOf("month")
                  .add(1, "days")
                  .format("YYYY-MM-DD");
                variables = {
                  ...variables,
                  matching: searchString !== "" ? searchString : undefined,
                  order: SortOrder.Desc,
                  filter: {
                    types: [CardType.User],
                    fileTypes: [FileType.File],
                    year: monthCardInfo.year,
                    month: monthCardInfo.month,
                    // startDate: startDate,
                    // endDate: endDate
                  },
                };
                getCardList(variables, () => {
                  setCurrentPage(page);
                });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderCardList = () => {
    let currentYear = years[yearSelectedIndex]
      ? years[yearSelectedIndex].year
      : 0;
    return (
      <>
        <div className="project-card-page-month-title-container">
          <img
            src={BackIcon}
            className="project-card-page-month-title-icon"
            onClick={() => {
              setIsShowCardList(false);
              history.push(
                `/project/mycard?year=${years[yearSelectedIndex].year}`
              );
            }}
          />
          <div className="project-card-page-month-title-text">
            {currentYear === 0 ? "全部" : currentYear + "年"}
          </div>
          <div className="project-card-page-month-title-text">
            {currentPageInfo.count}张
          </div>
        </div>
        <div className="project-card-cards-list-content">
          <Spin
            spinning={isLoading}
            wrapperClassName="project-card-page-month-list-container"
          >
            <Row gutter={[24, 48]}>
              {cardList.map((item, index) => {
                return (
                  <Col span={8} key={"renderCardList-" + item.id}>
                    <CardTemplate
                      item={item}
                      icon={NormalCardIcon}
                      isHoverOutLine={true}
                      isHoverShowIcon={true}
                      searchString={searchString}
                      addToProjectCallback={null}
                      setSelectedCard={setSelectedCard}
                      clickCallback={() => {
                        setIsShowCardDetail(true);
                        let path =
                          `/project/mycard/c/` +
                          window.atob(item.code).split("Card:")[1] +
                          window.location.search;
                        window.history.pushState(null, "", path);
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
            <div className="project-card-list-content-pagination">
              <Pagination
                itemRender={renderPagination}
                pageSize={PER_PAGE_CARD_COUNT}
                current={currentPage}
                total={currentPageInfo.count}
                hideOnSinglePage
                onChange={(page) => {
                  let variables = getPaginationPageVariable(
                    page,
                    currentPage,
                    currentPageInfo
                  );
                  let filter = {
                    types: [CardType.User],
                    fileTypes: [FileType.File],
                  };
                  if (currentYear !== 0) {
                    let startDate = moment(currentYear + "-" + 1)
                      .startOf("month")
                      .format("YYYY-MM-DD");
                    let endDate = moment(currentYear + "-" + 12)
                      .endOf("month")
                      .add(1, "days")
                      .format("YYYY-MM-DD");
                    filter = {
                      ...filter,
                      year: currentYear,
                      // startDate: startDate,
                      // endDate: endDate
                    };
                  }

                  variables = {
                    ...variables,
                    matching: searchString !== "" ? searchString : undefined,
                    order: SortOrder.Desc,
                    filter: filter,
                  };
                  getCardList(variables, () => {
                    setCurrentPage(page);
                  });
                }}
              />
            </div>
          </Spin>
        </div>
      </>
    );
  };

  const renderHeaderContent = () => {
    const categories = [
      {
        name: "项目",
      },
      {
        name: "卡片",
      },
    ];

    return (
      <div className="project-myproject-header-container">
        <div className="project-myproject-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"project-mycard-" + index}
                className={
                  index === 1
                    ? "project-myproject-header-tab-item-selected"
                    : "project-myproject-header-tab-item"
                }
                onClick={() => {
                  if (index !== 1) {
                    history.push("/project/myproject");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {!isShowCardList && !isShowMonthCardList && (
          <YearDrop
            position={{ left: "25px" }}
            years={years}
            currentYear={
              yearSelectedIndex === -1
                ? "全部"
                : years[yearSelectedIndex].year + "年"
            }
            selectedCallback={(yearIndex) => {
              changeYearRef.current(yearIndex);
            }}
          />
        )}
        {!isShowCardDetail && (
          <SearchWithDrop
            position={{ right: "25px" }}
            callback={(index, string) => {
              if (index === "当前页面") {
                searchCardRef.current(string);
              }
              if (index === "全部搜索") {
                openGlobalSearchRef.current(string);
              }
            }}
            selectValues={[
              {
                value: "当前页面",
              },
              {
                value: "全部搜索",
              },
            ]}
          />
        )}
      </div>
    );
  };

  const renderBodyContent = () => {
    if (isShowCardDetail) {
      return (
        <div className="corpus-project-detail-container">
          <div className="corpus-project-detail-title-bar">
            <div className="corpus-project-list-left-container">
              <img
                src={BackIcon}
                className="corpus-project-detail-title-bar-back"
                onClick={() => {
                  setIsShowCardDetail(false);
                  window.history.pushState(
                    null,
                    "",
                    `/project/mycard${window.location.search}`
                  );
                }}
              />

              <div className="corpus-project-detail-title-bar-title-text">
                {selectedCard.title}
              </div>
            </div>
          </div>
          <div className="corpus-card-container">
            <CardDetail
              cardInfo={selectedCard}
              updateCardInfo={(info) => {
                let cardId = window.atob(selectedCard.id).split("Card:")[1];
                let input = {
                  revision: false,
                  id: cardId,
                  ...info,
                  metadata:
                    info.metadata && info.metadata !== ""
                      ? JSON.stringify(info.metadata)
                      : null,
                };
                updateCard(input, () => {
                  let cardListTemp = cardList.map((listItem) => {
                    if (selectedCard.id === listItem.id) {
                      listItem = { ...listItem, ...info };
                    }
                    return listItem;
                  });

                  setSelectedCard({ ...selectedCard, ...info });
                  setCardList(cardListTemp);
                });
              }}
            />
          </div>
        </div>
      );
    }
    return (
      <>
        {isShowCardList && renderCardList()}
        {isShowMonthCardList && renderMonthCardList()}
        {!isShowCardList && !isShowMonthCardList && (
          <div className="project-card-page-container">
            <div className="project-card-page-list">
              {yearSelectedIndex === -1
                ? renderAllYearsData()
                : renderSingleYearData()}
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <MainContainer
        currentSiderIndex={1}
        setIsLogin={setIsLogin}
        isBodyLoading={isLoading}
        bodyContent={renderBodyContent()}
        headerContent={renderHeaderContent()}
      />
      <GlobalSearchModal
        showModal={showGlobalSearchModal}
        showAddToProject={false}
        addToProjectCallback={null}
        globalSearchString={globalSearchString}
        handleOnCancel={() => {
          setShowGlobalSearchModal({ isShow: false, isSearch: false });
        }}
      />
    </>
  );
};

export default MyCard;
