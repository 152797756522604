import * as React from "react";
import "./styles.css";

//引入的库组件
import { Dropdown, Menu, message, Upload } from "antd";

//引入的其他页面组件

//graphql查询

//引入的资源图片
import CardCountIcon from "../../../../assets/project/card-count-icon.png";
import TextCountIcon from "../../../../assets/project/text-count-icon.png";
import DeleteIcon from "../../../../assets/delete_card.png";
import DefaultCover from "../../../../assets/project/default-cover.png";
import EditIcon from "../../../../assets/project/edit-circle.png";

const type = ["image/jpeg", "image/png"];
const size = 5;

const ProjectTemplate = (props) => {
  const {
    templateInfo,
    templateClick,
    deleteCallback,
    setFile,
    setSelectedProject,
    changeNameCallback,
    exportProjectCallback,
  } = props;
  const [isHover, setIsHover] = React.useState(false);

  const beforeUpload = (file) => {
    let isType = true;
    let isSize = true;
    if (type) {
      isType = type.includes(file.type);
    }
    if (size) {
      isSize = file.size / 1024 / 1024 < size;
    }

    if (!isType) {
      message.error(`不支持 ${file.type} 文件类型`);
    }
    if (!isSize) {
      message.error(`文件限制 ${size} M`);
    }

    if (isType && isSize) {
      setSelectedProject(templateInfo);
      setFile(file);
    }
    return false;
  };

  const editOverlay = () => {
    return (
      <Menu
        className="project-template-dropdown-container"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          switch (e.key) {
            case "1":
              break;
            case "2":
              if (deleteCallback) {
                setSelectedProject(templateInfo);
                deleteCallback();
              }
              break;
            case "3":
              if (changeNameCallback) {
                setSelectedProject(templateInfo);
                changeNameCallback();
              }
              break;
            case "4":
              if (exportProjectCallback) {
                exportProjectCallback();
              }
              break;
          }
        }}
      >
        <Menu.Item
          key={"3"}
          className="project-template-dropdown-item-container"
        >
          <div className="project-template-dropdown-item">修改名称</div>
        </Menu.Item>
        <Menu.Item
          key={"1"}
          className="project-template-dropdown-item-container"
        >
          <Upload
            name="file"
            listType="text"
            showUploadList={false}
            beforeUpload={beforeUpload}
            className="project-template-dropdown-item-modify-cover-container"
          >
            <div className="project-template-dropdown-item">修改封面</div>
          </Upload>
        </Menu.Item>
        {/* <Menu.Item
          key={"4"}
          className="project-template-dropdown-item-container"
        >
          <div className="project-template-dropdown-item">导出项目</div>
        </Menu.Item> */}
        <Menu.Item
          key={"2"}
          className="project-template-dropdown-item-container"
        >
          <div className="project-template-dropdown-item">删除项目</div>
        </Menu.Item>
      </Menu>
    );
  };

  let coverImg =
    templateInfo.coverImageUrl && templateInfo.coverImageUrl !== ""
      ? templateInfo.coverImageUrl
      : DefaultCover;
  return (
    <div
      className="project-template-container-1133"
      onMouseEnter={() => {
        setIsHover(true);
      }}
      onMouseLeave={() => {
        setIsHover(false);
      }}
      onClick={() => {
        templateClick();
      }}
    >
      <div className="project-template-container-layer3" />
      <div className="project-template-container-layer2" />
      <div className="project-template-container-layer1">
        <div
          className="project-template-content-container"
          id={`project-item-${templateInfo.id}`}
        >
          <div className="project-template-content-text">
            {templateInfo.title}
          </div>
          <img src={coverImg} className="project-template-content-cover" />
          <Dropdown
            trigger={["hover"]}
            overlay={editOverlay}
            onClick={(e) => {
              e.stopPropagation();
            }}
            getPopupContainer={() =>
              document.getElementById(`project-item-${templateInfo.id}`)
            }
          >
            <img
              src={EditIcon}
              className="project-template-item-edit"
              style={!isHover ? { display: "none" } : {}}
            />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default ProjectTemplate;
