import * as React from "react";
import "./styles.css";

//引入的库组件
import { Switch, Route, Redirect } from "react-router";

//引入的其他页面组件
import InspirationGolden from "./InspirationGolden/index";
import InspirationWiki from "./InspirationWiki/index";

//graphql查询

//引入的资源图片

const Inspiration = (props) => {
  const { setIsLogin, history } = props;

  return (
    <Switch>
      <Route
        path="/idea/golden"
        component={(props) => {
          return <InspirationGolden {...props} setIsLogin={setIsLogin} />;
        }}
      />
      <Route
        path="/idea/wiki"
        component={(props) => {
          return <InspirationWiki {...props} setIsLogin={setIsLogin} />;
        }}
      />
      <Route
        path="/"
        render={() => {
          return <Redirect to="/idea/golden" />;
        }}
      />
    </Switch>
  );
};

export default Inspiration;
