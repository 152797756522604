import * as React from "react";
import "./styles.css";

//引入的库组件
import {
  Modal,
  Form,
  Input,
  Icon,
  message,
  Row,
  Col,
  Pagination,
  Spin,
} from "antd";

//引入的其他页面组件
import CardTemplate from "../Components/CardTemplate/index";
import { getPaginationPageVariable } from "../../utils/index";
import { renderPagination } from "../Components/Pagination/index";
import EmptyComponent from "../Components/EmptyComponent/index";

//graphql查询
import {
  useGetSharedCardsQuery,
  useGetCardListQuery,
  CardType,
  SharedProjectType,
  SortOrder,
  FileType,
} from "../../generated/graphql";

//引入的资源图片
import CloseIcon from "../../assets/globalmodal/close.png";
import SearchIcon from "../../assets/search.png";

const PER_PAGE_COUNT = 6;
const titles = ["卡片", "金句", "知识"];

const GlobalSearchModal = (props) => {
  const {
    showModal,
    handleOnCancel,
    globalSearchString,
    showAddToProject,
    addToProjectCallback,
  } = props;

  const [switchIndex, setSwitchIndex] = React.useState(0);
  const [titleIndex, setTitleIndex] = React.useState(0);
  const [searchString, setSearchString] = React.useState(globalSearchString);
  const [dataList, setDataList] = React.useState([]);
  const [selectedCards, setSelectedCards] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isModalSearch, setIsModalSearch] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchPageInfo, setSearchPageInfo] = React.useState({
    count: 0,
    pageSize: PER_PAGE_COUNT,
    pageInfo: null,
  });

  const { refetch: getSharedCardsQuery } = useGetSharedCardsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });

  const { refetch: getCardListQuery } = useGetCardListQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all"
  });

  React.useEffect(() => {
    let matching = isModalSearch ? searchString : globalSearchString;
    if (showModal.isSearch) {
      let variables = {
        after: undefined,
        first: PER_PAGE_COUNT,
        before: undefined,
        last: undefined,
        matching: matching === "" ? undefined : matching,
      };
      let searchType = getCardType(variables);
      if (!isModalSearch) {
        setSearchString(globalSearchString);
      }
      if (searchType.type === "user") {
        getCardList({ ...searchType.variables, order: SortOrder.Desc }, () => {
          setCurrentPage(1);
        });
      } else {
        getGlobalSearch(searchType.variables, () => {
          setCurrentPage(1);
        });
      }
      setIsModalSearch(false);
    }
  }, [showModal, switchIndex, titleIndex]);

  const getCardType = (variables) => {
    if (titleIndex === 0) {
      variables = {
        ...variables,
        filter: {
          notEmpty: true,
          notArchived: true,
          fileTypes: [FileType.File],
          types: [CardType.User],
        },
      };
      return { type: "user", variables };
    }
    if (titleIndex === 1) {
      variables = {
        ...variables,
        filter: {
          fileTypes: [FileType.File],
          types:
            switchIndex === 0 ? [CardType.Golden] : [SharedProjectType.Golden],
        },
      };
      return switchIndex === 0
        ? { type: "user", variables }
        : { type: "global", variables };
    }
    if (titleIndex === 2) {
      variables = {
        ...variables,
        filter: {
          fileTypes: [FileType.File],
          types: switchIndex === 0 ? [CardType.Wiki] : [SharedProjectType.Wiki],
        },
      };
      return switchIndex === 0
        ? { type: "user", variables }
        : { type: "global", variables };
    }
  };

  const getGlobalSearch = (variables, successCallback) => {
    setIsLoading(true);
    getSharedCardsQuery(variables).then((res) => {
      setIsLoading(false);
      if (res.data.sharedCards) {
        let pageInfo = res.data.sharedCards.pageInfo;
        let count = res.data.sharedCards.count;
        let datas = res.data.sharedCards.edges.map((item, index) => {
          return item.node;
        });
        setSearchPageInfo({
          count: count,
          pageInfo: pageInfo,
          pageSize: PER_PAGE_COUNT,
        });
        setDataList(datas);
        if (successCallback) {
          successCallback();
        }
      } else {
        message.error("搜索出错");
      }
    });
  };

  const getCardList = (variables, successCallback) => {
    setIsLoading(true);
    getCardListQuery(variables).then((res) => {
      setIsLoading(false);
      if (res.data && res.data.cards) {
        let pageInfo = res.data.cards.pageInfo;
        let count = res.data.cards.count;
        let cards = res.data.cards.edges.map((item, index) => {
          return item.node;
        });
        setSearchPageInfo({
          count: count,
          pageInfo: pageInfo,
          pageSize: PER_PAGE_COUNT,
        });
        setDataList(cards);
        if (successCallback) {
          successCallback();
        }
      } else {
        message.error("搜索出错");
      }
    });
  };

  const renderSwitch = () => {
    const switchClassName = "search-modal-titlebar-switch-text";
    const switchSelectedClassName =
      "search-modal-titlebar-switch-text search-modal-titlebar-switch-text-selected";
    return (
      <div className="search-modal-titlebar-switch-container">
        {titleIndex > 0 && (
          <div className="search-modal-titlebar-switch-background">
            <div
              className={
                switchIndex === 0 ? switchSelectedClassName : switchClassName
              }
              onClick={() => {
                if (switchIndex !== 0) {
                  setIsModalSearch(true);
                  setSwitchIndex(0);
                }
              }}
            >
              私有
            </div>
            <div
              className={
                switchIndex === 1 ? switchSelectedClassName : switchClassName
              }
              onClick={() => {
                if (switchIndex !== 1) {
                  setIsModalSearch(true);
                  setSwitchIndex(1);
                }
              }}
            >
              公共
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTitle = () => {
    const titleClassName = "search-modal-titlebar-title-switch-text";
    const titleSelectedClassName =
      "search-modal-titlebar-title-switch-text search-modal-titlebar-title-switch-text-selected";
    return (
      <div className="search-modal-titlebar-title-container">
        <div className="search-modal-titlebar-title-switch-container">
          {titles.map((item, index) => {
            return (
              <div
                key={item + "-" + index}
                className={
                  titleIndex === index ? titleSelectedClassName : titleClassName
                }
                onClick={() => {
                  if (index !== titleIndex) {
                    setIsModalSearch(true);
                    setTitleIndex(index);
                  }
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="search-modal-titlebar-search-container">
        <Input
          placeholder="搜索"
          className="search-modal-titlebar-search-input"
          prefix={
            <img src={SearchIcon} style={{ width: "16px", height: "16px" }} />
          }
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          onPressEnter={() => {
            let variables = {
              after: undefined,
              first: PER_PAGE_COUNT,
              before: undefined,
              last: undefined,
              matching: searchString === "" ? undefined : searchString,
            };
            let searchType = getCardType(variables);
            if (searchType.type === "user") {
              getCardList(
                { ...searchType.variables, order: SortOrder.Desc },
                () => {
                  setCurrentPage(1);
                }
              );
            } else {
              getGlobalSearch(searchType.variables, () => {
                setCurrentPage(1);
              });
            }
          }}
        />
      </div>
    );
  };

  const renderTitleBar = () => {
    return (
      <div className="search-modal-titlebar-container">
        {renderSwitch()}
        {renderTitle()}
        {renderSearch()}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <>
        {dataList.length > 0 ? (
          <div className="search-modal-content search-modal-content-no-scroll-bar">
            <Spin spinning={isLoading} style={{ marginTop: "100px" }}>
              <Row gutter={[24, 32]}>
                {dataList.map((item, index) => {
                  return (
                    <Col span={8} key={item.id + item.title + index}>
                      <CardTemplate
                        item={item}
                        isHoverOutLine={true}
                        isHoverShowIcon={true}
                        searchString={searchString}
                        extraTitleButton={
                          showAddToProject
                            ? [
                                {
                                  buttonType: "addToProject",
                                  callback: addToProjectCallback,
                                },
                              ]
                            : [
                                {
                                  buttonType: "copy",
                                  callback: null,
                                },
                              ]
                        }
                      />
                    </Col>
                  );
                })}
              </Row>
            </Spin>
            <div className="search-modal-content-pagination">
              <Pagination
                pageSize={PER_PAGE_COUNT}
                itemRender={renderPagination}
                current={currentPage}
                total={searchPageInfo.count}
                hideOnSinglePage
                onChange={(page) => {
                  let variables = getPaginationPageVariable(
                    page,
                    currentPage,
                    searchPageInfo
                  );
                  variables = {
                    ...variables,
                    matching: searchString === "" ? undefined : searchString,
                  };
                  let searchType = getCardType(variables);
                  if (searchType.type === "user") {
                    getCardList(
                      { ...searchType.variables, order: SortOrder.Desc },
                      () => {
                        setCurrentPage(page);
                      }
                    );
                  } else {
                    getGlobalSearch(searchType.variables, () => {
                      setCurrentPage(page);
                    });
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <EmptyComponent tip={`暂时没有${titles[titleIndex]}卡片`} />
        )}
      </>
    );
  };

  return (
    <Modal
      className="search-modal"
      footer={null}
      centered
      closeIcon={
        <img src={CloseIcon} style={{ width: "14px", height: "14px" }} />
      }
      visible={showModal.isShow}
      width={1140}
      bodyStyle={{
        height: "720px",
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
      onCancel={() => {
        handleOnCancel && handleOnCancel();
      }}
    >
      {renderTitleBar()}
      {renderContent()}
    </Modal>
  );
};

export default GlobalSearchModal;
