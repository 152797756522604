import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Form, Input } from "antd";

//引入的其他页面组件

//graphql查询

//引入的资源图片

const PenNameEditorModal = props => {
  const { showModal, handleOnOk, handleOnCancel, form } = props;
  const submit = () => {
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      handleOnOk && handleOnOk(values.userName);
      form.resetFields();
    });
  };

  return (
    <Modal
      afterClose={() => {
        form.resetFields();
      }}
      footer={null}
      centered
      className="penNameModal-1546"
      title="用户名"
      visible={showModal}
      closable={false}
      onOk={() => {
        submit();
      }}
      onCancel={() => {
        form.resetFields();
        handleOnCancel && handleOnCancel();
      }}
    >
      <Form>
        <Form.Item hasFeedback>
          {form.getFieldDecorator("userName", {
            rules: [
              {
                required: true,
                message: "请输入用户名"
              }
            ]
          })(
            <Input
              className="penNameModal-2745"
              maxLength={36}
              placeholder={"请输入用户名"}
              size={"large"}
            />
          )}
        </Form.Item>
      </Form>
      <div className="penNameModal-3709">
        <div
          className="penNameModal-4214"
          onClick={() => {
            form.resetFields();
            handleOnCancel && handleOnCancel();
          }}
        >
          <div className="penNameModal-4224">取消</div>
        </div>
        <div
          className="penNameModal-4487"
          onClick={() => {
            submit();
          }}
        >
          <div className="penNameModal-4497">确定</div>
        </div>
      </div>
    </Modal>
  );
};

const PenNameEditorModalWrapper = Form.create()(PenNameEditorModal);

export default PenNameEditorModalWrapper;
