export const getChartColor = (score) => {
  if (score >= 0 && score <= 20) {
    return "#FF6D40";
  }
  if (score >= 21 && score <= 40) {
    return "#FFCB00";
  }
  if (score >= 41 && score <= 60) {
    return "#2BDEB6 ";
  }
  if (score >= 61 && score <= 80) {
    return "#26C6FF ";
  }
  if (score >= 81 && score <= 100) {
    return "#48A0FC";
  }
};

export const getWatchColor = (score) => {
  if (score >= 0 && score <= 20) {
    return "#48A0FC";
  }
  if (score >= 21 && score <= 40) {
    return "#26C6FF ";
  }
  if (score >= 41 && score <= 60) {
    return "#2BDEB6 ";
  }
  if (score >= 61 && score <= 80) {
    return "#FFCB00";
  }
  if (score >= 81 && score <= 100) {
    return "#FF6D40";
  }
};

export const toChinesNum = (num) => {
  let changeNum = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  let unit = ["", "十", "百", "千", "万"];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp
      .toString()
      .split("")
      .reverse();
    let newNum = "";
    for (var i = 0; i < strArr.length; i++) {
      newNum =
        (i == 0 && strArr[i] == 0
          ? ""
          : i > 0 && strArr[i] == 0 && strArr[i - 1] == 0
          ? ""
          : changeNum[strArr[i]] + (strArr[i] == 0 ? unit[0] : unit[i])) +
        newNum;
    }
    return newNum;
  };
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = "0" + noWan;
  }
  return overWan ? getWan(overWan) + "万" + getWan(noWan) : getWan(num);
};
