import * as React from "react";
import "./styles.css";

//引入的库组件
import { Avatar, Dropdown, Menu } from "antd";
import moment from "moment";

//引入的其他页面组件
import headers from "../../GlobalData/headers";

//graphql查询
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { useGetMyInfoQuery, UserRole } from "../../generated/graphql";

//引入的资源图片
import SettingIcon from "../../assets/sider/header-setting.png";
import ExitIcon from "../../assets/sider/header-exit.png";
import AdviseIcon from "../../assets/sider/header-advise.png";
import DefaultAvatar from "../../assets/default_avatar.png";
import { history } from "../AiWriterRouter/index";

const GET_USER_INFO = gql`
  {
    userInfoClient @client
  }
`;

const siderInfo = [
  {
    name: "new",
    nameCN: "新建",
    icon: require("../../assets/sider/new.png"),
    iconSelected: require("../../assets/sider/new-selected.png"),
  },
  {
    name: "write",
    nameCN: "项目",
    icon: require("../../assets/sider/write.png"),
    iconSelected: require("../../assets/sider/write-selected.png"),
  },
  {
    name: "corpus",
    nameCN: "素材",
    icon: require("../../assets/sider/corpus.png"),
    iconSelected: require("../../assets/sider/corpus-selected.png"),
  },
  {
    name: "inspiration",
    nameCN: "灵感",
    icon: require("../../assets/sider/inspiration.png"),
    iconSelected: require("../../assets/sider/inspiration-selected.png"),
  },
  {
    name: "service",
    nameCN: "服务",
    icon: require("../../assets/sider/service.png"),
    iconSelected: require("../../assets/sider/service-selected.png"),
  },
];

const Sider = (props) => {
  const { currentSiderIndex, setIsLogin } = props;

  const { refetch: getMyInfoQuery } = useGetMyInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const [myInfo, setMyInfo] = React.useState(null);

  const { data: userInfoData } = useQuery(GET_USER_INFO);
  const { userInfoClient } = userInfoData;
  let userInfo = JSON.parse(userInfoClient);

  const getMyInfo = () => {
    getMyInfoQuery().then((res) => {
      if (res.data.me) {
        setMyInfo(res.data.me);
      }
    });
  };

  const siderButtonClick = (index) => {
    switch (index) {
      case 0:
        history.push("/template");
        break;
      case 1:
        history.push("/project");
        break;
      case 2:
        history.push("/corpus");
        break;
      case 3:
        history.push("/idea");
        break;
      case 4:
        history.push("/service");
        break;
      default:
        break;
    }
  };

  const renderMyInfo = () => {
    let role = myInfo.role;
    let tip = "";
    if (role === UserRole.Admin) {
      tip = "管理员";
    } else if (myInfo.userProfile) {
      tip = `VIP到期时间：${moment(myInfo.userProfile.vipExpiredAt).format(
        "YYYY-MM-DD"
      )}`;
    } else {
      return null;
    }

    return (
      <div className="ui-main-sider-avatar-dropdown-header-userinfo-tip-text">
        {tip}
      </div>
    );
  };

  const renderSettingMenu = () => {
    return (
      <Menu
        className="ui-main-sider-avatar-dropdown-container"
        onClick={(e) => {
          e.domEvent.stopPropagation();
          switch (e.key) {
            case "1":
              history.push("/user");
              break;
            case "2":
              {
                const w = window.open("about:blank");
                w.location.href = "https://support.qq.com/product/151927";
              }
              break;
            case "3":
              {
                const w = window.open("about:blank");
                w.location.href = "http://qr29.cn/EWBqf5";
              }
              break;
            case "4":
              window.localStorage.removeItem("TOKENINFO");
              window.localStorage.removeItem("USERINFO");
              headers.Authorization = "";
              setIsLogin(false);
              history.push("/login");
              break;
          }
        }}
      >
        <div className="ui-main-sider-avatar-dropdown-header-container">
          <Avatar
            src={userInfo.avatar !== "" ? userInfo.avatar : DefaultAvatar}
            size="large"
          />
          <div className="ui-main-sider-avatar-dropdown-header-userinfo-container">
            <div className="ui-main-sider-avatar-dropdown-header-text">
              {userInfo.nickname}
            </div>
            {myInfo && renderMyInfo()}
          </div>
        </div>
        <Menu.Item
          key={"1"}
          className="ui-main-sider-avatar-dropdown-setting-container"
        >
          <img
            src={SettingIcon}
            className="ui-main-sider-avatar-dropdown-setting-icon"
          />
          <div className="ui-main-sider-avatar-dropdown-setting-text">
            账号设置
          </div>
        </Menu.Item>
        <Menu.Item
          key={"2"}
          className="ui-main-sider-avatar-dropdown-setting-container"
        >
          <img
            src={AdviseIcon}
            className="ui-main-sider-avatar-dropdown-setting-icon"
          />
          <div className="ui-main-sider-avatar-dropdown-setting-text">
            意见反馈
          </div>
        </Menu.Item>
        <Menu.Item
          key={"3"}
          className="ui-main-sider-avatar-dropdown-setting-container"
        >
          <img
            src={AdviseIcon}
            className="ui-main-sider-avatar-dropdown-setting-icon"
          />
          <div className="ui-main-sider-avatar-dropdown-setting-text">
            入群申请
          </div>
        </Menu.Item>
        <Menu.Item
          key={"4"}
          className="ui-main-sider-avatar-dropdown-setting-container"
        >
          <img
            src={ExitIcon}
            className="ui-main-sider-avatar-dropdown-setting-icon"
          />
          <div className="ui-main-sider-avatar-dropdown-setting-text">
            退出登录
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <div className="ui-main-sider-background">
      <div className="ui-main-sider-avatar-container-1324">
        <Avatar
          src={userInfo.avatar !== "" ? userInfo.avatar : DefaultAvatar}
          size="large"
        />
        <Dropdown
          trigger={["click"]}
          overlay={renderSettingMenu}
          getPopupContainer={() => document.getElementById("sider-setting")}
          onVisibleChange={(visible) => {
            if (visible) {
              getMyInfo();
            }
          }}
        >
          <div
            className="ui-main-sider-avatar-dropdown-click"
            id="sider-setting"
          />
        </Dropdown>
      </div>
      <div className="ui-main-sider-tab-bar-container-1478">
        {siderInfo.map((item, index) => {
          if (index === siderInfo.length - 1) {
            return null;
          }
          return (
            <div
              key={item.name}
              className="ui-main-sider-tab-button-container-1494"
              style={currentSiderIndex !== index ? { cursor: "pointer" } : {}}
              onClick={() => {
                if (currentSiderIndex !== index) {
                  siderButtonClick(index);
                }
              }}
            >
              <img
                alt={item.name}
                className="ui-main-sider-tab-bar-icon-1518"
                src={
                  currentSiderIndex === index ? item.iconSelected : item.icon
                }
              />
              <div
                className={
                  currentSiderIndex === index
                    ? "ui-main-sider-tab-bar-icon-1647-selected"
                    : "ui-main-sider-tab-bar-icon-1647"
                }
              >
                {item.nameCN}
              </div>
            </div>
          );
        })}
      </div>
      <div className="ui-main-sider-service-container-1796">
        <div
          className="ui-main-sider-tab-button-container-1494"
          style={
            currentSiderIndex !== siderInfo.length - 1
              ? { cursor: "pointer" }
              : {}
          }
          onClick={() => {
            if (currentSiderIndex !== siderInfo.length - 1) {
              siderButtonClick(siderInfo.length - 1);
            }
          }}
        >
          <img
            alt={siderInfo[siderInfo.length - 1].name}
            src={
              currentSiderIndex === siderInfo.length - 1
                ? siderInfo[siderInfo.length - 1].iconSelected
                : siderInfo[siderInfo.length - 1].icon
            }
            className="ui-main-sider-tab-bar-icon-1518"
          />
          <div
            className={
              currentSiderIndex === siderInfo.length - 1
                ? "ui-main-sider-tab-bar-icon-1647-selected"
                : "ui-main-sider-tab-bar-icon-1647"
            }
          >
            {siderInfo[siderInfo.length - 1].nameCN}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sider;
