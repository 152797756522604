const getSystem = () => {
    let isMac = (navigator.platform == "Mac68K") || (navigator.platform == "MacPPC") || (navigator.platform == "Macintosh") || (navigator.platform == "MacIntel");
    if(isMac){
        return "MAC";
    }
    let isWin = (navigator.platform == "Win32") || (navigator.platform == "Windows");
    if(isWin) {
        return "WIN"
    }
    return "OTHERS";
}
export const SYSTEM = getSystem();

