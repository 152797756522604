import * as React from "react";
import "./styles.css";

//引入的库组件
import { Switch, Route, Redirect } from "react-router";
import MainContainer from "../Router_MainContainer";
import MyProject from "./MyProject/index";
import MyCard from "./MyCard/index";
import MyWriting from "./MyWriting/index";

//引入的其他页面组件

//graphql查询

//引入的资源图片

const Project = (props) => {
  const { setIsLogin, history } = props;

  return (
    <Switch>
      <Route
        key={"/project/myproject"}
        path="/project/myproject"
        component={(props) => {
          return <MyProject {...props} setIsLogin={setIsLogin} />;
        }}
      />
      <Route
        key={"/project/mycard"}
        path="/project/mycard"
        component={(props) => {
          return <MyCard {...props} setIsLogin={setIsLogin} />;
        }}
      />
      <Route
        key={"/project/writing"}
        path="/project/writing"
        component={(props) => {
          return <MyWriting {...props} setIsLogin={setIsLogin} />;
        }}
      />
      <Route
        key={"no-/"}
        path="/"
        render={() => {
          return <Redirect to="/project/writing" />;
        }}
      />
    </Switch>
  );
};

export default Project;
