import * as React from "react";
import "./styles.css";

import PrevIcon from "../../../assets/globalmodal/previous-page.png";
import NextIcon from "../../../assets/globalmodal/next-page.png";

export const renderPagination = (page, type, element) => {
  const pageContainer = document.getElementsByClassName('ant-pagination')[0];
  if (pageContainer) pageContainer.style.display = 'block';
  if (type === "prev") {
    return (
      <img
        src={PrevIcon}
        style={{ width: "20px", height: "20px", marginRight: "10px" }}
      />
    );
  }
  if (type === "next") {
    return (
      <img
        src={NextIcon}
        style={{ width: "20px", height: "20px", marginLeft: "10px" }}
      />
    );
  }
  if (type === "page") {
    return <>{page}</>;
  }
  return element;
};
