import { Chart, registerShape } from "@antv/g2";
import { getChartColor } from "../utils";

export const CircleChart = (chartData, id, textStyle = null) => {
  const chart = new Chart({
    container: id,
    autoFit: true,
  });
  chart.data(chartData);
  chart.legend(false);
  chart.tooltip(false);
  chart.facet("rect", {
    fields: ["type"],
    padding: 0,
    showTitle: false,
    eachView: (view, facet) => {
      const data = facet.data;
      let color = getChartColor(data[0].value);
      if (100 - data[0].value > 0) {
        data.push({ type: "其他", value: 100 - data[0].value });
      }
      view.data(data);
      view.coordinate("theta", {
        radius: 1.0,
        innerRadius: 0.85,
      });
      view
        .interval()
        .adjust({ type: "stack", reverseOrder: true })
        .position("value")
        .color("type", [color, "#F3F4F8"])
        .style("type", (xVal, yVal) => {
          const style = { opacity: 1 };
          if (data[0].type === xVal) {
            style.borderRadius = [10, 10, 10, 10];
          }
          return style;
        });
      view.annotation().text({
        position: ["50%", "50%"],
        content: data[0].type,
        style: {
          fontSize: textStyle && textStyle.textSize ? textStyle.textSize : 12,
          fill: color,
          fontWeight: 400,
          letterSpacing: 0.44,
          textBaseline: "bottom",
          textAlign: "center",
        },
        offsetY: -12,
      });

      view.annotation().text({
        position: ["50%", "50%"],
        content: data[0].value,
        style: {
          fontSize: textStyle && textStyle.countSize ? textStyle.countSize : 40,
          fill: color,
          fontWeight: 500,
          letterSpacing: 0,
          textAlign: "center",
        },
        offsetY: 10,
      });
    },
  });
  chart.render();
};
