import * as React from "react";
import "./styles.css";

//引入的库组件
import { Switch, Route, Redirect } from "react-router";

//引入的其他页面组件
import InspirationContainer from "../../InspirationContainer/index";
import MainContainer from "../../Router_MainContainer/index";
import SearchWithoutDrop from "../../Components/Search/searchWithoutDrop";

//graphql查询

//引入的资源图片

let searchCallback = null;

const InspirationGolden = (props) => {
  const { setIsLogin, history } = props;

  const [isShowCardDetail, setIsShowCardDetail] = React.useState(false);

  React.useEffect(() => {
    //加载完毕更改标签名
    document.title = `灵感-金句`;
  }, []);

  const setSearchCallback = (callback) => {
    searchCallback = callback;
  };

  const renderBodyContent = () => {
    return (
      <InspirationContainer
        history={history}
        isShowCardDetail={isShowCardDetail}
        setIsShowCardDetail={setIsShowCardDetail}
        setSearchCallback={setSearchCallback}
        titleIndex={0}
      />
    );
  };

  const renderTitleContent = () => {
    const categories = [
      {
        name: "金句",
      },
      {
        name: "知识",
      },
    ];
    return (
      <div className="inspiration-wiki-header-container">
        <div className="inspiration-wiki-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"inspiration-wiki-header-" + index}
                className={
                  index === 0
                    ? "inspiration-wiki-header-tab-item-selected"
                    : "inspiration-wiki-header-tab-item"
                }
                onClick={() => {
                  if (index !== 0) {
                    history.push("/idea/wiki");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        {!isShowCardDetail && (
          <SearchWithoutDrop
            position={{ right: "25px" }}
            callback={(string) => {
              if (searchCallback) {
                searchCallback(string);
                if (string !== "") {
                  window.history.pushState(
                    null,
                    "",
                    "/idea/golden?search=" + string
                  );
                } else {
                  window.history.pushState(null, "", "/idea/golden");
                }
              }
            }}
          />
        )}
      </div>
    );
  };

  return (
    <MainContainer
      currentSiderIndex={3}
      setIsLogin={setIsLogin}
      isBodyLoading={false}
      bodyContent={renderBodyContent()}
      headerContent={renderTitleContent()}
    />
  );
};

export default InspirationGolden;
