import * as React from "react";
import "./styles.css";

//引入的其他页面组件
import Sider from "../Router_Sider/index";
import Header from "../Router_Header/index";

//引入的库组件
import { Spin } from "antd";

const MainContainer = (props) => {
  const {
    currentSiderIndex,
    setIsLogin,
    bodyContent,
    headerContent,
    isBodyLoading = false,
  } = props;
  return (
    <div className="main-container">
      <Sider currentSiderIndex={currentSiderIndex} setIsLogin={setIsLogin} />
      <div className="main-body">
        <Header headerContent={headerContent} />

        <Spin
          spinning={isBodyLoading}
          size="large"
          wrapperClassName="main-body-body-container"
        >
          {bodyContent}
        </Spin>
      </div>
    </div>
  );
};

export default MainContainer;
