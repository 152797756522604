import * as React from "react";
import "./styles.css";

//引入的库组件
import { message, Row, Col, Spin, Pagination } from "antd";

//引入的其他页面组件
import { history } from "../AiWriterRouter/index";
import MonthCardTemplate from "../ProjectContainer/MonthCardTemplate/monthCardTemplate";
import EmptyComponent from "../Components/EmptyComponent";
import { getUrlPath, getUrlSearch } from "../../utils/getUrlInfo";
import SearchWithDrop from "../Components/Search/searchWithDrop";
import YearDrop from "../Components/YearDrop/index";

//graphql查询
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";
import { useQuery } from "react-apollo-hooks";
import { useGetCardsStatsQuery, CardType } from "../../generated/graphql";

//引入的资源图片

const tabs = ["all", "golden", "wiki"];
const projectTab = ["全部", "金句", "知识"];

const CorpusCardsDateContainer = (props) => {
  const { setHeaderContent, openGlobalSearchRef } = props;

  const client = useApolloClient();

  const [isInit, setIsInit] = React.useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [years, setYears] = React.useState([]);
  const [yearSelectedIndex, setYearSelectedIndex] = React.useState(-1);

  const { refetch: getCardsStatsQuery } = useGetCardsStatsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    let pathArray = getUrlPath();
    let searchArray = getUrlSearch();

    if (searchArray && searchArray.category) {
      switch (searchArray.category) {
        case "all":
          setSelectedTabIndex(0);
          break;
        case "wiki":
          setSelectedTabIndex(2);
          break;
        case "golden":
          setSelectedTabIndex(1);
          break;
        default:
          setSelectedTabIndex(0);
          window.history.pushState(
            null,
            "",
            "/corpus/cards/date-list?category=all"
          );
          break;
      }
    } else {
      setSelectedTabIndex(0);
      window.history.pushState(
        null,
        "",
        "/corpus/cards/date-list?category=all"
      );
    }
    getYear((years) => {
      if (searchArray && searchArray.year) {
        let index = -1;
        years.forEach((item, index) => {
          if (item.year === parseInt(searchArray.year)) {
            setYearSelectedIndex(index);
          }
        });
      }
      setIsInit(true);
    });
  }, []);

  React.useEffect(() => {
    if (isInit) {
      getYear();
    }
  }, [isInit, selectedTabIndex]);

  React.useEffect(() => {
    setHeaderContent(renderHeaderContent);
  }, [years, selectedTabIndex, yearSelectedIndex]);

  const getYear = (callback) => {
    getCardsStatsQuery({ types: getTypes() }).then((res) => {
      if (res.data.cardsStats) {
        let datas = res.data.cardsStats;
        let years = Array.from(
          new Set(
            datas.map((item) => {
              return item.year;
            })
          )
        );
        years.sort((v1, v2) => {
          return v2 - v1;
        });
        let stats = [];
        for (let i in years) {
          let year = years[i];
          let yearStats = {
            year: year,
            month: datas
              .filter((item) => {
                return year === item.year;
              })
              .sort((v1, v2) => {
                return v2.month - v1.month;
              }),
          };
          stats.push(yearStats);
        }
        setYears(stats);
        if (callback) {
          callback(stats);
        }
      } else {
        message.error("获取数据失败");
      }
    });
  };

  const search = (searchString) => {
    let path = "/corpus/cards/cards-list" + window.location.search;
    history.push(`${path}&searchString=${searchString}`);
  };

  const searchRef = React.useRef(search);
  searchRef.current = search;

  const getTypes = () => {
    let types = [];
    if (selectedTabIndex === 0) {
      types = [CardType.Golden, CardType.Wiki];
    } else if (selectedTabIndex === 1) {
      types = [CardType.Golden];
    } else {
      types = [CardType.Wiki];
    }
    return types;
  };

  const changeYear = (index) => {
    setYearSelectedIndex(index);
    let searchArray = getUrlSearch();
    if (index === -1) {
      delete searchArray.year;
    } else {
      searchArray.year = years[index].year;
    }
    let keys = Object.keys(searchArray);
    let path = "/corpus/cards/date-list" + (keys.length > 0 ? "?" : "");
    keys.forEach((item, index) => {
      if (index !== keys.length - 1) {
        path += `${item}=${searchArray[item]}&`;
      } else {
        path += `${item}=${searchArray[item]}`;
      }
    });
    window.history.pushState(null, "", path);
  };

  const changeYearRef = React.useRef(changeYear);
  changeYearRef.current = changeYear;

  const renderHeaderContent = () => {
    const categories = [
      {
        name: "项目",
      },
      {
        name: "卡片",
      },
    ];

    return (
      <div className="corpus-header-container">
        <div className="corpus-header-tab-container">
          {categories.map((item, index) => {
            return (
              <div
                key={"corpus-header-" + index}
                className={
                  index === 1
                    ? "corpus-header-tab-item-selected"
                    : "corpus-header-tab-item"
                }
                onClick={() => {
                  if (index !== 1) {
                    history.push("/corpus/project/list?category=all");
                  }
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
        <YearDrop
          position={{ left: "25px" }}
          years={years}
          currentYear={
            yearSelectedIndex === -1
              ? "全部"
              : years[yearSelectedIndex].year + "年"
          }
          selectedCallback={(yearIndex) => {
            changeYearRef.current(yearIndex);
          }}
        />
        <SearchWithDrop
          position={{ right: "25px" }}
          callback={(index, string) => {
            if (index === "当前页面") {
              searchRef.current(string);
            }
            if (index === "全部搜索") {
              openGlobalSearchRef.current(string);
            }
          }}
          selectValues={[
            {
              value: "当前页面",
            },
            {
              value: "全部搜索",
            },
          ]}
        />
      </div>
    );
  };

  const renderYearData = (item) => {
    return (
      <>
        <div className="project-card-page-year-title-container">
          <div className="project-card-page-year-title-dot" />
          <div className="project-card-page-year-title-text">{item.year}年</div>
        </div>
        <div className="project-card-page-year-card-container">
          <Row gutter={[24, 32]}>
            {item.month.map((monthItem, index) => {
              return (
                <Col span={8} key={monthItem.month}>
                  <MonthCardTemplate
                    cardInfo={monthItem}
                    cardClick={(cardInfo) => {
                      history.push(
                        `/corpus/cards/cards-list?year=${cardInfo.year}&month=${cardInfo.month}&category=${tabs[selectedTabIndex]}`
                      );
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  };

  const renderAllYearsData = () => {
    if (years.length <= 0) {
      return <EmptyComponent tip={"没有卡片数据"} />;
    }
    return years.map((item, index) => {
      return (
        <div
          className="project-card-page-year-container"
          key={"year-" + item.year}
        >
          {renderYearData(item)}
        </div>
      );
    });
  };

  const renderSingleYearData = () => {
    let year = years[yearSelectedIndex];

    return (
      <div className="project-card-page-year-card-container">
        <Row gutter={[24, 32]}>
          {year.month.map((monthItem, index) => {
            return (
              <Col span={8} key={monthItem.month}>
                <MonthCardTemplate
                  cardInfo={monthItem}
                  cardClick={(cardInfo) => {
                    // setCardStatInfo(cardInfo);
                    // setShowPage(1);
                    history.push(
                      `/corpus/cards/cards-list?year=${cardInfo.year}&month=${cardInfo.month}&category=${tabs[selectedTabIndex]}`
                    );
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  if (years.length <= 0) {
    return <EmptyComponent tip={"没有卡片数据"} />;
  }

  return (
    <div className="corpus-cards-date-container">
      <div className="corpus-cards-date-tab-container">
        {projectTab.map((item, index) => {
          let isSelected = "";
          if (index === selectedTabIndex) {
            isSelected = "-selected";
          }
          return (
            <div
              key={"container-" + index}
              className={"corpus-cards-date-tab-item-container" + isSelected}
              onClick={() => {
                if (index !== selectedTabIndex) {
                  setSelectedTabIndex(index);
                  let searchArray = getUrlSearch();
                  searchArray.category = tabs[index];
                  let keys = Object.keys(searchArray);
                  let path =
                    "/corpus/cards/date-list" + (keys.length > 0 ? "?" : "");
                  keys.forEach((item, index) => {
                    if (index !== keys.length - 1) {
                      path += `${item}=${searchArray[item]}&`;
                    } else {
                      path += `${item}=${searchArray[item]}`;
                    }
                  });
                  window.history.pushState(null, "", path);
                }
              }}
            >
              <div className={"corpus-cards-date-tab-item-text" + isSelected}>
                {item}
              </div>
            </div>
          );
        })}
      </div>
      <div className="corpus-cards-date-list-container">
        {yearSelectedIndex === -1
          ? renderAllYearsData()
          : renderSingleYearData()}
      </div>
    </div>
  );
};

export default CorpusCardsDateContainer;
