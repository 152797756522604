import { Children } from "react";

export const getChildrenIds = (
  currentCardId,
  outline,
  isWithSelf = false,
  isIncludeNoChildrenIds = true
) => {
  let childrenIds = [];
  if (isWithSelf) {
    childrenIds.push(currentCardId);
  }

  let currentItem = null;
  const getCurrentItem = (outline, currentCardId) => {
    for (let i = 0; i < outline.length; i++) {
      if (outline[i].id === currentCardId) {
        currentItem = outline[i];
        return true;
      } else {
        if (outline[i].children && outline[i].children.length) {
          if (getCurrentItem(outline[i].children, currentCardId)) {
            return true;
          }
        }
      }
    }
  };

  getCurrentItem(outline, currentCardId);

  let ids = getDatasIds(currentItem.children, isIncludeNoChildrenIds);
  childrenIds = childrenIds.concat(ids);

  return childrenIds;
};

export const getDatasIds = (datas, isIncludeNoChildrenIds = true) => {
  let ids = [];

  const getChildrenIds = (datas) => {
    for (let i = 0; i < datas.length; i++) {
      if (isIncludeNoChildrenIds) {
        ids.push(datas[i].id);
      }
      if (datas[i].children && datas[i].children.length) {
        if (!isIncludeNoChildrenIds) {
          ids.push(datas[i].id);
        }
        getChildrenIds(datas[i].children);
      }
    }
  };

  if (datas && datas.length) {
    getChildrenIds(datas);
  }
  return ids;
};

export const findAncestorsIds = (outline, currentCardId, withSelf = false) => {
  let ancestors = [];
  if (withSelf) {
    ancestors.push(currentCardId);
  }
  const findAncestor = (outline, currentCardId) => {
    for (let i = 0; i < outline.length; i++) {
      if (outline[i].id === currentCardId) {
        return true;
      } else {
        if (outline[i].children && outline[i].children.length) {
          if (findAncestor(outline[i].children, currentCardId)) {
            ancestors = ancestors.concat(outline[i].id);
            return true;
          }
        }
      }
    }
  };
  findAncestor(outline, currentCardId);
  return ancestors;
};

export const isInOutline = (id, outline) => {
  const findAncestor = (currentCardId, outline) => {
    for (let i = 0; i < outline.length; i++) {
      if (outline[i].id === currentCardId) {
        return true;
      } else {
        if (outline[i].children && outline[i].children.length) {
          if (findAncestor(currentCardId, outline[i].children)) {
            return true;
          }
        }
      }
    }
    return false;
  };
  return findAncestor(id, outline);
};

//查询到的数据，以callback的方式返回
export const getDataInOutline = (
  outline,
  id,
  callback,
  isFindParentNode = false
) => {
  if (id) {
    const data = outline;
    for (var i = 0; i < data.length; i++) {
      var item = data[i];
      if (id !== item.id) {
        if (item.children && item.children.length) {
          if (isFindParentNode) {
            for (let k in item.children) {
              let child = item.children[k];
              if (child.id === id) {
                callback(data, item, i); //data:查询节点的平级的大纲数据。item:查询的节点。index:查询节点在平级大纲中的索引
                return;
              }
            }
          }
          getDataInOutline(item.children, id, callback, isFindParentNode);
        }
      } else {
        callback(data, item, i);
        return;
      }
    }
  }
};

export const clearOutlineData = (outline) => {
  let outlineTemp = JSON.parse(JSON.stringify(outline));
  for (let i in outlineTemp) {
    let item = outlineTemp[i];
    if (item.hasOwnProperty("content")) {
      delete item.content;
    }
    if (item.children && item.children.length) {
      item.children = clearOutlineData(item.children);
    }
  }
  return outlineTemp;
};

export const copyObject = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const uniq = (array) => {
  let temp = [];
  for (let i = 0; i < array.length; i++) {
    if (temp.indexOf(array[i]) === -1) {
      temp.push(array[i]);
    }
  }
  return temp;
};

export const getOutlineDatas = (data, isWithSelf = false) => {
  let datas = [];
  if (isWithSelf) {
    datas.push(data);
  }

  const getDatas = (children) => {
    for (let i = 0; i < children.length; i++) {
      let item = children[i];
      datas.push(item);
      if (item.children && item.children.length) {
        getDatas(item.children);
      }
    }
  };

  if (data.children && data.children.length) {
    getDatas(data.children);
  }

  return datas;
};
