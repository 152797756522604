import * as React from "react";
import "../styles.css";

//引入的库组件
import { Tooltip } from "antd";

//引入的其他页面组件
import ReactMarkdown from "../../../Components/ReactMarkdown";

//graphql查询

//引入的资源图片
import HelpIcon from "../../../../assets/writing/statistic/help.png";

const Help = (props) => {
  const { tip, position, placement = null } = props;

  const renderTipContent = () => {
    return (
      <ReactMarkdown
        className="statistics-module-help-markdown-container"
        source={tip}
      />
    );
  };

  return (
    <Tooltip
      title={renderTipContent()}
      placement={placement ? placement : "leftTop"}
      arrowPointAtCenter={true}
      autoAdjustOverflow={true}
      overlayClassName="statistics-module-help-tool-tip"
    >
      <img
        src={HelpIcon}
        className="statistics-module-help"
        style={{
          ...position,
        }}
      />
    </Tooltip>
  );
};

export default Help;
