import React from "react";
import "./App.css";

import { ConfigProvider } from "antd";
import zhCN from "antd/es/locale/zh_CN";

import HomePage from './pages/HomePage/index';

const App: React.FC = () => {
  return (
    <ConfigProvider locale={zhCN}>
      <HomePage/>
    </ConfigProvider>
  );
};

export default App;
