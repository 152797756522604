import * as React from "react";
import "./styles.css";

//引入的库组件
import {
  message,
  Pagination,
  Row,
  Col,
  Dropdown,
  Menu,
  Icon,
  Spin,
} from "antd";
import moment from "moment";

//引入的其他页面组件
import MonthCardTemplate from "./MonthCardTemplate/monthCardTemplate";
import { getPaginationPageVariable } from "../../utils/index";
import CardTemplate from "../Components/CardTemplate/index";
import { renderPagination } from "../Components/Pagination/index";

//graphql查询
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { SortOrder, CardType, FileType } from "../../generated/graphql";

//引入的资源图片
import BackIcon from "../../assets/project/back-icon.png";
import NormalCardIcon from "../../assets/writing/normal-card.png";
import EmptyComponent from "../Components/EmptyComponent";

const PER_PAGE_CARD_COUNT = 18;

const GET_YEAR_STAT = gql`
  {
    projectCardStatsClient @client
    currentYearStatClient @client
  }
`;

const CardsContainer = (props) => {
  const {
    setSearchCallback,
    searchString,
    setSearchString,
    getCardListQuery,
  } = props;

  const client = useApolloClient();

  const { data: yearStatsData } = useQuery(GET_YEAR_STAT);
  const { projectCardStatsClient, currentYearStatClient } = yearStatsData;

  const [showCardList, setShowCardList] = React.useState(false);
  const [showMonthCardList, setShowMonthCardList] = React.useState(false);
  const [monthCardInfo, setMonthCardInfo] = React.useState({});
  const [cardList, setCardList] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentPageInfo, setCurrentPageInfo] = React.useState({
    count: 0,
    pageSize: PER_PAGE_CARD_COUNT,
    pageInfo: null,
  });

  React.useEffect(() => {
    setSearchCallback(searchCard);
    if (showCardList) {
      searchCard(searchString);
    }
  }, [monthCardInfo, showMonthCardList, showCardList, currentYearStatClient]);

  const searchCard = (searchString) => {
    let filter = {
      types: [CardType.User],
      fileTypes: [FileType.File],
    };
    if (showMonthCardList) {
      let startDate = moment(monthCardInfo.year + "-" + monthCardInfo.month)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endDate = moment(monthCardInfo.year + "-" + monthCardInfo.month)
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");
      filter = {
        ...filter,
        year: monthCardInfo.year,
        month: monthCardInfo.month,
        // startDate: startDate,
        // endDate: endDate
      };
    }

    if (currentYearStatClient !== 0) {
      let startDate = moment(currentYearStatClient + "-" + 1)
        .startOf("month")
        .format("YYYY-MM-DD");
      let endDate = moment(currentYearStatClient + "-" + 12)
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");
      filter = {
        ...filter,
        year: currentYearStatClient,
        // startDate: startDate,
        // endDate: endDate
      };
    }

    let variables = {
      after: undefined,
      first: PER_PAGE_CARD_COUNT,
      before: undefined,
      last: undefined,
      matching: searchString !== "" ? searchString : undefined,
      order: SortOrder.Desc,
      filter: filter,
    };
    setSearchString(searchString);
    getCardList(variables, () => {
      setCurrentPage(1);
      if (!showMonthCardList) {
        setShowCardList(true);
      }
    });
  };

  const getCardList = (variables, callback) => {
    getCardListQuery(variables).then((res) => {
      if (res.data.cards) {
        let pageInfo = res.data.cards.pageInfo;
        let count = res.data.cards.count;
        let cards = res.data.cards.edges.map((item, index) => {
          return item.node;
        });
        setCurrentPageInfo({ ...currentPageInfo, pageInfo, count });
        setCardList(cards);
        setIsLoading(false);
        if (callback) {
          callback();
        }
      } else {
        message.error("获取卡片失败");
      }
    });
  };

  const renderYearData = (item) => {
    return (
      <>
        <div className="project-card-page-year-title-container">
          <div className="project-card-page-year-title-dot" />
          <div className="project-card-page-year-title-text">{item.year}年</div>
        </div>
        <div className="project-card-page-year-card-container">
          <Row gutter={[24, 32]}>
            {item.month.map((monthItem, index) => {
              return (
                <Col span={8}>
                  <MonthCardTemplate
                    key={monthItem.month}
                    cardInfo={monthItem}
                    cardClick={(cardInfo) => {
                      setShowMonthCardList(true);
                      setMonthCardInfo(cardInfo);
                      setIsLoading(true);
                      let startDate = moment(
                        cardInfo.year + "-" + cardInfo.month
                      )
                        .startOf("month")
                        .format("YYYY-MM-DD");
                      let endDate = moment(cardInfo.year + "-" + cardInfo.month)
                        .endOf("month")
                        .add(1, "days")
                        .format("YYYY-MM-DD");
                      let variables = {
                        after: undefined,
                        first: PER_PAGE_CARD_COUNT,
                        before: undefined,
                        last: undefined,
                        matching:
                          searchString !== "" ? searchString : undefined,
                        order: SortOrder.Desc,
                        filter: {
                          types: [CardType.User],
                          fileTypes: [FileType.File],
                          year: cardInfo.year,
                          month: cardInfo.month,
                          // startDate: startDate,
                          // endDate: endDate
                        },
                      };
                      getCardList(variables, () => {
                        setCurrentPage(1);
                      });
                    }}
                  />
                </Col>
              );
            })}
          </Row>
        </div>
      </>
    );
  };

  const renderAllYearsData = () => {
    let cardsStats = JSON.parse(projectCardStatsClient);
    return cardsStats.map((item, index) => {
      return (
        <div
          className="project-card-page-year-container"
          key={"year-" + item.year}
        >
          {renderYearData(item)}
        </div>
      );
    });
  };

  const renderSingleYearData = () => {
    let year = currentYearStatClient;
    let cardsStats = JSON.parse(projectCardStatsClient);
    let item = null;
    for (let i in cardsStats) {
      if (cardsStats[i].year === year) {
        item = cardsStats[i];
        break;
      }
    }
    return (
      <div className="project-card-page-year-card-container">
        <Row gutter={[24, 32]}>
          {item.month.map((monthItem, index) => {
            return (
              <Col span={8} key={monthItem.month}>
                <MonthCardTemplate
                  cardInfo={monthItem}
                  cardClick={(cardInfo) => {
                    setShowMonthCardList(true);
                    setMonthCardInfo(cardInfo);
                    setIsLoading(true);
                    let startDate = moment(cardInfo.year + "-" + cardInfo.month)
                      .startOf("month")
                      .format("YYYY-MM-DD");
                    let endDate = moment(cardInfo.year + "-" + cardInfo.month)
                      .endOf("month")
                      .add(1, "days")
                      .format("YYYY-MM-DD");
                    let variables = {
                      after: undefined,
                      first: PER_PAGE_CARD_COUNT,
                      before: undefined,
                      last: undefined,
                      matching: searchString !== "" ? searchString : undefined,
                      order: SortOrder.Desc,
                      filter: {
                        types: [CardType.User],
                        fileTypes: [FileType.File],
                        year: cardInfo.year,
                        month: cardInfo.month,
                        // startDate: startDate,
                        // endDate: endDate
                      },
                    };
                    getCardList(variables, () => {
                      setCurrentPage(1);
                    });
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  };

  const renderMonthCardList = () => {
    return (
      <>
        <div className="project-card-page-month-title-container">
          <img
            src={BackIcon}
            className="project-card-page-month-title-icon"
            onClick={() => {
              setShowMonthCardList(false);
            }}
          />
          <div className="project-card-page-month-title-text">
            {monthCardInfo.year}年 · {monthCardInfo.month}月
          </div>
          <div className="project-card-page-month-title-text">
            {currentPageInfo.count}张
          </div>
        </div>
        <div className="project-card-cards-list-content">
          <Spin
            spinning={isLoading}
            wrapperClassName="project-card-page-month-list-container"
          >
            <Row gutter={[24, 48]}>
              {cardList.map((item, index) => {
                return (
                  <Col span={8} key={"renderMonthCardList-" + item.id}>
                    <CardTemplate
                      item={item}
                      icon={NormalCardIcon}
                      isHoverOutLine={true}
                      isHoverShowIcon={true}
                      searchString={searchString}
                      addToProjectCallback={null}
                    />
                  </Col>
                );
              })}
            </Row>
          </Spin>
          <div className="project-card-list-content-pagination">
            <Pagination
              itemRender={renderPagination}
              pageSize={PER_PAGE_CARD_COUNT}
              current={currentPage}
              total={currentPageInfo.count}
              hideOnSinglePage
              onChange={(page) => {
                let variables = getPaginationPageVariable(
                  page,
                  currentPage,
                  currentPageInfo
                );
                let startDate = moment(
                  monthCardInfo.year + "-" + monthCardInfo.month
                )
                  .startOf("month")
                  .format("YYYY-MM-DD");
                let endDate = moment(
                  monthCardInfo.year + "-" + monthCardInfo.month
                )
                  .endOf("month")
                  .add(1, "days")
                  .format("YYYY-MM-DD");
                variables = {
                  ...variables,
                  matching: searchString !== "" ? searchString : undefined,
                  order: SortOrder.Desc,
                  filter: {
                    types: [CardType.User],
                    fileTypes: [FileType.File],
                    year: monthCardInfo.year,
                    month: monthCardInfo.month,
                    // startDate: startDate,
                    // endDate: endDate
                  },
                };
                getCardList(variables, () => {
                  setCurrentPage(page);
                });
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const renderCardList = () => {
    return (
      <>
        <div className="project-card-page-month-title-container">
          <img
            src={BackIcon}
            className="project-card-page-month-title-icon"
            onClick={() => {
              setShowCardList(false);
            }}
          />
          <div className="project-card-page-month-title-text">
            {currentYearStatClient === 0
              ? "全部"
              : currentYearStatClient + "年"}
          </div>
          <div className="project-card-page-month-title-text">
            {currentPageInfo.count}张
          </div>
        </div>
        <div className="project-card-cards-list-content">
          <Spin
            spinning={isLoading}
            wrapperClassName="project-card-page-month-list-container"
          >
            <Row gutter={[24, 48]}>
              {cardList.map((item, index) => {
                return (
                  <Col span={8} key={"renderCardList-" + item.id}>
                    <CardTemplate
                      item={item}
                      icon={NormalCardIcon}
                      isHoverOutLine={true}
                      isHoverShowIcon={true}
                      searchString={searchString}
                      addToProjectCallback={null}
                    />
                  </Col>
                );
              })}
            </Row>
            <div className="project-card-list-content-pagination">
              <Pagination
                itemRender={renderPagination}
                pageSize={PER_PAGE_CARD_COUNT}
                current={currentPage}
                total={currentPageInfo.count}
                hideOnSinglePage
                onChange={(page) => {
                  let variables = getPaginationPageVariable(
                    page,
                    currentPage,
                    currentPageInfo
                  );
                  let filter = {
                    types: [CardType.User],
                    fileTypes: [FileType.File],
                  };
                  if (currentYearStatClient !== 0) {
                    let startDate = moment(currentYearStatClient + "-" + 1)
                      .startOf("month")
                      .format("YYYY-MM-DD");
                    let endDate = moment(currentYearStatClient + "-" + 12)
                      .endOf("month")
                      .add(1, "days")
                      .format("YYYY-MM-DD");
                    filter = {
                      ...filter,
                      year: currentYearStatClient,
                      // startDate: startDate,
                      // endDate: endDate
                    };
                  }

                  variables = {
                    ...variables,
                    matching: searchString !== "" ? searchString : undefined,
                    order: SortOrder.Desc,
                    filter: filter,
                  };
                  getCardList(variables, () => {
                    setCurrentPage(page);
                  });
                }}
              />
            </div>
          </Spin>
        </div>
      </>
    );
  };

  if (projectCardStatsClient === "") {
    return <EmptyComponent tip={"没有卡片数据"} />;
  }

  return (
    <>
      {showCardList && renderCardList()}
      {showMonthCardList && renderMonthCardList()}
      {!showCardList && !showMonthCardList && (
        <div className="project-card-page-container">
          <div className="project-card-page-list">
            {currentYearStatClient === 0
              ? renderAllYearsData()
              : renderSingleYearData()}
          </div>
        </div>
      )}
    </>
  );
};

export default CardsContainer;
