import * as React from "react";
import "./styles.css";

//引入的库组件
import { Spin, message, Progress } from "antd";

//graphql查询
import { useGetReplacementWordsQuery } from "../../../generated/graphql";

//引入的其他页面组件
import MarkHoverTipModal from "../../Components/MarkHoverTipModal/index";

//引入的资源图片

const WordReplacementDropdown = (props) => {
  const { position, wordReplacementDropdownData, wordClickCallback } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [words, setWords] = React.useState([]);

  const { refetch: getReplacementWordsQuery } = useGetReplacementWordsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    if (wordReplacementDropdownData) {
      setIsLoading(true);
      // setTimeout(() => {
      //   let words = [];
      //   for (let i = 0; i < 5; i++) {
      //     words.push(`这就是${i}`);
      //   }
      //   setWords(words);
      //   setIsLoading(false);
      // }, 3000);
      getReplacementWordsQuery({
        matching: wordReplacementDropdownData.word,
      }).then((res) => {
        setIsLoading(false);
        if (res.errors) {
          message.error("获取同义词失败");
          setWords([]);
        } else {
          try {
            let words = res.data.querySimWords.simWords;
            if (words.length) {
              setWords(words.filter((item, index) => {return index < 5}));
            } else {
              message.error("没有同义词");
              setWords([]);
            }
          } catch {}
        }
      });
    }
  }, [wordReplacementDropdownData]);

  const renderTitle = () => {
    if (!!!wordReplacementDropdownData) {
      return null;
    }
    return (
      <div className="word-replacement-dropdown-word-title-container">
        <div className="word-replacement-dropdown-word-title-text">
          {wordReplacementDropdownData.word}
        </div>
      </div>
    );
  };

  const renderReplacementItem = (item, index) => {
    return (
      <div
        key={index}
        className="word-replacement-dropdown-list-item-container"
        onClick={() => {
          if (wordClickCallback) {
            wordClickCallback({replaceWord: item, data: wordReplacementDropdownData});
          }
        }}
      >
        <div className="word-replacement-dropdown-list-item-text">{item.word}</div>
        <Progress
          percent={item.weight * 100}
          showInfo={false}
          status={"success"}
          className="word-replacement-dropdown-list-item-progress"
        />
      </div>
    );
  };

  const renderBody = () => {
    if (!!!wordReplacementDropdownData) {
      return null;
    }
    if (isLoading) {
      return (
        <div className="word-replacement-dropdown-loading">
          <Spin spinning={true} tip={"正在查询同义词"} />
        </div>
      );
    }
    return (
      <div className="word-replacement-dropdown-list-container">
        {words.map((item, index) => {
          return renderReplacementItem(item, index);
        })}
      </div>
    );
  };

  return (
    <MarkHoverTipModal
      position={position}
      componentId="aw-mark-word-replacement"
    >
      <div className="word-replacement-dropdown-container">
        {renderTitle()}
        <div className="word-replacement-dropdown-divider" />
        {renderBody()}
      </div>
    </MarkHoverTipModal>
  );
};

export default WordReplacementDropdown;
