import * as React from "react";
import "./styles.css";

//引入的库组件

//引入的其他页面组件
import CardItem from "../CardItem/cardItem";

//graphql查询

//引入的资源图片
import CardIcon from "../../../assets/project/card-count-icon.png";

const MonthCardTemplate = (props) => {
  const { cardInfo, cardClick } = props;
  return (
    <div
      className="project-month-card-template-container"
      onClick={() => {
        cardClick(cardInfo);
      }}
    >
      <div className="project-month-card-template-layer3" />
      <div className="project-month-card-template-layer2" />
      <div className="project-month-card-template-layer1">
        <div className="project-month-card-template-title-container">
          <div className="project-month-card-template-title-number">
            {cardInfo.month}
          </div>
          <div className="project-month-card-template-title-month">月</div>
        </div>
        <div className="project-month-card-template-divider" />
        <div className="project-month-card-template-count-contaier">
          <img
            src={CardIcon}
            className="project-month-card-template-count-icon"
          />
          <div className="project-month-card-template-count-text">
            {cardInfo.count}张
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthCardTemplate;
