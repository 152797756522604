import React, { useEffect } from "react";
import FeedbackPanel from "./FeedbackPanel";
import NO_MISTAKE from "../../assets/no_mistake.png";
import "./styles.css";

const FeedbackList = (props) => {
  const {
    activeId,
    TabKey,
    noMistake,
    ignoreWords,
    omitAllMistake,
    getFeedbackData,
    correctAllMistake,
    isEditModule,
    feedbackTabKey,
    omitMistake,
    feedbackData,
    correctMistake,
  } = props;
  const [openPanelId, setOpenPanelId] = React.useState("");
  const [checkTextBegin, setCheckTextBegin] = React.useState(true);

  useEffect(() => {
    if (feedbackData.length && checkTextBegin) {
      setCheckTextBegin(false);
    }
  }, [feedbackData]);

  return (
    <div className="feedback-list-outerbox">
      <div className="feedback-list-box">
        <div className="feedback-list">
          {feedbackData.length ? (
            feedbackData.map((feedback, i) => (
              <FeedbackPanel
                feedbackData={feedbackData}
                omitMistake={omitMistake}
                openPanelId={openPanelId}
                setOpenPanelId={setOpenPanelId}
                correctMistake={correctMistake}
                TabKey={TabKey}
                mistakeIndex={i}
                key={feedback.cardId + i}
                activeId={activeId}
                feedback={feedback}
                ignoreWords={ignoreWords}
              />
            ))
          ) : noMistake ? (
            <section
              onClick={() => {
                const isReCheck = true;
                getFeedbackData(isReCheck);
              }}
              className="no-mistake"
            >
              <img src={NO_MISTAKE} className="no-mistake-icon" />
              <p className="no-mistake-text">重新检查</p>
            </section>
          ) : null}
          <div className="feedback-list-checktip-bar-background">
            <div style={{ paddingLeft: 15 }}>
              <span className="feedback-advise-text">
                {" "}
                建议数: {feedbackData.length}
              </span>
            </div>
            <div style={{ display: "flex" }}>
              <div
                onClick={() => {
                  omitAllMistake();
                  getFeedbackData();
                }}
                className="feedback-re-submit"
              >
                {isEditModule && checkTextBegin ? "开始检查" : "重新检查"}
              </div>
              {isEditModule && checkTextBegin ? null : (
                <div
                  onClick={
                    feedbackTabKey == 1 ? correctAllMistake : omitAllMistake
                  }
                  className="feedback-proofread-all"
                >
                  {feedbackTabKey == 1 ? "修改全部" : "忽略全部"}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackList;
