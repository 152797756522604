import React, { useState } from "react";
import "./styles.css";

import { Icon, message, Input } from "antd";
import HighlightText from "../HightlightText/index";

import copy from "copy-to-clipboard";

import Insert from "../../../assets/insert-card.png";
import Copy from "../../../assets/inspiration/copy.png";
import Uncollect from "../../../assets/inspiration/uncollect.png";
import Collect from "../../../assets/inspiration/collect.png";
import Delete from "../../../assets/delete_card.png";

const { TextArea } = Input;

const CardTemplate = (props) => {
  const {
    item,
    searchString,
    extraTitleButton = [],
    style = { width: "340px", height: "226px" },
    icon = "",
    isHoverOutLine = false,
    isHoverShowIcon = false,
    isTitleEditble = false,
    titleEditCallback = null,
    isContentEditble = false,
    contentEditCallback = null,
    setSelectedCard = null,
    clickCallback = null,
  } = props;
  const { title, content } = item;
  const [isAdding, setIsAdding] = React.useState(false);
  const [isCollecting, setIsCollecting] = React.useState(false);
  const [isMouseIn, setIsMouseIn] = React.useState(false);
  const [isTitleEdit, setIsTitleEdit] = React.useState(false);
  const [titleEditString, setTitleEditString] = React.useState(title);
  const [isContentEdit, setIsContentEdit] = React.useState(false);
  const [contentEditString, setContentEditString] = React.useState(content);

  const renderAddToProject = (callback) => {
    return (
      <div key={"renderAddToProject"} style={{ marginLeft: "10px" }}>
        {isAdding ? (
          <Icon type="loading" />
        ) : (
          <img
            src={Insert}
            style={{ width: "14px", height: "14px", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              if (callback) {
                setIsAdding(true);
                callback(item, () => {
                  setIsAdding(false);
                });
              }
            }}
          />
        )}
      </div>
    );
  };

  const renderCollectButton = (callback) => {
    return (
      <div key={"renderCollectButton"} style={{ marginLeft: "10px" }}>
        {isCollecting ? (
          <Icon type="loading" />
        ) : (
          <img
            src={Uncollect}
            style={{ width: "14px", height: "14px", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              if (callback) {
                setIsCollecting(true);
                callback(item, () => {
                  setIsCollecting(false);
                });
              }
            }}
          />
        )}
      </div>
    );
  };

  const renderCopyButton = (callback) => {
    return (
      <div key={"renderCopyButton"} style={{ marginLeft: "10px" }}>
        <img
          src={Copy}
          style={{ width: "14px", height: "14px", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            copy(item.content);
            message.success("内容已复制到剪贴板");
          }}
        />
      </div>
    );
  };

  const renderDeleteButton = (callback) => {
    return (
      <div key={"renderDeleteButton"} style={{ marginLeft: "10px" }}>
        <img
          src={Delete}
          style={{ width: "14px", height: "14px", cursor: "pointer" }}
          onClick={(e) => {
            e.stopPropagation();
            if (callback) {
              callback(item);
            }
          }}
        />
      </div>
    );
  };

  const renderExtraTitleButton = (extraTitleButton) => {
    switch (extraTitleButton.buttonType) {
      case "addToProject":
        return renderAddToProject(extraTitleButton.callback);
      case "collect":
        return renderCollectButton(extraTitleButton.callback);
      case "copy":
        return renderCopyButton(extraTitleButton.callback);
      case "delete":
        return renderDeleteButton(extraTitleButton.callback);
      default:
        return null;
    }
  };

  let className = "card-template-container";
  if (isHoverOutLine && isMouseIn) {
    className = className + " card-template-container-outline";
  }

  return (
    <div
      className={className}
      style={{...style, cursor: clickCallback ? "pointer" : "unset"}}
      onMouseEnter={() => {
        setIsMouseIn(true);
      }}
      onMouseLeave={() => {
        setIsMouseIn(false);
      }}
      onClick={() => {
        if (clickCallback) {
          setSelectedCard(item);
          clickCallback(item);
        }
      }}
    >
      <div className="card-template-header-container">
        {icon === "" ? (
          <Icon type="search" style={{ width: "14px", height: "14px" }} />
        ) : (
          <img src={icon} style={{ width: "14px", height: "14px" }} />
        )}
        <div
          className="card-template-header-text-container"
          onDoubleClick={() => {
            if (isTitleEditble) {
              setIsTitleEdit(true);
            }
          }}
        >
          {isTitleEdit ? (
            <input
              autoFocus
              onFocus={(e) => {
                //获取焦点后自动全选
                e.target.select();
                if (setSelectedCard) {
                  setSelectedCard(item);
                }
              }}
              className="card-template-header-input"
              value={titleEditString}
              onBlur={() => {
                if (titleEditCallback) {
                  titleEditCallback(titleEditString);
                }
                setIsTitleEdit(false);
              }}
              onChange={(e) => {
                setTitleEditString(e.target.value);
              }}
            />
          ) : (
            <HighlightText
              className="card-template-header-text"
              text={titleEditString}
              highlightString={searchString}
              hightlightColor={"#FFF5A0"}
            />
          )}
        </div>
        {(!isHoverShowIcon || (isHoverShowIcon && isMouseIn)) &&
          extraTitleButton.map((item) => {
            return renderExtraTitleButton(item);
          })}
      </div>
      <div className="card-template-header-divider" />
      <div
        className="card-template-content-container"
        onDoubleClick={() => {
          if (isContentEditble) {
            setIsContentEdit(true);
          }
        }}
      >
        {isContentEdit ? (
          <TextArea
            className="card-template-content-input"
            value={contentEditString}
            autoSize={{ minRows: 8 }}
            autoFocus
            onFocus={(e) => {
              //获取焦点后自动全选
              e.target.select();
              if (setSelectedCard) {
                setSelectedCard(item);
              }
            }}
            onChange={(e) => {
              setContentEditString(e.target.value);
            }}
            onBlur={() => {
              if (contentEditCallback) {
                contentEditCallback(contentEditString);
              }
              setIsContentEdit(false);
            }}
          />
        ) : (
          <HighlightText
            className="card-template-content-text"
            text={contentEditString}
            highlightString={searchString}
            hightlightColor={"#FFF5A0"}
          />
        )}
      </div>
    </div>
  );
};

export default CardTemplate;
