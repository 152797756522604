import React, { useState } from "react";

const HighlightText = props => {
  const {
    className,
    text,
    highlightString,
    hightlightColor,
  } = props;

  if (
    !text ||
    !highlightString ||
    highlightString === "" ||
    text.indexOf(highlightString) < 0
  ) {
    return <div className={className}>{text}</div>;
  }

  if (text === highlightString) {
    return (
      <div className={className}>
        <span style={{ backgroundColor: hightlightColor }}>{text}</span>
      </div>
    );
  }

  //内容框有10行，一行260字，测试搜索文字： 一首五采缤纷的歌
  let newText = text ;
  let highlightStringStartIndex = newText.indexOf(highlightString);
  if (highlightStringStartIndex >= 260) {
    if(newText.length - highlightStringStartIndex >= 260) {
      newText = newText.substring(highlightStringStartIndex - 100);
    }else {
      newText = newText.substring(highlightStringStartIndex - (260 - (newText.length - highlightStringStartIndex) + 50));
    }
    newText = "..." + newText;
  }

  let otherStrs = newText.split(highlightString);
  return (
    <div className={className}>
      {otherStrs.map((item, index) => {
        if (index < otherStrs.length - 1 && item === "") {
          return (
            <span style={{ backgroundColor: hightlightColor }}>
              {highlightString}
            </span>
          );
        } else if (index < otherStrs.length - 1) {
          return (
            <>
              <>{item}</>
              <span style={{ backgroundColor: hightlightColor }}>
                {highlightString}
              </span>
            </>
          );
        } else {
          return <>{item}</>;
        }
      })}
    </div>
  );
};

export default HighlightText;
