import * as React from "react";
import "./styles.css";

//引入的其他页面组件
import TreeNodeInput from "./treeNodeInput";
import TreeComponent from "./treeComponent/index";

//引入的库组件
import { Icon, Tree, Spin } from "antd";
import { useQuery } from "react-apollo-hooks";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";

//引入的资源图片

const WRITING_OUTLINE_CHANGE_NAME_ID = gql`
  {
    writingChangeNameInOutlineIdClient @client
  }
`;

const WritingOutline = (props) => {
  const {
    outline,
    currentSelectCardId,
    treeNodeInput,
    currentExpandedKeys,
    leaveMenu,
    enterMenu,
    onExpand,
    onDragEnter,
    onDrop,
    onSelect,
    currentProjectInfo,
    showRightMenu,
    updateCardAndOutline,
    dragAndDropCallback
  } = props;

  const client = useApolloClient();
  const { data: changeNameData } = useQuery(WRITING_OUTLINE_CHANGE_NAME_ID);
  const { writingChangeNameInOutlineIdClient } = changeNameData;

  const outlineDom = React.useRef();

  React.useEffect(() => {
    const oDiv = outlineDom.current;
    if (!oDiv) return;
    oDiv.onmousemove = function(ev) {
      if (ev.target.className === "writing-outline-container") {
        oDiv.style.cursor = "move";
      }
    };
    oDiv.onmouseout = function(ev) {
      if (ev.target.className === "writing-outline-container") {
        oDiv.style.cursor = "unset";
      }
    };

    oDiv.onmousedown = function(ev) {
      if (ev.target.className === "writing-outline-container") {
        ev.preventDefault();
        // 鼠标按下时的位置
        var mouseDownX = ev.clientX;
        var mouseDownY = ev.clientY;
        // 方块上下左右四个边的位置和方块的长宽
        var T0 = this.offsetTop;
        var B0 = this.offsetTop + this.offsetHeight;
        var L0 = this.offsetLeft;
        var R0 = this.offsetLeft + this.offsetWidth;
        var W = this.offsetWidth;
        var H = this.offsetHeight;
        // 设置方块的识别范围
        var areaT = T0 + 10;
        var areaB = B0 - 10;
        var areaL = L0 + 10;
        var areaR = R0 - 10;
        // 判断改变方块的大小的方向
        // 左
        var changeL = mouseDownX < areaL;
        // 右
        var changeR = mouseDownX > areaR;
        // 上
        var changeT = mouseDownY < areaT;
        // 下
        var changeB = mouseDownY > areaB;
        // IE8 取消默认行为-设置全局捕获
        if (oDiv.setCapture) {
          oDiv.setCapture();
        }
        document.onmousemove = function(ev) {
          ev.stopPropagation();
          // 鼠标移动时的鼠标位置
          var mouseMoveX = ev.clientX;
          var mouseMoveY = ev.clientY;
          //根据改变方块大小的方向不同进行大小的改变
          // 左
          // if (changeL) {
          //   oDiv.style.width = mouseDownX - mouseMoveX + W + "px";
          //   oDiv.style.left = L0 - (mouseDownX - mouseMoveX) + "px";
          // }
          // 右
          if (changeR) {
            oDiv.style.width = mouseMoveX - mouseDownX + W + "px";
          }
          // 上
          // if (changeT) {
          //   oDiv.style.height = mouseDownY - mouseMoveY + H + "px";
          //   oDiv.style.top = T0 - (mouseDownY - mouseMoveY) + "px";
          // }
          // // 下
          // if (changeB) {
          //   oDiv.style.height = mouseMoveY - mouseDownY + H + "px";
          // }
          // 限定范围
          if (parseInt(oDiv.style.width) < 50) {
            oDiv.style.width = 50 + "px";
          }
          if (parseInt(oDiv.style.height) < 50) {
            oDiv.style.height = 50 + "px";
          }
        };
        document.onmouseup = function() {
          ev.stopPropagation();
          document.onmousemove = null;
          // 释放全局捕获
          if (oDiv.releaseCapture) {
            oDiv.releaseCapture();
          }
        };
        return false;
      }
    };
  }, [outlineDom]);

  const renderTreeNodeInput = (props) => {
    return <TreeNodeInput {...props} />;
  };

  const renderNode = (outline) => {
    return outline.map((item, index) => {
      if (item.children && item.children.length) {
        return (
          <Tree.TreeNode
            key={item.id}
            title={renderTreeNodeInput({
              icon: item.icon,
              title: item.title,
              id: item.id,
              index,
              children: item.children,
              currentProjectInfo,
              showRightMenu,
              updateCardAndOutline,
            })}
          >
            {renderNode(item.children)}
          </Tree.TreeNode>
        );
      } else {
        return (
          <Tree.TreeNode
            isLeaf
            key={item.id}
            title={renderTreeNodeInput({
              icon: item.icon,
              title: item.title,
              id: item.id,
              index,
              children: [],
              currentProjectInfo,
              showRightMenu,
              updateCardAndOutline,
            })}
          />
        );
      }
    });
  };

  return (
    <div
      key={"outline-tree"}
      className="writing-outline-container"
      ref={outlineDom}
      onMouseLeave={leaveMenu}
      onMouseEnter={enterMenu}
      onContextMenu={(e) => e.preventDefault()}
    >
      {/* <Tree
        expandedKeys={currentExpandedKeys}
        autoExpandParent={false}
        defaultExpandParent={false}
        blockNode={true}
        selectedKeys={[currentSelectCardId]}
        draggable={writingChangeNameInOutlineIdClient === ""}
        className="draggable-tree"
        onExpand={onExpand}
        onSelect={onSelect}
        onDragEnter={onDragEnter}
        onDrop={onDrop}
      >
        {renderNode(outline)}
      </Tree> */}
      <TreeComponent
        outline={outline}
        expandedKeys={currentExpandedKeys}
        selectedKey={currentSelectCardId}
        onSelect={onSelect}
        onExpand={onExpand}
        currentProjectInfo={currentProjectInfo}
        showRightMenu={showRightMenu}
        updateCardAndOutline={updateCardAndOutline}
        dragAndDropCallback={dragAndDropCallback}
      />
    </div>
  );
};

export default WritingOutline;
