//修改每个case项下对应的return即可返回对应提示
export const getModuleTip = (module) => {
  switch (module) {
    case "entirety-style":
      return `### 风格\n\n文本的整体感觉，包括指标如下，分数范围皆为「0-100 」，每 20 分为一档，总计五档，用不同颜色区分。\n\n* **可读指数**：衡量文本的复杂程度，得分越高，越容易阅读。若想提高可读指数，可少用生僻词，多用简单句。\n* **情感指数**：衡量文本的情感倾向，得分越高，越积极、正面。若想提高情感指数，可删减贬义词及其近义词。\n* **多样指数**：衡量文本的用词丰富程度，得分越高，使用的词汇越丰富。若想提高多样指数，可有意识使用新异词汇。\n\n**提醒**：文无定式，某项指数较低并不代表写作差，喜欢写复句的有伍尔芙等；文风阴郁的作家有三岛由纪夫等，张爱玲喜欢俗词新用，鲁迅好用新词。`;
    case "entirety-data":
      return `### 数据\n\n文本的数据统计，包括指标如下：\n\n* **字数**：比如，张爱玲的 《天才梦》总计 1189 字。\n* **词数**：比如，张爱玲的 《天才梦》总计 712 个词。\n* **句数**：比如，张爱玲的 《天才梦》总计 44 句。\n* **段数**：比如，张爱玲的 《天才梦》总计 12 段。\n\n基于上述原始数据，二次计算出：\n\n* **平均词长**：分词后，计算每个词的字数。比如，张爱玲的 《天才梦》为 1.71 ，表示作者使用了很多二字词，但也使用了不少单字词。鲁迅的《论雷峰塔的倒掉》为 1.59，表示作者使用的单字词更多。「我」是典型的单字词，「苹果」是典型的二字词，「天才梦」、「雷峰塔」是典型的三字词。\n* **平均句长**：包括标点符号在内，计算每个句子的字数，然后再做平均。比如，张爱玲的 《天才梦》平均句长为 31 个字。\n* **平均段长**：包括标点符号在内，计算每个段落的字数，然后再做平均。比如，鲁迅的《论雷峰塔的倒掉》平均段长为 106 字、张爱玲的《天才梦》平均段长为 114字。符合中文阅读习惯的段落字数，**建议控制在 100 字到 300 字。** \n* **词汇量**：依据文本推测所得，得分越高，说明作者掌握的词汇越多。**掌握的词汇量并不代表日常常用的词汇量，**张爱玲常用词汇量在 5 万；鲁迅在 15 万。`;

    case "word-pronunciation":
      return `### 字音\n\n汉语与英语差异较大。汉语分为声母与韵母。声母是指韵母前面的辅音，普通话的声母为23个，比如 b/p/m/f。韵母是指字音中声母、字调以外的部分。旧称为韵。普通话中共有 39 个韵母。比如 ang 韵、ou 韵。\n\n古代汉语分为唇齿喉舌鼻五音，普通话分为唇音、舌尖音、舌面音三种。考虑到中国各地方言、文言文的广泛流传，以及尊重中文美感传统，我们采纳古代汉语的五音划分。\n\n* **唇音**：b/p/m/f/w\n* **齿音**：d/t/n/l\n* **喉音**：j/q/x/y\n* **舌音**：z/c/s/zh/ch/sh/r\n* **鼻音**：g/k/h\n\n好的作家无不善用字音表达轻重轻清重浊不同意象。\n\n* 如果你想表达轻柔软弱，缠绵悱恻，可以多用齿音舌音。比如李清照《声声慢》，全词八十九字，齿音和舌音五十一字，占半数以上，如「寻寻觅觅冷冷清清，凄凄惨惨戚戚」。\n* 如果你想表达刚猛强壮，昂扬向上，可以多用牙音与喉音。比如李白《将进酒·君不见黄河之水天上来》全诗148 字，牙音最多，总计 43 字。\n* 如果你想表达阴郁沉重，沉默不语，可以多用唇音。比如 m 声母的字，如盲昧茂密冥蒙渺茫梦霾暮灭，都与黑暗的意思相关，发音时口唇先须闭合，给人郁卒之感。`;

    case "word-glyph":
      return `### 词数\n\n不同长度的词数，包括：一字词，二字词，三字词，四字词和多字词。\n\n* **一字词**：如「你」、「我」、「他」、「日」、「月」；\n* **二字词**：如「妈妈」、「苹果」、「香蕉」、「太阳」、「月亮」；\n* **三字词**：如「一会儿」、「自来水」、「数不清」、「肯德基」；\n* **四字词**：如「坐井观天」、「大吃大喝」、「可口可乐」、「奥林匹克」；\n* **多字词**：如「贵人多忘事」、「百思不得其解 」、「英雄所见略同」。`;

    case "word-meaning":
      return `### 词性\n\n文本中，不同词性的数量。包括：\n\n* **实词**：名词，动词，形容词，代词等；\n* **虚词**：副词，介词，连词等。`;

    case "word-difficulty":
      return `### 字词难度\n\n文本中的字词的平均难度，分为**平均字难度**与**平均词难度**。分数范围皆为「0-100 」，每 20 分为一档，总计五档，用不同颜色区分。\n\n* **平均字难度**：依据文本推测所得，得分越高，说明作者文本中的字难度越大。张爱玲的《天才梦》平均字难度是 19，第一档；鲁迅的《论雷峰塔的倒掉》平均字难度是 19，第一档。表示两位作家文中使用的字是最容易识别的，没什么阅读难度，以小学生学习过的字为主。\n* **平均词难度**：依据文本推测所得，得分越高，说明作者文本中的词难度越大。张爱玲的《天才梦》平均词难度是 50，第三档；鲁迅的《论雷峰塔的倒掉》平均词难度是 47，第三档。表示两位作家文中使用的词略有阅读难度，需要初中以上文化。`;

    case "syntax-length":
      return `### 句长\n\n文本中，句子的长度，包括：\n\n* **正常**：句子长度在正常范围以内。\n* **较长**：句子长度超过了正常范围，需要酌情精简。\n* **较短**：句子长度低于正常范围，一般用于特殊场景，比如表感叹、表抒情；或特殊文体，比如诗歌。`;

    case "syntax-form":
      return `### 句型\n\n文本中，不同句子类型的数量，包括：\n\n* **单句**：由短语或单个的词构成的句子，有特定的语调，能独自表达一定的意思的语言单位，不可再分析出分句的句子。比如「飞机起飞了。」是一个单句。\n* **复句**：由两个或两个以上，相当于单句的分段构成，通过逗号，分号或冒号连接在一起。比如「天地呵，那万物逆旅；光阴呵，那百代过客。」是一个复句。`;

    case "syntax-pattern":
      return `### 句类\n\n汉语可以分为四大类：\n\n* **感叹句**：带有浓厚的感情的句子。它表示快乐、惊讶、悲哀、厌恶、恐惧等浓厚的感情。感叹句一般用降调，句末用感叹号表示。\n* **疑问句**：提出问题、有表示疑问语调的句子，句末用问号表示。\n* **祈使句**：表示要对方做或不做某事、带有祈使语气的句子。句末一般用感叹号，但是有些祈使句的语气较弱，可以用句号结尾。\n* **陈述句**：用来描述一个事实。日常生活中使用的大部分句子都是陈述句。`;

    case "syntax-meaning":
      return `### 句义\n\n根据句子语义方向，分为：\n\n* **肯定句**：指的是对事物作出肯定判断的句子。\n* **否定句**：表示否定的句子。`;

    case "passage-word_cloud":
      return `### 词云\n\n又称文字云，依据句子在文本中出现的频率计算。越大的节点，表示在文本中出现次数越多。`;

    case "passage-difficulty":
      return `### 篇章难易度\n\n**篇章难易度**是以段落为单位，计算而成：分数范围皆为「0-100 」，表示文本阅读难度。篇章难易度在 0-20 分，小学文化即可阅读；在 20-40 分，初中文化即可阅读；在40-60 分，高中文化即可阅读；60-80 分，需大学文化；80 分以上，需专业知识。\n\n在上述指标基础之上，对整篇文本统计得出**整体篇章难易度**，表示整篇文本的阅读难易度。`;

    case "passage-char_word_sent":
      return `### 字词句\n\n以段落为单位，计算而成：\n\n* **段落字数**： 一个段落中的所有字数。\n* **段落词数**：一个段落中的所有词数。\n* **段落句数**：一个段落中的所有句数。\n\n在上述指标基础之上，对文本所有段落统计得出：\n\n* **段落平均词长**：文本中，段落词长的平均数。\n* **段落平均句长**：文本中，段落句长的平均数。`;
  }
};
