import * as React from "react";
import "./styles.css";

//引入的库组件
import { Switch, Route, Redirect } from "react-router";

//引入的其他页面组件
import MainContainer from "../Router_MainContainer/index";
import CorpusProjectContainer from "./corpusProjectContainer";
import CorpusCardsContainer from "./corpusCardsContainer";
import GlobalSearchModal from "../GlobalSearchModal/index";

//graphql查询

//引入的资源图片

const Corpus = (props) => {
  const { setIsLogin, history } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [headerContent, setHeaderContent] = React.useState(null);
  const [globalSearchString, setGlobalSearchString] = React.useState("");
  const [showGlobalSearchModal, setShowGlobalSearchModal] = React.useState({
    isShow: false,
    isSearch: false,
  });

  const openGlobalSearch = (string) => {
    setGlobalSearchString(string);
    setShowGlobalSearchModal({isSearch: true, isShow: true});
  };

  const openGlobalSearchRef = React.useRef(openGlobalSearch);
  openGlobalSearchRef.current = openGlobalSearch;

  const renderBodyContent = () => {
    return (
      <Switch>
        <Route
          path="/corpus/project"
          component={(props) => {
            return (
              <CorpusProjectContainer
                {...props}
                setHeaderContent={setHeaderContent}
                openGlobalSearchRef={openGlobalSearchRef}
              />
            );
          }}
        />
        <Route
          path="/corpus/cards"
          component={(props) => {
            return (
              <CorpusCardsContainer
                {...props}
                setHeaderContent={setHeaderContent}
                openGlobalSearchRef={openGlobalSearchRef}
              />
            );
          }}
        />
        <Route
          path="/corpus"
          render={() => {
            return <Redirect to="/corpus/project" />;
          }}
        />
      </Switch>
    );
  };

  const renderHeaderContent = (content) => {
    return headerContent;
  };

  const bodyContent = React.useMemo(() => {
    return renderBodyContent();
  }, []);

  return (
    <>
      <MainContainer
        currentSiderIndex={2}
        setIsLogin={setIsLogin}
        isBodyLoading={isLoading}
        bodyContent={bodyContent}
        headerContent={renderHeaderContent()}
      />
      <GlobalSearchModal
        showModal={showGlobalSearchModal}
        showAddToProject={false}
        addToProjectCallback={null}
        globalSearchString={globalSearchString}
        handleOnCancel={() => {
          setShowGlobalSearchModal({ isShow: false, isSearch: false });
        }}
      />
    </>
  );
};

export default Corpus;
