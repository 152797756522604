import { Chart, registerShape } from "@antv/g2";

export const WatchChart = (
  chartData,
  id,
  color = null,
  textStyle = null,
  countStyle = null
) => {
  registerShape("point", "pointer", {
    draw(cfg, group) {
      const point = cfg.points[0];
      const center = this.parsePoint({ x: 0, y: 0 });
      const target = this.parsePoint({ x: point.x, y: 0.9 });
      const dir_vec = { x: center.x - target.x, y: center.y - target.y };
      // normalize
      const length = Math.sqrt(dir_vec.x * dir_vec.x + dir_vec.y * dir_vec.y);
      dir_vec.x *= 1 / length;
      dir_vec.y *= 1 / length;
      // rotate dir_vector by -90 and scale
      const angle1 = -Math.PI / 2;
      const x_1 = Math.cos(angle1) * dir_vec.x - Math.sin(angle1) * dir_vec.y;
      const y_1 = Math.sin(angle1) * dir_vec.x + Math.cos(angle1) * dir_vec.y;
      // rotate dir_vector by 90 and scale
      const angle2 = Math.PI / 2;
      const x_2 = Math.cos(angle2) * dir_vec.x - Math.sin(angle2) * dir_vec.y;
      const y_2 = Math.sin(angle2) * dir_vec.x + Math.cos(angle2) * dir_vec.y;
      // polygon vertex
      const path = [
        ["M", target.x + x_1 * 1, target.y + y_1 * 1],
        ["L", center.x + x_1 * 3, center.y + y_1 * 3],
        ["L", center.x + x_2 * 3, center.y + y_2 * 3],
        ["L", target.x + x_2 * 1, target.y + y_2 * 1],
        ["Z"],
      ];
      const tick = group.addShape("path", {
        attrs: {
          path,
          fill: cfg.color,
        },
      });
      return tick;
    },
  });

  const chart = new Chart({
    container: id,
    autoFit: true,
  });

  chart.data(chartData);
  chart.coordinate("polar", {
    startAngle: (-10 / 8) * Math.PI,
    endAngle: (2 / 8) * Math.PI,
    radius: 0.5,
  });
  chart.scale("value", {
    min: 0,
    max: 1,
    tickInterval: 1,
  });
  chart.axis(false);
  chart.facet("rect", {
    fields: ["type"],
    showTitle: false,
    eachView: function eachView(view, facet) {
      const data = facet.data[0];
      // 指针
      view
        .point()
        .position("value*1")
        .shape("pointer")
        .color("#d8d8d8")
        .animate({
          appear: {
            animation: "fade-in",
          },
        });
      // 仪表盘背景
      view.annotation().arc({
        top: false,
        start: [0, 1],
        end: [1, 1],
        style: {
          stroke: "#ebedf0",
          lineWidth: 8,
        },
      });
      // 仪表盘前景
      view.annotation().arc({
        start: [0, 1],
        end: [data.value, 1],
        style: {
          stroke: color ? color : "#1890ff",
          lineWidth: 8,
        },
      });
      // 仪表盘信息
      const percent = parseInt(data.value * 100, 10);

      view.annotation().text({
        position:
          textStyle && textStyle.position ? textStyle.position : ["50%", "72%"],
        content: data.type,
        style:
          textStyle && textStyle.style
            ? textStyle.style
            : {
                fontSize: 12,
                fill: "#36363F",
                fontWeight: 400,
                letterSpacing: 0,
                textAlign: "center",
              },
      });
      view.annotation().text({
        position:
          countStyle && countStyle.position
            ? countStyle.position
            : ["50%", "82%"],
        content: `${percent}`,
        style:
          countStyle && countStyle.style
            ? countStyle.style
            : {
                fontSize: 26,
                fill: "#36363F",
                fontWeight: 500,
                letterSpacing: 0,
                textAlign: "center",
              },
      });
    },
  });
  chart.render();
};
