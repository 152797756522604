import * as React from "react";
import "./styles.css";

//引入的库组件
import { Spin, Tooltip, message } from "antd";
import ShortUUID from "short-uuid";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor, Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/i18n/zh-cn";
import "../aw-check.css";

import "tui-color-picker/dist/tui-color-picker.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";

import { uuid, regex } from "uuidv4";

//引入的其他页面组件
import timeLocalStorage from "../../../utils/timelocalstorage";
import configs from "../../../configs";
import headers from "../../../GlobalData/headers";
import KeyMap from "../../../i18n/keymap";
import keymap from "../../../i18n/keymap";
import { html2Escape } from "../utils/main";
import MakeSpecialMark from "./makeSpecialMark";
import { copyObject } from "../utils";

//七牛云，用作图床
const TOKENURL = configs.qiniuUri;
const UPLOADURL = "https://up-z1.qiniup.com";

//自动保存的间隔
const AUTO_SAVE_TIME = 2000;

const toolbarItems = [
  "heading",
  "bold",
  "italic",
  "strike",
  "divider",
  "hr",
  "quote",
  "divider",
  "ul",
  "ol",
  "task",
  "indent",
  "outdent",
  "divider",
  "table",
  "image",
  "link",
  "divider",
  "code",
  "codeblock",
  "divider",
];

const escape2html = (str) => {
  var arrEntities = { "<": "&lt;", ">": "&gt;" };
  return str.replace(/>|</g, function(all, t) {
    return arrEntities[all];
  });
};

const checkSpecialChar = (e) => {
  if (/(\&gt;|\&lt;)/g.test(e)) {
    //把匹配到的特殊字符替换成'',再加上原来的字符
    e = e.replace(/(\&gt;|\&lt;)/g, (str, index) => {
      return str.match(/(\&gt;|\&lt;)/g)[0];
    });
  } else if (
    /(\<|\>|\,|\/|\.|\$|\+|\-|\&|\||\!|\(|\)|\{|\}|\[|\]|\^|\”|\~|\*|\?|\:|\\)/g.test(
      e
    )
  ) {
    //把匹配到的特殊字符替换成'',再加上原来的字符
    e = e.replace(
      /(\<|\>|\,|\/|\.|\$|\+|\-|\&|\||\!|\(|\)|\{|\}|\[|\]|\^|\”|\~|\*|\?|\:|\\)/g,
      (str, index) => {
        return (
          "\\" +
          str.match(
            /(\<|\>|\,|\/|\.|\$|\+|\-|\&|\||\!|\(|\)|\{|\}|\[|\]|\^|\”|\~|\*|\?|\:|\\)/g
          )[0]
        );
      }
    );
  }
  return e;
};

const TuiEditorComponent = (props) => {
  const {
    data,
    isShowMarkdown,
    modifyDatas,
    setModifyDatas,
    titleSelectedIndex,
    saveCallback,
    feedbackData,
    feedbackType,
    onFocusCallback,
    onClickCallback,
    onHoverCallback,
    isWysiwygMode,
    isHighlightAndMarkMode,
    setIsHighlightAndMarkMode,
    highlightWords,
    findAndReplaceDatas,
    setFindAndReplaceDatas,
    isFullScreen,
    changeOutlineName,
    editorReset,
    setEditorReset,
  } = props;

  const [highlightInfo, setHighlightInfo] = React.useState(null);
  const [highlightRenderLine, setHighlightRenderLine] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isShortcutInit, setIsShortcutInit] = React.useState(false);
  const [isInEditor, setIsInEditor] = React.useState(false);
  const [isContentChange, setIsContentChange] = React.useState(false);
  const [content, setContent] = React.useState(
    data.data.type === "title" ? `# ${data.data.text}` : data.data.text
    // html2Escape(
    //   data.data.type === "title" ? `# ${data.data.text}` : data.data.text
    // )
  ); //编辑器原始文本内容
  const [contentForHighlight, setContentForHighLight] = React.useState(
    data.data.text
  ); //高亮文本
  const [isInit, setIsInit] = React.useState(false);
  const [isShowFeedBackData, setIsShowFeedBackData] = React.useState(false);
  const [scrollTop, setScrollTop] = React.useState(0);

  const editorRef = React.useRef();
  const viewerRef = React.useRef();

  const autoSaveCallback = () => {
    if (isContentChange) {
      setIsContentChange(false);
      saveData(content, false);
    }
  };
  const autoSaveCallbackRef = React.useRef(autoSaveCallback);
  autoSaveCallbackRef.current = autoSaveCallback;

  React.useEffect(() => {
    setIsInit(true);
    let time = setInterval(() => {
      autoSaveCallbackRef.current();
    }, AUTO_SAVE_TIME);
    if (!editorReset) {
      initToolbarTip();
      setIsLoading(true);
      setEditorReset(true);
    } else {
      setIsShortcutInit(true);
    }
    window.onblur = (e) => {
      setIsInEditor(false);
      if (editorRef.current) {
        editorRef.current.getInstance().blur();
      }
    };
    return () => {
      clearInterval(time);
    };
  }, []);

  React.useEffect(() => {
    if (!isInEditor) {
      if (data.data.text !== content) {
        setContent(data.data.text);
        if (!isHighlightAndMarkMode) {
          setEditorValue(data.data.text);
        }
      }
    }
  }, [data.data.text]);

  React.useEffect(() => {
    if (changeOutlineName.id === data.data.id && data.data.type === "title") {
      if (viewerRef.current) {
        viewerRef.current
          .getInstance()
          .setMarkdown(changeOutlineName.title, false);
      }
      if (editorRef.current) {
        editorRef.current
          .getInstance()
          .setMarkdown(changeOutlineName.title, false);
      }
    }
  }, [changeOutlineName]);

  React.useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isLoading]);

  React.useEffect(() => {
    customShortcuts();
  }, [isInEditor]);

  React.useEffect(() => {
    if (findAndReplaceDatas.length) {
      let contentTemp = content;
      const cardId = data.data.id;
      let dataIndex = -1;
      findAndReplaceDatas.forEach((item, index) => {
        if (item.cardId === cardId && data.data.type === "content") {
          dataIndex = index;
          let startIndex = item.start;
          let endIndex = item.end;
          contentTemp =
            contentTemp.substring(0, startIndex) +
            contentTemp.substring(endIndex, contentTemp.length);
          contentTemp = insertStr(contentTemp, startIndex, item.replace);
        }
      });
      findAndReplaceDatas.splice(dataIndex, 1);
      setFindAndReplaceDatas(findAndReplaceDatas);
      setContent(contentTemp);
      saveData(contentTemp, false);
    }
  }, [findAndReplaceDatas]);

  React.useEffect(() => {
    if (modifyDatas.length) {
      if (data.data.type !== "content") {
        return;
      }
      let contentTemp = content;
      const cardId = data.data.id;
      let notModifyDatas = [];
      let isModifyed = false;
      modifyDatas.forEach((item, index) => {
        if (item.cardId === cardId) {
          if (item.advice !== "") {
            let startIndex = item.start;
            let endIndex = item.end;
            contentTemp =
              contentTemp.substring(0, startIndex) +
              contentTemp.substring(endIndex, contentTemp.length);
            contentTemp = insertStr(contentTemp, startIndex, item.advice);
          }
          isModifyed = true;
        } else {
          notModifyDatas.push(item);
        }
      });
      if (isModifyed) {
        setModifyDatas(notModifyDatas);
      }
      setContent(contentTemp);
      saveData(contentTemp, false);
    }
  }, [modifyDatas]);

  React.useEffect(() => {
    if (isInit) {
      if (data.data.type !== "content" || !isHighlightAndMarkMode) {
        return;
      }
      const cardId = data.data.id;
      if (feedbackData.length) {
        let feedbackDataTemp = feedbackData.filter((item) => {
          return item.cardId === cardId;
        });
        let contentTemp = content;
        feedbackDataTemp.sort((a, b) => {
          return b.start - a.start;
        });
        if (feedbackDataTemp.length) {
          initFeedbackHighlightInfo(feedbackDataTemp);
        }
        // feedbackDataTemp.forEach((item, index) => {
        //   if (item.cardId === cardId) {
        //     let startIndex = item.start;
        //     let endIndex = item.end;
        //     let highLightText = `<span id="${item.mId}" class="${makeSpanClass(
        //       feedbackDataTemp.length - 1 - index
        //     )}">${item.wrong}</span>`;
        //     contentTemp =
        //       contentTemp.substring(0, startIndex) +
        //       contentTemp.substring(endIndex, contentTemp.length);
        //     contentTemp = insertStr(contentTemp, startIndex, highLightText);
        //     setEditorValue(contentTemp);
        //   }
        // });
      }
    }
  }, [feedbackData]);

  React.useEffect(() => {
    if (isInit) {
      resetSpecialDom();
      setTimeout(() => {
        if (data.data.type === "content" || !isHighlightAndMarkMode) {
          if (highlightWords.length) {
            highlightWords.map((item, index) => {
              if (item.type === "search") {
                initSearchHighlightInfo(item, index);
              } else if (item.type === "find-replace") {
                initFindAndReplaceHighlightInfo(item, index);
              } else if (
                item.type === "mark" ||
                item.type === "mark-word-cloud" ||
                item.type === "mark-word-meta" ||
                item.type === "mark-word-replacement"
              ) {
                if (item.cardId === data.data.id) {
                  initMarkHighlightInfo(item, index);
                }
              } else {
                removeHightlight();
              }
            });
          } else {
            removeHightlight();
          }
        }
      });
    }
  }, [highlightWords]);

  const resetSpecialDom = () => {
    setEditorValue(content);
    // if (highlightRenderLine.length) {
    //   let codeMirror = editorRef.current.getInstance().getCodeMirror();
    //   let currentViews = codeMirror.display.view;
    //   let viewStartIndex = codeMirror.display.viewFrom;
    //   let viewEndIndex = codeMirror.display.viewTo;

    //   currentViews.forEach((view, viewIndex) => {
    //     let realIndex = viewIndex + viewStartIndex;
    //     highlightRenderLine.forEach((renderLine, index) => {
    //       if (realIndex === renderLine.realIndex) {
    //         let element = view.node;
    //         let text = element.innerText;
    //         element.innerHTML = renderLine.innerHTML;
    //         element.innerText = text;
    //       }
    //     });
    //   });
    // }
    setHighlightRenderLine([]);
    setHighlightInfo(null);
  };

  const makeSpecialDom = (info, isInit = false) => {
    let codeMirror = editorRef.current.getInstance().getCodeMirror();
    let currentHighlightInfo = info || highlightInfo;

    if (currentHighlightInfo) {
      if (currentHighlightInfo.type === "search") {
      } else if (currentHighlightInfo.type === "find-replace") {
        makeFindAndReplaceHightlightRef.current(
          currentHighlightInfo,
          codeMirror,
          isInit
        );
      } else if (currentHighlightInfo.type === "mark-feedback") {
        makeFeedbackHighlightRef.current(
          currentHighlightInfo,
          codeMirror,
          isInit
        );
      } else if (
        currentHighlightInfo.type === "mark" ||
        currentHighlightInfo.type === "mark-word-cloud" ||
        currentHighlightInfo.type === "mark-word-meta" ||
        currentHighlightInfo.type === "mark-word-replacement"
      ) {
        makeMarkHighlightRef.current(currentHighlightInfo, codeMirror, isInit);
      }
    }
  };

  const makeSpecialDomRef = React.useRef(makeSpecialDom);
  makeSpecialDomRef.current = makeSpecialDom;

  const getCodeMirrorDocs = (codeMirror) => {
    let docs = [];
    const getDocs = (children) => {
      for (let i = 0; i < children.length; i++) {
        let childrenItem = children[i];
        if (childrenItem.children && childrenItem.children.length) {
          getDocs(childrenItem.children);
        } else {
          if (childrenItem.lines && childrenItem.lines.length) {
            docs = docs.concat(childrenItem.lines);
          }
        }
      }
    };
    getDocs(codeMirror.doc.children);
    return docs;
  };

  const initFeedbackHighlightInfo = (data) => {
    let codeMirror = editorRef.current.getInstance().getCodeMirror();
    let highlightInfo = makeFeedbackHighlightInfo(data, codeMirror);
    setHighlightInfo(highlightInfo);
    makeSpecialDomRef.current(highlightInfo, true);
  };

  const makeFeedbackHighlightInfo = (data, codeMirror) => {
    let info = {
      type: "mark-feedback",
    };
    let docs = getCodeMirrorDocs(codeMirror);
    let contentLength = 0;

    for (let i = 0; i < docs.length; i++) {
      let line = docs[i];
      let start = contentLength;
      contentLength = start + line.text.length;
      if (i < docs.length - 1) {
        contentLength++;
      }
      data.forEach((dataItem, index) => {
        if (dataItem.start >= start && dataItem.start < contentLength) {
          dataItem.displayIndex = i;
          dataItem.newStart = dataItem.start - start;
          dataItem.newEnd = dataItem.newStart + (dataItem.end - dataItem.start);
        }
      });
    }

    return { ...info, wordsInfo: data };
  };

  const makeFeedbackHighlight = (info, codeMirror, isInit = false) => {
    let currentViews = codeMirror.display.view;
    let viewStartIndex = codeMirror.display.viewFrom;
    let viewEndIndex = codeMirror.display.viewTo;
    let wordsInfo = info.wordsInfo;

    let changeViewLine = [];
    if (!isInit) {
      changeViewLine = highlightRenderLine.filter((item) => {
        return (
          item.realIndex >= viewStartIndex && item.realIndex < viewEndIndex
        );
      });
    }

    currentViews.forEach((view, viewIndex) => {
      let realIndex = viewIndex + viewStartIndex;
      let isRendered = false;
      changeViewLine.forEach((item) => {
        if (item.realIndex === realIndex) {
          isRendered = true;
        }
      });
      if (!isRendered) {
        let datas = [];
        wordsInfo.forEach((wordItem, wordIndex) => {
          if (wordItem.displayIndex === realIndex) {
            datas.push(wordItem);
          }
        });
        if (datas.length) {
          let element = view.node;
          let innerHTML = element.innerHTML;
          element.innerHTML = makeFeedbackHightlightString(datas, innerHTML);
          changeViewLine.push({ realIndex, innerHTML });
        }
      }
    });
    setHighlightRenderLine(changeViewLine);
  };

  const makeFeedbackHighlightRef = React.useRef(makeFeedbackHighlight);
  makeFeedbackHighlightRef.current = makeFeedbackHighlight;

  const makeFeedbackHightlightString = (datas, innerHTML) => {
    datas.sort((a, b) => {
      return b.newEnd - a.newEnd;
    });
    let innerHTMLContentPos = {};
    let isRealContent = false;
    let realContentIndex = 0;
    let innerHTMLIndex = 0;
    let isSpan = false;
    let isEscape = false;
    for (let htmlChar of innerHTML) {
      if (htmlChar === "&" && isRealContent) {
        isEscape = true;
        innerHTMLContentPos[realContentIndex] = innerHTMLIndex;
        realContentIndex++;
      } else if (htmlChar === ";" && isEscape) {
        isEscape = false;
      } else if (isEscape) {
      } else if (htmlChar !== "<" && htmlChar !== ">" && isRealContent) {
        innerHTMLContentPos[realContentIndex] = innerHTMLIndex;
        realContentIndex++;
      } else if (htmlChar === "<") {
        isSpan = true;
        isRealContent = false;
      } else if (htmlChar === ">") {
        isSpan = false;
        isRealContent = false;
      } else if (htmlChar !== "<" && htmlChar !== ">" && !isSpan) {
        isRealContent = true;
        innerHTMLContentPos[realContentIndex] = innerHTMLIndex;
        realContentIndex++;
      } else {
        isRealContent = false;
      }
      innerHTMLIndex++;
    }

    datas.map((posInfo, posInfoIndex) => {
      let startIndex = innerHTMLContentPos[posInfo.newStart];
      let endIndex = innerHTMLContentPos[posInfo.newEnd];
      let highLightTextStart = `<span id="${
        posInfo.mId
      }" class="${makeSpanClass(datas.length - 1 - posInfoIndex)}">`;
      let highLightTextEnd = "</span>";
      innerHTML = insertStr(innerHTML, endIndex, highLightTextEnd);
      innerHTML = insertStr(innerHTML, startIndex, highLightTextStart);
    });
    return innerHTML;
  };

  const initSearchHighlightInfo = (item, index) => {
    console.log("initSearchHighlightInfo================");
    console.log(item);
    console.log("initSearchHighlightInfo================");
  };

  const initFindAndReplaceHighlightInfo = (item, index) => {
    let codeMirror = editorRef.current.getInstance().getCodeMirror();
    let highlightInfo = makeFindAndReplaceHightlightInfo(
      item,
      index,
      codeMirror
    );
    setHighlightInfo(highlightInfo);
    makeSpecialDomRef.current(highlightInfo, true);
  };

  const makeFindAndReplaceHightlightInfo = (item, index, codeMirror) => {
    let docs = getCodeMirrorDocs(codeMirror);
    let contentLength = 0;
    let count = 0;
    let ids = [];
    const CHECK_STRING = new RegExp(checkSpecialChar(item.word), "g");

    for (let i = 0; i < docs.length; i++) {
      let line = docs[i];
      let start = contentLength;
      contentLength = start + line.text.length;
      if (i < docs.length - 1) {
        contentLength++;
      }
      let text = line.text;
      text = text.replace(CHECK_STRING, (str, index) => {
        let id = `find-and-replace-${data.data.id}-${count++}`;
        ids.push({
          id: id,
          start: start + index,
          end: start + index + str.length,
          cardId: data.data.id,
          displayIndex: i,
          targetWord: item.word,
        });
        return str;
      });
    }
    return { ...item, count, ids };
  };

  const makeFindAndReplaceHightlight = (info, codeMirror, isInit = false) => {
    let currentViews = codeMirror.display.view;
    let viewStartIndex = codeMirror.display.viewFrom;
    let viewEndIndex = codeMirror.display.viewTo;

    let changeViewLine = [];
    if (!isInit) {
      changeViewLine = highlightRenderLine.filter((item) => {
        return (
          item.realIndex >= viewStartIndex && item.realIndex < viewEndIndex
        );
      });
    }

    const CHECK_STRING = new RegExp(
      checkSpecialChar(escape2html(info.word)),
      "g"
    );
    currentViews.forEach((view, viewIndex) => {
      let realIndex = viewIndex + viewStartIndex;
      let isRendered = false;
      changeViewLine.forEach((item) => {
        if (item.realIndex === realIndex) {
          isRendered = true;
        }
      });
      if (!isRendered) {
        let element = view.node;
        let innerText = escape2html(view.line.text);
        let wordIndex = 0;

        let ids = info.ids.filter((item) => {
          return item.displayIndex === realIndex;
        });

        innerText = innerText.replace(CHECK_STRING, (str, strIndex) => {
          const getInfos = (wordIndex) => {
            let wordInfo = null;
            ids.forEach((infoItem, infoItemIndex) => {
              if (wordIndex === infoItemIndex) {
                wordInfo = infoItem;
              }
            });
            return wordInfo;
          };
          let wordInfo = getInfos(wordIndex++);
          let id = wordInfo.id;
          return `<span class=${info.className} id="${id}">${str}</span>`;
        });

        element.innerHTML = `<span role="presentation" style="padding-right: 0.1px;">${innerText}</span>`;
        // element.innerText = view.line.text;
        changeViewLine.push({ realIndex, innerText });
      }
    });
    if (info.callback && info.count > 0 && info.ids.length) {
      info.callback(info.count, info.ids);
    }
    setHighlightRenderLine(changeViewLine);
  };

  const makeFindAndReplaceHightlightRef = React.useRef(
    makeFindAndReplaceHightlight
  );
  makeFindAndReplaceHightlightRef.current = makeFindAndReplaceHightlight;

  const initMarkHighlightInfo = (item, index) => {
    let codeMirror = editorRef.current.getInstance().getCodeMirror();
    let highlightInfo = makeMarkHighlightInfo(item, index, codeMirror);
    setHighlightInfo(highlightInfo);
    makeSpecialDomRef.current(highlightInfo, true);
  };

  const makeMarkHighlightInfo = (item, index, codeMirror) => {
    let wordsInfo = {};
    item.wordInfo.map((wordInfoItem) => {
      wordsInfo[wordInfoItem.start] = wordInfoItem;
      wordsInfo[wordInfoItem.start].type = item.type;
      wordsInfo[wordInfoItem.start].cardId = item.cardId;
    });

    let docs = getCodeMirrorDocs(codeMirror);
    let contentLength = 0;
    for (let i = 0; i < docs.length; i++) {
      let line = docs[i];
      let start = contentLength;
      contentLength = start + line.text.length;
      if (i < docs.length - 1) {
        contentLength++;
      }
      Object.keys(wordsInfo).forEach((key) => {
        if (key >= start && key < contentLength) {
          wordsInfo[key].displayIndex = i;
          wordsInfo[key].newStart = key - start;
          wordsInfo[key].newEnd =
            wordsInfo[key].newStart +
            (wordsInfo[key].end - wordsInfo[key].start);
        }
      });
    }

    return { ...item, wordInfo: wordsInfo };
  };

  const makeMarkHighlight = (info, codeMirror, isInit = false) => {
    let currentViews = codeMirror.display.view;
    let viewStartIndex = codeMirror.display.viewFrom;
    let viewEndIndex = codeMirror.display.viewTo;
    let wordInfo = info.wordInfo;

    let changeViewLine = [];
    if (!isInit) {
      changeViewLine = highlightRenderLine.filter((item) => {
        return (
          item.realIndex >= viewStartIndex && item.realIndex < viewEndIndex
        );
      });
    }

    currentViews.forEach((view, viewIndex) => {
      let realIndex = viewIndex + viewStartIndex;
      let isRendered = false;
      changeViewLine.forEach((item) => {
        if (item.realIndex === realIndex) {
          isRendered = true;
        }
      });
      if (!isRendered) {
        let datas = [];
        Object.keys(wordInfo).forEach((key, keyIndex) => {
          let info = wordInfo[key];
          if (info.displayIndex === realIndex) {
            datas.push(info);
          }
        });
        if (datas.length) {
          let element = view.node;
          let innerHTML = element.innerHTML;
          element.innerHTML = makeMarkHightlightString(datas, innerHTML);
          changeViewLine.push({ realIndex, innerHTML });
        }
      }
    });
    setHighlightRenderLine(changeViewLine);
  };

  const makeMarkHighlightRef = React.useRef(makeMarkHighlight);
  makeMarkHighlightRef.current = makeMarkHighlight;

  const makeMarkHightlightString = (datas, innerHTML) => {
    datas.sort((a, b) => {
      return b.newEnd - a.newEnd;
    });
    let innerHTMLContentPos = {};
    let isRealContent = false;
    let realContentIndex = 0;
    let innerHTMLIndex = 0;
    let isSpan = false;
    let isEscape = false;
    for (let htmlChar of innerHTML) {
      if (htmlChar === "&" && isRealContent) {
        isEscape = true;
        innerHTMLContentPos[realContentIndex] = innerHTMLIndex;
        realContentIndex++;
      } else if (htmlChar === ";" && isEscape) {
        isEscape = false;
      } else if (isEscape) {
      } else if (htmlChar !== "<" && htmlChar !== ">" && isRealContent) {
        innerHTMLContentPos[realContentIndex] = innerHTMLIndex;
        realContentIndex++;
      } else if (htmlChar === "<") {
        isSpan = true;
        isRealContent = false;
      } else if (htmlChar === ">") {
        isSpan = false;
        isRealContent = false;
      } else if (htmlChar !== "<" && htmlChar !== ">" && !isSpan) {
        isRealContent = true;
        innerHTMLContentPos[realContentIndex] = innerHTMLIndex;
        realContentIndex++;
      } else {
        isRealContent = false;
      }
      innerHTMLIndex++;
    }

    datas.map((posInfo, posInfoIndex) => {
      let startIndex = innerHTMLContentPos[posInfo.newStart];
      let endIndex = innerHTMLContentPos[posInfo.newEnd];
      let highLightTextStart = `<span class="${posInfo.className}" ${
        posInfo.id !== "" ? "id=" + posInfo.id : ""
      }>`;
      let highLightTextEnd = "</span>";
      innerHTML = insertStr(innerHTML, endIndex, highLightTextEnd);
      innerHTML = insertStr(innerHTML, startIndex, highLightTextStart);
    });
    return innerHTML;
  };

  const makeSearchHighlight = (item, index) => {
    let value = content;
    const SEARCH_TAG = new RegExp(item.word, "g");
    const REPLACE_STRING = `<span class=${item.className} >${item.word}</span>`;
    value = value.replace();
    value = value.replace(SEARCH_TAG, REPLACE_STRING);
    setEditorValue(value);
  };

  const makeSearchHighlightString = (info, string) => {
    const SEARCH_TAG = new RegExp(info.word, "g");
    const REPLACE_STRING = `<span class=${info.className} >${info.word}</span>`;
    string = string.replace();
    string = string.replace(SEARCH_TAG, REPLACE_STRING);
    return string;
  };

  const makeFindAndReplaceHightlightString = (info, string, index) => {
    let count = 0;
    let ids = [];
    const CHECK_STRING = new RegExp(info.word, "g");
    string = string.replace(CHECK_STRING, (str, index) => {
      let id = `find-and-replace-${data.data.id}-${count++}`;
      ids.push({
        id: id,
        start: index,
        end: index + str.length,
        cardId: data.data.id,
      });
      return `<span class=${info.className} id="${id}">${info.word}</span>`;
    });
    if (info.callback && count > 0 && ids.length) {
      info.callback(count, ids);
    }
    return string;
  };

  const initToolbarTip = () => {
    if (editorRef.current) {
      let options = editorRef.current.getInstance().i18n._langs.get("zh-CN");
      if (options["Bold"].indexOf(KeyMap.EDITOR_BOLD_KEY) === -1) {
        options["Bold"] =
          options["Bold"] + `<br>${KeyMap.EDITOR_BOLD_KEY}</br>`;
      }
      if (options["Italic"].indexOf(KeyMap.EDITOR_ITALIC_KEY) === -1) {
        options["Italic"] =
          options["Italic"] + `<br>${KeyMap.EDITOR_ITALIC_KEY}</br>`;
      }
      if (options["Strike"].indexOf(KeyMap.EDITOR_STRIKE_KEY) === -1) {
        options["Strike"] =
          options["Strike"] + `<br>${KeyMap.EDITOR_STRIKE_KEY}</br>`;
      }
      if (options["Line"].indexOf(KeyMap.EDITOR_LINE_KEY) === -1) {
        options["Line"] =
          options["Line"] + `<br>${KeyMap.EDITOR_LINE_KEY}</br>`;
      }
      if (options["Blockquote"].indexOf(KeyMap.EDITOR_BLOCKQUOTE_KEY) === -1) {
        options["Blockquote"] =
          options["Blockquote"] + `<br>${KeyMap.EDITOR_BLOCKQUOTE_KEY}</br>`;
      }
      if (
        options["Unordered list"].indexOf(KeyMap.EDITOR_UNORDERED_LIST_KEY) ===
        -1
      ) {
        options["Unordered list"] =
          options["Unordered list"] +
          `<br>${KeyMap.EDITOR_UNORDERED_LIST_KEY}</br>`;
      }
      if (
        options["Ordered list"].indexOf(KeyMap.EDITOR_ORDERED_LIST_KEY) === -1
      ) {
        options["Ordered list"] =
          options["Ordered list"] +
          `<br>${KeyMap.EDITOR_ORDERED_LIST_KEY}</br>`;
      }
      if (options["Task"].indexOf(KeyMap.EDITOR_TAST_KEY) === -1) {
        options["Task"] =
          options["Task"] + `<br>${KeyMap.EDITOR_TAST_KEY}</br>`;
      }
      editorRef.current.getInstance().i18n._langs.set("zh-CN", options);
      setIsShortcutInit(true);
    }
  };

  //chrome不支持 CTRL + NTW
  const customShortcuts = () => {
    document.getElementById(`tui-editor-${data.data.id}`).onkeydown = (e) => {
      if (!e || !isInEditor || !editorRef.current) return;
      if (KeyMap.EDITOR_BOLD(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Bold");
      }
      if (KeyMap.EDITOR_ITALIC(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Italic");
      }
      if (KeyMap.EDITOR_STRIKE(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Strike");
      }
      if (KeyMap.EDITOR_LINE(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("HR");
      }
      if (KeyMap.EDITOR_BLOCKQUOTE(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Blockquote");
      }
      if (KeyMap.EDITOR_UNORDERED_LIST(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("UL");
      }
      if (KeyMap.EDITOR_ORDERED_LIST(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("OL");
      }
      if (KeyMap.EDITOR_TAST(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Task");
      }
    };
  };

  const makeMarkHightlight = (item, index) => {
    let contentTemp = content;
    const cardId = data.data.id;

    if (item.cardId === cardId) {
      item.wordInfo.sort((a, b) => {
        return b.end - a.end;
      });
      item.wordInfo.map((posInfo, posInfoIndex) => {
        let startIndex = posInfo.start;
        let endIndex = posInfo.end;
        let highLightTextStart = `<span class="${posInfo.className}" ${
          posInfo.id !== "" ? "id=" + posInfo.id : ""
        }>`;
        let highLightTextEnd = "</span>";
        contentTemp = insertStr(contentTemp, endIndex, highLightTextEnd);
        contentTemp = insertStr(contentTemp, startIndex, highLightTextStart);
      });
      setEditorValue(contentTemp);
    }
  };

  const removeHightlight = () => {
    setHighlightInfo(null);
    setHighlightRenderLine([]);
    let value = content;
    const MD_START_TAG = /<span.*?>/g;
    const MD_END_TAG = /<\/span>/g;
    value = value.replace(MD_START_TAG, "").replace(MD_END_TAG, "");
    setEditorValue(value);
  };

  const setEditorValue = (value, isFocus = false) => {
    if (viewerRef.current) {
      viewerRef.current.getInstance().setMarkdown(value, false);
    }
    if (editorRef.current) {
      editorRef.current.getInstance().setMarkdown(value, false);
    }
    if (isHighlightAndMarkMode) {
      makeSpecialDomRef.current();
    }
  };

  const saveData = (value, isOnFocus = false) => {
    if (saveCallback) {
      let saveData = {
        blocks: [
          {
            data: {
              text: value,
              id: data.data.id,
              type: data.data.type,
            },
            key: data.key,
            type: "paragraph",
          },
        ],
      };
      saveCallback(saveData, isOnFocus);
    }
  };

  const insertStr = (source, start, insert) => {
    if (start === undefined) {
      start = source.length;
    }
    return source.slice(0, start) + insert + source.slice(start);
  };

  const makeSpanClass = (position) => {
    if (feedbackType === "check" || feedbackType === "style") {
      return "aw-error";
    }
  };

  const changeContent = (isBlur = false) => {
    let value = editorRef.current.getInstance().getMarkdown();
    // if (editorRef.current.getInstance().isWysiwygMode()) {
    //   const MD_START_TAG = /<span.*?>/g;
    //   const MD_END_TAG = /<\/span>/g;
    //   value = value.replace(MD_START_TAG, "").replace(MD_END_TAG, "");
    // }
    setContent(value);
    if (isBlur) {
      saveData(value, true);
    }
    setIsContentChange(true);
  };

  const addImage = async (file, callback, source) => {
    let userInfo = timeLocalStorage.get("USERINFO");
    let userId = window.atob(userInfo.id).split("User:")[1];
    let projectId = data.projectId;
    let imageId = uuid();
    let imagePath = window.btoa(
      "ImageInfo:" + userId + "/" + projectId + "/" + imageId
    );
    // let imagePath = window.btoa("ImageInfo:" + userId + "/" + projectId + "/" + imageId);

    let translator = ShortUUID();
    let short =
      translator.fromUUID(userId) +
      "/" +
      translator.fromUUID(projectId) +
      "/" +
      translator.fromUUID(imageId);

    try {
      const requestToken = await fetch(TOKENURL, {
        method: "GET",
        headers: headers,
      });
      const resultToken = await requestToken.text();
      const token = resultToken;
      const formData = new FormData();
      formData.append("token", token);
      formData.append("file", file);
      formData.append("key", short); //可以区分文件夹的方案
      const upload = await fetch(UPLOADURL, {
        method: "POST",
        body: formData,
      });
      const resultUpload = await upload.json();
      const src = `https://static.aiwriter.net/${resultUpload.key}`;

      if (callback) {
        callback(src, "");
      }
    } catch (error) {
      message.error("上传失败，请检查网络设置");
    }
  };

  const html2Escape = (str) => {
    var arrEntities = { lt: "<", gt: ">", nbsp: " ", amp: "&", quot: '"' };
    return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
      return arrEntities[t];
    });
  };

  const changeHandle = () => {
    if (isHighlightAndMarkMode) {
      makeSpecialDomRef.current();
    } else {
      changeContent();
    }
  };

  const changeHandleRef = React.useRef(changeHandle);
  changeHandleRef.current = changeHandle;

  const focusHandle = () => {
    setIsInEditor(true);
    if (isHighlightAndMarkMode) {
      setEditorValue(content, true);
      setIsHighlightAndMarkMode(false);
      if (editorRef.current) {
        editorRef.current.getInstance().blur();
      }
    }
  };

  const focusHandleRef = React.useRef(focusHandle);
  focusHandleRef.current = focusHandle;

  const blurHandle = () => {
    setIsInEditor(false);
    if (isHighlightAndMarkMode) {
      makeSpecialDomRef.current();
    } else {
      changeContent(true);
    }
  };

  const blurHandleRef = React.useRef(blurHandle);
  blurHandleRef.current = blurHandle;

  if (isLoading) {
    return null;
  }

  return (
    <div
      id={`tui-editor-${data.data.id}`}
      className={
        "tuieditor-main-container" +
        (titleSelectedIndex === 3
          ? " tuieditor-main-container-preview-all"
          : "")
      }
      style={isShortcutInit ? {} : { display: "none" }}
      onMouseOver={(e) => {
        if (isHighlightAndMarkMode && onHoverCallback) {
          onHoverCallback(e);
        }
      }}
      onClick={(e) => {
        if (isHighlightAndMarkMode && onClickCallback) {
          onClickCallback(e);
        }
      }}
      onFocusCapture={(e) => {}}
      onScroll={(e) => {
        setScrollTop(e.target.scrollTop);
        if (isHighlightAndMarkMode) {
          makeSpecialDomRef.current();
        }
      }}
    >
      {isShowMarkdown ? (
        <div
          className={`tuieditor-preview-container  ${
            titleSelectedIndex === 2
              ? ""
              : data.data.type === "title"
              ? "tuieditor-container-title"
              : "tuieditor-container-content"
          }`}
        >
          <Viewer
            ref={viewerRef}
            height="auto"
            hideModeSwitch={true}
            initialEditType="markdown" //"markdown" "wysiwyg"
            initialValue={content}
            previewHighlight={false}
            usageStatistics={false}
          />
        </div>
      ) : (
        <Editor
          key={`tuieditor-markdown-id-${data.data.type}-${data.data.id}`}
          ref={editorRef}
          height="100%"
          previewStyle={isFullScreen ? "vertical" : "tab"}
          hideModeSwitch={true}
          initialEditType={"markdown"}
          initialValue={content}
          previewHighlight={false}
          usageStatistics={false}
          useCommandShortcut={false}
          language={"zh-CN"}
          toolbarItems={toolbarItems}
          hooks={{
            addImageBlobHook: addImage,
          }}
          events={{
            change: () => {
              changeHandleRef.current();
            },
            focus: () => {
              focusHandleRef.current();
            },
            blur: () => {
              blurHandleRef.current();
            },
          }}
        />
      )}
    </div>
  );
};

export default TuiEditorComponent;
