import * as React from "react";
import "../styles.css";

//引入的库组件
import axios from "axios";
import { message, Dropdown, Menu, Tooltip, Spin } from "antd";
import moment from "moment";
import { useApolloClient } from "react-apollo";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import * as localforage from "localforage";

//引入的其他页面组件
import Writing from "../../Router_Writing/index";
import MainContainer from "../../Router_MainContainer/index";
import { getUrlPath, getUrlSearch } from "../../../utils/getUrlInfo";
import {
  TimeStampToDate,
  DateToTimeStamp,
  compareDate,
} from "../../../utils/index";
import { convertToZH } from "aw-error";
import SearchWithDrop from "../../Components/Search/searchWithDrop";
import SearchWithoutDrop from "../../Components/Search/searchWithoutDrop";
import GlobalSearchModal from "../../GlobalSearchModal/index";
import ImportModal from "../../Components/ImportModal/index";
import ExportModal from "../../Components/ExportModal/index";
import ReleaseModal from "../../Components/ReleaseModal/index";
import ReportModal from "../../Components/ReportModal/index";
import { getSelectedKeysAncestors } from "../../Router_Writing/index";
import KeyMap from "../../../i18n/keymap";
import { getDatasIds, copyObject } from "../../Router_Writing/utils";
import { MainContextObj } from "../../HomePage/MainContext";
import { resetProjectInfo, resetCardInfo } from "./utils";
import * as WritingModuleAction from "../../WritingModuleAction/index";
import configs from "../../../configs";
import headers from "../../../GlobalData/headers";

//graphql查询
import {
  useGetProjectDetailInfoQuery,
  useSetOpenProjectDateMutation,
  useGetProjectListQuery,
  useGetCardsQuery,
  CardType,
  ProjectType,
  SortOrder,
} from "../../../generated/graphql";

//引入的资源图片
import CARD_TITLE_INDEX from "../../../assets/card_title_index.png";
import CARD_TITLE_INDEX_2 from "../../../assets/card_title_index2.png";
import EDITOR_TITLE_INDEX from "../../../assets/editor_title_index_2.png";
import EDITOR_TITLE_INDEX_2 from "../../../assets/editor_title_index.png";
import ARTICLE_INDEX from "../../../assets/article_index.png";
import ARTICLE_INDEX_2 from "../../../assets/article_index_2.png";
import BackIcon from "../../../assets/forward.png";
import FetchMoreIcon from "../../../assets/writing/dropdown-2.png";
import NodeDownIcon from "../../../assets/node-down.png";
import AddIcon from "../../../assets/writing/add.png";
import AddChildIcon from "../../../assets/writing/add-child.png";
import InsertIcon from "../../../assets/writing/insert.png";
import DeleteIcon from "../../../assets/writing/delete.png";
import SplitIcon from "../../../assets/writing/split.png";
import SaveIcon from "../../../assets/save_button.png";
import ExportIcon from "../../../assets/import-export/export.png";
import ImportIcon from "../../../assets/import-export/import.png";
import ReleaseIcon from "../../../assets/import-export/release.png";
import ReportIcon from "../../../assets/import-export/report.png";
import { uuid } from "short-uuid";

const GET_SAVE_TIME = gql`
  {
    writingTitleBarToolsSaveTimeClient @client
    writingChangeProjectNameClickClient @client
    writingChangeProjectNameClient @client
  }
`;

const PER_PAGE_COUNT = 11;

let exportCallback = null; //导出回调(废弃)
let searchCallback = null; //搜索回调
let addToProjectCallback = null; //全局搜索回调
let importCallback = null; //导入回调

//路由格式示例/project/writing/p/:code/c/:code?tab=xx
export const P_INDEX = 2;
export const P_ID_INDEX = 3;
export const C_INDEX = 4;
export const C_ID_INDEX = 5;

const MyWriting = (props) => {
  const { setIsLogin, history } = props;

  const {
    DBUUID,
    isNetworkOnline,
    dbController,
    isDBLeader,
  } = React.useContext(MainContextObj);
  const { data: saveTime } = useQuery(GET_SAVE_TIME);
  const {
    writingTitleBarToolsSaveTimeClient,
    writingChangeProjectNameClickClient,
    writingChangeProjectNameClient,
  } = saveTime;

  const client = useApolloClient();
  const [isLoading, setIsLoading] = React.useState(false);
  const [titleSelectedIndex, setTitleSelectedIndex] = React.useState(1);
  const [isInProject, setIsInProject] = React.useState(false);
  const [isGetProjectList, setIsGetProjectList] = React.useState(false);
  const [dropDownProjectList, setDropDownProjectList] = React.useState([]);
  const [isCreateInWriting, setIsCreateInWriting] = React.useState(false);
  const [globalSearchString, setGlobalSearchString] = React.useState("");
  const [selectedProjectCode, setSelectedProjectCode] = React.useState("");
  const [isResetEditor, setIsResetEditor] = React.useState(false);

  const [currentProjectDBKey, setCurrentProjectDBKey] = React.useState("");
  const [currentProjectInfo, setCurrentProjectInfo] = React.useState(null);
  const [currentProjectCards, setCurrentProjectCards] = React.useState(null);
  const [currentSelectCardId, setCurrentSelectCardId] = React.useState(""); //当前选中的节点ID

  const [showGlobalSearchModal, setShowGlobalSearchModal] = React.useState({
    isShow: false,
    isSearch: false,
  });
  const [dropDownProjectPageInfo, setDropDownProjectPageInfo] = React.useState(
    {}
  );
  const [defaultSelectedCard, setDefaultSelectedCard] = React.useState("");

  const [exportInfo, setExportInfo] = React.useState({
    isShowExportModal: false,
    selectedNode: "",
    projectId: "",
  });

  const [importInfo, setImportInfo] = React.useState({
    isShowImportModal: false,
    selectedNode: "",
    projectId: "",
  });

  const [releaseInfo, setReleaseInfo] = React.useState({
    isShowReleaseModal: false,
    selectedNode: "",
    projectId: "",
  });

  const [reportInfo, setReportInfo] = React.useState({
    isShowReportModal: false,
    selectedNode: "",
    projectId: "",
  });

  const [setOpenProjectDateMutation] = useSetOpenProjectDateMutation();

  const { refetch: getProjectDetailQuery } = useGetProjectDetailInfoQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getCardsInfoQuery } = useGetCardsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const { refetch: getProjectListQuery } = useGetProjectListQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    const getData = async () => {
      let pathArray = getUrlPath();
      let searchArray = getUrlSearch();

      let projectsTemp = [];
      let input = {
        first: PER_PAGE_COUNT,
        after: undefined,
        before: undefined,
        last: undefined,
        order: SortOrder.Desc,
      };

      getProjectList(input, false, async (projects) => {
        if (projects.length) {
          projectsTemp = projects;
          setDropDownProjectList(projects);

          //将参数放到pathname中的解析
          if (pathArray) {
            if (
              pathArray[P_INDEX] &&
              pathArray[P_INDEX] === "p" &&
              pathArray[P_ID_INDEX] &&
              pathArray[P_ID_INDEX] !== ""
            ) {
              let projectId = window.btoa("Project:" + pathArray[P_ID_INDEX]);
              let cards = [];
              let project = await fetchProject(pathArray[P_ID_INDEX], true);
              if (project) {
                await setOpenProjectDate(
                  window.atob(project.id).split("Project:")[1]
                );
              }
              if (project) {
                cards = await fetchCards(
                  project.outline,
                  window.atob(project.id).split("Project:")[1]
                );
              }

              let tab = 1;
              if (searchArray.tab) {
                tab = parseInt(searchArray.tab);
              }
              let path = `${window.location.pathname}?tab=${tab}`;
              if (
                pathArray[C_INDEX] &&
                pathArray[C_INDEX] === "c" &&
                pathArray[C_ID_INDEX] &&
                pathArray[C_ID_INDEX] !== ""
              ) {
                setDefaultSelectedCard(
                  window.btoa("Card:" + pathArray[C_ID_INDEX])
                );
              }
              window.history.pushState(null, "", path);
              setTitleSelectedIndex(tab);
              setIsInProject(true);
            } else {
              let originalId = window
                .atob(projectsTemp[0].id)
                .split("Project:")[1];
              let code = window.atob(projectsTemp[0].code).split("Project:")[1];
              const project = await fetchProject(originalId);
              if (project) await fetchCards(project.outline, originalId);
              if (project) await setOpenProjectDate(originalId);
              window.history.pushState(null, "", `/project/writing/p/${code}`);
              setIsInProject(true);
            }
          }
        } else {
          history.push("/project/myproject");
        }
      });
    };
    getData();
  }, []);

  React.useEffect(() => {
    if (writingChangeProjectNameClickClient) {
      let dropDownProjectListTemp = dropDownProjectList.map((item) => {
        return item;
      });
      dropDownProjectListTemp[0].title = writingChangeProjectNameClient;
      document.title = writingChangeProjectNameClient;
      setDropDownProjectList(dropDownProjectListTemp);
      client.writeData({
        data: { writingChangeProjectNameClickClient: false },
      });
    }
  }, [writingChangeProjectNameClickClient]);

  const getProjectList = (input, isFetchMore = false, callback = null) => {
    input = {
      ...input,
      filter: {
        types: [ProjectType.User],
      },
    };
    setIsGetProjectList(true);
    getProjectListQuery(input).then(async (res) => {
      if (res.data.projects) {
        let projects = res.data.projects.edges.map((item, index) => {
          return item.node;
        });

        let projectsTemp = dropDownProjectList.map((item) => {
          return item;
        });
        if (isFetchMore) {
          projects = projectsTemp.concat(projects);
        }
        setDropDownProjectList(projects);
        setDropDownProjectPageInfo(res.data.projects.pageInfo);

        if (callback) {
          callback(projects);
        }
      }
      setIsGetProjectList(false);
    });
  };

  const exportCardCallback = (isSingleCard = false) => {
    let ids = getSelectedKeysAncestors();
    let isInRecycleNode = false;
    ids.forEach((item) => {
      if (item === "recycle-node") {
        isInRecycleNode = true;
      }
    });
    if (isInRecycleNode) {
      message.warning("无法从废纸篓中导出");
    } else {
      setExportInfo({
        isShowExportModal: true,
        selectedNodeAncestors: getSelectedKeysAncestors(),
        projectId: currentProjectInfo.originalId,
        isSingleCard: isSingleCard,
      });
    }
  };

  const setOpenProjectDate = async (
    originalId,
    project = null,
    callback = null
  ) => {
    let input = {
      id: originalId,
      lastSeenAt: moment()
        .utc(8)
        .format(),
    };
    if (project) {
      input = {
        ...input,
        ...project,
      };
    }
    setOpenProjectDateMutation({ variables: { input } }).then((res) => {
      if (callback) {
        callback();
      }
    });
  };

  const getCardsByRest = async (variables) => {
    const GET_CARDS_QUERY = `
    query GetCards($first: Int, $ids: [ID], $filter: CardFilter!) {
      cards(first: $first, ids: $ids, filter: $filter) {
        count
        edges {
            cursor
            node {
              id
              code
              categories
              content
              coordinate
              insertedAt
              tags
              title
              type
              fileType
              updatedAt
            }
        }
      }
  }
    `;

    const res = await axios.post(
      configs.url,
      {
        query: GET_CARDS_QUERY,
        variables: variables,
      },
      {
        headers: headers,
      }
    );
    return res.data;
  };

  const fetchProject = async (originalId, isCode = false) => {
    let id = window.btoa("Project:" + originalId);
    let variables = {};
    if (isCode) {
      variables = {
        code: id,
      };
    } else {
      variables = {
        id: id,
      };
    }

    try {
      const pRes = await getProjectDetailQuery(variables);
      if (!pRes.data.node) {
        message.error("获取项目失败");
        history.push("/project/myproject");
        return;
      }

      const fixOutlineData = (outline) => {
        outline.map((item, index) => {
          if (!item.icon) {
            item.icon = "icon-default";
            if (item.file_type && item.file_type === "folder") {
              if (item.title === "手稿") {
                item.icon = "icon-menuscript";
              } else if (item.title === "资料") {
                item.icon = "icon-data";
              } else if (item.title === "灵感") {
                item.icon = "icon-inspiration";
              } else if (item.title === "人物") {
                item.icon = "icon-person";
              }
            }
          }
          if (!item.children) {
            item.children = [];
          } else if (item.children.length) {
            fixOutlineData(item.children);
          }
        });
      };

      let projectData = pRes.data.node;
      let projectId = window.atob(projectData.id).split("Project:")[1];
      projectData.originalId = projectId;
      projectData.outlineState =
        projectData.outlineState && Object.keys(projectData.outlineState).length
          ? projectData.outlineState
          : { expandedKeys: [], lastEditNode: "" };
      let projectCode = window.atob(projectData.code).split("Project:")[1];
      projectData.originalCode = projectCode;
      let pOutline = projectData.outline;
      let children = pOutline.menu.children;
      client.writeData({
        data: {
          defaultSelectedCardIdClient:
            children && children.length > 0 ? children[0].id : originalId,
        },
      });
      const hasTrashBin = pOutline.menu[1];
      if (!hasTrashBin) {
        pOutline.menu = [pOutline.menu];
        pOutline.menu.push({
          children: [],
          id: "recycle-node",
          title: "废纸篓",
        });
      }
      pOutline.menu[0].id = projectData.originalId;
      fixOutlineData(pOutline.menu);

      //=================新添加的数据库操作
      if (!projectData.outlineState.outlineUpdateAt) {
        projectData.outlineState.outlineUpdateAt = projectData.updatedAt;
      }
      let data = {};
      data.id = projectData.originalId;
      data.data = JSON.stringify(projectData);
      data.dbUpdateMark = DBUUID;

      let projectFromDB = await WritingModuleAction.getProjectData(
        projectData.originalId,
        dbController
      );

      if (projectFromDB && projectFromDB !== "") {
        let projectDataFromDB = JSON.parse(projectFromDB);
        if (
          !projectDataFromDB.outlineState.outlineUpdateAt ||
          compareDate(
            projectData.outlineState.outlineUpdateAt,
            projectDataFromDB.outlineState.outlineUpdateAt
          )
        ) {
          await WritingModuleAction.updateProjectData(data, dbController);
        } else {
          projectData = projectDataFromDB;
        }
      } else {
        await WritingModuleAction.updateProjectData(data, dbController);
      }
      //=================
      setCurrentProjectInfo(projectData);
      client.writeData({
        data: {
          currentProjectIdClient: projectId,
          projectOutlineClient: JSON.stringify(pOutline),
        },
      });
      let input = {
        id: projectData.originalId,
        outline: JSON.stringify(projectData.outline),
      };
      if (isDBLeader) {
        await setOpenProjectDateMutation({ variables: { input } });
      }
      return projectData;
    } catch (err) {
      console.error(err);
      message.error("项目-获取失败");
      history.push("/project/myproject");
    }
  };

  const fetchCards = async (pOutline, projectId) => {
    if (!pOutline.menu && pOutline.menu.length) {
      return;
    }
    const cardIdsOutline = getDatasIds(pOutline.menu[0].children).map(
      (item) => {
        return window.btoa("Card:" + item);
      }
    );
    const cardIdsTrash = getDatasIds(pOutline.menu[1].children).map((item) => {
      return window.btoa("Card:" + item);
    });
    let ids = [...cardIdsOutline, ...cardIdsTrash];

    let variables = {
      first: ids.length,
      ids,
      filter: { types: [CardType.User] },
    };
    try {
      let start = Date.now();
      // const cRes = await getCardsInfoQuery(variables);
      const cRes = await getCardsByRest(variables);
      if (!cRes.data.cards || !cRes.data.cards.edges.length) {
        console.error("获取卡片失败，重新打开一下吧");
      }
      let cards = {};
      cRes.data.cards.edges.map(async (item, index) => {
        let data = {
          categories: item.node.categories,
          content: item.node.content,
          coordinate: item.node.coordinate,
          id: window.atob(item.node.id).split("Card:")[1],
          insertedAt: item.node.insertedAt,
          tags: item.node.tags,
          title: item.node.title,
          type: item.node.type,
          code: window.atob(item.node.code).split("Card:")[1],
          updatedAt: item.node.updatedAt,
        };
        cards[data.id] = data;
      });

      //=================新添加的数据库操作
      let cardsDataFromDB = await WritingModuleAction.getCardsData(
        projectId,
        dbController
      );
      if (cardsDataFromDB && cardsDataFromDB !== "") {
        let cardsFromDB = JSON.parse(cardsDataFromDB);
        Object.keys(cards).forEach((cardFromServer) => {
          if (cardsFromDB[cardFromServer]) {
            if (
              cards[cardFromServer].updatedAt &&
              DateToTimeStamp(cardsFromDB[cardFromServer].updatedAt) >
                DateToTimeStamp(cards[cardFromServer].updatedAt)
            ) {
              cards[cardFromServer] = copyObject(cardsFromDB[cardFromServer]);
            }
          }
        });
      }

      let data = {};
      data.id = projectId;
      data.data = JSON.stringify(cards);
      data.dbUpdateMark = DBUUID;

      await WritingModuleAction.updateCardData(data, dbController);

      setCurrentProjectCards(cards);
      client.writeData({
        data: {
          projectCardsDataClient: JSON.stringify(cards),
        },
      });
      return cards;
    } catch (err) {
      console.error("卡片获取", err);
    }
  };

  const setExportCallback = (callback) => {
    exportCallback = callback;
  };

  const setSearchCallback = (callback) => {
    searchCallback = callback;
  };

  const setToProjectButtonCallback = (callback) => {
    addToProjectCallback = callback;
  };

  const setImportCallback = (callback) => {
    importCallback = callback;
  };

  const importSubmit = (
    selectedNode,
    newOutline,
    newCards,
    source_type,
    isLastCard
  ) => {
    if (importCallback) {
      importCallback(
        selectedNode,
        newOutline,
        newCards,
        source_type,
        isLastCard
      );
    }
  };

  const hoverCompo = (hoverInfo) => (
    <div className="tooltip-hover-info">
      <div style={{ width: "100%" }}>{hoverInfo.title}</div>
      <div>{hoverInfo.keys}</div>
    </div>
  );

  const openGlobalSearch = (string) => {
    setGlobalSearchString(string);
    setShowGlobalSearchModal({ isSearch: true, isShow: true });
  };

  const openGlobalSearchRef = React.useRef(openGlobalSearch);
  openGlobalSearchRef.current = openGlobalSearch;

  const renderBodyContent = () => {
    if (!isInProject) {
      return (
        <div className="project-writing-content-loading">
          <Spin spinning={true} tip={"正在加载卡片"}></Spin>
        </div>
      );
    }
    return (
      <Writing
        DBUUID={DBUUID}
        isDBLeader={isDBLeader}
        isNetworkOnline={isNetworkOnline}
        dbController={dbController}
        exportCardCallback={exportCardCallback}
        setShowGlobalSearchModal={setShowGlobalSearchModal}
        setToProjectButtonCallback={setToProjectButtonCallback}
        currentProjectCards={currentProjectCards}
        setCurrentProjectCards={setCurrentProjectCards}
        currentProjectInfo={currentProjectInfo}
        setCurrentProjectInfo={setCurrentProjectInfo}
        titleSelectedIndex={titleSelectedIndex}
        setTitleSelectedIndex={setTitleSelectedIndex}
        setImportCallback={setImportCallback}
        setExportCallback={setExportCallback}
        setSearchCallback={setSearchCallback}
        isResetEditor={isResetEditor}
        setIsResetEditor={setIsResetEditor}
        currentSelectCardId={currentSelectCardId}
        setCurrentSelectCardId={setCurrentSelectCardId}
      />
    );
    // return (
    //   <WritingModule
    //     exportCardCallback={exportCardCallback}
    //     isResetEditor={isResetEditor}
    //     setIsResetEditor={setIsResetEditor}
    //     setImportCallback={setImportCallback}
    //     setIsCreateInWriting={setIsCreateInWriting}
    //     setExportCallback={setExportCallback}
    //     setShowGlobalSearchModal={setShowGlobalSearchModal}
    //     setSearchCallback={setSearchCallback}
    //     setToProjectButtonCallback={setToProjectButtonCallback}
    //     defaultSelectedCard={defaultSelectedCard}
    //     titleIndex={titleSelectedIndex - 1}
    //     setTitleSelectedIndex={setTitleSelectedIndex}
    //     selectedProjectCode={selectedProjectCode}
    //   />
    // );
  };

  const projectListOverlay = () => {
    let recentProject = [];
    let otherProject = [];

    let dropDownProjectListTemp = dropDownProjectList.filter((item) => {
      return true;
    });
    dropDownProjectListTemp.push({
      title: "更多项目...",
      id: "project-back",
      code: "project-back",
    });
    if (dropDownProjectListTemp.length > 6) {
      recentProject = dropDownProjectListTemp.filter((item, index) => {
        return index > 0 && index <= 5;
      });
      otherProject = dropDownProjectListTemp.filter((item, index) => {
        return index > 5;
      });
    } else {
      recentProject = dropDownProjectListTemp.slice(1);
    }

    const selectProject = (projectCode, projectId) => {
      if (projectId === "project-back" && projectCode === "project-back") {
        history.push("/project/myproject");
      } else {
        let id = window.atob(projectId).split("Project:")[1];
        let code = window.atob(projectCode).split("Project:")[1];
        setIsInProject(false);
        // setIsLoading(true);
        setOpenProjectDate(id, null, () => {
          history.push("/project/writing/p/" + code);
        });
      }
    };
    return (
      <Menu
        className="project-writing-title-writing-tool-bar-dropdown-container"
        style={{
          paddingBottom: dropDownProjectPageInfo.hasNextPage ? "0px" : "10px",
        }}
      >
        <div className="project-writing-title-writing-tool-bar-dropdown-last-text">
          最近使用
        </div>
        {recentProject.map((item, index) => {
          return (
            <div
              key={"writing-projects-drop-recent-" + index}
              className="project-writing-title-writing-tool-bar-dropdown-last-item"
              onClick={() => {
                selectProject(item.code, item.id);
                // let input = {
                //   first: PER_PAGE_COUNT,
                //   after: undefined,
                //   before: undefined,
                //   last: undefined,
                //   order: SortOrder.Desc,
                // };
                // getProjectList(input);
              }}
            >
              <div className="project-writing-title-writing-tool-bar-dropdown-last-item-text">
                {item.title}
              </div>
            </div>
          );
        })}
        {dropDownProjectList.length > 6 && (
          <>
            <div className="project-writing-title-writing-tool-bar-dropdown-divider" />
            {otherProject.map((item, index) => {
              return (
                <div
                  key={"writing-projects-drop-" + index}
                  className="project-writing-title-writing-tool-bar-dropdown-last-item"
                  onClick={() => {
                    selectProject(item.code, item.id);
                    // let input = {
                    //   first: PER_PAGE_COUNT,
                    //   after: undefined,
                    //   before: undefined,
                    //   last: undefined,
                    //   order: SortOrder.Desc,
                    // };
                    // getProjectList(input);
                  }}
                >
                  <div className="project-writing-title-writing-tool-bar-dropdown-last-item-text">
                    {item.title}
                  </div>
                </div>
              );
            })}
            {dropDownProjectPageInfo.hasNextPage ||
              (dropDownProjectList.length > 15 && (
                <div
                  className="project-writing-title-writing-tool-bar-dropdown-fetch-more-container"
                  onClick={() => {
                    let input = {
                      first: 9,
                      after: dropDownProjectPageInfo.endCursor,
                      before: undefined,
                      last: undefined,
                      order: SortOrder.Desc,
                    };
                    getProjectList(input, true);
                  }}
                >
                  <img
                    src={FetchMoreIcon}
                    className="project-writing-title-writing-tool-bar-dropdown-fetch-more-icon"
                  />
                </div>
              ))}
          </>
        )}
      </Menu>
    );
  };

  const renderImportExportButton = () => {
    return (
      <div className="import-export-button-container">
        {/* <Tooltip placement="bottom" title="演示">
          <img
            id={"button-report"}
            src={ReportIcon}
            className="import-export-button"
            onClick={() => {
              setReportInfo({
                isShowReportModal: true,
                selectedNodeAncestors: getSelectedKeysAncestors(),
                projectId: selectedProjectId,
              });
            }}
          />
        </Tooltip>
        <Tooltip placement="bottom" title="发布">
          <img
            id={"button-release"}
            src={ReleaseIcon}
            className="import-export-button"
            onClick={() => {
              setReleaseInfo({
                isShowReleaseModal: true,
                selectedNodeAncestors: getSelectedKeysAncestors(),
                projectId: selectedProjectId,
              });
            }}
          />
        </Tooltip> */}
        <Tooltip placement="bottom" title="导入">
          <img
            id={"button-import"}
            src={ImportIcon}
            className="import-export-button"
            onClick={() => {
              let ids = getSelectedKeysAncestors();
              let isInRecycleNode = false;
              ids.forEach((item) => {
                if (item === "recycle-node") {
                  isInRecycleNode = true;
                }
              });
              if (isInRecycleNode) {
                message.warning("无法向废纸篓中导入");
              } else {
                setImportInfo({
                  isShowImportModal: true,
                  selectedNodeAncestors: getSelectedKeysAncestors(),
                  projectId: currentProjectInfo.originalId,
                });
              }
            }}
          />
        </Tooltip>
        <Tooltip placement="bottom" title="导出">
          <img
            id={"button-export"}
            src={ExportIcon}
            className="import-export-button"
            onClick={() => {
              exportCardCallback();
            }}
          />
        </Tooltip>
      </div>
    );
  };

  const getHeaderTitle = (index) => {
    switch (index) {
      case 0:
        return "卡片列表模式";
      case 1:
        return "卡片编辑模式";
      case 2:
        return "卡片拼接模式";
    }
  };

  const splitOverlay = () => {
    return (
      <Menu
        className="project-writing-split-container"
        onClick={(e) => {
          switch (e.key) {
            case "0":
              client.writeData({
                data: {
                  writingTitleBarToolsSplitClickClient: true,
                  writingTitleBarToolsSplitTypeClient: "sentence",
                },
              });
              break;
            case "1":
              client.writeData({
                data: {
                  writingTitleBarToolsSplitClickClient: true,
                  writingTitleBarToolsSplitTypeClient: "paragraph",
                },
              });
              break;
            case "2":
              client.writeData({
                data: {
                  writingTitleBarToolsSplitClickClient: true,
                  writingTitleBarToolsSplitTypeClient: "heading",
                },
              });
              break;
          }
        }}
      >
        <Menu.Item key={"0"} className="project-writing-split-item-container">
          按照句子拆分
        </Menu.Item>
        <Menu.Item key={"1"} className="project-writing-split-item-container">
          按照段落拆分
        </Menu.Item>
        <Menu.Item key={"2"} className="project-writing-split-item-container">
          按照标题拆分
        </Menu.Item>
      </Menu>
    );
  };

  const renderHeaderContent = () => {
    let categories = [
      { normal: CARD_TITLE_INDEX, selected: CARD_TITLE_INDEX_2 },
      { normal: EDITOR_TITLE_INDEX, selected: EDITOR_TITLE_INDEX_2 },
      { normal: ARTICLE_INDEX, selected: ARTICLE_INDEX_2 },
    ];
    return (
      <div className="project-myproject-header-container">
        {isInProject && (
          <div className="project-myproject-header-tab-container">
            {categories.map((item, index) => {
              let currentIndex = index + 1;
              let isSelected = currentIndex === titleSelectedIndex;
              if (
                currentSelectCardId === currentProjectInfo.originalId &&
                currentIndex === 2
              ) {
                return (
                  <div
                    key={"project-writing-" + currentIndex}
                    className={"project-myproject-header-tab-item"}
                    style={{ cursor: "not-allowed" }}
                  >
                    <img
                      src={isSelected ? item.selected : item.normal}
                      style={{ width: 26 }}
                    />
                  </div>
                );
              }
              return (
                <Tooltip
                  key={"header-button" + currentIndex}
                  placement="bottom"
                  title={getHeaderTitle(index)}
                >
                  <div
                    key={"project-writing-" + currentIndex}
                    className={"project-myproject-header-tab-item"}
                    onClick={() => {
                      if (currentIndex === 2) {
                        if (
                          currentSelectCardId === currentProjectInfo.originalId
                        ) {
                          return;
                        }
                        setIsResetEditor(true);
                      }
                      if (!isSelected) {
                        setTitleSelectedIndex(currentIndex);
                        let searchArray = getUrlSearch();
                        searchArray.tab = currentIndex;
                        let keys = Object.keys(searchArray);
                        let path =
                          window.location.pathname +
                          (keys.length > 0 ? "?" : "");
                        keys.forEach((item, index) => {
                          if (index !== keys.length - 1) {
                            path += `${item}=${searchArray[item]}&`;
                          } else {
                            path += `${item}=${searchArray[item]}`;
                          }
                        });
                        window.history.pushState(null, "", path);
                      }
                    }}
                  >
                    <img
                      src={isSelected ? item.selected : item.normal}
                      style={{ width: 26 }}
                    />
                  </div>
                </Tooltip>
              );
            })}
          </div>
        )}
        {isInProject && isNetworkOnline && renderImportExportButton()}
        {isInProject && isNetworkOnline && (
          // <SearchWithoutDrop
          //   position={{ right: "25px" }}
          //   callback={(string) => {
          //     openGlobalSearchRef.current(string);
          //   }}
          // />
          <SearchWithDrop
            position={{ right: "25px" }}
            callback={(index, string) => {
              if (index === "当前页面") {
                if (searchCallback) {
                  searchCallback(string);
                }
              }
              if (index === "全部搜索") {
                openGlobalSearchRef.current(string);
              }
            }}
            selectValues={[
              {
                value: "当前页面",
              },
              {
                value: "全部搜索",
              },
            ]}
          />
        )}
        {isInProject && (
          <div className="project-writing-title-writing-tool-bar-container">
            {dropDownProjectList.length > 0 && isNetworkOnline && (
              <img
                src={BackIcon}
                className="project-writing-title-writing-tool-bar-back-button"
                onClick={() => {
                  history.push("/project/myproject");
                }}
              />
            )}

            <div className="project-writing-title-writing-tool-bar-dropdown-button-container">
              <Dropdown
                className={
                  "project-writing-title-writing-tool-bar-dropdown" +
                  (dropDownProjectList.length < 2 || !isNetworkOnline
                    ? " project-writing-title-writing-tool-bar-dropdown-offline"
                    : "")
                }
                trigger={["click"]}
                disabled={dropDownProjectList.length < 2 || !isNetworkOnline}
                placement="bottomCenter"
                overlay={projectListOverlay}
                getPopupContainer={() =>
                  document.getElementById("project-list")
                }
              >
                <div
                  className="ant-dropdown-link"
                  id="project-list"
                  style={{ cursor: "pointer", color: "#000000" }}
                >
                  <div className="project-writing-title-writing-tool-bar-dropdown-text">
                    {dropDownProjectList[0].title}
                  </div>
                  <img
                    src={NodeDownIcon}
                    className="project-writing-title-writing-tool-bar-dropdown-icon"
                  />
                </div>
              </Dropdown>
            </div>
            <div className="project-writing-title-writing-tool-bar-divider" />
            <div
              className="project-writing-title-writing-tool-bar-save-container"
              onClick={() => {
                client.writeData({
                  data: {
                    writingTitleBarToolsSaveClickClient: true,
                  },
                });
              }}
            >
              <div className="project-writing-title-writing-tool-bar-save-dot" />
              <div className="project-writing-title-writing-tool-bar-save-text">
                {writingTitleBarToolsSaveTimeClient === ""
                  ? "未保存"
                  : "保存于" + writingTitleBarToolsSaveTimeClient}
              </div>
            </div>
            {isNetworkOnline ? (
              <div className="project-writing-title-writing-tool-bar-card-button-container">
                <Tooltip
                  placement="bottom"
                  title={hoverCompo({
                    title: "保存",
                    keys: KeyMap.SHORTCUTS_SAVE_KEY,
                  })}
                >
                  <img
                    id="button-save"
                    onClick={() => {
                      client.writeData({
                        data: {
                          writingTitleBarToolsSaveClickClient: true,
                        },
                      });
                    }}
                    src={SaveIcon}
                    className="project-writing-title-writing-tool-bar-card-button"
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={hoverCompo({
                    title: "新建同级卡片",
                    keys: KeyMap.SHORTCUTS_ADD_CARD_IN_SAME_LEVEL_KEY,
                  })}
                >
                  <img
                    id={"button-add"}
                    src={AddIcon}
                    className="project-writing-title-writing-tool-bar-card-button"
                    onClick={() => {
                      client.writeData({
                        data: { writingTitleBarToolsAddClickClient: true },
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={hoverCompo({
                    title: "新建子卡片",
                    keys: KeyMap.SHORTCUTS_ADD_CARD_IN_CHILD_KEY,
                  })}
                >
                  <img
                    id={"button-add-child"}
                    src={AddChildIcon}
                    className="project-writing-title-writing-tool-bar-card-button"
                    onClick={() => {
                      client.writeData({
                        data: { writingTitleBarToolsAddChildClickClient: true },
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip
                  placement="bottom"
                  title={hoverCompo({
                    title: "插入卡片",
                    keys: KeyMap.SHORTCUTS_INSERT_CARD_KEY,
                  })}
                >
                  <img
                    id={"button-insert"}
                    src={InsertIcon}
                    className="project-writing-title-writing-tool-bar-card-button"
                    onClick={() => {
                      client.writeData({
                        data: { writingTitleBarToolsInsertClickClient: true },
                      });
                    }}
                  />
                </Tooltip>
                <Dropdown
                  trigger={["hover"]}
                  placement="bottomCenter"
                  overlay={splitOverlay}
                  getPopupContainer={() =>
                    document.getElementById("button-split")
                  }
                >
                  <div
                    id={"button-split"}
                    className="project-writing-title-writing-tool-bar-card-button"
                  >
                    <img
                      src={SplitIcon}
                      className="project-writing-title-writing-tool-bar-card-button-img"
                    />
                  </div>
                </Dropdown>
                <Tooltip
                  placement="bottom"
                  title={hoverCompo({
                    title: "删除卡片",
                    keys: KeyMap.SHORTCUTS_DELETE_CARD_KEY,
                  })}
                >
                  <img
                    id={"button-delete"}
                    src={DeleteIcon}
                    className="project-writing-title-writing-tool-bar-card-button"
                    onClick={() => {
                      client.writeData({
                        data: { writingTitleBarToolsDeleteClickClient: true },
                      });
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className="project-writing-title-writing-tool-bar-card-button-container-offline">
                正在离线编辑
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <MainContainer
        currentSiderIndex={1}
        setIsLogin={setIsLogin}
        isBodyLoading={isLoading}
        bodyContent={renderBodyContent()}
        headerContent={renderHeaderContent()}
      />
      <GlobalSearchModal
        showModal={showGlobalSearchModal}
        showAddToProject={true}
        addToProjectCallback={addToProjectCallback}
        globalSearchString={globalSearchString}
        handleOnCancel={() => {
          setShowGlobalSearchModal({ isShow: false, isSearch: false });
        }}
      />
      <ImportModal
        importInfo={importInfo}
        setImportInfo={setImportInfo}
        importSubmit={importSubmit}
      />
      <ExportModal exportInfo={exportInfo} setExportInfo={setExportInfo} />
      <ReleaseModal releaseInfo={releaseInfo} setReleaseInfo={setReleaseInfo} />
      <ReportModal reportInfo={reportInfo} setReportInfo={setReportInfo} />
    </>
  );
};

export default MyWriting;
