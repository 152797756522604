import * as React from "react";
import "./styles.css";

//引入的库组件
import { Modal, Form, Input } from "antd";

//引入的其他页面组件

//graphql查询

//引入的资源图片

const CreateModal = (props) => {
  const {
    title,
    tip,
    showModal,
    handleOnOk,
    handleOnCancel,
    form,
    originName,
  } = props;
  const submit = () => {
    form.validateFieldsAndScroll((errors, values) => {
      if (errors) {
        return;
      }
      handleOnOk && handleOnOk(values.fileName);
      form.resetFields();
    });
  };

  const inputRef = React.useRef();

  React.useEffect(() => {
    if(showModal && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showModal]);

  return (
    <Modal
      afterClose={() => {
        form.resetFields();
      }}
      footer={null}
      centered
      className="createModal-1546"
      title={title}
      visible={showModal}
      closable={false}
      onOk={() => {
        submit();
      }}
      onCancel={() => {
        form.resetFields();
        handleOnCancel && handleOnCancel();
      }}
    >
      <Form>
        <Form.Item hasFeedback>
          {form.getFieldDecorator("fileName", {
            initialValue: originName ? originName : "",
            rules: [
              {
                required: true,
                message: tip,
              },
            ],
          })(
            <Input
              ref={inputRef}
              className="createModal-2745"
              maxLength={36}
              placeholder={tip}
              size={"large"}
              onPressEnter={() => {
                submit();
              }}
              autoFocus={true}
              onFocus={(e) => {
                e.target.select();
              }}
            />
          )}
        </Form.Item>
      </Form>
      <div className="createModal-3709">
        <div
          className="createModal-4214"
          onClick={() => {
            form.resetFields();
            handleOnCancel && handleOnCancel();
          }}
        >
          <div className="createModal-4224">取消</div>
        </div>
        <div
          className="createModal-4487"
          onClick={() => {
            submit();
          }}
        >
          <div className="createModal-4497">确定</div>
        </div>
      </div>
    </Modal>
  );
};

const CreateModalWrapper = Form.create()(CreateModal);

export default CreateModalWrapper;
