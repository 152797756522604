import * as React from "react";
import "./styles.css";

//引入的库组件
import { Dropdown } from "antd";

const BubbleBox = (props) => {
  const { children, contentNode, onVisibleChange, disabled } = props;

  return (
    <Dropdown
    disabled={disabled}
      onVisibleChange={(visible) => {
        if (onVisibleChange) {
          onVisibleChange(visible);
        }
      }}
      trigger={["click"]}
      placement={"bottomCenter"}
      overlay={contentNode}
      overlayClassName="bubble-box-container"
    >
      {children}
    </Dropdown>
  );
};

export default BubbleBox;
