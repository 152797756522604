/**
 * Created by wangna on 2017/12/19.
 */
const classnames = (...args) => {
    let classname = [];
    args.map(arg => {
        if (typeof arg === 'string') {
            classname.push(arg);
        } else {
            const keyArr = Object.keys(arg);
            classname = [...keyArr.filter(key => arg[key]), ...classname];
        }
    });
    return classname.join(" ");
};
export default classnames;