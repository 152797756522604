import * as React from "react";
import "./styles.css";

//引入的库组件
import { Chart, registerShape } from "@antv/g2";

//引入的其他页面组件
import Help from "./components/help";
import Title from "./components/title";
import { WatchChart } from "./components/watchChart";
import { CircleChart } from "./components/circleChart";
import { getModuleTip } from "./moduleTips";

//graphql查询

//引入的资源图片

const mockReadabilityData = [{ type: "可读性", value: 43 }];
const mockVocabularyData = [{ type: "词汇量", value: 44.02 }];
const mockSentimentityData = [{ type: "情感指数", value: 0.5 }];
const mockTtrData = [{ type: "多样性", value: 0.73 }];

const Entirety = (props) => {
  const { data } = props;

  React.useEffect(() => {
    if (data) {
      makeReadabilityChart(data.readability, "readability");
      makeWatchChart(data.sentiment_tendency, "sentiment_tendency", "#FFB3CB");
      makeWatchChart(data.ttr, "ttr", "#2BDEB6");
    }
  }, [data]);

  const makeWatchChart = (chartData, id, color = null) => {
    WatchChart(chartData, id, color);
  };

  const makeReadabilityChart = (chartData, id, textStyle = null) => {
    CircleChart(chartData, id, textStyle);
  };

  const renderStyle = () => {
    return (
      <div className="statistics-module-background statistics-entirety-style-container">
        <Help
          tip={getModuleTip("entirety-style")}
          position={{ right: "10px", top: "10px" }}
        />
        <Title title={"风格"} />
        <div
          id="readability"
          className="statistics-entirety-style-watch-readability"
        />
        <div className="statistics-entirety-style-watch-container">
          <div
            id="sentiment_tendency"
            className="statistics-entirety-style-watch-item"
          />
          <div id="ttr" className="statistics-entirety-style-watch-item" />
        </div>
      </div>
    );
  };

  const renderData = () => {
    return (
      <div className="statistics-module-background statistics-entirety-data-container">
        <Help
          tip={getModuleTip("entirety-data")}
          position={{ right: "10px", top: "10px" }}
          placement={"left"}
        />
        <Title title={"数据"} />
        <div className="statistics-entirety-data-count-container">
          <div className="statistics-entirety-data-count-container">
            <div className="statistics-entirety-data-count-line">
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot" />
                <div className="statistics-entirety-data-count-item-tip">
                  字符数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.char_num}
                </div>
              </div>
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot" />
                <div className="statistics-entirety-data-count-item-tip">
                  中文字数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.zhchar_num}
                </div>
              </div>
            </div>
            <div className="statistics-entirety-data-count-line">
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot" />
                <div className="statistics-entirety-data-count-item-tip">
                  数字个数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.numchar_num}
                </div>
              </div>
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot" />
                <div className="statistics-entirety-data-count-item-tip">
                  空白个数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.blankchar_num}
                </div>
              </div>
            </div>
            <div className="statistics-entirety-data-count-line">
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot-2" />
                <div className="statistics-entirety-data-count-item-tip">
                  标点数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.punchar_num}
                </div>
              </div>
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot-2" />
                <div className="statistics-entirety-data-count-item-tip">
                  词数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.word_num}
                </div>
              </div>
            </div>
            <div className="statistics-entirety-data-count-line">
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot-2" />
                <div className="statistics-entirety-data-count-item-tip">
                  句数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.sent_num}
                </div>
              </div>
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-count-item-dot-2" />
                <div className="statistics-entirety-data-count-item-tip">
                  段数
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.number.para_num}
                </div>
              </div>
            </div>
          </div>

          <div className="statistics-entirety-data-divider-container">
            <div className="statistics-entirety-data-divider" />
          </div>

          <div className="statistics-entirety-data-count-container">
            <div className="statistics-entirety-data-count-line">
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-sentence-item-dot" />
                <div className="statistics-entirety-data-count-item-tip">
                  平均词长
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.length.ave_word_len}
                </div>
              </div>
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-sentence-item-dot" />
                <div className="statistics-entirety-data-count-item-tip">
                  平均句长
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.length.ave_sent_len}
                </div>
              </div>
            </div>
            <div className="statistics-entirety-data-count-line">
              <div className="statistics-entirety-data-count-item">
                <div className="statistics-entirety-data-sentence-item-dot" />
                <div className="statistics-entirety-data-count-item-tip">
                  平均段长
                </div>
                <div className="statistics-entirety-data-count-item-value">
                  {data.length.ave_para_len}
                </div>
              </div>
            </div>
          </div>

          {/* <div
            id="vocabulary"
            className="statistics-entirety-data-sentence-vocabulary"
          /> */}

          <div className="statistics-entirety-data-sentence-vocabulary-background">
            <div className="statistics-entirety-data-sentence-vocabulary-text">
              {data.vocabulary[0].type}
            </div>
            <div className="statistics-entirety-data-sentence-vocabulary-count">
              {data.vocabulary[0].value}
            </div>
          </div>

          <div className="statistics-entirety-data-sentence-tip-text">
            根据当前文本
          </div>
          <div className="statistics-entirety-data-sentence-count-text">{`推测本文作者使用词汇量是${data.vocabulary[0].value}个`}</div>
        </div>
      </div>
    );
  };

  if (!data) {
    return null;
  }

  return (
    <div className="statistics-single-container">
      {renderStyle()}
      {renderData()}
    </div>
  );
};

export default Entirety;
