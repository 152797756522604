import * as React from "react";
import "./styles.css";

//引入的库组件
import { Spin, message } from "antd";

//引入的其他页面组件
import Article from "./article";
import Sentence from "./sentence";
import Entirety from "./entirety";
import Word from "./word";
import { toChinesNum } from "./utils";

//graphql查询
import { useGetStatisticDataQuery } from "../../../generated/graphql";

//引入的资源图片

const Tabs = ["整体", "字词", "句法", "篇章"];

const Statistics = (props) => {
  const { ids } = props;
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [entiretyData, setEntiretyData] = React.useState(null);
  const [wordData, setWordData] = React.useState(null);
  const [sentenceData, setSentenceData] = React.useState(null);
  const [passageData, setPassageData] = React.useState(null);

  const { refetch: getStatisticDataQuery } = useGetStatisticDataQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  React.useEffect(() => {
    setIsLoading(true);
    getStatisticDataQuery({ ids: ids }).then((res) => {
      setIsLoading(false);
      if (res.errors) {
        message.error("获取统计数据失败");
      } else {
        let indexes = res.data.calculateIndex.indexMap;
        if (!!!indexes) {
          message.error("没有获取到统计数据");
          return;
        }

        if (typeof indexes === "string") {
          indexes = JSON.parse(indexes);
        }

        if (!!!Object.keys(indexes).length) {
          message.error("没有获取到统计数据");
          return;
        }

        //整体的数据===================================================
        let entirety = indexes.entirety;
        let vocabulary = [{ type: "词汇量", value: entirety.infer.vocabulary }];
        let readability = [
          { type: "可读指数", value: entirety.style.readability },
        ];
        let sentiment_tendency = [
          { type: "情感指数", value: entirety.style.sentiment_tendency / 100 },
        ];
        let sentiment_intensity = [
          { type: "情感强度", value: entirety.style.sentiment_intensity / 100 },
        ];
        let ttr = [{ type: "多样指数", value: entirety.style.ttr / 100 }];
        let number = entirety.number;
        let length = entirety.length;

        let entiretyData = {
          vocabulary,
          readability,
          sentiment_tendency,
          sentiment_intensity,
          ttr,
          number,
          length,
        };
        setEntiretyData(entiretyData);

        //字词的数据===================================================
        let word = indexes.word;

        let pronunciation = [
          {
            item: "齿音",
            count: word.pronunciation.chiyin_chars_num,
            color: "#FF8484",
          },
          {
            item: "舌音",
            count: word.pronunciation.sheyin_chars_num,
            color: "#FFD47B ",
          },
          {
            item: "牙音",
            count: word.pronunciation.yayin_chars_num,
            color: "#2BDEB6",
          },
          {
            item: "唇音",
            count: word.pronunciation.chunyin_chars_num,
            color: "#FFA7EE",
          },
          {
            item: "喉音",
            count: word.pronunciation.houyin_chars_num,
            color: "#82A1FF",
          },
        ];

        let pronunciationCount = 0;
        pronunciation.forEach((item) => {
          pronunciationCount += item.count;
        });
        pronunciation.forEach((item) => {
          item.percent = (item.count / pronunciationCount) * 100;
        });

        let glyph = [
          { item: "一字", count: word.glyph.one_char_words_num },
          { item: "二字", count: word.glyph.two_char_words_num },
          { item: "三字", count: word.glyph.three_char_words_num },
          { item: "四字", count: word.glyph.four_char_words_num },
          { item: "多字", count: word.glyph.multi_char_words_num },
        ];

        let glyphCount = 0;
        glyph.forEach((item) => {
          glyphCount += item.count;
        });
        glyph.forEach((item) => {
          item.percent = item.count / glyphCount;
        });

        let meaning = [
          {
            item: "名词",
            count: word.meaning.noun_words_num,
            color: "#D4E5BF",
          },
          {
            item: "动词",
            count: word.meaning.verb_words_num,
            color: "#96D7C9 ",
          },
          {
            item: "形容词",
            count: word.meaning.adj_words_num,
            color: "#C4E2E9",
          },
          {
            item: "代词",
            count: word.meaning.pron_words_num,
            color: "#ECCCE6",
          },
          { item: "副词", count: word.meaning.adv_words_num, color: "#FFE0E0" },
          {
            item: "介词",
            count: word.meaning.prep_words_num,
            color: "#FFF4B5",
          },
          {
            item: "连词",
            count: word.meaning.conj_words_num,
            color: "#F7E6D1",
          },
        ];

        let meaningCount = 0;
        meaning.forEach((item) => {
          meaningCount += item.count;
        });
        meaning.forEach((item) => {
          item.percent = (item.count / meaningCount) * 100;
        });

        let charDifficulty = [
          { type: "平均字难度", value: word.infer.ave_char_difficulty / 100 },
        ];
        let wordDifficulty = [
          { type: "平均词难度", value: word.infer.ave_word_difficulty / 100 },
        ];

        let wordData = {
          pronunciation,
          glyph,
          meaning,
          charDifficulty,
          wordDifficulty,
        };
        setWordData(wordData);

        //句法的数据===================================================
        let syntax = indexes.syntax;

        let syntaxLength = [
          { item: "正常", count: syntax.length.normal },
          { item: "较长", count: syntax.length.long },
          { item: "较短", count: syntax.length.short },
        ];

        let syntaxLengthCount = 0;
        syntaxLength.forEach((item) => {
          syntaxLengthCount += item.count;
        });
        syntaxLength.forEach((item) => {
          item.percent = syntaxLengthCount ? item.count / syntaxLengthCount : 0;
        });

        let form = [
          {
            item: "单句数",
            count: syntax.form.single_sents_num,
            color: "#FFCF00",
          },
          {
            item: "复句数",
            count: syntax.form.complex_sents_num,
            color: "#82A1FF",
          },
          {
            item: "平均句子数",
            count: syntax.form.ave_sub_sents_num,
            color: "#2BDEB6",
          },
        ];

        let formCount = 0;
        form.forEach((item) => {
          formCount += item.count;
        });
        form.forEach((item) => {
          item.percent = (item.count / formCount) * 100;
        });

        let pattern = [
          {
            item: "感叹句数",
            count: syntax.pattern.exclamatory_sents_num,
            color: "#FFA7EE",
          },
          {
            item: "疑问句数",
            count: syntax.pattern.question_sents_num,
            color: "#17D2FF",
          },
          {
            item: "祈使句数",
            count: syntax.pattern.imperative_sents_num,
            color: "#FFCF00",
          },
          {
            item: "陈述句数",
            count: syntax.pattern.declarative_sents_num,
            color: "#2BDEB6",
          },
        ];

        let patternCount = 0;
        pattern.forEach((item) => {
          patternCount += item.count;
        });
        pattern.forEach((item) => {
          item.percent = (item.count / patternCount) * 100;
        });

        let negative = syntax.meaning.negative_sents_num;
        let postive = syntax.meaning.postive_sents_num;

        let syntaxData = {
          syntaxLength,
          form,
          pattern,
          negative,
          postive,
        };
        setSentenceData(syntaxData);

        //篇章的数据===================================================
        let passage = indexes.passage;

        let wordCloudData = passage.organization.word_cloud.map(
          (item, index) => {
            return {
              value: item[1],
              x: item[0],
            };
          }
        );

        let conciseness = passage.organization.conciseness.map(
          (item, index) => {
            return {
              name: `第${toChinesNum(index + 1)}段`,
              value: item,
            };
          }
        );

        let aveConciseness = [
          {
            type: "整体篇章难易度",
            value: passage.organization.ave_conciseness / 100,
          },
        ];

        let chars = passage.char_word_sent.char_nums.map((item, index) => {
          return {
            name: `第${toChinesNum(index + 1)}段`,
            value: item,
          };
        });

        let words = passage.char_word_sent.word_nums.map((item, index) => {
          return {
            name: `第${toChinesNum(index + 1)}段`,
            value: item,
          };
        });

        let sents = passage.char_word_sent.sent_nums.map((item, index) => {
          return {
            name: `第${toChinesNum(index + 1)}段`,
            value: item,
          };
        });

        const getArrayAve = (array) => {
          let sum = 0;
          if (array.length === 0) {
            return 0;
          }
          array.forEach((item) => {
            sum += item;
          });
          return (sum / array.length).toFixed(2);
        };

        let aveWordLength = {
          name: "平均词长",
          value: getArrayAve(passage.char_word_sent.ave_word_lens),
        };
        let aveSentLength = {
          name: "平均句长",
          value: getArrayAve(passage.char_word_sent.ave_sent_lens),
        };

        let passageData = {
          wordCloudData,
          conciseness:
            conciseness.length === 0
              ? [
                  {
                    name: `第${toChinesNum(1)}段`,
                    value: 0,
                  },
                ]
              : conciseness,
          aveConciseness,
          chars:
            chars.length === 0
              ? [
                  {
                    name: `第${toChinesNum(1)}段`,
                    value: 0,
                  },
                ]
              : chars,
          words:
            words.length === 0
              ? [
                  {
                    name: `第${toChinesNum(1)}段`,
                    value: 0,
                  },
                ]
              : words,
          sents:
            sents.length === 0
              ? [
                  {
                    name: `第${toChinesNum(1)}段`,
                    value: 0,
                  },
                ]
              : sents,
          aveWordLength,
          aveSentLength,
        };
        setPassageData(passageData);
      }
    });
  }, []);

  const renderContent = () => {
    switch (tabIndex) {
      case 0:
        return <Entirety data={entiretyData} />;
      case 1:
        return <Word data={wordData} />;
      case 2:
        return <Sentence data={sentenceData} />;
      case 3:
        return <Article data={passageData} />;
    }
  };
  return (
    <div className="statistics-container">
      {isLoading ? (
        <Spin
          spinning={isLoading}
          tip={"正在统计中..."}
          style={{ marginTop: "150px" }}
        />
      ) : (
        <>
          {" "}
          <div className="statistics-tab-container">
            {Tabs.map((item, index) => {
              return (
                <div
                  key={"statistic-tab-" + index}
                  className={
                    tabIndex === index
                      ? "statistics-tab-text-selected"
                      : "statistics-tab-text"
                  }
                  onClick={() => {
                    if (tabIndex !== index) {
                      setTabIndex(index);
                    }
                  }}
                >
                  {item}
                </div>
              );
            })}
          </div>
          <div className="statistics-content-container">{renderContent()}</div>
        </>
      )}
    </div>
  );
};

export default Statistics;
