import * as React from "react";
import "./styles.css";

//引入的库组件
import { Spin, Tooltip, message } from "antd";
import ShortUUID from "short-uuid";

import "codemirror/lib/codemirror.css";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor, Viewer } from "@toast-ui/react-editor";
import "@toast-ui/editor/dist/i18n/zh-cn";
import "../aw-check.css";

import "tui-color-picker/dist/tui-color-picker.css";
import colorSyntax from "@toast-ui/editor-plugin-color-syntax";

import { uuid, regex } from "uuidv4";

//引入的其他页面组件
import timeLocalStorage from "../../../utils/timelocalstorage";
import configs from "../../../configs";
import headers from "../../../GlobalData/headers";
import KeyMap from "../../../i18n/keymap";
import keymap from "../../../i18n/keymap";
import { html2Escape } from "../utils/main";

//七牛云，用作图床
const TOKENURL = configs.qiniuUri;
const UPLOADURL = "https://up-z1.qiniup.com";

//自动保存的间隔
const AUTO_SAVE_TIME = 2000;

const toolbarItems = [
  "heading",
  "bold",
  "italic",
  "strike",
  "divider",
  "hr",
  "quote",
  "divider",
  "ul",
  "ol",
  "task",
  "indent",
  "outdent",
  "divider",
  "table",
  "image",
  "link",
  "divider",
  "code",
  "codeblock",
  "divider",
];

const TuiEditorComponent = (props) => {
  const {
    data,
    isShowMarkdown,
    modifyDatas,
    setModifyDatas,
    titleSelectedIndex,
    saveCallback,
    feedbackData,
    feedbackType,
    onFocusCallback,
    onClickCallback,
    onHoverCallback,
    isWysiwygMode,
    isHighlightAndMarkMode,
    setIsHighlightAndMarkMode,
    highlightWords,
    findAndReplaceDatas,
    setFindAndReplaceDatas,
    isFullScreen,
    changeOutlineName,
    editorReset,
    setEditorReset,
  } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [isShortcutInit, setIsShortcutInit] = React.useState(false);
  const [isInEditor, setIsInEditor] = React.useState(false);
  const [isContentChange, setIsContentChange] = React.useState(false);
  const [content, setContent] = React.useState(
    data.data.type === "title" ? `# ${data.data.text}` : data.data.text
    // html2Escape(
    //   data.data.type === "title" ? `# ${data.data.text}` : data.data.text
    // )
  ); //编辑器原始文本内容
  const [contentForHighlight, setContentForHighLight] = React.useState(
    data.data.text
  ); //高亮文本
  const [isInit, setIsInit] = React.useState(false);
  const [isShowFeedBackData, setIsShowFeedBackData] = React.useState(false);
  const [scrollTop, setScrollTop] = React.useState(0);

  const editorRef = React.useRef();
  const viewerRef = React.useRef();

  const autoSaveCallback = () => {
    if (isContentChange) {
      setIsContentChange(false);
      saveData(content, false);
    }
  };
  const autoSaveCallbackRef = React.useRef(autoSaveCallback);
  autoSaveCallbackRef.current = autoSaveCallback;

  React.useEffect(() => {
    setIsInit(true);
    let time = setInterval(() => {
      autoSaveCallbackRef.current();
    }, AUTO_SAVE_TIME);
    if (!editorReset && !isShowMarkdown) {
      initToolbarTip();
      setIsLoading(true);
      setEditorReset(true);
    }else {
      setIsShortcutInit(true);
    }
    window.onblur = (e) => {
      setIsInEditor(false);
      if (editorRef.current) {
        editorRef.current.getInstance().blur();
      }
    };
    return () => {
      clearInterval(time);
    };
  }, []);

  React.useEffect(() => {
    if (!isInEditor) {
      if (data.data.text !== content) {
        setContent(data.data.text);
        if (!isHighlightAndMarkMode) {
          setEditorValue(data.data.text);
        }
      }
    }
  }, [data.data.text]);

  React.useEffect(() => {
    if (changeOutlineName.id === data.data.id && data.data.type === "title") {
      if (viewerRef.current) {
        viewerRef.current
          .getInstance()
          .setMarkdown(changeOutlineName.title, false);
      }
      if (editorRef.current) {
        editorRef.current
          .getInstance()
          .setMarkdown(changeOutlineName.title, false);
      }
    }
  }, [changeOutlineName]);

  React.useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
  }, [isLoading]);

  React.useEffect(() => {
    customShortcuts();
  }, [isInEditor]);

  React.useEffect(() => {
    if (findAndReplaceDatas.length) {
      let contentTemp = content;
      const cardId = data.data.id;
      let dataIndex = -1;
      findAndReplaceDatas.forEach((item, index) => {
        if (item.cardId === cardId && data.data.type === "content") {
          dataIndex = index;
          let startIndex = item.start;
          let endIndex = item.end;
          contentTemp =
            contentTemp.substring(0, startIndex) +
            contentTemp.substring(endIndex, contentTemp.length);
          contentTemp = insertStr(contentTemp, startIndex, item.replace);

          findAndReplaceDatas.splice(dataIndex, 1);
          setFindAndReplaceDatas(findAndReplaceDatas);
          setContent(contentTemp);
          saveData(contentTemp, false);
        }
      });
    }
  }, [findAndReplaceDatas]);

  React.useEffect(() => {
    if (modifyDatas.length) {
      if (data.data.type !== "content") {
        return;
      }
      let contentTemp = content;
      const cardId = data.data.id;
      let notModifyDatas = [];
      let isModifyed = false;
      modifyDatas.forEach((item, index) => {
        if (item.cardId === cardId) {
          if (item.advice !== "") {
            let startIndex = item.start;
            let endIndex = item.end;
            contentTemp =
              contentTemp.substring(0, startIndex) +
              contentTemp.substring(endIndex, contentTemp.length);
            contentTemp = insertStr(contentTemp, startIndex, item.advice);
          }
          isModifyed = true;
        } else {
          notModifyDatas.push(item);
        }
      });
      if (isModifyed) {
        setModifyDatas(notModifyDatas);
      }
      setContent(contentTemp);
      saveData(contentTemp, false);
    }
  }, [modifyDatas]);

  React.useEffect(() => {
    if (isInit) {
      if (data.data.type !== "content" || !isHighlightAndMarkMode) {
        return;
      }
      const cardId = data.data.id;
      if (feedbackData.length) {
        let feedbackDataTemp = JSON.parse(JSON.stringify(feedbackData));
        let contentTemp = content;
        feedbackDataTemp.sort((a, b) => {
          return b.start - a.start;
        });
        feedbackDataTemp.forEach((item, index) => {
          if (item.cardId === cardId) {
            let startIndex = item.start;
            let endIndex = item.end;
            let highLightText = `<span id="${item.mId}" class="${makeSpanClass(
              feedbackDataTemp.length - 1 - index
            )}">${item.wrong}</span>`;
            contentTemp =
              contentTemp.substring(0, startIndex) +
              contentTemp.substring(endIndex, contentTemp.length);
            contentTemp = insertStr(contentTemp, startIndex, highLightText);
            setEditorValue(contentTemp);
          }
        });
      }
    }
  }, [feedbackData]);

  React.useEffect(() => {
    if (isInit) {
      if (data.data.type === "content" || !isHighlightAndMarkMode) {
        if (highlightWords.length) {
          highlightWords.map((item, index) => {
            if (item.type === "search") {
              makeSearchHighlight(item, index);
            } else if (item.type === "find-replace") {
              makeFindAndReplaceHightlight(item, index);
            } else if (
              item.type === "mark" ||
              item.type === "mark-word-cloud" ||
              item.type === "mark-word-meta" ||
              item.type === "mark-word-replacement"
            ) {
              makeMarkHightlight(item, index);
            }
          });
        } else {
          removeHightlight();
        }
      }
    }
  }, [highlightWords]);

  const initToolbarTip = () => {
    if (editorRef.current) {
      let options = editorRef.current.getInstance().i18n._langs.get("zh-CN");
      if (options["Bold"].indexOf(KeyMap.EDITOR_BOLD_KEY) === -1) {
        options["Bold"] =
          options["Bold"] + `<br>${KeyMap.EDITOR_BOLD_KEY}</br>`;
      }
      if (options["Italic"].indexOf(KeyMap.EDITOR_ITALIC_KEY) === -1) {
        options["Italic"] =
          options["Italic"] + `<br>${KeyMap.EDITOR_ITALIC_KEY}</br>`;
      }
      if (options["Strike"].indexOf(KeyMap.EDITOR_STRIKE_KEY) === -1) {
        options["Strike"] =
          options["Strike"] + `<br>${KeyMap.EDITOR_STRIKE_KEY}</br>`;
      }
      if (options["Line"].indexOf(KeyMap.EDITOR_LINE_KEY) === -1) {
        options["Line"] =
          options["Line"] + `<br>${KeyMap.EDITOR_LINE_KEY}</br>`;
      }
      if (options["Blockquote"].indexOf(KeyMap.EDITOR_BLOCKQUOTE_KEY) === -1) {
        options["Blockquote"] =
          options["Blockquote"] + `<br>${KeyMap.EDITOR_BLOCKQUOTE_KEY}</br>`;
      }
      if (
        options["Unordered list"].indexOf(KeyMap.EDITOR_UNORDERED_LIST_KEY) ===
        -1
      ) {
        options["Unordered list"] =
          options["Unordered list"] +
          `<br>${KeyMap.EDITOR_UNORDERED_LIST_KEY}</br>`;
      }
      if (
        options["Ordered list"].indexOf(KeyMap.EDITOR_ORDERED_LIST_KEY) === -1
      ) {
        options["Ordered list"] =
          options["Ordered list"] +
          `<br>${KeyMap.EDITOR_ORDERED_LIST_KEY}</br>`;
      }
      if (options["Task"].indexOf(KeyMap.EDITOR_TAST_KEY) === -1) {
        options["Task"] =
          options["Task"] + `<br>${KeyMap.EDITOR_TAST_KEY}</br>`;
      }
      editorRef.current.getInstance().i18n._langs.set("zh-CN", options);
      setIsShortcutInit(true);
    }
  };

  //chrome不支持 CTRL + NTW
  const customShortcuts = () => {
    document.getElementById(`tui-editor-${data.data.id}`).onkeydown = (e) => {
      if (!e || !isInEditor || !editorRef.current) return;
      if (KeyMap.EDITOR_BOLD(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Bold");
      }
      if (KeyMap.EDITOR_ITALIC(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Italic");
      }
      if (KeyMap.EDITOR_STRIKE(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Strike");
      }
      if (KeyMap.EDITOR_LINE(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("HR");
      }
      if (KeyMap.EDITOR_BLOCKQUOTE(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Blockquote");
      }
      if (KeyMap.EDITOR_UNORDERED_LIST(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("UL");
      }
      if (KeyMap.EDITOR_ORDERED_LIST(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("OL");
      }
      if (KeyMap.EDITOR_TAST(e)) {
        e.preventDefault();
        editorRef.current.getInstance().exec("Task");
      }
    };
  };

  const makeMarkHightlight = (item, index) => {
    let contentTemp = content;
    const cardId = data.data.id;

    if (item.cardId === cardId) {
      item.wordInfo.sort((a, b) => {
        return b.end - a.end;
      });
      item.wordInfo.map((posInfo, posInfoIndex) => {
        let startIndex = posInfo.start;
        let endIndex = posInfo.end;
        let highLightTextStart = `<span class="${posInfo.className}" ${
          posInfo.id !== "" ? "id=" + posInfo.id : ""
        }>`;
        let highLightTextEnd = "</span>";
        contentTemp = insertStr(contentTemp, endIndex, highLightTextEnd);
        contentTemp = insertStr(contentTemp, startIndex, highLightTextStart);
      });
      setEditorValue(contentTemp);
    }
  };

  const makeFindAndReplaceHightlight = (item, index) => {
    let value = content;
    let count = 0;
    let ids = [];
    const CHECK_STRING = new RegExp(item.word, "g");
    value = value.replace(CHECK_STRING, (str, index) => {
      let id = `find-and-replace-${data.data.id}-${count++}`;
      ids.push({
        id: id,
        start: index,
        end: index + str.length,
        cardId: data.data.id,
        targetWord: str,
      });
      return `<span class=${item.className} id="${id}">${item.word}</span>`;
    });
    if (item.callback && count > 0 && ids.length) {
      item.callback(count, ids);
    }
    setEditorValue(value);
  };

  const makeSearchHighlight = (item, index) => {
    let value = content;
    const SEARCH_TAG = new RegExp(item.word, "g");
    const REPLACE_STRING = `<span class=${item.className} >${item.word}</span>`;
    value = value.replace();
    value = value.replace(SEARCH_TAG, REPLACE_STRING);
    setEditorValue(value);
  };

  const removeHightlight = () => {
    let value = content;
    const MD_START_TAG = /<span.*?>/g;
    const MD_END_TAG = /<\/span>/g;
    value = value.replace(MD_START_TAG, "").replace(MD_END_TAG, "");
    setEditorValue(value);
  };

  const setEditorValue = (value, isFocus = false) => {
    if (viewerRef.current) {
      viewerRef.current.getInstance().setMarkdown(value, false);
    }
    if (editorRef.current) {
      editorRef.current.getInstance().setMarkdown(value, false);
    }
    if (isHighlightAndMarkMode) {
      makeSpecialDom();
    }
    // if(isFocus){
    //   let el = document.getElementById(`tui-editor-${data.data.id}`);
    //   if(el){
    //     el.scrollTo(0, scrollTop);
    //   }
    // }
    // if (isClick) {
    //   let mouseEvent = document.createEvent("MouseEvent");
    //   mouseEvent.initMouseEvent("click", true, true, window, 1, mouseEvent.clientX, mouseEvent.clientY);
    //   let editor = document.getElementById("tui-editor");
    //   if (editor) {
    //     setTimeout(() => {
    //       console.log("dispatchEvent=============");
    //       editor.dispatchEvent(mouseEvent);
    //     }, 100);
    //   }
    // }
  };

  const saveData = (value, isOnFocus = false) => {
    if (saveCallback) {
      let saveData = {
        blocks: [
          {
            data: {
              text: value,
              id: data.data.id,
              type: data.data.type,
            },
            key: data.key,
            type: "paragraph",
          },
        ],
      };
      saveCallback(saveData, isOnFocus);
    }
  };

  const insertStr = (source, start, insert) => {
    return source.slice(0, start) + insert + source.slice(start);
  };

  const makeSpanClass = (position) => {
    if (feedbackType === "check" || feedbackType === "style") {
      return "aw-error";
    }
  };

  const changeContent = (isBlur = false) => {
    let value = editorRef.current.getInstance().getMarkdown();
    // if (editorRef.current.getInstance().isWysiwygMode()) {
    //   const MD_START_TAG = /<span.*?>/g;
    //   const MD_END_TAG = /<\/span>/g;
    //   value = value.replace(MD_START_TAG, "").replace(MD_END_TAG, "");
    // }
    setContent(value);
    if (isBlur) {
      saveData(value, true);
    }
    setIsContentChange(true);
  };

  const addImage = async (file, callback, source) => {
    let userInfo = timeLocalStorage.get("USERINFO");
    let userId = window.atob(userInfo.id).split("User:")[1];
    let projectId = data.projectId;
    let imageId = uuid();
    let imagePath = window.btoa(
      "ImageInfo:" + userId + "/" + projectId + "/" + imageId
    );
    // let imagePath = window.btoa("ImageInfo:" + userId + "/" + projectId + "/" + imageId);

    let translator = ShortUUID();
    let short =
      translator.fromUUID(userId) +
      "/" +
      translator.fromUUID(projectId) +
      "/" +
      translator.fromUUID(imageId);

    try {
      const requestToken = await fetch(TOKENURL, {
        method: "GET",
        headers: headers,
      });
      const resultToken = await requestToken.text();
      const token = resultToken;
      const formData = new FormData();
      formData.append("token", token);
      formData.append("file", file);
      formData.append("key", short); //可以区分文件夹的方案
      const upload = await fetch(UPLOADURL, {
        method: "POST",
        body: formData,
      });
      const resultUpload = await upload.json();
      const src = `https://static.aiwriter.net/${resultUpload.key}`;

      if (callback) {
        callback(src, "");
      }
    } catch (error) {
      message.error("上传失败，请检查网络设置");
    }
  };

  const makeSpecialDom = () => {
    let elements = document.getElementsByClassName(" tui-md-html");
    if (elements && elements.length) {
      let removeElement = [];
      let changeElement = [];

      Array.prototype.forEach.call(elements, (element, index) => {
        if (element.innerText.indexOf("<span") !== -1) {
          let textElement = element.nextSibling;
          let content = textElement.textContent;
          let outerHTML = element.innerText + content + "</span>";
          changeElement.push({ element: element, outerHTML: outerHTML });
          removeElement.push(textElement);
        } else if (element.innerText.indexOf("span class") !== -1) {
          let textElement = element.nextSibling;
          let content = textElement.textContent;
          let outerHTML = "<" + element.innerText + content + "</span>";
          changeElement.push({ element: element, outerHTML: outerHTML });
          removeElement.push(textElement);
        }
        if (element.innerText.indexOf("</span") !== -1) {
          removeElement.push(element);
        } else if (element.innerText === "<") {
          removeElement.push(element);
        }
      });
      removeElement.forEach((item) => {
        if (item.parentNode) {
          item.parentNode.removeChild(item);
        }
      });
      changeElement.forEach((item) => {
        item.element.outerHTML = item.outerHTML;
      });
    }
  };

  const changeHandle = () => {
    if (isHighlightAndMarkMode) {
      makeSpecialDom();
    } else {
      changeContent();
    }
  };

  const changeHandleRef = React.useRef(changeHandle);
  changeHandleRef.current = changeHandle;

  const focusHandle = () => {
    setIsInEditor(true);
    if (isHighlightAndMarkMode) {
      setEditorValue(content, true);
      setIsHighlightAndMarkMode(false);
    }
  };

  const focusHandleRef = React.useRef(focusHandle);
  focusHandleRef.current = focusHandle;

  const blurHandle = () => {
    setIsInEditor(false);
    if (isHighlightAndMarkMode) {
      makeSpecialDom();
    } else {
      changeContent(true);
    }
  };

  const blurHandleRef = React.useRef(blurHandle);
  blurHandleRef.current = blurHandle;

  if (isLoading) {
    return null;
  }

  return (
    <div
      id={`tui-editor-${data.data.id}`}
      className={
        "tuieditor-main-container" +
        (titleSelectedIndex === 3
          ? " tuieditor-main-container-preview-all"
          : "")
      }
      style={isShortcutInit ? {} : { display: "none" }}
      onMouseOver={(e) => {
        if (isHighlightAndMarkMode && onHoverCallback) {
          onHoverCallback(e);
        }
      }}
      onClick={(e) => {
        if (isHighlightAndMarkMode && onHoverCallback) {
          onClickCallback(e);
        }
      }}
      onFocusCapture={(e) => {}}
      onScroll={(e) => {
        setScrollTop(e.target.scrollTop);
        if (isHighlightAndMarkMode) {
          makeSpecialDom();
        }
      }}
    >
      {isShowMarkdown ? (
        <div
          className={`tuieditor-preview-container  ${
            titleSelectedIndex === 2
              ? ""
              : data.data.type === "title"
              ? "tuieditor-container-title"
              : "tuieditor-container-content"
          }`}
        >
          <Viewer
            ref={viewerRef}
            height="auto"
            hideModeSwitch={true}
            initialEditType="markdown" //"markdown" "wysiwyg"
            initialValue={content}
            previewHighlight={false}
            usageStatistics={false}
          />
        </div>
      ) : (
        <Editor
          key={`tuieditor-markdown-id-${data.data.type}-${data.data.id}`}
          ref={editorRef}
          height="100%"
          previewStyle={isFullScreen ? "vertical" : "tab"}
          hideModeSwitch={true}
          initialEditType={"markdown"}
          initialValue={content}
          previewHighlight={false}
          usageStatistics={false}
          useCommandShortcut={false}
          language={"zh-CN"}
          toolbarItems={toolbarItems}
          hooks={{
            addImageBlobHook: addImage,
          }}
          events={{
            change: () => {
              changeHandleRef.current();
            },
            focus: () => {
              focusHandleRef.current();
            },
            blur: () => {
              blurHandleRef.current();
            },
          }}
        />
      )}
    </div>
  );
};

export default TuiEditorComponent;
