import * as React from "react";
import "./styles.css";
import "./styles2.css";

//引入的其他页面组件
import BubbleBox from "../../Components/BubbleBox/index";
import WordMark from "../WordMark/index";
import WordCloud from "../WordCloud/index";
import WordMeta from "../WordMeta/index";
import WordReplace from "../WordReplace/index";

//引入的库组件
import { Icon, Tooltip } from "antd";

//graphql查询
import { useQuery } from "react-apollo-hooks";
import gql from "graphql-tag";
import { useApolloClient } from "react-apollo";

//引入的资源图片
import FULL_SCREEN from "../../../assets/full-screen.png";
import ADD_CARD from "../../../assets/add-card.png";
import INSERT_CARD from "../../../assets/insert-card.png";
import OUTLINE_NODE from "../../../assets/card_icon.png";
import OUTLINE_FOLDER from "../../../assets/folder_yellow.png";

import CloseIcon from "../../../assets/writing/close.png";
import OpenIcon from "../../../assets/writing/open.png";

import FindAndReplace from "../../../assets/writing/replace.png";
import FindAndReplaceSelected from "../../../assets/writing/replace-selected.png";
import MarkDownIcon from "../../../assets/writing/markdown-icon.png";
import MarkDownIconSelected from "../../../assets/writing/markdown-icon-selected.png";
import TuiIcon from "../../../assets/writing/tui-icon.png";
import VditorIcon from "../../../assets/writing/vditor-icon.png";

const GET_CARD_PAGE_DATA = gql`
  {
    cardSordTypeClient @client
  }
`;

const GET_TOOLS_TAB_DATA = gql`
  {
    writingToolsTabIndexClient @client
  }
`;

const FullScreenButton = (props) => {
  return (
    <Tooltip placement="bottom" title={"全屏"}>
      <img
        src={FULL_SCREEN}
        style={{
          cursor: "pointer",
          width: "14px",
          height: "14px",
          marginRight: 20,
        }}
        onClick={() => {
          props.setFullScreen(true);
        }}
      />
    </Tooltip>
  );
};

const SortTypeButton = (props) => {
  const client = useApolloClient();
  const { data: cardPageData } = useQuery(GET_CARD_PAGE_DATA);
  const { cardSordTypeClient } = cardPageData;
  const [orderMethod, setOrderMethod] = React.useState(cardSordTypeClient);
  const chooseOrderMethod = () => {
    client.writeData({
      data: {
        cardSordTypeClient: orderMethod === 0 ? 1 : 0,
      },
    });
    setOrderMethod(orderMethod === 0 ? 1 : 0);
  };
  return (
    <div className="tab-menu-card-button-sort-type-container">
      <div className="tab-menu-order" onClick={chooseOrderMethod}>
        {orderMethod === 1 ? "平铺排列" : "顺序排列"}
      </div>
    </div>
  );
};

const WritingTab = (props) => {
  const {
    isNetworkOnline,
    titleSelectedIndex,
    currentCardInfo,
    isShowOutline,
    setIsShowOutline,
    renderCardIds,
    setHighlightWords,
    highlightWords,
    hideProofreadingBar,
    isShowMarkdown,
    setIsShowMarkDown,
    setShowReplaceModal,
    isShowReplaceModal,
    setProofReadingBar,
    omitAllMistake,
    setFullScreen,
  } = props;

  return (
    <div className="writing-tab-bar-container">
      <div
        className={`writing-tab-bar-outline ${
          isShowOutline
            ? "writing-tab-bar-outline-showing"
            : "writing-tab-bar-outline-hiding"
        }`}
      >
        <div className="writing-tab-bar-outline-container">
          <div className="writing-tab-bar-outline-text">大纲</div>
        </div>
        <div
          className="writing-tab-bar-outline-icon"
          onClick={() => {
            setIsShowOutline(!isShowOutline);
          }}
        >
          <img
            src={isShowOutline ? CloseIcon : OpenIcon}
            style={{ width: "10px" }}
          />
        </div>
      </div>

      {titleSelectedIndex === 1 && (
        <div className="tab-menu-container">
          <div className="writing-tab-bar-tab-menu-card-button-container">
            <div className="writing-tab-bar-tab-card-count-container">
              <img
                src={currentCardInfo.icon}
                style={{ marginTop: 1, width: 16, marginRight: 6, height: 16 }}
              />
              <span
                style={{ paddingTop: 0 }}
                className="writing-tab-bar-tab-menu-card-name-text"
              >
                {currentCardInfo.title}
              </span>

              <span className="writing-tab-bar-tab-card-count-text">
                {currentCardInfo.cardCount}张
              </span>
            </div>
            <SortTypeButton />
          </div>
        </div>
      )}
      {titleSelectedIndex === 2 && (
        <div className="writing-tab-bar-title-container">
          <div className={"writing-tab-bar-title-text"}>
            {currentCardInfo.title}
          </div>
          <div className="writing-tab-bar-title-divider" />
          <div className="writing-tab-bar-title-text">{`${currentCardInfo.contentLength}字`}</div>
        </div>
      )}
      {titleSelectedIndex > 1 && (
        <div>
          <div className="writing-tab-bar-menu-fullscreen-tab">
            {isNetworkOnline && (
              <WordMark
                imgStyle={{
                  width: 14,
                  height: 14,
                  marginRight: 20,
                  cursor: "pointer",
                }}
                currentCardIds={renderCardIds.map((item, index) => {
                  return window.btoa("Card:" + item.data.id);
                })}
                setHighlightWords={setHighlightWords}
                highlightWords={highlightWords}
                disabled={!hideProofreadingBar}
              />
            )}
            {isNetworkOnline && (
              <WordCloud
                imgStyle={{
                  width: 18,
                  height: 14,
                  marginRight: 20,
                  cursor: "pointer",
                }}
                currentCardIds={renderCardIds.map((item, index) => {
                  return window.btoa("Card:" + item.data.id);
                })}
                setHighlightWords={setHighlightWords}
                highlightWords={highlightWords}
                disabled={!hideProofreadingBar}
              />
            )}
            {isNetworkOnline && (
              <WordMeta
                imgStyle={{
                  width: 14,
                  height: 14,
                  marginRight: 20,
                  cursor: "pointer",
                }}
                currentCardIds={renderCardIds.map((item, index) => {
                  return window.btoa("Card:" + item.data.id);
                })}
                setHighlightWords={setHighlightWords}
                highlightWords={highlightWords}
                disabled={!hideProofreadingBar}
              />
            )}

            {/**占位符*/}
            <div
              style={{
                width: 14,
                height: 14,
                marginRight: 20,
              }}
            />
            <Tooltip
              placement="bottom"
              title={isShowMarkdown ? "切换至编辑模式" : "切换至预览模式"}
            >
              <img
                src={isShowMarkdown ? MarkDownIconSelected : MarkDownIcon}
                style={{
                  width: 18,
                  height: 14,
                  marginRight: 20,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowReplaceModal(false);
                  setIsShowMarkDown(!isShowMarkdown);
                }}
              />
            </Tooltip>
            {isNetworkOnline && (
              <Tooltip placement="bottom" title={"查找和替换"}>
                <img
                  src={
                    isShowReplaceModal ? FindAndReplaceSelected : FindAndReplace
                  }
                  style={{
                    width: 14,
                    height: 14,
                    marginRight: 20,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (hideProofreadingBar) {
                      setShowReplaceModal(!isShowReplaceModal);
                    }
                  }}
                />
              </Tooltip>
            )}

            <FullScreenButton
              setProofReadingBar={setProofReadingBar}
              omitAllMistake={omitAllMistake}
              setFullScreen={setFullScreen}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WritingTab;
