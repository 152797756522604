import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import "../../styles.css";
import { useSharedProjectsQuery } from "../../../../generated/graphql";

const { CheckableTag } = Tag;

const pageInfo = {
  before: undefined,
  after: undefined,
  first: 24,
};

const HotTags = (props) => {
  const {
    isMultiple,
    setIsMultiple,
    title,
    selectedCategory,
    setChosenTag,
    setChosenCate,
    order,
    setSelectedCategory,
    chosenTag,
    selectedTags,
    setSelectedTags,
    categoryList,
    setCardList,
    getWIKICards,
    hasMultiChoice,
    setCStr,
    filterSelectedProjects,
  } = props;
  const { refetch: getSharedProjects } = useSharedProjectsQuery({
    skip: true,
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });

  const getFirstTagCards = (defaultTag) => {
    setSelectedTags([defaultTag]);
    const variables = {
      selectedTags: [defaultTag],
      type: "WIKI",
      order,
      pageInfo,
    };
    if (!hasMultiChoice || !isMultiple) setChosenTag(defaultTag);
    getWIKICards(variables);
  };

  useEffect(() => {
    if (!selectedTags.length) return;
    const variables = {
      selectedTags: [selectedTags[0]],
      type: "WIKI",
      order,
      pageInfo,
    };
    if (!hasMultiChoice || !isMultiple) setChosenTag(selectedTags[0]);
    getWIKICards(variables);
  }, [order]);

  useEffect(() => {
    if (
      !selectedTags.length &&
      hasMultiChoice &&
      categoryList &&
      categoryList.length
    ) {
      getFirstTagCards(categoryList[0]);
    }
  }, [selectedTags]);

  useEffect(() => {
    if (!hasMultiChoice && categoryList && categoryList.length) {
      const cates = document.getElementsByClassName(
        "ant-tag ant-tag-checkable"
      );
      cates[0].click();
    }
  }, [categoryList]);

  const getSelectedProjects = async (tagName) => {
    const variables = {
      filter: {
        type: "WIKI",
        categories: [tagName],
        isSpecialWiki: true,
      },
      first: 100,
      order,
    };
    const res = await getSharedProjects(variables);
    if (res && res.data && res.data.sharedProjects) {
      const selectedProjects = res.data.sharedProjects.edges.map((sp) => {
        const spInfo = sp.node;
        return {
          title: spInfo.title,
          id: spInfo.id,
        };
      });
      setChosenCate(selectedProjects);
      filterSelectedProjects(selectedProjects);
      const alltag = document.getElementById("tag-id-all");
      if (alltag) alltag.click();
    }
  };

  const handleChange = async (tag, checked) => {
    setCStr("");
    if (!hasMultiChoice) {
      getSelectedProjects(tag.name);
      setChosenCate(tag.name);
      setSelectedCategory([tag]);
      // setChosenTag({});
      setSelectedTags([]);
      const selectedProjects = categoryList.filter((c) => c.name === tag.name);
      if (selectedProjects.length) {
        filterSelectedProjects(selectedProjects[0].project);
      }
      return;
    }
    if (!isMultiple) {
      setChosenTag(tag);
      const variables = {
        selectedTags: [tag],
        type: "WIKI",
        order,
        pageInfo,
      };
      setSelectedTags([tag]);
      return getWIKICards(variables);
    }
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    const filteredTags =
      nextSelectedTags.length > 1
        ? nextSelectedTags.filter((t) => t.value !== "all")
        : nextSelectedTags;
    setSelectedTags(filteredTags);
    setCStr(filteredTags.map((ft) => ft.title).join("；"));
  };

  return (
    <div style={{ width: 286 }}>
      <div style={{ display: "flex", background: "rgba(239,240,244,1)" }}>
        <div
          style={{
            paddingTop: 3,
            height: 30,
            width: 286,
            paddingLeft: 15,
            marginRight: 8,
          }}
        >
          <span className={"tag-selection-tab"}>{title}</span>
        </div>
        {title === "筛选" ? (
          <div style={{ width: 50, fontSize: 11, marginTop: 6 }}>
            {/*{isMultiple*/}
            {/*    ? <div style={{ display: 'flex', width: 50, justifyContent: 'space-between' }}>*/}
            {/*        <span onClick={() => {*/}
            {/*            setCStr('');*/}
            {/*            setChosenTag({});*/}
            {/*            setIsMultiple(!isMultiple);*/}
            {/*            setSelectedTags([]);*/}
            {/*        }}>多选</span>*/}
            {/*        <span*/}
            {/*            style={{ fontWeight: 'bold', color: 'rgba(255,113,140,1)' }}*/}
            {/*            onClick={() => {*/}
            {/*                setCardList([]);*/}
            {/*                const variables = {*/}
            {/*                    selectedTags,*/}
            {/*                    type: 'WIKI',*/}
            {/*                    order,*/}
            {/*                    pageInfo*/}
            {/*                };*/}
            {/*                setChosenTag({});*/}
            {/*                getWIKICards(variables);*/}
            {/*            }}*/}
            {/*        >*/}
            {/*            确认*/}
            {/*        </span>*/}
            {/*      </div>*/}
            {/*    : <span onClick={() => {*/}
            {/*        setCStr('');*/}
            {/*        setChosenTag({});*/}
            {/*        setSelectedTags([]);*/}
            {/*        setIsMultiple(!isMultiple);*/}
            {/*    }}>单选</span>}*/}
          </div>
        ) : null}
      </div>
      <div style={{ paddingLeft: 20, paddingTop: 15, paddingBottom: 15 }}>
        {hasMultiChoice && categoryList && categoryList.length ? (
          <CheckableTag
            id={"tag-id-all"}
            key={"all"}
            checked={selectedTags[0] ? selectedTags[0].value === "all" : false}
            onChange={(checked) => {
              setCStr("");
              const selected = [{ value: "all" }];
              setSelectedTags(selected);
              setChosenTag({});
              const variables = {
                pageInfo,
                selectedTags: selected,
                type: "WIKI",
                order,
                isAll: true,
              };
              return getWIKICards(variables);
            }}
          >
            全部
          </CheckableTag>
        ) : null}
        {categoryList &&
          categoryList.length &&
          categoryList.map((tag, i) => (
            <CheckableTag
              key={"checkable" + i}
              checked={
                hasMultiChoice
                  ? selectedTags.indexOf(tag) > -1
                  : selectedCategory.indexOf(tag) > -1
              }
              onChange={(checked) => handleChange(tag, checked)}
            >
              {tag.name || tag.title}
            </CheckableTag>
          ))}
      </div>
    </div>
  );
};

export default HotTags;
