import * as React from "react";

//引入的其他页面组件
import BaseDB from "../WritingModuleAction/baseDB";
import DBController from "../WritingModuleAction/dbController";
import ProjectSchema from "../WritingModuleAction/projectSchema";
import CardSchema from "../WritingModuleAction/cardSchema";

//引入的库组件
import { uuid } from "uuidv4";

export const MainContextObj = React.createContext();
// export const projectDB = new BaseDB("projectsDB", [
//   { chartName: "project", keyPath: "code", indexArray: ["code", "id"] },
// ]);

// export const projectDB = new DBController("projectdb", [
//   { name: "project", schema: ProjectSchema },
// ]);

const MainContext = (props) => {
  const [isNetworkOnline, setIsNetworkOnline] = React.useState(
    window.navigator.onLine
  );
  const [DBUUID, setDBUUID] = React.useState(uuid());//项目实例标示符，用来区别多Tab操作时的操作窗口和接收窗口
  const [dbController, setDBController] = React.useState(null);
  const [isDBLeader, setIsDBLeader] = React.useState(false);
  const [isInitDB, setIsInitDB] = React.useState(false);

  const handleOnline = (e) => {
    setIsNetworkOnline(true);
  };

  const handleOffline = (e) => {
    setIsNetworkOnline(false);
  };

  React.useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    const initDB = async () => {
      const projectDB = await DBController.getAIWriterRxDB("projectdb", [
        {
          name: "project_string_data",
          schema: ProjectSchema,
          migrationStrategies: {},
        },
        {
          name: "cards_string_data",
          schema: CardSchema,
          migrationStrategies: {},
        },
      ]);
      projectDB.db.waitForLeadership().then((e) => {
        setIsDBLeader(true);
      });
      setDBController(projectDB);
      setIsInitDB(true);
    };

    initDB();

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  if (!isInitDB) {
    return null;
  }

  return (
    <MainContextObj.Provider
      value={{
        DBUUID: DBUUID,
        isNetworkOnline: isNetworkOnline,
        dbController: dbController,
        isDBLeader: isDBLeader,
      }}
    >
      {props.children}
    </MainContextObj.Provider>
  );
};

export default MainContext;
